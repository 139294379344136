import React from 'react';
import { FooterStyles } from './FooterStyles';
import {
  menuMethodsItems, menuOtherItems, menuProductsItems, partnerFooterIcons, shoppersPlatformUrls, socialMediasMenu,
} from '../../constants';
import { Link, useLocation } from 'react-router-dom';
import LightItUpTextIcon from '../../assets/icons/LightItUpTextIcon';
import FooterLogoIcon from '../../assets/icons/FooterLogoIcon';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

interface Props {
  colorTheme: 'darkTheme' | 'lightTheme',
}

function Footer({ colorTheme }: Props) {
  const { pathname } = useLocation();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <FooterStyles className={colorTheme} id="footer">
      <div className="container">
        <div className="footerContent">
          <div className="logoAndPartnersWrapper">
            <div className="logoContainer">
              <FooterLogoIcon />
              <LightItUpTextIcon color={colorTheme === 'lightTheme' ? '#000' : '#FFF'} />
            </div>
            <div className="partnersContainer">
              <span>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_1')}</span>
              <div>
                {/* eslint-disable-next-line react/no-array-index-key */}
                {partnerFooterIcons.map((iconWithAlt, index) => <img key={index} src={iconWithAlt.icon} alt={iconWithAlt.alt} />)}
              </div>
            </div>
          </div>
          <div className="navAndPrivacyPolicyWrapper">
            <div className="footerNavWrapper">
              <ul className="otherList">
                {menuOtherItems(interfaceLanguage).map((item, index) => {
                  if (item.shoppersPlatform) {
                    return (
                      <li
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        className={item.url === '/' && pathname === '/' ? 'active' : pathname.startsWith(item.url) && item.url !== '/' ? 'active' : ''}
                      >
                        <a href={shoppersPlatformUrls[interfaceLanguage] ? shoppersPlatformUrls[interfaceLanguage] : shoppersPlatformUrls.en} target="_blank" rel="noreferrer">{item.name}</a>
                      </li>
                    );
                  }

                  if (item.toOtherSite) {
                    return (
                      <li
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        className={item.url === '/' && pathname === '/' ? 'active' : pathname.startsWith(item.url) && item.url !== '/' ? 'active' : ''}
                      >
                        <a href={`${item.url}`}>{item.name}</a>
                      </li>
                    );
                  }

                  return (
                    <li
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      className={item.url === '/' && pathname === '/' ? 'active' : pathname.startsWith(item.url) && item.url !== '/' ? 'active' : ''}
                    >
                      <Link to={`/${interfaceLanguage}${item.url}`}>{item.name}</Link>
                    </li>
                  );
                })}
              </ul>

              <div className="MethodsListWrapper">
                <span>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_2')}</span>
                <ul>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  {menuMethodsItems(interfaceLanguage).map((item, index) => <li className={pathname.startsWith(item.url) ? 'active' : ''} key={index}><Link to={`/${interfaceLanguage}${item.url}`}>{item.name}</Link></li>)}
                </ul>
              </div>

              <div className="ProductsListWrapper">
                <span>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_3')}</span>
                <ul>
                  {menuProductsItems(interfaceLanguage).map((item, index) => {
                    if (((item.onlyInLanguages && item.onlyInLanguages.includes(interfaceLanguage)) || !item.onlyInLanguages)) {
                      return (
                        item.otherSiteLink ? (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index}>
                            <a
                              target="_blank"
                              className={pathname.startsWith(item.url) ? 'active' : ''}
                              href={item.url}
                              rel="noreferrer"
                            >
                              {item.name}
                            </a>
                          </li>

                        ) : (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index}>
                            <Link
                              className={pathname.startsWith(item.url) ? 'active' : ''}
                              to={`/${interfaceLanguage}${item.url}`}
                            >
                              {item.name}
                            </Link>
                          </li>
                        )
                      );
                    }

                    return null;
                  })}
                </ul>
              </div>
            </div>

            <div className="privacyPolicyWrapper">
              <div className="socialLinksWrapper">
                <div className="contacts">
                  <span>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_4')}</span>

                  <a href={interfaceLanguage === 'ro' ? 'tel:+40751628156' : interfaceLanguage === 'kz' ? 'tel:87076716116' : 'tel:+4314120126'}>{interfaceLanguage === 'ro' ? '+40751628156' : interfaceLanguage === 'kz' ? '+87076716116' : '+4314120126'}</a>

                  <a href={interfaceLanguage === 'ro' ? 'mailto:info_ro@4service-group.com' : interfaceLanguage === 'kz' ? 'mailto:info@4service.kz' : 'mailto:global@4service-group.com'}>{interfaceLanguage === 'ro' ? 'info_ro@4service-group.com' : interfaceLanguage === 'kz' ? 'info@4service.kz' : 'global@4service-group.com'}</a>
                </div>

                <div className="socialLinksContainer">
                  {socialMediasMenu.map((item, index) => {
                    const Icon = item.icon;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <a href={item.url} className="iconWrapper" key={index}>
                        <Icon
                          color={colorTheme === 'lightTheme' ? '#000' : '#FFF'}
                          circleColor={colorTheme === 'lightTheme' ? '#0000FF' : 'rgba(255, 255, 255, 0.4)'}
                          width={29}
                          height={29}
                        />
                      </a>
                    );
                  })}
                </div>
              </div>

              <div className="privacyPolicyContainer">
                <Link to={`/${interfaceLanguage}`}><span>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_5')}</span></Link>
                <Link to={`/${interfaceLanguage}/privacy-policy`}>
                  <span>{getTranslationsByLangOrEng(interfaceLanguage, 'footer_6')}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
