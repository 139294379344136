import React, { CSSProperties, useCallback, useRef } from 'react';
import { CircleCheckboxStyles } from './CircleCheckboxStyles';
import CheckMark from '../../../assets/icons/CheckMark';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorMessage from '../../formErrorMessage/FormErrorMessage';

interface CheckboxProps {
  name: string;
  value: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  extraBlockStyles?: CSSProperties;
  checkboxWrapperStyles?: CSSProperties;
  customizedCheckboxContainerStyles?: CSSProperties;
  label?: string;
  disabled?: boolean;
  error?: string;
  showError?: boolean;
  children?: any;
  colorTheme?: 'darkTheme' | 'lightTheme';
}

const CircleCheckbox: React.FC<CheckboxProps> = ({
  name,
  value,
  onChange,
  extraBlockStyles,
  checkboxWrapperStyles,
  customizedCheckboxContainerStyles,
  label,
  disabled,
  error,
  showError,
  children,
  colorTheme = 'lightTheme',
}) => {
  const { touched } = useFormikContext();

  const inputRef = useRef(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
    }
  }, [inputRef]);

  return (
    <CircleCheckboxStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label && <span>{label}</span>}
        <input className="checkboxDefault" type="checkbox" id={name} checked={value} ref={inputRef} onChange={onChange} disabled={disabled} />
        <div className="checkboxWrapper" style={checkboxWrapperStyles}>
          <div style={customizedCheckboxContainerStyles}>
            <div
              className={value ? `customizedCheckbox customizedCheckboxChecked ${colorTheme}` : `customizedCheckbox ${colorTheme}`}
              style={label ? { marginTop: 16 } : {}}
              onChange={disabled ? () => null : handleOnClick}
            >
              {value && <div className={`checkMark ${colorTheme}`} />}
            </div>
          </div>
          {children && (
          <div className="checkboxText">
            {children}
          </div>
          )}
        </div>
        {showError && (
          <div className="formErrorContainer">
            {error && <span>{error}</span>}
            {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
          </div>
        )}
      </label>
    </CircleCheckboxStyles>
  );
};

export default CircleCheckbox;
