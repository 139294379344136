import styled from 'styled-components';

export const ContactFormStyles = styled.div`
  form {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    padding: 47px 0 30px;

    @media (max-width: 1000px) {
      padding: 24px 0 16px;
      gap: 30px;
    }

    @media (max-width: 700px) {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 1px;
      width: calc(100% + 10px);
    }

    &::after {
      top: 0;
    }

    &::before, &::after {
      bottom: 0;
    }

    .formTextContainer {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1000px) {
        max-width: 154px;
      }

      @media (max-width: 700px) {
        max-width: none;
      }

      @media (max-width: 700px) {
        margin-bottom: 58px;
        padding: 0 15px;
      }

      h2 {
        margin-bottom: 12px;

        font-weight: 600;
        font-size: 51px;
        line-height: 115%;

        @media (max-width: 1000px) {
          font-size: 28px;
        }

        @media (max-width: 700px) {
          font-size: 36px;
        }
      }

      .errors {
        display: flex;
        flex-direction: column;

        .requiredText {
          max-width: 270px;

          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #c4c4c4;

          @media (max-width: 1000px) {
            font-size: 8px;
            line-height: 10px;
          }

          @media (max-width: 700px) {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }

    .formFieldsAndSubmitButtonWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      box-sizing: border-box;

      @media (max-width: 700px) {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
      }

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 12px;

        grid-column: 1/3;

        @media (max-width: 1000px) {
          gap: 7px;
        }

        @media (max-width: 700px) {
          margin-bottom: 58px;
          gap: 12px;
        }
      }

      .submitContainer {
        grid-column: 3/4;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        @media (max-width: 700px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 35px;
        }

        .submitButton {
          position: relative;
          display: flex;
          align-items: center;
          border: none;

          padding: 0 54px;
          height: 49px;

          border-radius: 100px;
          transition: 0.5s all;
          cursor: pointer;
          font-size: 16px;
          line-height: 20px;
          white-space: nowrap;

          @media only screen and (min-width: 700px) and (max-width: 1000px) {
            height: 26px;
            font-size: 8.53333px;
            line-height: 10px;
            padding: 0 26px;
          }

          //span {
          //  transition: 0.5s all;
          //}


          svg {
            position: absolute;
            right: 0;

            opacity: 0;
            transition: 0.5s all;

            @media only screen and (min-width: 700px) and (max-width: 1000px) {
              width: 20px;
            }
          }

          &:hover {
            span, svg {
              transform: translateX(-20px);

              @media only screen and (min-width: 700px) and (max-width: 1000px) {
                transform: translateX(-10px);
              }
            }

            svg {
              opacity: 1;
            }
          }
        }

        .imageWrapper {
          position: relative;
          width: 108px;
          height: 108px;
          border-radius: 100%;
          overflow: hidden;

          margin-bottom: 88px;

          @media (max-width: 1000px) {
            margin-bottom: 20px;
            width: 57.6px;
            height: 57.6px;
          }

          @media (max-width: 700px) {
            margin-bottom: 0;

            width: 80px;
            height: 80px;
          }

          .violetCircle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            border-radius: 100%;
            box-sizing: border-box;
            --b: 10px; /* border width*/

            display: inline-block;
            margin: 10px;
            aspect-ratio: 1;

            &::before {
              content: "";
              position: absolute;
              z-index: -1;
              inset: 0;
              background: var(--c, linear-gradient(to right, #6A21E6, #EB2E4B));
              transform: translate(-9%, -9%);
              padding: var(--b);
              border-radius: 50%;
              -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
              mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
            }

            @media (max-width: 1000px) {
              transform: translate(-58%, -60%);
            }

            @media (max-width: 700px) {
              transform: translate(-54%, -53%);
            }
          }

          .blueCircle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 81.5%;
            height: 81.5%;
            border: 5px solid #0000FF;
            box-sizing: border-box;

            border-radius: 100%;

            @media only screen and (min-width: 700px) and (max-width: 1000px) {
              width: 80.5%;
              height: 80.5%;

              transform: translate(-49%, -51%);
            }
          }

          img {
            height: 84.75949096679688px;
            width: 118.93670654296875px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 1000px) {
              width: 63.43px;
              height: 45.21px;
            }

            @media (max-width: 700px) {
              height: 62.78480529785156px;
              width: 88.10126495361328px;
            }
          }
        }
      }
    }
  }

  .pnoneInputContainer {
    &.error {
      .phoneInput {
        color: #FF0000;

        &:focus {
          &::placeholder {
            color: #FF0000;
          }
        }

        &::placeholder {
          color: #FF0000;
        }
      }
    }

    .phoneInput {
      background: none;
      border: none;
      font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 1000px) {
        font-size: 9px;
        line-height: 11px;
      }

      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 20px;
      }

      .phoneInputDropdownButton {
        border: rgba(255, 255, 255, 0.5);

        .arrow {
          &.up {
            border-top: none;
          }
        }
      }

      .phoneInputDropdown {
        max-width: 280px;

        .country {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #000;

          .country-name {
            text-align: center;
          }
        }
      }
    }
  }


  &.darkTheme {
    background-color: #000;

    .phoneInput {
      color: #FFF;

      &:focus {
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }

      &::placeholder {
        color: #FFF;
      }

      .phoneInputDropdownButton {
        border: rgba(255, 255, 255, 0.5);

        .selected-flag {
          background: none;

          &.open {
            background: none;
          }
        }

        .arrow {
          &.up {
            border-bottom: 4px solid #FFF;
          }

          border-top: 4px solid #FFF;
        }
      }

      .phoneInputDropdown {
        .country {
          color: #000;
        }
      }
    }

    .checkboxWrapper {
      max-width: 273px;
      display: flex;
      align-items: center;

      p {
        font-size: 11px;
        line-height: 13px;

        color: #c4c4c4;
      }

      a {
        font-size: 11px;
        line-height: 13px;
        text-decoration-line: underline;

        color: #FFF   ;
      }
    }

    .submitButton {
      position: relative;
      display: flex;
      align-items: center;
      border: none;

      padding: 0 54px;
      height: 49px;

      background: #CBCBCB;
      border-radius: 100px;
      transition: 0.5s all;
      cursor: pointer;

      span {
        transition: 0.5s all;
      }

      svg {
        position: absolute;
        right: 0;

        opacity: 0;
        transition: 0.5s all;
      }

      &:hover {
        background-color: #FFF;

        span, svg {
          transform: translateX(-20px);
        }

        svg {
          opacity: 1;
        }
      }
    }

    form {
      &::before, &::after {
        background-color: #FFF;
      }

      .formTextContainer {
        h2 {
          color: #FFFFFF;
        }
      }
    }
  }

  &.lightTheme {
    background-color: #FFF;

    .checkboxWrapper {
      max-width: 273px;
      display: flex;
      align-items: center;

      p {
        font-size: 9px;
        line-height: 11px;

        color: #767676;
      }

      a {
        font-size: 9px;
        line-height: 11px;
        text-decoration-line: underline;

        color: #0000FF;
      }
    }

    .submitButton {
      position: relative;
      display: flex;
      align-items: center;
      border: none;

      padding: 0 54px;
      height: 49px;

      background: #CBCBCB;
      color: #000;
      border-radius: 100px;
      transition: 0.5s all;
      cursor: pointer;
      box-sizing: border-box;

      &:hover {
        color: #FFF;
        border: 2px solid #000;

        svg {
          path {
            fill: #FFF;
          }
        }
      }

      span {
        transition: 0.3s transform;
      }

      svg {
        position: absolute;
        right: 0;

        opacity: 0;
        transition: 0.5s all;

        path {
          transition: 0.5s all;
          fill: #000;
        }
      }

      &:hover {
        span, svg {
          transform: translateX(-20px);
        }

        svg {
          opacity: 1;
        }
      }
    }


    form {
      &::before, &::after {
        background-color: #000;
      }

      .formTextContainer {
        h2 {
          color: #000;
        }
      }
    }
  }


  .errors {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FF0000;

    @media (max-width: 1000px) {
      font-size: 8px;
      line-height: 10px;
    }

    @media (max-width: 700px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;
