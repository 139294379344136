import React, { useRef } from 'react';
import { MenuStyles } from './MenuStyles';
import {
  menuMethodsItems, menuOtherItems, menuProductsItems, shoppersPlatformUrls, socialMediasMenu,
} from '../../constants';
import { useClickOutside } from '../../hooks/useClickOutside';
import CrossInCircleIcon from '../../assets/icons/CrossInCircleIcon';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';

interface Props {
  textColor: string,
  iconsColor: string,
  active: boolean,
  backgroundColor: string,
  menuButtonRef: any,
  setActive: () => void,
  openCloseMenuButton: any,
  langMenuRef: any,
}

function Menu({
  textColor, iconsColor, active, backgroundColor, menuButtonRef, setActive, openCloseMenuButton, langMenuRef,
}: Props) {
  const { pathname } = useLocation();

  const menuRef = useRef<any>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useClickOutside({ current: menuRef.current }, () => setActive(), { current: [menuButtonRef.current, openCloseMenuButton.current, langMenuRef.current] });

  // useClickOutside(menuRef, () => setActive(), menuButtonRef.current);

  return (
    <MenuStyles textColor={textColor} backgroundColor={backgroundColor} className={active ? 'menuActive' : ''}>
      <div className="container">
        <div className={active ? 'menuActive menuContent' : 'menuContent'} ref={menuRef}>
          <div className="menuHeadWrapper">
            <div className="linksContainer">
              <div className="methodsLinksWrapper">
                <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'menu_17')}</h2>
                <ul className="methodsLinksContainer">
                  {menuMethodsItems(interfaceLanguage).map((item, index) => (
                    // <Link className={pathname.startsWith(item.url) ? 'active' : ''} to={item.url} key={index} onClick={() => setActive()}>
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={item.url}>
                      <Link to={`/${interfaceLanguage}${item.url}`} onClick={() => setActive()}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="productsLinksWrapper">
                <h2>{getTranslationsByLangOrEng(interfaceLanguage, 'menu_18')}</h2>
                <ul className="productsLinksContainer">
                  {menuProductsItems(interfaceLanguage).map((item, index) => {
                    if (((item.onlyInLanguages && item.onlyInLanguages.includes(interfaceLanguage)) || !item.onlyInLanguages)) {
                      return item.otherSiteLink ? (
                        <li key={item.url}>
                          <a target="_blank" href={item.url} onClick={() => setActive()} rel="noreferrer">
                            {item.name}
                          </a>
                        </li>
                      ) : (
                        <li key={item.url}>
                          <Link to={`/${interfaceLanguage}${item.url}`} onClick={() => setActive()}>
                            {item.name}
                          </Link>
                        </li>
                      );
                    }

                    return null;
                  })}
                </ul>
              </div>

              <ul className="otherLinksContainer1">
                {menuOtherItems(interfaceLanguage).slice(0, 4).map((item, index) => (
                  <li key={item.url} onClick={() => setActive()}>
                    {/* <li className={pathname.startsWith(item.url) ? 'active' : ''} key={index} onClick={() => setActive()}> */}
                    {item.shoppersPlatform ? <a key={item.name} href={shoppersPlatformUrls[interfaceLanguage] ? shoppersPlatformUrls[interfaceLanguage] : shoppersPlatformUrls.en} target="_blank" rel="noreferrer">{item.name}</a> : (
                      item.toOtherSite ? (
                        <a key={item.name} href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                      ) : (
                        <Link to={`/${interfaceLanguage}${item.url}`} onClick={() => setActive()}>
                          {item.name}
                        </Link>
                      )
                    )}
                  </li>
                ))}
              </ul>

              <ul className="otherLinksContainer2">
                {menuOtherItems(interfaceLanguage).slice(4).map((item, index) => (
                  // <Link className={pathname.startsWith(item.url) ? 'active' : ''} to={item.url} key={index} onClick={() => setActive()}>
                  <li key={item.url}>
                    <Link to={`/${interfaceLanguage}${item.url}`} onClick={() => setActive()}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="socialLinksContainer">
              <ul>
                {socialMediasMenu.map((socialMedia, index) => {
                  const Icon = socialMedia.icon;
                  return (
                    <li key={socialMedia.url}>
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <a href={socialMedia.url} target="_blank" rel="noreferrer"><Icon color={iconsColor} circleColor={iconsColor} /></a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="splitter" />

          {/* <div className="menuFooterContent"> */}
          {/*  <h2>Registration Mystery shopping</h2> */}
          {/*  <a href=""> */}
          {/*    <div> */}
          {/*      Log in | Registration */}
          {/*    </div> */}
          {/*  </a> */}
          {/* </div> */}
        </div>
      </div>
    </MenuStyles>
  );
}

export default Menu;
