import React, { Fragment, useEffect, useState } from 'react';
import { CasesStyles } from './CasesStyles';
import CaseItem from '../../components/caseItem/CaseItem';
import ButtonWithArrowOnHover from '../../components/buttonWithArrowOnHover/ButtonWithArrowOnHover';
import EmptyHeaderBlockOnBlackPage from '../../components/emptyHeaderBlockOnBlackPage/EmptyHeaderBlockOnBlackPage';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useLocation } from 'react-router-dom';
import { IPost } from '../../enteties/IPost';
import workWithResponse from '../../functions/workWithResponse';
import { AdminApi } from '../../api/adminApi';
import Loader from '../../components/loader/Loader';
import MetaFromApi from '../../components/metaFromApi/MetaFromApi';

function Cases() {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [posts, setPosts] = useState<IPost[] | null>(null);
  const [activePage, setActivePage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(6);
  const [totalPostsCount, setTotalPostsCount] = useState<number>(0);

  async function getPosts() {
    setIsLoading(true);

    if (loadingError) {
      setLoadingError(false);
    }

    try {
      await workWithResponse(() => AdminApi.getPosts(interfaceLanguage, { type: '1' }, limit, activePage * limit))
        .then(({ error, data: postsData }) => {
          if (!error && postsData) {
            if (posts) {
              setPosts([...posts, ...postsData.data]);
            } else {
              setPosts(postsData.data);
            }

            setTotalPostsCount(postsData.count);
          }
        });
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // workWithResponse(() => AdminApi.getPosts(interfaceLanguage, { type: '2' }, limit, activePage * limit))
    //   .then(({ error, data: posts }) => {
    //     if (!error && posts) {
    //       setPosts(posts.data);
    //       setTotalPostsCount(posts.count);
    //       setIsLoading(false);
    //     }
    //   });
  }, []);

  // useEffect(() => {
  //   if (!isFirstRender) {
  //     if (prevLanguage.current === interfaceLanguage) {
  //       getPosts();
  //     } else {
  //       setActivePage(0);
  //       getPosts();
  //       prevLanguage.current = interfaceLanguage;
  //     }
  //   }
  // }, [interfaceLanguage, activePage]);

  useEffect(() => {
    getPosts();
  }, [location.search, interfaceLanguage, activePage]);

  return (
    <CasesStyles length={posts ? posts.length : 0}>
      <MetaFromApi slug="cases" />

      <EmptyHeaderBlockOnBlackPage />
      <div className="titleWrapper">
        <h1>{getTranslationsByLangOrEng(interfaceLanguage, 'cases_14')}</h1>
      </div>

      <div className="container">
        {
          posts ? (
            <>
              {/* <div className="hashtagsWrapper"> */}
              {/*  <button */}
              {/*    className={selectedHashtag === null ? 'active' : ''} */}
              {/*    type="button" */}
              {/*    onClick={() => setSelectedHashtag(null)} */}
              {/*  > */}
              {/*    {getTranslationsByLangOrEng(interfaceLanguage, 'cases_15')} */}
              {/*  </button> */}
              {/*  {hashtags.map((hashtag, index) => ( */}
              {/*    // eslint-disable-next-line react/no-array-index-key */}
              {/*    <button */}
              {/*      key={index} */}
              {/*      className={selectedHashtag === hashtag ? 'active' : ''} */}
              {/*      type="button" */}
              {/*      onClick={() => setSelectedHashtag(hashtag)} */}
              {/*    > */}
              {/*      {hashtag} */}
              {/*    </button> */}
              {/*  ))} */}
              {/* </div> */}
              <div className="casesList">
                {posts.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>
                    <CaseItem
                      title={item.contentTitle}
                      // hashtags={item.hashtags}
                      link={item.slug}
                      // captions={item.captions}
                      image={item.coverImage}
                    />
                  </Fragment>
                ))}

                {/* <div className={`lastItem ${posts.length % 2 === 0 ? 'large' : posts.length % 3 === 0 ? 'medium' : 'small'}`}> */}
                {/*  <span>{getTranslationsByLangOrEng(interfaceLanguage, 'cases_16')}</span> */}
                {/*  <ButtonWithArrowOnHover */}
                {/*    onClick={() => {}} */}
                {/*    arrowColor="#000" */}
                {/*    hoverTextColor="#000" */}
                {/*    textColor="#000" */}
                {/*    text={getTranslationsByLangOrEng(interfaceLanguage, 'find_out_more')} */}
                {/*    hoverBackground="#FFF" */}
                {/*    background="#CBCBCB" */}
                {/*  /> */}
                {/* </div> */}
              </div>

              {isLoading && (
                <Loader />
              )}

              {(activePage + 1) * limit < totalPostsCount && (
                <ButtonWithArrowOnHover
                  onClick={() => setActivePage((activePageNumber) => activePageNumber + 1)}
                  arrowColor="#000"
                  hoverTextColor="#000"
                  textColor="#FFF"
                  className="moreButton"
                  text={getTranslationsByLangOrEng(interfaceLanguage, 'more')}
                  hoverBackground="#CBCBCB"
                  borderColor="#CBCBCB"
                  background="transparent"
                />
              )}
            </>
          ) : (
            <Loader />
          )
        }
        {/* <div className="large lastItem"> */}
        {/*  <span>{getTranslationsByLangOrEng(interfaceLanguage, 'cases_16')}</span> */}
        {/*  <ButtonWithArrowOnHover */}
        {/*    onClick={() => {}} */}
        {/*    arrowColor="#000" */}
        {/*    hoverTextColor="#000" */}
        {/*    textColor="#000" */}
        {/*    text={getTranslationsByLangOrEng(interfaceLanguage, 'find_out_more')} */}
        {/*    hoverBackground="#FFF" */}
        {/*    background="#CBCBCB" */}
        {/*  /> */}
        {/* </div> */}
      </div>

    </CasesStyles>
  );
}

export default Cases;
