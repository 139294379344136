import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableLanguages, availableLanguages } from '../../enteties/ILanguage';

export interface LanguageState {
  interfaceLanguage: AvailableLanguages,
}

const initialState: LanguageState = {
  interfaceLanguage: 'en',
};

export const languageSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    // Reducer comes here
    setInterfaceLanguage: (state, action: PayloadAction<AvailableLanguages>) => ({ ...state, interfaceLanguage: action.payload }),
  },
  extraReducers: {},
});

const { actions, reducer } = languageSlice;
// Extract and export each action creator by name
export const { setInterfaceLanguage } = actions;
// Export the reducer, either as a default or named export
export default reducer;
