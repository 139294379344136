import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMetaData } from '../../enteties/IMetaData';

export interface metaDataState {
  pagesMetaData: { [key: string]: IMetaData },
}

const initialState: metaDataState = {
  pagesMetaData: {},
};

export const metaDataSlice = createSlice({
  name: 'metaData',
  initialState,
  reducers: {
    // Reducer comes here
    setPagesMetaData: (state, action: PayloadAction<{ [key: string]: IMetaData }>) => ({ ...state, pagesMetaData: action.payload }),
  },
  extraReducers: {},
});

const { actions, reducer } = metaDataSlice;
// Extract and export each action creator by name
export const { setPagesMetaData } = actions;
// Export the reducer, either as a default or named export
export default reducer;
