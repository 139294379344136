import React, { useEffect, useRef, useState } from 'react';
import { HeaderStyles } from './HeaderStyles';
import Logo from '../../assets/images/Logo';
import Menu from '../menu/Menu';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { LANGUAGES } from '../../constants/LANGUAGES';
import ILanguage from '../../enteties/ILanguage';
import { setInterfaceLanguage } from '../../state/slices/languageSlice';
import LangMenuMobile from '../langMenuMobile/LangMenuMobile';

interface Props {
  logoTextColor: string,
  sloganTextColor: string,
  langMenuTextColor: string,
  burgerMenuColor: string,
  menuTextColor: string,
  menuIconsColor: string,
  menuBackgroundColor: string,
  backgroundColor: string,
  backgroundColorWhenMenuActive?: string,
}

function Header({
  logoTextColor, sloganTextColor, langMenuTextColor, burgerMenuColor, menuTextColor, menuIconsColor, menuBackgroundColor, backgroundColor, backgroundColorWhenMenuActive,
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const langRef = useRef<any>();
  const menuRef = useRef<any>();
  const openCloseMenuButton = useRef<any>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();

  useClickOutside(langRef, () => setLanguageMenuOpen(false));

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [pathname]);

  return (
    <HeaderStyles sloganTextColor={sloganTextColor} langMenuTextColor={langMenuTextColor} burgerMenuColor={burgerMenuColor} backgroundColor={backgroundColor}>
      <div className="headerContent" style={menuOpen ? { background: backgroundColorWhenMenuActive } : {}}>
        <div className="container">

          <div className="logoContainer">
            <Link to={`/${interfaceLanguage}`}><Logo color={logoTextColor} /></Link>
            <span className="slogan sloganDesktop">{getTranslationsByLangOrEng(interfaceLanguage, 'light_it_up')}</span>
          </div>

          <span className="sloganMobile slogan">{getTranslationsByLangOrEng(interfaceLanguage, 'light_it_up')}</span>

          <div className="buttonsWrapper">
            <div className="langMenuContainer" ref={langRef}>
              <ul className={languageMenuOpen ? 'langMenu langMenuOpen' : 'langMenu'}>
                {Object.values(LANGUAGES).map((lang : ILanguage, index) => {
                  if (lang.code !== interfaceLanguage) {
                    return (
                      <li key={lang.name}>
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(setInterfaceLanguage(lang.code));

                            const splittedPathName = pathname.split('/');
                            splittedPathName[1] = lang.code;
                            navigate(`${splittedPathName.join('/')}`);
                            setLanguageMenuOpen(false);
                          }}
                        >
                          {lang.name}
                        </button>
                      </li>
                    );
                  }

                  return '';
                })}
              </ul>
              <button type="button" className={languageMenuOpen ? 'active menuMainButton' : 'menuMainButton'} onClick={() => setLanguageMenuOpen(!languageMenuOpen)}>
                {languageMenuOpen && LANGUAGES[interfaceLanguage].icon && (
                  <div className="iconContainer">
                    <img className="langFlag" src={LANGUAGES[interfaceLanguage].icon} alt="" />
                  </div>
                )}
                <span>{LANGUAGES[interfaceLanguage].name}</span>
              </button>
            </div>

            <LangMenuMobile />

            <div className={menuOpen ? 'open menu' : 'menu'} onClick={() => setMenuOpen(!menuOpen)} ref={menuRef}>
              <div className="menuStrip" />
              <div className="menuStrip" />
              <div className="menuStrip" />
            </div>
          </div>

        </div>
      </div>

      <Menu
        textColor={menuTextColor}
        iconsColor={menuIconsColor}
        backgroundColor={menuBackgroundColor}
        active={menuOpen}
        setActive={() => setMenuOpen(false)}
        menuButtonRef={menuRef}
        openCloseMenuButton={openCloseMenuButton}
        langMenuRef={langRef}
      />

      <button ref={openCloseMenuButton} onClick={() => setMenuOpen(!menuOpen)} type="button" className={menuOpen ? 'active menuMobileButton' : 'menuMobileButton'}>
        <div />
      </button>
    </HeaderStyles>
  );
}

export default Header;
