import styled from 'styled-components';

export const CircleCheckboxStyles = styled.div`
  .fieldName {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }
  
  .checkboxDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .checkboxWrapper {
    display: grid;
    grid-template-columns: 15px 1fr;
    grid-gap: 12px;
    cursor: pointer;
    color: #2C2C2C;
  }
  
  .customizedCheckbox {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    background: #E6E6E6;
    
    &.lightTheme {
      border: 1px solid #000;
    }
    
    &.darkTheme {
      border: 1px solid #FFF;
    }
  }

  .checkMark {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    
    background: #000;
  }
  
  .checkboxText {
    line-height: 22px;
    font-size: 14px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
