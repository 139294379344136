export function getParameterString(filterParams?: { [key: string]: string | number | string[] | number[] }): string {
  if (!filterParams) {
    return '';
  }

  const paramKeys = Object.keys(filterParams);
  if (paramKeys.length === 0) {
    return '';
  }

  const paramStrings = paramKeys.map((key) => {
    const value = filterParams[key];
    if (Array.isArray(value)) {
      const encodedValues = value.map((v) => encodeURIComponent(v));
      return `${encodeURIComponent(key)}=${encodedValues.join(',')}`;
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });

  return `&${paramStrings.join('&')}`;
}
