import React from 'react';
import { EmptyHeaderBlockOnBlackPageStyles } from './EmptyHeaderBlockOnBlackPageStyles';

function EmptyHeaderBlockOnBlackPage() {
  return (
    <EmptyHeaderBlockOnBlackPageStyles />
  );
}

export default EmptyHeaderBlockOnBlackPage;
