import ApiResponse from '../api/entities/ApiResponse';

async function workWithResponse<T>(request: () => Promise<ApiResponse<T>>): Promise<{ data: T | null, error: boolean }> {
  const response = await request();

  if (response.statusCode >= 200 && response.statusCode < 300) {
    return { data: response.data, error: false };
  }

  return { data: null, error: true };
}

export default workWithResponse;
