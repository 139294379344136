import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LayoutStyles } from './LayoutStyles';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { headerColorThemes, pagesWithBlackTheme } from '../../constants';
import ContactForm from '../contactForm/ContactForm';
import ArrowRightButtonIcon from '../../assets/icons/ArrowRightButtonIcon';
import CookieBar from '../cookieBar/CookieBar';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { useAppSelector } from '../../state/hooks';

export const Layout: React.FC = () => {
  const { pathname, hash } = useLocation();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [cookies, setCookie, removeCookie] = useCookies<string>(['cookieAccept']);

  const [scrollY, setScrollY] = useState(0);
  const [scrollToTopButtonVisibility, setScrollToTopButtonVisibility] = useState(false);

  const [prevInterfaceLanguage, setPrevInterfaceLanguage] = useState(interfaceLanguage);

  function logit() {
    setScrollY(window.pageYOffset);
    if (scrollY > 300) {
      setScrollToTopButtonVisibility(true);
    }

    if (scrollY < 300 && scrollToTopButtonVisibility) {
      setScrollToTopButtonVisibility(false);
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', logit);
    };
  });

  useEffect(() => {
    if (prevInterfaceLanguage === interfaceLanguage) {
      window.scroll(0, 0);
    } else {
      setPrevInterfaceLanguage(interfaceLanguage);
    }

    if (scrollToTopButtonVisibility) {
      setScrollToTopButtonVisibility(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (cookies.cookieAccept === 'true') {
      ReactGA.initialize('{MY_GA_ID}');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const theme = useMemo(() => {
    const splittedPathName = pathname.split('/');

    if (splittedPathName.slice(2).length === 0 || splittedPathName[2] === '') {
      return 'darkTheme';
    }
    if ((splittedPathName[2] === 'blog' && splittedPathName.length > 3) || (splittedPathName[2] === 'cases' && splittedPathName.length > 3)) {
      return 'lightTheme';
    }
    if (pagesWithBlackTheme.some((path) => (
      `${splittedPathName.slice(2).join('/')}`.toLowerCase().startsWith(path.toLowerCase())
    ))) {
      return 'darkTheme';
    }

    return 'lightTheme';
  }, [pathname]);

  return (
    <LayoutStyles className={theme}>
      <div className="layoutContentWrapper">
        <Header
          burgerMenuColor={headerColorThemes[theme].burgerMenuColor}
          langMenuTextColor={headerColorThemes[theme].langMenuTextColor}
          logoTextColor={headerColorThemes[theme].logoTextColor}
          sloganTextColor={headerColorThemes[theme].sloganTextColor}
          backgroundColor={headerColorThemes[theme].backgroundColor}
          menuBackgroundColor={headerColorThemes[theme].menuBackgroundColor}
          menuIconsColor={headerColorThemes[theme].menuIconsColor}
          menuTextColor={headerColorThemes[theme].menuTextColor}
          backgroundColorWhenMenuActive={headerColorThemes[theme].backgroundColorWhenMenuActive}
        />
        <main>
          <Outlet />
          {scrollToTopButtonVisibility && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button className="scrollButton" type="button" onClick={() => window.scrollTo(0, 0)}>
              <ArrowRightButtonIcon color="#FFF" width={20} height={30} />
            </button>
          )}

        </main>
        <div className="footerWrapper">
          <ContactForm
            colorTheme={theme}
          />
          <Footer
            colorTheme={theme}
          />
        </div>

        {/* {!cookies.cookieAccept && <CookieBar />} */}
      </div>
    </LayoutStyles>
  );
};
