import React from 'react';

function ArrowRightSmall({
  width = 6, height = 6, color = '#fff',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width={width} height={height}>
      <path
        fill={color}
        d="M15.244 11.767L8.39 18.672a1.12 1.12 0 11-1.59-1.579l6.064-6.11-6.11-6.064a1.12 1.12 0 011.58-1.59l6.904 6.854a1.117 1.117 0 01.006 1.584"
      />
    </svg>
  );
}

export default ArrowRightSmall;
