import InstagramIcon from './assets/icons/InstagramIcon';
import FacebookIcon from './assets/icons/FacebookIcon';
import LinkedinIcon from './assets/icons/LinkedinIcon';
import esomarLogo from './assets/icons/ESOMAR.png';
import DTLogo from './assets/icons/Logo DT.png';
import MSPAlog from './assets/icons/MSPA.png';
import socialResponsibilityEducationalActivitiesDataImage1 from './assets/images/servicemaniabusinessgame.png';
import socialResponsibilityEducationalActivitiesDataImage2 from './assets/images/diiya business.png';
import socialResponsibilityEducationalActivitiesDataImage3 from './assets/images/service design sessions.png';

import socialResponsibilityCharityDataImage1 from './assets/images/HelpEasyWith4ServiceGroup.png';
import socialResponsibilityCharityDataImage2 from './assets/images/fundraising-for-orphanages-and-retirement-homes.jpg';
import socialResponsibilityCharityDataImage3 from './assets/images/SaveUkraine.png';

import socialResponsibilityAnimalWelfareDataImage1 from './assets/images/Supporting animal shelters .png';
import socialResponsibilityAnimalWelfareDataImage2 from './assets/images/Creative initiatives to help stray animals.png';
import socialResponsibilityAnimalWelfareDataImage3 from './assets/images/pet-friendly-office.jpg';

import personalPhoto2 from './assets/images/Sergey Pashkovsky.png';
import personalPhoto3 from './assets/images/Viktoria Skorbota.png';
import personalPhoto4 from './assets/images/Alina_Andreieva.png';
import personalPhoto5 from './assets/images/Max Papka.png';
import personalPhoto6 from './assets/images/Kravchenko.png';
import personalPhoto7 from './assets/images/Aksonova.png';
import personalPhoto8 from './assets/images/Zhelevskiy.png';
import personalPhoto9 from './assets/images/Ruse.png';
import personalPhoto10 from './assets/images/Dinu.png';
import personalPhoto11 from './assets/images/Levitski.png';
import personalPhoto12 from './assets/images/Ardelean.png';

import aboutUsPartnerImage1 from './assets/images/Logo DT.png';
import aboutUsPartnerImage2 from './assets/images/logo mspa.png';
import aboutUsPartnerImage3 from './assets/images/logo esomar.png';
import aboutUsPartnerImage4 from './assets/images/Logo ВАКЦ.png';
import aboutUsPartnerImage5 from './assets/images/Logo DTEK.png';
import MysteryShoppingKeyFeaturesIcon2 from './assets/icons/MysteryShoppingKeyFeaturesIcon2';
import MysteryShoppingKeyFeaturesIcon1 from './assets/icons/MysteryShoppingKeyFeaturesIcon1';
import MysteryShoppingKeyFeaturesIcon3 from './assets/icons/MysteryShoppingKeyFeaturesIcon3';
import MysteryShoppingKeyFeaturesIcon4 from './assets/icons/MysteryShoppingKeyFeaturesIcon4';

import blogImage1 from './assets/images/presentation1.png';
import blogImage2 from './assets/images/presentation2.png';

import cases1 from './assets/images/cases car.png';
import cases2 from './assets/images/hisens cases.png';
import cases3 from './assets/images/cases phone.png';

import methodologies1 from './assets/images/methodolodies illustration CJM.png';
import methodologies2 from './assets/images/methodologies nps.png';
import methodologies3 from './assets/images/methodologies illustration BHT.png';
import methodologies4 from './assets/images/methodologies illustration HR research.png';
import methodologies5 from './assets/images/methodologies illustration CSI.png';
import methodologies5ro from './assets/images/methodologies illustration CSI-ro.png';
import methodologies6 from './assets/images/ux-testing.methodologies.png';
import methodologies6ro from './assets/images/ux-testing.methodologies-ro.png';
import methodologies7 from './assets/images/retailaudit.methodologies.png';
import methodologies8 from './assets/images/adtesting.methodologies.png';

import methodologies1Tablet from './assets/images/methodolodies illustration CJM Mobile.png';
import methodologies2Tablet from './assets/images/methodologies nps mobile.png';
import methodologies3Tablet from './assets/images/methodologies illustration BHT Mobile.png';
import methodologies4Tablet from './assets/images/methodologies illustration HR research Mobile.png';
import methodologies5Tablet from './assets/images/methodologies illustration CSI Mobile.png';

import products1 from './assets/images/products illustration Qualitative research.png';
import products2 from './assets/images/quantitativeresearchtabs.png';
import products3 from './assets/images/products illustration Mystery Shopping.png';
import products3ro from './assets/images/products illustration Mystery Shopping-ro.png';
import products4 from './assets/images/voicerproducts.png';
import products5 from './assets/images/play4salesproducts.png';
import products6 from './assets/images/wantent-products.png';
import products7 from './assets/images/price-monitoring.products.png';
import products8 from './assets/images/service-design.products.png';
import products8ro from './assets/images/service-design.products-ro.png';

import products1Tablet from './assets/images/products illustration Qualitative research mobile.png';
import products2Tablet from './assets/images/quantitativeresearchtabsMobile.png';
import products3Tablet from './assets/images/products illustration Mystery Shopping Mobile.png';
import products4Tablet from './assets/images/voicerproductsMobile.png';
import products5Tablet from './assets/images/play4salesproductsMobile.png';
import products6Tablet from './assets/images/wantent-products.mobile.png';
import products8Tablet from './assets/images/service-design.products-tablet.png';

import { IBlogItem } from './enteties/IBlogItem';
import MysteryShoppingKeyFeaturesIcon5 from './assets/icons/MysteryShoppingKeyFeaturesIcon5';
import MysteryShoppingKeyFeaturesIcon6 from './assets/icons/MysteryShoppingKeyFeaturesIcon6';
import MysteryShoppingKeyFeaturesIcon7 from './assets/icons/MysteryShoppingKeyFeaturesIcon7';
import MysteryShoppingKeyFeaturesIcon8 from './assets/icons/MysteryShoppingKeyFeaturesIcon8';
import MysteryShoppingKeyFeaturesIcon9 from './assets/icons/MysteryShoppingKeyFeaturesIcon9';
import MysteryShoppingKeyFeaturesIcon10 from './assets/icons/MysteryShoppingKeyFeaturesIcon10';
import MysteryShoppingKeyFeaturesIcon11 from './assets/icons/MysteryShoppingKeyFeaturesIcon11';
import MysteryShoppingKeyFeaturesIcon12 from './assets/icons/MysteryShoppingKeyFeaturesIcon12';

import typesOfMysteryShoppingImage1 from './assets/images/types of mystery shopping 1.png';
import typesOfMysteryShoppingImage2 from './assets/images/types of mystery shopping 2.png';
import typesOfMysteryShoppingImage3 from './assets/images/types of mystery shopping 3.png';
import typesOfMysteryShoppingImage4 from './assets/images/types of mystery shopping 4.png';
import typesOfMysteryShoppingImage5 from './assets/images/types of mystery shopping 5.png';
import typesOfMysteryShoppingImage6 from './assets/images/types of mystery shopping 6.png';
import typesOfMysteryShoppingImage7 from './assets/images/types of mystery shopping 7.png';
import typesOfMysteryShoppingImage8 from './assets/images/types of mystery shopping 8.png';
import { IMethodsHRSolutionsTextBlockInfo } from './enteties/IMethodsHRSolutionsTextBlockInfo';

import CJMStickerDesktop1 from './assets/images/CJMSticker1Desktop.png';
import CJMStickerTablet1 from './assets/images/CJMSticker1Tablet.png';
import CJMStickerMobile1 from './assets/images/CJMSticker1Mobile.png';
import CJMStickerDesktop2 from './assets/images/CJMSticker2Desktop.png';
import CJMStickerTablet2 from './assets/images/CJMSticker2Tablet.png';
import CJMStickerMobile2 from './assets/images/CJMSticker2Mobile.png';
import CJMStickerDesktop3 from './assets/images/CJMSticker3Desktop.png';
import CJMStickerTablet3 from './assets/images/CJMSticker3Tablet.png';
import CJMStickerMobile3 from './assets/images/CJMSticker3Mobile.png';
import CJMStickerDesktop4 from './assets/images/CJMSticker4Desktop.png';
import CJMStickerTablet4 from './assets/images/CJMSticker4Tablet.png';
import CJMStickerMobile4 from './assets/images/CJMSticker4Mobile.png';
import CJMStickerDesktop5 from './assets/images/CJMSticker5Desktop.png';
import CJMStickerTablet5 from './assets/images/CJMSticker5Tablet.png';
import CJMStickerMobile5 from './assets/images/CJMSticker5Mobile.png';

import CJMStickerDesktop1UA from './assets/images/CJMSticker1DesktopUA.png';
import CJMStickerTablet1UA from './assets/images/CJMSticker1TabletUA.png';
import CJMStickerMobile1UA from './assets/images/CJMSticker1MobileUA.png';
import CJMStickerDesktop2UA from './assets/images/CJMSticker2DesktopUA.png';
import CJMStickerTablet2UA from './assets/images/CJMSticker2TabletUA.png';
import CJMStickerMobile2UA from './assets/images/CJMSticker2MobileUA.png';
import CJMStickerDesktop3UA from './assets/images/CJMSticker3DesktopUA.png';
import CJMStickerTablet3UA from './assets/images/CJMSticker3TabletUA.png';
import CJMStickerMobile3UA from './assets/images/CJMSticker3MobileUA.png';
import CJMStickerDesktop4UA from './assets/images/CJMSticker4DesktopUA.png';
import CJMStickerTablet4UA from './assets/images/CJMSticker4TabletUA.png';
import CJMStickerMobile4UA from './assets/images/CJMSticker4MobileUA.png';
import CJMStickerDesktop5UA from './assets/images/CJMSticker5DesktopUA.png';
import CJMStickerTablet5UA from './assets/images/CJMSticker5TabletUA.png';
import CJMStickerMobile5UA from './assets/images/CJMSticker5MobileUA.png';

import { IQualitativeResearchesSolutionsProps } from './enteties/IQualitativeResearchesSolutionsProps';

import qualitativeResearchesSolutionsVideo1 from './assets/gifs/QualitativeResearchesSolutionsVideo1.gif';

// en
import qualitativeResearchesSolutions1Sticker1Desktop from './assets/images/qualitativeResearchesSticker1Desktop.png';
import qualitativeResearchesSolutions1Sticker1Tablet from './assets/images/qualitativeResearchesSticker1Tablet.png';
import qualitativeResearchesSolutions1Sticker1Mobile from './assets/images/qualitativeResearchesSticker1Mobile.png';
import qualitativeResearchesSolutions1Sticker2Desktop from './assets/images/qualitativeResearchesSticker2Desktop.png';
import qualitativeResearchesSolutions1Sticker2Tablet from './assets/images/qualitativeResearchesSticker2Tablet.png';
import qualitativeResearchesSolutions1Sticker2Mobile from './assets/images/qualitativeResearchesSticker2Mobile.png';
import qualitativeResearchesSolutions1Sticker3Desktop from './assets/images/qualitativeResearchesSticker3Desktop.png';
import qualitativeResearchesSolutions1Sticker3Tablet from './assets/images/qualitativeResearchesSticker3Tablet.png';
import qualitativeResearchesSolutions1Sticker3Mobile from './assets/images/qualitativeResearchesSticker3Mobile.png';

// ua
import qualitativeResearchesSolutions1Sticker1DesktopUA from './assets/images/qualitativeResearchesSticker1DesktopUK.png';
import qualitativeResearchesSolutions1Sticker1TabletUA from './assets/images/qualitativeResearchesSticker1TabletUK.png';
import qualitativeResearchesSolutions1Sticker1MobileUA from './assets/images/qualitativeResearchesSticker1MobileUK.png';
import qualitativeResearchesSolutions1Sticker2DesktopUA from './assets/images/qualitativeResearchesSticker2DesktopUK.png';
import qualitativeResearchesSolutions1Sticker2TabletUA from './assets/images/qualitativeResearchesSticker2TabletUK.png';
import qualitativeResearchesSolutions1Sticker2MobileUA from './assets/images/qualitativeResearchesSticker2MobileUK.png';
import qualitativeResearchesSolutions1Sticker3DesktopUA from './assets/images/qualitativeResearchesSticker3DesktopUK.png';
import qualitativeResearchesSolutions1Sticker3TabletUA from './assets/images/qualitativeResearchesSticker3TabletUK.png';
import qualitativeResearchesSolutions1Sticker3MobileUA from './assets/images/qualitativeResearchesSticker3MobileUK.png';

// ro
import qualitativeResearchesSolutions1Sticker1DesktopRo from './assets/images/qualitativeResearchesSticker1Desktop-ro.png';
import qualitativeResearchesSolutions1Sticker1TabletRo from './assets/images/qualitativeResearchesSticker1Tablet-ro.png';
import qualitativeResearchesSolutions1Sticker1MobileRo from './assets/images/qualitativeResearchesSticker1Mobile-ro.png';
import qualitativeResearchesSolutions1Sticker2DesktopRo from './assets/images/qualitativeResearchesSticker2Desktop-ro.png';
import qualitativeResearchesSolutions1Sticker2TabletRo from './assets/images/qualitativeResearchesSticker2Tablet-ro.png';
import qualitativeResearchesSolutions1Sticker2MobileRo from './assets/images/qualitativeResearchesSticker2Mobile-ro.png';
import qualitativeResearchesSolutions1Sticker3DesktopRo from './assets/images/qualitativeResearchesSticker3Desktop-ro.png';
import qualitativeResearchesSolutions1Sticker3TabletRo from './assets/images/qualitativeResearchesSticker3Tablet-ro.png';
import qualitativeResearchesSolutions1Sticker3MobileRo from './assets/images/qualitativeResearchesSticker3Mobile-ro.png';

import qualitativeResearchesSolutionsVideo2 from './assets/gifs/QualitativeResearchesSolutionsVideo2.gif';

// en
import qualitativeResearchesSolutions2Sticker1Desktop from './assets/images/qualitativeResearchesSticker4Desktop.png';
import qualitativeResearchesSolutions2Sticker1Tablet from './assets/images/qualitativeResearchesSticker4Tablet.png';
import qualitativeResearchesSolutions2Sticker1Mobile from './assets/images/qualitativeResearchesSticker4Mobile.png';
import qualitativeResearchesSolutions2Sticker2Desktop from './assets/images/qualitativeResearchesSticker5Desktop.png';
import qualitativeResearchesSolutions2Sticker2Tablet from './assets/images/qualitativeResearchesSticker5Tablet.png';
import qualitativeResearchesSolutions2Sticker2Mobile from './assets/images/qualitativeResearchesSticker5Mobile.png';
import qualitativeResearchesSolutions2Sticker3Desktop from './assets/images/qualitativeResearchesSticker6Desktop.png';
import qualitativeResearchesSolutions2Sticker3Tablet from './assets/images/qualitativeResearchesSticker6Tablet.png';
import qualitativeResearchesSolutions2Sticker3Mobile from './assets/images/qualitativeResearchesSticker6Mobile.png';

// ua
import qualitativeResearchesSolutions2Sticker1DesktopUA from './assets/images/qualitativeResearchesSticker4DesktopUK.png';
import qualitativeResearchesSolutions2Sticker1TabletUA from './assets/images/qualitativeResearchesSticker4TabletUK.png';
import qualitativeResearchesSolutions2Sticker1MobileUA from './assets/images/qualitativeResearchesSticker4MobileUK.png';
import qualitativeResearchesSolutions2Sticker2DesktopUA from './assets/images/qualitativeResearchesSticker5DesktopUK.png';
import qualitativeResearchesSolutions2Sticker2TabletUA from './assets/images/qualitativeResearchesSticker5TabletUK.png';
import qualitativeResearchesSolutions2Sticker2MobileUA from './assets/images/qualitativeResearchesSticker5MobileUK.png';
import qualitativeResearchesSolutions2Sticker3DesktopUA from './assets/images/qualitativeResearchesSticker6DesktopUK.png';
import qualitativeResearchesSolutions2Sticker3TabletUA from './assets/images/qualitativeResearchesSticker6TabletUK.png';
import qualitativeResearchesSolutions2Sticker3MobileUA from './assets/images/qualitativeResearchesSticker6MobileUK.png';

// ro
import qualitativeResearchesSolutions2Sticker1DesktopRo from './assets/images/qualitativeResearchesSticker4Desktop-ro.png';
import qualitativeResearchesSolutions2Sticker1TabletRo from './assets/images/qualitativeResearchesSticker4Tablet-ro.png';
import qualitativeResearchesSolutions2Sticker1MobileRo from './assets/images/qualitativeResearchesSticker4Mobile-ro.png';
import qualitativeResearchesSolutions2Sticker2DesktopRo from './assets/images/qualitativeResearchesSticker5Desktop-ro.png';
import qualitativeResearchesSolutions2Sticker2TabletRo from './assets/images/qualitativeResearchesSticker5Tablet-ro.png';
import qualitativeResearchesSolutions2Sticker2MobileRo from './assets/images/qualitativeResearchesSticker5Mobile-ro.png';
import qualitativeResearchesSolutions2Sticker3DesktopRo from './assets/images/qualitativeResearchesSticker6Desktop-ro.png';
import qualitativeResearchesSolutions2Sticker3TabletRo from './assets/images/qualitativeResearchesSticker6Tablet-ro.png';
import qualitativeResearchesSolutions2Sticker3MobileRo from './assets/images/qualitativeResearchesSticker6Mobile-ro.png';

import qualitativeResearchesSolutionsVideo3 from './assets/gifs/QualitativeResearchesSolutionsVideo3.gif';

// en
import qualitativeResearchesSolutions3Sticker1Desktop from './assets/images/qualitativeResearchesSticker7Desktop.png';
import qualitativeResearchesSolutions3Sticker1Tablet from './assets/images/qualitativeResearchesSticker7Tablet.png';
import qualitativeResearchesSolutions3Sticker1Mobile from './assets/images/qualitativeResearchesSticker7Mobile.png';
import qualitativeResearchesSolutions3Sticker2Desktop from './assets/images/qualitativeResearchesSticker8Desktop.png';
import qualitativeResearchesSolutions3Sticker2Tablet from './assets/images/qualitativeResearchesSticker8Tablet.png';
import qualitativeResearchesSolutions3Sticker2Mobile from './assets/images/qualitativeResearchesSticker8Mobile.png';
import qualitativeResearchesSolutions3Sticker3Desktop from './assets/images/qualitativeResearchesSticker9Desktop.png';
import qualitativeResearchesSolutions3Sticker3Tablet from './assets/images/qualitativeResearchesSticker9Tablet.png';
import qualitativeResearchesSolutions3Sticker3Mobile from './assets/images/qualitativeResearchesSticker9Mobile.png';

// ua
import qualitativeResearchesSolutions3Sticker1DesktopUA from './assets/images/qualitativeResearchesSticker7DesktopUK.png';
import qualitativeResearchesSolutions3Sticker1TabletUA from './assets/images/qualitativeResearchesSticker7TabletUK.png';
import qualitativeResearchesSolutions3Sticker1MobileUA from './assets/images/qualitativeResearchesSticker7MobileUK.png';
import qualitativeResearchesSolutions3Sticker2DesktopUA from './assets/images/qualitativeResearchesSticker8DesktopUK.png';
import qualitativeResearchesSolutions3Sticker2TabletUA from './assets/images/qualitativeResearchesSticker8TabletUK.png';
import qualitativeResearchesSolutions3Sticker2MobileUA from './assets/images/qualitativeResearchesSticker8MobileUK.png';
import qualitativeResearchesSolutions3Sticker3DesktopUA from './assets/images/qualitativeResearchesSticker9DesktopUK.png';
import qualitativeResearchesSolutions3Sticker3TabletUA from './assets/images/qualitativeResearchesSticker9TabletUK.png';
import qualitativeResearchesSolutions3Sticker3MobileUA from './assets/images/qualitativeResearchesSticker9MobileUK.png';

// ro
import qualitativeResearchesSolutions3Sticker1DesktopRo from './assets/images/qualitativeResearchesSticker7Desktop-ro.png';
import qualitativeResearchesSolutions3Sticker1TabletRo from './assets/images/qualitativeResearchesSticker7Tablet-ro.png';
import qualitativeResearchesSolutions3Sticker1MobileRo from './assets/images/qualitativeResearchesSticker7Mobile-ro.png';
import qualitativeResearchesSolutions3Sticker2DesktopRo from './assets/images/qualitativeResearchesSticker8Desktop-ro.png';
import qualitativeResearchesSolutions3Sticker2TabletRo from './assets/images/qualitativeResearchesSticker8Tablet-ro.png';
import qualitativeResearchesSolutions3Sticker2MobileRo from './assets/images/qualitativeResearchesSticker8Mobile-ro.png';
import qualitativeResearchesSolutions3Sticker3DesktopRo from './assets/images/qualitativeResearchesSticker9Desktop-ro.png';
import qualitativeResearchesSolutions3Sticker3TabletRo from './assets/images/qualitativeResearchesSticker9Tablet-ro.png';
import qualitativeResearchesSolutions3Sticker3MobileRo from './assets/images/qualitativeResearchesSticker9Mobile-ro.png';

import IThreeItemsGreedListWithGifAndDescription from './enteties/IThreeItemsGreedListWithGifAndDescription';

import gifThreeItemsGrid1 from './assets/gifs/circleArrows.gif';
import gifThreeItemsGrid2 from './assets/gifs/graph.gif';
import gifThreeItemsGrid3 from './assets/gifs/graph2.gif';

import gifThreeItemsGrid4 from './assets/gifs/a href=httpswww.flaticon 3.gif';
import gifThreeItemsGrid5 from './assets/gifs/a href=httpswww.flaticon 2.gif';
import gifThreeItemsGrid6 from './assets/gifs/a href=httpswww.flaticon 1.gif';
import { IQuantitativeResearchesSolutionData } from './enteties/IQuantitativeResearchesSolutionData';

import quantitativeResearchesSolutions1Video from './assets/gifs/quntitativeresearchsolutionsvideo1.gif';
import quantitativeResearchesSolutions2Video from './assets/gifs/quntitativeresearchsolutionsvideo2.gif';
import quantitativeResearchesSolutions3Video from './assets/gifs/quntitativeresearchsolutionsvideo3.gif';
import LogoDT from './assets/icons/LogoDT';
import MSPALogo from './assets/icons/MSPALogo';
import EsomarLogo from './assets/icons/EsomarLogo';
import LogoDTEK from './assets/icons/LogoDTEK';
import LogoBAKC from './assets/icons/LogoBAKC';

import partner1White from './assets/images/partners/white/BMW 1_static for white background.png';
import partner1WhiteHover from './assets/images/partners/white/BMW 2_hover for white background.png';
import partner2White from './assets/images/partners/white/BNP Paribas Group 1_static for white background.png';
import partner2WhiteHover from './assets/images/partners/white/BNP Paribas Group 2_hover for white background.png';
import partner3White from './assets/images/partners/white/Carrefour 1_static for white background.png';
import partner3WhiteHover from './assets/images/partners/white/Carrefour 2_hover for white background.png';
import partner4White from './assets/images/partners/white/Epson 1_static for white background.png';
import partner4WhiteHover from './assets/images/partners/white/Epson 2_hover for white background.png';
import partner5White from './assets/images/partners/white/Loreal 1_static for white background.png';
import partner5WhiteHover from './assets/images/partners/white/Loreal 2_hover for white background.png';
import partner6White from './assets/images/partners/white/Mercedes 1_static for white background.png';
import partner6WhiteHover from './assets/images/partners/white/Mercedes 2_hover for white background.png';
import partner7White from './assets/images/partners/white/Rolex 1_static for white background.png';
import partner7WhiteHover from './assets/images/partners/white/Rolex 2_hover for white background.png';
import partner8White from './assets/images/partners/white/Samsung 1_static for white background.png';
import partner8WhiteHover from './assets/images/partners/white/Samsung 2_hover for white background.png';
import partner9White from './assets/images/partners/white/Shell 1_static for white background.png';
import partner9WhiteHover from './assets/images/partners/white/Shell 2_hover for white background.png';
import partner10White from './assets/images/partners/white/Tui 1_static for white background.png';
import partner10WhiteHover from './assets/images/partners/white/Tui 2_hover for white background.png';
import partner11White from './assets/images/partners/white/UBER 1_static for white background.png';
import partner11WhiteHover from './assets/images/partners/white/UBER 2_hover for white background.png';

import partner1Black from './assets/images/partners/black/BMW 3_static for black background.png';
import partner1BlackHover from './assets/images/partners/black/BMW 4_hover for black background.png';
import partner2Black from './assets/images/partners/black/BNP Paribas Group 3_static for black background.png';
import partner2BlackHover from './assets/images/partners/black/BNP Paribas Group 4_hover for black background.png';
import partner3Black from './assets/images/partners/black/Carrefour 3_static for black background.png';
import partner3BlackHover from './assets/images/partners/black/Carrefour 4_hover for black background.png';
import partner4Black from './assets/images/partners/black/Epson 3_static for black background.png';
import partner4BlackHover from './assets/images/partners/black/Epson 4_hover for black background.png';
import partner5Black from './assets/images/partners/black/Loreal 3_static for black background.png';
import partner5BlackHover from './assets/images/partners/black/Loreal 4_hover for black background.png';
import partner6Black from './assets/images/partners/black/Mercedes 3_static for black background.png';
import partner6BlackHover from './assets/images/partners/black/Mercedes 4_hover for black background.png';
import partner7Black from './assets/images/partners/black/Rolex 3_static for black background.png';
import partner7BlackHover from './assets/images/partners/black/Rolex 4_hover for black background.png';
import partner8Black from './assets/images/partners/black/Samsung 3_static for black background.png';
import partner8BlackHover from './assets/images/partners/black/Samsung 4_hover for black background.png';
import partner9Black from './assets/images/partners/black/Shell 3_static for black background.png';
import partner9BlackHover from './assets/images/partners/black/Shell 4_hover for black background.png';
import partner10Black from './assets/images/partners/black/Tui 3_static for black background.png';
import partner10BlackHover from './assets/images/partners/black/Tui 4_hover for black background.png';
import partner11Black from './assets/images/partners/black/UBER 3_static for black background.png';
import partner11BlackHover from './assets/images/partners/black/UBER 4_hover for black background.png';
import uxAboutSliderIcon6 from './assets/icons/uxAboutSliderIcon6.png';
import uxAboutSliderIcon5 from './assets/icons/uxAboutSliderIcon5.png';
import uxAboutSliderIcon4 from './assets/icons/uxAboutSliderIcon4.png';
import uxAboutSliderIcon3 from './assets/icons/uxAboutSliderIcon3.png';
import uxAboutSliderIcon2 from './assets/icons/uxAboutSliderIcon2.png';
import uxAboutSliderIcon1 from './assets/icons/uxAboutSliderIcon1.png';

import uxAbout2SliderIcon4 from './assets/icons/uxAbout2SliderIcon4.png';
import uxAbout2SliderIcon3 from './assets/icons/uxAbout2SliderIcon3.png';
import uxAbout2SliderIcon2 from './assets/icons/uxAbout2SliderIcon2.png';
import uxAbout2SliderIcon1 from './assets/icons/uxAbout2SliderIcon1.png';

import uxTwoSolutionsIcon1 from './assets/icons/uxTwoSolutinsIcon1.png';
import uxTwoSolutionsIcon2 from './assets/icons/uxTwoSolutinsIcon2.png';
import uxTwoSolutionsIcon3 from './assets/icons/uxTwoSolutinsIcon3.png';
import priceMonitoringKeyIndicatorsListDataImg1 from './assets/images/priceMonitoringKeyIndicatorsListDataImg1.jpg';
import priceMonitoringKeyIndicatorsListDataImg2 from './assets/images/priceMonitoringKeyIndicatorsListDataImg2.jpg';
import priceMonitoringKeyIndicatorsListDataImg3 from './assets/images/priceMonitoringKeyIndicatorsListDataImg3.jpg';
import priceMonitoringKeyIndicatorsListDataImg4 from './assets/images/priceMonitoringKeyIndicatorsListDataImg4.jpg';

import contactsDataFlag1 from './assets/icons/IT.png';
import contactsDataFlag2 from './assets/icons/US.png';
import contactsDataFlag3 from './assets/icons/GB.png';
import contactsDataFlag4 from './assets/icons/CH.png';
import contactsDataFlag5 from './assets/icons/NL.png';
import contactsDataFlag6 from './assets/icons/SK.png';
import contactsDataFlag7 from './assets/icons/SI.png';
import contactsDataFlag8 from './assets/icons/TD.png';
import contactsDataFlag9 from './assets/icons/AZ.png';
import contactsDataFlag10 from './assets/icons/UA.png';
import contactsDataFlag11 from './assets/icons/KZ.png';
import contactsDataFlag12 from './assets/icons/EU.png';
import getTranslationsByLangOrEng from './utils/getTranslationsByLangOrLang';

export const menuMethodsItems = (lang: string): {name: string, url: string}[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'menu_1'),
    url: '/customer-journey-map',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_2'),
    url: '/net-promoter-score',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_3'),
    url: '/customer-satisfaction-index',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_4'),
    url: '/brand-health-tracking',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_5'),
    url: '/ux-testing',
  },
  {
    name: 'Advertising testing',
    url: '/ad-testing',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_6'),
    url: '/hr-research',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'main_65'),
    url: '/retail-audit',
  },
];

export const menuProductsItems = (lang: string): {name: string, url: string, otherSiteLink?: boolean, onlyInLanguages?: string[]}[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'menu_7'),
    url: '/qualitative-research',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_8'),
    url: '/quantitative-research',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_9'),
    url: '/mystery-shopping',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_21'),
    url: '/automotive-industry-price-analysis',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_10'),
    url: 'https://voicer.software/en',
    otherSiteLink: true,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_11'),
    url: 'https://4service.group/play4sales/',
    otherSiteLink: true,
    onlyInLanguages: ['uk'],
  },
  // {
  //   name: 'EMET',
  //   url: '/emet',
  // },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_12'),
    url: '/wantent',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_13'),
    url: '/price-monitoring',
  },
];

export const shoppersPlatformUrls = {
  uk: 'https://4serviceua.shopmetrics.com/auth/index.asp#signup',
  kz: 'https://4serviceua.shopmetrics.com/auth/index.asp#signup',
  en: 'https://scheduling.shopmetrics.com/auth/index.asp#signup',
  ro: 'https://4servicero.shopmetrics.com/auth/index.asp#signup',
  de: 'https://scheduling.shopmetrics.com/auth/index.asp#signup',
  fr: 'https://scheduling.shopmetrics.com/auth/index.asp#signup',
};

export const menuOtherItems = (lang: string): {name: string, url: string, toOtherSite?: boolean, shoppersPlatform?: boolean}[] => [
  // {
  //   name: 'CX-copter',
  //   url: '/',
  // },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_14'),
    url: '/about',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_15'),
    url: '/contacts',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_19'),
    url: 'https://4serviceua.shopmetrics.com/auth/index.asp#signup',
    toOtherSite: true,
    shoppersPlatform: true,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_16'),
    url: '/blog',
  },
  // {
  //   name: 'Career',
  //   url: '/career',
  // },
  {
    name: getTranslationsByLangOrEng(lang, 'cases_14'),
    url: '/cases',
  },
  {
    name: getTranslationsByLangOrEng(lang, 'menu_20'),
    url: '/social-responsibility',
  },
];

export const socialMediasMenu: { icon: any, url: string}[] = [
  {
    icon: LinkedinIcon,
    url: 'https://www.linkedin.com/company/4service-global/',
  },
  {
    icon: InstagramIcon,
    url: 'https://instagram.com/4serviceworldwide?igshid=YmMyMTA2M2Y=',
  },
  {
    icon: FacebookIcon,
    url: 'https://www.facebook.com/4ServiceWorldwide',
  },
];

export const partnerFooterIcons: {alt: string, icon: string }[] = [
  {
    alt: 'DT academy',
    icon: DTLogo,
  },
  {
    alt: 'MSPA',
    icon: MSPAlog,
  },
  {
    alt: 'Esomar',
    icon: esomarLogo,
  },
];

// Social Responsibility data
// TODO: add load more api path

export const socialResponsibilityEducationalActivitiesData = (lang: string): {
  pathWithId: string, id: string, title: string, subtitle: string, loadMoreApiPath: string, infoBlocksData: {title: string, description: string, image: string, path: string}[]
} => ({
  title: getTranslationsByLangOrEng(lang, 'social_responsibility_3'),
  subtitle: getTranslationsByLangOrEng(lang, 'social_responsibility_4'),
  loadMoreApiPath: '#',
  id: 'education',
  pathWithId: '/CSR#education',
  infoBlocksData: [
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_5'),
      description: `${getTranslationsByLangOrEng(lang, 'social_responsibility_6')} \n\n ${getTranslationsByLangOrEng(lang, 'social_responsibility_7')}`,
      image: socialResponsibilityEducationalActivitiesDataImage1,
      path: '#',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_8'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_9'),
      image: socialResponsibilityEducationalActivitiesDataImage2,
      path: '#',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_10'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_11'),
      image: socialResponsibilityEducationalActivitiesDataImage3,
      path: '#',
    },
  ],
});

export const socialResponsibilityCharityData = (lang: string): {
  pathWithId: string, id: string, title: string, subtitle: string, loadMoreApiPath: string, infoBlocksData: {title: string, description: string, image: string, path: string}[]
} => ({
  title: getTranslationsByLangOrEng(lang, 'social_responsibility_12'),
  id: 'charity',
  subtitle: getTranslationsByLangOrEng(lang, 'social_responsibility_13'),
  loadMoreApiPath: '#',
  pathWithId: '/CRS#charity',
  infoBlocksData: [
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_14'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_15'),
      image: socialResponsibilityCharityDataImage1,
      path: '#',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_16'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_17'),
      image: socialResponsibilityCharityDataImage2,
      path: '#',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_18'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_19'),
      image: socialResponsibilityCharityDataImage3,
      path: '#',
    },
  ],
});

const s = {

};

export const socialResponsibilityAnimalWelfareData = (lang: string): {
  pathWithId: string, id: string, title: string, subtitle: string, loadMoreApiPath: string, infoBlocksData: {title: string, description: string, image: string, path: string}[]
} => ({
  title: getTranslationsByLangOrEng(lang, 'social_responsibility_20'),
  id: 'animals',
  subtitle: getTranslationsByLangOrEng(lang, 'social_responsibility_21'),
  loadMoreApiPath: '#',
  pathWithId: '/CRS#animals',
  infoBlocksData: [
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_22'),
      description: `${getTranslationsByLangOrEng(lang, 'social_responsibility_23')}\n\n${getTranslationsByLangOrEng(lang, 'social_responsibility_24')}`,
      image: socialResponsibilityAnimalWelfareDataImage1,
      path: '#',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_25'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_26'),
      image: socialResponsibilityAnimalWelfareDataImage2,
      path: '#',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'social_responsibility_27'),
      description: getTranslationsByLangOrEng(lang, 'social_responsibility_28'),
      image: socialResponsibilityAnimalWelfareDataImage3,
      path: '#',
    },
  ],
});
// Social Responsibility data //

export const aboutUsSocialResponsibilityData = (lang: string): {title: string, subtitle: string, moreButtonLink: string, infoBlocksData: {title: string, description: string, image: string, path: string}[]} => ({
  title: getTranslationsByLangOrEng(lang, 'about_us_3'),
  subtitle: getTranslationsByLangOrEng(lang, 'about_us_4'),
  moreButtonLink: '/CRS',
  infoBlocksData: [
    {
      title: getTranslationsByLangOrEng(lang, 'about_us_5'),
      description: getTranslationsByLangOrEng(lang, 'about_us_6'),
      image: socialResponsibilityEducationalActivitiesDataImage1,
      path: '/CSR#education',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'about_us_7'),
      description: getTranslationsByLangOrEng(lang, 'about_us_8'),
      image: socialResponsibilityCharityDataImage1,
      path: '/CSR#charity',
    },
    {
      title: getTranslationsByLangOrEng(lang, 'about_us_9'),
      description: getTranslationsByLangOrEng(lang, 'about_us_10'),
      image: socialResponsibilityAnimalWelfareDataImage1,
      path: '/CSR#animals',
    },
  ],
});

// About us Page

export const aboutUsHistoryData = (lang: string) : {year: number, description: string}[] => ([
  {
    year: 2001,
    description: getTranslationsByLangOrEng(lang, 'about_us_16'),
  },
  {
    year: 2006,
    description: getTranslationsByLangOrEng(lang, 'about_us_17'),
  },
  {
    year: 2014,
    description: getTranslationsByLangOrEng(lang, 'about_us_18'),
  },
  {
    year: 2015,
    description: getTranslationsByLangOrEng(lang, 'about_us_19'),
  },
  {
    year: 2016,
    description: getTranslationsByLangOrEng(lang, 'about_us_20'),
  },
  {
    year: 2017,
    description: getTranslationsByLangOrEng(lang, 'about_us_21'),
  },
  {
    year: 2019,
    description: getTranslationsByLangOrEng(lang, 'about_us_22'),
  },
  {
    year: 2020,
    description: getTranslationsByLangOrEng(lang, 'about_us_23'),
  },
]);

export const personalRoles = (lang: string): {[key: string]: {key: string, name: string}} => ({
  1: {
    key: '1',
    name: getTranslationsByLangOrEng(lang, 'about_us_roles_1'),
  },
  2: {
    key: '2',
    name: getTranslationsByLangOrEng(lang, 'about_us_roles_2'),
  },
  // 3: {
  //   key: '3',
  //   name: getTranslationsByLangOrEng(lang, 'about_us_roles_3'),
  // },
});

export const teamPersonalData = (lang: string): {image: string, name: string, position: string, email: string, phone: string, positionTag: string, linkedin: string, geo: string[]}[] => ([
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_55'),
    image: personalPhoto2,
    email: 'global@4service-group.com',
    phone: '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_59'),
    positionTag: '1',
    linkedin: '#',
    geo: ['uk', 'kz'],
  },
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_56'),
    image: personalPhoto3,
    email: 'global@4service-group.com',
    phone: '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_59'),
    positionTag: '1',
    linkedin: '#',
    geo: ['uk', 'kz'],
  },
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_60'),
    image: personalPhoto4,
    email: 'a.andreeva@4service-group.com',
    phone: '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_61'),
    positionTag: '2',
    linkedin: '#',
    geo: ['en', 'fr', 'ro', 'de', 'uk', 'kz'],
  },
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_58'),
    image: personalPhoto5,
    email: 'global@4service-group.com',
    phone: '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_59'),
    positionTag: '1',
    linkedin: '#',
    geo: ['uk', 'kz'],
  },
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_62'),
    image: personalPhoto6,
    email: 'j.kravchenko@4service-group.com',
    phone: (lang === 'en' || lang === 'fr' || lang === 'de') ? '' : '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_63'),
    positionTag: '2',
    linkedin: '#',
    geo: ['uk', 'kz', 'en', 'fr', 'de'],
  },
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_64'),
    image: personalPhoto7,
    email: 'o.aksonova@4service-group.com',
    phone: '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_65'),
    positionTag: '2',
    linkedin: '#',
    geo: ['uk', 'kz', 'en', 'fr', 'de'],
  },
  {
    name: getTranslationsByLangOrEng(lang, 'about_us_66'),
    image: personalPhoto8,
    email: 'd.zhelevskiy@4service-group.com',
    phone: '+4314120126',
    position: getTranslationsByLangOrEng(lang, 'about_us_67'),
    positionTag: '2',
    linkedin: '#',
    geo: ['uk', 'kz', 'en', 'fr', 'de'],
  },
  {
    name: 'Alina Ruse',
    image: personalPhoto9,
    email: 'a.ruse@4service-group.com',
    phone: '+40751628156',
    position: getTranslationsByLangOrEng(lang, 'about_us_63'),
    positionTag: '1',
    linkedin: '#',
    geo: ['ro'],
  },
  {
    name: 'George Dinu',
    image: personalPhoto10,
    email: 'g.dinu@4service-group.com',
    phone: '+40732866521',
    position: 'Research & Analytics manager',
    positionTag: '1',
    linkedin: '#',
    geo: ['ro'],
  },
  {
    name: 'Katerina Levitski',
    image: personalPhoto11,
    email: 'k.levitski@4service-group.com',
    phone: '+40742113017',
    position: 'Client Service manager',
    positionTag: '1',
    linkedin: '#',
    geo: ['ro'],
  },
  {
    name: 'Bianca Ardelean',
    image: personalPhoto12,
    email: 'b.ardelean@4service-group.com',
    phone: '+40721271355',
    position: 'Project manager',
    positionTag: '1',
    linkedin: '#',
    geo: ['en', 'fr', 'de'],
  },
]);

export const aboutUsMapTextData = (lang: string): {title: string, description: string}[] => ([
  {
    title: '95',
    description: getTranslationsByLangOrEng(lang, 'about_us_30'),
  },
  {
    title: '1 500 000+',
    description: getTranslationsByLangOrEng(lang, 'about_us_31'),
  },
  {
    title: 'Top-10',
    description: getTranslationsByLangOrEng(lang, 'about_us_32'),
  },
  {
    title: '7',
    description: getTranslationsByLangOrEng(lang, 'about_us_33'),
  },
  {
    title: '400+',
    description: getTranslationsByLangOrEng(lang, 'about_us_34'),
  },
]);

export const aboutUsPartnersSliderData = (lang: string): {link: string, name: string, description: string, image: string, imageSVG?: any}[] => ([
  {
    name: 'DesignThinkers Academy',
    description: `${getTranslationsByLangOrEng(lang, 'about_us_45')
    }\n\n${
      getTranslationsByLangOrEng(lang, 'about_us_46')}`,
    image: aboutUsPartnerImage1,
    imageSVG: LogoDT,
    link: 'https://www.designthinkersacademy.com/',
  },
  {
    name: 'MSPA',
    description: getTranslationsByLangOrEng(lang, 'about_us_47'),
    image: aboutUsPartnerImage2,
    link: '',
  },
  {
    name: 'ESOMAR',
    description: `${getTranslationsByLangOrEng(lang, 'about_us_48')
    }\n\n${
      getTranslationsByLangOrEng(lang, 'about_us_49')}`,
    image: aboutUsPartnerImage3,
    link: 'https://esomar.org/',
  },
  {
    name: 'The Ukrainian Contact Center Association',
    description: `${getTranslationsByLangOrEng(lang, 'about_us_50')
    }\n\n${
      getTranslationsByLangOrEng(lang, 'about_us_51')}`,
    image: aboutUsPartnerImage4,
    link: 'https://uccai.com.ua/',
  },
  {
    name: 'Academy DTEK',
    description: `${getTranslationsByLangOrEng(lang, 'about_us_52')
    }\n\n${
      getTranslationsByLangOrEng(lang, 'about_us_53')}`,
    image: aboutUsPartnerImage5,
    imageSVG: LogoDTEK,
    link: 'https://dtek.com/',
  },
]);

// About us page End //

// Mystery Shopping page
export const mysteryShoppingAboutUsData = (lang: string): { title: string, description: string }[] => [
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_123'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_6'),
  },
  {
    title: '> 100 000+',
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_7'),
  },
  {
    title: '> 20 000+',
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_8'),
  },
  {
    title: '400+',
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_9'),
  },
  {
    title: '7',
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_10'),
  },
  {
    title: 'Top-10',
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_11'),
  },
  {
    title: '1 500 000+',
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_12'),
  },
];

export const mysteryShoppingKeyFeaturesSectionData1 = (lang: string): {
  title: string;
  subtitle: string;
  listCaption: string;
  listData: {icon: any, description: string}[];
} => ({
  title: getTranslationsByLangOrEng(lang, 'mystery_shopping_84'),
  subtitle: getTranslationsByLangOrEng(lang, 'mystery_shopping_85'),
  listCaption: getTranslationsByLangOrEng(lang, 'mystery_shopping_86'),
  listData: [
    {
      icon: MysteryShoppingKeyFeaturesIcon1,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_87'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon2,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_88'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon3,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_89'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon4,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_90'),
    },
  ],
});

export const mysteryShoppingKeyFeaturesSectionData2 = (lang: string): {
  title: string;
  subtitle: string;
  listCaption: string;
  listData: {icon: any, description: string}[];
} => ({
  title: getTranslationsByLangOrEng(lang, 'mystery_shopping_91'),
  subtitle: getTranslationsByLangOrEng(lang, 'mystery_shopping_92'),
  listCaption: getTranslationsByLangOrEng(lang, 'mystery_shopping_93'),
  listData: [
    {
      icon: MysteryShoppingKeyFeaturesIcon5,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_94'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon6,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_95'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon7,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_96'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon8,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_97'),
    },
  ],
});

export const mysteryShoppingKeyFeaturesSectionData3 = (lang: string): {
  title: string;
  subtitle: string;
  listCaption: string;
  listData: {icon: any, description: string}[];
} => ({
  title: getTranslationsByLangOrEng(lang, 'mystery_shopping_98'),
  subtitle: getTranslationsByLangOrEng(lang, 'mystery_shopping_99'),
  listCaption: getTranslationsByLangOrEng(lang, 'mystery_shopping_100'),
  listData: [
    {
      icon: MysteryShoppingKeyFeaturesIcon9,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_101'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon10,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_102'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon11,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_103'),
    },
    {
      icon: MysteryShoppingKeyFeaturesIcon12,
      description: getTranslationsByLangOrEng(lang, 'mystery_shopping_104'),
    },
  ],
});

export const typesOfMysteryShoppingData = (lang: string): {title: string, description: string, image: string, wrappedSheetPosition: string}[] => [
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_107'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_108'),
    image: typesOfMysteryShoppingImage1,
    wrappedSheetPosition: 'bottomLeft',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_109'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_110'),
    image: typesOfMysteryShoppingImage2,
    wrappedSheetPosition: 'topRight',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_111'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_112'),
    image: typesOfMysteryShoppingImage3,
    wrappedSheetPosition: 'topLeft',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_113'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_114'),
    image: typesOfMysteryShoppingImage4,
    wrappedSheetPosition: 'topRight',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_115'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_116'),
    image: typesOfMysteryShoppingImage5,
    wrappedSheetPosition: 'bottomRight',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_117'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_118'),
    image: typesOfMysteryShoppingImage6,
    wrappedSheetPosition: 'topRight',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_119'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_120'),
    image: typesOfMysteryShoppingImage7,
    wrappedSheetPosition: 'bottomRight',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping_121'),
    description: getTranslationsByLangOrEng(lang, 'mystery_shopping_122'),
    image: typesOfMysteryShoppingImage8,
    wrappedSheetPosition: 'topLeft',
  },
];

// Mystery Shopping page End //

// Main page start

export const mainPageBlogData: IBlogItem[] = [
  {
    title: 'What is Service Design and Design Thinking?',
    description: 'If you are familiar with the terms Service design and Design Thinking but do not '
      + 'have a clear understanding of these processes then this article is for you. Have you ever...',
    hashtag: ['#Marketing_Technologies_and_Tools'],
    link: '',
    image: blogImage1,
  },
  {
    title: 'What is Service Design and Design Thinking?',
    description: 'If you are familiar with the terms Service design and Design Thinking but do not '
      + 'have a clear understanding of these processes then this article is for you. Have you ever...',
    hashtag: ['#Marketing_Technologies_and_Tools'],
    link: '',
    image: blogImage2,
  },
  {
    title: 'What is Service Design and Design Thinking?',
    description: 'If you are familiar with the terms Service design and Design Thinking but do not '
      + 'have a clear understanding of these processes then this article is for you. Have you ever...',
    hashtag: ['#Marketing_Technologies_and_Tools'],
    link: '',
    image: blogImage1,
  },
];

export const mainPageBallsData = (lang: string): {border: string, text: string}[] => [
  {
    border: '#0000FF',
    text: getTranslationsByLangOrEng(lang, 'main_52'),
  },
  {
    border: '#EE2752',
    text: getTranslationsByLangOrEng(lang, 'main_53'),
  },
  {
    border: '#0000FF',
    text: getTranslationsByLangOrEng(lang, 'main_54'),
  },
  {
    border: '#EE2752',
    text: getTranslationsByLangOrEng(lang, 'main_55'),
  },
  {
    border: '#0000FF',
    text: getTranslationsByLangOrEng(lang, 'main_56'),
  },
  {
    border: '#6220EF',
    text: getTranslationsByLangOrEng(lang, 'main_57'),
  },
  {
    border: '#0000FF',
    text: getTranslationsByLangOrEng(lang, 'main_58'),
  },
  {
    border: '#6220EF',
    text: getTranslationsByLangOrEng(lang, 'main_59'),
  },
  {
    border: '#0000FF',
    text: getTranslationsByLangOrEng(lang, 'main_60'),
  },
  {
    border: '#6220EF',
    text: getTranslationsByLangOrEng(lang, 'main_61'),
  },
  {
    border: '#0000FF',
    text: getTranslationsByLangOrEng(lang, 'main_62'),
  },
];

export const mainPageMethodologiesData = (lang: string): {caption: string, title: string, description: string, background: string, image: string, imageTablet: string, link: string, anotherSite?: boolean}[] => [
  {
    title: getTranslationsByLangOrEng(lang, 'main_15'),
    description: getTranslationsByLangOrEng(lang, 'main_16'),
    caption: getTranslationsByLangOrEng(lang, 'main_17'),
    background: 'linear-gradient(45deg, #EB2E4B 0%, #6220EF 123.24%), linear-gradient(91.15deg, #EB2E4B -6.99%, #6220EF 205.96%)',
    image: methodologies1,
    imageTablet: methodologies1Tablet,
    link: '/customer-journey-map',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'main_18'),
    description: getTranslationsByLangOrEng(lang, 'main_19'),
    caption: getTranslationsByLangOrEng(lang, 'main_20'),
    background: 'linear-gradient(45deg, #2298FF 3.42%, #3EC290 100%)',
    image: methodologies2,
    imageTablet: methodologies2Tablet,
    link: '/net-promoter-score',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'main_21'),
    description: getTranslationsByLangOrEng(lang, 'main_22'),
    caption: getTranslationsByLangOrEng(lang, 'main_23'),
    background: 'linear-gradient(45deg, #6C33F1 0%, #F26B35 54.17%, #F8B01D 100%)',
    image: methodologies3,
    imageTablet: methodologies3Tablet,
    link: '/brand-health-tracking',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'main_24'),
    description: getTranslationsByLangOrEng(lang, 'main_25'),
    caption: getTranslationsByLangOrEng(lang, 'main_26'),
    background: 'linear-gradient(45deg, #7622FF 0%, #B73EC2 100%)',
    image: methodologies4,
    imageTablet: methodologies4Tablet,
    link: '/hr-research',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'main_27'),
    description: getTranslationsByLangOrEng(lang, 'main_28'),
    caption: getTranslationsByLangOrEng(lang, 'main_29'),
    background: 'linear-gradient(225.55deg, #8640F9 -0.98%, #0029FF 49.46%, #002050 100%)',
    image: lang === 'ro' ? methodologies5ro : methodologies5,
    imageTablet: methodologies5Tablet,
    link: '/customer-satisfaction-index',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'main_30'),
    description: getTranslationsByLangOrEng(lang, 'main_31'),
    caption: getTranslationsByLangOrEng(lang, 'main_30'),
    background: 'linear-gradient(40.49deg, rgba(24, 24, 27, 0.7) 0.43%, rgba(138, 138, 142, 0.7) 100%)',
    image: lang === 'ro' ? methodologies6ro : methodologies6,
    imageTablet: lang === 'ro' ? methodologies6ro : methodologies6,
    link: '/ux-testing',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'ad_testing_1'),
    description: getTranslationsByLangOrEng(lang, 'main_11'),
    caption: getTranslationsByLangOrEng(lang, 'ad_testing_1'),
    background: 'linear-gradient(221.17deg, #FDA272 0%, #35C879 64.9%, #00A3FF 100%)',
    image: methodologies8,
    imageTablet: methodologies8,
    link: '/ad-testing',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'main_65'),
    description: getTranslationsByLangOrEng(lang, 'main_66'),
    caption: getTranslationsByLangOrEng(lang, 'main_65'),
    background: 'linear-gradient(86.71deg, #420710 9.17%, rgba(33, 33, 33, 0.72) 100%)',
    image: methodologies7,
    imageTablet: methodologies7,
    link: '/retail-audit',
  },
];

export const mainPageProductsData = (lang: string): {caption: string, title: string, description: string, background: string, image: string, imageTablet: string, link: string, anotherSite?: boolean, onlyInLanguages?: string[]}[] => [
  {
    title: getTranslationsByLangOrEng(lang, 'qualitative_research'),
    description: getTranslationsByLangOrEng(lang, 'main_5'),
    caption: getTranslationsByLangOrEng(lang, 'qualitative_research'),
    background: 'linear-gradient(225.73deg, #00FFB6 -1.31%, #48A6E0 30.61%, #9A40C6 100%)',
    image: products1,
    imageTablet: products1Tablet,
    link: '/qualitative-research',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'quantitative_research'),
    description: getTranslationsByLangOrEng(lang, 'main_6'),
    caption: getTranslationsByLangOrEng(lang, 'quantitative_research'),
    background: 'linear-gradient(227.07deg, #FDA272 0%, #C835B6 42.19%, #0066FF 100%)',
    image: products2,
    imageTablet: products2Tablet,
    link: '/quantitative-research',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'mystery_shopping'),
    description: getTranslationsByLangOrEng(lang, 'main_7'),
    caption: getTranslationsByLangOrEng(lang, 'mystery_shopping'),
    background: 'linear-gradient(87.34deg, #0500FF 0%, #00D1FF 100%)',
    image: lang === 'ro' ? products3ro : products3,
    imageTablet: lang === 'ro' ? products3ro : products3Tablet,
    link: '/mystery-shopping',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'voicer'),
    description: getTranslationsByLangOrEng(lang, 'main_8'),
    caption: getTranslationsByLangOrEng(lang, 'voicer'),
    background: 'linear-gradient(225deg, #883EEC 0%, #7700A0 100%)',
    image: products4,
    imageTablet: products4Tablet,
    link: 'https://voicer.software/en',
    anotherSite: true,
  },
  {
    title: getTranslationsByLangOrEng(lang, 'wantent'),
    description: getTranslationsByLangOrEng(lang, 'main_9'),
    caption: getTranslationsByLangOrEng(lang, 'wantent'),
    background: 'linear-gradient(227.07deg, #28B5C9 0%, #091638 100%), linear-gradient(195.13deg, #00FFE0 -1.7%, #6470FF 51.7%, #FF50ED 104%), linear-gradient(225deg, #883EEC 0%, #7700A0 100%)',
    image: products6,
    imageTablet: products6Tablet,
    link: '/wantent',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'play4sales'),
    description: getTranslationsByLangOrEng(lang, 'main_10'),
    caption: getTranslationsByLangOrEng(lang, 'play4sales'),
    background: 'linear-gradient(195.13deg, #00FFE0 -1.7%, #6470FF 51.7%, #FF50ED 104%), linear-gradient(225deg, #883EEC 0%, #7700A0 100%)',
    image: products5,
    imageTablet: products5Tablet,
    link: 'https://4service.group/play4sales/',
    anotherSite: true,
    onlyInLanguages: ['uk'],
  },
  {
    title: getTranslationsByLangOrEng(lang, 'price_monitoring'),
    description: getTranslationsByLangOrEng(lang, 'main_11'),
    caption: getTranslationsByLangOrEng(lang, 'price_monitoring'),
    background: 'linear-gradient(56.34deg, #ED43E6 0%, #FF4F5E 51.56%, #FAC863 100%)',
    image: products7,
    imageTablet: products7,
    link: '/price-monitoring',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'service_design'),
    description: getTranslationsByLangOrEng(lang, 'main_12'),
    caption: getTranslationsByLangOrEng(lang, 'service_design'),
    background: 'linear-gradient(226.69deg, #E8B500 7.4%, #FFF500 100%)',
    image: lang === 'ro' ? products8ro : products8,
    imageTablet: lang === 'ro' ? products8ro : products8,
    link: 'https://scheduling-europe.com/service/customer-experience-services/service-design/',
    anotherSite: true,
  },
];

// Main page End //

export const methodsHRSolutionsTextBlockInfo1 = (lang: string): IMethodsHRSolutionsTextBlockInfo => ({
  caption: getTranslationsByLangOrEng(lang, 'methods_hr_24'),
  title: getTranslationsByLangOrEng(lang, 'methods_hr_25'),
  listTitle: getTranslationsByLangOrEng(lang, 'methods_hr_26'),
  firstListData: [
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_27'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_28'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_29'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_30'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_31'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_32'),
    },
  ],
  secondListData: [
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_33'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_34'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_35'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_36'),
    },
  ],
});

export const methodsHRSolutionsTextBlockInfo2 = (lang: string): IMethodsHRSolutionsTextBlockInfo => ({
  caption: getTranslationsByLangOrEng(lang, 'methods_hr_38'),
  title: getTranslationsByLangOrEng(lang, 'methods_hr_39'),
  firstListData: [
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_40'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_41'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_42'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_43'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_44'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_45'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_46'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_44'),
    },
  ],
  secondListData: [
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_47'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_48'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_49'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_50'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_51'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_52'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_53'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_54'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_55'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_56'),
    },
  ],
});

export const methodsHRBallsData = (lang: string): {border: string, text: string}[] => [
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_9'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_10'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_11'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_12'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_13'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_14'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_15'),
  },
  {
    border: '#7622FF',
    text: getTranslationsByLangOrEng(lang, 'methods_hr_16'),
  },
];

export const methodsHRSolutionsTextBlockInfo3 = (lang: string): IMethodsHRSolutionsTextBlockInfo => ({
  caption: getTranslationsByLangOrEng(lang, 'methods_hr_57'),
  title: getTranslationsByLangOrEng(lang, 'methods_hr_58'),
  firstListData: [
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_59'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_60'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_61'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_62'),
    },
  ],
  secondListData: [
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_63'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_64'),
    },
    {
      boldPartOfDescription: getTranslationsByLangOrEng(lang, 'methods_hr_65'),
      description: getTranslationsByLangOrEng(lang, 'methods_hr_66'),
    },
  ],
});

// Cases

export const mainPageCasesData = (lang: string): {title: string, image: string, link: string, captions: string[], hashtags: string[]}[] => [
  {
    title: getTranslationsByLangOrEng(lang, 'cases_1'),
    link: '#',
    image: cases1,
    captions: [getTranslationsByLangOrEng(lang, 'cases_2'), getTranslationsByLangOrEng(lang, 'cases_3'), getTranslationsByLangOrEng(lang, 'cases_4')],
    hashtags: [getTranslationsByLangOrEng(lang, 'hashtag_automotive')],
  },
  {
    title: getTranslationsByLangOrEng(lang, 'cases_5'),
    link: '#',
    image: cases2,
    captions: [getTranslationsByLangOrEng(lang, 'cases_6'), getTranslationsByLangOrEng(lang, 'cases_7'), getTranslationsByLangOrEng(lang, 'cases_8')],
    hashtags: [getTranslationsByLangOrEng(lang, 'hashtag_retail')],
  },
  {
    title: getTranslationsByLangOrEng(lang, 'cases_9'),
    link: '#',
    image: cases3,
    captions: [getTranslationsByLangOrEng(lang, 'cases_10'), getTranslationsByLangOrEng(lang, 'cases_11'), getTranslationsByLangOrEng(lang, 'cases_12'), getTranslationsByLangOrEng(lang, 'cases_13')],
    hashtags: [getTranslationsByLangOrEng(lang, 'hashtag_retail')],
  },
];

// Cases End //

export const headerColorThemes: {[key: string]: {backgroundColorWhenMenuActive?: string, burgerMenuColor: string, langMenuTextColor: string, sloganTextColor: string, logoTextColor: string, menuTextColor: string, menuIconsColor: string, menuBackgroundColor: string, backgroundColor: string}} = {
  lightTheme: {
    burgerMenuColor: '#000000',
    langMenuTextColor: '#000000',
    sloganTextColor: '#EE2752',
    logoTextColor: '#000000',
    menuTextColor: '#000000',
    menuIconsColor: '#B8B8B8',
    menuBackgroundColor: '#FFF',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  darkTheme: {
    burgerMenuColor: '#FFFFFF',
    langMenuTextColor: '#FFFFFF',
    sloganTextColor: '#EE2752',
    logoTextColor: '#FFFFFF',
    menuTextColor: '#FFFFFF',
    menuIconsColor: '#FFFFFF',
    menuBackgroundColor: '#0000FF',

    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backgroundColorWhenMenuActive: '#0000FF',
  },
};

export const pageGradientColor: {[key: string]: string} = {
  netpromotionscore: 'linear-gradient(45deg, #2298FF 3.42%, #3EC290 100%)',
};

export const mainAndGradientPageColors: {[key: string] : { gradient: string, color: string}} = {
  mysteryShopping: {
    gradient: 'background: linear-gradient(87.34deg, rgba(5, 0, 255, 0.64) 0%, rgba(0, 209, 255, 0.36) 100%);',
    color: '#0000FF',
  },
};

export const pagesWithBlackTheme: string[] = [
  'social-responsibility', 'about', 'cases', 'wantent', 'privacy-policy', 'terms-and-conditions', 'thank-you', 'blog', 'contacts',
];

export const pagesWithLightTheme: string[] = [
  'mysteryshopping',
];

export const CJMStickersByLang = (lang: string): {imageDesktop: string; imageTablet: string; imageMobile: string}[] => ([
  {
    imageDesktop: lang === 'uk' ? CJMStickerDesktop1UA : CJMStickerDesktop1,
    imageTablet: lang === 'uk' ? CJMStickerTablet1UA : CJMStickerTablet1,
    imageMobile: lang === 'uk' ? CJMStickerMobile1UA : CJMStickerMobile1,
  },
  {
    imageDesktop: lang === 'uk' ? CJMStickerDesktop2UA : CJMStickerDesktop2,
    imageTablet: lang === 'uk' ? CJMStickerTablet2UA : CJMStickerTablet2,
    imageMobile: lang === 'uk' ? CJMStickerMobile2UA : CJMStickerMobile2,
  },
  {
    imageDesktop: lang === 'uk' ? CJMStickerDesktop3UA : CJMStickerDesktop3,
    imageTablet: lang === 'uk' ? CJMStickerTablet3UA : CJMStickerTablet3,
    imageMobile: lang === 'uk' ? CJMStickerMobile3UA : CJMStickerMobile3,
  },
  {
    imageDesktop: lang === 'uk' ? CJMStickerDesktop4UA : CJMStickerDesktop4,
    imageTablet: lang === 'uk' ? CJMStickerTablet4UA : CJMStickerTablet4,
    imageMobile: lang === 'uk' ? CJMStickerMobile4UA : CJMStickerMobile4,
  },
  {
    imageDesktop: lang === 'uk' ? CJMStickerDesktop5UA : CJMStickerDesktop5,
    imageTablet: lang === 'uk' ? CJMStickerTablet5UA : CJMStickerTablet5,
    imageMobile: lang === 'uk' ? CJMStickerMobile5UA : CJMStickerMobile5,
  },
]);

export const qualitativeResearchesSolutions1Data = (lang: string): IQualitativeResearchesSolutionsProps => ({
  video: qualitativeResearchesSolutionsVideo1,
  isOnlineTextInTitle: true,
  listData: [
    getTranslationsByLangOrEng(lang, 'qualitative_research_18'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_19'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_20'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_21'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_22'),
  ],
  listTitle: getTranslationsByLangOrEng(lang, 'qualitative_research_23'),
  title: getTranslationsByLangOrEng(lang, 'qualitative_research_24'),
  stickers: [
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions1Sticker1DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker1DesktopUA : qualitativeResearchesSolutions1Sticker1Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions1Sticker1TabletRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker1TabletUA : qualitativeResearchesSolutions1Sticker1Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions1Sticker1MobileRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker1MobileUA : qualitativeResearchesSolutions1Sticker1Mobile,
    },
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions1Sticker2DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker2DesktopUA : qualitativeResearchesSolutions1Sticker2Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions1Sticker2TabletRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker2TabletUA : qualitativeResearchesSolutions1Sticker2Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions1Sticker2MobileRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker2MobileUA : qualitativeResearchesSolutions1Sticker2Mobile,
    },
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions1Sticker3DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker3DesktopUA : qualitativeResearchesSolutions1Sticker3Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions1Sticker3TabletRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker3TabletUA : qualitativeResearchesSolutions1Sticker3Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions1Sticker3MobileRo : lang === 'uk' ? qualitativeResearchesSolutions1Sticker3MobileUA : qualitativeResearchesSolutions1Sticker3Mobile,
    },
  ],
  background: '#9E41E8',
  videoMaskColor: 'linear-gradient(180deg, rgba(158, 65, 232, 0) 20.83%, #9E41E8 82.25%)',
  description: getTranslationsByLangOrEng(lang, 'qualitative_research_25'),
  className: 'solutions1',
  caption: `${getTranslationsByLangOrEng(lang, 'solutions')} 1`,
  videoCaption: getTranslationsByLangOrEng(lang, 'qualitative_research_26'),
  dotColor: 'linear-gradient(241.95deg, #00FFB6 12.86%, #22D5CA 79.71%), linear-gradient(267.46deg, #00FFB6 -5.91%, #48A6E0 25.46%, #9A40C6 93.66%)',
});

export const qualitativeResearchesSolutions2Data = (lang: string): IQualitativeResearchesSolutionsProps => ({
  video: qualitativeResearchesSolutionsVideo2,
  isOnlineTextInTitle: true,
  listData: [
    getTranslationsByLangOrEng(lang, 'qualitative_research_27'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_28'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_29'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_30'),
  ],
  listTitle: getTranslationsByLangOrEng(lang, 'qualitative_research_31'),
  title: getTranslationsByLangOrEng(lang, 'qualitative_research_32'),
  stickers: [
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions2Sticker1DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker1DesktopUA : qualitativeResearchesSolutions2Sticker1Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions2Sticker1TabletRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker1TabletUA : qualitativeResearchesSolutions2Sticker1Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions2Sticker1MobileRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker1MobileUA : qualitativeResearchesSolutions2Sticker1Mobile,
    },
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions2Sticker2DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker2DesktopUA : qualitativeResearchesSolutions2Sticker2Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions2Sticker2TabletRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker2TabletUA : qualitativeResearchesSolutions2Sticker2Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions2Sticker2MobileRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker2MobileUA : qualitativeResearchesSolutions2Sticker2Mobile,
    },
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions2Sticker3DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker3DesktopUA : qualitativeResearchesSolutions2Sticker3Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions2Sticker3TabletRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker3TabletUA : qualitativeResearchesSolutions2Sticker3Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions2Sticker3MobileRo : lang === 'uk' ? qualitativeResearchesSolutions2Sticker3MobileUA : qualitativeResearchesSolutions2Sticker3Mobile,
    },
  ],
  background: '#48AFEF',
  videoMaskColor: 'linear-gradient(180deg, rgba(72, 175, 239, 0) 0%, #48AFEF 82.25%)',
  description: `${getTranslationsByLangOrEng(lang, 'qualitative_research_33')}\n\n${getTranslationsByLangOrEng(lang, 'qualitative_research_34')}`,
  className: 'solutions2',
  caption: `${getTranslationsByLangOrEng(lang, 'solutions')} 2`,
  videoCaption: getTranslationsByLangOrEng(lang, 'qualitative_research_36'),
  dotColor: 'linear-gradient(34.43deg, #9A1AFF 7.38%, #9E41E8 89.82%), linear-gradient(241.95deg, #00FFB6 12.86%, #22D5CA 79.71%), linear-gradient(267.46deg, #00FFB6 -5.91%, #48A6E0 25.46%, #9A40C6 93.66%)',
});

export const qualitativeResearchesSolutions3Data = (lang: string): IQualitativeResearchesSolutionsProps => ({
  video: qualitativeResearchesSolutionsVideo3,
  isOnlineTextInTitle: false,
  listData: [
    getTranslationsByLangOrEng(lang, 'qualitative_research_37'),
    getTranslationsByLangOrEng(lang, 'qualitative_research_38'),
  ],
  listTitle: getTranslationsByLangOrEng(lang, 'qualitative_research_39'),
  title: getTranslationsByLangOrEng(lang, 'qualitative_research_40'),
  stickers: [
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions3Sticker1DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker1DesktopUA : qualitativeResearchesSolutions3Sticker1Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions3Sticker1TabletRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker1TabletUA : qualitativeResearchesSolutions3Sticker1Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions3Sticker1MobileRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker1MobileUA : qualitativeResearchesSolutions3Sticker1Mobile,
    },
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions3Sticker2DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker2DesktopUA : qualitativeResearchesSolutions3Sticker2Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions3Sticker2TabletRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker2TabletUA : qualitativeResearchesSolutions3Sticker2Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions3Sticker2MobileRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker2MobileUA : qualitativeResearchesSolutions3Sticker2Mobile,
    },
    {
      desktop: lang === 'ro' ? qualitativeResearchesSolutions3Sticker3DesktopRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker3DesktopUA : qualitativeResearchesSolutions3Sticker3Desktop,
      tablet: lang === 'ro' ? qualitativeResearchesSolutions3Sticker3TabletRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker3TabletUA : qualitativeResearchesSolutions3Sticker3Tablet,
      mobile: lang === 'ro' ? qualitativeResearchesSolutions3Sticker3MobileRo : lang === 'uk' ? qualitativeResearchesSolutions3Sticker3MobileUA : qualitativeResearchesSolutions3Sticker3Mobile,
    },
  ],
  dotColor: 'linear-gradient(34.43deg, #9A1AFF 7.38%, #9E41E8 89.82%), linear-gradient(241.95deg, #00FFB6 12.86%, #22D5CA 79.71%), linear-gradient(267.46deg, #00FFB6 -5.91%, #48A6E0 25.46%, #9A40C6 93.66%)',
  background: '#00FFB6',
  videoMaskColor: 'linear-gradient(180deg, rgba(0, 255, 182, 0) 43.81%, #00FFB6 90.03%)',
  description: `${getTranslationsByLangOrEng(lang, 'qualitative_research_41')}\n\n${getTranslationsByLangOrEng(lang, 'qualitative_research_42')}`,
  className: 'solutions3',
  caption: `${getTranslationsByLangOrEng(lang, 'solutions')} 3`,
  videoCaption: getTranslationsByLangOrEng(lang, 'qualitative_research_43'),
  subtitle: getTranslationsByLangOrEng(lang, 'qualitative_research_44'),
  listTextColor: '#000',
});

export const NPShreeItemsGreedListWithGifAndDescriptionData = (lang: string): IThreeItemsGreedListWithGifAndDescription => ({
  title: getTranslationsByLangOrEng(lang, 'nps_22'),
  bookmarkColor: 'linear-gradient(45deg, #2298FF 3.42%, #3EC290 100%)',
  listData: [
    {
      gif: gifThreeItemsGrid1,
      description: getTranslationsByLangOrEng(lang, 'nps_23'),
      title: getTranslationsByLangOrEng(lang, 'nps_24'),
    },
    {
      gif: gifThreeItemsGrid2,
      description: getTranslationsByLangOrEng(lang, 'nps_25'),
      title: getTranslationsByLangOrEng(lang, 'nps_26'),
    },
    {
      gif: gifThreeItemsGrid3,
      description: getTranslationsByLangOrEng(lang, 'nps_27'),
      title: getTranslationsByLangOrEng(lang, 'nps_28'),
    },
  ],
});

export const qualitativeResearchesThreeItemsGreedListWithGifAndDescriptionData = (lang: string): IThreeItemsGreedListWithGifAndDescription => ({
  title: getTranslationsByLangOrEng(lang, 'qualitative_research_45'),
  bookmarkColor: 'linear-gradient(225.73deg, #00FFB6 -1.31%, #48A6E0 30.61%, #9E41E8 100%)',
  caption: getTranslationsByLangOrEng(lang, 'why_us'),
  listData: [
    {
      gif: gifThreeItemsGrid4,
      description: getTranslationsByLangOrEng(lang, 'qualitative_research_46'),
      title: getTranslationsByLangOrEng(lang, 'qualitative_research_47'),
    },
    {
      gif: gifThreeItemsGrid5,
      description: getTranslationsByLangOrEng(lang, 'qualitative_research_48'),
      title: getTranslationsByLangOrEng(lang, 'qualitative_research_49'),
    },
    {
      gif: gifThreeItemsGrid6,
      description: `${getTranslationsByLangOrEng(lang, 'qualitative_research_50')}\n`
        + `${getTranslationsByLangOrEng(lang, 'qualitative_research_51')}\n${
          getTranslationsByLangOrEng(lang, 'qualitative_research_52')}`,
      title: getTranslationsByLangOrEng(lang, 'qualitative_research_53'),
    },
  ],
});

export const quantitativeResearchesSolutions1Data = (lang: string): IQuantitativeResearchesSolutionData => ({
  videoCaption: getTranslationsByLangOrEng(lang, 'cawi'),
  videoDescription: `${getTranslationsByLangOrEng(lang, 'quantitative_research_20')}\n${getTranslationsByLangOrEng(lang, 'quantitative_research_21')}`,
  description: getTranslationsByLangOrEng(lang, 'quantitative_research_22'),
  subtitle: getTranslationsByLangOrEng(lang, 'quantitative_research_23'),
  title: getTranslationsByLangOrEng(lang, 'cawi'),
  caption: `${getTranslationsByLangOrEng(lang, 'solutions')} 1`,
  video: quantitativeResearchesSolutions1Video,
  listData: [
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_24'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_25'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_26'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_27'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_28'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_29'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_30'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_31'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_32'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_33'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_34'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_35'),
    },
  ],
  colourListData: [
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_36'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_37'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_38'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_39'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_40'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_41'),
    },
  ],
});

export const quantitativeResearchesSolutions2Data = (lang: string): IQuantitativeResearchesSolutionData => ({
  videoCaption: getTranslationsByLangOrEng(lang, 'f2f'),
  videoDescription: `${getTranslationsByLangOrEng(lang, 'quantitative_research_42')}\n${getTranslationsByLangOrEng(lang, 'quantitative_research_43')}`,
  description: `${getTranslationsByLangOrEng(lang, 'quantitative_research_44')}\n\n${getTranslationsByLangOrEng(lang, 'quantitative_research_45')}`,
  subtitle: getTranslationsByLangOrEng(lang, 'quantitative_research_46'),
  title: getTranslationsByLangOrEng(lang, 'f2f'),
  caption: `${getTranslationsByLangOrEng(lang, 'solutions')} 2`,
  video: quantitativeResearchesSolutions2Video,
  listData: [
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_47'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_48'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_49'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_50'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_51'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_52'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_53'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_54'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_55'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_56'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_57'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_58'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_59'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_60'),
    },
    {
      boldPartOfText: getTranslationsByLangOrEng(lang, 'quantitative_research_61'),
      text: getTranslationsByLangOrEng(lang, 'quantitative_research_62'),
    },
  ],
  colourListData: [
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_63'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_64'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_65'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_66'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_67'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_68'),
    },
  ],
});

export const quantitativeResearchesSolutions3Data = (lang: string): IQuantitativeResearchesSolutionData => ({
  videoCaption: getTranslationsByLangOrEng(lang, 'cati'),
  videoDescription: `${getTranslationsByLangOrEng(lang, 'quantitative_research_69')}\n${getTranslationsByLangOrEng(lang, 'quantitative_research_70')}`,
  description: getTranslationsByLangOrEng(lang, 'quantitative_research_71'),
  subtitle: getTranslationsByLangOrEng(lang, 'quantitative_research_72'),
  title: getTranslationsByLangOrEng(lang, 'cati'),
  caption: `${getTranslationsByLangOrEng(lang, 'solutions')} 3`,
  video: quantitativeResearchesSolutions3Video,
  colourListData: [
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_73'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_74'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_75'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_76'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_77'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_78'),
    },
    {
      title: getTranslationsByLangOrEng(lang, 'quantitative_research_79'),
      description: getTranslationsByLangOrEng(lang, 'quantitative_research_80'),
    },
  ],
});

export const productsTabsData = (lang: string): {caption: string, title: string, description: string, background: string, link: string, anotherSite?: boolean}[] => [
  {
    title: getTranslationsByLangOrEng(lang, 'qualitative_research'),
    description: getTranslationsByLangOrEng(lang, 'products_tabs_3'),
    caption: getTranslationsByLangOrEng(lang, 'qualitative_research'),
    background: 'linear-gradient(225.73deg, #00FFB6 -1.31%, #48A6E0 30.61%, #9A40C6 100%)',
    link: '/qualitative-research',
  },
  {
    title: getTranslationsByLangOrEng(lang, 'service_design'),
    description: getTranslationsByLangOrEng(lang, 'main_12'),
    caption: getTranslationsByLangOrEng(lang, 'service_design'),
    background: 'linear-gradient(227.07deg, #FDA272 0%, #C835B6 42.19%, #0066FF 100%)',
    link: 'https://scheduling-europe.com/service/customer-experience-services/service-design/',
    anotherSite: true,
  },
  {
    title: getTranslationsByLangOrEng(lang, 'quantitative_research'),
    description: getTranslationsByLangOrEng(lang, 'products_tabs_4'),
    caption: getTranslationsByLangOrEng(lang, 'quantitative_research'),
    background: 'linear-gradient(226.69deg, #E8B500 7.4%, #FFF500 100%)',
    link: '/quantitative-research',
  },
];

export const partnersWhiteTheme: {static: string, hover: string, alt: string}[] = [
  {
    static: partner1White,
    hover: partner1WhiteHover,
    alt: 'BMW',
  },
  {
    static: partner2White,
    hover: partner2WhiteHover,
    alt: 'BNP Paribas Group',
  },
  {
    static: partner3White,
    hover: partner3WhiteHover,
    alt: 'Carrefour',
  },
  {
    static: partner4White,
    hover: partner4WhiteHover,
    alt: 'Epson',
  },
  {
    static: partner5White,
    hover: partner5WhiteHover,
    alt: 'Loreal',
  },
  {
    static: partner6White,
    hover: partner6WhiteHover,
    alt: 'Mercedes',
  },
  {
    static: partner7White,
    hover: partner7WhiteHover,
    alt: 'Rolex',
  },
  {
    static: partner8White,
    hover: partner8WhiteHover,
    alt: 'Samsung',
  },
  {
    static: partner9White,
    hover: partner9WhiteHover,
    alt: 'Shell',
  },
  {
    static: partner10White,
    hover: partner10WhiteHover,
    alt: 'Tui',
  },
  {
    static: partner11White,
    hover: partner11WhiteHover,
    alt: 'UBER',
  },
];

export const partnersBlackTheme: {static: string, hover: string, alt: string}[] = [
  {
    static: partner1Black,
    hover: partner1BlackHover,
    alt: 'BMW',
  },
  {
    static: partner2Black,
    hover: partner2BlackHover,
    alt: 'BNP Paribas Group',
  },
  {
    static: partner3Black,
    hover: partner3BlackHover,
    alt: 'Carrefour',
  },
  {
    static: partner4Black,
    hover: partner4BlackHover,
    alt: 'Epson',
  },
  {
    static: partner5Black,
    hover: partner5BlackHover,
    alt: 'Loreal',
  },
  {
    static: partner6Black,
    hover: partner6BlackHover,
    alt: 'Mercedes',
  },
  {
    static: partner7Black,
    hover: partner7BlackHover,
    alt: 'Rolex',
  },
  {
    static: partner8Black,
    hover: partner8BlackHover,
    alt: 'Samsung',
  },
  {
    static: partner9Black,
    hover: partner9BlackHover,
    alt: 'Shell',
  },
  {
    static: partner10Black,
    hover: partner10BlackHover,
    alt: 'Tui',
  },
  {
    static: partner11Black,
    hover: partner11BlackHover,
    alt: 'UBER',
  },
];

export const uxTestingAboutSliderData = (lang: string): {icon: any, text: string}[] => ([
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_9'),
    icon: uxAboutSliderIcon1,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_10'),
    icon: uxAboutSliderIcon2,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_11'),
    icon: uxAboutSliderIcon3,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_12'),
    icon: uxAboutSliderIcon4,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_13'),
    icon: uxAboutSliderIcon5,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_14'),
    icon: uxAboutSliderIcon6,
  },
]);

export const uxTestingAbout2SliderData = (lang: string): {icon: any, text: string}[] => [
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_22'),
    icon: uxAbout2SliderIcon1,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_23'),
    icon: uxAbout2SliderIcon2,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_24'),
    icon: uxAbout2SliderIcon3,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_25'),
    icon: uxAbout2SliderIcon4,
  },
];

export const uxTestingTwoSolutionsInfoBlocsData = (lang: string): {icon: any, text: string}[] => [
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_31'),
    icon: uxTwoSolutionsIcon1,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_32'),
    icon: uxTwoSolutionsIcon2,
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ux_testing_33'),
    icon: uxTwoSolutionsIcon3,
  },
];

export const priceMonitoringSectionListData = (lang: string): {boldPartOfText: string, text: string}[] => [
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_7'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_8'),
  },
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_9'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_10'),
  },
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_11'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_12'),
  },
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_13'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_14'),
  },
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_15'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_16'),
  },
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_17'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_18'),
  },
  {
    boldPartOfText: getTranslationsByLangOrEng(lang, 'price_monitoring_19'),
    text: getTranslationsByLangOrEng(lang, 'price_monitoring_20'),
  },
];

export const priceMonitoringKeyIndicatorsListData = (lang: string): {
  img: string,
  listData: {colourText: string, text: string}[],
}[] => [
  {
    img: priceMonitoringKeyIndicatorsListDataImg1,
    listData: [
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_23'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_24'),
      },
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_25'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_26'),
      },
    ],
  },
  {
    img: priceMonitoringKeyIndicatorsListDataImg2,
    listData: [
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_27'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_28'),
      },
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_29'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_30'),
      },
    ],
  },
  {
    img: priceMonitoringKeyIndicatorsListDataImg3,
    listData: [
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_31'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_32'),
      },
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_33'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_34'),
      },
    ],
  },
  {
    img: priceMonitoringKeyIndicatorsListDataImg4,
    listData: [
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_35'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_36'),
      },
      {
        colourText: getTranslationsByLangOrEng(lang, 'price_monitoring_37'),
        text: getTranslationsByLangOrEng(lang, 'price_monitoring_38'),
      },
    ],
  },
];

export const contactsData = (lang: string): {img: string, name: string, number: string}[] => [
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_7'),
    number: '+390690280688',
    img: contactsDataFlag1,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_8'),
    number: '+14244071047',
    img: contactsDataFlag2,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_9'),
    number: '+442045771548',
    img: contactsDataFlag3,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_10'),
    number: '+41445514300',
    img: contactsDataFlag4,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_11'),
    number: '+3197010281254',
    img: contactsDataFlag5,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_12'),
    number: '+421233221043',
    img: contactsDataFlag6,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_13'),
    number: '+38618888936',
    img: contactsDataFlag7,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_14'),
    number: '+40751628156',
    img: contactsDataFlag8,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_15'),
    number: '+994702077088',
    img: contactsDataFlag9,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_16'),
    number: '+380675492271',
    img: contactsDataFlag10,
  },
  {
    name: getTranslationsByLangOrEng(lang, 'contacts_18'),
    number: '+4314120126',
    img: contactsDataFlag12,
  },
];

export const adTestingPageBallsData = (lang: string): { text: string, border?: string, borderImage?: string[] }[] => [
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_7'),
    border: '#C63050',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_8'),
    border: '#08A9EC',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_9'),
    border: '#82BA77',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_10'),
    border: '#F0A573',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_11'),
    border: '#82BA77',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_12'),
    border: '#C63050',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_13'),
    border: '#82BA77',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_14'),
    border: '#F0A573',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_15'),
    border: '#08A9EC',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_16'),
    border: '#07A87F',
  },
  {
    text: getTranslationsByLangOrEng(lang, 'ad_testing_17'),
    border: '#C63050',
  },
];
