// import ukFlag from '../assets/icons/UK-flag.png';
// import frFlag from '../assets/icons/Flag-FR.png';
// import roFlag from '../assets/icons/Flag-RO.png';
// import deFlag from '../assets/icons/Flag-DE.png';
// import kzFlag from '../assets/icons/Flag-KZ.png';
// import uaFlag from '../assets/icons/Flag-UA.png';

import UAIcon from '../assets/icons/UA.svg';
import ENIcon from '../assets/icons/EN.svg';
import FRIcon from '../assets/icons/FR.svg';
import ROIcon from '../assets/icons/RO.svg';
import DEIcon from '../assets/icons/DE.svg';
import KZIcon from '../assets/icons/KZ.svg';

import ILanguage from '../enteties/ILanguage';

interface ILanguages {
  en: ILanguage;
  fr: ILanguage;
  ro: ILanguage;
  de: ILanguage;
  uk: ILanguage;
  kz: ILanguage;
}

export const LANGUAGES: ILanguages = {
  en: {
    name: 'EN',
    code: 'en',
    icon: ENIcon,
  },
  fr: {
    name: 'FR',
    code: 'fr',
    icon: FRIcon,
  },
  ro: {
    name: 'RO',
    code: 'ro',
    icon: ROIcon,
  },
  de: {
    name: 'DE',
    code: 'de',
    icon: DEIcon,
  },
  // kz: {
  //   name: 'KZ',
  //   code: 'kz',
  //   icon: kzFlag,
  // },
  uk: {
    name: 'UA',
    code: 'uk',
    icon: UAIcon,
  },
  kz: {
    name: 'KZ',
    code: 'kz',
    icon: KZIcon,
  },
};

export const availableLanguages = ['en', 'fr', 'de', 'uk', 'ro', 'kz'];
