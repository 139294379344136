import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps, useFormikContext,
} from 'formik';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import InputField from '../form/inputField/InputField';
import FormErrorMessage from '../formErrorMessage/FormErrorMessage';
import { translations } from '../../i18n/translations';
import { Api } from '../../api';
import { ContactFormStyles } from './ContactFormStyles';

import gif from '../../assets/icons/WQKU 1.gif';
import ArrowRight from '../../assets/icons/ArrowRight';
import CircleCheckbox from '../form/circleCheckbox/CircleCheckbox';
import { useAppSelector } from '../../state/hooks';
import getTranslationsByLangOrEng from '../../utils/getTranslationsByLangOrLang';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';

// export const phoneRegExp = /^(\d{4,30})$|^(\(?\d{2,3}\)?[ .-]?)?\d{3,6}[-. ]?\d{3,10}$|^\+?\(?([0-9]{3})\)?[-. ]?([0-9]{2,3})[-. ]?([0-9]{4,6})[-. ]?(\d{3,6})?$|^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

  interface FormValues {
    name: string;
    phone: string;
    email: string;
    company: string;
    terms: boolean;
  }

const initialValues: FormValues = {
  name: '',
  phone: '',
  email: '',
  company: '',
  terms: false,
};

export default function ContactForm({ colorTheme }: { colorTheme: 'darkTheme' | 'lightTheme' }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [errorMessage, setErrorMessage] = useState<any>({});

  const [countryCode, setCountryCode] = useState<string>('');

  const formRef = useRef<any>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    formRef.current.handleReset();
  }, [pathname]);

  useEffect(() => {
    axios.get('https://ipapi.co/json/').then((response) => {
      const data = response.data;
      setCountryCode(response.data.country_code.toLowerCase());
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(getTranslationsByLangOrEng(interfaceLanguage, 'validation_email'))
      .required(getTranslationsByLangOrEng(interfaceLanguage, 'validation_required')),
    name: Yup.string()
      .required(getTranslationsByLangOrEng(interfaceLanguage, 'validation_required')),
    phone: Yup.string()
      .matches(phoneRegExp, getTranslationsByLangOrEng(interfaceLanguage, 'validation_phone'))
      .required(getTranslationsByLangOrEng(interfaceLanguage, 'validation_required')),
    terms: Yup.boolean()
      .isTrue(getTranslationsByLangOrEng(interfaceLanguage, 'validation_privacy_policy')),
  });

  function getErrorMessage(key: string) {
    return typeof errorMessage === 'object'
      ? errorMessage[key]
      && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
        key
      ].substring(1)}`
      : undefined;
  }

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    const data = {
      company_name: values.company,
      email: values.email,
      name: values.name,
      phone_number: values.phone,
      web_site: `https://4service.company${pathname}`,
      language: interfaceLanguage,
    };

    Api.sendForm(data)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          navigate(`/${interfaceLanguage}/thank-you`);
        } else {
          setErrorMessage(translations[interfaceLanguage].submit_error);

          setTimeout(() => {
            setErrorMessage({});
          }, 3000);
        }
      });

    setSubmitting(false);
  }

  function handleKeyUp(key: string, error: string | undefined) {
    setErrorMessage(typeof errorMessage === 'object' ? { ...errorMessage, [key]: error } : errorMessage);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formTextContainer">
        <h2>
          {getTranslationsByLangOrEng(interfaceLanguage, 'form_title')}
        </h2>

        <div className="errors">
          {
            Object.entries(errors).length > 0 && Object.entries(touched).length ? Array.from(new Set(Object.values(errors))).map((text, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                <span className="form-field-erro">{text}</span>
                {/* <ErrorMessage name={item[0]} component={FormErrorMessage} /> */}
              </Fragment>
            )) : typeof errorMessage === 'string' ? (
              <span className="formErrorContainer error">
                *
                {errorMessage}
              </span>
            ) : (
              <span className="requiredText">
                {getTranslationsByLangOrEng(interfaceLanguage, 'form_required')}
              </span>
            )
}
        </div>
      </div>
      <div className="formFieldsAndSubmitButtonWrapper">
        <div className="inputs">
          <InputField
            name="name"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('name', errors?.name)}
            placeholder={`${getTranslationsByLangOrEng(interfaceLanguage, 'form_name_placeholder')} *`}
            value={values.name}
            error={typeof errorMessage === 'object' && getErrorMessage('name') ? getErrorMessage('name') : errors.name}
            required
            withFlickerHoverAnim
            colorTheme={colorTheme}
            showError={false}
          />

          <InputField
            name="company"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('company', errors?.company)}
            placeholder={getTranslationsByLangOrEng(interfaceLanguage, 'form_company_placeholder')}
            value={values.company}
            error={typeof errorMessage === 'object' ? getErrorMessage('company') : undefined}
            required
            withFlickerHoverAnim
            colorTheme={colorTheme}
            showError={false}
          />

          <InputField
            name="email"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('email', errors?.email)}
            placeholder={`${getTranslationsByLangOrEng(interfaceLanguage, 'form_email_placeholder')} *`}
            value={values.email}
            error={typeof errorMessage === 'object' && getErrorMessage('email') ? getErrorMessage('email') : errors.email}
            required
            withFlickerHoverAnim
            colorTheme={colorTheme}
            showError={false}
          />

          <PhoneInput
            country={!!countryCode && countryCode.length ? countryCode : 'ua'}
            value={values.phone}
            onChange={(phone, data) => setFieldValue('phone', phone)}
            containerClass={errors.phone && touched.phone ? 'error pnoneInputContainer' : 'pnoneInputContainer'}
            inputClass="phoneInput"
            dropdownClass="phoneInputDropdown"
            buttonClass="phoneInputDropdownButton"
            placeholder={`${getTranslationsByLangOrEng(interfaceLanguage, 'form_phone_placeholder')} *`}
          />

          <div className="checkboxWrapper">
            <CircleCheckbox
              colorTheme={colorTheme}
              name="terms"
              value={values.terms}
              onChange={() => setFieldValue('terms', !values.terms)}
              error={typeof errorMessage === 'object' && getErrorMessage('terms') ? getErrorMessage('terms') : touched.terms && errors.terms ? errors.terms : undefined}
            >
              <p>
                {getTranslationsByLangOrEng(interfaceLanguage, 'form_1')}
                {' '}
                <Link target="_blank" to={`/${interfaceLanguage}/privacy-policy`}>{getTranslationsByLangOrEng(interfaceLanguage, 'form_2')}</Link>
              </p>
            </CircleCheckbox>
          </div>
        </div>
        <div className="submitContainer">
          <div className="imageWrapper">
            <img src={gif} alt="human" />
            <div className="violetCircle" />
            <div className="blueCircle" />
          </div>
          <button type="submit" onClick={() => {}} className="submitButton fw700">
            <span>
              {getTranslationsByLangOrEng(interfaceLanguage, 'form_submit')}
            </span>
            <ArrowRight />
          </button>
        </div>
      </div>
    </Form>
  );

  return (
    <ContactFormStyles id="form" className={colorTheme}>
      <div className="container">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>
      </div>
    </ContactFormStyles>
  );
}
