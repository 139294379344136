import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

interface SeoPops {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  noIndexPage?: boolean;
  keywords?: string;
  noFollow?: boolean;
  lang?: string;
}

function Seo({
  title, description, ogTitle, ogDescription, noFollow = false, noIndexPage = false, keywords, lang = 'en',
}: SeoPops) {
  return (
    <Helmet>
      <title>{title || '4Service: Mystery shopping provider, Market research agency Description'}</title>
      <meta name="description" content={description || 'Marketing research for B2B/B2C: Mystery shopper, Retail audit, NPS, staff motivation and service quality check.'} />
      <meta name="og:title" content={ogTitle || title || '4Service: Mystery shopping provider, Market research agency Description'} />
      <meta name="og:description" content={ogDescription || description || 'Marketing research for B2B/B2C: Mystery shopper, Retail audit, NPS, staff motivation and service quality check.'} />
      <meta name="robots" content={`${noIndexPage ? 'noindex' : 'index'}, ${noFollow ? 'nofollow' : 'follow'}`} />
      <meta name="lang" content={lang} />
      {keywords && (
        <meta name="keywords" content={keywords} />
      )}
    </Helmet>
  );
}

export default Seo;
