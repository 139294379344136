import React, { CSSProperties } from 'react';
import { InputFieldStyles } from './InputFieldStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorMessage from '../../formErrorMessage/FormErrorMessage';

interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (key: string, value: string) => void;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  label?: string;
  onClick?: () => void;
  onBlur?: () => void;
  onKeyUp?: () => void;
  onKeyDown?: (e: any) => void;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  children?: any;
  withFlickerHoverAnim?: boolean;
  colorTheme?: 'lightTheme' | 'darkTheme',
  className?: string,
}

export default function InputField({
  name,
  placeholder,
  label,
  error,
  showError = true,
  value,
  min,
  max,
  onChange,
  onClick,
  onBlur,
  onKeyUp,
  onKeyDown,
  extraBlockStyles,
  disabled,
  type,
  required,
  children,
  withFlickerHoverAnim,
  colorTheme,
  className = '',
}: InputFieldProps) {
  const { touched } = useFormikContext<any>();

  return (
    <InputFieldStyles style={extraBlockStyles} className={`${className} ${colorTheme}`}>
      <label htmlFor={name}>
        {label
          && (
            <span>
              {label}
              {required && <span className="required"> *</span>}
            </span>
          )}
        <div className={error && touched[name] ? 'inputWrap error' : 'inputWrap'}>
          <div className="inputContainer">
            <input
              id={name}
              type={type || 'text'}
              min={type === 'number' ? min : undefined}
              max={type === 'number' ? max : undefined}
              value={value}
              onChange={(event) => onChange(name, event.target.value)}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled}
              onClick={onClick}
              onKeyDown={onKeyDown}
              className={
              withFlickerHoverAnim && disabled && typeof value === 'string' && value.length === 0
                ? 'disabled animActive'
                : disabled ? 'disabled'
                  : withFlickerHoverAnim && typeof value === 'string' && value.length === 0 ? 'animActive'
                    : ''
              }
              autoComplete="off"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="cursor" />
            {showError && (
            <div className="formErrorContainer">
              {error && <span>{error}</span>}
              {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
            </div>
            )}
          </div>
          <div className="childrenContainer">
            {children}
          </div>
        </div>
      </label>
    </InputFieldStyles>
  );
}
