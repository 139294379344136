import { useEffect } from 'react';

export function useClickOutside(ref: any, callback: () => void, exclude?: any) {
  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      if (exclude && exclude.current.length && ref && ref.current && !ref.current.contains(event.target) && !exclude.current.some((item: any) => item.contains(event.target))) {
        callback();
      } else if (ref && ref.current && !ref.current.contains(event.target) && (exclude && !Array.isArray(exclude.current) && (!exclude || !exclude.contains(event.target)))) {
        callback();
      } else if (ref && ref.current && !ref.current.contains(event.target) && !exclude) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, exclude]);
}
