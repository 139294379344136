export const translations: {[key: string]: { [key: string]: string}} = {
  en: {
    form_title: "We'll get in touch with you shortly",
    form_required: 'Fields marked with * are mandatory',
    form_name_placeholder: 'Name',
    form_email_placeholder: 'Email',
    form_phone_placeholder: 'Phone number',
    form_company_placeholder: 'Company',
    form_submit: 'Submit',
    form_1: 'I give my consent to the processing of personal data according to the',
    form_2: 'Privacy Policy',
    validation_privacy_policy: 'Please accept Privacy Policy',
    validation_phone: 'Phone number is not valid',
    validation_email: 'Invalid email format',
    validation_required: 'Required fields are not filled in',
    submit_error: 'An error occurred while saving the form',
    footer_1: 'Our partners:',
    footer_2: 'Methods',
    footer_3: 'Products',
    footer_4: 'Contacts:',
    footer_5: 'Imprint',
    footer_6: 'Privacy Policy',
    about_us_1: '4Service Corporate Social Responsibility',
    about_us_2: 'From the heart and soul of every member of our team. We do our best to keep a spark of kindness in each of our employees and make the world around us a little better',
    about_us_3: '4Service Corporate Social Responsibility',
    about_us_4: 'From the heart and soul of every member of our team. We do our best to keep a spark of kindness in each of our employees and make the world around us a little better',
    about_us_5: 'Service Mania business game',
    about_us_6: 'Our outreach initiatives for children and students',
    about_us_7: 'Charity / Volunteering',
    about_us_8: 'Orphanages, care homes for elderly people and volunteering for the Ukrainian army. The most moving part of our activities',
    about_us_9: 'Animal welfare',
    about_us_10: 'Anyone can pop into our office with a pet. We lend a hand to shelters, raise funds for food and sometimes also try to find home for homeless animals',
    about_us_11: '4Service Group',
    about_us_12: 'Is an international research company managing customer experience.',
    about_us_13: 'We implement complex projects to increase sales and develop business of client-oriented companies.',
    about_us_14: 'Is an international research holding with headquarters in Vienna, Austria. The company was established in 2001 with an aim to improve the quality of life of its clients and people who interact with business, use its services and consume its products. After all, this is for a reason the name “Forservice” was chosen for a company that represents customer service and good quality.',
    about_us_15: 'This was the start of the history of 4Service in Kyiv, Ukraine as the leading provider of mystery shopping services. It has continued to operate successfully as a research company across 52 countries with new opportunities in a holistic approach to analyzing and improving the entire customer experience chain',
    about_us_16: 'born in the name of service',
    about_us_17: 'first office located outside of Ukraine',
    about_us_18: 'relocation of headquarters to Vienna',
    about_us_19: 'elite MSPA member, Top-10 agency in Europe',
    about_us_20: 'establishing offices in 9 countries',
    about_us_21: 'first digital projects, Voicer',
    about_us_22: 'partners of DesignThinkers Academy',
    about_us_23: 'first global CХ projects',
    about_us_24: 'Mission',
    about_us_25: 'Our mission is to improve customer service all over the world! Our inspiration is the qualitative changes we see in the people and companies we work with.',
    about_us_26: '20+ years of Customer Experience',
    about_us_27: '95 countries',
    about_us_28: 'We collect and share the best industry experience from around the world',
    about_us_29: 'Head office in Vienna',
    about_us_30: 'countries. We collect and share the best industry experience from around the world',
    about_us_31: 'service quality ratings',
    about_us_32: 'we are in top-10 European CX Agencies',
    about_us_33: 'representative offices / HQ in Austria',
    about_us_34: 'active projects',
    about_us_35: 'Your future workplace',
    about_us_36: 'Our offices',
    about_us_37: '4Service ™ takes efforts to create comfortable working space for its employees. Our employees work on-site or remote or hybrid to the best convenience and productivity of each employee. Rooms for relaxation, free psychological support, and a welcoming environment. We are human and pet friendly.',
    about_us_38: 'Team that lights up',
    about_us_39: 'Our multinational team is committed to listening, leaning into feedback from our customers, and mutual growth. Our clients’ customer journey map with us is as important to our business as launching new products or hitting our financial targets. Our activities are aimed at having a positive impact on the communities around us and to deliver innovation and people-centric solutions for our customers.',
    about_us_40: 'All',
    about_us_41: 'Management',
    about_us_42: 'Team Global',
    about_us_43: 'Product owners',
    about_us_44: 'Our partners',
    about_us_45: 'We implement complex projects to increase sales and develop the businesses of client-oriented companies',
    about_us_46: 'is one of the leading ‘design driven’ Professional Training Institutes, represented in 25 countries and train, develop and facilitate creative multidisciplinary teams and communities to drive positive change. Design Thinking enables you to develop and deliver innovative ideas, change and solutions to complicated problems.',
    about_us_47: 'Their mission is to provide space and time for teams and individuals to be inspired and challenged, to ‘learn-by-doing’ and strengthen their ability for creative problem solving and help organizations build the necessary environment and mindset to kick-start bold innovation.',
    about_us_48: 'is the representative Trade Association for companies involved in the Mystery Shopping industry. The Association operates on a regional basis worldwide, with chapters located in America, Europe/Africa and Asia/Pacific. Member companies unite as a common body for the purpose of strengthening the Mystery Shopping industry through combined efforts and actions.',
    about_us_49: "is a not-for-profit membership organization. Members form a community convinced of the potential of data analytics, research and insights to help improve societies, organizations and everyone's life.",
    about_us_50: 'ESOMAR is headquartered in Amsterdam, the Netherlands. ESOMAR is present in over 130 countries through its members since 1947.',
    about_us_51: '(UCCA) is a professional community of private experts and companies united at the aim of development and interactions on the area of contact center and customer service. The Philosophy of the UCCA is raising the culture of the contact center industry, based on modern technologies and high standards.',
    about_us_52: 'The Ukrainian Contact Center Association was founded on 2010',
    about_us_53: 'studies the best world experience in the field of education to adapt and use it on the Ukrainian market. That is why Academy DTEK cooperates with the leading international business schools: INSEAD, IE Business School and Stanford University.',
    about_us_54: 'Academy DTEK is focused on the development of a client-oriented and innovative culture in D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School digital program, Effective Leader: Pre-MBA, Change Energy.',
    about_us_55: 'Sergey Pashkovsky',
    about_us_56: 'Viktoria Skorbota',
    about_us_57: 'Artem Fomin',
    about_us_58: 'Max Papka',
    about_us_59: 'International Sales Manager',
    bht_1: 'Brand Health',
    bht_2: 'Tracking',
    bht_3: 'Brands are incredibly powerful',
    bht_4: 'Find out how well your brand is performing overall',
    bht_5: 'About',
    bht_6: 'What is Brand Health Tracking?',
    bht_7: 'This is a type of market research that is conducted with a certain regularity (every two weeks, every month etc.) in order to obtain information about the',
    bht_8: 'status of a brand on the market based on indicators such as prominence, demand, image, the characteristics of its consumers, brand health, etc.',
    bht_9: 'Buy the brand most often',
    bht_10: 'Loyal',
    bht_11: 'Bought the brand for 3 month',
    bht_12: 'Repertory / regular customers',
    bht_13: 'Bought the brand for 6 month',
    bht_14: 'Customers in the short term',
    bht_15: 'Bought the brand for a year',
    bht_16: 'Customers in the long term',
    bht_17: 'Are aware of the brand? but have never used/bought it',
    bht_18: 'Aware but not customers',
    bht_19: 'Unaware: have never heard about the brand',
    bht_20: 'Unaware',
    bht_21: 'Brand buyers profile',
    bht_22: 'Evaluating the consistency of efforts put in in the long-term to build a brand',
    bht_23: 'Measuring the level of customer knowledge about the brand',
    bht_24: 'Showing the effectiveness of the advertising and information campaigns',
    bht_25: 'Determining what impact a period of high advertising has on the sales/marketing activities of the competitors',
    bht_26: 'Providing a constant control to the business owner in periods of continuous market changes',
    bht_27: 'What tasks can be solved by BHT:',
    bht_28: 'Request a research',
    bht_29: 'Task to solve',
    bht_30: 'For example,',
    bht_31: 'if a company needs only one session of BHT per quarter during the measurement of market shares, a survey of respondents should be conducted more often when measuring customer brand knowledge from advertising.',
    bht_32: 'CATI',
    bht_33: 'Face-to-Face',
    bht_53: 'Interviews',
    bht_34: 'СAWI',
    bht_35: 'Task to solve',
    bht_36: 'By collecting information',
    bht_37: 'through various methods, brand tracking is usually divided into three stages depending on the target audience and how difficult they are to reach:',
    bht_38: 'We use both traditional and contemporary methods to collect data:',
    bht_39: 'We work with using two models: the first one with a simple set of indicators and the second one targets more complex industries such as food and service industries. A more complex model involves an in-depth study that includes up to 10 estimated indices.',
    bht_40: 'We offer',
    bht_41: 'a control of initial data',
    bht_42: 'We cover',
    bht_43: 'the whole of the country',
    bht_44: 'We provide a solution',
    bht_45: 'for non-dynamic markets/markets with a limited number of players (Brand Point Analyze)',
    bht_46: 'Why us?',
    bht_47: 'Why choose us?',
    bht_50: 'Poor result',
    bht_51: 'Good result',
    bht_52: 'Average result',
    cases_1: 'Challenge: How to improve customer electric vehicle buying experience in Hyundai showrooms?',
    cases_2: 'Customer Journey mapping',
    cases_3: 'Ideation',
    cases_4: 'Qualitative research',
    cases_5: 'Challenge: What should be changed in the stores (both in service process, in the interior, in brand perception) in order to get customers interest into brand Oppo?',
    cases_6: 'Online CJM',
    cases_7: 'UI testing',
    cases_8: 'Interviews',
    cases_9: 'Challenge: What should be changed in the stores in order to get customers interest into brand Oppo?',
    cases_10: 'Qualitative part',
    cases_11: 'Ideation',
    cases_12: 'Quantitative part',
    cases_13: 'Customer Journey Map',
    cases_14: 'Cases',
    cases_15: 'All',
    cases_16: 'Let‘s make your great case together',
    csi_1: 'Customer',
    csi_2: 'satisfaction index',
    csi_3: 'Determine the reasons for consumers’',
    csi_4: 'satisfaction and dissatisfaction',
    csi_5: 'Research into customer satisfaction',
    csi_6: 'is the determination of the factors, causes and motives that influence a customer’s decision on purchasing as well as the behavior of the target audience.',
    csi_7: 'A customer satisfaction survey',
    csi_8: 'shows how satisfied or dissatisfied customer is with a particular aspect of service or the product in general.',
    csi_9: 'The satisfaction survey',
    csi_10: 'is conducted among company’s existing customers and users of certain products/services.',
    csi_11: 'What is CSI?',
    csi_12: 'Customer satisfaction measures the extent to which customer’s expectations have been satisfied regarding the purchase of a product or service provided by your company.',
    csi_13: 'Happy man',
    csi_14: 'About',
    csi_15: 'Identify any hidden factors',
    csi_16: 'that may affect the customer satisfaction',
    csi_17: 'Determine the reasons',
    csi_18: 'behind any rise/drop in demand for goods and services',
    csi_19: 'Optimize the company’s',
    csi_20: 'business processes',
    csi_21: 'Adapting them',
    csi_22: 'for the needs of the modern consumer',
    csi_23: 'Form an effective advertising message',
    csi_24: 'and position a product under the client’s request',
    csi_25: 'Tasks to solve',
    csi_26: 'What tasks customer satisfaction surveys can solve',
    csi_27: 'Man with phone',
    csi_28: 'We construct a mathematical model',
    csi_29: 'of factors which may influence customer satisfaction/customer loyalty (regression analysis)',
    csi_30: 'Optimize the',
    csi_31: 'company’s business processes',
    csi_32: 'We build a calculated customer satisfaction index',
    csi_33: '(CSI) and compare it with declarative one',
    csi_34: 'We conduct a research',
    csi_35: 'for both B2B and B2C segments.',
    csi_36: 'Our questionnaires are developed by a team',
    csi_37: 'of analysts especially for the individual projects of our clients',
    csi_38: 'Why us',
    csi_39: 'Why choose us?',
    csi_40: 'Methods of collecting information:',
    main_1: 'improve their financial performance by learning from the experiences of their clients',
    main_2: 'СX consulting and services a 360° view of your business',
    main_3: 'Discover our range of solutions and tools that we use to solve your business challenges',
    main_4: 'Products',
    main_5: 'Qualitative research is a collecting and analyzing non-numerical data to understand concepts, opinions, or experiences',
    main_6: 'Quantitative research methods is an objective measurement and numerical analysis of data collected through polls, questionnaires, and surveys',
    main_7: 'Mystery shopping is a market research technique for measuring, and monitoring the interactions between a customer and a company or organisation during a pre-defined scenario',
    main_8: 'Feedback Management System. Voice of your customer in one system Customer Feedback Management System',
    main_9: 'An AI-powered content intelligence platform providing insights from the audience',
    main_10: 'Performance Management Software. Gamified application for increasing sales',
    main_11: "Price monitoring is the process of collection, processing, and analysis of the competitors' prices",
    main_12: 'Service design is how to understand the needs and desires of the people who will use a product or service by spending time with them',
    main_13: 'Our analytical team applies the most up-to-date and fundamental approaches that we’ve proven to be successful with many clients',
    main_14: 'Methodologies',
    main_15: 'Customer journey map',
    main_16: 'Customer Journey Mapping (CJM) is about defining a target audience, understanding their needs and environment, and then creating a plan to deliver an engaging experience',
    main_17: 'CJM',
    main_18: 'Net Promotion Score',
    main_19: 'Net Promoter Score (NPS) is the gold standard of customer experience metrics, based on one question: How likely would you recommend Organization/Product/Service?',
    main_20: 'NPS',
    main_21: 'Brand Health Tracking',
    main_22: 'Brand Health Tracking (BHT) research can measure how your brand is performing on awareness and usage, brand positioning, and brand performance',
    main_23: 'BHT',
    main_24: 'Human Resources Research',
    main_25: 'Human Resource Research is used to evaluate HR practices and performance and offers detail analysis on the current development and management',
    main_26: 'HR research',
    main_27: 'Customer satisfaction index',
    main_28: 'The Customer Satisfaction Index (CSI) is a score that indicates how satisfied your customers are with the company\'s products / services.',
    main_29: 'CSI',
    main_30: 'UX testing',
    main_31: 'Usability (UX) testing is how real people interact with a website, app, or other product and observing their behavior and reactions to it',
    main_32: 'Why us',
    main_33: 'Our mission is to help businesses to provide highly effective customer experience',
    main_34: 'Focusing on quality',
    main_35: 'We are quality-controlled',
    main_36: 'when it comes to research data. We make all data sets available to clients',
    main_37: 'Cost-effective',
    main_38: 'Our experience, resources and streamlined',
    main_39: 'business processes allow us to save you money and time',
    main_40: 'Smart solutions',
    main_41: 'We use a variety of techniques',
    main_42: 'and toolkits to solve your particular problems',
    main_43: 'Focusing on quality',
    main_44: 'We quality-controlled',
    main_45: 'when it comes to research data. We make all data sets available to clients',
    main_46: 'Cost-effective',
    main_47: 'Our experience, resources and streamlined',
    main_48: 'business processes allow us to save you money and time',
    main_49: 'Smart solutions',
    main_50: 'We use a variety of techniques',
    main_51: 'and toolkits to solve your particular problems',
    main_52: 'Cash & Carry',
    main_53: 'Electronics',
    main_54: 'Luxury/Premium',
    main_55: 'Dining & Hospitality',
    main_56: 'Retail',
    main_57: 'Automotive',
    main_58: 'Fashion',
    main_59: 'Banks',
    main_60: 'Travel & Leisure',
    main_61: 'Watches & Jewelry',
    main_62: 'Household & Appliances',
    main_63: 'Blog',
    main_64: 'Go to the blog to read more interesting posts',
    main_65: 'Retail audit',
    main_66: 'Collect detailed data on your store or brand, analyze growth barriers, and create effective strategies to overcome them',
    cjm_1: 'Customer',
    cjm_2: 'Journey map',
    cjm_3: 'Enter the world of customers and share in their experience',
    cjm_4: 'What is Customer Journey Map?',
    cjm_5: 'CJM provides a visualized history of consumer interaction with a product, service, company, or brand through various channels and during a certain period of time.',
    cjm_6: 'This allows companies to objectively analyze the experience of customer interaction, fix and eliminate any barriers that arise and to offer recommendations for improving the product.',
    cjm_7: 'Determination of TA, segmentation',
    cjm_8: 'Identification of the stages of customer interaction',
    cjm_9: 'Identification of primary customer interaction channels',
    cjm_10: 'Research of customer experience',
    cjm_11: 'Analysis of barriers and hypothesis on optimization',
    cjm_12: 'Execution and testing of hypotheses',
    cjm_13: 'Designing a Customer Journey Map consists of the following steps:',
    cjm_14: 'The Customer Journey Map will demonstrate:',
    cjm_15: 'CJM provides a visualized history of consumer interaction with a product, service, company, or brand through various channels and during a certain period of time.',
    cjm_16: 'We ensure teamwork',
    cjm_17: 'between analysts and designers',
    cjm_18: 'We offer an individual',
    cjm_19: 'approach to each client',
    cjm_20: 'We can increase overall',
    cjm_21: 'customer satisfaction with the service/product and ultimately increase their loyalty to the company',
    cjm_22: 'We guarantee the',
    cjm_23: 'optimization of the consumer’s path and take into account the company’s business processes',
    cjm_24: 'We offer our customers',
    cjm_25: 'the opportunity to use combined approaches: CATI, CAWI, focus groups, in-depth interviews, expert interviews, ethnography, etc.',
    cjm_26: 'Why choose us?',
    methods_hr_1: 'Human Resources Research',
    methods_hr_2: 'Get objective insights about your team and find how to improve HR performance',
    methods_hr_3: 'Some reasons why you may need HR research',
    methods_hr_4: 'of the staff interviewed',
    methods_hr_5: 'hr projects implemented',
    methods_hr_6: 'staff training sessions have been held',
    methods_hr_7: 'Leading European holding for customer experience and employee research',
    methods_hr_8: '4service HR researches in numbers',
    methods_hr_9: "To identify problems and weaknesses in each stage of the life of your company's employees: search/recruitment/onboarding/career development/recertifications",
    methods_hr_10: 'To know what occasions deserve raises and bonuses',
    methods_hr_11: 'You need to predict or provide solutions of employees dissatisfaction',
    methods_hr_12: 'Through HR research new and current methods of evaluation of HR policies, programmes and practices are possible',
    methods_hr_13: 'To improve the mental health of company employees',
    methods_hr_14: 'If you want to improve productivity through a goal-oriented approach',
    methods_hr_15: 'If you have an international and/or remote team and you need to manage it',
    methods_hr_16: 'To understand how attractive your employer brand is to potential employees',
    methods_hr_17: 'Employee Satisfaction',
    methods_hr_18: 'HR Branding Research',
    methods_hr_19: 'Cultural Diversity',
    methods_hr_20: 'Compensation and Salary Plansies',
    methods_hr_21: 'Team Engagement and Loyality',
    methods_hr_22: 'Protected Class Employees',
    methods_hr_23: 'Areas of research',
    methods_hr_24: 'Solution 1',
    methods_hr_25: 'eNPS, Employee Satisfaction, Employee Loyalty',
    methods_hr_26: 'What we measure:',
    methods_hr_27: 'Loyalty:',
    methods_hr_28: 'employee loyalty, predicting future behavior, determining the weight of factors that affect loyalty',
    methods_hr_29: 'Involvement:',
    methods_hr_30: 'involvement of employees in work, interest in results and achievements',
    methods_hr_31: 'Engagement:',
    methods_hr_32: 'identification of employee values and company values',
    methods_hr_33: 'Trust 360:',
    methods_hr_34: 'trust in management, co-workers, subordinates, trust in employer strategies and policies',
    methods_hr_35: 'Satisfaction:',
    methods_hr_36: 'defining the unique factors for each company, evaluation of satisfaction on each key factor affecting loyalty',
    methods_hr_37: 'Solution 2',
    methods_hr_38: 'Employer branding',
    methods_hr_39: 'Internal employer',
    methods_hr_40: 'brand analysis (staff, stakeholder, internal recruiters and HR specialists survey)',
    methods_hr_41: 'External employer analysis',
    methods_hr_42: '(competitor EVP analysis, external recruiter survey, job seeker survey)',
    methods_hr_43: 'Brand awareness and reputation',
    methods_hr_44: 'analysis',
    methods_hr_45: 'Employer presence',
    methods_hr_47: 'Employer reference',
    methods_hr_48: 'collection',
    methods_hr_49: 'Competitive benchmarking',
    methods_hr_50: 'on key factors such as',
    methods_hr_51: 'Salary,',
    methods_hr_52: 'working environment, atmosphere, nature of work',
    methods_hr_53: 'Analysis of needs and expectations',
    methods_hr_54: 'of job seekers',
    methods_hr_55: 'In-depth interviews,',
    methods_hr_56: 'desk research and quantitative research are used',
    methods_hr_57: 'Solution 3',
    methods_hr_58: 'Employee journey map/mystery employee',
    methods_hr_59: 'constructing the jobseeker journey identifying',
    methods_hr_60: 'the main stages of the search, channels of communication, selection criteria and assessment of jobseekers',
    methods_hr_61: 'monitoring all stages',
    methods_hr_62: 'and channels of communication with mystery job seekers (just making an appointment for an interview, having an interview, an internship in the company)',
    methods_hr_63: 'Identification',
    methods_hr_64: 'of weaknesses in the recruitment and onboarding processes',
    methods_hr_65: 'Recommendations for improvement,',
    methods_hr_66: 'benchmarking with industry leaders',
    mystery_shopping_1: 'Mystery',
    mystery_shopping_2: 'Shopping',
    mystery_shopping_3: 'Evaluate your service with real customers',
    mystery_shopping_4: 'Check standards assess your staff become closer to your customer',
    mystery_shopping_5: 'We are Elite member of professional leading Mystery Shopping Providers association MSPA Europe',
    mystery_shopping_6: 'of experience',
    mystery_shopping_7: 'visits monthly',
    mystery_shopping_8: 'mystery shoppers worldwide',
    mystery_shopping_9: 'Active projects',
    mystery_shopping_10: 'offices, HQ in Austira',
    mystery_shopping_11: 'Europian Mystery Shopping Providers',
    mystery_shopping_12: 'service quality ratings',
    mystery_shopping_13: 'Questionnaire',
    mystery_shopping_14: 'Software',
    mystery_shopping_15: "Shopper's profile",
    mystery_shopping_16: 'Validation and objection',
    mystery_shopping_17: 'Analytics',
    mystery_shopping_18: 'With these 5 steps, our company 4Service guarantees the quality of the mystery shopper service',
    mystery_shopping_19: 'Programme',
    mystery_shopping_20: 'The 5 elements of the Mystery Shopping programme by 4Service',
    mystery_shopping_21: 'Questionnaire:',
    mystery_shopping_22: 'the core of your programme',
    mystery_shopping_23: 'This part of the programme is viewable by all stakeholders',
    mystery_shopping_24: 'For top managers.',
    mystery_shopping_25: 'Show all the important steps and details of the process',
    mystery_shopping_26: 'For employees.',
    mystery_shopping_27: 'Reflect what employers expect from them',
    mystery_shopping_28: 'For mystery shoppers.',
    mystery_shopping_29: 'Clear and structured to avoid confusion and bias',
    mystery_shopping_30: 'Shopmetrics Software:',
    mystery_shopping_31: 'user-friendly and innovative',
    mystery_shopping_32: 'Benefits:',
    mystery_shopping_33: 'Integrates',
    mystery_shopping_34: 'with your business processes and software',
    mystery_shopping_35: 'GDPR-Driven',
    mystery_shopping_36: 'Privacy Management Toolkit',
    mystery_shopping_37: 'Detailed',
    mystery_shopping_38: 'Legal Compliance Package',
    mystery_shopping_39: 'Report',
    mystery_shopping_40: 'builder',
    mystery_shopping_41: 'Management',
    mystery_shopping_42: 'of access levels',
    mystery_shopping_43: 'Most questionnaires',
    mystery_shopping_44: 'are completed within an hour',
    mystery_shopping_45: 'Shoppers profile:',
    mystery_shopping_46: '200 000+ mystery shoppers worldwide',
    mystery_shopping_47: 'Main criteria: maximum proximity to your target audience, with previous experience with your company or competitors',
    mystery_shopping_48: 'Shoppers’ training:',
    mystery_shopping_49: 'Introduction training test',
    mystery_shopping_50: 'Skype/Phone call',
    mystery_shopping_51: 'Evaluation test (optional)',
    mystery_shopping_52: 'Validation and objections',
    mystery_shopping_53: 'Validation',
    mystery_shopping_54: 'System',
    mystery_shopping_55: '100% reports',
    mystery_shopping_56: 'are reviewed by the validation team',
    mystery_shopping_57: 'Additional calls',
    mystery_shopping_58: 'for clarification',
    mystery_shopping_59: 'Audio records',
    mystery_shopping_60: 'after the visit to note the most important issues',
    mystery_shopping_61: 'Employee fraud',
    mystery_shopping_62: 'prevention system',
    mystery_shopping_63: 'Objection',
    mystery_shopping_64: 'System',
    mystery_shopping_65: "Any shopper's assessment",
    mystery_shopping_66: 'can be objected by the employee on the website directly',
    mystery_shopping_67: 'Each objection',
    mystery_shopping_68: 'is considered and responded to by the local validation team',
    mystery_shopping_69: 'You can see the statistic',
    mystery_shopping_70: 'of the objections',
    mystery_shopping_71: 'Analytics.',
    mystery_shopping_72: 'The report are presented in person and contains:',
    mystery_shopping_73: "Company's",
    mystery_shopping_74: 'pain points and concerns',
    mystery_shopping_75: 'Dynamics',
    mystery_shopping_76: 'by divisions and sections',
    mystery_shopping_77: 'Correlation',
    mystery_shopping_78: 'between meeting standards and customer satisfaction',
    mystery_shopping_79: 'Benchmarking',
    mystery_shopping_80: 'against competitors',
    mystery_shopping_81: 'Practical',
    mystery_shopping_82: 'recommendations on service improvements',
    mystery_shopping_83: 'What makes us different?',
    mystery_shopping_84: 'We carry out ideation sessions',
    mystery_shopping_85: 'We not only recommend what to do, but we together with you look for practical ways to solve service problems.',
    mystery_shopping_86: 'Ideation sessions are a powerful tool that helps us:',
    mystery_shopping_87: 'To develop directions for product or services improvement',
    mystery_shopping_88: 'To explore new revenue stream opportunities and business strategies',
    mystery_shopping_89: 'To find solutions to intricate customer-centric challenges',
    mystery_shopping_90: 'To transform customers’ pain points into delights',
    mystery_shopping_91: 'We conduct video interviews',
    mystery_shopping_92: "We don't just collect questionnaires - we also get live comments from shoppers",
    mystery_shopping_93: 'We do this in order to:',
    mystery_shopping_94: 'Our facilitators will be conduct in-depth interviews and give effective feedback',
    mystery_shopping_95: "See the experience from the customer's perspective",
    mystery_shopping_96: 'Enhance your content marketing strategy',
    mystery_shopping_97: 'Predict customer behavior',
    mystery_shopping_98: 'We design Customer Journey Map',
    mystery_shopping_99: 'Customer Journey Mapping helps us to step into customers shoes to understand their needs and challenges.',
    mystery_shopping_100: 'Сreating a customer journey is the best way to:',
    mystery_shopping_101: "See the experience from the customer's perspective",
    mystery_shopping_102: 'Enhance your content marketing strategy',
    mystery_shopping_103: 'Predict customer behavior',
    mystery_shopping_104: 'Identify gaps in service or communications',
    mystery_shopping_105: 'We provide different types of Mystery Shopping',
    mystery_shopping_106: 'Types of Mystery Shopping',
    mystery_shopping_107: 'Mystery Caller',
    mystery_shopping_108: 'Calls your managers and/or call centre to determine the quality of service provided over the phone',
    mystery_shopping_109: 'Luxury mystery shopping',
    mystery_shopping_110: 'Checking service in luxury locations by a special category of mystery shoppers',
    mystery_shopping_111: 'Competitive visits',
    mystery_shopping_112: 'The easiest way to understand how your competitor operates',
    mystery_shopping_113: 'Mystery Employee',
    mystery_shopping_114: "Will help you to understand your employee's whole journey (hiring, adaptation, office processes, etc.)",
    mystery_shopping_115: 'Motivational visits',
    mystery_shopping_116: 'A useful tool for stimulating the offer of additional services and/or products, sales of specific products',
    mystery_shopping_117: 'Mystery Shopper Online',
    mystery_shopping_118: 'Simulates and measures customer experience in your online shop, website or app',
    mystery_shopping_119: 'Sales & promotions check',
    mystery_shopping_120: 'Aids in identifying how promotions are displayed in all locations and how effectively do they work',
    mystery_shopping_121: 'Purchase and return visits',
    mystery_shopping_122: 'Helps to reflect the entire process from purchase to return in order to improve the customer experience',
    mystery_shopping_123: '> 20 years',
    nps_1: 'Net Promotion',
    nps_2: 'Score',
    nps_3: 'Measure, track and improve customer loyalty',
    nps_4: 'They respond with a score of 0 to 6.',
    nps_5: 'They may have had a poor experience and who are unlikely to buy from you again, and may even discourage others from buying from you.',
    nps_6: 'These are respondents who score between 7 and 8.',
    nps_7: 'They are satisfied with your service but not happy enough to be considered promoters.',
    nps_8: 'These are the respondents who rate your business as a 9 or a 10.',
    nps_9: 'This means that they would recommend you to a friend or colleague, and are therefore advocating on your behalf.',
    nps_10: 'What is NPS?',
    nps_11: 'It measures customer perception based on one simple question:',
    nps_12: 'How likely is it that you would recommend [Organization/Product /Service] to a friend or colleague?',
    nps_13: 'Does my company need it?',
    nps_14: 'NPS is your sustainable growth',
    nps_15: 'Understand the Dynamics of Customer Loyalty',
    nps_16: 'Motivate your staff',
    nps_17: 'Understand the relationship to different products',
    nps_18: 'Compare how customers treat you and your competitors',
    nps_19: 'What can I do with NPS?',
    nps_20: 'What kind of business is NPS relevant for?',
    nps_21: 'Information gathering methods:',
    nps_22: 'Our recommendations when working with NPS',
    nps_23: 'This means that we develop guidelines to help turn Detractors into promoters',
    nps_24: 'We work in a "closed loop" method',
    nps_25: 'NPS is a tracking research in which we track changes in customer loyalty',
    nps_26: 'We monitor the dynamics',
    nps_27: 'We offer to always evaluate the competitors in your niche for a more in-depth analysis',
    nps_28: 'Competitors evaluation',
    price_monitoring_1: 'Price Monitoring',
    price_monitoring_2: 'Retail Monitoring',
    price_monitoring_3: 'Increase sales potential and optimise your pricing policy through competitor price monitoring',
    price_monitoring_4: '4Service has been providing price monitoring services worldwide for more than 20 years',
    price_monitoring_5: 'Prices are monitored within a short time by our team of qualified in-house auditors, allowing us to respond quickly to changes in competitors prices',
    price_monitoring_6: 'Challenges our company can help you to solve with price monitoring',
    price_monitoring_7: "Evaluating competitors' pricing",
    price_monitoring_8: 'and product policies',
    price_monitoring_9: 'Monitoring promotions',
    price_monitoring_10: 'and special offers',
    price_monitoring_11: 'Monitoring market conditions',
    price_monitoring_12: 'and market environment',
    price_monitoring_13: 'Checking and controlling sales outlets',
    price_monitoring_14: 'for special equipment and merchandise',
    price_monitoring_15: 'Managing product',
    price_monitoring_16: 'flow within the trade',
    price_monitoring_17: 'Optimizing conditions',
    price_monitoring_18: 'for better collaboration with suppliers, dealers',
    price_monitoring_19: 'Measuring shelf space,',
    price_monitoring_20: 'assessing positioning of goods, stock availability',
    price_monitoring_21: 'Key indicators and monitoring criteria',
    price_monitoring_22: 'All data is compiled in one interface including:',
    price_monitoring_23: 'name',
    price_monitoring_24: 'of the product and brand category',
    price_monitoring_25: 'availability',
    price_monitoring_26: 'of promotional materials',
    price_monitoring_27: 'price',
    price_monitoring_28: 'and availability of any promotional offers',
    price_monitoring_29: 'shelf',
    price_monitoring_30: 'size',
    price_monitoring_31: 'product',
    price_monitoring_32: 'display',
    price_monitoring_33: 'SKU',
    price_monitoring_34: 'facing',
    price_monitoring_35: 'type',
    price_monitoring_36: 'of packaging',
    price_monitoring_37: 'any other',
    price_monitoring_38: 'general information',
    price_monitoring_39: 'Pre-project analysis:',
    price_monitoring_40: 'We study',
    price_monitoring_41: "your company's pricing and product policy, business and industry practices",
    price_monitoring_42: 'We provide',
    price_monitoring_43: 'prompt training, testing and certification for auditors',
    price_monitoring_44: 'We customise',
    price_monitoring_45: 'and set up the software',
    price_monitoring_46: 'Field work:',
    price_monitoring_47: 'Visits to retail',
    price_monitoring_48: 'outlets',
    price_monitoring_49: 'Reporting:',
    price_monitoring_50: 'Our analytical team',
    price_monitoring_51: "prepares online reports in the company's personal accounts",
    price_monitoring_52: 'We provide',
    price_monitoring_53: 'a full photo report, PPT report, Excel report in terms of type of locality, chain name, outlet address',
    price_monitoring_54: 'How it',
    price_monitoring_55: 'works',
    price_monitoring_56: 'the size of individual discounts',
    price_monitoring_57: 'cost calculation',
    price_monitoring_58: 'size of individual bonuses',
    price_monitoring_59: 'loyalty programs',
    price_monitoring_60: 'etc.',
    price_monitoring_61: 'We use specialized software:',
    price_monitoring_62: 'PRADATA, Shopmetrics, and offer comprehensive technical support',
    price_monitoring_63: 'Rapid deployment',
    price_monitoring_64: 'and scaling (from day 1)',
    price_monitoring_65: 'All data confirmed',
    price_monitoring_66: 'by photos and GPS tagging',
    price_monitoring_67: 'We have the necessary number',
    price_monitoring_68: 'of field agents in any country',
    price_monitoring_69: 'We can work with large',
    price_monitoring_70: 'amounts of data',
    price_monitoring_71: 'Monitoring of individual prices',
    price_monitoring_72: 'If you have complex products - we can help you define your pricing strategy',
    price_monitoring_73: "We can monitor individual competitor's prices, for example:",
    price_monitoring_74: 'Low',
    price_monitoring_75: 'cost',
    price_monitoring_76: 'High',
    price_monitoring_77: 'efficiency',
    price_monitoring_78: 'Excel report',
    price_monitoring_79: 'PPT report',
    price_monitoring_80: 'BI report',
    price_monitoring_81: 'Competitor pricing data helps you make the best pricing decisions',
    price_monitoring_82: 'We provide a full photo report, PPT report, Excel report in terms of type of locality',
    qualitative_research_1: 'Qualitative',
    qualitative_research_2: 'researches',
    qualitative_research_3: 'Collect, analyse and interpret data through the observation of actions and words of your customers',
    qualitative_research_4: 'Values',
    qualitative_research_5: 'Behaviour',
    qualitative_research_6: 'Background',
    qualitative_research_7: 'Expectations',
    qualitative_research_8: 'Enviroment',
    qualitative_research_9: 'Fears/Barriers',
    qualitative_research_10: "What is the purpose of the customer's choice?",
    qualitative_research_11: 'Man with phone',
    qualitative_research_12: 'brand',
    qualitative_research_13: 'category',
    qualitative_research_14: 'advertising',
    qualitative_research_15: 'product',
    qualitative_research_16: 'The uniqueness of qualitative methods is that they allow us to understand the causes and motivation of consumer behavior',
    qualitative_research_17: "Аnalysis of the consumer's attitude to a particular phenomenon",
    qualitative_research_18: 'Individual perspectives and experiences',
    qualitative_research_19: 'Topics that cannot be investigated through surveys',
    qualitative_research_20: 'Sensitive topics',
    qualitative_research_21: 'Hard to reach audience',
    qualitative_research_22: 'Complex geography',
    qualitative_research_23: 'In-depth interviews useful for:',
    qualitative_research_24: 'In-depth interviews',
    qualitative_research_25: 'An in-depth interview is a qualitative research technique that involves conducting individual interviews with respondents to explore their perspectives on a particular idea, program, or situation. An in-depth interview explores individual experiences / perceptions / practices in rich detail.',
    qualitative_research_26: 'Aims:',
    qualitative_research_27: 'Characterizing social and cultural norms',
    qualitative_research_28: 'Hard to reach audience',
    qualitative_research_29: 'Sharing and comparing (Morgan) investigated through surveys',
    qualitative_research_30: 'Topics that cannot be investigated through surveys',
    qualitative_research_31: 'In-depth focus groups useful for:',
    qualitative_research_32: 'Focus groups',
    qualitative_research_33: 'Deep exploration of individual meetings, emerging discussions, discussions between manifestations under the influence of the group moderator.',
    qualitative_research_34: 'Generating insights into shared experiences and social norms through group discussion, narrative data in a focused discussion.',
    qualitative_research_36: 'Benefits:',
    qualitative_research_37: 'Studying the lifestyle and behavior of the consumer in various real conditions (at home, at the point of sale, at work, etc.)',
    qualitative_research_38: 'Search for insights for the development of new products, packaging, service improvement, etc.',
    qualitative_research_39: 'In-depth ethnography research useful for:',
    qualitative_research_40: 'Ethnography research',
    qualitative_research_41: 'Ethnography is a type of qualitative research that involves immersing yourself into a particular community or organization to observe their behavior and interactions up close.',
    qualitative_research_42: 'Ethnography is a flexible research method that allows you to gain a deep understanding of a group’s shared culture, conventions, and social dynamics. However, it also involves some practical and ethical challenges.',
    qualitative_research_43: 'Methods:',
    qualitative_research_44: 'Ethnography is a study of the consumer in real-life conditions and interaction with a product/service',
    qualitative_research_45: 'Benefits of working with us',
    qualitative_research_46: 'We can find respondents from any target audience. And we control the quality of recruiting',
    qualitative_research_47: 'Recruiting of any kind of complexity',
    qualitative_research_48: 'Our moderators are multilingual and have facilitation skills',
    qualitative_research_49: 'Expert moderators',
    qualitative_research_50: 'Working online/offline',
    qualitative_research_51: 'We provide audio/video',
    qualitative_research_52: 'recordings',
    qualitative_research_53: 'We can conduct qualitative research in any locality',
    quantitative_research_1: 'Study of customer satisfaction',
    quantitative_research_2: 'with a particular product or service (SCI, NPS)',
    quantitative_research_3: 'Conducting segmentation',
    quantitative_research_4: 'surveys of different markets',
    quantitative_research_5: 'Market research',
    quantitative_research_6: 'NPS management',
    quantitative_research_7: 'Competitor analysis',
    quantitative_research_8: 'Quantitative',
    quantitative_research_9: 'researches',
    quantitative_research_10: 'Research methodology which uses to test theories about people’s attitudes and behaviors based on objectival, numerical and statistical data',
    quantitative_research_11: 'years',
    quantitative_research_12: 'of CX experience worldwide',
    quantitative_research_13: 'panel',
    quantitative_research_14: 'of respondents',
    quantitative_research_15: 'countries',
    quantitative_research_16: 'we run projects all over the world',
    quantitative_research_17: 'Who we are',
    quantitative_research_18: '4Service is a global marketing research and CX holding. We offer expert data collection services to large variety of businesses',
    quantitative_research_19: '4Service By Numbers',
    quantitative_research_20: 'Computer Assisted',
    quantitative_research_21: 'Web Interviewing',
    quantitative_research_22: 'An Internet-based questionnaire technique. The respondent completes an electronic questionnaire without the help of an interviewer. The questionnaire can be sent electronically or posted on the website.',
    quantitative_research_23: 'Computer Assisted Web Interviewing',
    quantitative_research_24: 'In most cases',
    quantitative_research_25: 'this method is the most cost-effective in terms of material and time costs',
    quantitative_research_26: 'This survey provides',
    quantitative_research_27: 'an opportunity to interview an audience that is difficult to reach',
    quantitative_research_28: 'It provides extensive possibilities',
    quantitative_research_29: 'for video and audio material demonstration as well as pictures',
    quantitative_research_30: 'Respondents can be',
    quantitative_research_31: 'located anywhere in the world',
    quantitative_research_32: 'It allows to investigate',
    quantitative_research_33: 'the specific behaviour of the Internet audience. The main results can be accessed by the client in real time via a web interface',
    quantitative_research_34: 'Eligible for any confidential,',
    quantitative_research_35: 'sensitive, personal topic or issue that respondents may have been reluctant to answer when talking to an interviewer',
    quantitative_research_36: '150 000+ panel',
    quantitative_research_37: 'We have our own panel of respondents worldwide',
    quantitative_research_38: 'We work with challenging enquiries',
    quantitative_research_39: 'By using complementary tools (social media, advertising), we can find a specific audience and a sophisticated profile of the respondent',
    quantitative_research_40: 'We motivate our respondents',
    quantitative_research_41: 'We have designed a framework for encouraging the completion of long surveys by providing material incentives',
    quantitative_research_42: 'Face-to-face interviews',
    quantitative_research_43: 'conducted by using tablet',
    quantitative_research_44: 'This is one of the main methods of collecting quantitative data, during which the interviewer communicates directly with the respondent on a strictly structured questionnaire.',
    quantitative_research_45: "Face-to-face interviews can be conducted at the respondents' place of work or residence, on the street or atdesignated sales venues using a tablet",
    quantitative_research_46: 'Face-to-face interviews conducted by using tablet',
    quantitative_research_47: 'Determining the level',
    quantitative_research_48: 'of popularity and brand recognition',
    quantitative_research_49: 'Consumer segmentation',
    quantitative_research_50: 'based on their buying behaviour',
    quantitative_research_51: 'Analysing the effectiveness',
    quantitative_research_52: 'of the advertising campaign',
    quantitative_research_53: 'Customer satisfaction',
    quantitative_research_54: 'measurement',
    quantitative_research_55: 'Determining the best price',
    quantitative_research_56: 'for the product or service',
    quantitative_research_57: 'Analysis',
    quantitative_research_58: 'of consumer preferences',
    quantitative_research_59: 'Study',
    quantitative_research_60: 'of how consumers buy',
    quantitative_research_61: 'Assessment',
    quantitative_research_62: 'of market capacity',
    quantitative_research_63: 'DigSee Software',
    quantitative_research_64: 'We use special software with audio recording and GPS',
    quantitative_research_65: 'Interviewers and supervisors',
    quantitative_research_66: "we can conduct interviews in any language and translate into the client's preferred language",
    quantitative_research_67: 'Sampling',
    quantitative_research_68: 'The analytical department develops a system of respondent recruitment in order to obtain the best possible result',
    quantitative_research_69: 'Computer Assisted',
    quantitative_research_70: 'Telephone Interviewing',
    quantitative_research_71: 'Methodology for collecting quantitative information through telephone interviews using a clearly structured questionnaire.',
    quantitative_research_72: 'Computer Assisted Telephone Interviewing',
    quantitative_research_73: 'We have our own call centers',
    quantitative_research_74: 'We use special software with audio recording and GPS',
    quantitative_research_75: 'Qualified operators',
    quantitative_research_76: 'Our operators speak different languages, are trained, well-tested and continually improve their skills',
    quantitative_research_77: 'Databases',
    quantitative_research_78: 'We work with our own databases and your customer bases as well',
    quantitative_research_79: 'SLA',
    quantitative_research_80: 'We guarantee quality and sign up an SLA (Service Level Agreement)',
    quantitative_research_81: 'Goal setting',
    quantitative_research_82: 'Methodology determination',
    quantitative_research_83: 'Segmentation and sampling',
    quantitative_research_84: 'Defining the data collection channels',
    quantitative_research_85: 'Creating a questionnaire that is logical for the respondent',
    quantitative_research_86: 'Data collecting process',
    quantitative_research_87: 'Quality assurance',
    quantitative_research_88: 'Analysis techniques',
    quantitative_research_89: 'Data processing',
    quantitative_research_90: 'Survey design',
    quantitative_research_91: 'GPS location tracking of interviewers',
    quantitative_research_92: 'Data verification',
    quantitative_research_93: 'Questionnaire programming and testing',
    quantitative_research_94: 'Data validation',
    quantitative_research_95: 'Audio recordings while following the requirements of the GDPR',
    quantitative_research_96: 'Training and competence of research staff',
    quantitative_research_97: 'Quality Assurance',
    quantitative_research_98: 'Factor analysis',
    quantitative_research_99: 'Segmentation',
    quantitative_research_100: 'Perceptual mapping',
    quantitative_research_101: 'Correlation',
    quantitative_research_102: 'Conversion model',
    quantitative_research_103: 'Jaccard',
    quantitative_research_104: 'Cluster analysis',
    quantitative_research_105: 'etc.',
    quantitative_research_106: 'Analytical Reports',
    quantitative_research_107: 'We use SPSS® and other tools to build analytical reports using different methodologies',
    quantitative_research_108: 'What business challenges сan be solved:',
    quantitative_research_109: 'What business challenges сan be solved:',
    social_responsibility_1: '4Service Corporate Social Responsibility',
    social_responsibility_2: 'From the heart and soul of every member of our team. We are trying to keep a spark of kindness in each of our employees and make the world around us a little better',
    social_responsibility_3: 'Educational activities / Youth support',
    social_responsibility_4: 'Our outreach initiatives for children and students',
    social_responsibility_5: 'Service Mania business game',
    social_responsibility_6: 'One important part of our training activities is the business game Service Mania.',
    social_responsibility_7: 'Service Mania challenges you to think strategically about how to deal with unusual situations.',
    social_responsibility_8: 'Research presentation / Diiya. Business',
    social_responsibility_9: 'A business and customer perspective on the service situation: a presentation of our 4Service research findings by Victoria Skorbota, the Chief of Development at 4Service Ukraine',
    social_responsibility_10: 'Service Design sessions',
    social_responsibility_11: 'The Service Design methodology is an important part of 4Service. A couple of years ago we became a DesignThinkers Academy partner and we not only use best practices in our work but also actively deliver training to the best companies',
    social_responsibility_12: 'Charity / Volunteering',
    social_responsibility_13: 'Orphanages, care homes for the elderly and volunteering for the Ukrainian army. The most touching part of our activities',
    social_responsibility_14: '#HelpEasyWith4ServiceGroup',
    social_responsibility_15: 'We work in partnership with the Blagomai Charity Fund and try to bring celebration and help those who are in dire need',
    social_responsibility_16: 'Fundraising for orphanages and retirement homes',
    social_responsibility_17: 'We at 4Service have initiated several fundraisers for retirement homes and orphanages to buy basic necessities',
    social_responsibility_18: '#SaveUkraine',
    social_responsibility_19: 'The war has affected many of our staff and close friends. Each of us helps as much as we can',
    social_responsibility_20: 'Animal welfare',
    social_responsibility_21: 'Anyone can come to our office with their pet. We help shelters, raise funds for food and sometimes also look for homes for homeless animals',
    social_responsibility_22: 'Supporting animal shelters',
    social_responsibility_23: 'Homeless animals need help just as much as all vulnerable members of our society.',
    social_responsibility_24: 'Our company has taken the Sirius Stray Animal Shelter (Kyiv region, Ukraine) into our care.',
    social_responsibility_25: 'Creative initiatives to help stray animals',
    social_responsibility_26: 'How can we bring the urgent issue of animal shelters to the attention of our customers and partners?',
    social_responsibility_27: 'Pet friendly office',
    social_responsibility_28: 'Many of our team members have pets. We have created a healthy environment where everyone can come into the office with their pets and feel comfortable',
    ux_testing_1: 'UX Testing',
    ux_testing_2: 'Human and AI-based approach to testing and improving your websites and applications',
    ux_testing_3: 'What is UX testing',
    ux_testing_4: 'UX testing helps you to understand how people interact with your product, app or website',
    ux_testing_5: 'The navigation is not very clear',
    ux_testing_6: 'I have no idea how to make an order',
    ux_testing_7: 'Not enough photos in the catalogue',
    ux_testing_8: 'You will:',
    ux_testing_9: 'Discover opportunities to improve',
    ux_testing_10: "Identify problems in the design and customer's interaction",
    ux_testing_11: 'Find out what difficulties the user encounters when interacting with the site',
    ux_testing_12: 'Observe the customer journey',
    ux_testing_13: 'Recognize when and why does your target audience leave the site',
    ux_testing_14: 'Define which sections arouse the least and the highest interest and emotion',
    ux_testing_15: 'User-based UX testing',
    ux_testing_16: '4Service provides you with the real human insights you need to make customer-centric websites and apps using qualitative and quantitative methods',
    ux_testing_17: "I don't understand how to use the site. Where the menu is hidden?",
    ux_testing_18: 'I think there is too much space between the letters, the text is unreadable',
    ux_testing_19: "The pictures overlap each other Is this the way it's supposed to be?",
    ux_testing_20: 'UX testing helps you to understand how people interact with your product, app or website',
    ux_testing_21: 'User-based UX testing research design:',
    ux_testing_22: 'Discover opportunities to improve',
    ux_testing_23: 'Determining the type and method of testing (qualitative/quantitative)',
    ux_testing_24: 'Drafting of hypotheses and user scenarios',
    ux_testing_25: 'Analysis and reporting of test results',
    ux_testing_26: 'Two solutions',
    ux_testing_27: 'We offer two approaches to successful UX testing',
    ux_testing_28: 'User-based UX testing',
    ux_testing_29: 'AI + human-based UX testing',
    ux_testing_30: 'Our strengths:',
    ux_testing_31: 'We can analyse the competitive landscape',
    ux_testing_32: 'We can bring in narrow experts to conduct and participate in interviews',
    ux_testing_33: 'We can conduct interviews in different languages and translate them into the requireв language',
    ux_testing_34: 'with existing usability',
    ux_testing_35: 'metrics or standards',
    ux_testing_36: 'your performance',
    ux_testing_37: "against a competitor's",
    ux_testing_38: 'versions',
    ux_testing_39: 'of the same product',
    ux_testing_40: 'Satisfaction rating',
    ux_testing_41: 'NPS',
    ux_testing_42: 'Success rates',
    ux_testing_43: 'Error rate',
    ux_testing_44: 'Task completion time',
    ux_testing_45: 'User-based UX testing',
    ux_testing_46: 'Quantitative method',
    ux_testing_47: 'We use at least 100 people to participate in UX testing for this stage',
    ux_testing_48: 'Relevant when you need a comparison of your website or app:',
    ux_testing_49: 'Quantitative method measure:',
    ux_testing_50: 'Observation',
    ux_testing_51: 'Spending time with a user or group of users and observing their behavior with the product as they use it in day-to-day life',
    ux_testing_52: 'In-depth interview',
    ux_testing_53: 'Allow you to learn the attitudes, beliefs, desires, and experiences of the users who come to your site. We recommend conducting 15-20 interviews',
    ux_testing_54: 'Ethnographic research',
    ux_testing_55: 'Conducted in the environment of respondents where they will be using the product. By observing, you begin to have a good understanding of the psychology of the user, the emotional challenges they face.',
    ux_testing_56: 'User-based UX testing',
    ux_testing_57: 'Qualitative method',
    ux_testing_58: 'This method helps to understand the motivations and logic of users behavior',
    ux_testing_59: 'We use:',
    ux_testing_60: "We're developing a customer journey map to illustrate the results of our research",
    ux_testing_61: '30 participants (male/female)',
    ux_testing_62: 'Respondents pre-screen and invite them to take the survey',
    ux_testing_63: 'Remote online interviewing on the Wantent platform',
    ux_testing_64: 'AI + human based',
    ux_testing_65: 'AI + human-based UX testing',
    ux_testing_66: 'A combination of unique artificial intelligence technology Wantent and real users',
    ux_testing_67: 'The large font in the headline have attracted attention',
    ux_testing_68: "Users look at the 'loud-speaking' numbers, but do not read the information next to them",
    ux_testing_69: 'UX testing helps you to understand how people interact with your product, app or website',
    ux_testing_70: 'AI + human-based testing research design',
    ux_testing_71: 'How it works',
    ux_testing_72: 'Wantent is an AI-based content-market fit solution.',
    ux_testing_73: 'Wantent estimates the efficiency of websites and apps by applying ML technologies to analyze the audience’s emotional reactions and engagement.',
    ux_testing_74: 'To interpret the results, we use storytelling techniques, diversity, and empathy, to drive performance and growth.',
    ux_testing_75: "The user's attention and emotions during transitions through sections",
    ux_testing_76: 'Statistics by participant group (attention and focus throughout the session)',
    ux_testing_77: 'Analysis of page conversions',
    ux_testing_78: 'Attention and emotions during tasks performing',
    ux_testing_79: 'Findings on the perception',
    ux_testing_80: 'of the website by different groups of participants',
    ux_testing_81: 'Analysis of the ease',
    ux_testing_82: 'of navigating through pages and the ease of finding important elements',
    ux_testing_83: 'A perception analysis',
    ux_testing_84: 'of the information provided on the website',
    ux_testing_85: 'Definition areas',
    ux_testing_86: 'of improvement within individual pages (navigation, information blocks, etc.)',
    ux_testing_87: 'We analyse in detail',
    ux_testing_88: 'Key results of the research',
    ux_testing_89: 'Recommendations for improving the structure of the website and individual pages',
    ux_testing_90: 'We carefully select the target audience for Usability Testing.',
    ux_testing_91: 'We conduct research in different countries, different languages and among different user groups',
    ux_testing_92: 'We can test in applications',
    ux_testing_93: "and on competitors' websites and provide comparative analysis",
    ux_testing_94: 'We combine different research methods.',
    ux_testing_95: 'Our qualified facilitators guide the participant through the test process.',
    ux_testing_96: 'We conduct testing',
    ux_testing_97: 'on a variety of devices and software versions',
    ux_testing_98: 'Why choose us?',
    wantent_1: '4Service x',
    wantent_2: 'Wantent',
    wantent_3: 'A unique partner project from 4Service and Wantent © A combination of artificial intelligence and a human-based approach for content analysis',
    wantent_4: 'measuring success before the content is aired',
    wantent_5: 'mitigating the risks of diversity issues',
    wantent_6: 'deep insights and recommendations to improve the content',
    wantent_7: 'objective decisions on content and marketing strategy',
    wantent_8: 'Wantent is an AI-based content-market fit solution.',
    wantent_9: 'Wantent is an artificial intelligence-based solution for matching content to the market. Wantent evaluates the effectiveness of video content by applying ML technologies to analyse emotional response and audience engagement.',
    wantent_10: 'Wantent helps to define content-market fit and maximize content efficiency by',
    wantent_11: 'Scenario setup & participant recruitment',
    wantent_12: 'personalized project design and audience worldwide',
    wantent_13: 'Gathering viewers’ reactions',
    wantent_14: 'detailed and clear feedback from the audience',
    wantent_15: 'Analysis of the content perception',
    wantent_16: 'behavior and emotional reaction analysis by AI',
    wantent_17: 'Delivery of insights for final wrap up',
    wantent_18: 'recommendations for improvements',
    wantent_19: 'Wantent maximizes your content efficiency',
    wantent_20: 'Ad concept pre-testing',
    wantent_21: 'Creative concept testing',
    wantent_22: 'Checking the consistency of future creative with the developed strategy and understanding the reactions of the target audience for possible adjustments to the creative concepts/messages',
    wantent_23: 'Viewability testing of the brand elements (post-production)',
    wantent_24: 'Confirmation of the effectiveness of the advertising/sponsorship investment',
    wantent_25: 'Ad testing',
    wantent_26: 'A/B testing',
    wantent_27: 'Comparing the effectiveness of creatives and increasing engagement and attention during the production phase',
    wantent_28: 'Launched ad testing',
    wantent_29: 'Defining the messages performance and creating recommendations for improving audio and visuals',
    wantent_30: 'Solutions for TV',
    wantent_31: 'Pilots, promos, new formats, TV presenters testing',
    wantent_32: 'Conducting a comparative analysis to identify the most engaging one and strengthen it',
    wantent_33: 'TV show and feature film testing',
    wantent_34: 'Analyzing the viewers’ involvement, attention level, emotional response during the watching long content in natural conditions. We automatically find the peaks of the audience dump and moments of distraction to evaluate and improve the video mounting',
    wantent_35: 'UI/UX testing',
    wantent_36: 'Website prototypes and mobile applications testing',
    wantent_37: 'Analysis of user reaction and emotions using Wantent technology:',
    wantent_38: 'level of attention',
    wantent_39: 'emotional reactions',
    wantent_40: "heatmaps of participants' gaze direction",
    wantent_41: 'Website and application usability analysis based on participant feedback:',
    wantent_42: 'System usability scale (SUS)',
    wantent_43: 'Client Effort Score (Ease of Interaction)',
    wantent_44: 'Net Promotion Score (NPS)',
    wantent_45: 'GDPR complience and user privacy',
    wantent_46: 'Wantent is fully compliant with the GDPR and CCPA policies and meets the principles of collection, storage, processing, and protection of the participants’ personal data',
    wantent_47: 'Nikita Lobyntsev',
    wantent_48: 'СDO, Reface (Media & Entertainment)',
    wantent_49: 'We experiment with tons of new content ideas with machine-learning technologies every day. Wantent helps us understand and evaluate risks and advantages, discover users’ feedback, behaviour and perception to get causality of different aspects of our solutions.',
    wantent_50: 'Alexander Smirnov',
    wantent_51: 'co-owner TABASCO (Advertising)',
    wantent_52: 'Wantent provides a truly unique service that helps us know very minor details of what consumers like and dislike. There is no way to cheat, there is no way to lie - Wantent always knows the truth, and hereby we as marketing experts get the priceless (hahaha - we know the exact price and it is affordable) guidelines on how to improve our communications.',
    wantent_53: 'Reviews',
    terms_1: 'Terms & conditions',
    terms_2: 'Terms of Use Agreement',
    terms_3: 'Please read these Terms of Use Agreement (“terms of use”, “agreement”) carefully before using website',
    terms_4: '(“website”) operated by 4Service Holdings GmbH ( “4Service”, “us”, ‘we”, “our”).',
    terms_5: 'Conditions of useBy using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. 4Service only grant use and access of this website, its products, and its services to those who have accepted its terms.',
    terms_6: 'Privacy Policy',
    terms_7: 'Before you continue using our website, we advise you to read our Privacy Policy regarding ouruser data collection. It will help you better understand our practices.',
    terms_8: 'Age restriction',
    terms_9: 'You must be at least 16 (sixteen) years of age before you can use this website. By using this website, you warrant that you are at least 16 (sixteen) years of age and you may legally adhere to this Agreement. 4Service assumes no responsibility for liabilities related to age misrepresentation.',
    terms_10: 'Intellectual property',
    terms_11: 'You agree that all materials, products, and services provided on this website are the property of 4Service, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the 4Service’s intellectual property in any way, including electronic, digital, or new trademark registrations.',
    terms_12: 'Accessing our website',
    terms_13: 'Access to our Site is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on our site without notice. We will not be liable if for any reason our site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of our site, or our entire site, to users who have registered with us. You are responsible for making all arrangements necessary for you to have access to our site including the use of equipment which is compatible with our site. You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms, and that they comply with them.',
    terms_14: 'Applicable law',
    terms_15: 'By visiting this website, you agree that the laws of the Republic of Austria, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between 4Service and you.',
    terms_16: 'Disputes',
    terms_17: 'Any dispute related in any way to your visit to this website shall be arbitrated by Commercial court of Vienna.',
    terms_18: 'Indemnification',
    terms_19: 'You agree to indemnify 4Service and its affiliates and hold 4Service harmless against legal claims and demands that may arise from your use or misuse of our website. We reserve the right to select our own legal counsel.',
    terms_20: 'The period of storage',
    terms_21: 'Taking into account the purposes of processing, the period of storage of the Users’ personal data (period of storage) is 24 months since the date the data processing consent is duly obtained from You.',
    terms_22: 'Limitation on liability',
    terms_23: '4Service is not liable for any damages that may occur to you as a result of your misuse of our website. 4Service reserves the right, without notice, to edit, modify, and change this Agreement any time by updating this posting. Your continued use of the website now, or following the posting of any changes or modifications, will indicate acceptance by you of such changes or modifications. If any part of this agreement is declared unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions. Possible evidence of use of the website for illegal purposes will be provided to law enforcement authorities. This Agreement is an understanding between 4Service and the user.',
    terms_24: 'Please direct all privacy/usage related questions and concerns to the following address:',
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Tegetthoffstrasse 7',
    terms_27: '1010 Vienna',
    terms_28: 'Austria',
    terms_29: 'Review',
    terms_30: 'When the personal data You provide us with are not inaccurate then, You are entitled to ask us to correct them indeed (GDPR Article 16).',
    thank_you_1: 'Thank you!',
    thank_you_2: 'We will get back to you within 24 hours',
    thank_you_3: 'P.S. Our managers are already reviewing your request to put together a better offer',
    thank_you_4: 'Menu',
    thank_you_5: 'Woman typing',
    privacy_policy_1: 'Privacy notice form for data subjects (users of the web-site)',
    privacy_policy_2: 'This Privacy Notice Form (Privacy Notice) is intended to natural persons (Data subjects) whose personal data are collected by 4Service Holdings GmbH, in line with the requirements of the GDPR in frames of visiting of the web-site',
    privacy_policy_3: '(here in after Web-site and Users of the Web-site/Users respectively).',
    privacy_policy_4: 'Privacy Notice Form For Data Subjects (Users Of The Web-Site) of 4Service Holdings GmbH is applied in all companies of the 4Service Group, which are under the legal authority under the supervision of or controlled by 4Service Holdings GmbH.',
    privacy_policy_5: 'Contacts',
    privacy_policy_6: '4Service Holdings GmbH',
    privacy_policy_7: '(Company/we/us),',
    privacy_policy_8: 'Address:',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
    privacy_policy_10: 'E-mail:',
    privacy_policy_12: 'Who we are?',
    privacy_policy_13: 'An Individual (You) who visits the Website with any purpose. For the purpose of this Document You shall be also indicated as User.',
    privacy_policy_14: 'Personal data we collect from you',
    privacy_policy_15: 'When you enter to our Web-site, we send You a Privacy notice, in which we offer ask you to provide us a consent for processing of the following information:',
    privacy_policy_16: 'IP address, user’s name, surname, address, phone number (landline or mobile), e-mail address, address, company name, country, e-mail address, first name, last name, phone number, province, state and ZIP/Postal code, usage data, data about interaction with external social networks or platforms, information about registration and auntification on the web-site',
    privacy_policy_17: 'geographic position.',
    privacy_policy_18: 'In case, you express your will and give us such consent, we start to process such information from you.',
    privacy_policy_19: 'Lawfulness (consent)',
    privacy_policy_20: 'When you enter to our Web-site, we send You a Privacy notice, in which we offer ask you to provide us a consent for processing of the following information:',
    privacy_policy_21: 'The legal basis for processing for the personal data is consent, which we are going to get from You through filling in the consent form at the following link:',
    privacy_policy_22: 'If You fill in the consent form, this means that You understand and accept all conditions specified in this Privacy Notice.',
    privacy_policy_23: 'Withdrawal of consent',
    privacy_policy_24: 'You have a right to withdraw the consent You has provided us earlier at any time. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. You may withdraw your consent through sending us the appropriate request at the following e-mail address withdraw@4service-group.com, herewith, the form of the request is available at the following link:',
    privacy_policy_25: 'Withdrawal Form for User',
    privacy_policy_26: 'Purposes of processing',
    privacy_policy_27: 'We process your personal data for the following purposes:',
    privacy_policy_28: '– improve client services (it allows a more effective response to the requests from clients); – personalization of Users’ experience (it allows to determine who is more interesting in services); – improve the Site (it allows to improve the quality of products and services, convenience of their use, develop new Services enhance our products and services); – communicate with User with newsletters, marketing or promotional materials and other information that include our news, updating, information about services with the remark on the instruction how to refuse from receiving follow-up e-mails; – carry out statistical and other kinds of research and analysis based on anonymized data; – provide personalized services to the User and perform agreements and contracts; – participate of the User in various projects implemented by us through the Site, responding to queries addressed by User through the Site, research, maintenance of accounts and records and the promotion of services.',
    privacy_policy_29: 'Subjects the personal data may be transferred to',
    privacy_policy_30: 'While processing your personal data, we transfer your personal data to the entities which act as the Company’s processors. The Company’s processors act exclusively on the basis of the Company’s instructions. The specifics of actions the Company’s processors conduct and list of such processors can be found in the Privacy and Data Protection Policy at the following link: Privacy and Data Protection Policy',
    privacy_policy_31: 'The countries the personal data may be transferred to',
    privacy_policy_32: 'The Company transfers your personal data on the basis of an adequacy decision as its stipulated by the GDPR and EU Commission. Additional information regarding personal data transfer to the USA can be found in the Privacy and Data Protection Policy at the following link: Privacy and Data Protection Policy.',
    privacy_policy_33: 'The period of storage',
    privacy_policy_34: 'Taking into account the purposes of processing, the period of storage of the Users’ personal data (period of storage) is 24 months since the date the data processing consent is duly obtained from You.',
    privacy_policy_35: 'Right of access',
    privacy_policy_36: 'You have a right to know whether personal data concerning You are being processed and 2) if so, access such data with loads of additional stipulations stated in the GDPR Article 15.',
    privacy_policy_37: 'Right to rectification',
    privacy_policy_38: 'When the personal data You provide us with are not inaccurate then, You are entitled to ask us to correct them indeed (GDPR Article 16).',
    privacy_policy_39: 'Right to erasure (right to be forgotten)',
    privacy_policy_40: 'You have a right to obtain from us the erasure of your personal data without undue delay and we shall have the obligation to erase your personal data without undue delay where the grounds, stated in the GDPR Article 17 applies.',
    privacy_policy_41: 'Right to restriction of processing',
    privacy_policy_42: 'You have a right to limit processing of your personal data with several exceptions under the scope of the GDPR in particular stated in the GDPR Article 18.',
    privacy_policy_43: 'We are obliged to inform You what data are being collected, how they are being used, how long they will be kept and whether they will be shared with any third parties. This information must be communicated concisely and in plain language.',
    privacy_policy_44: 'Right to data portability',
    privacy_policy_45: 'You are permitted to obtain and reuse your personal data for your own purposes across different services.',
    privacy_policy_46: 'Right to object',
    privacy_policy_47: 'You have a right to object the processing of personal data that are being processed by the Company. We must stop processing personal data unless we demonstrate compelling legitimate grounds for the processing that overrides the interests, rights and freedoms of the individual or if the processing is for the establishment or exercise of defense of legal claims.',
    privacy_policy_48: 'Right not to be subject to a decision based solely on automated processing',
    privacy_policy_49: 'You have a right to object to any automated profiling that is occurring without consent. Herewith, the You have a right your personal data are to be processed with the human involvement.',
    privacy_policy_50: 'Complaints',
    privacy_policy_51: 'In the event that You wish to make a complaint about how your personal data are being processed by the Company (or by processors, described above), or how your complaint has been handled, You have the right to lodge a complaint directly with the supervisory authority and the Company.',
    privacy_policy_52: 'The details for each of these contacts are:',
    privacy_policy_53: 'Supervisory authority:',
    privacy_policy_54: 'The Austrian Data Protection Authority',
    privacy_policy_55: 'Österreichische Datenschutzbehörde',
    privacy_policy_56: 'Wickenburggasse 8',
    privacy_policy_57: '1080 Vienna',
    privacy_policy_58: 'Austria / Europe',
    privacy_policy_59: 'Company:',
    privacy_policy_60: '4Service Holdings GmbH (Company/we/us),',
    privacy_policy_61: 'Address: Tegetthoffstraße 7, 1010 Vienna, Austria.',
    privacy_policy_62: 'E-mail:',
    privacy_policy_63: 'Privacy and data protection policy',
    privacy_policy_64: 'Read more about how and why we use your data here: Privacy and Data Protection Policy',
    privacy_policy_65: 'Approval',
    privacy_policy_66: 'The Company has developed all internal documents to define roles among staff concerning the personal data processing within the Company, in particular responsible for approving and reviewing the legitimacy of this document is the Managing Director.',
    contacts_1: 'For clients',
    contacts_2: 'For all kinds of businesses, market research agencies and big companies, business partners and media.',
    contacts_3: 'Tegetthoffstrasse 7, Vienna, Austria',
    contacts_4: 'For Shoppers',
    contacts_5: 'Locals and travelers, drivers and students, professional mystery shoppers and homebodies.',
    contacts_6: 'Contact us from your country:',
    contacts_7: 'Italy',
    contacts_8: 'USA',
    contacts_9: 'UK',
    contacts_10: 'Switzerland',
    contacts_11: 'Netherlands',
    contacts_12: 'Slovakia',
    contacts_13: 'Slovenia',
    contacts_14: 'Romania',
    contacts_15: 'AZ',
    contacts_16: 'Ukraine',
    contacts_17: 'Kazakhstan',
    contacts_18: 'Rest of World',
    menu_1: 'CJM',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'BHT',
    menu_5: 'UX testing',
    menu_6: 'HR research',
    menu_7: 'Qualitative research',
    menu_8: 'Quantitative research',
    menu_9: 'Mystery shopping',
    menu_10: 'Voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Price monitoring',
    menu_14: 'About us',
    menu_15: 'Contacts',
    menu_16: 'Blog',
    menu_17: 'Methods',
    menu_18: 'Products',
    menu_19: 'Shoppers \n platform',
    menu_20: 'CSR',
    menu_21: 'Automotive price analysis',
    nps_passives: 'Passives',
    nps_detractors: 'Detractors',
    nps_promoters: 'Promoters',
    nps_a_score: 'a score',
    area: 'Area',
    products_tabs_1: 'In order to achieve maximum efficiency, we also carry out in complex:',
    products_tabs_2: 'Products',
    products_tabs_3: 'Qualitative research is collecting and analyzing non-numerical data to understand concepts, opinions, or experiences',
    products_tabs_4: 'Quantitative research methods is an objective measurement and numerical analysis of data collected through polls, questionnaires, and surveys',
    our_clients_1: 'Our clients',
    preview_cases_section_1: 'Cases',
    preview_cases_section_2: 'Go to the cases to read more interesting posts',
    blog_1: 'Rousing content blog',
    blog_2: 'No posts available for your request',
    about: 'About',
    why_us: 'Why us',
    about_us: 'About us',
    key_features: 'Key features',
    solutions: 'Solutions',
    monitoring: 'Monitoring',
    how: 'How',
    how_it_works: 'How it works',
    when_to_use: 'When to use',
    mystery_shopping: 'Mystery shopping',
    voicer: 'Voicer',
    wantent: 'Wantent',
    play4sales: 'Play4Sales',
    price_monitoring: 'Price monitoring',
    service_design: 'Service design',
    qualitative_research: 'Qualitative research',
    quantitative_research: 'Quantitative research',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Automotive',
    hashtag_retayl: '#Retail',
    hashtag_all_posts: '#All_posts',
    hashtag_feedback: '#feedback',
    hashtag_4Service_csr: '#4Service_CSR',
    hashtag_cases: '#Cases',
    hashtag_market_research: '#market_research',
    more: 'More',
    speak_to_an_expert: 'Speak to an expert',
    book_consultancy: 'Book consultancy',
    read_all: 'Read all',
    find_out_more: 'Find out more',
    address: 'Address',
    mon_fri: 'Mon-Fri',
    phone_number: 'Phone number',
    flag: 'flag',
    scroll_to_left: 'scroll to left',
    online: 'online',
    tel: 'Tel',
    email: 'E-mail',
    light_it_up: 'Light it up',
    about_us_60: 'Alina Andreieva',
    about_us_61: 'Director of business development',
    about_us_62: 'Julia Kravchenko',
    about_us_63: 'Senior project manager',
    about_us_64: 'Olga Aksonova',
    about_us_65: 'Business Development Manager',
    about_us_66: 'Zhelevskiy Dmitriy',
    about_us_67: 'Operations Director',
    about_us_roles_1: 'Management',
    about_us_roles_2: 'Team Global',
    about_us_roles_3: 'Product owners',
    ad_testing_1: 'Ad Testing',
    ad_testing_2: 'An advertising campaign is a huge investment. Make sure that your ads are powerful and deliver maximum ROI',
    ad_testing_3: 'lock',
    ad_testing_4: 'Industries we serve',
    ad_testing_5: '4Service is a global customer experience research holding',
    ad_testing_6: '4Service is global marketing research and CX holding. We offer expert data collection services to large variety of businesses',
    ad_testing_7: 'Cash & Carry',
    ad_testing_8: 'Retail',
    ad_testing_9: 'Electronics',
    ad_testing_10: 'Luxure/Premium',
    ad_testing_11: 'Dining & Hospitality',
    ad_testing_12: 'Automotive',
    ad_testing_13: 'Travel & Leisure',
    ad_testing_14: 'Fashion',
    ad_testing_15: 'Banks',
    ad_testing_16: 'Watches & Jewelry',
    ad_testing_17: 'Household & Appliance',
    ad_testing_18: 'Esomar member',
    ad_testing_19: 'We have conducted Ad Testing research on advertising for various businesses in different areas',
    ad_testing_20: 'You need Ad Testing if you are',
    ad_testing_21: 'Promoting a new product or service',
    ad_testing_22: 'Touching on a sensitive subject',
    ad_testing_23: 'Trying to reach a new target demographic or market',
    ad_testing_24: 'Discussing several different design options',
    ad_testing_25: 'Need a proof of concept for management teams or investors',
    ad_testing_26: 'You will get answers to the questions',
    ad_testing_27: 'Which advertising channels are best suited to these ads?',
    ad_testing_28: 'Are we using the right visuals to get the message across?',
    ad_testing_29: 'Do your advertising texts and messages resonate?',
    ad_testing_30: 'Which advertising channels are best suited to these ads?',
    ad_testing_31: 'What catches their attention in the first place?',
    ad_testing_32: 'Is the placement and layout of the ad easy to understand?',
    ad_testing_33: 'We evaluate',
    ad_testing_34: 'Branding',
    ad_testing_35: 'how your brand is linked to advertising and how much it will be remembered',
    ad_testing_36: 'Key Metrics',
    ad_testing_37: 'Integration',
    ad_testing_38: 'Recognition',
    ad_testing_39: 'Fit',
    ad_testing_40: 'Creative',
    ad_testing_41: "How your advertisement stands out and catches people's attention",
    ad_testing_42: 'Key Metrics',
    ad_testing_43: 'RECOGNITION',
    ad_testing_44: 'LIKING',
    ad_testing_45: 'MTO',
    ad_testing_46: 'MOTIVATION',
    ad_testing_47: 'Customer experience, emotional engagement',
    ad_testing_48: "With the use of artificial intelligence technology and expert analysts, we analyse people's real emotions and get insights into their perceptions of advertising",
    ad_testing_49: 'Rating',
    ad_testing_50: 'Male',
    ad_testing_51: 'Female',
    ad_testing_52: 'Engagement high',
    ad_testing_53: 'Attention',
    ad_testing_54: 'What did the audience like and recall?',
    ad_testing_55: 'Actress',
    ad_testing_56: 'Equality',
    ad_testing_57: 'Musical background',
    ad_testing_58: 'Positive emotions',
    ad_testing_59: 'People of different races and appearance',
    ad_testing_60: 'Outdoor shots',
    ad_testing_61: 'What did the audienceне NOT like and recall?',
    ad_testing_62: "The speaker's voice did not match what was happening on the screen",
    ad_testing_63: 'Low attention to the product',
    ad_testing_64: 'Too dynamic',
    ad_testing_65: 'Difficult to comprehend',
    ad_testing_66: 'TRP',
    ad_testing_67: 'Impressions',
    ad_testing_68: 'Shows',
    ad_testing_69: 'Conversion',
    ad_testing_70: 'Media',
    ad_testing_71: 'We measure the effectiveness of an advertising campaign in conjunction with media activity, calculating the impact of media investments in different channels on the results monitored.',
    ad_testing_72: 'We conduct advertising research in any format, platform or channel',
    ad_testing_73: 'videocontent',
    ad_testing_74: 'outdoor',
    ad_testing_75: 'TV ads',
    ad_testing_76: 'print',
    ad_testing_77: 'digital',
    ad_testing_78: 'ad campaings',
    ad_testing_79: 'POS display and advertising',
    ad_testing_80: 'animatics',
    ad_testing_81: 'With our help, you can test your advertising at any stage',
    ad_testing_82: 'Concept',
    ad_testing_83: 'which ideas have stronger capacity?',
    ad_testing_84: 'Early-stage production',
    ad_testing_85: 'which animatics will go into production?',
    ad_testing_86: 'Production',
    ad_testing_87: 'how to edit a video so that it can retain attention?',
    ad_testing_88: 'Pre-media',
    ad_testing_89: 'which communication channels will work and what outcome can be expected?',
    ad_testing_90: 'Ad Post-Testing',
    ad_testing_91: 'Production',
    ad_testing_92: 'is my advertising captivating? Does the effectiveness of creativity diminish over time?',
    ad_testing_93: 'Pre-media',
    ad_testing_94: 'how effective is my advertising in numbers? how does it compare with the competition?',
    ad_testing_95: 'Ad Pre-Testing',
    ad_testing_96: 'To provide you with the high-quality data, we use',
    ad_testing_97: 'Quantitative research methodology',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'Qualitative research methodology',
    ad_testing_100: 'Focus Groups',
    ad_testing_101: 'AI',
    ad_testing_102: 'Wantent',
    ad_testing_103: 'Analytical reports you receive',
    ad_testing_104: 'Our team of analysts, media experts, marketers will prepare and present you a tailored report with detailed recommendations',
    ad_testing_age: 'age',
    ad_testing_ad: 'Ad',
    automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
    automotive_industry_price_analysis_2: 'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
    automotive_industry_price_analysis_3: 'Request a quote',
    automotive_industry_price_analysis_4: 'Our methodology',
    automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
    automotive_industry_price_analysis_6: 'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
    automotive_industry_price_analysis_7: 'We offer precise figures and dependable multilateral data',
    automotive_industry_price_analysis_8: 'We have global coverage spanning every country in the world',
    automotive_industry_price_analysis_9: 'Our in-house resources include mystery shoppers and auditors',
    automotive_industry_price_analysis_10: 'We validate the quality of our data',
    automotive_industry_price_analysis_11: 'Our advantages:',
    automotive_industry_price_analysis_12: 'Price monitoring for specific competitive models (B2B and B2C)',
    automotive_industry_price_analysis_13: 'Monitoring the level of discount',
    automotive_industry_price_analysis_14: 'Monitoring retailer stock for your brand and competitors',
    automotive_industry_price_analysis_15: 'Monitoring e-commerce automotive prices',
    automotive_industry_price_analysis_16: 'Price communication analysis',
    automotive_industry_price_analysis_17: 'Keeping track of retailer shipping charges',
    automotive_industry_price_analysis_18: 'Monitoring promotions and sales at retailers',
    automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
    automotive_industry_price_analysis_20: 'Calculating lease payments',
    automotive_industry_price_analysis_21: 'Analyzing specific pricing considerations for electric vehicles (EVs)',
    automotive_industry_price_analysis_22: 'Conducting direct-to-consumer versus retailer price comparisons',
    automotive_industry_price_analysis_23: 'Developing subscription pricing models',
    automotive_industry_price_analysis_24: '4Service is a global customer experience (CX) holding company with over 20 years of experience',
    automotive_industry_price_analysis_25: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_26: "4Service's analysis of prices in the automotive industry includes:",
    automotive_industry_price_analysis_27: "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
    automotive_industry_price_analysis_28: 'of customers understand that prices are increasing across all brands',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'of dealers mentioned the possibility of price increases during the communication/selling process',
    automotive_industry_price_analysis_35: '47.6',
    automotive_industry_price_analysis_36: 'Key figures',
    automotive_industry_price_analysis_37: "You'll figure out how to price your product relative to other vehicles in the lineup.",
    automotive_industry_price_analysis_38: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_39: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
    automotive_industry_price_analysis_40: "You'll be able to determine the appropriate monthly payment and leasing rates.",
    automotive_industry_price_analysis_41: 'What benefits for your brand or dealer will you gain:',
    automotive_industry_price_analysis_42: 'You submit an application',
    automotive_industry_price_analysis_43: 'Our manager contacts you to determine the main objectives',
    automotive_industry_price_analysis_44: 'We conduct a marketing research',
    automotive_industry_price_analysis_45: 'We regularly provide you with reports and recommendations in an online format',
    automotive_industry_price_analysis_46: 'Car with sale sticker',
    automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
    automotive_industry_price_analysis_48: 'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
    retail_audit_1: 'FIELD MARKETING AUDIT/retail audit',
    retail_audit_2: 'Collect detailed data on your store or brand, analyze growth barriers, and create effective strategies to overcome them',
    retail_audit_3: 'Request a quote',
    retail_audit_4: '4Service conducts on-site store audits on behalf of brands to assess the effectiveness of their promotional strategies, field execution teams, and marketing tactics in reaching the intended target market.',
    retail_audit_5: 'We meticulously observe the execution of promotional campaigns and ensure adherence to the promotional guidelines.',
    retail_audit_6: 'Top 10',
    retail_audit_7: 'CX agencies in Europe',
    retail_audit_8: 'years experience',
    retail_audit_9: 'countries coverage',
    retail_audit_10: '1,6 milion',
    retail_audit_11: 'evaluations per year',
    retail_audit_12: 'What do we track?',
    retail_audit_13: 'Presence of point-of-sale (POS) materials',
    retail_audit_14: 'Presence and activation of brand ambassadors, ensuring adherence to the planned schedule',
    retail_audit_15: 'Assessment of brand ambassadors` engagement in advocacy campaigns',
    retail_audit_16: 'Evaluation of consumer interaction, ensuring compliance with promotional guidelines and brand messaging',
    retail_audit_17: 'Fraud prevention measures',
    retail_audit_18: 'Your business can use the results of a field marketing audit to:',
    retail_audit_19: 'Assess compliance with budget and processes',
    retail_audit_20: 'Compare actual results with intended objectives',
    retail_audit_21: 'Enhance the performance of marketing campaigns ',
    retail_audit_22: 'Strategically plan for the future',
    retail_audit_23: 'Identify opportunities for cost reduction',
    retail_audit_24: 'Improve sales and conversion rates',
    retail_audit_25: 'Optimize return on investment (ROI)',
    retail_audit_26: 'THEY CHOSE 4SERVICE AS THEIR TRADE INVESTMENT PARTNER',
    retail_audit_27: 'A leading international tobacco product manufacturer partnered with 4Service to enhance its brands and improve its in-store performance. Our teams provided in field marketing audit and consumer experience live reporting.',
    retail_audit_28: 'increase in planning accuracy of the field marketing agency',
    retail_audit_29: 'increase in brand message delivering',
    retail_audit_30: 'increase in BA engagement (consumer activation)',
    retail_audit_31: 'increase in data collection accuracy and GDPR compliance',
    retail_audit_32: 'What valuable insights do we tend to find:',
    retail_audit_33: 'A lack of consistency or even conflict between the terms of the SLA leads to significant unapproved charges being made to the client',
    retail_audit_34: 'Incorrect charging of fees due to mixing up the types of service being provided',
    retail_audit_35: 'Field service not being performed including staff not being assigned to planned location | POS',
    retail_audit_36: 'Charges made on an estimate rather than actual and not reconciled',
    retail_audit_37: 'Incorrect application of the promo guideline providing fraudulent data',
    retail_audit_38: 'How do we work?',
    retail_audit_39: 'Our auditor visits designated point-of-sale (POS) locations based on the agencyʼs activation plan.',
    retail_audit_40: 'They evaluate key performance indicators (KPIs) as outlined in the brief, such as POS presence, brand ambassador engagement, adherence to promotional guidelines, and fraud prevention measures.',
    retail_audit_41: 'Validation:',
    retail_audit_42: 'We gather and consolidate the data obtained.',
    retail_audit_43: 'A comprehensive report is drafted.',
    retail_audit_44: 'Our validation team thoroughly reviews all surveys for accuracy, including compliance with project brief and any additional comments.',
    retail_audit_45: 'Online Reporting System:',
    retail_audit_46: 'Completed and validated questionnaires are uploaded to our online portal within 36 hours.',
    retail_audit_47: 'Clients have access to a personal account portal, enabling them to monitor the progress at any time.',
    retail_audit_48: 'A final report containing results and recommendations is provided.',
    retail_audit_49: 'Speak to an expert',
    retail_audit_50: 'Our strengths:',
  },
  de: {
    form_title: 'Wir werden uns umgehend bei Ihnen melden',
    form_required: 'Mit * gekennzeichnete Felder sind obligatorisch',
    form_name_placeholder: 'Name',
    form_email_placeholder: 'Email',
    form_phone_placeholder: 'Telefonnummer',
    form_company_placeholder: 'Firma',
    form_submit: 'Absenden',
    form_1: 'Ich erteile meine Einwilligung zur Verarbeitung personenbezogener Daten gemäß dem',
    form_2: 'Datenschutz',
    validation_privacy_policy: 'Bitte akzeptieren Sie die Datenschutzerklärung',
    validation_phone: 'Die Telefonnummer ist nicht gültig',
    validation_email: 'Ungültiges E-Mail-Format',
    validation_required: 'Pflichtfelder sind nicht ausgefüllt',
    submit_error: 'Fehler beim Speichern des Formulars',
    footer_1: 'Unsere Partner:',
    footer_2: 'Methoden',
    footer_3: 'Produkte',
    footer_4: 'Kontaktdaten:',
    footer_5: 'Impressum',
    footer_6: 'Datenschutz',
    about_us_1: 'Soziale Unternehmensverantwortung von 4Service',
    about_us_2: 'Mit offenem Herzen und Geist jedes Mitglieds unseres Teams. Wir versuchen, in jedem unserer Mitarbeiter Freundlichkeit zu bewahren und die Welt ein wenig besser zu machen',
    about_us_3: 'Soziale Unternehmensverantwortung von 4Service',
    about_us_4: 'Mit offenem Herzen und Geist jedes Mitglieds unseres Teams. Wir versuchen, in jedem unserer Mitarbeiter Freundlichkeit zu bewahren und die Welt ein wenig besser zu machen',
    about_us_5: 'Businessspiel »ServiceMania»',
    about_us_6: 'Unsere Öffentlichkeitsarbeit für Kinder und Studenten',
    about_us_7: 'Wohltätigkeit / Freiwilligenarbeit',
    about_us_8: 'Waisenhäuser, Altenheime und Freiwilligendienste für die ukrainische Armee. Der berührendste Teil unserer Tätigkeit',
    about_us_9: 'Tierschutz',
    about_us_10: 'Jeder kann mit seinem Haustier zu uns ins Büro kommen. Wir helfen Tierheimen, sammeln Spenden für Futter und suchen auch ein Zuhause für heimatlose Tiere',
    about_us_11: '4Service Group',
    about_us_12: '4Service Group ist ein internationales Forschungsunternehmen, das die Kundenerfahrung verwaltet.',
    about_us_13: 'Wir führen komplexe Projekte zur Umsatzsteigerung und Geschäftsentwicklung kundenorientierter Unternehmen durch.',
    about_us_14: 'ist eine internationale Forschungsholding mit Hauptsitz in Wien, Österreich. Das Unternehmen wurde 2001 mit dem Ziel gegründet, die Lebensqualität seiner Kunden und Menschen zu verbessern, die mit Firmen interagieren, ihre Dienstleistungen nutzen und ihre Produkte konsumieren. Schließlich wurde der Name „Forservice“ nicht umsonst für ein Unternehmen gewählt, das für Kundenservice und gute Qualität steht.',
    about_us_15: '4Service wurde in Kyiv, in der Ukraine, gegründet und entwickelte sich zu einem führenden Anbieter von Mystery Shopping Services. Es ist weiterhin als Forschungsunternehmen in 52 Ländern erfolgreich tätig und bietet neue Möglichkeiten in einem ganzheitlichen Ansatz zur Analyse und Verbesserung aller Phasen der Kundenerfahrung (CX) an.',
    about_us_16: 'geboren im Namen des Dienstes',
    about_us_17: 'erstes Büro außerhalb der Ukraine',
    about_us_18: 'Verlegung des Firmensitzes nach Wien',
    about_us_19: 'elitäres MSPA Mitglied, Top-10 Agenturen in Europa',
    about_us_20: 'Gründung von Büros in 9 Ländern',
    about_us_21: 'erste Digitalprojekte, Voicer',
    about_us_22: 'Partner von DesignThinkers Academy',
    about_us_23: 'erste weltweite CХ-Projekte',
    about_us_24: 'Mission',
    about_us_25: 'Unsere Mission ist den Kundenservice weltweit zu verbessern! Uns inspirieren die qualitativen Veränderungen, die wir bei den Menschen und Unternehmen sehen, mit denen wir zusammenarbeiten.',
    about_us_26: '20+ Jahre Kundenerfahrung',
    about_us_27: '95 Länder',
    about_us_28: 'Wir sammeln und teilen die besten Branchenerfahrungen aus der ganzen Welt',
    about_us_29: 'Hauptsitz in Wien',
    about_us_30: 'Länder. Wir sammeln und teilen die besten Branchenerfahrungen aus der ganzen Welt',
    about_us_31: 'Bewertungen der Servicequalität',
    about_us_32: 'wir gehören zu den Top-10 europäischen CX-Agenturen',
    about_us_33: 'Büros / HQ in Österreich',
    about_us_34: 'aktive Projekte',
    about_us_35: 'Ihr zukünftiger Arbeitsplatz',
    about_us_36: 'Unsere Büros',
    about_us_37: '4Service ™ bemüht sich, einen komfortablen Arbeitsplatz für seine Mitarbeiter zu schaffen. Unsere Mitarbeiter arbeiten im Büro, von Zuhause oder können dies kombinieren, um den besten Komfort und die beste Produktivität jedes Mitarbeiters zu fördern. Räume zum Entspannen, kostenlose psychologische Betreuung und eine einladende Umgebung. Wir sind menschen- und tierfreundlich.',
    about_us_38: 'Team, das strahlt und anpackt',
    about_us_39: 'Unser multinationales Team ist bestrebt zuzuhören, sich auf das Feedback unserer Kunden zu stützen und gemeinsam zu wachsen. Die Karte der Kundenreise ist für unser Geschäft genauso wichtig, wie die Einführung neuer Produkte oder das Erreichen unserer finanziellen Ziele. Unsere Aktivitäten konzentrieren sich darauf, einen positiven Einfluss auf die Gemeinschaften um uns herum zu haben und unseren Kunden Innovationen und menschenorientierte Lösungen zu liefern.',
    about_us_40: 'Alles',
    about_us_41: 'Geschäftsführung',
    about_us_42: 'Team Global',
    about_us_43: 'Produktbesitzer',
    about_us_44: 'Unsere Partner',
    about_us_45: 'Wir führen komplexe Projekte zur Umsatzsteigerung und Geschäftsentwicklung kundenorientierter Unternehmen durch.',
    about_us_46: 'ist eine der führenden „designorientierten“ Berufshochschulen, die in 25 Ländern vertreten ist und kreative multidisziplinäre Teams und Gemeinschaften ausbildet, entwickelt und unterstützt, um positive Veränderungen voranzutreiben. Design Thinking ermöglicht es, innovative Ideen, Änderungen und Lösungen für komplizierte Probleme zu entwickeln und bereitzustellen.',
    about_us_47: 'Ihre Mission ist es, Teams und Einzelpersonen Raum und Zeit zu bieten, um Inspiration und Herausforderungen zu finden, zu lernen durch Handeln und ihre Fähigkeit zur kreativen Problemlösung zu stärken, und Organisationen dabei zu helfen, die notwendige Umgebung und Denkweise aufzubauen, um mutige Innovationen anzustoßen.',
    about_us_48: 'ist die repräsentative Handelsvereinigung für Unternehmen, die in der Mystery-Shopping-Branche tätig sind. Die Vereinigung ist weltweit regional tätig und hat Niederlassungen in Amerika, Europa/Afrika und Asien/Pazifik. Die Mitgliedsunternehmen schließen sich zu einer gemeinsamen Struktur, um die Mystery Shopping-Branche durch gemeinsame Bemühungen und Maßnahmen zu stärken.',
    about_us_49: 'ist eine gemeinnützige Mitgliederorganisation. Die Mitglieder bilden eine Gemeinschaft, die vom Potenzial von Datenanalyse, Forschung und Erkenntnissen überzeugt ist, um Gesellschaften, Organisationen und das Leben aller zu verbessern.',
    about_us_50: 'ESOMAR hat seinen Hauptsitz in Amsterdam, Niederlande. ESOMAR ist seit 1947 durch seine Mitglieder in über 130 Ländern präsent.',
    about_us_51: '(UCCA) ist eine professionelle Gemeinschaft privater Experten und Unternehmen, die sich zum Ziel der Entwicklung und Interaktion im Bereich Kontaktzentrum und Kundenservice zusammengeschlossen haben. Die Philosophie der UCCA ist es, die Kultur der Kontaktzentrum-Branche auf der Grundlage moderner Technologien und hoher Standards zu verbessern.',
    about_us_52: 'Der ukrainische Kontaktzentrumsverband wurde 2010 gegründet',
    about_us_53: 'studiert die beste weltweite Erfahrung im Bildungsbereich, um sie auf dem ukrainischen Markt anzupassen und zu nutzen. Deshalb kooperiert Academy DTEK mit den führenden internationalen Wirtschaftsschulen: INSEAD, IE Wirtschaftsschule und Stanford Universität.',
    about_us_54: 'Academy DTEK konzentriert sich auf die Entwicklung einer kundenorientierten und innovativen Kultur in der D.Client Schule, ID Schule, Executive Education: Energy of Innovation, Power of Communications, DX School Digital Program, Effective Leader: Pre-MBA, Change Energy.',
    about_us_55: 'Serhij Pashkovsky',
    about_us_56: 'Viktoria Skorbota',
    about_us_57: 'Artem Fomin',
    about_us_58: 'Max Papka',
    about_us_59: 'Internationale(-r) Vertriebsmanager(-in)',
    bht_1: 'Brand Health ',
    bht_2: 'Tracking',
    bht_3: 'Marken sind unglaublich mächtig',
    bht_4: 'Erfahren Sie, wie gut Ihre Marke insgesamt funktioniert',
    bht_5: 'Über',
    bht_6: 'Was ist Brand Health Tracking?',
    bht_7: 'Hierbei handelt es sich um eine Art Marktforschung, die mit einer gewissen Regelmäßigkeit (alle zwei Wochen, monatlich etc.) durchgeführt wird, um',
    bht_8: 'anhand von Indikatoren wie Bekanntheit, Nachfrage, Image, Verbraucherprofil, Markengesundheit usw. Informationen über den Status einer Marke auf dem Markt zu erhalten.',
    bht_9: 'Kauft die Marke am häufigsten',
    bht_10: 'Loyal',
    bht_11: 'Hat die Marke in den letzten 3 Monaten gekauft',
    bht_12: 'Stammkunden',
    bht_13: 'Hat die Marke in den letzten 6 Monaten gekauft',
    bht_14: 'Kurzfristige Kunden',
    bht_15: 'Hat die Marke im letzten Jahr gekauft',
    bht_16: 'Langfristige Kunden',
    bht_17: 'Kennt die Marke, hat sie aber noch nie benutzt/gekauft',
    bht_18: 'Vertraut, aber sind keine Kunden',
    bht_19: 'Nicht vertraut: haben noch nie über die Marke gehört',
    bht_20: 'Nicht vertraut',
    bht_21: 'Verbraucherprofil der Marke',
    bht_22: 'Bewertung der Beständigkeit von Anstrengungen, die langfristig unternommen werden, um eine Marke aufzubauen',
    bht_23: 'Messung vom Wissen der Kunden über die Marke',
    bht_24: 'Nachweis der Effektivität der Werbe- und Informationskampagnen',
    bht_25: 'Ermittlung, welche Auswirkungen eine Phase hoher Werbung auf die Verkaufs-/Marketingaktivitäten der Wettbewerber hat',
    bht_26: 'Bereitstellung einer ständigen Kontrolle für den Geschäftsinhaber in Zeiten der kontinuierlichen Marktveränderungen',
    bht_27: 'Welche Aufgaben kann BHT lösen:',
    bht_28: 'Forschung anfordern',
    bht_29: 'Zu lösende Aufgabe',
    bht_30: 'Zum Beispiel,',
    bht_31: 'benötigt ein Unternehmen bei der Messung des Marktanteils nur eine BHT-Sitzung pro Quartal, sollte eine Umfrage der Kunden bei der Messung der Markenbekanntheit durch Werbung häufiger durchgeführt werden.',
    bht_32: 'CATI',
    bht_33: 'Face-to-Face',
    bht_53: 'Befragungen',
    bht_34: 'СAWI',
    bht_35: 'Zu lösende Aufgabe',
    bht_36: 'Durch das Sammeln von Informationen',
    bht_37: 'mit verschiedenen Methoden wird das Markentracking normalerweise in drei Phasen unterteilt, je nach Zielgruppe und wie schwer sie zu erreichen ist:',
    bht_38: 'Wir verwenden sowohl traditionelle als auch moderne Methoden zur Datenerhebung: ',
    bht_39: 'Wir arbeiten mit zwei Modellen, eines mit einem einfachen Satz von Indikatoren, während das andere auf komplexere Branchen wie die Lebensmittel- und Dienstleistungsbranche abzielt. Ein komplexeres Modell beinhaltet eine gründliche Studie, die bis zu 10 geschätzte Indizes umfasst.',
    bht_40: 'Wir bieten',
    bht_41: 'eine Kontrolle über Ausgangsdaten',
    bht_42: 'Wir decken',
    bht_43: 'das ganze Land ab',
    bht_44: 'Wir bieten eine Lösung',
    bht_45: 'für nicht dynamische Märkte/ Märkte mit einer begrenzten Anzahl von Spielern (Brand Point Analyse)',
    bht_46: 'Warum wir?',
    bht_47: 'Warum sollten Sie uns wählen?',
    bht_50: 'Schlechtes Ergebnis',
    bht_51: 'Gutes Ergebnis',
    bht_52: 'Durchschnittliches Ergebnis',
    cases_1: 'Herausforderung: Wie kann das Kauferlebnis von Kunden für Elektrofahrzeuge in Hyundai-Ausstellungsräumen verbessert werden?',
    cases_2: 'Karte der Kundenreise',
    cases_3: 'Ideenfindung',
    cases_4: 'Qualitative Forschung',
    cases_5: 'Herausforderung: Was sollte in den Geschäften geändert werden (Serviceprozesse, Einrichtung, Markenwahrnehmung), um das Interesse der Kunden an der Marke Oppo zu wecken?',
    cases_6: 'Online-CJM',
    cases_7: 'UI-Tests',
    cases_8: 'Befragungen',
    cases_9: 'Herausforderung: Was sollte in den Geschäften geändert werden, um das Interesse der Kunden an der Marke Oppo zu wecken?',
    cases_10: 'Qualitativer Teil',
    cases_11: 'Ideenfindung',
    cases_12: 'Quantitativer Teil',
    cases_13: 'Karte der Kundenreise',
    cases_14: 'Fälle',
    cases_15: 'Alles',
    cases_16: 'Lassen Sie uns gemeinsam an Ihrem großartigen Fall arbeiten',
    csi_1: 'Kunden',
    csi_2: 'zufriedenheitsindex (CSI)',
    csi_3: 'Bestimmen Sie die Gründe für ',
    csi_4: 'Zufriedenheit und Unzufriedenheit der Verbraucher',
    csi_5: 'Kundenzufriedenheitsforschung ',
    csi_6: 'ist die Ermittlung der Faktoren, Ursachen und Motive, die die Kaufentscheidung eines Kunden sowie das Verhalten der Zielgruppe beeinflussen.',
    csi_7: 'Eine Umfrage zur Kundenzufriedenheit ',
    csi_8: 'zeigt, wie zufrieden oder unzufrieden ein Kunde mit einem bestimmten Aspekt des Services oder einem Produkt im Allgemeinen ist.',
    csi_9: 'Die Umfrage zur Kundenzufriedenheit ',
    csi_10: 'wird unter bestehenden Kunden eines Unternehmens und den Nutzern bestimmter Produkte/Dienstleistungen durchgeführt.',
    csi_11: 'Was ist CSI?',
    csi_12: 'Die Kundenzufriedenheit misst, inwieweit die Erwartungen eines Kunden hinsichtlich des Kaufs eines Produktes oder Services Ihres Unternehmens erfüllt wurden.',
    csi_13: 'Glücklicher Mensch',
    csi_14: 'Über',
    csi_15: 'Versteckte Faktoren identifizieren,',
    csi_16: 'die die Kundenzufriedenheit beeinträchtigen können',
    csi_17: 'Gründe für den Anstieg/Rückgang',
    csi_18: 'der Nachfrage nach Waren und Dienstleistungen bestimmen',
    csi_19: 'Geschäftsprozesse eines Unternehmens ',
    csi_20: 'optimieren',
    csi_21: 'Sie an die Bedürfnisse ',
    csi_22: 'des modernen Verbrauchers anpassen',
    csi_23: 'Eine wirkungsvolle Werbebotschaft bilden ',
    csi_24: 'und ein Produkt unter der Kundenanfrage positionieren',
    csi_25: 'Zu lösende Aufgaben',
    csi_26: 'Welche Aufgaben kann die Umfrage zur Kundenzufriedenheit lösen',
    csi_27: 'Mann mit dem Handy',
    csi_28: 'Wir erstellen ein mathematisches Modell ',
    csi_29: 'von Faktoren, die die Kundenzufriedenheit/Kundenbindung beeinflussen können (Regressionsanalyse)',
    csi_30: 'Geschäftsprozesse eines Unternehmens ',
    csi_31: 'optimieren',
    csi_32: 'Wir erstellen einen berechneten Kundenzufriedenheitsindex ',
    csi_33: '(CSI) und vergleichen ihn mit einem deklarativen',
    csi_34: 'Wir forschen ',
    csi_35: 'sowohl im B2B-, als auch im B2C-Bereich.',
    csi_36: 'Unsere Umfragen werden von einem Team ',
    csi_37: 'aus Analysten speziell für die individuellen Projekte unserer Kunden entwickelt',
    csi_38: 'Warum wir',
    csi_39: 'Warum sollten Sie uns wählen?',
    csi_40: 'Methods of collecting information:',
    main_1: 'ihre finanzielle Leistungsfähigkeit verbessern, indem sie aus den Erfahrungen ihrer Kunden lernen',
    main_2: 'СX Beratung und Dienstleistungen 360°-Ansicht Ihres Unternehmens',
    main_3: 'Entdecken Sie unser Angebot an Lösungen und Tools, mit denen wir Ihre geschäftlichen Herausforderungen lösen',
    main_4: 'Produkte',
    main_5: 'Qualitative Forschung sammelt und analysiert nicht-numerische Daten, um Konzepte, Meinungen oder Erfahrungen zu verstehen.',
    main_6: 'Quantitative Forschungsmethoden sind eine objektive Messung und numerische Analyse von Daten, die durch Umfragen und Fragebögen gesammelt werden.',
    main_7: 'Mystery Shopping ist eine Marktforschungstechnik zur Messung und Überwachung der Interaktionen zwischen einem Kunden und einem Unternehmen oder einer Organisation in einem vordefinierten Szenario.',
    main_8: 'Feedback-Management-System. Die Stimme Ihres Kunden in einem System Customer Feedback Management System',
    main_9: 'Eine KI-gestützte Content-Intelligence-Plattform, die Erkenntnisse aus dem Publikum liefert',
    main_10: 'Performance-Management-Software. Gamifizierte Anwendung zur Umsatzsteigerung',
    main_11: 'Preisüberwachung ist der Prozess der Erhebung, Verarbeitung und Analyse der Preise der Wettbewerber.',
    main_12: 'Service Design bedeutet, Bedürfnisse und Wünsche der Menschen zu verstehen, die ein Produkt oder eine Dienstleistung nutzen, indem man Zeit mit ihnen verbringt.',
    main_13: 'Unser analytisches Team wendet die aktuellsten und grundlegendsten Ansätze an, die wir bei vielen Kunden als erfolgreich bewiesen haben.',
    main_14: 'Methoden',
    main_15: 'Karte der Kundenreise',
    main_16: 'Bei der Karte der Kundenreise (CJM) geht es darum, eine Zielgruppe zu definieren, ihre Bedürfnisse und ihr Umfeld zu verstehen und dann einen Plan zu erstellen, um ein ansprechendes Erlebnis zu bieten.',
    main_17: 'Karte der Kundenreise',
    main_18: 'Net Promoter Score',
    main_19: 'Net Promoter Score (NPS) ist der Goldstandard für Kundenerfahrungsmetriken, basierend auf einer Frage: Wie wahrscheinlich würden Sie Unternehmen/Produkte/Dienstleistungen weiterempfehlen?',
    main_20: 'NPS',
    main_21: 'Brand Health Tracking',
    main_22: 'Brand Health Tracking (BHT)-Forschung kann messen, wie Ihre Marke in Bezug auf Bekanntheit und Nutzung, Markenpositionierung und Markenleistung abschneidet',
    main_23: 'BHT',
    main_24: 'Personalforschung',
    main_25: 'Personalforschung dient für die Bewertung von HR-Praktiken und -Leistungen und bietet detaillierte Analysen zu der aktuellen Entwicklung und Management',
    main_26: 'Personalforschung',
    main_27: 'Kundenzufriedenheitsindex',
    main_28: 'Personalforschung dient für die Bewertung von HR-Praktiken und -Leistungen und bietet detaillierte Analysen zu der aktuellen Entwicklung und Management',
    main_29: 'CSI',
    main_30: 'UX-Tests',
    main_31: 'Nutzbarkeit-Tests (UX) zeigen, wie echte Menschen mit einer Website, App oder einem anderen Produkt interagieren und ihr Verhalten und ihre Reaktionen darauf beobachten.',
    main_32: 'Warum wir',
    main_33: 'Unsere Mission ist es, Unternehmen dabei zu helfen, ein hocheffektives Kundenerlebnis zu bieten.',
    main_34: 'Qualität im Fokus',
    main_35: 'Wir kontrollieren die Qualität ',
    main_36: 'der Forschungsdaten. Alle Datensätze stellen wir den Kunden zur Verfügung',
    main_37: 'Kosteneffizienz',
    main_38: 'Unsere Erfahrung, Ressourcen und optimierten',
    main_39: 'Geschäftsprozesse ermöglichen uns, Ihnen Geld und Zeit zu sparen',
    main_40: 'Intelligente Lösungen',
    main_41: 'Wir verwenden verschiedene Techniken ',
    main_42: 'und Instrumente, um Ihre speziellen Probleme zu lösen',
    main_43: 'Qualität im Fokus',
    main_44: 'Wir kontrollieren die Qualität ',
    main_45: 'der Forschungsdaten. Alle Datensätze stellen wir den Kunden zur Verfügung',
    main_46: 'Kosteneffizienz',
    main_47: 'Unsere Erfahrung, Ressourcen und optimierten ',
    main_48: 'Geschäftsprozesse ermöglichen uns, Ihnen Geld und Zeit zu sparen',
    main_49: 'Intelligente Lösungen',
    main_50: 'Wir verwenden verschiedene Techniken ',
    main_51: 'und Instrumente, um Ihre speziellen Probleme zu lösen',
    main_52: 'Cash & Carry',
    main_53: 'Elektronik',
    main_54: 'Luxus/Premium',
    main_55: 'Essen & Gastfreundschaft',
    main_56: 'Einzelhandel',
    main_57: 'Automobil',
    main_58: 'Mode',
    main_59: 'Banken',
    main_60: 'Reisen & Freizeit',
    main_61: 'Uhren & Schmuck',
    main_62: 'Haushalt & Haushaltsgeräte',
    main_63: 'Blog',
    main_64: 'Besuchen Sie den Blog, um weitere interessante Beiträge zu lesen',
    main_65: 'Einzelhandelsaudit',
    main_66: 'Sammeln Sie detaillierte Daten zu Ihrem Geschäft oder Ihrer Marke, analysieren Sie Wachstumsbarrieren und entwickeln Sie wirksame Strategien zu deren Überwindung',
    cjm_1: 'Kunde',
    cjm_2: 'Karte der Kundenreise',
    cjm_3: 'Betreten Sie die Welt der Kunden und teilen Sie ihre Erfahrungen mit',
    cjm_4: 'Was ist eine Karte der Kundenreise?',
    cjm_5: 'CJM bietet eine visualisierte Geschichte der Verbraucherinteraktion mit einem Produkt, einer Dienstleistung, einem Unternehmen oder einer Marke über verschiedene Kanäle und über einen bestimmten Zeitraum.',
    cjm_6: 'Dadurch können Unternehmen die Erfahrung der Kundeninteraktion objektiv analysieren, auftretende Hindernisse beheben und beseitigen und Empfehlungen zur Verbesserung des Produkts geben.',
    cjm_7: 'Bestimmung der Zielgruppe, Segmentierung',
    cjm_8: 'Identifizierung der Phasen der Kundeninteraktion',
    cjm_9: 'Identifizierung der primären Kundeninteraktionskanäle',
    cjm_10: 'Erforschung der Kundenerfahrungen',
    cjm_11: 'Analyse von Hindernissen und Hypothesen zur Optimierung',
    cjm_12: 'Ausführung und Prüfung von Hypothesen',
    cjm_13: 'Die Gestaltung einer Karte der Kundenreise besteht aus folgenden Schritten:',
    cjm_14: 'Die Karte der Kundenreise zeigt:',
    cjm_15: 'CJM bietet eine visualisierte Geschichte der Verbraucherinteraktion mit einem Produkt, einer Dienstleistung, einem Unternehmen oder einer Marke über verschiedene Kanäle und über einen bestimmten Zeitraum.',
    cjm_16: 'Wir sorgen für Teamarbeit',
    cjm_17: 'zwischen Analysten und Designern',
    cjm_18: 'Wir bieten jedem ',
    cjm_19: 'Kunden einen individuellen Ansatz.',
    cjm_20: 'Wir können allgemeine',
    cjm_21: 'Kundenzufriedenheit mit dem Service/Produkt erhöhen und letztendlich die Loyalität gegenüber dem Unternehmen erhöhen.',
    cjm_22: 'Wir garantieren ',
    cjm_23: 'Optimierung des Verbraucherwegs und berücksichtigen Geschäftsprozesse des Unternehmens',
    cjm_24: 'Wir bieten unseren Kunden',
    cjm_25: 'eine Möglichkeit, kombinierte Ansätze zu nutzen: CATI, CAWI, Fokusgruppen, Tiefeninterviews, Experteninterviews, Ethnografie etc.',
    cjm_26: 'Warum sollten Sie uns wählen?',
    methods_hr_1: 'Personalforschung',
    methods_hr_2: 'Erhalten Sie objektive Einblicke in Ihr Team und erfahren Sie, wie die HR-Leistung verbessert werden kann.',
    methods_hr_3: 'Einige Gründe, warum Sie möglicherweise eine Personalforschung benötigen',
    methods_hr_4: 'der befragten Mitarbeiter',
    methods_hr_5: 'HR-Projekte umgesetzt',
    methods_hr_6: 'Schulungen für Mitarbeiter durchgeführt',
    methods_hr_7: 'Führende europäische Holding für Kundenerfahrung- und Mitarbeiterforschung',
    methods_hr_8: '4Service Personalforschung in Zahlen',
    methods_hr_9: 'Um Probleme und Schwächen in jeder Lebensphase der Mitarbeiter Ihres Unternehmens zu identifizieren: Suche/Rekrutierung/Einstieg/Karriere/Entwicklung/Re-Zertifizierungen',
    methods_hr_10: 'Um zu wissen, welche Anlässe Gehaltserhöhungen und Boni verdienen',
    methods_hr_11: 'Sie müssen Lösungen für Unzufriedenheit am Arbeitsplatz vorhersagen oder bereitstellen',
    methods_hr_12: 'Durch HR-Forschung werden neue und aktuelle Methoden der Bewertung von HR-Politiken, -Programmen und -Praktiken möglich',
    methods_hr_13: 'Um psychische Gesundheit der Mitarbeiter im Unternehmen zu verbessern',
    methods_hr_14: 'Wenn Sie die Produktivität durch einen zielorientierten Ansatz verbessern möchten',
    methods_hr_15: 'Wenn Sie ein internationales und/oder Außenteam haben und es verwalten müssen',
    methods_hr_16: 'Um zu verstehen, wie attraktiv Ihre Arbeitgebermarke für potenzielle Mitarbeiter ist',
    methods_hr_17: 'Zufriedenheit der Mitarbeiter',
    methods_hr_18: 'HR-Branding-Forschung',
    methods_hr_19: 'Kulturelle Vielfalt',
    methods_hr_20: 'Vergütungs- und Gehaltspläne',
    methods_hr_21: 'Engagement und Loyalität des Teams',
    methods_hr_22: 'Mitarbeiter der geschützten Klasse',
    methods_hr_23: 'Bereiche der Forschung',
    methods_hr_24: 'Lösung 1',
    methods_hr_25: 'eNPS, Mitarbeiterzufriedenheit, Mitarbeiterloyalität',
    methods_hr_26: 'Was wir messen:',
    methods_hr_27: 'Loyalität:',
    methods_hr_28: 'Mitarbeiterloyalität, Vorhersage des zukünftigen Verhaltens, Bestimmung der Gewichtung von Faktoren, die die Loyalität beeinflussen',
    methods_hr_29: 'Beteiligung:',
    methods_hr_30: 'Beteiligung der Mitarbeiter an der Arbeit, Interesse an Ergebnissen und Leistungen',
    methods_hr_31: 'Engagement:',
    methods_hr_32: 'Identifikation von Mitarbeiterwerten und Unternehmenswerten',
    methods_hr_33: 'Vertrauen 360:',
    methods_hr_34: 'Vertrauen in Management, Kollegen, Untergebene, Vertrauen in Strategien und Richtlinien des Arbeitgebers',
    methods_hr_35: 'Zufriedenheit:',
    methods_hr_36: 'Definition der einzigartigen Faktoren für jedes Unternehmen, Bewertung der Zufriedenheit mit jedem Schlüsselfaktor, der die Loyalität beeinflusst',
    methods_hr_37: 'Lösung 2',
    methods_hr_38: 'Arbeitgeber Branding',
    methods_hr_39: 'Interner Arbeitgeber',
    methods_hr_40: 'Markenanalyse (Mitarbeiter-, Beteiligter-, interne Recruiter- und HR-Spezialisten-Befragungen)',
    methods_hr_41: 'Externe Arbeitgeberanalyse ',
    methods_hr_42: '(Konkurrenten-EVP-Analyse, externe Recruiter-Befragung, Stellensuchende-Befragung)',
    methods_hr_43: 'Markenbekanntheit und Reputations-',
    methods_hr_44: 'analyse',
    methods_hr_45: 'Anwesenheit des Arbeitgebers',
    methods_hr_47: 'Sammlung von ',
    methods_hr_48: 'Arbeitgeberreferenzen',
    methods_hr_49: 'Wettbewerbs-Benchmarking ',
    methods_hr_50: 'zu Schlüsselfaktoren wie ',
    methods_hr_51: 'Gehalt, ',
    methods_hr_52: 'Arbeitsumfeld, Atmosphäre, Art der Arbeit',
    methods_hr_53: 'Analyse der Bedürfnisse und Erwartungen ',
    methods_hr_54: 'von Arbeitssuchenden',
    methods_hr_55: 'Tiefeninterviews, ',
    methods_hr_56: 'Sekundärforschung und quantitative Forschung werden verwendet',
    methods_hr_57: 'Lösung 3',
    methods_hr_58: 'Mitarbeiter Reisekarte/Mystery-Mitarbeiter',
    methods_hr_59: 'Aufbau der Reise des Arbeitssuchenden',
    methods_hr_60: 'Bestimmung der Hauptphasen des Suchprozesses, der Kommunikationskanäle, der Auswahlkriterien und der Bewertung der Arbeitssuchende',
    methods_hr_61: 'Überwachung aller Phasen',
    methods_hr_62: 'und Kanäle der Kommunikation mit Mystery Arbeitssuchenden (einfach einen Termin für ein Vorstellungsgespräch vereinbaren, ein Vorstellungsgespräch bestehen, ein Praktikum in der Firma machen)',
    methods_hr_63: 'Ermittlung ',
    methods_hr_64: 'von Schwachstellen in den Rekrutierungs- und Onboarding-Prozessen',
    methods_hr_65: 'Empfehlungen zur Verbesserung, ',
    methods_hr_66: 'Benchmarking mit Industrieführern',
    mystery_shopping_1: 'Mystery',
    mystery_shopping_2: 'Shopping',
    mystery_shopping_3: 'Bewerten Sie Ihren Service mit echten Kunden',
    mystery_shopping_4: 'Prüfen Sie Standards, beurteilen Sie Ihre Mitarbeiter, werden Sie näher an Ihrem Kunden',
    mystery_shopping_5: 'Wir sind ein Elite-Mitglied der professionell führenden Vereinigung - Mystery Shopping Providers Association (MSPA) Europe.',
    mystery_shopping_6: 'von Erfahrung',
    mystery_shopping_7: 'besucht monatlich',
    mystery_shopping_8: 'Testkäufer weltweit',
    mystery_shopping_9: 'Aktive Projekte',
    mystery_shopping_10: 'Büros, Hauptsitz in Österreich',
    mystery_shopping_11: 'Europäische Mystery-Shopping-Anbieter',
    mystery_shopping_12: 'Bewertungen der Servicequalität',
    mystery_shopping_13: 'Fragebogen',
    mystery_shopping_14: 'Software',
    mystery_shopping_15: 'Käuferprofil',
    mystery_shopping_16: 'Validierung und Widerspruch',
    mystery_shopping_17: 'Analytik',
    mystery_shopping_18: 'Mit diesen 5 Schritten garantiert unsere Firma 4Service die Qualität des Mystery Shopper Services.',
    mystery_shopping_19: 'Programm',
    mystery_shopping_20: 'Die 5 Elemente des Mystery Shopping Programms von 4Service',
    mystery_shopping_21: 'Fragebogen:',
    mystery_shopping_22: 'der Kern Ihres Programms',
    mystery_shopping_23: 'Dieser Teil des Programms ist für alle Beteiligten sichtbar',
    mystery_shopping_24: 'Für Top-Manager.',
    mystery_shopping_25: 'Der Fragebogen sollte alle wichtigen Schritte und Details des Prozesses darstellen',
    mystery_shopping_26: 'Für Mitarbeiter.',
    mystery_shopping_27: 'Der Fragebogen sollte die Erwartungen der Arbeitgeber widerspiegeln',
    mystery_shopping_28: 'Für Testkäufer.',
    mystery_shopping_29: 'Klar und strukturiert, um Verwirrung und Vorurteile zu vermeiden',
    mystery_shopping_30: 'Shopmetrics-Software:',
    mystery_shopping_31: 'benutzerfreundlich und innovativ',
    mystery_shopping_32: 'Vorteile:',
    mystery_shopping_33: 'Integriert sich ',
    mystery_shopping_34: 'in Ihre Geschäftsprozesse und Software',
    mystery_shopping_35: 'GDPR-getrieben',
    mystery_shopping_36: 'Instrumente für das Datenschutzmanagement',
    mystery_shopping_37: 'Ausführlich',
    mystery_shopping_38: 'Paket zur Einhaltung gesetzlicher Vorschriften',
    mystery_shopping_39: 'Bericht',
    mystery_shopping_40: 'Ersteller',
    mystery_shopping_41: 'Verwaltung ',
    mystery_shopping_42: 'der Zugriffsebenen',
    mystery_shopping_43: 'Die meisten Fragebögen ',
    mystery_shopping_44: 'sind innerhalb einer Stunde ausgefüllt',
    mystery_shopping_45: 'Käuferprofil:',
    mystery_shopping_46: 'Über 200.000 Testkäufer weltweit',
    mystery_shopping_47: 'Hauptkriterien: maximale Nähe zu Ihrer Zielgruppe, mit Vorerfahrungen mit Ihrem Unternehmen oder Wettbewerbern',
    mystery_shopping_48: 'Käuferschulung:',
    mystery_shopping_49: 'Einführungstrainingstest',
    mystery_shopping_50: 'Skype/Telefonanruf',
    mystery_shopping_51: 'Einstufungstest (optional)',
    mystery_shopping_52: 'Validierung und Widerspruch',
    mystery_shopping_53: 'Validierung',
    mystery_shopping_54: 'System',
    mystery_shopping_55: '100% der Berichte ',
    mystery_shopping_56: 'werden vom Validierungsteam geprüft',
    mystery_shopping_57: 'Zusätzliche Anrufe ',
    mystery_shopping_58: 'zur Klarstellung',
    mystery_shopping_59: 'Audioaufzeichnungen ',
    mystery_shopping_60: 'nach dem Besuch, um die wichtigsten Punkte zu notieren',
    mystery_shopping_61: 'System zur Verhinderung ',
    mystery_shopping_62: 'von Mitarbeiterbetrug',
    mystery_shopping_63: 'Widerspruch',
    mystery_shopping_64: 'System',
    mystery_shopping_65: 'Jede Käuferbewertung ',
    mystery_shopping_66: 'kann vom Mitarbeiter direkt auf der Webseite widersprochen werden',
    mystery_shopping_67: 'Jeder Widerspruch ',
    mystery_shopping_68: 'wird vom lokalen Validierungsteam geprüft und beantwortet',
    mystery_shopping_69: 'Sie können die Statistik ',
    mystery_shopping_70: 'der Widersprüche einsehen',
    mystery_shopping_71: 'Analytik.',
    mystery_shopping_72: 'Der Bericht wird persönlich vorgelegt und enthält:',
    mystery_shopping_73: 'Schmerzpunkte ',
    mystery_shopping_74: 'und Bedenken des Unternehmens',
    mystery_shopping_75: 'Dynamik ',
    mystery_shopping_76: 'nach Abteilungen und Abschnitten',
    mystery_shopping_77: 'Zusammenhang ',
    mystery_shopping_78: 'zwischen der Erfüllung der Standards und der Kundenzufriedenheit',
    mystery_shopping_79: 'Benchmarking ',
    mystery_shopping_80: 'gegen Mitbewerber',
    mystery_shopping_81: 'Praktische ',
    mystery_shopping_82: 'Empfehlungen zu Serviceverbesserungen',
    mystery_shopping_83: 'Was unterscheidet uns?',
    mystery_shopping_84: 'Wir führen Ideenfindungssitzungen durch',
    mystery_shopping_85: 'Wir empfehlen nicht nur, was zu tun ist, sondern wir suchen gemeinsam mit Ihnen nach praktischen Wegen, um Serviceprobleme zu lösen.',
    mystery_shopping_86: 'Sitzungen zur Ideenfindung sind ein leistungsstarkes Werkzeug, das uns hilft:',
    mystery_shopping_87: 'Richtungen für die Verbesserung von Produkten oder Dienstleistungen zu entwickeln',
    mystery_shopping_88: 'Neue Einnahmequellen und Geschäftsstrategien zu erkunden',
    mystery_shopping_89: 'Lösungen für komplizierte kundenorientierte Herausforderungen zu finden',
    mystery_shopping_90: 'Schmerzpunkte der Kunden in Freuden zu verwandeln',
    mystery_shopping_91: 'Wir führen Video-Befragungen',
    mystery_shopping_92: 'Wir sammeln nicht nur Fragebögen – wir bekommen auch Live-Kommentare von Käufern',
    mystery_shopping_93: 'Wir tun dies, um:',
    mystery_shopping_94: 'Unsere erfahrenen Moderatoren sind in der Lage, hochwertige Tiefeninterviews zu führen und effektives Feedback zu geben',
    mystery_shopping_95: 'Sehen Sie das Erlebnis aus der Perspektive des Kunden',
    mystery_shopping_96: 'Verbessern Sie Ihre Content-Marketing-Strategie',
    mystery_shopping_97: 'Prognostizieren Sie das Kundenverhalten',
    mystery_shopping_98: 'Wir entwerfen die Karte der Kundenreise',
    mystery_shopping_99: 'Die Karte der Kundenreise hilft uns, uns in einen Kunden hineinzuversetzen, um seine Bedürfnisse und Herausforderungen zu verstehen.',
    mystery_shopping_100: 'Das Erstellen einer Kundenreise ist der beste Weg, um:',
    mystery_shopping_101: 'Das Erlebnis aus der Perspektive des Kunden zu sehen',
    mystery_shopping_102: 'Ihre Content-Marketing-Strategie zu verbessern',
    mystery_shopping_103: 'Das Kundenverhalten zu prognostizieren',
    mystery_shopping_104: 'Lücken im Service oder in der Kommunikation zu identifizieren',
    mystery_shopping_105: 'Wir bieten verschiedene Arten von Mystery Shopping an',
    mystery_shopping_106: 'Arten von Mystery Shopping',
    mystery_shopping_107: 'Mystery Anrufer',
    mystery_shopping_108: 'Ruft Ihre Vorgesetzten und/oder das Kontaktzentrum an, um die Servicequalität am Telefon zu ermitteln',
    mystery_shopping_109: 'Luxuriöser Mystery Shopping',
    mystery_shopping_110: 'Prüfung des Services an Luxusstandorten durch eine spezielle Kategorie von Testkäufern',
    mystery_shopping_111: 'Wettbewerbsbesuche',
    mystery_shopping_112: 'Der einfachste Weg, um zu verstehen, wie Ihr Konkurrent arbeitet',
    mystery_shopping_113: 'Mystery Mitarbeiter',
    mystery_shopping_114: 'Hilft Ihnen, den gesamten Weg Ihres Mitarbeiters zu verstehen (Einstellung, Anpassung, Büroprozesse usw.)',
    mystery_shopping_115: 'Motivationsbesuch',
    mystery_shopping_116: 'Ein nützliches Instrument, um das Angebot zusätzlicher Dienstleistungen und/oder Produkte sowie den Verkauf bestimmter Produkte anzuregen',
    mystery_shopping_117: 'Online-Testkäufer',
    mystery_shopping_118: 'Simuliert und misst das Kundenerlebnis in Ihrem Online-Shop, auf Ihrer Webseite oder in Ihrer App',
    mystery_shopping_119: 'Verkaufs- und Werbeaktions-Prüfung',
    mystery_shopping_120: 'Hilft zu erkennen, wie Werbeaktionen an allen Standorten angezeigt werden und wie effektiv sie funktionieren.',
    mystery_shopping_121: 'Einkauf und Rückgabe',
    mystery_shopping_122: 'Hilft dabei, den gesamten Prozess vom Kauf bis zur Rückgabe abzubilden, um das Kundenerlebnis zu verbessern.',
    mystery_shopping_123: '> 20 jahre',
    nps_1: 'Net Promotion',
    nps_2: 'Score',
    nps_3: 'Kundenloyalität messen, verfolgen und verbessern',
    nps_4: 'Sie antworten mit einer Punktzahl von 0 bis 6.',
    nps_5: 'Sie haben möglicherweise schlechte Erfahrungen gemacht und werden wahrscheinlich nicht wieder bei Ihnen kaufen oder andere sogar davon abhalten, bei Ihnen zu kaufen',
    nps_6: 'Dies sind Befragte mit einer Punktzahl zwischen 7 und 8.',
    nps_7: 'Sie sind mit Ihrem Service zufrieden, aber nicht glücklich genug, um als Promoter angesehen zu werden.',
    nps_8: 'Dies sind Befragte, die Ihr Unternehmen mit 9 oder 10 bewerten.',
    nps_9: 'Das bedeutet, dass sie Sie einem Freund oder Kollegen weiterempfehlen würden und sich deshalb für Sie einsetzen.',
    nps_10: 'Was ist NPS?',
    nps_11: 'Es misst die Kundenwahrnehmung anhand einer einfachen Frage:',
    nps_12: 'Wie wahrscheinlich ist es, dass Sie [Organisation/Produkt/Dienstleistung] einem Freund oder Kollegen weiterempfehlen würden?',
    nps_13: 'Braucht es mein Unternehmen?',
    nps_14: 'NPS ist Ihr nachhaltiges Wachstum',
    nps_15: 'Dynamik der Kundenloyalität verstehen',
    nps_16: 'Ihre Mitarbeiter motivieren',
    nps_17: 'Einstellung zu verschiedenen Produkten verstehen',
    nps_18: 'Vergleichen, wie Kunden Sie und Ihre Mitbewerber behandeln',
    nps_19: 'Was kann ich mit NPS machen?',
    nps_20: 'Für welche Art von Unternehmen ist NPS relevant?',
    nps_21: 'Methoden zum Sammeln von Informationen:',
    nps_22: 'Unsere Empfehlungen beim Arbeiten mit NPS',
    nps_23: 'Das bedeutet, dass wir Richtlinien entwickeln, die dabei helfen, Kritiker in Promotern zu verwandeln',
    nps_24: 'Wir arbeiten mit „Kreislauf“-Methode',
    nps_25: 'NPS ist eine Tracking-Forschung, bei der wir Veränderungen in der Kundenloyalität verfolgen.',
    nps_26: 'Wir beobachten die Dynamik',
    nps_27: 'Für eine tiefergehende Analyse empfehlen wir, immer die Wettbewerber in Ihrer Branche zu bewerten',
    nps_28: 'Bewertung der Wettbewerber',
    price_monitoring_1: 'Preisüberwachung',
    price_monitoring_2: 'Einzelhandelsüberwachung',
    price_monitoring_3: 'Erhöhen Sie das Umsatzpotenzial und optimieren Sie Ihre Preispolitik durch Preisbeobachtung der Wettbewerber',
    price_monitoring_4: '4Service bietet seit über 20 Jahren Preisüberwachungsdienste weltweit an',
    price_monitoring_5: 'Die Preise werden innerhalb kurzer Zeit von unserem Team qualifizierter interner Prüfer überwacht, sodass wir schnell auf Preisänderungen der Wettbewerber reagieren können.',
    price_monitoring_6: 'Herausforderungen, die unser Unternehmen mit Preisüberwachung lösen kann',
    price_monitoring_7: 'Bewertung der Preis- ',
    price_monitoring_8: 'und Produktpolitik der Wettbewerber',
    price_monitoring_9: 'Überwachung der Werbeaktionen ',
    price_monitoring_10: 'und Sonderangebote',
    price_monitoring_11: 'Überwachung der Marktbedingungen ',
    price_monitoring_12: 'und des Marktumfelds',
    price_monitoring_13: 'Prüfung und Kontrolle von Verkaufsstellen ',
    price_monitoring_14: 'für Spezialgeräte und Handelswaren',
    price_monitoring_15: 'Verwaltung des Warenflusses ',
    price_monitoring_16: 'innerhalb des Handels',
    price_monitoring_17: 'Optimierung der Bedingungen ',
    price_monitoring_18: 'für eine bessere Zusammenarbeit mit Lieferanten und Händlern',
    price_monitoring_19: 'Messung der Regalfläche, ',
    price_monitoring_20: 'Beurteilung der Warenpositionierung, Lagerverfügbarkeit',
    price_monitoring_21: 'Kennzahlen und Überwachungskriterien',
    price_monitoring_22: 'Alle Daten werden in einem Webinterface zusammengestellt, einschließlich:',
    price_monitoring_23: 'des Namen ',
    price_monitoring_24: 'der Produkt- und Markenkategorie',
    price_monitoring_25: 'der Verfügbarkeit',
    price_monitoring_26: 'von Werbematerialien',
    price_monitoring_27: 'des Preises',
    price_monitoring_28: 'und der Verfügbarkeit der Sonderangebote',
    price_monitoring_29: 'des Regal',
    price_monitoring_30: 'der Größe',
    price_monitoring_31: 'des Produktes',
    price_monitoring_32: 'der Auslage',
    price_monitoring_33: 'des Artikels',
    price_monitoring_34: 'des sichtbaren',
    price_monitoring_35: 'Typen',
    price_monitoring_36: 'der Verpackung',
    price_monitoring_37: 'sonstige ',
    price_monitoring_38: 'allgemeine Informationen',
    price_monitoring_39: 'Analyse vor dem Projekt:',
    price_monitoring_40: 'Wir untersuchen ',
    price_monitoring_41: 'die Preis- und Produktpolitik sowie die Geschäfts- und Branchenpraktiken Ihres Unternehmens',
    price_monitoring_42: 'Wir bieten ',
    price_monitoring_43: 'zeitnahe Schulungen, Tests und Zertifizierungen für Auditoren an',
    price_monitoring_44: 'Wir passen ',
    price_monitoring_45: 'die Software an und richten sie ein',
    price_monitoring_46: 'Die Feldarbeit:',
    price_monitoring_47: 'Besuche von Einzelhandels-',
    price_monitoring_48: 'geschäften',
    price_monitoring_49: 'Berichterstattung:',
    price_monitoring_50: 'Unser analytisches Team ',
    price_monitoring_51: 'erstellt Online-Berichte in den persönlichen Konten des Unternehmens',
    price_monitoring_52: 'Wir bieten ',
    price_monitoring_53: 'einen vollständigen Fotobericht, PPT-Bericht, Excel-Bericht in Bezug auf die Art des Ortes, den Namen der Kette und die Adresse der Filiale an',
    price_monitoring_54: 'Wie es ',
    price_monitoring_55: 'funktioniert',
    price_monitoring_56: 'Größe der individuellen Rabatte',
    price_monitoring_57: 'Kostenkalkulation',
    price_monitoring_58: 'Höhe der einzelnen Boni',
    price_monitoring_59: 'Loyalitätsprogramme',
    price_monitoring_60: 'usw.',
    price_monitoring_61: 'Wir verwenden spezialisierte Software: ',
    price_monitoring_62: 'PRADATA, Shopmetrics und bieten umfassende technische Unterstützung an',
    price_monitoring_63: 'Schnelle Bereitstellung ',
    price_monitoring_64: 'und Skalierung (ab Tag 1)',
    price_monitoring_65: 'Alle Daten sind ',
    price_monitoring_66: 'durch Fotos und GPS-Tagging bestätigt ',
    price_monitoring_67: 'Wir haben die notwendige Anzahl ',
    price_monitoring_68: 'an Außendienstmitarbeitern in jedem Land',
    price_monitoring_69: 'Wir können große ',
    price_monitoring_70: 'Datenmengen verarbeiten ',
    price_monitoring_71: 'Überwachung der einzelnen Preise',
    price_monitoring_72: 'Wenn Sie komplexe Produkte haben, können wir Ihnen helfen, Ihre Preisstrategie zu definieren.',
    price_monitoring_73: 'Wir können Preise einzelner Wettbewerber überwachen, zum Beispiel:',
    price_monitoring_74: 'Niedrige ',
    price_monitoring_75: 'Kosten',
    price_monitoring_76: 'Hohe ',
    price_monitoring_77: 'Effizienz',
    price_monitoring_78: 'Excel-Bericht',
    price_monitoring_79: 'PPT-Bericht',
    price_monitoring_80: 'BI-Bericht',
    price_monitoring_81: 'Preisdaten von Wettbewerbern helfen Ihnen, die besten Preisentscheidungen zu treffen.',
    price_monitoring_82: 'Wir bieten einen vollständigen Fotobericht, PPT-Bericht, Excel-Bericht in Bezug auf die Art des Ortes an',
    qualitative_research_1: 'Qualitative',
    qualitative_research_2: 'Forschungen',
    qualitative_research_3: 'Sammeln, analysieren und interpretieren der Daten, indem man die Handlungen und Worte Ihrer Kunden beobachtet.',
    qualitative_research_4: 'Werte',
    qualitative_research_5: 'Verhalten',
    qualitative_research_6: 'Hintergrund',
    qualitative_research_7: 'Erwartungen',
    qualitative_research_8: 'Umgebung',
    qualitative_research_9: 'Ängste/Hindernisse',
    qualitative_research_10: 'Welchen Zweck hat die Wahl des Kunden?',
    qualitative_research_11: 'Mann mit dem Handy',
    qualitative_research_12: 'Marke',
    qualitative_research_13: 'Kategorie',
    qualitative_research_14: 'Werbung',
    qualitative_research_15: 'Produkt',
    qualitative_research_16: 'Die Einzigartigkeit qualitativer Methoden besteht darin, dass sie uns ermöglichen, die Ursachen und Motivationen des Verbraucherverhaltens zu verstehen.',
    qualitative_research_17: 'Analyse der Einstellung des Verbrauchers zu einem bestimmten Phänomen',
    qualitative_research_18: 'Persönliche Perspektiven und Erfahrungen',
    qualitative_research_19: 'Themen, die sich nicht durch Umfragen untersuchen lassen',
    qualitative_research_20: 'Empfindliche Themen',
    qualitative_research_21: 'Schwer erreichbare Zielgruppe',
    qualitative_research_22: 'Komplexe Geographie',
    qualitative_research_23: 'Tiefeninterviews sind nützlich für:',
    qualitative_research_24: 'Tiefeninterviews',
    qualitative_research_25: 'Ein Tiefeninterview ist eine qualitative Forschungstechnik, bei der Einzelinterviews mit Befragten durchgeführt werden, um ihre Perspektiven über eine bestimmte Idee, ein bestimmtes Programm oder eine bestimmte Situation zu erkunden. In einem Tiefeninterview werden individuelle Erfahrungen/Wahrnehmungen/Praktiken detailliert untersucht.',
    qualitative_research_26: 'Ziele:',
    qualitative_research_27: 'Charakterisierung sozialer und kultureller Normen',
    qualitative_research_28: 'Schwer erreichbare Zielgruppe',
    qualitative_research_29: 'Teilen und Vergleichen (Morgan), untersucht durch Umfragen',
    qualitative_research_30: 'Themen, die sich nicht durch Umfragen untersuchen lassen',
    qualitative_research_31: 'Ausführliche Fokusgruppen sind nützlich für:',
    qualitative_research_32: 'Fokusgruppen',
    qualitative_research_33: 'Tiefe Erforschung einzelner Treffen, aufkommende Diskussionen, Diskussionen zwischen Manifestationen unter dem Einfluss des Gruppenmoderators.',
    qualitative_research_34: 'Generierung von Erkenntnissen über gemeinsame Erfahrungen und soziale Normen durch Gruppendiskussionen, narrative Daten in einer fokussierten Diskussion',
    qualitative_research_36: 'Vorteile:',
    qualitative_research_37: 'Untersuchung des Lebensstils und des Verhaltens des Verbrauchers unter verschiedenen realen Bedingungen (zu Hause, am Verkaufsort, am Arbeitsplatz usw.)',
    qualitative_research_38: 'Suche nach Erkenntnissen für die Entwicklung neuer Produkte, Verpackungen, Serviceverbesserungen usw.',
    qualitative_research_39: 'Ausführliche ethnographische Forschung ist nützlich für:',
    qualitative_research_40: 'Ethnographische Forschung',
    qualitative_research_41: 'Ethnographie ist eine Art qualitativer Forschung, bei der man in eine bestimmte Gemeinschaft oder Organisation eintaucht, um deren Verhalten und Interaktionen aus der Nähe zu beobachten.',
    qualitative_research_42: 'Ethnographie ist eine flexible Forschungsmethode, mit der Sie ein tiefes Verständnis für die gemeinsame Kultur, Konventionen und soziale Dynamik einer Gruppe gewinnen können. Sie bringt jedoch auch einige praktische und ethische Herausforderungen.',
    qualitative_research_43: 'Methoden:',
    qualitative_research_44: 'Ethnographie ist eine Untersuchung des Verbrauchers unter realen Bedingungen und seiner Interaktion mit einem Produkt / einer Dienstleistung.',
    qualitative_research_45: 'Vorteile von der Zusammenarbeit mit uns',
    qualitative_research_46: 'Wir finden Befragte aus jeder Zielgruppe. Und wir kontrollieren die Rekrutierungsqualität',
    qualitative_research_47: 'Rekrutierung beliebiger Komplexität',
    qualitative_research_48: 'Unsere Moderatoren sind mehrsprachig und verfügen über Moderationsfähigkeiten',
    qualitative_research_49: 'Erfahrene Moderatoren',
    qualitative_research_50: 'Wir arbeiten online/offline',
    qualitative_research_51: 'Wir stellen Audio-/Videoaufnahmen',
    qualitative_research_52: 'zur Verfügung',
    qualitative_research_53: 'Wir können qualitative Forschung an jedem Ort durchführen',
    quantitative_research_1: 'Untersuchung der Kundenzufriedenheit ',
    quantitative_research_2: 'mit einem bestimmten Produkt oder Service (SCI, NPS)',
    quantitative_research_3: 'Durchführung von Segmentierungserhebungen',
    quantitative_research_4: 'in verschiedenen Märkten',
    quantitative_research_5: 'Marktforschung',
    quantitative_research_6: 'NPS-Management',
    quantitative_research_7: 'Wettbewerbsanalyse',
    quantitative_research_8: 'Quantitative',
    quantitative_research_9: 'Forschungen',
    quantitative_research_10: 'Forschungsmethodik, mit der Theorien über Einstellungen und Verhaltensweisen von Menschen anhand objektiver numerischer und statistischer Daten getestet wurden',
    quantitative_research_11: 'Jahre',
    quantitative_research_12: 'der CX-Erfahrung weltweit',
    quantitative_research_13: 'Panel',
    quantitative_research_14: 'der Befragten',
    quantitative_research_15: 'Länder',
    quantitative_research_16: 'Wir führen Projekte auf der ganzen Welt durch',
    quantitative_research_17: 'Wer wir sind',
    quantitative_research_18: '4Service ist eine globale Marketingforschungs- und CX-Holding. Wir bieten fachkundige Datenerfassungsdienste für eine Vielzahl von Unternehmen an',
    quantitative_research_19: '4Service in Zahlen',
    quantitative_research_20: 'Computerunterstützte ',
    quantitative_research_21: 'Web-Interviews',
    quantitative_research_22: 'Eine internetbasierte Fragebogentechnik. Der Befragte füllt einen elektronischen Fragebogen ohne Interviewer aus. Der Fragebogen kann elektronisch übermittelt oder auf der Webseite veröffentlicht werden.',
    quantitative_research_23: 'Computerunterstützte Web-Interviews',
    quantitative_research_24: 'In den meisten Fällen ',
    quantitative_research_25: 'ist diese Methode finanziell und zeitlich am günstigsten',
    quantitative_research_26: 'Diese Umfrage gibt ',
    quantitative_research_27: 'eine Möglichkeit, ein schwer zu erreichendes Publikum zu befragen',
    quantitative_research_28: 'Es bietet umfangreiche Möglichkeiten',
    quantitative_research_29: 'zur Demonstration von Video- und Audiomaterial, sowie Bilddemonstrationen',
    quantitative_research_30: 'Die Befragten können sich ',
    quantitative_research_31: 'überall auf der Welt befinden',
    quantitative_research_32: 'Es erlaubt, das spezifische Verhalten ',
    quantitative_research_33: 'des Internetpublikums zu untersuchen. Die wichtigsten Ergebnisse sind für den Kunden in Echtzeit über ein Webinterface abrufbar.',
    quantitative_research_34: 'Geeignet für alle vertraulichen, ',
    quantitative_research_35: 'sensiblen, persönlichen Themen oder Probleme, die die Befragten im Gespräch mit einem Interviewer möglicherweise nur zögerlich besprechen',
    quantitative_research_36: '150 000+ Befragten',
    quantitative_research_37: 'Wir haben unsere eigene Gruppe von Befragten weltweit.',
    quantitative_research_38: 'Wir arbeiten mit anspruchsvollen Anfragen',
    quantitative_research_39: 'Durch den Einsatz ergänzender Instrumente (soziale Medien, Werbung) können wir eine spezifische Zielgruppe und ein ausgefeiltes Profil des Befragten finden.',
    quantitative_research_40: 'Wir motivieren unsere Befragten',
    quantitative_research_41: 'Wir haben ein System entwickelt, um das Ausfüllen langer Umfragen durch materielle Anreize zu fördern.',
    quantitative_research_42: 'Persönliche Befragungen',
    quantitative_research_43: 'werden mit Tablets durchgeführt ',
    quantitative_research_44: 'Dies ist eine der Hauptmethoden zur Erhebung quantitativer Daten, bei der der Interviewer direkt nach einem streng strukturierten Fragebogen mit dem Befragten kommuniziert.',
    quantitative_research_45: 'Persönliche Interviews können am Arbeits- oder Wohnort der Befragten, auf der Straße oder an ausgewiesenen Verkaufsstellen per Tablet durchgeführt werden.',
    quantitative_research_46: 'Persönliche Interviews mit der Verwendung vom Tablet',
    quantitative_research_47: 'Ermittlung des Bekanntheitsgrades ',
    quantitative_research_48: 'und der Markenwahrnehmung',
    quantitative_research_49: 'Kundensegmentierung ',
    quantitative_research_50: 'nach ihrem Kaufverhalten',
    quantitative_research_51: 'Analyse der Effektivität ',
    quantitative_research_52: 'einer Werbekampagne',
    quantitative_research_53: 'Messung ',
    quantitative_research_54: 'der Kundenzufriedenheit',
    quantitative_research_55: 'Bestimmung des besten Preises ',
    quantitative_research_56: 'für das Produkt oder die Dienstleistung',
    quantitative_research_57: 'Analyse ',
    quantitative_research_58: 'der Verbraucherpräferenzen',
    quantitative_research_59: 'Untersuchung ',
    quantitative_research_60: 'des Kaufverhaltens der Kunden',
    quantitative_research_61: 'Bewertung ',
    quantitative_research_62: 'der Marktkapazität',
    quantitative_research_63: 'DigSee-Software',
    quantitative_research_64: 'Wir verwenden eine spezielle Software mit Audioaufnahme und GPS',
    quantitative_research_65: 'Interviewer und Vorgesetzte',
    quantitative_research_66: 'Wir können Interviews in beliebiger Sprache durchführen und in die vom Kunden bevorzugte Sprache übersetzen',
    quantitative_research_67: 'Probenahme',
    quantitative_research_68: 'Die analytische Abteilung entwickelt ein System für die Rekrutierung von Befragten, um die bestmöglichen Ergebnisse zu erzielen',
    quantitative_research_69: 'Computerunterstützte ',
    quantitative_research_70: 'telefonische Befragung',
    quantitative_research_71: 'Methodik zum Sammeln quantitativer Informationen durch Telefoninterviews mit einem klar strukturierten Fragebogen.',
    quantitative_research_72: 'Computerunterstützte telefonische Befragung',
    quantitative_research_73: 'Wir haben unsere eigenen Callcenter',
    quantitative_research_74: 'Wir verwenden eine spezielle Software mit Audioaufnahme und GPS',
    quantitative_research_75: 'Qualifizierte Mitarbeiter',
    quantitative_research_76: 'Unsere Mitarbeiter sprechen verschiedene Sprachen, sind geschult und geprüft und verbessern ihre Fähigkeiten kontinuierlich',
    quantitative_research_77: 'Datenbanken',
    quantitative_research_78: 'Wir arbeiten mit unseren eigenen Datenbanken und Ihren Kundenstämmen',
    quantitative_research_79: 'SLA',
    quantitative_research_80: 'Wir garantieren Qualität und unterzeichnen ein SLA (Service Level Agreement)',
    quantitative_research_81: 'Zielsetzung',
    quantitative_research_82: 'Definieren der Methodik',
    quantitative_research_83: 'Segmentierung und Probenahme',
    quantitative_research_84: 'Definieren von Datenerfassungskanälen',
    quantitative_research_85: 'Gestaltung eines Fragebogens, der für den Befragten logisch ist',
    quantitative_research_86: 'Prozess der Datenerfassung',
    quantitative_research_87: 'Qualitätssicherung',
    quantitative_research_88: 'Analysetechniken',
    quantitative_research_89: 'Datenverarbeitung',
    quantitative_research_90: 'Design der Umfrage',
    quantitative_research_91: 'GPS-Standortverfolgung von Interviewern',
    quantitative_research_92: 'Datenüberprüfung',
    quantitative_research_93: 'Fragebogenprogrammierung und Testen',
    quantitative_research_94: 'Datenvalidierung',
    quantitative_research_95: 'Audioaufnahmen unter Einhaltung der GDPR-Anforderungen',
    quantitative_research_96: 'Ausbildung und Kompetenz des Forschungspersonals',
    quantitative_research_97: 'Qualitätssicherung',
    quantitative_research_98: 'Faktorenanalyse',
    quantitative_research_99: 'Segmentierung',
    quantitative_research_100: 'Wahrnehmbare Zuordnung',
    quantitative_research_101: 'Korrelation',
    quantitative_research_102: 'Umwandlungsmodell',
    quantitative_research_103: 'Jaccard',
    quantitative_research_104: 'Cluster-Analyse',
    quantitative_research_105: 'usw.',
    quantitative_research_106: 'Analytische Berichte',
    quantitative_research_107: 'Wir verwenden SPSS® und andere Instrumente, um Analyseberichte mit unterschiedlichen Methoden zu erstellen',
    quantitative_research_108: 'What business challenges сan be solved:',
    quantitative_research_109: 'What business challenges сan be solved:',
    social_responsibility_1: 'Soziale Unternehmensverantwortung von 4Service',
    social_responsibility_2: 'Mit offenem Herzen und Geist jedes Mitglieds unseres Teams. Wir versuchen, in jedem unserer Mitarbeiter Freundlichkeit zu bewahren und die Welt ein wenig besser zu machen',
    social_responsibility_3: 'Bildungsaktivitäten/ Jugendunterstützung',
    social_responsibility_4: 'Unsere Öffentlichkeitsarbeit für Kinder und Studenten',
    social_responsibility_5: 'Businessspiel «ServiceMania»',
    social_responsibility_6: 'Ein wichtiger Bestandteil unserer Trainingsaktivitäten ist das Businessspiel »ServiceMania».',
    social_responsibility_7: '«Service Mania zwingt Sie dazu, strategisch zu überlegen, wie Sie mit ungewöhnlichen Situationen umgehen.',
    social_responsibility_8: 'Forschungspräsentation/ Diia.Business',
    social_responsibility_9: 'Eine Geschäfts- und Kundenperspektive auf einer Servicesituation: eine Präsentation der 4Service Forschungsergebnisse von Victoria Skorbota, Entwicklungsleiterin bei 4Service Ukraine',
    social_responsibility_10: 'Sitzungen zum Service-Design',
    social_responsibility_11: 'Die Service-Design Methodik ist ein wichtiger Bestandteil von 4Service. Vor einigen Jahren wurden wir Partner der DesignThinkers Academy und wir wenden nicht nur die besten Praktiken in unserer Arbeit an, sondern führen auch aktiv Schulungen für die besten Unternehmen durch',
    social_responsibility_12: 'Wohltätigkeit/ Freiwilligenarbeit',
    social_responsibility_13: 'Waisenhäuser, Altenheime und Freiwilligendienste für die ukrainische Armee. Der berührendste Teil unserer Tätigkeit',
    social_responsibility_14: '#HelpEasyWith4ServiceGroup',
    social_responsibility_15: 'Wir arbeiten mit dem Blagomai Wohltätigkeitsfonds zusammen und versuchen, den Menschen in Not Glück und Hilfe zu geben',
    social_responsibility_16: 'Spendensammlung für Waisenhäuser und Altersheime',
    social_responsibility_17: 'Wir haben mehrere Spendenaktionen für Altersheime und Waisenhäuser initiiert, um Grundbedarfsartikel zu kaufen',
    social_responsibility_18: '#SaveUkraine',
    social_responsibility_19: 'Viele unserer Mitarbeiter und engen Freunde sind vom Krieg betroffen. Jeder von uns hilft so viel er kann',
    social_responsibility_20: 'Tierschutz',
    social_responsibility_21: 'Jeder kann mit seinem Haustier zu uns ins Büro kommen. Wir helfen Tierheimen, sammeln Spenden für Futter und suchen manchmal auch ein Zuhause für heimatlose Tiere.',
    social_responsibility_22: 'Unterstützung von Tierheimen',
    social_responsibility_23: 'Obdachlose Tiere brauchen ebenso Hilfe wie alle schutzbedürftigen Mitglieder unserer Gesellschaft.',
    social_responsibility_24: 'Unsere Firma hat das Tierheim Sirius Stray Animal Shelter (Kyiv Region, Ukraine) in unsere Obhut genommen. ',
    social_responsibility_25: 'Kreative Initiativen zur Hilfe für streunende Tiere',
    social_responsibility_26: 'Wie können wir unsere Kunden und Partner auf das dringende Thema Tierheime aufmerksam machen?',
    social_responsibility_27: 'Tierfreundliches Büro',
    social_responsibility_28: 'Viele unserer Mitarbeiter haben Haustiere. Wir haben ein gesundes Umfeld geschaffen, in dem jeder mit seinen Haustieren ins Büro kommen und sich wohlfühlen kann.',
    ux_testing_1: 'UX-Tests',
    ux_testing_2: 'Menschlicher und KI-basierter Ansatz zum Testen und Verbessern Ihrer Webseiten und Anwendungen',
    ux_testing_3: 'Was ist UX-Test?',
    ux_testing_4: 'UX-Tests helfen Ihnen zu verstehen, wie Menschen mit Ihrem Produkt, Ihrer App oder Ihrer Webseite interagieren.',
    ux_testing_5: 'Die Navigation ist nicht sehr übersichtlich',
    ux_testing_6: 'Ich habe keine Ahnung, wie man bestellt',
    ux_testing_7: 'Zu wenig Fotos im Katalog',
    ux_testing_8: 'Sie werden:',
    ux_testing_9: 'Verbesserungsmöglichkeiten entdecken',
    ux_testing_10: 'Probleme im Design und in der Kundeninteraktion identifizieren',
    ux_testing_11: 'Herausfinden, auf welche Schwierigkeiten der Benutzer bei der Interaktion mit der Webseite stößt',
    ux_testing_12: 'Die Kundenreise beobachten',
    ux_testing_13: 'Erkennen, wann und warum Ihre Zielgruppe die Seite verlässt',
    ux_testing_14: 'Definieren, welche Abschnitte am wenigsten und am meisten Interesse und Emotionen hervorrufen',
    ux_testing_15: 'Benutzerbasierte UX-Tests',
    ux_testing_16: '4Service liefert Ihnen echte menschliche Einblicke, die Sie benötigen, um kundenorientierte Webseiten und Anwendungen mit qualitativen und quantitativen Methoden zu erstellen.',
    ux_testing_17: 'Ich verstehe die Verwendung der Webseite nicht. Wo ist das Menü versteckt?',
    ux_testing_18: 'Ich denke, es gibt zu viel Platz zwischen den Buchstaben, der Text ist nicht lesbar',
    ux_testing_19: 'Die Bilder überlagern sich. Ist das so vorgesehen?',
    ux_testing_20: 'UX-Tests helfen Ihnen zu verstehen, wie Menschen mit Ihrem Produkt, Ihrer App oder Ihrer Webseite interagieren',
    ux_testing_21: 'Forschungsdesign für benutzerbasierte UX-Tests:',
    ux_testing_22: 'Entdeckung der Verbesserungsmöglichkeiten',
    ux_testing_23: 'Bestimmung von Art und Methode der Prüfung (qualitativ/ quantitativ)',
    ux_testing_24: 'Erstellung von Hypothesen und Nutzerszenarien',
    ux_testing_25: 'Analyse und Berichterstattung über Testergebnisse',
    ux_testing_26: 'Zwei Lösungen',
    ux_testing_27: 'Wir bieten zwei Ansätze für erfolgreiche UX-Tests an',
    ux_testing_28: 'Benutzerbasierte UX-Tests',
    ux_testing_29: 'KI + menschenbasierte UX-Tests',
    ux_testing_30: 'Unsere Stärken:',
    ux_testing_31: 'Wir können das Wettbewerbsumfeld analysieren',
    ux_testing_32: 'Wir können ausgewählte Experten hinzuziehen, um Interviews durchzuführen und daran teilzunehmen',
    ux_testing_33: 'Wir führen Interviews in verschiedenen Sprachen und übersetzen sie in die gewünschte Sprache',
    ux_testing_34: 'mit vorhandenen Nutzbarkeit-Metriken',
    ux_testing_35: 'oder Standards',
    ux_testing_36: 'Ihre Leistung ',
    ux_testing_37: 'im Vergleich zu einem Mitbewerber',
    ux_testing_38: 'Versionen ',
    ux_testing_39: 'des gleichen Produktes',
    ux_testing_40: 'Zufriedenheitsbewertung',
    ux_testing_41: 'NPS',
    ux_testing_42: 'Erfolgsrate',
    ux_testing_43: 'Fehlerrate',
    ux_testing_44: 'Abschlusszeit der Aufgabe',
    ux_testing_45: 'Benutzerbasierte UX-Tests',
    ux_testing_46: 'Qualitative Methode',
    ux_testing_47: 'Wir setzen mindestens 100 Personen ein, um an UX-Tests für diese Phase teilzunehmen',
    ux_testing_48: 'Es ist relevant, wenn Sie einen Vergleich Ihrer Webseite oder App benötigen',
    ux_testing_49: 'Maße der Quantitativen Methode:',
    ux_testing_50: 'Beobachtung',
    ux_testing_51: 'Verbringen der Zeit mit einem Benutzer oder einer Gruppe von Benutzern und beobachten ihr Verhalten mit dem Produkt bei der täglichen Verwendung',
    ux_testing_52: 'Tiefeninterview',
    ux_testing_53: 'Sie können die Einstellungen, Überzeugungen, Wünsche und Erfahrungen der Benutzer kennenlernen, die Ihre Webseite besuchen. Wir empfehlen 15-20 Interviews zu führen',
    ux_testing_54: 'Ethnografische Forschung',
    ux_testing_55: 'Durchgeführt in der Umgebung der Befragten, wo sie das Produkt verwenden werden. Durch das Beobachten bekommen Sie ein gutes Verständnis für die Psychologie des Benutzers, emotionale Herausforderungen, denen er gegenübersteht',
    ux_testing_56: 'Benutzerbasierte UX-Tests',
    ux_testing_57: 'Qualitative Methode',
    ux_testing_58: 'Diese Methode hilft, die Motivationen und die Logik des Benutzerverhaltens zu verstehen.',
    ux_testing_59: 'Wir verwenden:',
    ux_testing_60: 'Wir entwickeln eine Karte der Kundenreise, um die Ergebnisse unserer Forschung zu veranschaulichen.',
    ux_testing_61: '30 Teilnehmer (männlich/weiblich)',
    ux_testing_62: 'Die Befragten bestehen eine Vorabprüfung und werden zur Teilnahme an der Umfrage eingeladen.',
    ux_testing_63: 'Remote Online-Interviews auf der Wantent-Plattform',
    ux_testing_64: 'KI + menschenbasierte',
    ux_testing_65: 'KI + menschenbasierte UX-Tests',
    ux_testing_66: 'Eine Kombination aus einzigartiger Technologie der künstlichen Intelligenz Wantent und echten Benutzern',
    ux_testing_67: 'Die große Schrift in der Überschrift hat Aufmerksamkeit erregt',
    ux_testing_68: 'Nutzer schauen auf die «laut sprechenden» Zahlen, lesen aber die Informationen daneben nicht «г»',
    ux_testing_69: 'UX-Tests helfen Ihnen zu verstehen, wie Menschen mit Ihrem Produkt, Ihrer App oder Ihrer Webseite interagieren.',
    ux_testing_70: 'Forschungsdesign für KI + menschenbasierte Tests',
    ux_testing_71: 'Wie es funktioniert',
    ux_testing_72: 'Wantent ist eine KI-basierte Lösung für den Content-Markt.',
    ux_testing_73: 'Wantent schätzt Effizienz von Webseiten und Apps durch den Einsatz von ML-Technologien zur Analyse der emotionalen Reaktionen und des Engagements der Zielgruppe.',
    ux_testing_74: 'Um die Ergebnisse zu interpretieren, nutzen wir Storytelling-Techniken, Diversität und Empathie, um Leistung und Wachstum voranzutreiben.',
    ux_testing_75: 'Aufmerksamkeit und Emotionen des Benutzers beim Laufen durch Abschnitte',
    ux_testing_76: 'Statistiken nach Teilnehmergruppe (Aufmerksamkeit und Fokus während der gesamten Sitzung)',
    ux_testing_77: 'Analyse der Seitenumwandlungen',
    ux_testing_78: 'Aufmerksamkeit und Emotionen während der Aufgabenerfüllung',
    ux_testing_79: 'Erkenntnisse zur Wahrnehmung ',
    ux_testing_80: 'der Webseite durch verschiedene Teilnehmergruppen',
    ux_testing_81: 'Analyse der Leichtigkeit ',
    ux_testing_82: 'der Navigation durch Seiten und der Leichtigkeit, wichtige Elemente zu finden',
    ux_testing_83: 'Analyse der Wahrnehmung ',
    ux_testing_84: 'der auf der Webseite bereitgestellten Informationen',
    ux_testing_85: 'Definierung ',
    ux_testing_86: 'der Verbesserungsbereiche innerhalb einzelner Seiten (Navigation, Infoblöcke usw.)',
    ux_testing_87: 'Wir analysieren im Detail',
    ux_testing_88: 'Wichtigste Ergebnisse der Forschung',
    ux_testing_89: 'Empfehlungen zur Verbesserung der Struktur der Webseite und einzelner Seiten',
    ux_testing_90: 'Wir wählen die Zielgruppe für UX-Tests sorgfältig aus.',
    ux_testing_91: 'Wir forschen in verschiedenen Ländern, auf verschiedenen Sprachen und unter verschiedenen Nutzergruppen',
    ux_testing_92: 'Wir können Anwendungen',
    ux_testing_93: 'und Webseiten von Wettbewerbern testen und Vergleichsanalyse bereitstellen',
    ux_testing_94: 'Wir kombinieren verschiedene Forschungsmethoden.',
    ux_testing_95: 'Unsere qualifizierten Moderatoren begleiten den Teilnehmer durch den Testprozess.',
    ux_testing_96: 'Wir testen ',
    ux_testing_97: 'auf einer Vielzahl von Geräten und Softwareversionen',
    ux_testing_98: 'Warum sollten Sie uns wählen',
    wantent_1: '4Service x',
    wantent_2: 'Wantent',
    wantent_3: 'Ein einzigartiges Partnerprojekt von 4Service und Wantent © Eine Kombination aus künstlicher Intelligenz und einem menschenbasierten Ansatz zur Content Analyse',
    wantent_4: 'Messen des Erfolgs, bevor der Content ausgestrahlt wird',
    wantent_5: 'Minderung der Risiken von Diversitätsproblemen',
    wantent_6: 'tiefe Einblicke und Empfehlungen zur Verbesserung des Contents',
    wantent_7: 'objektive Entscheidungen über Content und Marketingstrategie',
    wantent_8: 'Wantent ist eine KI-basierte Lösung für den Content-Markt.',
    wantent_9: 'Wantent ist eine auf künstlicher Intelligenz basierende Lösung, um Content an den Markt anzupassen. Wantent wertet die Effektivität von Videoinhalten aus, indem es ML-Technologien anwendet, um die emotionale Reaktion und das Engagement des Publikums zu analysieren.',
    wantent_10: 'Wantent hilft dabei, die Eignung für den Content-Markt zu definieren und die Content-Effizienz zu maximieren durch:',
    wantent_11: 'Szenario Aufbau & Teilnehmerrekrutierung',
    wantent_12: 'personalisiertes Projektdesign und Publikum weltweit',
    wantent_13: 'Sammeln der Zuschauerreaktionen',
    wantent_14: 'detailliertes und klares Feedback vom Publikum',
    wantent_15: 'Analyse der Content Wahrnehmung',
    wantent_16: 'Verhaltens- und emotionale Reaktionsanalyse durch KI',
    wantent_17: 'Bereitstellung von Erkenntnissen für die abschließende Zusammenfassung',
    wantent_18: 'Empfehlungen für Verbesserungen',
    wantent_19: 'Wantent maximiert Ihre Content-Effizienz',
    wantent_20: 'Vorabtest des Anzeigenkonzepts',
    wantent_21: 'Testen kreativer Konzepte',
    wantent_22: 'Überprüfung der Konsistenz der zukünftigen kreativen mit der entwickelten Strategie und Verständnis der Reaktionen der Zielgruppe für mögliche Anpassungen der kreativen Konzepte/Meldungen',
    wantent_23: 'Sichtbarkeitstest der Markenelemente (Postproduktion)',
    wantent_24: 'Bestätigung der Wirksamkeit der Investition für Werbung/Sponsoring',
    wantent_25: 'Anzeigentests',
    wantent_26: 'A/B-Tests',
    wantent_27: 'Vergleich der Effektivität vom Kreativen und Erhöhung des Engagements und der Aufmerksamkeit während der Produktionsphase',
    wantent_28: 'Einführung von Anzeigentests',
    wantent_29: 'Definieren der Leistung von Nachrichten und Erstellen von Empfehlungen zur Verbesserung der Audio- und Bildqualität',
    wantent_30: 'Lösungen für TV',
    wantent_31: 'Pilotprojekte, Promos, neue Formate, Tests von TV-Moderatoren',
    wantent_32: 'Durchführen einer vergleichenden Analyse, um das ansprechendste zu identifizieren und zu stärken',
    wantent_33: 'Testen von Fernsehsendungen und Spielfilmen',
    wantent_34: 'Analyse der Beteiligung, des Aufmerksamkeitsniveaus und der emotionalen Reaktion der Zuschauer während des Betrachtens langer Contents unter natürlichen Bedingungen. Wir finden automatisch die Spitzen der Aufmerksamkeit des Publikums und Momente der Ablenkung, um die Videomontage zu bewerten und zu verbessern',
    wantent_35: 'UI/UX-Tests',
    wantent_36: 'Webseite-Prototypen und Testen von mobilen Anwendungen',
    wantent_37: 'Analyse von Benutzerreaktionen und Emotionen mit Wantent-Technologie:',
    wantent_38: 'Aufmerksamkeitsgrad',
    wantent_39: 'emotionale Reaktionen',
    wantent_40: 'Heatmaps der Blickrichtung der Teilnehmer',
    wantent_41: 'Analyse der Benutzerfreundlichkeit von Webseiten und Anwendungen basierend auf dem Feedback der Teilnehmer:',
    wantent_42: 'Skala der Systembenutzbarkeit (SUS)',
    wantent_43: 'Client Effort Score (Benutzerfreundlichkeit)',
    wantent_44: 'Net Promotion Score (NPS)',
    wantent_45: 'GDPR-Konformität und Datenschutz der Benutzer',
    wantent_46: 'Wantent ist vollständig konform mit den GDPR- und CCPA-Richtlinien und erfüllt die Grundsätze der Erhebung, Speicherung, Verarbeitung und des Schutzes der personenbezogenen Daten der Teilnehmer',
    wantent_47: 'Mykyta Lobyntsev',
    wantent_48: 'Chief Digital Officer, Reface (Medien & Unterhaltung)',
    wantent_49: 'Wir experimentieren jeden Tag mit unzähligen neuen Content-Ideen mit maschinellen Lerntechnologien. Wantent hilft uns, Risiken und Vorteile zu verstehen und zu bewerten, das Feedback, das Verhalten und die Wahrnehmung der Benutzer zu entdecken, um die Kausalität verschiedener Aspekte unserer Lösungen zu ermitteln.',
    wantent_50: 'Oleksandr Smirnov',
    wantent_51: 'Miteigentümer TABASCO (Werbung)',
    wantent_52: 'Wantent bietet einen wirklich einzigartigen Service, der uns hilft, sehr kleine Details darüber zu erfahren, was Verbraucher mögen und was nicht. Es gibt keine Möglichkeit zu schummeln, es gibt keine Möglichkeit zu lügen - Wantent kennt immer die Wahrheit, und hiermit erhalten wir als Marketingexperten die unbezahlbaren (hahaha - wir kennen den genauen Preis und es ist erschwinglich) Richtlinien, wie wir unsere Kommunikation verbessern können.',
    wantent_53: 'Rezensionen',
    terms_1: 'Allgemeine Geschäftsbedingungen',
    terms_2: 'Nutzungsbestimmungen',
    terms_3: 'Bitte lesen Sie diese Nutzungsbestimmungen („Nutzungsbedingungen“, „Vereinbarung“) sorgfältig durch, bevor Sie die Webseite nutzen.',
    terms_4: '(«Webseite») betrieben von 4Service Holdings GmbH («4Service», «uns», «wir», «unser»). «c»',
    terms_5: 'Nutzungsbedingungen: Durch die Nutzung dieser Webseite bestätigen Sie, dass Sie diese Vereinbarung gelesen haben und damit einverstanden sind, ihre Bedingungen einzuhalten. Wenn Sie nicht an die Bedingungen dieser Vereinbarung gebunden sein möchten, wird Ihnen empfohlen, die Webseite dementsprechend zu verlassen. 4Service gewährt nur denjenigen die Nutzung und den Zugriff auf diese Webseite, ihre Produkte und ihre Dienstleistungen, die seine Bedingungen akzeptiert haben.',
    terms_6: 'Datenschutz',
    terms_7: 'Bevor Sie unsere Webseite weiter nutzen, empfehlen wir Ihnen, unsere Datenschutzrichtlinie bezüglich unserer Benutzerdatenerfassung zu lesen. Sie hilft Ihnen, unsere Praktiken besser zu verstehen.',
    terms_8: 'Altersbeschränkung',
    terms_9: 'Sie müssen mindestens 16 (sechzehn) Jahre alt sein, um diese Webseite nutzen zu dürfen. Durch die Nutzung dieser Webseite garantieren Sie, dass Sie mindestens 16 (sechzehn) Jahre alt sind und diese Vereinbarung rechtlich einhalten können. 4Service übernimmt keine Verantwortung für Haftungen im Zusammenhang mit Altersangaben.',
    terms_10: 'Geistiges Eigentum',
    terms_11: 'Sie stimmen zu, dass alle auf dieser Webseite bereitgestellten Materialien, Produkte und Dienstleistungen Eigentum von 4Service, seinen verbundenen Unternehmen, Direktoren, leitenden Angestellten, Mitarbeitern, Vertretern, Lieferanten oder Lizenzgebern sind, einschließlich aller Urheberrechte, Geschäftsgeheimnisse, Marken, Patente und anderen geistigen Eigentums. Sie stimmen auch zu, dass Sie das geistige Eigentum von 4Service in keiner Weise reproduzieren oder weiterverbreiten werden, einschließlich elektronischer, digitaler oder neuer Markenregistrierungen.',
    terms_12: 'Zugriff auf unsere Webseite',
    terms_13: 'Der Zugriff auf unsere Webseite ist vorübergehend gestattet, und wir behalten uns das Recht vor, den Service, den wir auf unserer Webseite anbieten, ohne Vorankündigung zurückzuziehen oder zu ändern. Wir haften nicht, wenn unsere Webseite aus irgendeinem Grund zu irgendeinem Zeitpunkt oder für einen bestimmten Zeitraum nicht verfügbar ist. Von Zeit zu Zeit können wir den Zugriff auf einige Teile unserer Webseite oder unsere gesamte Webseite auf Benutzer beschränken, die sich bei uns registriert haben. Sie sind dafür verantwortlich, alle Vorkehrungen zu treffen, die für den Zugriff auf unsere Webseite erforderlich sind, einschließlich der Verwendung von Geräten, die mit unserer Webseite kompatibel sind. Sie sind auch dafür verantwortlich sicherzustellen, dass alle Personen, die über Ihre Internetverbindung auf unsere Webseite zugreifen, diese Bedingungen kennen und sie einhalten.',
    terms_14: 'Geltendes Recht',
    terms_15: 'Durch den Besuch dieser Webseite stimmen Sie zu, dass die Gesetze der Republik Österreich, ohne Rücksicht auf Grundsätze des Kollisionsrechts, diese Allgemeinen Geschäftsbedingungen oder alle Streitigkeiten jeglicher Art, die zwischen 4Service und Ihnen entstehen könnten, regeln.',
    terms_16: 'Streitigkeiten',
    terms_17: 'Alle Streitigkeiten im Zusammenhang mit Ihrem Besuch dieser Webseite werden vom Handelsgericht Wien geschlichtet.',
    terms_18: 'Entschädigung',
    terms_19: 'Sie stimmen zu, 4Service und seine verbundenen Unternehmen schad- und klaglos zu halten und 4Service von Rechtsansprüchen und Forderungen freizustellen, die sich aus Ihrer Nutzung oder Ihrem Missbrauch unserer Webseite ergeben können. Wir behalten uns das Recht vor, unseren eigenen Rechtsbeistand auszuwählen.',
    terms_20: 'Dauer der Speicherung',
    terms_21: 'Unter Berücksichtigung der Verarbeitungszwecke beträgt die Speicherdauer der personenbezogenen Daten der Nutzer (Speicherdauer) 24 Monate ab dem Datum, an dem die ordnungsgemäße Einwilligung zur Datenverarbeitung von Ihnen eingeholt wurde.',
    terms_22: 'Haftungsbeschränkung',
    terms_23: '4Service haftet nicht für Schäden, die Ihnen durch den Missbrauch unserer Webseite entstehen können. 4Service behält sich das Recht vor, diese Vereinbarung jederzeit ohne Vorankündigung zu bearbeiten, zu modifizieren und zu ändern, indem Sie diese Veröffentlichung aktualisieren. Ihre fortgesetzte Nutzung der Webseite jetzt oder nach der Veröffentlichung von Änderungen oder Modifikationen zeigt Ihre Zustimmung zu solchen Änderungen oder Modifikationen. Wenn ein Teil dieser Vereinbarung für rechtswidrig, nichtig oder nicht durchsetzbar erklärt wird, gilt dieser Teil als trennbar und berührt nicht die Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen. Mögliche Beweise für die Nutzung der Webseite für illegale Zwecke werden den Strafverfolgungsbehörden zur Verfügung gestellt. Diese Vereinbarung ist eine Vereinbarung zwischen 4Service und dem Benutzer.',
    terms_24: 'Bitte richten Sie alle Fragen und Anliegen im Zusammenhang mit Datenschutz/ Nutzung an folgende Adresse:',
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Tegetthoffstraße 7',
    terms_27: '1010 Wien',
    terms_28: 'Österreich',
    terms_29: 'Überprüfung',
    terms_30: 'Wenn die personenbezogenen Daten, die Sie uns zur Verfügung stellen, nicht richtig sind, haben Sie das Recht, uns zu bitten, sie tatsächlich zu korrigieren (GDPR Artikel 16).',
    thank_you_1: 'Vielen Dank!',
    thank_you_2: 'Wir werden uns innerhalb von 24 Stunden bei Ihnen melden',
    thank_you_3: 'P.S. Unsere Manager prüfen bereits Ihre Anfrage, um ein besseres Angebot zusammenzustellen',
    thank_you_4: 'Menü',
    thank_you_5: 'Die Frau tippt',
    privacy_policy_1: 'Datenschutzerklärungsformular für betroffene Personen (Benutzer der Webseite)',
    privacy_policy_2: 'Diese Datenschutzerklärung (Privacy Notice) richtet sich an natürliche Personen (betroffene Personen), deren personenbezogene Daten von der 4Service Holdings GmbH gemäß den Anforderungen der GDPR im Rahmen des Besuchs der Webseite erhoben werden.',
    privacy_policy_3: '(hier nach Webseite bzw. Benutzer der Webseite/ Benutzer).',
    privacy_policy_4: 'Das Formular der Datenschutzerklärung für betroffene Personen (Benutzer der Webseite) der 4Service Holdings GmbH gilt in allen Unternehmen der 4Service-Gruppe, die der rechtlichen Autorität unter der Aufsicht der 4Service Holdings GmbH unterliegen oder von ihr kontrolliert werden.',
    privacy_policy_5: 'Kontaktdaten',
    privacy_policy_6: '4Service Holdings GmbH',
    privacy_policy_7: '(Firma/wir/uns),',
    privacy_policy_8: 'Adresse:',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Wien, Österreich.',
    privacy_policy_10: 'Email:',
    privacy_policy_12: 'Wer sind wir?',
    privacy_policy_13: 'Eine Einzelperson (Sie), die die Webseite zu irgendeinem Zweck besucht. Für die Zwecke dieses Dokuments werden Sie auch als Benutzer bezeichnet.',
    privacy_policy_14: 'Personenbezogene Daten, die wir von Ihnen erheben',
    privacy_policy_15: 'Wenn Sie unsere Webseite öffnen, senden wir Ihnen eine Datenschutzerklärung, in der wir Sie bitten, uns Ihre Zustimmung zur Verarbeitung der folgenden Informationen zu erteilen:',
    privacy_policy_16: 'IP-Adresse, Benutzername, Nachname, Adresse, Telefonnummer (Festnetz oder Mobil), E-Mail-Adresse, Adresse, Firmenname, Land, E-Mail-Adresse, Vorname, Nachname, Telefonnummer, Provinz, Bundesland und Postleitzahl, Nutzungsdaten, Daten über die Interaktion mit externen sozialen Netzwerken oder Plattformen, Informationen zur Registrierung und Authentifizierung auf der Webseite',
    privacy_policy_17: 'geografische Lage.',
    privacy_policy_18: 'Falls Sie Ihren Willen äußern und uns eine solche Zustimmung geben, beginnen wir mit der Verarbeitung dieser Informationen von Ihnen.',
    privacy_policy_19: 'Rechtmäßigkeit (Einwilligung)',
    privacy_policy_20: 'Wenn Sie unsere Webseite öffnen, senden wir Ihnen eine Datenschutzerklärung, in der wir Sie bitten, uns Ihre Zustimmung zur Verarbeitung der folgenden Informationen zu erteilen:',
    privacy_policy_21: 'Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist die Einwilligung, die wir von Ihnen erhalten, indem Sie das Einwilligungsformular unter folgendem Link ausfüllen:',
    privacy_policy_22: 'Wenn Sie das Einwilligungsformular ausfüllen, bedeutet dies, dass Sie alle in dieser Datenschutzerklärung aufgeführten Bedingungen verstehen und akzeptieren.',
    privacy_policy_23: 'Widerruf der Einwilligung',
    privacy_policy_24: 'Sie haben das Recht, eine früher erteilte Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Sie können Ihre Einwilligung widerrufen, indem Sie uns eine entsprechende Anfrage an folgende E-Mail-Adresse withdraw@4service-group.com senden, wobei das Anfrageformular unter folgendem Link verfügbar ist:',
    privacy_policy_25: 'Widerrufsformular für Benutzer',
    privacy_policy_26: 'Zwecke der Verarbeitung',
    privacy_policy_27: 'Wir verarbeiten Ihre personenbezogenen Daten zu folgenden Zwecken:',
    privacy_policy_28: '– Verbesserung der Kundendienstleistungen (Ermöglicht eine effektivere Reaktion auf Kundenanfragen); – Personalisierung der Benutzererfahrung. (Es ermöglicht festzustellen, wer mehr an Dienstleistungen interessiert ist); – Verbesserung der Website. (Es ermöglicht die Verbesserung der Qualität von Produkten und Dienstleistungen, die Bequemlichkeit ihrer Nutzung, die Entwicklung neuer Dienstleistungen zur Verbesserung unserer Produkte und Dienstleistungen); – Kommunikation mit dem Benutzer durch Newsletter, Marketing- oder Werbematerialien und andere Informationen, die unsere Neuigkeiten, Aktualisierungen, Informationen über Dienstleistungen enthalten, mit dem Hinweis auf die Anweisung, wie man den Erhalt von Folge-E-Mails ablehnt. – Durchführung statistischer und anderer Arten von Forschung und Analyse auf der Grundlage anonymisierter Daten. – Bereitstellung personalisierter Dienste für den Benutzer und Durchführung von Vereinbarungen und Verträgen; – Teilnahme des Benutzers an verschiedenen Projekten, die von uns über die Website durchgeführt werden, Beantwortung von Anfragen des Benutzers über die Website, Recherche, Führung von Konten und Aufzeichnungen und Förderung von Dienstleistungen.',
    privacy_policy_29: 'Subjekte, an die die personenbezogenen Daten übermittelt werden können',
    privacy_policy_30: 'Bei der Verarbeitung Ihrer personenbezogenen Daten übermitteln wir Ihre personenbezogenen Daten an die Stellen, die als Auftragsverarbeiter des Unternehmens handeln. Die Auftragsverarbeiter des Unternehmens handeln ausschließlich nach Weisung des Unternehmens. Die Einzelheiten der von den Auftragsverarbeitern des Unternehmens durchgeführten Maßnahmen und eine Liste dieser Auftragsverarbeiter finden Sie in der Datenschutzrichtlinie unter folgendem Link: Datenschutzrichtlinie',
    privacy_policy_31: 'Die Länder, an die die personenbezogenen Daten übermittelt werden können',
    privacy_policy_32: 'Das Unternehmen übermittelt Ihre personenbezogenen Daten auf Grundlage eines Angemessenheitsbeschlusses gemäß den Vorgaben der GDPR und der EU-Kommission. Weitere Informationen zur Übermittlung personenbezogener Daten in die USA finden Sie in der Datenschutzrichtlinie unter folgendem Link: Datenschutzrichtlinie.',
    privacy_policy_33: 'Dauer der Speicherung',
    privacy_policy_34: 'Unter Berücksichtigung der Verarbeitungszwecke beträgt die Speicherdauer der personenbezogenen Daten der Nutzer (Speicherdauer) 24 Monate ab dem Datum, an dem die ordnungsgemäße Einwilligung zur Datenverarbeitung von Ihnen eingeholt wurde.',
    privacy_policy_35: 'Zugangsrecht',
    privacy_policy_36: 'Sie haben das Recht zu erfahren, ob Sie betreffende personenbezogene Daten verarbeitet werden, und 2) wenn ja, auf diese Daten zuzugreifen, mit vielen zusätzlichen Bestimmungen, die in Artikel 15 der GDPR aufgeführt sind.',
    privacy_policy_37: 'Recht auf Berichtigung',
    privacy_policy_38: 'Wenn die personenbezogenen Daten, die Sie uns zur Verfügung stellen, nicht richtig sind, haben Sie das Recht, uns zu bitten, sie tatsächlich zu korrigieren (GDPR Artikel 16).',
    privacy_policy_39: 'Recht auf Löschung (Recht auf Vergessen)',
    privacy_policy_40: 'Sie haben das Recht, von uns zu verlangen, dass Ihre personenbezogenen Daten unverzüglich gelöscht werden, und wir sind verpflichtet, Ihre personenbezogenen Daten unverzüglich zu löschen, wenn die in Artikel 17 der GDPR genannten Gründe zutreffen.',
    privacy_policy_41: 'Recht auf Einschränkung der Verarbeitung',
    privacy_policy_42: 'Sie haben ein Recht auf Einschränkung der Verarbeitung Ihrer personenbezogenen Daten mit mehreren Ausnahmen im Rahmen der GDPR, insbesondere angegeben in Artikel 18 der GDPR.',
    privacy_policy_43: 'Wir sind verpflichtet, Sie darüber zu informieren, welche Daten erhoben werden, wie sie verwendet werden, wie lange sie aufbewahrt werden und ob sie an Dritte weitergegeben werden. Diese Informationen müssen prägnant und in einfacher Sprache übermittelt werden.',
    privacy_policy_44: 'Recht auf Datenübertragbarkeit',
    privacy_policy_45: 'Sie dürfen Ihre personenbezogenen Daten für Ihre eigenen Zwecke über verschiedene Dienste hinweg erhalten und wiederverwenden.',
    privacy_policy_46: 'Widerspruchsrecht',
    privacy_policy_47: 'Sie haben ein Recht, der Verarbeitung personenbezogener Daten, die von der Gesellschaft verarbeitet werden, zu widersprechen. Wir müssen die Verarbeitung personenbezogener Daten einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder wenn die Verarbeitung der Geltendmachung oder Ausübung von Rechtsansprüchen dient.',
    privacy_policy_48: 'Recht, nicht einer Entscheidung unterworfen zu werden, die ausschließlich auf einer automatisierten Verarbeitung beruht',
    privacy_policy_49: 'Sie haben ein Recht, einer automatisierten Profilerstellung zu widersprechen, die ohne Ihre Einwilligung erfolgt. Hiermit haben Sie das Recht, dass Ihre personenbezogenen Daten unter menschlicher Beteiligung verarbeitet werden.',
    privacy_policy_50: 'Beschwerden',
    privacy_policy_51: 'Falls Sie eine Beschwerde darüber einreichen möchten, wie Ihre personenbezogenen Daten von dem Unternehmen (oder den oben beschriebenen Auftragsverarbeitern) verarbeitet werden oder wie Ihre Beschwerde bearbeitet wurde, haben Sie ein Recht, eine Beschwerde direkt bei der Aufsichtsbehörde und dem Unternehmen einzureichen.',
    privacy_policy_52: 'Details für jeden dieser Kontakte sind:',
    privacy_policy_53: 'Aufsichtsbehörde:',
    privacy_policy_54: 'Österreichische Datenschutzbehörde',
    privacy_policy_55: 'Österreichische Datenschutzbehörde',
    privacy_policy_56: 'Wickenburggasse 8',
    privacy_policy_57: '1080 Wien',
    privacy_policy_58: 'Österreich / Europa',
    privacy_policy_59: 'Unternehmen:',
    privacy_policy_60: '4Service Holdings GmbH (Unternehmen/wir/uns),',
    privacy_policy_61: 'Adresse: Tegetthoffstraße 7, 1010 Wien, Österreich.',
    privacy_policy_62: 'Email:',
    privacy_policy_63: 'Datenschutz und Datenschutzrichtlinie',
    privacy_policy_64: 'Lesen Sie hier mehr darüber, wie und warum wir Ihre Daten verwenden: Datenschutzrichtlinie',
    privacy_policy_65: 'Genehmigung',
    privacy_policy_66: 'Das Unternehmen hat alle internen Dokumente entwickelt, um die Rollen der Mitarbeiter in Bezug auf die Verarbeitung personenbezogener Daten innerhalb des Unternehmens zu definieren, insbesondere ist der Geschäftsführer für die Genehmigung und Überprüfung der Rechtmäßigkeit dieses Dokuments verantwortlich.',
    contacts_1: 'Für Kunden',
    contacts_2: 'Für alle Arten von Unternehmen, Marktforschungsinstitute und große Unternehmen, Geschäftspartner und Medien.',
    contacts_3: 'Tegetthoffstraße 7, Wien, Österreich',
    contacts_4: 'Für Einkäufer',
    contacts_5: 'Einheimische und Reisende, Autofahrer und Studenten, professionelle Testkäufer und Privatpersonen.',
    contacts_6: 'Kontaktieren Sie uns aus Ihrem Land:',
    contacts_7: 'Italien',
    contacts_8: 'den USA',
    contacts_9: 'Großbritannien',
    contacts_10: 'der Schweiz',
    contacts_11: 'der Niederlande',
    contacts_12: 'der Slowakei',
    contacts_13: 'Slowenien',
    contacts_14: 'Rumänien',
    contacts_15: 'AZ',
    contacts_16: 'der Ukraine',
    contacts_17: 'Kasachstan',
    contacts_18: 'Rest der Welt',
    menu_1: 'Karte der Kundenreise',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'BHT',
    menu_5: 'UX-Tests',
    menu_6: 'HR-Forschung',
    menu_7: 'Qualitative Forschung',
    menu_8: 'Quantitative Forschung',
    menu_9: 'Mystery-Shopping',
    menu_10: 'Voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Preisüberwachung',
    menu_14: 'Über uns',
    menu_15: 'Kontaktdaten',
    menu_16: 'Blog',
    menu_17: 'Methoden',
    menu_18: 'Produkte',
    menu_19: 'Shoppers \n platform',
    menu_20: 'CSR',
    nps_passives: 'Passive',
    nps_detractors: 'Kritiker',
    nps_promoters: 'Promoter',
    nps_a_score: 'eine Punktzahl',
    area: 'Bereich',
    products_tabs_1: 'Um eine maximale Effizienz zu erreichen, führen wir auch komplexe Arbeiten durch:',
    products_tabs_2: 'Produkte',
    products_tabs_3: 'Qualitative Forschung sammelt und analysiert nicht-numerische Daten, um Konzepte, Meinungen oder Erfahrungen zu verstehen.',
    products_tabs_4: 'Quantitative Forschungsmethoden sind eine objektive Messung und numerische Analyse von Daten, die durch Umfragen und Fragebögen gesammelt werden.',
    our_clients_1: 'Unsere Kunden',
    preview_cases_section_1: 'Fälle',
    preview_cases_section_2: 'Öffnen Sie die Fälle, um weitere interessante Beiträge zu lesen',
    blog_1: 'Mitreißender Content-Blog',
    blog_2: 'Keine Beiträge verfügbar für Ihre Anfrage.',
    about: 'Über',
    why_us: 'Warum wir',
    about_us: 'Über uns',
    key_features: 'Hauptmerkmale',
    solutions: 'Lösungen',
    monitoring: 'Überwachung',
    how: 'Wie',
    how_it_works: 'Wie es funktioniert',
    when_to_use: 'Wann zu benutzen',
    mystery_shopping: 'Mystery Shopping',
    voicer: 'Voicer',
    wantent: 'Wantent',
    play4sales: 'Play4Sales',
    price_monitoring: 'Preisüberwachung',
    service_design: 'Service-Design',
    qualitative_research: 'Qualitative Forschung',
    quantitative_research: 'Quantitative Forschung',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Automobil',
    hashtag_retayl: '#Einzelhandel',
    hashtag_all_posts: '#Alle_Beiträge',
    hashtag_feedback: '#Feedback',
    hashtag_4Service_csr: '#4Service_CSR',
    hashtag_cases: '#Fälle',
    hashtag_market_research: '#Marktforschung',
    more: 'Mehr',
    speak_to_an_expert: 'Sprechen Sie mit einem Experten',
    book_consultancy: 'Bestellen Sie eine Beratung',
    read_all: 'Alles lesen',
    find_out_more: 'Mehr erfahren',
    address: 'Adresse',
    mon_fri: 'Mo-Fr',
    phone_number: 'Telefonnummer',
    flag: 'Flagge',
    scroll_to_left: 'nach links scrollen',
    online: 'online',
    tel: 'Tel.',
    email: 'Email',
    light_it_up: 'Beleuchte es',
    about_us_60: 'Alina Andreieva',
    about_us_61: 'Leiterin der Geschäftsentwicklung',
    about_us_62: 'Julia Kravchenko',
    about_us_63: 'Senior Projektmanagerin',
    about_us_64: 'Olga Aksonova',
    about_us_65: 'Managerin für Geschäftsentwicklung',
    about_us_66: 'Dmytro Zhelevskiy',
    about_us_67: 'Betriebsleiter',
    about_us_roles_1: 'Management',
    about_us_roles_2: 'Team Global',
    about_us_roles_3: 'Produkteigentümer',
    ad_testing_1: 'Anzeigentests',
    ad_testing_2: 'Eine Werbekampagne ist eine riesige Investition. Stellen Sie sicher, dass Ihre Anzeigen leistungsstark sind und maximale Kapitalrentabilität erzielen',
    ad_testing_3: 'Schloss',
    ad_testing_4: 'Branchen die wir bedienen',
    ad_testing_5: '4Service ist eine globale Customer Experience Forschungsholding',
    ad_testing_6: '4Service ist eine globale Marketingforschungs- und CX-Holding. Wir bieten fachkundige Datenerfassungsdienste für eine Vielzahl von Unternehmen an',
    ad_testing_7: 'Cash & Carry Geschäft',
    ad_testing_8: 'Einzelhandel',
    ad_testing_9: 'Elektronik',
    ad_testing_10: 'Luxus/Premium',
    ad_testing_11: 'Restaurants & Gastfreundschaft',
    ad_testing_12: 'Automobil',
    ad_testing_13: 'Reisen & Freizeit',
    ad_testing_14: 'Mode',
    ad_testing_15: 'Banken',
    ad_testing_16: 'Uhren & Schmuck',
    ad_testing_17: 'Haushalt & Haushaltsgeräte',
    ad_testing_18: 'Mitglied von Esomar',
    ad_testing_19: 'Wir haben Werbetests für verschiedene Unternehmen in unterschiedlichen Bereichen durchgeführt',
    ad_testing_20: 'Sie brauchen Anzeigentests falls Sie',
    ad_testing_21: 'für ein neues Produkt oder eine neue Dienstleistung werben',
    ad_testing_22: 'ein sensibles Thema ansprechen',
    ad_testing_23: 'versuchen, eine neue demografische Zielgruppe oder einen neuen Markt zu erreichen',
    ad_testing_24: 'über mehrere unterschiedliche Designoptionen sprechen',
    ad_testing_25: 'einen Machbarkeitsnachweis für Managementteams oder Investoren benötigen',
    ad_testing_26: 'Sie erhalten Antworten auf die Fragen',
    ad_testing_27: 'Welche Werbekanäle sind für diese Anzeigen am besten geeignet?',
    ad_testing_28: 'Verwenden Sie richtige visuelle Elemente, um Informationen zu vermitteln?',
    ad_testing_29: 'Kommen Ihre Werbetexte und Nachrichten an?',
    ad_testing_30: 'Welche Werbekanäle sind für diese Anzeigen am besten geeignet?',
    ad_testing_31: 'Was fällt zuerst auf?',
    ad_testing_32: 'Sind Platzierung und Anordnung der Anzeige leicht verständlich?',
    ad_testing_33: 'Wir bewerten',
    ad_testing_34: 'Markenzeichen',
    ad_testing_35: 'wie Ihre Marke mit der Werbung verknüpft ist und wie stark sie in Erinnerung bleibt',
    ad_testing_36: 'Kennzahlen',
    ad_testing_37: 'Einbindung',
    ad_testing_38: 'Erkennung',
    ad_testing_39: 'Fit',
    ad_testing_40: 'Kreativ',
    ad_testing_41: 'Wie Ihre Werbung auffällt und Aufmerksamkeit erregt',
    ad_testing_42: 'Kennzahlen',
    ad_testing_43: 'ERKENNUNG',
    ad_testing_44: 'LIKING',
    ad_testing_45: 'MTO',
    ad_testing_46: 'MOTIVATION',
    ad_testing_47: 'Kundenerlebnis, emotionales Engagement',
    ad_testing_48: 'Mithilfe von Technologien der künstlichen Intelligenz und erfahrenen Analysten analysieren wir die wahren Emotionen der Menschen und erhalten Einblicke in ihre Wahrnehmung von Werbung',
    ad_testing_49: 'Bewertung',
    ad_testing_50: 'Männlich',
    ad_testing_51: 'Weiblich',
    ad_testing_52: 'hohes Engagement',
    ad_testing_53: 'Aufmerksamkeit',
    ad_testing_54: 'Was hat dem Publikum gefallen und woran erinnert es sich?',
    ad_testing_55: 'Schauspielerin',
    ad_testing_56: 'Gleichwertigkeit',
    ad_testing_57: 'Musikalischer Hintergrund',
    ad_testing_58: 'Positive Gefühle',
    ad_testing_59: 'Menschen verschiedener Rassen und Aussehen',
    ad_testing_60: 'Außenaufnahmen',
    ad_testing_61: 'Was hat dem Publikum NICHT gefallen und woran erinnert es sich nicht?',
    ad_testing_62: 'Die Stimme des Sprechers stimmte nicht mit dem überein, was auf dem Bildschirm passierte.',
    ad_testing_63: 'Geringe Beachtung des Produkts',
    ad_testing_64: 'Zu dynamisch',
    ad_testing_65: 'Schwer zu verstehen',
    ad_testing_66: 'TRP',
    ad_testing_67: 'Eindrücke',
    ad_testing_68: 'Shows',
    ad_testing_69: 'Konversion',
    ad_testing_70: 'Medien',
    ad_testing_71: 'Wir messen die Effektivität einer Werbekampagne in Verbindung mit der Medienaktivität und berechnen die Auswirkungen von Medieninvestitionen in verschiedenen Kanälen auf die überwachten Ergebnisse.',
    ad_testing_72: 'Wir führen Werbeforschung in allen Formaten, Plattformen oder Kanälen durch',
    ad_testing_73: 'Videoinhalt',
    ad_testing_74: 'draußen',
    ad_testing_75: 'Fernsehwerbung',
    ad_testing_76: 'Ausdruck',
    ad_testing_77: 'Digital',
    ad_testing_78: 'Werbekampagnen',
    ad_testing_79: 'POS-Anzeige und Werbung',
    ad_testing_80: 'Animation',
    ad_testing_81: 'Mit unserer Hilfe können Sie Ihre Werbung jederzeit testen',
    ad_testing_82: 'Konzept',
    ad_testing_83: 'Welche Ideen haben eine stärkere Kapazität?',
    ad_testing_84: 'Produktion in der Frühphase',
    ad_testing_85: 'Welche Animationen werden in Produktion gehen?',
    ad_testing_86: 'Produktion',
    ad_testing_87: 'Wie bearbeitet man ein Video, damit es Aufmerksamkeit erregt?',
    ad_testing_88: 'Vormedien',
    ad_testing_89: 'Welche Kommunikationskanäle funktionieren und welche Ergebnisse sind zu erwarten?',
    ad_testing_90: 'Anzeigennachtest',
    ad_testing_91: 'Produktion',
    ad_testing_92: 'Ist meine Werbung ansprechend? Lässt die Effektivität der Kreativität mit der Zeit nach?',
    ad_testing_93: 'Vormedien',
    ad_testing_94: 'Wie effektiv ist meine Werbung in Zahlen? Wie schneidet es im Vergleich zur Konkurrenz ab?',
    ad_testing_95: 'Anzeigenvortest',
    ad_testing_96: 'Um Ihnen qualitativ hochwertige Daten zur Verfügung zu stellen, verwenden wir',
    ad_testing_97: 'quantitative Forschungsmethodik',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'qualitative Forschungsmethodik',
    ad_testing_100: 'Fokusgruppen',
    ad_testing_101: 'KI',
    ad_testing_102: 'Wantent - Analyse der Reaktion des Zuschauers, um die Wirksamkeit des angesehenen Materials zu bewerten',
    ad_testing_103: 'Analytische Berichte, die Sie erhalten',
    ad_testing_104: 'Unser Team aus Analysten, Medienexperten und Vermarktern erstellt und präsentiert Ihnen einen maßgeschneiderten Bericht mit detaillierten Empfehlungen',
    ad_testing_age: 'Alter',
    ad_testing_ad: 'Anzeige',
    automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
    automotive_industry_price_analysis_2: 'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
    automotive_industry_price_analysis_3: 'Request a quote',
    automotive_industry_price_analysis_4: 'Our methodology',
    automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
    automotive_industry_price_analysis_6: 'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
    automotive_industry_price_analysis_7: 'We offer precise figures and dependable multilateral data',
    automotive_industry_price_analysis_8: 'We have global coverage spanning every country in the world',
    automotive_industry_price_analysis_9: 'Our in-house resources include mystery shoppers and auditors',
    automotive_industry_price_analysis_10: 'We validate the quality of our data',
    automotive_industry_price_analysis_11: 'Our advantages:',
    automotive_industry_price_analysis_12: 'Price monitoring for specific competitive models (B2B and B2C)',
    automotive_industry_price_analysis_13: 'Monitoring the level of discount',
    automotive_industry_price_analysis_14: 'Monitoring retailer stock for your brand and competitors',
    automotive_industry_price_analysis_15: 'Monitoring e-commerce automotive prices',
    automotive_industry_price_analysis_16: 'Price communication analysis',
    automotive_industry_price_analysis_17: 'Keeping track of retailer shipping charges',
    automotive_industry_price_analysis_18: 'Monitoring promotions and sales at retailers',
    automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
    automotive_industry_price_analysis_20: 'Calculating lease payments',
    automotive_industry_price_analysis_21: 'Analyzing specific pricing considerations for electric vehicles (EVs)',
    automotive_industry_price_analysis_22: 'Conducting direct-to-consumer versus retailer price comparisons',
    automotive_industry_price_analysis_23: 'Developing subscription pricing models',
    automotive_industry_price_analysis_24: '4Service is a global customer experience (CX) holding company with over 20 years of experience',
    automotive_industry_price_analysis_25: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_26: "4Service's analysis of prices in the automotive industry includes:",
    automotive_industry_price_analysis_27: "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
    automotive_industry_price_analysis_28: 'of customers understand that prices are increasing across all brands',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'of dealers mentioned the possibility of price increases during the communication/selling process',
    automotive_industry_price_analysis_35: '47.6',
    automotive_industry_price_analysis_36: 'Key figures',
    automotive_industry_price_analysis_37: "You'll figure out how to price your product relative to other vehicles in the lineup.",
    automotive_industry_price_analysis_38: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_39: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
    automotive_industry_price_analysis_40: "You'll be able to determine the appropriate monthly payment and leasing rates.",
    automotive_industry_price_analysis_41: 'What benefits for your brand or dealer will you gain:',
    automotive_industry_price_analysis_42: 'You submit an application',
    automotive_industry_price_analysis_43: 'Our manager contacts you to determine the main objectives',
    automotive_industry_price_analysis_44: 'We conduct a marketing research',
    automotive_industry_price_analysis_45: 'We regularly provide you with reports and recommendations in an online format',
    automotive_industry_price_analysis_46: 'Car with sale sticker',
    automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
    automotive_industry_price_analysis_48: 'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
    retail_audit_1: 'FIELD MARKETING AUDIT/Einzelhandelsaudit',
    retail_audit_2: 'Sammeln Sie detaillierte Daten zu Ihrem Geschäft oder Ihrer Marke, analysieren       Sie Wachstumsbarrieren und entwickeln Sie wirksame Strategien zu deren Überwindung',
    retail_audit_3: 'Angebot anfordern',
    retail_audit_4: '4Service führt im Auftrag von Marken Filialaudits vor Ort durch, um die Wirksamkeit ihrer Werbestrategien, Außendienstteams und Marketingtaktiken bei der Erreichung des beabsichtigten Zielmarkts zu bewerten.',
    retail_audit_5: 'Wir beobachten die Durchführung von Werbekampagnen akribisch und stellen die Einhaltung der Werberichtlinien sicher.',
    retail_audit_6: 'Top 10',
    retail_audit_7: 'CX-Agenturen in Europa',
    retail_audit_8: 'Jahre Erfahrung',
    retail_audit_9: 'Länderabdeckung',
    retail_audit_10: '1,6 Millionen',
    retail_audit_11: 'Auswertungen pro Jahr',
    retail_audit_12: 'Was verfolgen wir?',
    retail_audit_13: 'Vorhandensein von Point-of-Sale (POS)-Materialien',
    retail_audit_14: 'Präsenz und Aktivierung von Markenbotschaftern, Sicherstellung der Einhaltung des geplanten Zeitplans',
    retail_audit_15: 'Bewertung des Engagements von Markenbotschaftern in Advocacy-Kampagnen',
    retail_audit_16: 'Bewertung der Verbraucherinteraktion, Sicherstellung der Einhaltung von Werberichtlinien und Markenbotschaften',
    retail_audit_17: 'Maßnahmen zur Betrugsprävention',
    retail_audit_18: 'Ihr Unternehmen kann die Ergebnisse eines Feldmarketing-Audits nutzen, um',
    retail_audit_19: 'Einhaltung von Budget und Prozessen beurteilen',
    retail_audit_20: 'Ist-Ergebnisse mit angestrebten Zielen vergleichen',
    retail_audit_21: 'Die Leistung von Marketingkampagnen verbessern ',
    retail_audit_22: 'Strategisch für die Zukunft planen',
    retail_audit_23: 'Möglichkeiten zur Kostensenkung identifizieren',
    retail_audit_24: 'Verkäufe und Konversionsraten verbessern',
    retail_audit_25: 'Return on Investment (ROI) optimieren',
    retail_audit_26: 'SIE HABEN SICH FÜR 4SERVICE ALS IHREN HANDELSINVESTITIONSPARTNER ENTSCHEIDEN',
    retail_audit_27: 'Ein führender internationaler Hersteller von Tabakprodukten hat sich mit 4Service zusammengetan, um seine Marken zu stärken und die Leistung im Geschäft zu verbessern. Unsere Teams haben eine Marketingüberprüfung vor Ort und Live-Berichterstattung über die Kundenerfahrung durchgeführt.',
    retail_audit_28: 'Steigerung der Planungsgenauigkeit der Feldmarketing-Agentur',
    retail_audit_29: 'Steigerung der Markenbotschaften',
    retail_audit_30: 'Steigerung der Mitarbeiterbindung (Kundenaktivierung)',
    retail_audit_31: 'Steigerung der Genauigkeit der Datensammlung und Einhaltung der DSGVO',
    retail_audit_32: 'Welche wertvollen Erkenntnisse finden wir normalerweise:',
    retail_audit_33: 'Mangelnde Konsistenz oder sogar Konflikte zwischen den Bedingungen des SLA führen zu erheblichen nicht genehmigten Kosten für den Kunden',
    retail_audit_34: 'Fehlerhafte Gebührenberechnung aufgrund von Verwechslung der angebotenen Dienstleistungen',
    retail_audit_35: 'Nichtdurchführung des Vor-Ort-Services, einschließlich Nichtzuweisung von Mitarbeitern zum geplanten Standort | POS',
    retail_audit_36: 'Gebühren auf Basis einer Schätzung, anstatt auf Basis von tatsächlichen Daten und nicht abgeglichen',
    retail_audit_37: 'Fehlerhafte Anwendung der Aktionsrichtlinie, die betrügerische Daten liefert',
    retail_audit_38: 'Wie arbeiten wir?',
    retail_audit_39: 'Unser Auditor besucht ausgewiesene Point-of-Sale (POS)-Standorte basierend auf dem Aktivierungsplan der Agentur.',
    retail_audit_40: 'Sie bewerten wichtige Leistungsindikatoren (KPIs), wie im Briefing festgelegt, wie die Präsenz am POS, die Mitarbeiterbindung, die Einhaltung der Aktionsrichtlinien und Maßnahmen zur Betrugsprävention.',
    retail_audit_41: 'Validierung:',
    retail_audit_42: 'Wir sammeln und konsolidieren die erhaltenen Daten.',
    retail_audit_43: 'Ein umfassender Bericht wird erstellt.',
    retail_audit_44: 'Unser Validierungsteam überprüft alle Umfragen sorgfältig auf Genauigkeit, einschließlich der Einhaltung des Projektauftrags und etwaiger zusätzlicher Kommentare.',
    retail_audit_45: 'Online-Berichtssystem:',
    retail_audit_46: 'Abgeschlossene und validierte Fragebögen werden innerhalb von 36 Stunden in unser Online-Portal hochgeladen.',
    retail_audit_47: 'Kunden haben Zugriff auf ein persönliches Kontoportal, das es ihnen ermöglicht, den Fortschritt jederzeit zu überwachen.',
    retail_audit_48: 'Ein Abschlussbericht mit Ergebnissen und Empfehlungen wird bereitgestellt.',
    retail_audit_49: 'Sprechen Sie mit einem Experten',
    retail_audit_50: 'Unsere Stärken:',
  },
  fr: {
    form_title: 'Nous vous contacterons sous peu',
    form_required: "Les champs marqués d'une * sont obligatoires",
    form_name_placeholder: 'Nom',
    form_email_placeholder: 'E-mail',
    form_phone_placeholder: 'Numéro de téléphone',
    form_company_placeholder: 'Entreprise',
    form_submit: 'Soumettre',
    form_1: 'Je donne mon acceptation au traitement des données personnelles conformément à la',
    form_2: 'Politique de Confidentialité',
    validation_privacy_policy: 'Veuillez accepter la Politique de Confidentialité',
    validation_phone: "Le numéro de téléphone n'est pas valide",
    validation_email: "Format d'email invalide",
    validation_required: 'Les champs obligatoires ne sont pas remplis',
    submit_error: "Une erreur s'est produite lors de l'enregistrement du formulaire",
    footer_1: 'Nos partenaires:',
    footer_2: 'Méthodes',
    footer_3: 'Produits',
    footer_4: 'Contacts:',
    footer_5: 'Imprimer',
    footer_6: 'Politique de Confidentialité',
    about_us_1: "4Service Responsabilité Sociale d'Entreprise",
    about_us_2: "Du cœur et de l'âme de chaque membre de notre équipe. Nous faisons de notre mieux pour garder une étincelle de bienveillance chez chacun de nos employés et rendre le monde qui nous entoure un peu meilleur",
    about_us_3: "4Service Responsabilité Sociale d'Entreprise",
    about_us_4: "Du cœur et de l'âme de chaque membre de notre équipe. Nous faisons de notre mieux pour garder une étincelle de bienveillance chez chacun de nos employés et rendre le monde qui nous entoure un peu meilleur",
    about_us_5: "Jeu d'entreprise Service Mania",
    about_us_6: 'Nos initiatives de sensibilisation pour les enfants et les étudiants',
    about_us_7: 'Charité / Bénévolat',
    about_us_8: "Orphelinats, maisons de retraite pour personnes âgées et volontariat pour l'armée ukrainienne. La partie la plus émouvante de nos activités",
    about_us_9: 'Bien-être animal',
    about_us_10: "N'importe qui peut entrer dans notre bureau avec un animal de compagnie. Nous donnons un coup de main aux refuges, collectons des fonds pour la nourriture et essayons parfois aussi de trouver un foyer pour les animaux sans abri.",
    about_us_11: '4Service Groupe',
    about_us_12: "Est une société de recherche internationale qui gère l'expérience client.",
    about_us_13: 'Nous mettons en œuvre des projets complexes pour augmenter les ventes et développer les affaires des entreprises orientées client.',
    about_us_14: "Est un holding de recherche international dont le siège est à Vienne, en Autriche. La société a été créée en 2001 dans le but d'améliorer la qualité de vie de ses clients et les personnes qui interagissent avec le commerce, utilisent ses services et consomment ses produits. Après tout, c'est pour une raison que le nom «Forservice » a été choisi pour une entreprise qui représente le service client et la bonne qualité.",
    about_us_15: "4Service a été fondé à Kiev, en Ukraine, et devenue l'un des principaux fournisseurs de services d'achat mystère. Aujourd'hui, l'entreprise opère avec succès dans 52 pays en tant que société de recherche avec de nouvelles capacités à une approche holistique pour analyser et améliorer toutes les étapes de l'expérience client.",
    about_us_16: 'né au nom du service',
    about_us_17: "premier bureau situé hors d'Ukraine",
    about_us_18: 'déménagement du siège à Vienne',
    about_us_19: 'membre élite de la MSPA, Top 10 des agences en Europe',
    about_us_20: 'création de bureaux dans 9 pays',
    about_us_21: 'premiers projets numériques, Voicer',
    about_us_22: 'partenaires de DesignThinkers Academy',
    about_us_23: 'premiers projets CХ mondiaux',
    about_us_24: 'Mission',
    about_us_25: "Notre mission est d'améliorer le service client partout dans le monde ! Notre inspiration réside dans les changements qualitatifs que nous constatons chez les personnes et les entreprises avec lesquelles nous travaillons.",
    about_us_26: "Plus de 20 ans d'expérience client",
    about_us_27: '95 pays',
    about_us_28: "Nous accumulons et partageons la meilleure expérience de l'industrie du monde entier",
    about_us_29: 'Siège social à Vienne',
    about_us_30: "des pays. Nous rassemblons et partageons la meilleure expérience de l'industrie du monde entier",
    about_us_31: 'notes de qualité de service',
    about_us_32: 'nous sommes dans le top 10 des agences CX Européennes',
    about_us_33: 'bureaux de représentation / siège social en Autriche',
    about_us_34: 'projets actifs',
    about_us_35: 'Votre futur lieu de travail',
    about_us_36: 'Nos bureaux',
    about_us_37: "4Service™ s'efforce de créer un espace de travail confortable pour ses employés. La liberté de choix de travailler sur site, à distance ou hybride favorise le meilleur confort et la meilleure productivité de chaque employé. Des salles de repos, un soutien psychologique gratuit et un environnement accueillant. Nous acceptons les humains et les animaux domestiques.",
    about_us_38: "L'équipe qui s'illumine",
    about_us_39: "Notre équipe multinationale s'engage à écouter compte tenu des commentaires de nos clients et de la croissance mutuelle. La carte du parcours client de nos clients avec nous est aussi importante pour notre entreprise que le lancement de nouveaux produits ou l'atteinte de nos objectifs financiers. Nos activités visent à avoir un impact positif sur les communautés voisines et à fournir à nos clients des solutions innovantes et centrées sur les personnes.",
    about_us_40: 'Tous',
    about_us_41: 'Gestion',
    about_us_42: 'Équipe mondiale',
    about_us_43: 'Propriétaires de produits',
    about_us_44: 'Nos partenaires',
    about_us_45: 'Nous mettons en œuvre des projets complexes pour augmenter les ventes et développer les affaires des entreprises orientées client',
    about_us_46: "est l'un des principaux instituts de formation professionnelle «axés sur le design», représenté dans 25 pays et forme, développe et facilite des équipes et des communautés multidisciplinaires créatives pour conduire un changement positif. Le Design Thinking vous permet de développer et de proposer des idées innovantes, des changements et des solutions à des problèmes complexes.",
    about_us_47: "Leur mission est de fournir de l'espace et du temps aux équipes et aux individus pour qu'ils soient inspirés et mis au défi, pour « apprendre par la pratique » et renforcer leur capacité à résoudre les problèmes de manière créative et aider les organisations à créer l'environnement et l'état d'esprit nécessaires pour lancer une innovation audacieuse.",
    about_us_48: "est l'association commerciale représentative des entreprises impliquées dans l'industrie des Achats Mystères. L'Association opère sur une base régionale dans le monde entier, avec des sections situées en Amérique, Europe/Afrique et Asie/Pacifique. Les entreprises membres s'unissent en un corps commun dans le but de renforcer l'industrie d'Achats Mystères grâce à des efforts et des actions combinés.",
    about_us_49: "est une association à but non lucratif. Les membres forment une communauté convaincue du potentiel de l'analyse des données, de la recherche et des idées pour aider à améliorer les sociétés, les organisations et la vie de chacun.",
    about_us_50: 'ESOMAR a son siège social à Amsterdam, aux Pays-Bas. ESOMAR est présent dans plus de 130 pays à travers ses membres depuis 1947.',
    about_us_51: "(UCCA) est une communauté professionnelle d'experts privés et d'entreprises réunies dans un objectif de développement et d'interactions dans le domaine du centre de contact et du service client. La philosophie de l'UCCA élève la culture de l'industrie des centres de contact, basée sur des technologies modernes et des normes élevées.",
    about_us_52: "L'Association Ukrainienne des centres de contact a été fondée en 2010",
    about_us_53: "étudie la meilleure expérience mondiale dans le domaine de l'éducation pour l'adapter et l'utiliser sur le marché Ukrainien. C'est pourquoi l'Académie DTEK coopère avec les principales écoles de commerce internationales : INSEAD, IE Business School et l'Université de Stanford.",
    about_us_54: "Academy DTEK se concentre sur le développement d'une culture innovante et orientée client dans D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School digital program, Effective Leader: Pre-MBA, Change Energy.",
    about_us_55: 'Sergey Pashkovsky',
    about_us_56: 'Viktoria Skorbota',
    about_us_57: 'Artem Fomin',
    about_us_58: 'Max Papka',
    about_us_59: 'Manager des ventes internationales',
    bht_1: 'Santé de la marque',
    bht_2: 'Suivi',
    bht_3: 'Les marques sont incroyablement puissantes',
    bht_4: 'Découvrez les performances globales de votre marque',
    bht_5: 'À propos',
    bht_6: "Qu'est-ce que le suivi de la santé de la marque?",
    bht_7: "Il s'agit d'un type d'étude de marché qui est menée avec une certaine régularité (toutes les deux semaines, tous les mois, etc.) afin d'obtenir des informations sur les",
    bht_8: "statuts d'une marque sur le marché en fonction d'indicateurs tels que la notoriété, la demande, l'image, les caractéristiques de ses consommateurs, la santé de la marque, etc.",
    bht_9: 'Acheter la marque le plus souvent',
    bht_10: 'Loyal',
    bht_11: 'Acheté la marque pendant 3 mois',
    bht_12: 'Répertoire / clients réguliers',
    bht_13: 'Acheté la marque pendant 6 mois',
    bht_14: 'Clients à court terme',
    bht_15: 'Acheté la marque pendant un an',
    bht_16: 'Clients à long terme',
    bht_17: "Connaissez-vous la marque ? mais je ne l'ai jamais utilisé/acheté",
    bht_18: 'Au courant mais pas clients',
    bht_19: "Ignorant : je n'ai jamais entendu parler de la marque",
    bht_20: 'Ignorant',
    bht_21: 'Profil des acheteurs de la marque',
    bht_22: 'Évaluer la cohérence des efforts déployés sur le long terme pour construire une marque',
    bht_23: 'Mesurer le niveau de connaissance des clients sur la marque',
    bht_24: "Démontrer l'efficacité des campagnes de publicité et d'information",
    bht_25: "Déterminer l'impact d'une période de forte publicité sur les activités de vente/marketing des concurrents",
    bht_26: "Fournir un contrôle constant au propriétaire de l'entreprise dans les périodes de changements continus du marché",
    bht_27: 'Quelles tâches peuvent être résolues par BHT:',
    bht_28: 'Demander une recherche',
    bht_29: 'Tâche à résoudre',
    bht_30: 'Par exemple,',
    bht_31: "si une entreprise n'a besoin que d'une séance de BHT par trimestre lors de la mesure des parts de marché, une enquête auprès des répondants devrait être menée plus souvent lors de la mesure de la connaissance de la marque client par la publicité.",
    bht_32: 'CATI',
    bht_33: 'Face à face',
    bht_53: 'Les entretiens',
    bht_34: 'СAWI',
    bht_35: 'Tâche à résoudre',
    bht_36: 'En recueillant des informations',
    bht_37: "à travers diverses méthodes, le suivi de la marque est généralement divisé en trois étapes selon le public cible et sa difficulté à l'atteindre :",
    bht_38: 'Nous utilisons à la fois des méthodes traditionnelles et contemporaines pour collecter des données :',
    bht_39: "Nous travaillons à l'aide de deux modèles, l'un avec un ensemble d'indicateurs simple et l'autre ciblant des industries plus complexes telles que les industries alimentaires et de services. Un modèle plus complexe implique une étude approfondie qui comprend jusqu'à 10 indices estimés.",
    bht_40: 'Nous offrons',
    bht_41: 'un contrôle des données initiales',
    bht_42: 'Nous couvrons',
    bht_43: "l'ensemble du pays",
    bht_44: 'Nous fournissons une solution',
    bht_45: "pour les marchés non dynamiques/marchés avec un nombre limité d'acteurs (Brand Point Analyzer)",
    bht_46: 'Pourquoi nous?',
    bht_47: 'Pourquoi nous choisir?',
    bht_50: 'Résultat médiocre',
    bht_51: 'Bon résultat',
    bht_52: 'Résultat moyen',
    cases_1: "Défi : Comment améliorer l'expérience client d'achat d'un véhicule électrique dans les showrooms Hyundai ?",
    cases_2: 'Cartographie du parcours client',
    cases_3: 'Idéation',
    cases_4: 'Recherche qualitative',
    cases_5: "Défi : Que faut-il changer dans les magasins (à la fois dans le processus de service, dans l'intérieur, dans la perception de la marque) afin d'intéresser les clients à la marque Oppo?",
    cases_6: 'CJM en ligne',
    cases_7: "Test de l'interface utilisateur",
    cases_8: 'Les entretiens',
    cases_9: 'Défi : Que faut-il changer dans les magasins pour intéresser les clients à la marque Oppo?',
    cases_10: 'Partie qualitative',
    cases_11: 'Idéation',
    cases_12: 'Partie quantitative',
    cases_13: 'Carte du parcours client',
    cases_14: 'Cas',
    cases_15: 'Tous',
    cases_16: 'Réalisons ensemble votre superbe dossier',
    csi_1: 'Client',
    csi_2: 'indice de satisfaction',
    csi_3: 'Déterminer les raisons pour lesquelles les consommateurs',
    csi_4: 'satisfaction et insatisfaction',
    csi_5: 'Recherche sur la satisfaction client',
    csi_6: "est la détermination des facteurs, causes et motivations qui influencent la décision d'achat d'un client ainsi que le comportement du public cible.",
    csi_7: 'Une enquête de satisfaction client',
    csi_8: "montre à quel point un client est satisfait ou insatisfait d'un aspect particulier du service ou du produit en général.",
    csi_9: "L'enquête de satisfaction",
    csi_10: "est menée auprès des clients existants d'une entreprise et des utilisateurs de certains produits/services.",
    csi_11: "Qu'est-ce que le SCI?",
    csi_12: "La satisfaction client mesure dans quelle mesure les attentes d'un client ont été satisfaites concernant l'achat d'un produit ou d'un service fourni par votre entreprise.",
    csi_13: 'Homme heureux',
    csi_14: 'À propos',
    csi_15: 'Identifier les facteurs cachés',
    csi_16: 'qui peuvent affecter la satisfaction du client',
    csi_17: 'Déterminer les raisons',
    csi_18: 'derrière toute hausse/baisse de la demande de biens et services',
    csi_19: 'Optimiser une entreprise',
    csi_20: "processus d'affaires",
    csi_21: 'Les adapter',
    csi_22: 'aux besoins du consommateur moderne',
    csi_23: 'Former un message publicitaire efficace',
    csi_24: 'et positionner un produit sous la demande du client',
    csi_25: 'Tâches à résoudre',
    csi_26: 'Quelles tâches les enquêtes de satisfaction client peuvent résoudre',
    csi_27: 'Homme avec téléphone',
    csi_28: 'Nous construisons un modèle mathématique',
    csi_29: 'des facteurs pouvant influencer la satisfaction client/fidélisation client (analyse de régression)',
    csi_30: 'Optimiser un',
    csi_31: "les processus d'affaires de l'entreprise",
    csi_32: 'Nous construisons un indice de satisfaction client calculé',
    csi_33: '(SCI) et comparez-le avec un déclaratif',
    csi_34: 'Nous effectuons des recherches',
    csi_35: 'pour les segments B2B et B2C.',
    csi_36: 'Nos questionnaires sont élaborés par une équipe',
    csi_37: "d'analystes spécialement pour les projets individuels de nos clients",
    csi_38: 'Pourquoi nous',
    csi_39: 'Pourquoi nous choisir?',
    csi_40: 'Methods of collecting information:',
    main_1: 'améliorer leur performance financière en apprenant des expériences de leurs clients',
    main_2: 'Conseils et services СX une vision à 360° de votre entreprise',
    main_3: "Découvrez notre gamme de solutions et d'outils que nous utilisons pour résoudre vos défis d'affaires",
    main_4: 'Des produits',
    main_5: 'La recherche qualitative consiste à collecter et à analyser des données non numériques pour comprendre des concepts, des opinions ou des expériences',
    main_6: "Les méthodes de recherche quantitative sont une mesure objective et une analyse numérique des données recueillies par le biais de sondages, de questionnaires et d'enquêtes",
    main_7: "Le client mystère est une technique d'étude de marché permettant de mesurer et de surveiller les interactions entre un client et une entreprise ou une organisation au cours d'un scénario prédéfini.",
    main_8: 'Système de gestion des commentaires. Voix de votre client dans un système Système de gestion des commentaires des clients',
    main_9: "Une plate-forme d'intelligence de contenu alimentée par l'AI fournissant des informations sur l'audience",
    main_10: 'Logiciel de gestion des performances. Application gamifiée pour augmenter les ventes',
    main_11: "La surveillance des prix est le processus de collecte, de traitement et d'analyse des prix des concurrents",
    main_12: 'La conception de services consiste à comprendre les besoins et les désirs des personnes qui utiliseront un produit ou un service en passant du temps avec ',
    main_13: 'Notre équipe analytique applique les approches les plus récentes et les plus fondamentales qui ont fait leurs preuves auprès de nombreux clients',
    main_14: 'Méthodologies',
    main_15: 'Carte du parcours client',
    main_16: 'La Cartographie du Parcours Client (CPC) consiste à définir un public cible, à comprendre ses besoins et son environnement, puis à créer un plan pour offrir une expérience attrayante',
    main_17: 'CPC',
    main_18: 'Score de Recommandation Net',
    main_19: "Score de Recommandation Net (SRN) est l'étalon-or des métriques d'expérience client, basé sur une question : Quelle est la probabilité que vous recommandiez l'organisation/le produit/le service?",
    main_20: 'SRN',
    main_21: 'Suivi de la Santé de la Marque',
    main_22: "La recherche sur le Suivi de la Santé de la Marque (SSM) peut mesurer les performances de votre marque en termes de notoriété et d'utilisation, de positionnement et de performance de la marque",
    main_23: 'SSM',
    main_24: 'Recherche sur les ressources humaines',
    main_25: 'La recherche sur les ressources humaines est utilisée pour évaluer les pratiques et les performances des RH et offre une analyse détaillée du développement et de la gestion actuels',
    main_26: 'Recherche RH',
    main_27: 'Indice de satisfaction de la clientèle',
    main_28: 'La recherche sur les ressources humaines est utilisée pour évaluer les pratiques et les performances des RH et offre une analyse détaillée du développement et de la gestion actuels',
    main_29: 'ISC',
    main_30: 'Tests UX',
    main_31: "Les tests d'utilisabilité (UX) sont la façon dont de vraies personnes interagissent avec un site Web, une application ou un autre produit et observent leur comportement et leurs réactions",
    main_32: 'Pourquoi nous',
    main_33: "Notre mission est d'aider les entreprises à offrir une expérience client hautement efficace",
    main_34: 'Priorité à la qualité',
    main_35: 'Nous contrôlons la qualité',
    main_36: "lorsqu'il s'agit de données de recherche. Nous mettons tous les ensembles de données à la disposition des clients",
    main_37: 'Rentable',
    main_38: 'Notre expérience, nos ressources et notre rationalisation',
    main_39: "les processus commerciaux nous permettent de vous faire gagner du temps et de l'argent",
    main_40: 'Solutions intelligentes',
    main_41: 'Nous utilisons diverses techniques',
    main_42: 'et des boîtes à outils pour résoudre vos problèmes particuliers',
    main_43: 'Priorité à la qualité',
    main_44: 'Nous contrôlons la qualité',
    main_45: "lorsqu'il s'agit de données de recherche. Nous mettons tous les ensembles de données à la disposition des clients",
    main_46: 'Rentable',
    main_47: 'Notre expérience, nos ressources et notre rationalisation',
    main_48: "les processus commerciaux nous permettent de vous faire gagner du temps et de l'argent",
    main_49: 'Solutions intelligentes',
    main_50: 'Nous utilisons diverses techniques',
    main_51: 'et des boîtes à outils pour résoudre vos problèmes particuliers',
    main_52: 'Payer et emporter',
    main_53: 'Électronique',
    main_54: 'Luxe/Premium',
    main_55: 'Restauration & Hospitalité',
    main_56: 'Vente au détail',
    main_57: 'Automobile',
    main_58: 'Mode',
    main_59: 'Banques',
    main_60: 'Voyage & Loisirs',
    main_61: 'Montres & Bijoux',
    main_62: 'Appareils ménagers',
    main_63: 'Blog',
    main_64: "Allez sur le blog pour lire d'autres articles intéressants",
    main_65: 'Audit de vente au détail',
    main_66: 'Collectez des données détaillées sur votre magasin ou votre marque, analysez les obstacles à la croissance et créez des stratégies efficaces pour les surmonter',
    cjm_1: 'Client',
    cjm_2: 'Carte du trajet',
    cjm_3: 'Entrez dans le monde des clients et partagez leur expérience',
    cjm_4: "Qu'est-ce qu'une Carte de Parcours Client?",
    cjm_5: 'CPC fournit un historique visualisé de interaction du consommateur avec un produit, service, entreprise ou marque via divers canaux et pendant une certaine période de temps.',
    cjm_6: "Cela permet aux entreprises de objectivement analyser l'expérience client interaction, fixer et éliminer les obstacles qui surviennent et de proposer des recommandations pour améliorer le produit.",
    cjm_7: "Détermination de l'AT, segmentation",
    cjm_8: "Identification des étapes de l'interaction client",
    cjm_9: "Identification des principaux canaux d'interaction clients",
    cjm_10: "Recherche d'expérience client",
    cjm_11: "Analyse des barrières et hypothèse d'optimisation",
    cjm_12: 'Exécution et test des hypothèses',
    cjm_13: "La conception d'une carte de parcours client comprend les étapes suivantes :",
    cjm_14: 'La Carte du Parcours Client démontrera :',
    cjm_15: "CPC fournit un historique visualisé de l'interaction des consommateurs avec un produit, un service, une entreprise ou une marque à travers différents canaux et pendant une certaine période de temps.",
    cjm_16: "Nous assurons le travail d'équipe",
    cjm_17: 'entre analystes et concepteurs',
    cjm_18: 'Nous proposons à un particulier',
    cjm_19: 'approche de chaque client',
    cjm_20: 'Nous pouvons augmenter globalement',
    cjm_21: "la satisfaction du client avec le service/produit et finalement augmenter sa fidélité à l'entreprise",
    cjm_22: 'Nous garantissons la',
    cjm_23: 'optimisation du parcours du consommateur et prise en compte des processus métiers de l’entreprise',
    cjm_24: 'Nous offrons à nos clients',
    cjm_25: "la possibilité d'utiliser des approches combinées : CATI, CAWI, focus group, entretiens approfondis, entretiens d'experts, ethnographie, etc.",
    cjm_26: 'Pourquoi nous choisir?',
    methods_hr_1: 'Recherche en ressources humaines',
    methods_hr_2: 'Obtenez des informations objectives sur votre équipe et découvrez comment améliorer les performances RH',
    methods_hr_3: "Quelques raisons pour lesquelles vous pourriez avoir besoin d'une recherche en RH",
    methods_hr_4: 'du personnel interrogé',
    methods_hr_5: 'projets rh mis en place',
    methods_hr_6: 'des sessions de formation du personnel ont été organisées',
    methods_hr_7: "1er holding européen pour l'expérience client et la recherche collaborateurs",
    methods_hr_8: '4service RH recherche en chiffres',
    methods_hr_9: 'Pour identifier les problèmes et les faiblesses à chaque étape de la vie des collaborateurs de votre entreprise : recherche/recrutement/onboarding/carrière développement/recertifications',
    methods_hr_10: 'Pour savoir quelles occasions méritent augmentations et bonus',
    methods_hr_11: "Vous devez prévoir ou apporter des solutions à l'insatisfaction des employés",
    methods_hr_12: "Grâce à la recherche sur les RH des méthodes nouvelles et actuelles d'évaluation des politiques RH, des programmes et des pratiques sont possibles",
    methods_hr_13: "Pour améliorer la santé mentale des employés de l'entreprise",
    methods_hr_14: 'Si vous souhaitez améliorer votre productivité grâce à une approche axée sur les objectifs',
    methods_hr_15: 'Si vous avez une équipe internationale et/ou à distance et vous devez la gérer',
    methods_hr_16: 'Pour comprendre à quel point votre marque employeur est attrayante pour les employés potentiels',
    methods_hr_17: 'La satisfaction des employés',
    methods_hr_18: 'Recherche sur la marque RH',
    methods_hr_19: 'Diversité culturelle',
    methods_hr_20: 'Rémunération et plans salariaux',
    methods_hr_21: "Engagement et fidélité de l'équipe",
    methods_hr_22: 'Employés de la catégorie protégée',
    methods_hr_23: 'Domaines de recherche',
    methods_hr_24: 'Solutions 1',
    methods_hr_25: 'eNPS, Satisfaction des employés, Loyauté des employés',
    methods_hr_26: 'Ce que nous mesurons :',
    methods_hr_27: 'Loyauté:',
    methods_hr_28: 'la fidélité des employés, prédire le comportement futur, déterminer le poids des facteurs qui affectent la fidélité',
    methods_hr_29: 'Implication:',
    methods_hr_30: 'implication des employés dans le travail, intérêt pour les résultats et les réalisations',
    methods_hr_31: 'Engagement:',
    methods_hr_32: "identification des valeurs des employés et des valeurs de l'entreprise",
    methods_hr_33: 'Faites confiance 360:',
    methods_hr_34: "Faites confiance à la direction, les collègues, les subordonnés, confiance dans les stratégies et les politiques de l'employeur",
    methods_hr_35: 'Satisfaction:',
    methods_hr_36: 'définition des facteurs propres à chaque entreprise, évaluation de la satisfaction sur chaque facteur clé influant sur la fidélisation',
    methods_hr_37: 'Solutions 2',
    methods_hr_38: 'Marque employeur',
    methods_hr_39: 'Employeur interne',
    methods_hr_40: 'analyse de la marque (enquête auprès du personnel, des parties prenantes, des recruteurs internes et des spécialistes RH)',
    methods_hr_41: 'Analyse des employeurs externes',
    methods_hr_42: "(analyse de la concurrence EVP, enquête auprès des recruteurs externes, enquête auprès des demandeurs d'emploi)",
    methods_hr_43: 'Notoriété et réputation de la marque',
    methods_hr_44: 'analyse',
    methods_hr_45: 'Présence employeur',
    methods_hr_47: 'Référence employeur',
    methods_hr_48: 'collection',
    methods_hr_49: 'Benchmark concurrentiel',
    methods_hr_50: 'sur des facteurs clés tels que',
    methods_hr_51: 'Salaire,',
    methods_hr_52: 'environnement de travail, ambiance, nature du travail',
    methods_hr_53: 'Analyse des besoins et des attentes',
    methods_hr_54: "des demandeurs d'emploi",
    methods_hr_55: 'Entretiens approfondis,',
    methods_hr_56: 'la recherche documentaire et la recherche quantitative sont utilisées',
    methods_hr_57: 'Solutions 3',
    methods_hr_58: 'Carte du parcours des employés/employé mystère',
    methods_hr_59: "construire le parcours du demandeur d'emploi identifier",
    methods_hr_60: "les grandes étapes de la recherche, les canaux de communication, les critères de sélection et d'évaluation des demandeurs d'emploi",
    methods_hr_61: 'surveiller toutes les étapes',
    methods_hr_62: "et les canaux de communication avec les demandeurs d'emploi mystères (juste prendre rendez-vous pour un entretien, avoir un entretien, un stage dans l'entreprise)",
    methods_hr_63: 'Identification',
    methods_hr_64: "des faiblesses dans les processus de recrutement et d'intégration",
    methods_hr_65: "Recommandations d'amélioration,",
    methods_hr_66: "benchmarking avec les leaders de l'industrie",
    mystery_shopping_1: 'Mystère',
    mystery_shopping_2: 'Achats',
    mystery_shopping_3: 'Évaluez votre service avec de vrais clients',
    mystery_shopping_4: 'Vérifier les normes évaluer votre personnel se rapprocher de votre client',
    mystery_shopping_5: "Nous sommes membre d'élite de l'association professionnelle des fournisseurs d'achats mystères MSPA Europe",
    mystery_shopping_6: "d'expérience",
    mystery_shopping_7: 'visites mensuelles',
    mystery_shopping_8: 'clients mystères du monde entier',
    mystery_shopping_9: 'Projets actifs',
    mystery_shopping_10: 'bureaux, siège en Autriche',
    mystery_shopping_11: 'Fournisseurs européens de shopping mystère',
    mystery_shopping_12: 'notes de qualité de service',
    mystery_shopping_13: 'Questionnaire',
    mystery_shopping_14: 'Logiciel',
    mystery_shopping_15: "Profil de l'acheteur",
    mystery_shopping_16: 'Validation et objection',
    mystery_shopping_17: 'Analytique',
    mystery_shopping_18: 'Avec ces 5 étapes, notre société 4Service garantit la qualité du service client mystère',
    mystery_shopping_19: 'Programme',
    mystery_shopping_20: 'Les 5 éléments du programme Achats Mystère de 4Service',
    mystery_shopping_21: 'Questionnaire:',
    mystery_shopping_22: "l'essentiel de votre programme",
    mystery_shopping_23: 'Cette partie du programme est visible par toutes les parties prenantes',
    mystery_shopping_24: 'Pour les top-managers.',
    mystery_shopping_25: 'Afficher toutes les étapes importantes et les détails du processus',
    mystery_shopping_26: 'Pour les employés.',
    mystery_shopping_27: "Refléter ce que les employeurs attendent d'eux",
    mystery_shopping_28: 'Pour les clients mystères.',
    mystery_shopping_29: 'Clair et structuré pour éviter la confusion et les préjugés',
    mystery_shopping_30: 'Logiciel Shopmetrics:',
    mystery_shopping_31: 'convivial et innovant',
    mystery_shopping_32: 'Avantages:',
    mystery_shopping_33: 'Intègre',
    mystery_shopping_34: 'avec vos processus métier et vos logiciels',
    mystery_shopping_35: 'Basé sur le GDPR',
    mystery_shopping_36: 'Boîte à outils de gestion de la confidentialité',
    mystery_shopping_37: 'Détaillé',
    mystery_shopping_38: 'Forfait Conformité Légale',
    mystery_shopping_39: 'Rapport',
    mystery_shopping_40: 'constructeur',
    mystery_shopping_41: 'Gestion',
    mystery_shopping_42: "des niveaux d'accès",
    mystery_shopping_43: 'La plupart des questionnaires',
    mystery_shopping_44: 'sont terminés en une heure',
    mystery_shopping_45: 'Profil des acheteurs:',
    mystery_shopping_46: 'Plus de 200 000 clients mystères dans le monde',
    mystery_shopping_47: 'Critères principaux: proximité maximale avec votre public cible, avec une expérience antérieure avec votre entreprise ou vos concurrents',
    mystery_shopping_48: 'Formation des acheteurs:',
    mystery_shopping_49: "Test de formation d'introduction",
    mystery_shopping_50: 'Skype/Appel téléphonique',
    mystery_shopping_51: "Test d'évaluation (facultatif)",
    mystery_shopping_52: 'Validation et objections',
    mystery_shopping_53: 'Validation',
    mystery_shopping_54: 'Système',
    mystery_shopping_55: '100% rapports',
    mystery_shopping_56: "sont revus par l'équipe de validation",
    mystery_shopping_57: 'Appels supplémentaires',
    mystery_shopping_58: 'pour clarification',
    mystery_shopping_59: 'Enregistrements audio',
    mystery_shopping_60: 'après la visite pour noter les problèmes les plus importants',
    mystery_shopping_61: 'Fraude aux employés',
    mystery_shopping_62: 'système de prévention',
    mystery_shopping_63: 'Objection',
    mystery_shopping_64: 'Système',
    mystery_shopping_65: "L'évaluation de tout acheteur",
    mystery_shopping_66: "peut être contesté par l'employé directement sur le site",
    mystery_shopping_67: 'Chaque objection',
    mystery_shopping_68: "est pris en compte et répondu par l'équipe de validation locale",
    mystery_shopping_69: 'Vous pouvez voir la statistique',
    mystery_shopping_70: 'des objections',
    mystery_shopping_71: 'Analytique.',
    mystery_shopping_72: 'Le rapport est présenté en personne et contient:',
    mystery_shopping_73: 'Entreprise',
    mystery_shopping_74: 'points douloureux et préoccupations',
    mystery_shopping_75: 'Dynamique',
    mystery_shopping_76: 'par divisions et sections',
    mystery_shopping_77: 'Corrélation',
    mystery_shopping_78: 'entre le respect des normes et la satisfaction client',
    mystery_shopping_79: 'Analyse comparative',
    mystery_shopping_80: 'contre des concurrents',
    mystery_shopping_81: 'Pratique',
    mystery_shopping_82: "recommandations sur l'amélioration du service",
    mystery_shopping_83: "Qu'est-ce qui nous differencie?",
    mystery_shopping_84: "Nous réalisons des séances d'idéation",
    mystery_shopping_85: 'Non seulement nous recommandons quoi faire, mais nous recherchons avec vous des moyens pratiques de résoudre les problèmes de service.',
    mystery_shopping_86: "Les séances d'idéation sont un outil puissant qui nous aide à:",
    mystery_shopping_87: "Développer des orientations pour l'amélioration des produits ou des services",
    mystery_shopping_88: 'Explorer de nouvelles opportunités de flux de revenus et de nouvelles stratégies commerciales',
    mystery_shopping_89: 'Trouver des solutions aux défis complexes centrés sur le client',
    mystery_shopping_90: 'Transformer les points douloureux des clients en délices',
    mystery_shopping_91: 'Nous réalisons des entretiens vidéo',
    mystery_shopping_92: 'Nous ne recueillons pas seulement des questionnaires - nous recevons également des commentaires en direct des acheteurs',
    mystery_shopping_93: 'Nous faisons cela afin de:',
    mystery_shopping_94: 'Nos animateurs mèneront des entretiens approfondis et donneront un retour efficace',
    mystery_shopping_95: "Visualisez l'expérience du point de vue du client",
    mystery_shopping_96: 'Améliorez votre stratégie de marketing de contenu',
    mystery_shopping_97: 'Prédire le comportement des clients',
    mystery_shopping_98: 'Nous concevons la Carte du Parcours Client',
    mystery_shopping_99: 'La cartographie du parcours client nous aide à nous mettre à la place des clients pour comprendre leurs besoins et leurs défis.',
    mystery_shopping_100: 'Créer un parcours client est le meilleur moyen de:',
    mystery_shopping_101: "Voir l'expérience du point de vue du client",
    mystery_shopping_102: 'Améliorez votre stratégie de marketing de contenu',
    mystery_shopping_103: 'Prédire le comportement des clients',
    mystery_shopping_104: 'Identifier les lacunes dans le service ou les communications',
    mystery_shopping_105: "Nous proposons différents types d'achats mystères",
    mystery_shopping_106: "Types d'achat mystère",
    mystery_shopping_107: 'Appel mystérieux',
    mystery_shopping_108: "Appelle vos managers et/ou centre d'appels pour déterminer la qualité du service fourni par téléphone",
    mystery_shopping_109: 'Achat mystère de luxe',
    mystery_shopping_110: 'Service de vérification dans les lieux de luxe par une catégorie spéciale de clients mystères',
    mystery_shopping_111: 'Visites compétitives',
    mystery_shopping_112: 'Le moyen le plus simple de comprendre comment fonctionne votre concurrent',
    mystery_shopping_113: 'Employé mystère',
    mystery_shopping_114: "Vous aidera à comprendre l'ensemble du parcours de votre employé (embauche, adaptation, processus de bureau, etc.)",
    mystery_shopping_115: 'Visites de motivation',
    mystery_shopping_116: "Un outil utile pour stimuler l'offre de services et/ou de produits complémentaires, la vente de produits spécifiques",
    mystery_shopping_117: 'Client mystère en ligne',
    mystery_shopping_118: "Simule et mesure l'expérience client dans votre boutique en ligne, votre site Web ou votre application",
    mystery_shopping_119: 'Vérification des soldes et des promotions',
    mystery_shopping_120: 'Aide à identifier la façon dont les promotions sont affichées dans tous les emplacements et leur efficacité',
    mystery_shopping_121: "Visites d'achat et de retour",
    mystery_shopping_122: "Aide à refléter l'ensemble du processus, de l'achat au retour, afin d'améliorer l'expérience client",
    mystery_shopping_123: '> 20 années',
    nps_1: 'Promotion Net',
    nps_2: 'Score',
    nps_3: 'Mesurer, suivre et améliorer la fidélité des clients',
    nps_4: 'Ils répondent avec un score de 0 à 6.',
    nps_5: "Ils ont peut-être eu une mauvaise expérience et sont peu susceptibles d'acheter à nouveau chez vous, et peuvent même décourager les autres d'acheter chez vous.",
    nps_6: 'Ce sont des répondants qui obtiennent un score compris entre 7 et 8.',
    nps_7: 'Ils sont satisfaits de votre service mais pas assez heureux pour être considérés comme des promoteurs.',
    nps_8: 'Ce sont les répondants qui évaluent votre entreprise comme un 9 ou un 10.',
    nps_9: "Cela signifie qu'ils vous recommanderaient à un ami ou à un collègue, et défendent donc vos intérêts.",
    nps_10: "Qu'est-ce que le SRN?",
    nps_11: 'Il mesure la perception des clients en se basant sur une question simple:',
    nps_12: 'Quelle est la probabilité que vous recommandiez [Organisation/Produit/Service] à un ami ou à un collègue?',
    nps_13: 'Mon entreprise en a-t-elle besoin?',
    nps_14: 'Le SRN est votre croissance durable',
    nps_15: 'Comprendre la dynamique de la fidélité client',
    nps_16: 'Motivez votre personnel',
    nps_17: 'Comprendre la relation avec différents produits',
    nps_18: 'Comparez la façon dont les clients vous traitent et traitent vos concurrents',
    nps_19: 'Que puis-je faire avec le SRN?',
    nps_20: "Pour quel type d'entreprise le SRN est-il pertinent ?",
    nps_21: "Méthodes de collecte d'informations :",
    nps_22: 'Nos recommandations lorsque vous travaillez avec SRN',
    nps_23: 'Cela signifie que nous élaborons des lignes directrices pour aider à transformer les détracteurs en promoteurs',
    nps_24: 'Nous travaillons en "boucle fermée"',
    nps_25: 'SRN est une recherche de suivi dans laquelle nous suivons les changements dans la fidélité des clients',
    nps_26: 'Nous surveillons la dynamique',
    nps_27: 'Nous vous proposons de toujours évaluer les concurrents de votre créneau pour une analyse plus approfondie',
    nps_28: 'Évaluation des concurrents',
    price_monitoring_1: 'Surveillance des prix',
    price_monitoring_2: 'Surveillance de la vente au détail',
    price_monitoring_3: 'Augmentez votre potentiel de vente et optimisez votre politique de prix grâce à la veille tarifaire de vos concurrents',
    price_monitoring_4: '4Service fournit des services de surveillance des prix dans le monde entier depuis plus de 20 ans',
    price_monitoring_5: "Les prix sont contrôlés dans un court délai par notre équipe d'auditeurs internes qualifiés, ce qui nous permet de réagir rapidement aux changements de prix des concurrents",
    price_monitoring_6: 'Défis que notre entreprise peut vous aider à résoudre grâce à la surveillance des prix',
    price_monitoring_7: 'Évaluation des prix des concurrents',
    price_monitoring_8: 'et politiques de produits',
    price_monitoring_9: 'Suivi des promotions',
    price_monitoring_10: 'et offres spéciales',
    price_monitoring_11: 'Surveillance des conditions du marché',
    price_monitoring_12: 'et environnement de marché',
    price_monitoring_13: 'Vérification et contrôle des points de vente',
    price_monitoring_14: 'pour équipements spéciaux et marchandises',
    price_monitoring_15: 'Gestion du produit',
    price_monitoring_16: 'flux au sein du commerce',
    price_monitoring_17: "Conditions d'optimisation",
    price_monitoring_18: 'pour une meilleure collaboration avec les fournisseurs, les revendeurs',
    price_monitoring_19: "Mesurer l'espace en rayon,",
    price_monitoring_20: 'évaluation du positionnement des marchandises, de la disponibilité des stocks',
    price_monitoring_21: 'Indicateurs clés et critères de suivi',
    price_monitoring_22: 'Toutes les données sont compilées dans une interface comprenant :',
    price_monitoring_23: 'nom',
    price_monitoring_24: 'de la catégorie de produits et de marques',
    price_monitoring_25: 'disponibilité',
    price_monitoring_26: 'de matériel promotionnel',
    price_monitoring_27: 'prix',
    price_monitoring_28: "et la disponibilité d'éventuelles offres promotionnelles",
    price_monitoring_29: 'étagère',
    price_monitoring_30: 'taille',
    price_monitoring_31: 'produit',
    price_monitoring_32: 'afficher',
    price_monitoring_33: 'UGS',
    price_monitoring_34: 'orienté vers',
    price_monitoring_35: 'taper',
    price_monitoring_36: "d'emballage",
    price_monitoring_37: 'tout autre',
    price_monitoring_38: 'informations générales',
    price_monitoring_39: "Analyse d'avant-projet:",
    price_monitoring_40: 'Nous étudions',
    price_monitoring_41: 'la politique de prix et de produits de votre entreprise, les pratiques commerciales et industrielles',
    price_monitoring_42: 'Nous fournissons',
    price_monitoring_43: 'une formation, des tests et une certification rapides pour les auditeurs',
    price_monitoring_44: 'Nous personnalisons',
    price_monitoring_45: 'et configurer le logiciel',
    price_monitoring_46: 'Travail sur le terrain:',
    price_monitoring_47: 'Visites au détail',
    price_monitoring_48: 'prises électriques',
    price_monitoring_49: 'Rapport:',
    price_monitoring_50: 'Notre équipe analytique',
    price_monitoring_51: "prépare des rapports en ligne dans les comptes personnels de l'entreprise",
    price_monitoring_52: 'Nous fournissons',
    price_monitoring_53: 'un reportage photo complet, rapport PPT, rapport Excel en termes de type de localité, nom de chaîne, adresse de point de vente',
    price_monitoring_54: 'Comment cela',
    price_monitoring_55: 'fonctionne',
    price_monitoring_56: 'la taille des remises individuelles',
    price_monitoring_57: 'calcul des frais',
    price_monitoring_58: 'taille des bonus individuels',
    price_monitoring_59: 'Programmes de fidélité',
    price_monitoring_60: 'etc.',
    price_monitoring_61: 'Nous utilisons des logiciels spécialisés:',
    price_monitoring_62: 'PRADATA, Shopmetrics et offrons un support technique complet',
    price_monitoring_63: 'Déploiement rapide',
    price_monitoring_64: "et mise à l'échelle (dès le jour 1)",
    price_monitoring_65: 'Toutes les données confirmées',
    price_monitoring_66: 'par photos et marquage GPS',
    price_monitoring_67: 'Nous avons le nombre nécessaire',
    price_monitoring_68: "d'agents de terrain dans n'importe quel pays",
    price_monitoring_69: 'Nous pouvons travailler avec de grands',
    price_monitoring_70: 'quantités de données',
    price_monitoring_71: 'Suivi des prix individuels',
    price_monitoring_72: 'Si vous avez des produits complexes - nous pouvons vous aider à définir votre stratégie de prix',
    price_monitoring_73: 'Nous pouvons surveiller les prix des concurrents individuels, par exemple:',
    price_monitoring_74: 'Bas',
    price_monitoring_75: 'prix',
    price_monitoring_76: 'Haute',
    price_monitoring_77: 'efficacité',
    price_monitoring_78: 'Rapport Excel',
    price_monitoring_79: 'Rapport PPT',
    price_monitoring_80: 'Rapport BI',
    price_monitoring_81: 'Les données de tarification des concurrents vous aident à prendre les meilleures décisions en matière de tarification',
    price_monitoring_82: 'Nous fournissons un rapport photo complet, un rapport PPT, un rapport Excel en termes de type de localité',
    qualitative_research_1: 'Qualitatives',
    qualitative_research_2: 'recherches',
    qualitative_research_3: "Recueillir, analyser et interpréter les données à travers l'observation des actions et des paroles de vos clients",
    qualitative_research_4: 'Valeurs',
    qualitative_research_5: 'Comportement',
    qualitative_research_6: 'Arrière-plan',
    qualitative_research_7: 'Attentes',
    qualitative_research_8: 'Environnement',
    qualitative_research_9: 'Craintes/obstacles',
    qualitative_research_10: 'A quoi sert le choix du client ?',
    qualitative_research_11: 'Homme avec téléphone',
    qualitative_research_12: 'marque',
    qualitative_research_13: 'catégorie',
    qualitative_research_14: 'publicité',
    qualitative_research_15: 'produit',
    qualitative_research_16: "La particularité des méthodes qualitatives est qu'elles nous permettent de comprendre les causes et la motivation du comportement des consommateurs",
    qualitative_research_17: "Аanalyse de l'attitude du consommateur face à un phénomène particulier",
    qualitative_research_18: 'Perspectives et expériences individuelles',
    qualitative_research_19: 'Sujets qui ne peuvent pas être étudiés par des sondages',
    qualitative_research_20: 'Sujets sensibles',
    qualitative_research_21: 'Public difficile à atteindre',
    qualitative_research_22: 'Géographie complexe',
    qualitative_research_23: 'Entretiens approfondis utiles pour:',
    qualitative_research_24: 'Entretiens approfondis',
    qualitative_research_25: 'Une entrevue en profondeur est une technique de recherche qualitative qui consiste à mener des entrevues individuelles avec les répondants pour explorer leurs points de vue sur une idée, un programme ou une situation particulière. Un entretien approfondi explore les expériences / perceptions / pratiques individuelles de manière détaillée.',
    qualitative_research_26: 'Buts:',
    qualitative_research_27: 'Caractérisation des normes sociales et culturelles',
    qualitative_research_28: 'Public difficile à atteindre',
    qualitative_research_29: 'Partage et comparaison (Morgan) étudiés par sondages',
    qualitative_research_30: 'Sujets qui ne peuvent pas être étudiés par des sondages',
    qualitative_research_31: 'Groupes de discussion approfondis utiles pour:',
    qualitative_research_32: 'Groupes de concentration',
    qualitative_research_33: "Exploration approfondie des rencontres individuelles, des discussions naissantes, des discussions entre manifestations sous l'influence de l'animateur du groupe.",
    qualitative_research_34: 'Générer des informations sur les expériences partagées et les normes sociales par le biais de discussions de groupe, de données narratives dans une discussion ciblée.',
    qualitative_research_36: 'Avantages:',
    qualitative_research_37: 'Étudier le mode de vie et le comportement du consommateur dans différentes conditions réelles (à domicile, en point de vente, au travail, etc.)',
    qualitative_research_38: 'Rechercher des idées pour le développement de nouveaux produits, emballages, amélioration des services, etc.',
    qualitative_research_39: 'Recherche ethnographique approfondie utile pour: ',
    qualitative_research_40: 'Recherche ethnographique',
    qualitative_research_41: "L'ethnographie est un type de recherche qualitative qui consiste à s'immerger dans une communauté ou une organisation particulière pour observer de près leur comportement et leurs interactions.",
    qualitative_research_42: "L'ethnographie est une méthode de recherche flexible qui vous permet d'acquérir une compréhension approfondie de la culture, des conventions et de la dynamique sociale partagées d'un groupe. Toutefois, cela implique également des défis pratiques et éthiques.",
    qualitative_research_43: 'Méthodes :',
    qualitative_research_44: "L'ethnographie est une étude du consommateur en conditions réelles et en interaction avec un produit/service",
    qualitative_research_45: 'Avantages de travailler avec nous',
    qualitative_research_46: "Nous pouvons trouver des répondants de n'importe quel public cible. Et nous contrôlons la qualité du recrutement",
    qualitative_research_47: 'Recrutement de tout type de complexité',
    qualitative_research_48: 'Nos modérateurs sont multilingues et ont des compétences de facilitation',
    qualitative_research_49: 'Modérateurs experts',
    qualitative_research_50: 'Travailler en ligne/hors ligne',
    qualitative_research_51: "Nous fournissons l'audio/vidéo",
    qualitative_research_52: 'enregistrements',
    qualitative_research_53: "Nous pouvons mener des recherches qualitatives dans n'importe quelle localité",
    quantitative_research_1: 'Étude de satisfaction client',
    quantitative_research_2: 'avec un produit ou un service en particulier (SCI, NPS)',
    quantitative_research_3: 'Mener une segmentation',
    quantitative_research_4: 'enquêtes sur différents marchés',
    quantitative_research_5: 'Étude de marché',
    quantitative_research_6: 'Gestion du NPS',
    quantitative_research_7: 'Analyse de la concurrence',
    quantitative_research_8: 'Quantitatives',
    quantitative_research_9: 'recherches',
    quantitative_research_10: 'Méthodologie de recherche qui utilise pour tester les théories sur les attitudes et les comportements des gens sur la base de données objectives, numériques et statistiques',
    quantitative_research_11: 'années',
    quantitative_research_12: "d'expérience CX dans le monde",
    quantitative_research_13: 'panneau',
    quantitative_research_14: 'des répondants',
    quantitative_research_15: 'des pays',
    quantitative_research_16: 'nous menons des projets partout dans le monde',
    quantitative_research_17: 'Qui nous sommes',
    quantitative_research_18: "4Service est une recherche marketing mondiale et une holding CX. Nous offrons des services experts de collecte de données à une grande variété d'entreprises",
    quantitative_research_19: '4service En Chiffres',
    quantitative_research_20: 'Assisté par ordinateur',
    quantitative_research_21: 'Entrevue Web',
    quantitative_research_22: "Une technique de questionnaire basée sur Internet. Le répondant remplit un questionnaire électronique sans l'aide d'un intervieweur. Le questionnaire peut être envoyé par voie électronique ou affiché sur le site Web.",
    quantitative_research_23: 'Entretien Web assisté par ordinateur',
    quantitative_research_24: 'Dans la plupart des cas',
    quantitative_research_25: 'cette méthode est la plus rentable en termes de coûts de matériel et de temps',
    quantitative_research_26: 'Cette enquête fournit',
    quantitative_research_27: "une opportunité d'interviewer un public difficile à atteindre",
    quantitative_research_28: 'Il offre de nombreuses possibilités',
    quantitative_research_29: 'pour la démonstration de matériel vidéo et audio ainsi que des images',
    quantitative_research_30: 'Les répondants peuvent être',
    quantitative_research_31: "situé n'importe où dans le monde",
    quantitative_research_32: "Cela permet d'enquêter",
    quantitative_research_33: "le comportement spécifique de l'audience Internet. Les principaux résultats sont accessibles par le client en temps réel via une interface web",
    quantitative_research_34: 'Admissible à toute confidentialité,',
    quantitative_research_35: "sujet ou problème sensible et personnel auquel les répondants ont peut-être hésité à répondre lorsqu'ils ont parlé à un intervieweur",
    quantitative_research_36: '150 000+ panneaux',
    quantitative_research_37: 'Nous avons notre propre panel de répondants dans le monde entier',
    quantitative_research_38: 'Nous travaillons avec des demandes difficiles',
    quantitative_research_39: 'En utilisant des outils complémentaires (médias sociaux, publicité), nous pouvons trouver un public spécifique et un profil sophistiqué du répondant',
    quantitative_research_40: 'Nous motivons nos répondants',
    quantitative_research_41: 'Nous avons conçu un cadre pour encourager la réalisation de longues enquêtes en fournissant des incitations matérielles',
    quantitative_research_42: 'Entretiens face à face',
    quantitative_research_43: "réalisée à l'aide d'une tablette",
    quantitative_research_44: "C'est l'une des principales méthodes de collecte de données quantitatives, au cours de laquelle l'enquêteur communique directement avec le répondant sur un questionnaire strictement structuré.",
    quantitative_research_45: "Les entretiens face-à-face peuvent être menés sur le lieu de travail ou de résidence des répondants, dans la rue ou sur des lieux de vente désignés à l'aide d'une tablette",
    quantitative_research_46: "Entretiens face à face réalisés à l'aide d'une tablette",
    quantitative_research_47: 'Détermination du niveau',
    quantitative_research_48: 'de popularité et de reconnaissance de la marque',
    quantitative_research_49: 'Segmentation des consommateurs',
    quantitative_research_50: "en fonction de leur comportement d'achat",
    quantitative_research_51: "L'analyse de l'efficacité",
    quantitative_research_52: 'de la campagne publicitaire',
    quantitative_research_53: 'Satisfaction du client',
    quantitative_research_54: 'la mesure',
    quantitative_research_55: 'Déterminer le meilleur prix',
    quantitative_research_56: 'pour le produit ou le service',
    quantitative_research_57: 'Analyse',
    quantitative_research_58: 'des préférences des consommateurs',
    quantitative_research_59: 'Étude',
    quantitative_research_60: 'de la façon dont les consommateurs achètent',
    quantitative_research_61: 'Évaluation',
    quantitative_research_62: 'de la capacité du marché',
    quantitative_research_63: 'Logiciel DigSee',
    quantitative_research_64: 'Nous utilisons un logiciel spécial avec enregistrement audio et GPS',
    quantitative_research_65: 'Intervieweurs et superviseurs',
    quantitative_research_66: "nous pouvons mener des entretiens dans n'importe quelle langue et traduire dans la langue préférée du client",
    quantitative_research_67: 'Échantillonnage',
    quantitative_research_68: 'Le service analytique développe un système de recrutement des répondants pour obtenir le meilleur résultat possible',
    quantitative_research_69: 'Assisté par ordinateur',
    quantitative_research_70: 'Entretien téléphonique',
    quantitative_research_71: "Méthodologie de collecte d'informations quantitatives par entretiens téléphoniques à l'aide d'un questionnaire clairement structuré.",
    quantitative_research_72: 'Entretien téléphonique assisté par ordinateur',
    quantitative_research_73: "Nous avons nos propres centres d'appels",
    quantitative_research_74: 'Nous utilisons un logiciel spécial avec enregistrement audio et GPS',
    quantitative_research_75: 'Opérateurs qualifiés',
    quantitative_research_76: 'Nos opérateurs parlent différentes langues, sont formés et bien testés et améliorent continuellement leurs compétences',
    quantitative_research_77: 'Bases de données',
    quantitative_research_78: 'Nous travaillons avec nos propres bases de données et vos bases de clients également',
    quantitative_research_79: 'ANS',
    quantitative_research_80: 'Nous garantissons la qualité et signons un ANS (Accord de Niveau de Service)',
    quantitative_research_81: "Établissement d'objectifs",
    quantitative_research_82: 'Détermination de la méthodologie',
    quantitative_research_83: 'Segmentation et échantillonnage',
    quantitative_research_84: 'Définir les canaux de collecte des données',
    quantitative_research_85: 'Créer un questionnaire logique pour le répondant',
    quantitative_research_86: 'Processus de collecte de données',
    quantitative_research_87: 'Assurance qualité',
    quantitative_research_88: "Techniques d'analyse",
    quantitative_research_89: 'Traitement des données',
    quantitative_research_90: "Conception de l'enquête",
    quantitative_research_91: 'Localisation GPS des enquêteurs',
    quantitative_research_92: 'Verification des données',
    quantitative_research_93: 'Programmation et test du questionnaire',
    quantitative_research_94: 'La validation des données',
    quantitative_research_95: 'Enregistrements audio en suivant les exigences du GDPR',
    quantitative_research_96: 'Formation et compétence du personnel de recherche',
    quantitative_research_97: 'Assurance qualité',
    quantitative_research_98: 'Analyse factorielle',
    quantitative_research_99: 'Segmentation',
    quantitative_research_100: 'Cartographie perceptive',
    quantitative_research_101: 'Corrélation',
    quantitative_research_102: 'Modélisation des conversions',
    quantitative_research_103: 'Jaccard',
    quantitative_research_104: 'Analyse groupée',
    quantitative_research_105: 'etc.',
    quantitative_research_106: 'Rapports analytiques',
    quantitative_research_107: "Nous utilisons SPSS® et d'autres outils pour créer des rapports analytiques en utilisant différentes méthodologies",
    quantitative_research_108: 'What business challenges сan be solved:',
    quantitative_research_109: 'What business challenges сan be solved:',
    social_responsibility_1: "4Service Responsabilité Sociale d'Entreprise",
    social_responsibility_2: "Du cœur et de l'âme de chaque membre de notre équipe. Nous visons à garder une étincelle de bienveillance chez chacun de nos employés et à rendre le monde qui nous entoure un peu meilleur",
    social_responsibility_3: 'Activités éducatives / Accompagnement des jeunes',
    social_responsibility_4: 'Nos initiatives de sensibilisation pour les enfants et les étudiants',
    social_responsibility_5: "Service Mania jeu d'entreprise",
    social_responsibility_6: "Une partie importante de nos activités de formation est le jeu d'entreprise Service Mania.",
    social_responsibility_7: 'Service Mania vous met au défi de réfléchir stratégiquement à la manière de gérer les situations inhabituelles.',
    social_responsibility_8: 'Présentation de la recherche / Diiya. Entreprise',
    social_responsibility_9: 'Une perspective commerciale et client sur la situation des services: une présentation des résultats de notre recherche 4Service par Victoria Skorbota, responsable du développement chez 4Service Ukraine',
    social_responsibility_10: 'Sessions de conception de services',
    social_responsibility_11: 'La méthodologie de conception de service est une partie importante de 4Service. Il y a quelques années, nous sommes devenus un partenaire de la DesignThinkers Academy et non seulement nous utilisons les meilleures pratiques dans notre travail, mais nous dispensons également activement des formations aux meilleures entreprises.',
    social_responsibility_12: 'Charité / Bénévolat',
    social_responsibility_13: "Orphelinats, maisons de retraite pour personnes âgées et volontariat pour l'armée ukrainienne. La partie la plus touchante de nos activités",
    social_responsibility_14: '#AideFacileAvecLeGroupe4Service',
    social_responsibility_15: "Nous travaillons en partenariat avec le Blagomai Charity Fund et essayons de célébrer et d'aider ceux qui sont dans le besoin",
    social_responsibility_16: 'Collecte de fonds pour les orphelinats et les maisons de retraite',
    social_responsibility_17: "Chez 4Service, nous avons lancé plusieurs collectes de fonds pour les maisons de retraite et les orphelinats afin d'acheter des produits de première nécessité",
    social_responsibility_18: "#Sauvonsl'Ukraine",
    social_responsibility_19: 'La guerre a touché bon nombre de nos employés et amis proches. Chacun de nous aide autant que possible',
    social_responsibility_20: 'Bien-être animal',
    social_responsibility_21: 'Tout le monde peut venir à notre bureau avec son animal de compagnie. Nous aidons les refuges, collectons des fonds pour la nourriture et parfois aussi cherchons des maisons pour les animaux sans abri',
    social_responsibility_22: 'Soutien aux refuges pour animaux',
    social_responsibility_23: "Les animaux sans abri ont autant besoin d'aide que tous les membres vulnérables de notre société.",
    social_responsibility_24: 'Notre société a pris en charge le refuge pour animaux errants Sirius (région de Kiev, Ukraine).',
    social_responsibility_25: 'Des initiatives créatives pour aider les animaux errants',
    social_responsibility_26: "Comment porter la question urgente des refuges pour animaux à l'attention de nos clients et partenaires ?",
    social_responsibility_27: 'Bureau acceptant les animaux de compagnie',
    social_responsibility_28: "Beaucoup de membres de notre équipe ont des animaux de compagnie. Nous avons créé un environnement sain où chacun peut venir au bureau avec son animal de compagnie et se sentir à l'aise",
    ux_testing_1: 'Tests UX',
    ux_testing_2: "Approche humaine et basée sur l'IA pour tester et améliorer vos sites Web et applications",
    ux_testing_3: "Qu'est-ce que les tests UX",
    ux_testing_4: 'Les tests UX vous aident à comprendre comment les gens interagissent avec votre produit, votre application ou votre site Web',
    ux_testing_5: "La navigation n'est pas très claire",
    ux_testing_6: 'Je ne sais pas comment passer une commande',
    ux_testing_7: 'Pas assez de photos dans le catalogue',
    ux_testing_8: 'Vous allez:',
    ux_testing_9: "Découvrir des opportunités d'amélioration",
    ux_testing_10: "Identifier les problèmes dans la conception et l'interaction avec le client",
    ux_testing_11: "Découvrir les difficultés rencontrées par l'utilisateur lors de l'interaction avec le site",
    ux_testing_12: 'Observer le parcours client',
    ux_testing_13: 'Reconnaître quand et pourquoi votre public cible quitte le site',
    ux_testing_14: "Définir les sections qui suscitent le moins et le plus d'intérêt et d'émotion",
    ux_testing_15: "Test UX basé sur l'utilisateur",
    ux_testing_16: '4Service vous fournit les véritables informations humaines dont vous avez besoin pour créer des sites Web et des applications centrés sur le client en utilisant des méthodes qualitatives et quantitatives',
    ux_testing_17: 'Je ne comprends pas comment utiliser le site. Où le menu est-il caché ?',
    ux_testing_18: "Je pense qu'il y a trop d'espace entre les lettres, le texte est illisible",
    ux_testing_19: "Les images se chevauchent Est-ce ainsi que c'est censé être ?",
    ux_testing_20: 'Les tests UX vous aident à comprendre comment les gens interagissent avec votre produit, votre application ou votre site Web',
    ux_testing_21: "Conception de recherche de test UX basée sur l'utilisateur :",
    ux_testing_22: "Découvrir des opportunités d'amélioration",
    ux_testing_23: 'Détermination du type et de la méthode de test (qualitatif/quantitatif)',
    ux_testing_24: "Rédaction d'hypothèses et de scénarios d'utilisation",
    ux_testing_25: 'Analyse et rapport des résultats des tests',
    ux_testing_26: 'Deux solutions',
    ux_testing_27: 'Nous proposons deux approches pour réussir les tests UX',
    ux_testing_28: "Test UX basé sur l'utilisateur",
    ux_testing_29: "IA + tests UX basés sur l'humain",
    ux_testing_30: 'Nos points forts:',
    ux_testing_31: 'Nous pouvons analyser le paysage concurrentiel',
    ux_testing_32: 'Nous pouvons faire appel à des experts restreints pour mener et participer à des entretiens',
    ux_testing_33: 'Nous pouvons mener des entretiens dans différentes langues et les traduire dans la langue requise',
    ux_testing_34: "avec l'utilisabilité existante",
    ux_testing_35: 'métriques ou normes',
    ux_testing_36: 'vos performances',
    ux_testing_37: 'face à un concurrent',
    ux_testing_38: 'versions',
    ux_testing_39: 'du même produit',
    ux_testing_40: 'Note de satisfaction',
    ux_testing_41: 'NPS',
    ux_testing_42: 'Taux de réussite',
    ux_testing_43: "Taux d'erreur",
    ux_testing_44: "Temps d'exécution de la tâche",
    ux_testing_45: "Test UX basé sur l'utilisateur",
    ux_testing_46: 'Méthode quantitative',
    ux_testing_47: 'Nous utilisons au moins 100 personnes pour participer aux tests UX pour cette étape',
    ux_testing_48: "Pertinent lorsque vous avez besoin d'une comparaison de votre site Web ou de votre application :",
    ux_testing_49: 'Mesure de la méthode quantitative :',
    ux_testing_50: 'Observation',
    ux_testing_51: "Passer du temps avec un utilisateur ou un groupe d'utilisateurs et observer leur comportement avec le produit lorsqu'ils l'utilisent au quotidien",
    ux_testing_52: 'Interview en profondeur',
    ux_testing_53: "Vous permettre d'apprendre les attitudes, les croyances, les désirs et les expériences des utilisateurs qui visitent votre site. Nous recommandons de mener 15 à 20 entretiens",
    ux_testing_54: 'Recherche ethnographique',
    ux_testing_55: "Réalisé dans l'environnement des répondants où ils utiliseront le produit. En observant, vous commencez à bien comprendre la psychologie de l'utilisateur, les défis émotionnels auxquels il est confronté.",
    ux_testing_56: "Test UX basé sur l'utilisateur",
    ux_testing_57: 'Méthode qualitative',
    ux_testing_58: 'Cette méthode aide à comprendre les motivations et la logique du comportement des utilisateurs',
    ux_testing_59: 'Nous utilisons:',
    ux_testing_60: 'Nous développons une carte du parcours client pour illustrer les résultats de nos recherches',
    ux_testing_61: '30 participants (homme/femme)',
    ux_testing_62: 'Les répondants présélectionnent et les invitent à répondre au sondage',
    ux_testing_63: 'Entretien en ligne à distance sur la plateforme Wantent',
    ux_testing_64: "IA + basé sur l'humain",
    ux_testing_65: "IA + tests UX basés sur l'humain",
    ux_testing_66: "Une combinaison de technologie unique d'intelligence artificielle Wantent et d'utilisateurs réels",
    ux_testing_67: "La grande police dans le titre a attiré l'attention",
    ux_testing_68: 'Les utilisateurs regardent les numéros  «à voix haute» mais ne lisent pas les informations à côté',
    ux_testing_69: 'Les tests UX vous aident à comprendre comment les gens interagissent avec votre produit, votre application ou votre site Web',
    ux_testing_70: 'IA + conception de recherche de tests humains',
    ux_testing_71: 'Comment ça fonctionne',
    ux_testing_72: "Wantent est une solution d'adaptation au marché du contenu basée sur l'IA.",
    ux_testing_73: "Wantent estime l'efficacité des sites Web et des applications en appliquant les technologies ML pour analyser les réactions émotionnelles et l'engagement du public.",
    ux_testing_74: "Pour interpréter les résultats, nous utilisons des techniques de narration, la diversité et l'empathie, pour stimuler la performance et la croissance.",
    ux_testing_75: "L'attention et les émotions de l'utilisateur lors des transitions entre les sections",
    ux_testing_76: 'Statistiques par groupe de participants (attention et concentration tout au long de la session)',
    ux_testing_77: 'Analyse des conversions de pages',
    ux_testing_78: "Attention et émotions pendant l'exécution des tâches",
    ux_testing_79: 'Des découvertes sur la perception',
    ux_testing_80: 'du site Web par différents groupes de participants',
    ux_testing_81: 'Analyse de la facilité',
    ux_testing_82: 'de naviguer dans les pages et la facilité de trouver les éléments importants',
    ux_testing_83: 'Une analyse de perception',
    ux_testing_84: 'des informations fournies sur le site',
    ux_testing_85: 'Zones de définition',
    ux_testing_86: "d'amélioration au sein des pages individuelles (navigation, blocs d'information, etc.)",
    ux_testing_87: 'Nous analysons en détail',
    ux_testing_88: 'Principaux résultats de la recherche',
    ux_testing_89: 'Recommandations pour améliorer la structure du site Web et des pages individuelles',
    ux_testing_90: "Nous sélectionnons avec soin le public cible pour les tests d'utilisabilité.",
    ux_testing_91: "Nous effectuons des recherches dans différents pays, différentes langues et parmi différents groupes d'utilisateurs",
    ux_testing_92: 'Nous pouvons tester dans les applications',
    ux_testing_93: 'et sur les sites Web des concurrents et fournir une analyse comparative',
    ux_testing_94: 'Nous combinons différentes méthodes de recherche.',
    ux_testing_95: 'Nos animateurs qualifiés guident le participant tout au long du processus de test.',
    ux_testing_96: 'Nous effectuons des tests',
    ux_testing_97: "sur une variété d'appareils et de versions de logiciels",
    ux_testing_98: 'Pourquoi nous choisir?',
    wantent_1: '4Service x',
    wantent_2: 'Wantent',
    wantent_3: "Un projet partenaire unique de 4Service et Wantent © Une combinaison d'intelligence artificielle et d'une approche humaine pour l'analyse de contenu",
    wantent_4: 'mesurer le succès avant que le contenu ne soit diffusé',
    wantent_5: 'atténuer les risques liés aux problèmes de diversité',
    wantent_6: 'des informations approfondies et des recommandations pour améliorer le contenu',
    wantent_7: 'décisions objectives sur le contenu et la stratégie marketing',
    wantent_8: "Wantent est une solution d'adaptation au marché du contenu basée sur l'IA.",
    wantent_9: "Wantent est une solution basée sur l'intelligence artificielle pour faire correspondre le contenu au marché. Wantent évalue l'efficacité du contenu vidéo en appliquant des technologies ML pour analyser la réponse émotionnelle et l'engagement du public.",
    wantent_10: "Wantent aide à définir l'adéquation au marché du contenu et à maximiser l'efficacité du contenu en",
    wantent_11: 'Configuration du scénario et recrutement des participants',
    wantent_12: 'conception de projet personnalisée et audience dans le monde entier',
    wantent_13: 'Recueillir les réactions des téléspectateurs',
    wantent_14: 'commentaires détaillés et clairs du public',
    wantent_15: 'Analyse de la perception du contenu',
    wantent_16: 'analyse du comportement et des réactions émotionnelles par IA',
    wantent_17: "Livraison d'idées pour la conclusion finale",
    wantent_18: "recommandations d'améliorations",
    wantent_19: "Wantent maximise l'efficacité de votre contenu",
    wantent_20: 'Pré-test du concept publicitaire',
    wantent_21: 'Test de concept créatif',
    wantent_22: "Vérifier la cohérence de la future création avec la stratégie développée et comprendre les réactions du public cible pour d'éventuels ajustements aux concepts/messages créatifs",
    wantent_23: 'Test de visibilité des éléments de marque (post-production)',
    wantent_24: "Confirmation de l'efficacité de l'investissement publicitaire/parrainage",
    wantent_25: 'Tests publicitaires',
    wantent_26: 'Test A/B',
    wantent_27: "Comparer l'efficacité des créations et accroître l'engagement et l'attention pendant la phase de production",
    wantent_28: 'Tests publicitaires lancés',
    wantent_29: "Définir les performances des messages et créer des recommandations pour améliorer l'audio et les visuels",
    wantent_30: 'Solutions pour la télévision',
    wantent_31: 'Pilotes, promos, nouveaux formats, test des présentateurs TV',
    wantent_32: 'Mener une analyse comparative pour identifier la plus engageante et la renforcer',
    wantent_33: "Tests d'émissions de télévision et de longs métrages",
    wantent_34: "Analyser l'implication, le niveau d'attention, la réponse émotionnelle des téléspectateurs lors du visionnage de longs contenus dans des conditions naturelles. Nous retrouvons automatiquement les pics d'audience et les moments de distraction pour évaluer et améliorer le montage vidéo",
    wantent_35: 'Tests UI/UX',
    wantent_36: "Prototypes de sites Web et tests d'applications mobiles",
    wantent_37: "Analyse de la réaction et des émotions des utilisateurs à l'aide de la technologie Wantent :",
    wantent_38: "niveau d'attention",
    wantent_39: 'réactions émotionnelles',
    wantent_40: 'cartes thermiques de la direction du regard des participants',
    wantent_41: "Analyse de l'utilisabilité du site Web et de l'application basée sur les commentaires des participants :",
    wantent_42: "Échelle d'utilisabilité du système (EUS)",
    wantent_43: "Score d'effort du client (facilité d'interaction)",
    wantent_44: 'Score de Promotion Net (SPN)',
    wantent_45: 'Conformité GDPR et confidentialité des utilisateurs',
    wantent_46: 'Wantent est entièrement conforme aux politiques GDPR et CCPA et respecte les principes de collecte, de stockage, de traitement et de protection des données personnelles des participants',
    wantent_47: 'Nikita Lobyntsev',
    wantent_48: 'СDO, Reface (médias et divertissement)',
    wantent_49: "Nous expérimentons chaque jour des tonnes de nouvelles idées de contenu avec des technologies d'apprentissage automatique. Wantent nous aide à comprendre et à évaluer les risques et les avantages, à découvrir les commentaires, le comportement et la perception des utilisateurs pour obtenir la causalité des différents aspects de nos solutions.",
    wantent_50: 'Alexander Smirnov',
    wantent_51: 'co-propriétaire TABASCO (Publicité)',
    wantent_52: "Wantent fournit un service vraiment unique qui nous aide à connaître des détails très mineurs sur ce que les consommateurs aiment et n'aiment pas. Il n'y a aucun moyen de tricher, il n'y a aucun moyen de mentir - Wantent sait toujours la vérité, et par la présente, nous, en tant qu'experts en marketing, obtenons des directives inestimables (hahaha - nous connaissons le prix exact et il est abordable) sur la façon d'améliorer nos communications.",
    wantent_53: 'Avis',
    terms_1: 'Termes et conditions',
    terms_2: "Conditions d'utilisation",
    terms_3: "Veuillez lire attentivement ces conditions d'utilisation («conditions d'utilisation», «accord») avant d'utiliser le site Web",
    terms_4: '(«site Web») exploité par 4Service Holdings GmbH («4Service», «nous», «nous», «notre»). ',
    terms_5: "Conditions d'utilisation En utilisant ce site Web, vous certifiez que vous avez lu et examiné le présent accord et que vous acceptez de vous conformer à ses termes. Si vous ne souhaitez pas être lié par les termes du présent accord, il vous est conseillé de quitter le site Web en conséquence. 4Service n'accorde l'utilisation et l'accès à ce site Web, ses produits et ses services qu'à ceux qui en ont accepté les termes.",
    terms_6: 'Politique de Confidentialité',
    terms_7: 'Avant de continuer à utiliser notre site Web, nous vous conseillons de lire notre politique de confidentialité concernant notre collecte de données utilisateur. Cela vous aidera à mieux comprendre nos pratiques.',
    terms_8: "Limite d'âge",
    terms_9: "Vous devez avoir au moins 16 (seize) ans avant de pouvoir utiliser ce site Web. En utilisant ce site Web, vous garantissez que vous avez au moins 16 (seize) ans et que vous pouvez légalement adhérer au présent accord. 4Service n'assume aucune responsabilité pour les responsabilités liées à la fausse déclaration d'âge.",
    terms_10: 'Propriété intellectuelle',
    terms_11: "Vous acceptez que tous les matériaux, produits et services fournis sur ce site Web sont la propriété de 4Service, ses sociétés affiliées, administrateurs, dirigeants, employés, agents, fournisseurs ou concédants de licence, y compris tous les droits d'auteur, secrets commerciaux, marques de commerce, brevets et autres propriétés intellectuelles. Vous acceptez également de ne pas reproduire ou redistribuer la propriété intellectuelle de 4Service de quelque manière que ce soit, y compris les enregistrements électroniques, numériques ou de nouvelles marques.",
    terms_12: 'Accéder à notre site Web',
    terms_13: "L'accès à notre site est autorisé sur une base temporaire, et nous nous réservons le droit de retirer ou de modifier le service que nous fournissons sur notre site sans préavis. Nous ne serons pas responsables si, pour une raison quelconque, notre site est indisponible à tout moment ou pour toute période. De temps à autre, nous pouvons restreindre l'accès à certaines parties de notre site, ou à l'intégralité de notre site, aux utilisateurs qui se sont inscrits auprès de nous. Vous êtes responsable de prendre toutes les dispositions nécessaires pour que vous ayez accès à notre site, y compris l'utilisation d'équipements compatibles avec notre site. Vous êtes également responsable de vous assurer que toutes les personnes qui accèdent à notre site via votre connexion Internet connaissent ces conditions et qu'elles les respectent.",
    terms_14: 'Loi applicable',
    terms_15: "En visitant ce site Web, vous acceptez que les lois de la République d'Autriche, sans égard aux principes des lois sur les conflits, régissent ces termes et conditions, ou tout litige de quelque nature que ce soit qui pourrait survenir entre 4Service et vous.",
    terms_16: 'Des disputes',
    terms_17: 'Tout litige lié de quelque manière que ce soit à votre visite sur ce site Web sera arbitré par le tribunal de commerce de Vienne.',
    terms_18: 'Indemnité',
    terms_19: "Vous acceptez d'indemniser 4Service et ses sociétés affiliées et de dégager 4Service de toute responsabilité contre les réclamations et demandes légales pouvant découler de votre utilisation ou mauvaise utilisation de notre site Web. Nous nous réservons le droit de sélectionner nos propres conseillers juridiques.",
    terms_20: 'La période de stockage',
    terms_21: 'Compte tenu des finalités du traitement, la durée de conservation des données à caractère personnel des Utilisateurs (durée de conservation) est de 24 mois à compter de la date à laquelle le consentement au traitement des données est dûment obtenu de Vous.',
    terms_22: 'Limitation de responsabilité',
    terms_23: "4Service n'est pas responsable des dommages qui pourraient vous survenir à la suite de votre mauvaise utilisation de notre site Web. 4Service se réserve le droit, sans préavis, d'éditer, de modifier et de changer cet accord à tout moment en mettant à jour cette publication. Votre utilisation continue du site Web maintenant, ou après la publication de tout changement ou modification, indiquera votre acceptation de ces changements ou modifications. Si une partie de cet accord est déclarée illégale, nulle ou inapplicable, cette partie sera réputée divisible et n'affectera pas la validité et l'applicabilité des autres dispositions. Les preuves éventuelles d'utilisation du site Web à des fins illégales seront fournies aux autorités chargées de l'application de la loi. Cet accord est un accord entre 4Service et l'utilisateur.",
    terms_24: "Veuillez adresser toutes les questions et préoccupations liées à la confidentialité/utilisation à l'adresse suivante :",
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Tegetthoffstrasse 7',
    terms_27: '1010 Vienne',
    terms_28: "L'Autriche",
    terms_29: 'Avis',
    terms_30: 'Lorsque les données personnelles que Vous nous fournissez ne sont pas inexactes alors, Vous êtes en droit de nous demander de les corriger effectivement (GDPR Article 16).',
    thank_you_1: 'Merci!',
    thank_you_2: 'Nous vous répondrons dans les 24 heures',
    thank_you_3: 'P.S.  Nos responsables étudient déjà votre demande pour vous proposer une meilleure offre',
    thank_you_4: 'Menu',
    thank_you_5: 'femme, dactylographie',
    privacy_policy_1: 'Formulaire de déclaration de confidentialité pour les personnes concernées (utilisateurs du site Web)',
    privacy_policy_2: 'Ce formulaire de déclaration de confidentialité (avis de confidentialité) est destiné aux personnes physiques (personnes concernées) dont les données personnelles sont collectées par 4Service Holdings GmbH, conformément aux exigences du GDPR dans les cadres de visite du site Web.',
    privacy_policy_3: '(ci-après Site Web et Utilisateurs du Site Web/Utilisateurs respectivement).',
    privacy_policy_4: "Le formulaire de déclaration de confidentialité pour les personnes concernées (utilisateurs du site Web) de 4Service Holdings GmbH est appliqué dans toutes les sociétés du groupe 4Service, qui sont sous l'autorité légale sous la supervision ou le contrôle de 4Service Holdings GmbH.",
    privacy_policy_5: 'Contacts',
    privacy_policy_6: '4Service Holdings GmbH',
    privacy_policy_7: '(Société/nous/nous),',
    privacy_policy_8: 'Adresse:',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienne, Autriche.',
    privacy_policy_10: 'E-mail:',
    privacy_policy_12: 'Qui sommes-nous ?',
    privacy_policy_13: "Un Individu (Vous) qui visite le Site Internet dans un but quelconque. Aux fins du présent document, vous serez également indiqué en tant qu'utilisateur.",
    privacy_policy_14: 'Données personnelles que nous collectons auprès de vous',
    privacy_policy_15: 'Lorsque vous entrez sur notre site Web, nous vous envoyons un avis de confidentialité, dans lequel nous vous proposons de nous demander de nous donner votre consentement pour le traitement des informations suivantes :',
    privacy_policy_16: "Adresse IP, nom d'utilisateur, nom, adresse, numéro de téléphone (fixe ou mobile), adresse e-mail, adresse, nom de l'entreprise, pays, adresse e-mail, prénom, nom, numéro de téléphone, province, état et code postal , données d'utilisation, données sur l'interaction avec des réseaux sociaux ou des plateformes externes, informations sur l'enregistrement et l'authentification sur le site Web",
    privacy_policy_17: 'situation géographique.',
    privacy_policy_18: 'Dans le cas où vous exprimez votre volonté et nous donnez un tel consentement, nous commençons à traiter ces informations vous concernant.',
    privacy_policy_19: 'Légalité (consentement)',
    privacy_policy_20: 'Lorsque vous entrez sur notre site Web, nous vous envoyons un avis de confidentialité, dans lequel nous vous proposons de nous demander de nous donner votre consentement pour le traitement des informations suivantes :',
    privacy_policy_21: 'La base légale pour le traitement des données personnelles est le consentement, que nous allons obtenir de vous en remplissant le formulaire de consentement sur le lien suivant :',
    privacy_policy_22: 'Si vous remplissez le formulaire de consentement, cela signifie que vous comprenez et acceptez toutes les conditions spécifiées dans le présent avis de confidentialité.',
    privacy_policy_23: 'Retrait du consentement',
    privacy_policy_24: "Vous avez le droit de retirer à tout moment le consentement que vous nous avez fourni précédemment. Le retrait du consentement n'affecte pas la licéité du traitement fondé sur le consentement avant son retrait. Vous pouvez retirer votre consentement en nous envoyant la demande appropriée à l'adresse e-mail suivante withdraw@4service-group.com, ci-joint, le formulaire de la demande est disponible au lien suivant :",
    privacy_policy_25: "Formulaire de retrait pour l'utilisateur",
    privacy_policy_26: 'Objectifs du traitement',
    privacy_policy_27: 'Nous traitons vos données personnelles aux fins suivantes :',
    privacy_policy_28: "– améliorer les services aux clients (Il permet une réponse plus efficace aux demandes des clients) ; – personnalisation de l'expérience des Utilisateurs. Cela permet de déterminer qui est le plus intéressé par les services) ; – améliorer le Site. (Il permet d'améliorer la qualité des produits et services, la commodité de leur utilisation, de développer de nouveaux services, d'améliorer nos produits et services) ; – communiquer avec l'utilisateur avec des newsletters, du matériel marketing ou promotionnel et d'autres informations qui incluent nos actualités, mises à jour, informations sur les services avec la remarque sur l'instruction comment refuser de recevoir des e-mails de suivi. – effectuer des recherches et des analyses statistiques et autres sur la base de données anonymisées. – fournir des services personnalisés à l'Utilisateur et exécuter des accords et des contrats ; – participer de l'Utilisateur à divers projets mis en œuvre par nous via le Site, en répondant aux requêtes adressées par l'Utilisateur via le Site, la recherche, la tenue des comptes et des enregistrements et la promotion des services.",
    privacy_policy_29: 'Sujets auxquels les données personnelles peuvent être transférées',
    privacy_policy_30: 'Lors du traitement de vos données personnelles, nous transférons vos données personnelles aux entités qui agissent en tant que sous-traitants de la Société. Les processeurs de la Société agissent exclusivement sur la base des instructions de la Société. Les détails des actions menées par les sous-traitants de la Société et la liste de ces sous-traitants peuvent être trouvés dans la Politique de confidentialité et de protection des données au lien suivant : Politique de confidentialité et de protection des données',
    privacy_policy_31: 'Les pays vers lesquels les données personnelles peuvent être transférées',
    privacy_policy_32: "La Société transfère vos données personnelles sur la base d'une décision d'adéquation telle que stipulée par le GDPR et la Commission européenne. Des informations supplémentaires concernant le transfert de données personnelles aux États-Unis peuvent être trouvées dans la politique de confidentialité et de protection des données au lien suivant : Politique de confidentialité et de protection des données.",
    privacy_policy_33: 'La période de stockage',
    privacy_policy_34: 'Compte tenu des finalités du traitement, la durée de conservation des données personnelles des Utilisateurs (durée de conservation) est de 24 mois à compter de la date à laquelle le consentement au traitement des données est dûment obtenu de la part de Vous.',
    privacy_policy_35: "Droit d'accès",
    privacy_policy_36: "Vous avez le droit de savoir si des données personnelles vous concernant sont traitées et 2) si tel est le cas, d'accéder à ces données avec de nombreuses stipulations supplémentaires énoncées dans l'article 15 du GDPR .",
    privacy_policy_37: 'Droit de rectification',
    privacy_policy_38: 'Lorsque les données personnelles que Vous nous fournissez ne sont pas inexactes alors, Vous êtes en droit de nous demander de les corriger effectivement (GDPR Article 16).',
    privacy_policy_39: "Droit à l'effacement (droit à l'oubli)",
    privacy_policy_40: "Vous avez le droit d'obtenir de notre part l'effacement de vos données personnelles sans retard injustifié et nous aurons l'obligation d'effacer vos données personnelles sans retard injustifié lorsque les motifs énoncés dans l'article 17 du GDPR s'appliquent.",
    privacy_policy_41: 'Droit de restriction du traitement',
    privacy_policy_42: "Vous avez le droit de limiter le traitement de vos données personnelles avec plusieurs exceptions dans le cadre du RGPD, en particulier indiqué dans l'article 18 du RGPD.",
    privacy_policy_43: 'Nous sommes tenus de vous informer des données collectées, de la manière dont elles sont utilisées, de la durée de leur conservation et si elles seront partagées avec des tiers. Ces informations doivent être communiquées de manière concise et dans un langage simple.',
    privacy_policy_44: 'Droit à la portabilité des données',
    privacy_policy_45: 'Vous êtes autorisé à obtenir et à réutiliser vos données personnelles à vos propres fins dans différents services.',
    privacy_policy_46: "Droit d'opposition",
    privacy_policy_47: "Vous avez le droit de vous opposer au traitement des données personnelles qui sont traitées par la Société. Nous devons cesser de traiter les données personnelles à moins que nous ne démontrions des motifs légitimes impérieux pour le traitement qui prévalent sur les intérêts, les droits et les libertés de l'individu ou si le traitement est destiné à l'établissement ou à l'exercice de la défense de réclamations légales.",
    privacy_policy_48: "Droit de ne pas faire l'objet d'une décision fondée uniquement sur un traitement automatisé",
    privacy_policy_49: "Vous avez le droit de vous opposer à tout profilage automatisé qui se produit sans consentement. Par la présente, le Vous avez le droit que vos données personnelles soient traitées avec l'implication humaine.",
    privacy_policy_50: 'Réclamations',
    privacy_policy_51: "Dans le cas où vous souhaitez déposer une réclamation concernant la manière dont vos données personnelles sont traitées par la Société (ou par les sous-traitants, décrits ci-dessus), ou la manière dont votre réclamation a été traitée, vous avez le droit de déposer une réclamation directement auprès du l'autorité et la Société.",
    privacy_policy_52: 'Les détails de chacun de ces contacts sont :',
    privacy_policy_53: 'Autorité de surveillance:',
    privacy_policy_54: "L'autorité Autrichienne de protection des données",
    privacy_policy_55: 'Österreichische Datenschutzbehörde',
    privacy_policy_56: 'Wickenburggasse 8',
    privacy_policy_57: '1080 Vienne',
    privacy_policy_58: 'Autriche / Europe',
    privacy_policy_59: 'Entreprise:',
    privacy_policy_60: '4Service Holdings GmbH (Société/nous/nous),',
    privacy_policy_61: 'Adresse: Tegetthoffstraße 7, 1010 Vienne, Autriche.',
    privacy_policy_62: 'E-mail:',
    privacy_policy_63: 'Politique de confidentialité et de protection des données',
    privacy_policy_64: 'En savoir plus sur comment et pourquoi nous utilisons vos données ici: Politique de confidentialité et de protection des données',
    privacy_policy_65: 'Approbation',
    privacy_policy_66: "La Société a élaboré tous les documents internes pour définir les rôles du personnel concernant le traitement des données personnelles au sein de la Société, en particulier responsable de l'approbation et de la vérification de la légitimité de ce document est le directeur général.",
    contacts_1: 'Pour les clients',
    contacts_2: "Pour toutes sortes d'entreprises, agences d'études de marché et grandes entreprises, partenaires commerciaux et médias.",
    contacts_3: 'Tegetthoffstrasse 7, Vienne, Autriche',
    contacts_4: 'Pour les acheteurs',
    contacts_5: 'Locaux et voyageurs, chauffeurs et étudiants, clients mystères professionnels et casaniers.',
    contacts_6: 'Contactez-nous depuis votre pays :',
    contacts_7: 'Italie',
    contacts_8: 'Etats-Unis',
    contacts_9: 'ROYAUME-UNI',
    contacts_10: 'Suisse',
    contacts_11: 'Pays-Bas',
    contacts_12: 'Slovaquie',
    contacts_13: 'Slovénie',
    contacts_14: 'Roumanie',
    contacts_15: 'AZ',
    contacts_16: 'Ukraine',
    contacts_17: 'Kazakhstan',
    contacts_18: 'Reste du monde',
    menu_1: 'CJM',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'BHT',
    menu_5: 'Tests UX',
    menu_6: 'Recherche RH',
    menu_7: 'Recherche qualitative',
    menu_8: 'Recherche quantitative',
    menu_9: 'Achat mystère',
    menu_10: 'Voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Suivi des tarifs',
    menu_14: 'À propos de nous',
    menu_15: 'Contacts',
    menu_16: 'Blog',
    menu_17: 'Méthodes',
    menu_18: 'Des produits',
    menu_19: 'Shoppers \n platform',
    menu_20: 'CSR',
    nps_passives: 'Passifs',
    nps_detractors: 'Détracteurs',
    nps_promoters: 'Promoteurs',
    nps_a_score: 'une note',
    area: 'Zone',
    products_tabs_1: "Afin d'atteindre une efficacité maximale, nous réalisons également dans des complexes:",
    products_tabs_2: 'Des produits',
    products_tabs_3: 'La recherche qualitative consiste à collecter et à analyser des données non numériques pour comprendre des concepts, des opinions ou des expériences',
    products_tabs_4: "Les méthodes de recherche quantitative sont une mesure objective et une analyse numérique des données recueillies par le biais de sondages, de questionnaires et d'enquêtes",
    our_clients_1: 'Nos clients',
    preview_cases_section_1: 'Cas',
    preview_cases_section_2: 'Accédez aux cas pour lire des articles plus intéressants',
    blog_1: 'Blog de contenu passionnant',
    blog_2: 'Aucun article disponible pour votre demande.',
    about: 'À propos',
    why_us: 'Pourquoi nous',
    about_us: 'À propos de nous',
    key_features: 'Principales caractéristiques',
    solutions: 'Solutions',
    monitoring: 'Surveillance',
    how: 'Comment',
    how_it_works: 'Comment ça fonctionne',
    when_to_use: 'Quand utiliser',
    mystery_shopping: 'Achat mystère',
    voicer: 'Voicer',
    wantent: 'Vouloir',
    play4sales: 'Play4Sales',
    price_monitoring: 'Suivi des tarifs',
    service_design: 'Conception de services',
    qualitative_research: 'Recherche qualitative',
    quantitative_research: 'Recherche quantitative',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Automobile',
    hashtag_retayl: '#Vente au détail',
    hashtag_all_posts: '#Tous_les messages',
    hashtag_feedback: '#Avis',
    hashtag_4Service_csr: '#4Service_CSR',
    hashtag_cases: '#Cas',
    hashtag_market_research: '#étude de marché',
    more: 'Plus',
    speak_to_an_expert: 'Parlez à un expert',
    book_consultancy: 'Commander une consultation',
    read_all: 'Lis tout',
    find_out_more: 'En savoir plus',
    address: 'Adresse',
    mon_fri: 'Lun-ven',
    phone_number: 'Numéro de téléphone',
    flag: 'drapeau',
    scroll_to_left: 'défiler vers la gauche',
    online: 'en ligne',
    tel: 'Tél',
    email: 'E-mail',
    light_it_up: 'Allumez-le',
    about_us_60: 'Alina Andreieva',
    about_us_61: 'Directeur du développement commercial',
    about_us_62: 'Julia Kravchenko',
    about_us_63: 'Chef de projet senior',
    about_us_64: 'Olga Aksonova',
    about_us_65: 'Responsable du développement commercial',
    about_us_66: 'Zhelevskiy Dmitriy',
    about_us_67: "Directeur d'exploitation",
    about_us_roles_1: 'Le management',
    about_us_roles_2: 'Équipe mondiale',
    about_us_roles_3: 'Propriétaires de produits',
    ad_testing_1: 'Tests publicitaires',
    ad_testing_2: 'Une campagne publicitaire est un énorme investissement. Assurez-vous que vos annonces sont puissantes et offrent un retour sur investissement maximal',
    ad_testing_3: 'verrouiller',
    ad_testing_4: 'Industries que nous servons',
    ad_testing_5: "4Service est un holding mondial de recherche sur l'expérience client",
    ad_testing_6: "4Service est une recherche marketing mondiale et une holding CX. Nous offrons des services experts de collecte de données à une grande variété d'entreprises",
    ad_testing_7: 'Cash & Carry',
    ad_testing_8: 'Détail',
    ad_testing_9: 'Électronique',
    ad_testing_10: 'Luxe/Premium',
    ad_testing_11: 'Restauration & Hospitalité',
    ad_testing_12: 'Automobile',
    ad_testing_13: 'Voyages & Loisirs',
    ad_testing_14: 'Mode',
    ad_testing_15: 'Banques',
    ad_testing_16: 'Montres &  Bijoux',
    ad_testing_17: 'Ménage & Appareil',
    ad_testing_18: 'Membre Esomar',
    ad_testing_19: 'Nous avons mené des recherches de tests publicitaires sur la publicité pour diverses entreprises dans différents domaines',
    ad_testing_20: "Vous avez besoin de tests publicitaires si vous l'êtes",
    ad_testing_21: 'Promouvoir un nouveau produit ou service',
    ad_testing_22: 'Abordant un sujet sensible',
    ad_testing_23: "Essayer d'atteindre un nouveau groupe démographique ou marché cible",
    ad_testing_24: 'Discuter de plusieurs options de conception différentes',
    ad_testing_25: "Besoin d'une preuve de concept pour les équipes de direction ou les investisseurs",
    ad_testing_26: 'Vous obtiendrez des réponses aux questions',
    ad_testing_27: 'Quels canaux publicitaires sont les plus adaptés à ces publicités?',
    ad_testing_28: 'Utilisons-nous les bons visuels pour faire passer le message?',
    ad_testing_29: 'Vos textes et messages publicitaires résonnent-ils?',
    ad_testing_30: 'Quels canaux publicitaires sont les plus adaptés à ces publicités?',
    ad_testing_31: "Qu'est-ce qui attire leur attention en premier lieu?",
    ad_testing_32: "L'emplacement et la mise en page de l'annonce sont-ils faciles à comprendre?",
    ad_testing_33: 'Nous évaluons',
    ad_testing_34: "L'image de marque",
    ad_testing_35: 'comment votre marque est liée à la publicité et dans quelle mesure elle restera dans les mémoires',
    ad_testing_36: 'Indicateurs clés',
    ad_testing_37: "L'intégration",
    ad_testing_38: 'Reconnaissance',
    ad_testing_39: 'Adapter',
    ad_testing_40: 'Créatif',
    ad_testing_41: "Comment votre publicité se démarque et attire l'attention des gens",
    ad_testing_42: 'Indicateurs clés',
    ad_testing_43: 'RECONNAISSANCE',
    ad_testing_44: 'AIMER',
    ad_testing_45: 'MTO',
    ad_testing_46: 'MOTIVATION',
    ad_testing_47: 'Expérience client, engagement émotionnel',
    ad_testing_48: "Grâce à l'utilisation de la technologie de l'intelligence artificielle et d'analystes experts, nous analysons les émotions réelles des gens et obtenons des informations sur leurs perceptions de la publicité",
    ad_testing_49: 'Évaluation',
    ad_testing_50: 'Mâle',
    ad_testing_51: 'Femelle',
    ad_testing_52: 'Engagement élevé',
    ad_testing_53: 'Attention',
    ad_testing_54: "Qu'est-ce que le public a aimé et rappelé?",
    ad_testing_55: 'Actrice',
    ad_testing_56: 'Égalité',
    ad_testing_57: 'Arrière-plan musical',
    ad_testing_58: 'Émotions positives',
    ad_testing_59: "Des gens de races et d'apparences différentes",
    ad_testing_60: 'Prises de vue en extérieur',
    ad_testing_61: "Qu'est-ce que le public n'a PAS aimé et rappelé?",
    ad_testing_62: "La voix de l'intervenant ne correspondait pas à ce qui se passait à l'écran",
    ad_testing_63: 'Faible attention au produit',
    ad_testing_64: 'Trop dynamique',
    ad_testing_65: 'Difficile à comprendre',
    ad_testing_66: 'TRP',
    ad_testing_67: 'impressions',
    ad_testing_68: 'Spectacles',
    ad_testing_69: 'Conversion',
    ad_testing_70: 'Médias',
    ad_testing_71: "Nous mesurons l'efficacité d'une campagne publicitaire en conjonction avec l'activité médiatique, en calculant l'impact des investissements médiatiques dans différents canaux sur les résultats surveillés.",
    ad_testing_72: "Nous effectuons des recherches publicitaires dans n'importe quel format, plate-forme ou canal",
    ad_testing_73: 'contenu vidéo',
    ad_testing_74: 'Extérieur',
    ad_testing_75: 'Publicités télévisées',
    ad_testing_76: 'impression',
    ad_testing_77: 'numérique',
    ad_testing_78: 'campagnes publicitaires',
    ad_testing_79: 'Affichage et publicité POS',
    ad_testing_80: 'animatique',
    ad_testing_81: 'Avec notre aide, vous pouvez tester votre publicité à tout moment',
    ad_testing_82: 'Concept',
    ad_testing_83: 'quelles idées ont une capacité plus forte?',
    ad_testing_84: 'Production à un stade précoce',
    ad_testing_85: 'quelles animatiques entreront en production?',
    ad_testing_86: 'Production',
    ad_testing_87: "comment éditer une vidéo pour qu'elle puisse retenir l'attention?",
    ad_testing_88: 'Pré-média',
    ad_testing_89: "quels canaux de communication fonctionneront et à quel résultat peut-on s'attendre?",
    ad_testing_90: 'Post-test des annonces',
    ad_testing_91: 'Production',
    ad_testing_92: "ma publicité est-elle captivante ? L'efficacité de la créativité diminue-t-elle avec le temps?",
    ad_testing_93: 'Pré-média',
    ad_testing_94: "Quelle est l'efficacité de ma publicité en chiffres ? comment se compare-t-elle à la concurrence?",
    ad_testing_95: 'Pré-test des annonces',
    ad_testing_96: 'Pour vous fournir des données de haute qualité, nous utilisons',
    ad_testing_97: 'Méthodologie de recherche quantitative',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'Méthodologie de recherche qualitative',
    ad_testing_100: 'Groupes de discussion',
    ad_testing_101: 'IA',
    ad_testing_102: 'Wantent',
    ad_testing_103: 'Les rapports analytiques que vous recevez',
    ad_testing_104: "Notre équipe d'analystes, d'experts en médias et de spécialistes du marketing préparera et vous présentera un rapport sur mesure avec des recommandations détaillées",
    ad_testing_age: 'âge',
    ad_testing_ad: 'Publicité',
    automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
    automotive_industry_price_analysis_2: 'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
    automotive_industry_price_analysis_3: 'Request a quote',
    automotive_industry_price_analysis_4: 'Our methodology',
    automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
    automotive_industry_price_analysis_6: 'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
    automotive_industry_price_analysis_7: 'We offer precise figures and dependable multilateral data',
    automotive_industry_price_analysis_8: 'We have global coverage spanning every country in the world',
    automotive_industry_price_analysis_9: 'Our in-house resources include mystery shoppers and auditors',
    automotive_industry_price_analysis_10: 'We validate the quality of our data',
    automotive_industry_price_analysis_11: 'Our advantages:',
    automotive_industry_price_analysis_12: 'Price monitoring for specific competitive models (B2B and B2C)',
    automotive_industry_price_analysis_13: 'Monitoring the level of discount',
    automotive_industry_price_analysis_14: 'Monitoring retailer stock for your brand and competitors',
    automotive_industry_price_analysis_15: 'Monitoring e-commerce automotive prices',
    automotive_industry_price_analysis_16: 'Price communication analysis',
    automotive_industry_price_analysis_17: 'Keeping track of retailer shipping charges',
    automotive_industry_price_analysis_18: 'Monitoring promotions and sales at retailers',
    automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
    automotive_industry_price_analysis_20: 'Calculating lease payments',
    automotive_industry_price_analysis_21: 'Analyzing specific pricing considerations for electric vehicles (EVs)',
    automotive_industry_price_analysis_22: 'Conducting direct-to-consumer versus retailer price comparisons',
    automotive_industry_price_analysis_23: 'Developing subscription pricing models',
    automotive_industry_price_analysis_24: '4Service is a global customer experience (CX) holding company with over 20 years of experience',
    automotive_industry_price_analysis_25: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_26: "4Service's analysis of prices in the automotive industry includes:",
    automotive_industry_price_analysis_27: "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
    automotive_industry_price_analysis_28: 'of customers understand that prices are increasing across all brands',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'of dealers mentioned the possibility of price increases during the communication/selling process',
    automotive_industry_price_analysis_35: '47.6',
    automotive_industry_price_analysis_36: 'Key figures',
    automotive_industry_price_analysis_37: "You'll figure out how to price your product relative to other vehicles in the lineup.",
    automotive_industry_price_analysis_38: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_39: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
    automotive_industry_price_analysis_40: "You'll be able to determine the appropriate monthly payment and leasing rates.",
    automotive_industry_price_analysis_41: 'What benefits for your brand or dealer will you gain:',
    automotive_industry_price_analysis_42: 'You submit an application',
    automotive_industry_price_analysis_43: 'Our manager contacts you to determine the main objectives',
    automotive_industry_price_analysis_44: 'We conduct a marketing research',
    automotive_industry_price_analysis_45: 'We regularly provide you with reports and recommendations in an online format',
    automotive_industry_price_analysis_46: 'Car with sale sticker',
    automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
    automotive_industry_price_analysis_48: 'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
    retail_audit_1: 'AUDIT DE MARKETING DE TERRAIN/audit de vente au détail',
    retail_audit_2: 'Collectez des données détaillées sur votre magasin ou votre marque, analysez les obstacles à la croissance et créez des stratégies efficaces pour les surmonter',
    retail_audit_3: 'Demander un devis',
    retail_audit_4: "4Service réalise des audits de magasins sur place au nom des marques pour évaluer l'efficacité de leurs stratégies promotionnelles, de leurs équipes d'exécution sur le terrain et de leurs tactiques marketing dans l'atteinte du marché cible prévu",
    retail_audit_5: "Nous observons méticuleusement l'exécution des campagnes promotionnelles et veillons au respect des directives promotionnelles.",
    retail_audit_6: 'Top 10',
    retail_audit_7: 'Agences CX en Europe',
    retail_audit_8: "années d'expérience",
    retail_audit_9: 'couverture dans les pays',
    retail_audit_10: '1,6 million',
    retail_audit_11: 'évaluations par an',
    retail_audit_12: 'Que suivons-nous?',
    retail_audit_13: 'Présence de matériel point de vente (POS)',
    retail_audit_14: 'Présence et activation des ambassadeurs de marque, en veillant au respect du calendrier prévu',
    retail_audit_15: "Évaluation de l'implication des ambassadeurs de marque dans les campagnes de promotion",
    retail_audit_16: "Évaluation de l'interaction avec les consommateurs, en veillant au respect des directives promotionnelles et des messages de marque",
    retail_audit_17: 'Mesures de prévention de la fraude',
    retail_audit_18: "Votre entreprise peut utiliser les résultats d'un audit de marketing sur le terrain pour :",
    retail_audit_19: 'Évaluer la conformité au budget et aux processus',
    retail_audit_20: 'Comparer les résultats réels aux objectifs prévus',
    retail_audit_21: 'Améliorer la performance des campagnes marketing ',
    retail_audit_22: "Planifier stratégiquement pour l'aveni",
    retail_audit_23: 'Identifier les opportunités de réduction des coûts',
    retail_audit_24: 'Améliorer les ventes et les taux de conversion',
    retail_audit_25: 'Optimiser le retour sur investissement (ROI)',
    retail_audit_26: 'ILS ONT CHOISI 4SERVICE COMME PARTENAIRE DE LEUR INVESTISSEMENT COMMERCIAL',
    retail_audit_27: "Un fabricant international de produits du tabac de premier plan s'est associé à 4Service pour améliorer ses marques et améliorer ses performances en magasin. Nos équipes ont fourni une évaluation du marketing sur le terrain et un reporting en direct de l'expérience client.",
    retail_audit_28: "augmentation de la précision de la planification de l'agence de marketing sur le terrain",
    retail_audit_29: 'augmentation de la diffusion du message de la marque',
    retail_audit_30: "augmentation de l'engagement des ambassadeurs de marque (activation des consommateurs)",
    retail_audit_31: 'augmentation de la précision de la collecte de données et de la conformité au RGPD',
    retail_audit_32: 'Quelles informations précieuses avons-nous tendance à trouver :',
    retail_audit_33: "Un manque de cohérence ou même un conflit entre les termes de l'ALD entraîne des frais non approuvés importants pour le client",
    retail_audit_34: 'Facturation incorrecte des frais en raison de la confusion entre les types de services fournis',
    retail_audit_35: "Non-exécution du service sur le terrain, y compris l'affectation incorrecte du personnel à l'emplacement prévu | PDV",
    retail_audit_36: "Facturation sur la base d'une estimation plutôt que de données réelles et non rapprochées",
    retail_audit_37: 'Application incorrecte des directives promotionnelles fournissant des données frauduleuses',
    retail_audit_38: 'Comment travaillons-nous ?',
    retail_audit_39: "Notre auditeur visite les points de vente désignés en fonction du plan d'activation de l'agence.",
    retail_audit_40: "Ils évaluent les indicateurs de performance clés (IPC) tels que définis dans le brief, tels que la présence du point de vente, l'engagement des ambassadeurs de marque, le respect des directives promotionnelles et les mesures de prévention de la fraude.",
    retail_audit_41: 'Validation:',
    retail_audit_42: 'Nous recueillons et consolidons les données obtenues.',
    retail_audit_43: 'Un rapport détaillé est rédigé.',
    retail_audit_44: "Notre équipe de validation examine minutieusement toutes les enquêtes pour en vérifier l'exactitude, y compris la conformité avec le cahier des charges du projet et tout commentaire supplémentaire.",
    retail_audit_45: 'Système de reporting en ligne :',
    retail_audit_46: 'Les questionnaires complétés et validés sont téléchargés sur notre portail en ligne dans un délai de 36 heures.',
    retail_audit_47: "Les clients ont accès à un portail de compte personnel, ce qui leur permet de suivre l'avancement à tout moment.",
    retail_audit_48: 'Un rapport final contenant les résultats et les recommandations est fourni.',
    retail_audit_49: 'Parlez à un expert',
    retail_audit_50: 'Nos points forts :',
  },
  uk: {
    form_title: 'Ми зконтактуємося з Вами найближчим часом',
    form_required: "Поля, зазначені *, є обов'язковими для заповнення",
    form_name_placeholder: "Ім'я",
    form_email_placeholder: 'Електронна пошта',
    form_phone_placeholder: 'Номер телефону',
    form_company_placeholder: 'Компанія',
    form_submit: 'Подати на розгляд',
    form_1: 'Я згоден на обробку персональних даних відповідно до',
    form_2: 'політики конфіденційності',
    validation_privacy_policy: 'Будь ласка, прийміть Політику конфіденційності',
    validation_phone: 'Номер телефону недійсний',
    validation_email: 'Неправильний формат електронної пошти',
    validation_required: "Не заповнені обов'язкові поля",
    submit_error: 'Виникла помилка при збереженні форми',
    footer_1: 'Наші партнери:',
    footer_2: 'Методи',
    footer_3: 'Продукти',
    footer_4: 'Контакти:',
    footer_5: 'Вихідні дані',
    footer_6: 'Політика конфіденційності',
    about_us_1: 'Корпоративна соціальна відповідальність 4Service',
    about_us_2: 'Від серця та душі кожного члена нашої команди. Ми намагаємося зберегти іскру доброти в кожному нашому співробітникові і зробити світ навколо нас трохи кращим',
    about_us_3: 'Корпоративна соціальна відповідальність 4Service',
    about_us_4: 'Від серця та душі кожного члена нашої команди. Ми намагаємося зберегти іскру доброти в кожному нашому співробітникові і зробити світ навколо нас трохи кращим',
    about_us_5: 'Бізнес-гра «СервісМанія»',
    about_us_6: 'Наші просвітницькі ініціативи для дітей та студентів',
    about_us_7: 'Благодійність / Волонтерство',
    about_us_8: 'Дитячі будинки, будинки для людей похилого віку та волонтерство в українській армії. Найзворушливіша частина нашої діяльності',
    about_us_9: 'Турбота про тварин',
    about_us_10: 'Будь-хто може прийти до нас в офіс зі своїм вихованцем. Ми допомагаємо притулкам, збираємо кошти на їжу, а також шукаємо притулок для бездомних тварин.',
    about_us_11: 'Компанія 4Сервіс',
    about_us_12: 'Міжнародна дослідницька компанія, що керує клієнтським досвідом.',
    about_us_13: 'Ми реалізуємо комплексні проекти для збільшення продажів та розвитку бізнесу клієнтоорієнтованих компаній.',
    about_us_14: 'Є міжнародним дослідним холдингом зі штаб-квартирою у Відні, Австрія. 2001 рік став початком діяльності компанії, метою якої стало покращення якості життя своїх клієнтів та людей, які взаємодіють з бізнесом, набувають його послуг та споживають його продукти. Адже не випадково назва «Форсервіс» була обрана для компанії, що втілює обслуговування клієнтів та гарну якість.',
    about_us_15: 'Історія 4Service розпочалася у м. Київ (Україна) як провідного постачальника послуг таємних покупок. Компанія продовжує успішно працювати в якості дослідницької організації в 52 країнах, відкриваючи нові можливості комплексного підходу до аналізу та покращення всього ланцюжка обслуговування клієнтів.',
    about_us_16: "народжений в ім'я сервісу",
    about_us_17: 'перший офіс за межами України',
    about_us_18: 'перенесення штаб-квартири до Відня',
    about_us_19: 'елітний член MSPA, у Топ-10 агентств Європи',
    about_us_20: 'відкриття офісів у 9 країнах',
    about_us_21: 'перші цифрові проекти, Voicer',
    about_us_22: 'партнери Академії DesignThinkers',
    about_us_23: 'перші глобальні проекти Клієнтського Досвіду',
    about_us_24: 'Місія',
    about_us_25: 'Наша місія – покращувати обслуговування клієнтів по всьому світу! Нас надихають якісні зміни, які ми спостерігаємо у людях та компаніях, з якими працюємо.',
    about_us_26: '20+ років Клієнтського Досвіду',
    about_us_27: '95 країн',
    about_us_28: 'Ми збираємо та ділимося найкращим галузевим досвідом з усього світу',
    about_us_29: 'Головний офіс у Відні',
    about_us_30: 'країни. Ми збираємо та ділимося найкращим галузевим досвідом з усього світу',
    about_us_31: 'рейтинги якості обслуговування',
    about_us_32: 'ми входимо до Топ-10 європейських агентств з Клієнтського Досвіду',
    about_us_33: 'представництва / штаб-квартира в Австрії',
    about_us_34: 'активні проекти',
    about_us_35: 'Ваше майбутнє робоче місце',
    about_us_36: 'Наші офіси',
    about_us_37: '4Service™ докладає зусиль для створення комфортного робочого простору своїм співробітникам. Можливість вибору працювати на місцях, дистанційно чи суміщено забезпечує максимальну зручність та продуктивність кожного співробітника. Кімнати для відпочинку, безкоштовна психологічна підтримка та доброзичлива обстановка. Ми дружні до людей та домашніх улюбленців.',
    about_us_38: 'Команда, яка сяє та запалює',
    about_us_39: 'Наша багатонаціональна команда прагне прислухатися, спираючись на відгуки наших клієнтів та взаємне зростання. Карта подорожі наших клієнтів з нами також важлива для нашого бізнесу, як запуск нових продуктів або досягнення наших фінансових цілей. Наша діяльність спрямована на те, щоб надавати позитивний вплив на навколишні спільноти та пропонувати інноваційні та орієнтовані на людей рішення для наших клієнтів.',
    about_us_40: 'Усе',
    about_us_41: 'Управління',
    about_us_42: 'Команда Глобал',
    about_us_43: 'Власники продукту',
    about_us_44: 'Наші партнери',
    about_us_45: 'Реалізуємо комплексні проекти для збільшення продажів та розвитку бізнесу клієнтоорієнтованих компаній',
    about_us_46: 'є одним із провідних Інститутів Професійного Навчання, орієнтованих на дизайн, представленого в 25 країнах, де навчаються, розвиваються та підтримуються творчі мультидисциплінарні команди та спільноти для досягнення позитивних змін. Дизайн-мислення дозволяє розробляти та пропонувати інноваційні ідеї, зміни та вирішення складних проблем.',
    about_us_47: 'Їхня місія полягає в тому, щоб надати простір і час командам та окремим особам, щоб вони могли надихатися та кидати собі виклик, навчатися на практиці та зміцнювати свої здібності до творчого вирішення проблем, а також допомагати організаціям створювати необхідне середовище та мислення для запуску сміливих інновацій.',
    about_us_48: "є представником Торгової асоціації компаній, що займаються індустрією Mystery Shopping. Асоціація працює на регіональній основі по всьому світу, її відділення розташовані в Америці, Європі, Африці та Азії, Тихоокеанському регіоні. Компанії-члени об'єднуються в єдине ціле з метою зміцнення промисловості Mystery Shopping за рахунок спільних зусиль і дій.",
    about_us_49: 'є некомерційною організацією з урахуванням членства. Учасники утворюють співтовариство, переконане в потенціалі аналізу даних, досліджень та ідей для покращення суспільства, організацій та життя кожного.',
    about_us_50: 'Штаб-квартира ESOMAR знаходиться в Амстердамі (Нідерланди). З 1947 року ESOMAR представлена у більш ніж 130 країнах завдяки компаніям-учасникам.',
    about_us_51: "UCCA — професійна спільнота приватних фахівців та компаній, об'єднаних метою розвитку та взаємодії у сфері контакт-центру та обслуговування клієнтів. Філософія UCCA – підвищення культури індустрії контакт-центрів, ґрунтуючись на сучасних технологіях та високих стандартах.",
    about_us_52: 'Українська асоціація контакт-центрів була заснована у 2010 році.',
    about_us_53: 'вивчає найкращий світовий досвід у сфері освіти, щоб пристосувати та використати його на українському ринку. Саме тому Академія ДПЕК співпрацює з провідними міжнародними бізнес-школами: INSEAD, IE Business School та Stanford University.',
    about_us_54: 'Академія ДПЕК орієнтована на розвиток клієнтоорієнтованої та інноваційної культури в D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, цифровій програмі DX School, Effective Leader: Pre-MBA, Change Energy.',
    about_us_55: 'Сергій Пашковський',
    about_us_56: 'Вікторія Скорбота',
    about_us_57: 'Артем Фомін',
    about_us_58: 'Макс Папка',
    about_us_59: 'Менеджер з міжнародних продаж',
    bht_1: "Відстеження Здоров'я",
    bht_2: 'Бренду',
    bht_3: 'Бренди неймовірно сильні',
    bht_4: 'Дізнайтеся, наскільки добре працює ваш бренд, загалом',
    bht_5: 'Про',
    bht_6: "Що таке відстеження здоров'я Бренда?",
    bht_7: 'Це вид дослідження ринку, що проводиться з певною періодичністю (кожні два тижні, кожен місяць і т.д.) з метою отримання інформації про',
    bht_8: "статус бренду на ринку на основі таких показників, як популярність, затребуваність, імідж, характеристики його споживачів, здоров'я бренду та інше.",
    bht_9: 'Бренд, що споживається найчастіше',
    bht_10: 'Лояльний',
    bht_11: 'Придбання бренду на 3 місяці',
    bht_12: 'Репертуар / постійні клієнти',
    bht_13: 'Придбання бренду на 6 місяців',
    bht_14: 'Клієнти у короткостроковій перспективі',
    bht_15: 'Придбання бренду на рік',
    bht_16: 'Клієнти у довгостроковій перспективі',
    bht_17: 'Знайомий із брендом? але жодного разу не користувалися/не набували',
    bht_18: 'Ознайомлені, але не є клієнтами',
    bht_19: 'Не ознайомлені: ніколи не чули про бренд',
    bht_20: 'Не ознайомлені',
    bht_21: 'Профіль покупця бренду',
    bht_22: 'Оцінка послідовності зусиль, доданих у довгостроковій перспективі для створення бренду',
    bht_23: 'Вимірювання рівня знань клієнтів про бренд',
    bht_24: 'Демонстрація ефективності рекламно-інформаційних кампаній',
    bht_25: 'Визначення впливу періоду активної реклами на рівень продажів/маркетингову діяльність конкурентів.',
    bht_26: 'Забезпечення власника бізнесу постійним контролем у періоди безперервних ринкових змін',
    bht_27: 'Які завдання може вирішити Відстеження Бренду (ВЗБ):',
    bht_28: 'Запит на дослідження',
    bht_29: 'Завдання для вирішення',
    bht_30: 'Наприклад,',
    bht_31: 'якщо компанії потрібен лише один сеанс ВЗБ у квартал під час виміру часток ринку, опитування респондентів слід проводити частіше при вимірі впізнаваності бренду з реклами серед клієнтів.',
    bht_32: "CATI (комп'ютерне телефонне інтерв'ю)",
    bht_33: 'Обличчям до обличчя',
    bht_53: "Інтерв'ю",
    bht_34: "СAWI (інтернет-інтерв'ю за допомогою комптютера)",
    bht_35: 'Завдання для вирішення',
    bht_36: 'Збираючи інформацію',
    bht_37: 'за допомогою різних методів, відстеження бренду зазвичай ділиться на три етапи залежно від цільової аудиторії та складності її охоплення:',
    bht_38: 'Ми використовуємо як традиційні, так і сучасні методи збору даних:',
    bht_39: 'Ми працюємо з двома моделями: одна з простим набором показників, а інша націлена на складніші галузі, такі як харчова промисловість та сфера послуг. Більш складна модель передбачає поглиблене дослідження, що включає до 10 розрахункових показників.',
    bht_40: 'Ми пропонуємо',
    bht_41: 'контроль вихідних даних',
    bht_42: 'Ми покриваємо',
    bht_43: 'всю країну',
    bht_44: 'Ми пропонуємо рішення',
    bht_45: 'для нединамічних ринків/ринків з обмеженою кількістю гравців (Brand Point Analyzer)',
    bht_46: 'Чому ми?',
    bht_47: 'Чому варто обрати саме нас?',
    bht_50: 'Слабкий результат',
    bht_51: 'Гарний результат',
    bht_52: 'Середній результат',
    cases_1: 'Як підвищити якість обслуговування клієнтів при покупці електромобілів в автосалонах Hyundai?',
    cases_2: 'Карта Шляху клієнта',
    cases_3: 'Формування ідей',
    cases_4: 'Якісні дослідження',
    cases_5: "Що потрібно змінити в магазинах (як у процесі обслуговування, так і в інтер'єрі, у сприйнятті бренду), щоб викликати у покупців інтерес до бренду Oppo?",
    cases_6: 'Онлайн Карта Шляху Клієнта',
    cases_7: 'Тестування інтерфейсу користувача',
    cases_8: "Інтерв'ю",
    cases_9: 'Що потрібно змінити в магазинах, щоб викликати інтерес покупців до бренду Oppo?',
    cases_10: 'Якісна частина',
    cases_11: 'Формування ідей',
    cases_12: 'Кількісна частина',
    cases_13: 'Карта Шляху Клієнта',
    cases_14: 'Кейси',
    cases_15: 'Усе',
    cases_16: 'Давайте виконаємо Ваш чудовий кейс разом',
    csi_1: 'Клієнт',
    csi_2: 'індекс задоволеності',
    csi_3: "З'ясувати причини споживчої",
    csi_4: 'задоволеності та незадоволеності',
    csi_5: 'Дослідження задоволеності клієнтів',
    csi_6: 'це визначення чинників, причин і мотивів, що впливають на рішення клієнта про купівлю, і навіть на поведінку цільової аудиторії.',
    csi_7: 'Опитування задоволеності клієнтів',
    csi_8: 'показує, наскільки клієнт задоволений чи не задоволений конкретним аспектом послуги чи продуктом загалом',
    csi_9: 'Опитування задоволеності',
    csi_10: 'проводиться серед існуючих клієнтів компанії та користувачів певних продуктів/послуг.',
    csi_11: 'Що таке Індекс Задоволеності Клієнтів (CSI)?',
    csi_12: 'Задоволеність клієнтів вимірює ступінь, у якому очікування клієнтів були задоволені щодо покупки продукту чи послуги, що надаються вашою компанією.',
    csi_13: 'Щаслива людина',
    csi_14: 'Про',
    csi_15: 'Визначити будь-які приховані фактори',
    csi_16: 'які можуть вплинути на задоволеність клієнтів',
    csi_17: 'Визначити причини',
    csi_18: 'будь-якого зростання/ падіння попиту на товари та послуги',
    csi_19: 'Оптимізувати',
    csi_20: 'ділові процеси',
    csi_21: 'Адаптувати їх',
    csi_22: 'під потреби сучасного споживача',
    csi_23: 'Сформувати ефективне рекламне повідомлення',
    csi_24: 'та позиціонувати продукт під запит клієнта',
    csi_25: 'Завдання для вирішення',
    csi_26: 'Які завдання можуть вирішити опитування задоволеності клієнтів',
    csi_27: 'Чоловік з телефоном',
    csi_28: 'Ми будуємо математичну модель',
    csi_29: 'факторів, які можуть вплинути на задоволеність/лояльність клієнтів (регресійний аналіз)',
    csi_30: 'Оптимізувати',
    csi_31: 'бізнес-процеси компанії',
    csi_32: 'Ми будуємо розрахунковий індекс задоволеності клієнтів',
    csi_33: '(CSI) і порівнюємо його з декларативним',
    csi_34: 'Ми проводимо дослідження',
    csi_35: 'для сегментів B2B та B2C.',
    csi_36: 'Наші анкети розроблені командою',
    csi_37: 'аналітиків спеціально під індивідуальні проекти наших клієнтів',
    csi_38: 'Чому ми',
    csi_39: 'Чому варто вибрати саме нас?',
    csi_40: 'Methods of collecting information:',
    main_1: 'покращувати їхні фінансові показники, вивчаючи досвід їхніх клієнтів',
    main_2: 'Консультації та послуги у сфері Клієнтського Досвіду, погляд 360° на ваш бізнес',
    main_3: 'Відкрийте для себе наш спектр рішень та інструментів, які ми використовуємо для реалізації ваших бізнес-завдань',
    main_4: 'Продукти',
    main_5: 'Якісне дослідження - це збір та аналіз нечислових даних для розуміння концепцій, думок або досвіду.',
    main_6: "Кількісні методи дослідження - це об'єктивний вимір та числовий аналіз даних, зібраних за допомогою опитувань, анкет та голосувань.",
    main_7: 'Таємний покупець — це метод дослідження ринку для вимірювання та моніторингу взаємодії між покупцем та компанією чи організацією в рамках наперед визначеного сценарію.',
    main_8: "Система управління зворотним зв'язком. Голос вашого клієнта в одній системі - Системі управління зворотним зв'язком зі споживачем",
    main_9: 'Платформа аналізу контенту на основі штучного інтелекту, що надає інформацію від аудиторії',
    main_10: 'Програмне забезпечення для керування продуктивністю. Додаток у формі гри для збільшення продажів',
    main_11: 'Моніторинг цін - це процес збирання, обробки та аналізу цін конкурентів.',
    main_12: 'Сервіс-дизайн — це те, як проводячи час із людьми, які користуватимуться продуктом чи послугою, зрозуміти їхні потреби та бажання.',
    main_13: 'Наша аналітична команда застосовує найсучасніші та фундаментальні підходи, які довели свою ефективність з багатьма клієнтами.',
    main_14: 'Методології',
    main_15: 'Карта шляху клієнта',
    main_16: 'Карта Шляху Клієнта (CJM) – це визначення цільової аудиторії, розуміння її потреб та середовища, а потім створення плану для залучення споживачів та їхньої успішної взаємодії з вашим брендом.',
    main_17: 'Карта Шляху Клієнта CJM',
    main_18: 'Індекс Споживчої Лояльності (NPS)',
    main_19: 'Net Promoter Score (NPS) — це золотий стандарт якості обслуговування клієнтів, заснований на одному питанні: наскільки ймовірно, що ви порекомендуєте організацію/ продукт/ послугу?',
    main_20: 'Індекс Споживчої Лояльності NPS',
    main_21: "Відстеження Здоров'я Бренду BHT",
    main_22: 'Дослідження Brand Health Tracking (BHT) може виміряти, як ваш бренд працює з погляду впізнаваності та використання, позиціонування бренду та ефективності бренду.',
    main_23: "Відстеження Здоров'я Бренду BHT",
    main_24: 'Дослідження Людських Ресурсів',
    main_25: 'Дослідження людських ресурсів використовується для оцінки напрацювань та продуктивності відділу кадрів та пропонує докладний аналіз поточного розвитку та управління.',
    main_26: 'HR-дослідження',
    main_27: 'Індекс задоволеності клієнтів',
    main_28: 'Дослідження людських ресурсів використовується для оцінки напрацювань та продуктивності HR та пропонує докладний аналіз поточного розвитку та управління.',
    main_29: 'Індекс задоволеності клієнтів CSI',
    main_30: 'UX-тестування',
    main_31: 'Юзабіліті-тестування (UX) – це перевірка того, як реальні люди взаємодіють із веб-сайтом, програмою або іншим продуктом; спостереження за поведінкою користувачів та його реакцією на продукт.',
    main_32: 'Чому ми',
    main_33: 'Наша місія – допомогти підприємствам забезпечити високоефективний клієнтський досвід.',
    main_34: 'Зосередженість на якості',
    main_35: 'Ми контролюємо якість',
    main_36: 'коли справа стосується даних дослідження. Ми робимо всі набори даних доступними для клієнтів',
    main_37: 'Економічна ефективність',
    main_38: 'Наш досвід, ресурси та оптимізовані',
    main_39: 'бізнес-процеси дозволяють нам економити ваші гроші та час',
    main_40: 'Розумні рішення',
    main_41: 'Ми використовуємо різні техніки',
    main_42: 'та набори інструментів для вирішення ваших конкретних проблем',
    main_43: 'Зосередженість на якості',
    main_44: 'Ми контролюємо якість',
    main_45: 'коли справа стосується даних дослідження. Ми робимо всі набори даних доступними для клієнтів',
    main_46: 'Економічна ефективність',
    main_47: 'Наш досвід, ресурси та оптимізовані',
    main_48: 'бізнес-процеси дозволяють нам економити ваші гроші та час',
    main_49: 'Розумні рішення',
    main_50: 'Ми використовуємо різні техніки',
    main_51: 'та набори інструментів для вирішення ваших конкретних проблем',
    main_52: 'Дрібнооптова торгівля «заплати та забирай»',
    main_53: 'Електроніка',
    main_54: 'Люкс/ && Преміум',
    main_55: 'Ресторани/ Гостинність',
    main_56: 'Роздрібна торгівля',
    main_57: 'Автомобільний',
    main_58: 'Мода',
    main_59: 'Банки',
    main_60: 'Подорожі та відпочинок',
    main_61: 'Годинники та прикраси',
    main_62: 'Побутова техніка',
    main_63: 'Блог',
    main_64: 'Перейдіть до блогу, щоб прочитати більше цікавих матеріалів',
    main_65: 'Аудит роздрібної торгівлі',
    main_66: 'Збирайте докладні дані про свій магазин або бренд, аналізуйте перешкоди росту та створюйте ефективні стратегії їх подолання',
    cjm_1: 'Клієнт',
    cjm_2: 'Карта подорожі',
    cjm_3: 'Увійдіть у світ клієнтів та поділіться їх досвідом',
    cjm_4: 'Що таке карта шляху клієнта CJM?',
    cjm_5: 'CJM надає візуалізовану історію взаємодії споживача з продуктом, послугою, компанією або брендом різними каналами та протягом певного періоду часу.',
    cjm_6: "Це дозволяє компаніям об'єктивно аналізувати досвід взаємодії з клієнтами, визначати та усувати бар'єри, що виникають, і пропонувати рекомендації щодо покращення продукту.",
    cjm_7: 'Визначення ЦА, сегментація',
    cjm_8: 'Визначення етапів взаємодії із клієнтом',
    cjm_9: 'Визначення основних каналів взаємодії з клієнтами',
    cjm_10: 'Дослідження клієнтського досвіду',
    cjm_11: "Аналіз бар'єрів та гіпотеза оптимізації",
    cjm_12: 'Виконання та перевірка гіпотез',
    cjm_13: 'Розробка Карти шляху клієнта складається з наступних кроків:',
    cjm_14: 'Карта шляху клієнта продемонструє:',
    cjm_15: 'CJM надає візуалізовану історію взаємодії споживача з продуктом, послугою, компанією або брендом різними каналами та протягом певного періоду часу.',
    cjm_16: 'Ми забезпечуємо командну роботу',
    cjm_17: 'між аналітиками та дизайнерами',
    cjm_18: 'Ми пропонуємо індивідуальний',
    cjm_19: 'підхід до кожного клієнта',
    cjm_20: 'Ми можемо збільшити в цілому',
    cjm_21: 'задоволеність клієнтів послугою/ товаром і, зрештою, підвищення їхньої лояльності до компанії',
    cjm_22: 'Ми гарантуємо',
    cjm_23: 'оптимізацію шляху споживача та облік бізнес-процесів компанії',
    cjm_24: 'Ми пропонуємо нашим клієнтам',
    cjm_25: "можливість використання комбінованих підходів: CATI, CAWI, фокус-групи, глибинні інтерв'ю, експертні інтерв'ю, етнографію та ін.",
    cjm_26: 'Чому варто вибрати саме нас?',
    methods_hr_1: 'Дослідження Управління Персоналом',
    methods_hr_2: "Отримайте об'єктивну інформацію про свою команду та дізнайтесь, як підвищити ефективність роботи відділу кадрів.",
    methods_hr_3: 'Декілька причин, з яких вам знадобиться HR-дослідження',
    methods_hr_4: 'опитаних співробітників',
    methods_hr_5: 'реалізовано HR проектів',
    methods_hr_6: 'проведено навчальних семінарів для персоналу',
    methods_hr_7: 'Провідний європейський холдинг з клієнтського досвіду та досліджень співробітників',
    methods_hr_8: 'HR-дослідження 4Service у цифрах',
    methods_hr_9: "Для виявлення проблем та слабких місць на кожному етапі життя співробітників вашої компанії: пошук/набір/адаптація/кар'єра розвиток/переатестація",
    methods_hr_10: 'Щоб знати, які випадки заслуговують на добавки і бонуси',
    methods_hr_11: 'Вам потрібно прогнозувати чи пропонувати рішення незадоволеності співробітників',
    methods_hr_12: 'Завдяки дослідженням у галузі управління персоналом, можливі нові та поточні програми, практики та методи оцінки політики у галузі управління персоналом.',
    methods_hr_13: "Для покращення психічного здоров'я співробітників компанії",
    methods_hr_14: 'Якщо ви хочете підвищити продуктивність за рахунок цілеспрямованого підходу',
    methods_hr_15: 'Якщо у вас є міжнародна та/або віддалена команда, і вам потрібно нею керувати',
    methods_hr_16: 'Зрозуміти, наскільки привабливим є ваш бренд роботодавця для потенційних співробітників',
    methods_hr_17: 'Задоволеність співробітників',
    methods_hr_18: 'Дослідження HR-брендингу',
    methods_hr_19: 'Культурна різноманітність',
    methods_hr_20: 'Плани винагород та заробітної плати',
    methods_hr_21: 'Залучення та лояльність команди',
    methods_hr_22: 'Співробітники захищеного класу',
    methods_hr_23: 'Області досліджень',
    methods_hr_24: 'Рішення 1',
    methods_hr_25: 'eNPS, Задоволеність співробітників, Лояльність співробітників',
    methods_hr_26: 'Що ми вимірюємо:',
    methods_hr_27: 'Прихильність:',
    methods_hr_28: 'лояльність співробітників, прогнозування майбутньої поведінки, визначення ваги факторів, що впливають на лояльність',
    methods_hr_29: 'Залученість:',
    methods_hr_30: 'залученість співробітників до роботи, зацікавленість у результатах та досягненнях',
    methods_hr_31: 'Взаємодія:',
    methods_hr_32: 'визначення цінностей співробітників та цінностей компанії',
    methods_hr_33: 'Довіра 360:',
    methods_hr_34: 'довіра до керівництва, колег, підлеглих, довіра до стратегій та політики роботодавця',
    methods_hr_35: 'Задоволеність:',
    methods_hr_36: 'визначення унікальних факторів для кожної компанії; оцінка задоволеності по кожному ключовому фактору, що впливає на лояльність.',
    methods_hr_37: 'Рішення 2',
    methods_hr_38: 'Бренд роботодавця',
    methods_hr_39: 'Внутрішній роботодавець',
    methods_hr_40: 'аналіз бренду (опитування співробітників, акціонерів, внутрішніх рекрутерів та HR-фахівців)',
    methods_hr_41: 'Зовнішній аналіз роботодавця',
    methods_hr_42: '(Аналіз рівня можливості отримувати прибуток у конкурентів, опитування зовнішніх рекрутерів, опитування претендентів)',
    methods_hr_43: 'Впізнаваність та репутація бренду',
    methods_hr_44: 'аналіз',
    methods_hr_45: 'Присутність роботодавця',
    methods_hr_47: 'Рекомендації роботодавця',
    methods_hr_48: 'збори',
    methods_hr_49: 'Конкурентний бенчмаркінг',
    methods_hr_50: 'на таких ключових факторах, як',
    methods_hr_51: 'Зарплата,',
    methods_hr_52: 'робоче середовище, атмосфера, характер роботи',
    methods_hr_53: 'Аналіз потреб та очікувань',
    methods_hr_54: 'претендентів',
    methods_hr_55: "глибинні інтерв'ю,",
    methods_hr_56: 'використовуються кабінетні дослідження та кількісні дослідження',
    methods_hr_57: 'Рішення 3',
    methods_hr_58: 'Карта шляху співробітника/ Таємний співробітник',
    methods_hr_59: 'побудова шляху пошуку роботи, визначення',
    methods_hr_60: 'основних етапів пошуку, каналів комунікації, критеріїв відбору та оцінки претендентів',
    methods_hr_61: 'контроль усіх етапів',
    methods_hr_62: "та каналів зв'язку за допомогою таємних кандидатів (просто запис на співбесіду, проходження співбесіди, стажування в компанії)",
    methods_hr_63: 'Визначення',
    methods_hr_64: 'слабких місць у процесі найму та адаптації',
    methods_hr_65: 'Рекомендації щодо покращення,',
    methods_hr_66: 'порівняння з лідерами галузі',
    mystery_shopping_1: 'Таємний',
    mystery_shopping_2: 'Покупець',
    mystery_shopping_3: 'Оцініть ваші послуги, що надаються реальними клієнтами',
    mystery_shopping_4: 'Перевірте та визначте стандарти, як вашому персоналу стати ближче до клієнта',
    mystery_shopping_5: 'Ми є елітним членом професійної провідної асоціації постачальників таємних покупок MSPA Europe.',
    mystery_shopping_6: 'досвіду',
    mystery_shopping_7: 'відвідує щомісяця',
    mystery_shopping_8: 'таємні покупці по всьому світу',
    mystery_shopping_9: 'Активні проекти',
    mystery_shopping_10: 'офіси, штаб-квартира в Австрії',
    mystery_shopping_11: 'Європейські постачальники таємних покупок',
    mystery_shopping_12: 'рейтинги якості обслуговування',
    mystery_shopping_13: 'Анкета',
    mystery_shopping_14: 'Програмне забезпечення',
    mystery_shopping_15: 'Профіль покупця',
    mystery_shopping_16: 'Ствердження та зауваження',
    mystery_shopping_17: 'Аналітика',
    mystery_shopping_18: 'Завдяки цим 5 крокам наша компанія 4Service гарантує якість послуги таємного покупця.',
    mystery_shopping_19: 'Програма',
    mystery_shopping_20: '5 елементів програми «Таємний покупець» від 4Service',
    mystery_shopping_21: 'Анкета:',
    mystery_shopping_22: 'суть вашої програми',
    mystery_shopping_23: 'Ця частина програми доступна для перегляду всіма зацікавленими сторонами',
    mystery_shopping_24: 'Для топ-менеджерів.',
    mystery_shopping_25: 'Показати всі важливі кроки та деталі процесу',
    mystery_shopping_26: 'Для співробітників.',
    mystery_shopping_27: 'Відобразити те, що роботодавці очікують від них',
    mystery_shopping_28: 'Для таємних покупців.',
    mystery_shopping_29: 'Ясність і структуризація, щоб уникнути плутанини та упередженості',
    mystery_shopping_30: 'Програмне забезпечення Shopmetrics:',
    mystery_shopping_31: 'зручне та інноваційне',
    mystery_shopping_32: 'Переваги:',
    mystery_shopping_33: 'Інтегрується',
    mystery_shopping_34: 'з вашими бізнес-процесами та програмним забезпеченням',
    mystery_shopping_35: 'Регламент GDPR',
    mystery_shopping_36: 'Інструментарій керування конфіденційністю',
    mystery_shopping_37: 'Детальний',
    mystery_shopping_38: 'Пакет дотримання правових норм',
    mystery_shopping_39: 'Звіт',
    mystery_shopping_40: 'будівельник',
    mystery_shopping_41: 'Управління',
    mystery_shopping_42: 'рівнів доступу',
    mystery_shopping_43: 'Більшість анкет',
    mystery_shopping_44: 'виконуються протягом години',
    mystery_shopping_45: 'Профіль покупця:',
    mystery_shopping_46: '200 000+ таємних покупців у всьому світі',
    mystery_shopping_47: 'Основні критерії: максимальна близькість до вашої цільової аудиторії, наявність попереднього досвіду роботи з вашою компанією чи конкурентами',
    mystery_shopping_48: 'Навчання покупців:',
    mystery_shopping_49: 'Вступний тренувальний тест',
    mystery_shopping_50: 'Скайп/телефонний дзвінок',
    mystery_shopping_51: 'Оціночний тест (за бажанням)',
    mystery_shopping_52: 'Твердження та заперечення',
    mystery_shopping_53: 'Перевірка',
    mystery_shopping_54: 'Система',
    mystery_shopping_55: '100% звіти',
    mystery_shopping_56: 'перевіряється групою валідаторів',
    mystery_shopping_57: 'Додаткові дзвінки',
    mystery_shopping_58: "з метою роз'яснення",
    mystery_shopping_59: 'Аудіозаписи',
    mystery_shopping_60: 'після візиту відзначити найважливіші моменти',
    mystery_shopping_61: 'Шахрайство співробітників',
    mystery_shopping_62: 'система профілактики',
    mystery_shopping_63: 'Заперечення',
    mystery_shopping_64: 'Система',
    mystery_shopping_65: 'Будь-яка оцінка покупця',
    mystery_shopping_66: 'може бути заперечена співробітником безпосередньо на сайті',
    mystery_shopping_67: 'Кожне заперечення',
    mystery_shopping_68: 'розглядається та на нього відповідає місцева група валідаторів',
    mystery_shopping_69: 'Ви можете переглянути статистику',
    mystery_shopping_70: 'заперечень',
    mystery_shopping_71: 'аналітика.',
    mystery_shopping_72: 'Звіт подається особисто і містить:',
    mystery_shopping_73: 'компанії',
    mystery_shopping_74: 'больові точки та проблеми',
    mystery_shopping_75: 'Динаміка',
    mystery_shopping_76: 'за підрозділами та відділами',
    mystery_shopping_77: 'Кореляція',
    mystery_shopping_78: 'між дотриманням стандартів та задоволеністю клієнтів',
    mystery_shopping_79: 'Бенчмаркінг',
    mystery_shopping_80: 'проти конкурентів',
    mystery_shopping_81: 'Практичні',
    mystery_shopping_82: 'рекомендації щодо покращення сервісу',
    mystery_shopping_83: 'Що нас відрізняє?',
    mystery_shopping_84: 'Проводимо сеанси генерації ідей',
    mystery_shopping_85: 'Ми не лише видаємо рекомендації, що робити, а й разом із вами шукаємо практичні шляхи вирішення сервісних завдань.',
    mystery_shopping_86: 'Сеанси формування ідей це потужний інструмент, який допомагає нам:',
    mystery_shopping_87: 'Розробити напрямки щодо покращення продукту чи послуги',
    mystery_shopping_88: 'Вивчити нові можливості потоку доходів та бізнес-стратегій',
    mystery_shopping_89: 'Для пошуку рішень складних клієнтоорієнтованих завдань',
    mystery_shopping_90: 'Перетворити больові точки клієнтів на втіху',
    mystery_shopping_91: "Проводимо відеоінтерв'ю",
    mystery_shopping_92: 'Ми не просто збираємо анкети – ми також отримуємо живі коментарі від покупців',
    mystery_shopping_93: 'Ми робимо це для того, щоб:',
    mystery_shopping_94: "Наші фасилітатори провели глибинні інтерв'ю та надали ефективний зворотний зв'язок",
    mystery_shopping_95: 'Погляньте на обслуговування з позиції клієнта',
    mystery_shopping_96: 'Поліпшіть свою стратегію контент-маркетингу',
    mystery_shopping_97: 'Прогнозувати поведінку клієнтів',
    mystery_shopping_98: 'Ми розробляємо Карту шляху клієнта',
    mystery_shopping_99: 'Створення Карти шляху клієнта допомагає нам стати на місце споживачів, щоб зрозуміти їхні потреби та проблеми.',
    mystery_shopping_100: 'Створення шляху клієнта - найкращий спосіб, щоб',
    mystery_shopping_101: 'Розглянути досвід із точки зору споживача',
    mystery_shopping_102: 'Поліпшити свою стратегію контент-маркетингу',
    mystery_shopping_103: 'Прогнозувати поведінку клієнтів',
    mystery_shopping_104: 'Виявити прогалини в обслуговуванні чи комунікаціях',
    mystery_shopping_105: 'Ми пропонуємо різні види таємних покупок',
    mystery_shopping_106: 'Типи таємних покупок',
    mystery_shopping_107: 'Таємний телефонуючий',
    mystery_shopping_108: 'Телефонує вашим керуючим та/або в кол-центр для визначення якості послуг, що надаються по телефону',
    mystery_shopping_109: 'Люксовий таємний шопінг',
    mystery_shopping_110: 'Перевірка сервісу у люксових закладах спеціальною категорією таємних покупців',
    mystery_shopping_111: 'Конкурентні візити',
    mystery_shopping_112: 'Найпростіший спосіб зрозуміти, як працює ваш конкурент',
    mystery_shopping_113: 'Таємний співробітник',
    mystery_shopping_114: 'Допоможе вам зрозуміти весь шлях вашого співробітника (найм, адаптація, офісні процеси тощо)',
    mystery_shopping_115: 'Мотиваційні візити',
    mystery_shopping_116: 'Корисний інструмент для стимулювання пропозиції додаткових послуг та/або товарів, продажу конкретних товарів',
    mystery_shopping_117: 'Таємний покупець онлайн',
    mystery_shopping_118: 'Імітує та визначає якість обслуговування клієнтів у вашому інтернет-магазині, на веб-сайті або у додатку.',
    mystery_shopping_119: 'Перевірка продажів та рекламних акцій',
    mystery_shopping_120: 'Допомагає визначити, як рекламні акції відображаються у всіх місцях і наскільки ефективно вони працюють.',
    mystery_shopping_121: 'Покупка та повторні візити',
    mystery_shopping_122: 'Допомагає відобразити процес від покупки до повернення, щоб поліпшити якість обслуговування клієнтів.',
    mystery_shopping_123: '> 20 років',
    nps_1: 'Індекс',
    nps_2: 'Споживчої Лояльності NPS',
    nps_3: 'Вимірює, відстежує та підвищує лояльність клієнтів',
    nps_4: 'Вони відповідають із оцінкою від 0 до 6.',
    nps_5: 'Можливо, у них був поганий досвід, і вони навряд чи будуть купувати у вас знову і можуть навіть відмовити інших від покупки у вас.',
    nps_6: 'Це респонденти, які обрали від 7 до 8 балів.',
    nps_7: 'Вони задоволені вашим сервісом, але не досить щасливі, щоб вважатися промоутерами.',
    nps_8: 'Це респонденти, які оцінюють ваш бізнес на 9 чи 10 балів.',
    nps_9: 'Це означає, що вони порекомендують вас другу чи колезі а, отже, висловлюються на вашу користь.',
    nps_10: 'Що таке NPS?',
    nps_11: 'Він вимірює сприйняття клієнтів на основі одного простого питання:',
    nps_12: 'Наскільки можливо, що ви порекомендуєте [Організацію/ Продукт/Послугу] другу чи колезі?',
    nps_13: 'Чи потрібно це моїй компанії?',
    nps_14: 'NPS — ваше стійке зростання',
    nps_15: 'Зрозуміти динаміку лояльності клієнтів',
    nps_16: 'Мотивувати свій персонал',
    nps_17: 'Дізнатися ставлення до різних продуктів',
    nps_18: 'Порівняти, як споживачі ставляться до вас та ваших конкурентів',
    nps_19: 'Що я можу зробити з NPS?',
    nps_20: 'Для якого бізнесу є актуальним NPS?',
    nps_21: 'Методи збирання інформації:',
    nps_22: 'Наші рекомендації під час роботи з NPS',
    nps_23: 'Це означає, що ми розробляємо рекомендації, які допоможуть перетворити недоброзичливих на промоутерів.',
    nps_24: 'Працюємо за методом «замкнутого циклу».',
    nps_25: 'NPS – це спостережливе дослідження, в якому ми відстежуємо зміни лояльності клієнтів.',
    nps_26: 'Слідкуємо за динамікою',
    nps_27: 'Ми пропонуємо завжди оцінювати конкурентів у вашій ніші для більш глибокого аналізу',
    nps_28: 'Оцінка конкурентів',
    price_monitoring_1: 'Моніторинг цін',
    price_monitoring_2: 'Роздрібний моніторинг',
    price_monitoring_3: 'Збільшує потенціал продажу та оптимізує цінову політику за рахунок моніторингу цін конкурентів',
    price_monitoring_4: '4Service більше 20 років надає послуги з моніторингу цін у всьому світі.',
    price_monitoring_5: 'Наша команда кваліфікованих штатних аудиторів у стислі терміни відстежує ціни, що дозволяє нам швидко реагувати на зміни цін конкурентів.',
    price_monitoring_6: 'Завдання, які наша компанія може допомогти вам вирішити за допомогою моніторингу цін',
    price_monitoring_7: 'Оцінка цін конкурентів',
    price_monitoring_8: 'та продуктової політики',
    price_monitoring_9: 'Моніторинг рекламних акцій',
    price_monitoring_10: 'та спеціальних пропозицій',
    price_monitoring_11: "Моніторинг ринкової кон'юнктури",
    price_monitoring_12: 'та ринкового середовища',
    price_monitoring_13: 'Перевірка та контроль торгових точок',
    price_monitoring_14: 'на наявність спецобладнання та товарів',
    price_monitoring_15: 'Управління продуктовим',
    price_monitoring_16: 'потоком усередині торгівлі',
    price_monitoring_17: 'Умови оптимізації',
    price_monitoring_18: 'для кращої співпраці з постачальниками, дилерами',
    price_monitoring_19: 'Вимірювання простору на полицях,',
    price_monitoring_20: 'оцінка розташування товарів, наявності запасів',
    price_monitoring_21: 'Ключові показники та критерії моніторингу',
    price_monitoring_22: 'Всі дані зібрані в одному інтерфейсі, включаючи:',
    price_monitoring_23: "ім'я",
    price_monitoring_24: 'категорії продукту та бренду',
    price_monitoring_25: 'доступність',
    price_monitoring_26: 'рекламних матеріалів',
    price_monitoring_27: 'ціна',
    price_monitoring_28: 'та наявність будь-яких акційних пропозицій',
    price_monitoring_29: 'полиця',
    price_monitoring_30: 'розмір',
    price_monitoring_31: 'продукт',
    price_monitoring_32: 'вітрина',
    price_monitoring_33: 'Артикул',
    price_monitoring_34: 'лицьовий',
    price_monitoring_35: 'тип',
    price_monitoring_36: 'упаковки',
    price_monitoring_37: 'будь-яка інша',
    price_monitoring_38: 'Загальна інформація',
    price_monitoring_39: 'Передпроектний аналіз:',
    price_monitoring_40: 'Ми вивчаємо',
    price_monitoring_41: 'цінову та продуктову політику вашої компанії, ділові та галузеві методи роботи',
    price_monitoring_42: 'Ми надаємо',
    price_monitoring_43: 'оперативне навчання, тестування та сертифікацію аудиторів',
    price_monitoring_44: 'Ми індивідуально адаптуємо',
    price_monitoring_45: 'та налаштовуємо програмне забезпечення',
    price_monitoring_46: 'Польові роботи:',
    price_monitoring_47: 'Відвідування точок',
    price_monitoring_48: 'роздрібної торгівлі',
    price_monitoring_49: 'Складання звітів:',
    price_monitoring_50: 'Наша аналітична команда',
    price_monitoring_51: 'готує онлайн-звіти в особистих кабінетах компанії',
    price_monitoring_52: 'Ми надаємо',
    price_monitoring_53: 'повний фотозвіт, звіт PPT, звіт Excel із зазначенням типу населеного пункту, назви мережі, адреси торгової точки',
    price_monitoring_54: 'Як це',
    price_monitoring_55: 'працює',
    price_monitoring_56: 'розмір індивідуальних знижок',
    price_monitoring_57: 'розрахунок вартості',
    price_monitoring_58: 'розмір індивідуальних бонусів',
    price_monitoring_59: 'програми лояльності',
    price_monitoring_60: 'і т.д.',
    price_monitoring_61: 'Ми використовуємо спеціалізоване програмне забезпечення:',
    price_monitoring_62: 'PRADATA, Shopmetrics та пропонуємо всебічну технічну підтримку',
    price_monitoring_63: 'Швидке використання',
    price_monitoring_64: 'та масштабування (з першого дня)',
    price_monitoring_65: 'Усі дані підтверджені',
    price_monitoring_66: 'фотографіями та GPS-мітками',
    price_monitoring_67: 'У нас є необхідна кількість',
    price_monitoring_68: 'польових агентів у будь-якій країні',
    price_monitoring_69: 'Ми можемо працювати з великою',
    price_monitoring_70: 'кількістю даних',
    price_monitoring_71: 'Моніторинг індивідуальних цін',
    price_monitoring_72: 'Якщо у вас є сукупність продуктів, ми можемо допомогти вам визначити вашу стратегію ціноутворення',
    price_monitoring_73: 'Ми можемо відстежувати окремі ціни конкурентів, наприклад:',
    price_monitoring_74: 'Низькі',
    price_monitoring_75: 'витрати',
    price_monitoring_76: 'Висока',
    price_monitoring_77: 'ефективність',
    price_monitoring_78: 'Звіт Excel',
    price_monitoring_79: 'РРТ-звіт',
    price_monitoring_80: 'звіт бізнес-аналітики',
    price_monitoring_81: 'Дані ціни конкурентів допомагають приймати оптимальні цінові рішення.',
    price_monitoring_82: 'Надаємо повний фотозвіт, звіт PPT, звіт Excel за типом населеного пункту',
    qualitative_research_1: 'Якісні',
    qualitative_research_2: 'дослідження',
    qualitative_research_3: 'Збір, аналіз та інтерпретація даних, спостерігаючи за діями та словами ваших клієнтів.',
    qualitative_research_4: 'Цінності',
    qualitative_research_5: 'Поведінка',
    qualitative_research_6: 'накопичений досвід',
    qualitative_research_7: 'Очікування',
    qualitative_research_8: 'Довкілля',
    qualitative_research_9: "Страхи/бар'єри",
    qualitative_research_10: 'Якою є мета клієнтського вибору?',
    qualitative_research_11: 'Чоловік з телефоном',
    qualitative_research_12: 'бренд',
    qualitative_research_13: 'категорія',
    qualitative_research_14: 'реклама',
    qualitative_research_15: 'продукт',
    qualitative_research_16: 'Унікальність якісних методів у тому, що вони дозволяють зрозуміти причини та мотивацію споживчої поведінки.',
    qualitative_research_17: 'Аналіз ставлення споживача до того чи іншого явища',
    qualitative_research_18: 'Індивідуальні погляди та досвід',
    qualitative_research_19: 'Теми, які неможливо дослідити за допомогою опитувань',
    qualitative_research_20: 'Делікатні теми',
    qualitative_research_21: 'Важкодоступна аудиторія',
    qualitative_research_22: 'Складна географія',
    qualitative_research_23: "Глибинні інтерв'ю корисні для:",
    qualitative_research_24: "Глибинні інтерв'ю",
    qualitative_research_25: "Глибинне інтерв'ю — це метод якісного дослідження, який включає проведення індивідуальних інтерв'ю з респондентами для вивчення їх поглядів на ту чи іншу ідею, програму чи ситуацію. Глибинне інтерв'ю докладно досліджує індивідуальний досвід/сприйняття/практику.",
    qualitative_research_26: 'Цілі:',
    qualitative_research_27: 'Характеристика соціальних та культурних норм',
    qualitative_research_28: 'Важкодоступна аудиторія',
    qualitative_research_29: 'Обмін та порівняння (Морган), досліджені за допомогою опитувань',
    qualitative_research_30: 'Теми, які не можна досліджувати за допомогою опитувань',
    qualitative_research_31: 'Поглиблені фокус-групи корисні для:',
    qualitative_research_32: 'Фокус групи',
    qualitative_research_33: 'Глибоке вивчення окремих зустрічей, виникаючих дискусій, дискусій між оприлюдненнями під впливом модератора групи.',
    qualitative_research_34: 'Отримання інформації про загальний досвід та соціальні норми за допомогою групового обговорення, оповідальних даних у цілеспрямованому обговоренні.',
    qualitative_research_36: 'Переваги:',
    qualitative_research_37: 'Вивчення способу життя та поведінки споживача у різних реальних умовах (вдома, у торговій точці, на роботі і т.д.)',
    qualitative_research_38: 'Пошук інформації для розробки нових продуктів, упаковки, покращення обслуговування тощо.',
    qualitative_research_39: 'Поглиблене етнографічне дослідження корисне для:',
    qualitative_research_40: 'Етнографічне дослідження',
    qualitative_research_41: 'Етнографія - це тип якісного дослідження, яке включає занурення в життя певної спільноти або організації, щоб поблизу спостерігати за їх поведінкою і взаємодією.',
    qualitative_research_42: "Етнографія - це гнучкий метод дослідження, який дозволяє вам глибше зрозуміти загальну культуру, традиції та соціальну динаміку групи. Однак, він також пов'язаний з деякими практичними та етичними викликами.",
    qualitative_research_43: 'Методи:',
    qualitative_research_44: 'Етнографія - це вивчення споживача в реальних умовах та його взаємодії з товаром / послугою.',
    qualitative_research_45: 'Переваги роботи з нами',
    qualitative_research_46: 'Ми можемо знайти респондентів із будь-якої цільової аудиторії. І контролюємо якість підбору',
    qualitative_research_47: 'Рекрутинг будь-якої складності',
    qualitative_research_48: 'Наші модератори розмовляють кількома мовами і мають навички сприяння та усунення труднощів',
    qualitative_research_49: 'Досвідчені модератори',
    qualitative_research_50: 'Робота онлайн/офлайн',
    qualitative_research_51: 'Надаємо аудіо/відео',
    qualitative_research_52: 'запису',
    qualitative_research_53: 'Ми можемо провести якісне дослідження у будь-якій місцевості',
    quantitative_research_1: 'Дослідження задоволеності клієнтів',
    quantitative_research_2: 'певним продуктом чи послугою (SCI, NPS)',
    quantitative_research_3: 'Проведення сегментації',
    quantitative_research_4: 'огляди різних ринків',
    quantitative_research_5: 'Дослідження ринку',
    quantitative_research_6: 'Управління NPS',
    quantitative_research_7: 'Аналіз конкурентів',
    quantitative_research_8: 'Кількісні',
    quantitative_research_9: 'дослідження',
    quantitative_research_10: "Методологія дослідження використовується для перевірки теорій щодо ставлення та поведінки людей на основі об'єктивних, числових та статистичних даних.",
    quantitative_research_11: 'роки',
    quantitative_research_12: 'досвіду CX по всьому світу',
    quantitative_research_13: 'панель',
    quantitative_research_14: 'респондентів',
    quantitative_research_15: 'країни',
    quantitative_research_16: 'ми працюємо над проектами по всьому світу',
    quantitative_research_17: 'Хто ми',
    quantitative_research_18: '4Service – глобальний холдинг маркетингових досліджень та CX. Ми пропонуємо експертні послуги зі збору даних для різних підприємств',
    quantitative_research_19: '4service у числах',
    quantitative_research_20: "Комп'ютеризоване",
    quantitative_research_21: "Веб-інтерв'ю",
    quantitative_research_22: "Техніка анкетування в Інтернеті. Респондент заповнює електронну анкету без допомоги інтерв'юера. Анкета може бути надіслана в електронному вигляді або розміщена на сайті.",
    quantitative_research_23: "Веб-інтерв'ю з використанням комп'ютера",
    quantitative_research_24: 'В більшості випадків',
    quantitative_research_25: 'цей спосіб є найбільш рентабельним з погляду матеріальних та тимчасових витрат.',
    quantitative_research_26: 'Це опитування дає',
    quantitative_research_27: "можливість взяти інтерв'ю у важкодоступної аудиторії",
    quantitative_research_28: 'Він надає широкі можливості',
    quantitative_research_29: 'для демонстрації відео- та аудіоматеріалів, а також',
    quantitative_research_30: 'Респонденти можуть',
    quantitative_research_31: 'знаходиться в будь-якій точці світу',
    quantitative_research_32: 'Він дозволяє досліджувати',
    quantitative_research_33: 'специфічна поведінка інтернет-аудиторії. Клієнт отримує доступ до основних результатів у реальному часі через веб-інтерфейс.',
    quantitative_research_34: 'Прийнятний для будь-якої конфіденційної,',
    quantitative_research_35: "чутливої особистої теми чи питання, на яке респонденти, можливо, не хотіли відповідати при інтерв'юері",
    quantitative_research_36: '150 000+ панель',
    quantitative_research_37: 'Ми маємо власну панель респондентів по всьому світу.',
    quantitative_research_38: 'Працюємо зі складними запитами',
    quantitative_research_39: 'Використовуючи додаткові інструменти (соціальні мережі, рекламу), ми можемо знайти конкретну аудиторію та специфічний профіль респондента.',
    quantitative_research_40: 'Ми мотивуємо наших респондентів',
    quantitative_research_41: 'Ми розробили систему заохочення до проходження довгих опитувань шляхом надання матеріальних заохочень.',
    quantitative_research_42: "Особисті інтерв'ю",
    quantitative_research_43: 'проводяться за допомогою планшета',
    quantitative_research_44: "Це один з основних методів збору кількісних даних, в ході якого інтерв'юер безпосередньо спілкується з респондентом за структурованою анкетою.",
    quantitative_research_45: "Очні інтерв'ю можуть проводитись за місцем роботи або проживання респондента, на вулиці або у спеціально відведених місцях продажу з використанням планшета.",
    quantitative_research_46: "Очні інтерв'ю з використанням планшета",
    quantitative_research_47: 'Визначення рівня',
    quantitative_research_48: 'популярності та впізнаваності бренду',
    quantitative_research_49: 'Сегментація споживачів',
    quantitative_research_50: 'на основі їх покупцевої поведінки',
    quantitative_research_51: 'Аналіз ефективності',
    quantitative_research_52: 'рекламної кампанії',
    quantitative_research_53: 'Задоволеність клієнтів',
    quantitative_research_54: 'вимір',
    quantitative_research_55: 'Визначення найкращої ціни',
    quantitative_research_56: 'для продукту чи послуги',
    quantitative_research_57: 'Аналіз',
    quantitative_research_58: 'споживчих переваг',
    quantitative_research_59: 'Вивчення',
    quantitative_research_60: 'того, як споживачі купують',
    quantitative_research_61: 'Оцінка',
    quantitative_research_62: 'ємності ринку',
    quantitative_research_63: 'Програмне забезпечення DigSee',
    quantitative_research_64: 'Використовуємо спеціальне ПЗ з аудіозаписом та GPS',
    quantitative_research_65: "Інтерв'юери та супервайзери",
    quantitative_research_66: "ми можемо проводити інтерв'ю будь-якою мовою і перекладати мову, якій віддає перевагу Клієнт",
    quantitative_research_67: 'Вибірка',
    quantitative_research_68: 'Аналітичний відділ розробляє систему підбору респондентів для отримання найкращого результату',
    quantitative_research_69: "Комп'ютеризоване",
    quantitative_research_70: "Телефонне інтерв'ю",
    quantitative_research_71: "Методика збирання кількісної інформації за допомогою телефонних інтерв'ю з використанням чітко структурованої анкети.",
    quantitative_research_72: "Телефонне інтерв'ю з використанням комп'ютера",
    quantitative_research_73: 'У нас є власні кол-центри',
    quantitative_research_74: 'Використовуємо спеціальне ПЗ з аудіозаписом та GPS',
    quantitative_research_75: 'Кваліфіковані оператори',
    quantitative_research_76: 'Наші оператори володіють різними мовами, пройшли навчання, атестацію та постійно вдосконалюють свої навички.',
    quantitative_research_77: 'Бази даних',
    quantitative_research_78: 'Ми працюємо як з власними базами даних, так і з вашими базами клієнтів.',
    quantitative_research_79: 'Угода про рівень послуг SLA',
    quantitative_research_80: 'Ми гарантуємо якість та підписуємо SLA (Service Level Agreement)',
    quantitative_research_81: 'Постановка цілей',
    quantitative_research_82: 'Визначення методології',
    quantitative_research_83: 'Сегментація та вибірка',
    quantitative_research_84: 'Визначення каналів збору даних',
    quantitative_research_85: 'Створення анкети, логічної для респондента',
    quantitative_research_86: 'Процес збору даних',
    quantitative_research_87: 'Забезпечення якості',
    quantitative_research_88: 'Методи аналізу',
    quantitative_research_89: 'Обробка даних',
    quantitative_research_90: 'Розробка опитування',
    quantitative_research_91: "Відстеження місцезнаходження інтерв'юерів за допомогою GPS",
    quantitative_research_92: 'Перевірка даних',
    quantitative_research_93: 'Програмування та тестування анкети',
    quantitative_research_94: 'Валідація даних',
    quantitative_research_95: 'Аудіозаписи з дотриманням вимог GDPR',
    quantitative_research_96: 'Підготовка та компетентність наукового персоналу',
    quantitative_research_97: 'Забезпечення якості',
    quantitative_research_98: 'Факторний аналіз',
    quantitative_research_99: 'Сегментація',
    quantitative_research_100: 'Побудова карти сприйняття',
    quantitative_research_101: 'Кореляція',
    quantitative_research_102: 'Моделювання конверсій',
    quantitative_research_103: 'Коефіцієнт подібності Жаккара',
    quantitative_research_104: 'Кластерний аналіз',
    quantitative_research_105: 'і т.д.',
    quantitative_research_106: 'Аналітичні звіти',
    quantitative_research_107: 'Ми використовуємо SPSS® та інші інструменти для створення аналітичних звітів із застосуванням різних методологій.',
    quantitative_research_108: 'What business challenges сan be solved:',
    quantitative_research_109: 'What business challenges сan be solved:',
    social_responsibility_1: 'Корпоративна соціальна відповідальність 4Service',
    social_responsibility_2: 'Від серця та душі кожного члена нашої команди. Ми намагаємося зберегти іскру доброти в кожному нашому співробітнику і зробити світ навколо нас трохи кращим',
    social_responsibility_3: 'Освітня діяльність / Підтримка молоді',
    social_responsibility_4: 'Наші просвітницькі ініціативи для дітей та студентів',
    social_responsibility_5: 'Бізнес-гра «Сервісоманія»',
    social_responsibility_6: 'Важливою частиною наших навчальних заходів є ділова гра «Сервісоманія».',
    social_responsibility_7: 'Service Mania змушує вас думати стратегічно про те, як впоратися з незвичайними ситуаціями.',
    social_responsibility_8: 'Презентація дослідження/Дійя. Бізнес',
    social_responsibility_9: 'Погляд бізнесу та клієнта на ситуацію з сервісом: презентація результатів нашого дослідження 4Service від Вікторії Скорбота, керівника відділу розвитку 4Service Україна',
    social_responsibility_10: 'Сесії з сервіс-дизайну',
    social_responsibility_11: 'Методологія Service Design є важливою частиною 4Service. Кілька років тому ми стали партнером DesignThinkers Academy і не тільки використовуємо найкращі практики у своїй роботі, але й активно навчаємо найкращі компанії',
    social_responsibility_12: 'Благодійність / Волонтерство',
    social_responsibility_13: 'Дитячі будинки, будинки для людей похилого віку та волонтерство на користь української армії. Найзворушливіша частина нашої діяльності',
    social_responsibility_14: '#HelpEasyWith4ServiceGroup',
    social_responsibility_15: 'Ми співпрацюємо з Благодійним фондом «Благомай» і намагаємося організувати свято та допомогу тим, хто гостро цього потребує',
    social_responsibility_16: 'Збір засобів для дитячих будинків та будинків для людей похилого віку',
    social_responsibility_17: 'У 4Service ми ініціювали кілька зборів коштів для будинків для людей похилого віку та дитячих будинків на купівлю предметів першої необхідності.',
    social_responsibility_18: '#SaveUkraine',
    social_responsibility_19: 'Війна торкнулася багатьох наших співробітників та близьких друзів. Кожен з нас допомагає чим може',
    social_responsibility_20: 'Турбота про тварин',
    social_responsibility_21: 'Будь-хто може прийти до нас в офіс зі своїм улюбленцем. Ми допомагаємо притулкам, збираємо кошти на корм, інколи ж шукаємо будинок для бездомних тварин.',
    social_responsibility_22: 'Підтримка притулків для тварин',
    social_responsibility_23: 'Бездомні тварини потребують допомоги так само, як і решта вразливих членів нашого суспільства.',
    social_responsibility_24: 'Наша компанія взяла під свою опіку притулок для бездомних тварин «Сіріус» (Київська область, Україна).',
    social_responsibility_25: 'Креативні ініціативи на допомогу бездомним тваринам',
    social_responsibility_26: 'Як ми можемо донести актуальність проблеми притулків для тварин до наших клієнтів і партнерів?',
    social_responsibility_27: 'Офіс, дружній до домашніх тварин',
    social_responsibility_28: 'Багато членів нашої команди мають домашніх тварин. Ми створили здорове середовище, в якому кожен може прийти в офіс зі своїм улюбенцем і почуватися комфортно.',
    ux_testing_1: 'UX-тестування',
    ux_testing_2: 'Людський підхід та підхід з використанням штучного інтелекту до тестування та покращення ваших веб-сайтів та додатків',
    ux_testing_3: 'Що таке UX-тестування',
    ux_testing_4: 'UX-тестування допоможе вам зрозуміти, як люди взаємодіють з вашим продуктом, програмою або веб-сайтом.',
    ux_testing_5: 'Навігація не дуже зрозуміла',
    ux_testing_6: 'Я не розумію, як зробити замовлення',
    ux_testing_7: 'Недостатньо фото у каталозі',
    ux_testing_8: 'Ви зможете:',
    ux_testing_9: 'Розкрити шляхи вдосконалення',
    ux_testing_10: 'Виявити проблеми у дизайні та взаємодії з клієнтом',
    ux_testing_11: 'Дізнатися, з якими труднощами стикається користувач при взаємодії із сайтом',
    ux_testing_12: 'Спостерігати за подорожжю клієнта',
    ux_testing_13: 'Дізнатися, коли і чому ваша цільова аудиторія залишає сайт',
    ux_testing_14: 'Визначити, які розділи викликають найменший та найбільший інтерес та емоції',
    ux_testing_15: 'UX-тестування користувача',
    ux_testing_16: '4Service надає реальну людську інформацію, необхідну для створення клієнтоорієнтованих веб-сайтів і додатків з використанням якісних і кількісних методів.',
    ux_testing_17: 'Я не розумію, як користуватись сайтом. Де заховане меню?',
    ux_testing_18: 'Я вважаю, що між буквами надто великий простір, текст не читається',
    ux_testing_19: 'Картинки накладаються одна на одну. Так і має бути?',
    ux_testing_20: 'UX-тестування допоможе вам зрозуміти, як люди взаємодіють з вашим продуктом, програмою або веб-сайтом.',
    ux_testing_21: 'Дизайн дослідження тестування користувача UX:',
    ux_testing_22: 'Розкрити можливості для вдосконалення',
    ux_testing_23: 'Визначення типу та методу тестування (якісне/ кількісне)',
    ux_testing_24: 'Складання гіпотез та сценаріїв користувача',
    ux_testing_25: 'Аналіз та звіт про результати випробувань',
    ux_testing_26: 'Два рішення',
    ux_testing_27: 'Ми пропонуємо два підходи до успішного тестування UX',
    ux_testing_28: 'UX-тестування користувача',
    ux_testing_29: 'UX-тестування людиною та штучним інтелектом',
    ux_testing_30: 'Наші сильні сторони:',
    ux_testing_31: 'Ми можемо аналізувати конкурентне середовище',
    ux_testing_32: "Можемо залучити вузьких спеціалістів для проведення та участі в інтерв'ю",
    ux_testing_33: "Ми можемо проводити інтерв'ю різними мовами та перекладати їх потрібною мовою",
    ux_testing_34: 'з існуючою зручністю використання',
    ux_testing_35: 'показників чи стандартів',
    ux_testing_36: 'ваша діяльність',
    ux_testing_37: 'проти конкурентних',
    ux_testing_38: 'версії',
    ux_testing_39: 'того ж продукту',
    ux_testing_40: 'Рейтинг задоволеності',
    ux_testing_41: 'Індекс Споживчої Лояльності NPS',
    ux_testing_42: 'Показники успіху',
    ux_testing_43: 'Частота помилок',
    ux_testing_44: 'Час виконання завдання',
    ux_testing_45: 'UX-тестування користувача',
    ux_testing_46: 'Кількісний метод',
    ux_testing_47: 'Ми залучуємо не менше 100 осіб для участі в UX-тестуванні на цьому етапі',
    ux_testing_48: 'Актуально, коли вам необхідно порівняти ваш сайт або додаток:',
    ux_testing_49: 'Кількісний метод виміру:',
    ux_testing_50: 'Спостереження',
    ux_testing_51: 'Проведення часу з користувачем або групою користувачів та спостереження за їх взаємодією з продуктом, коли вони використовують його у повсякденному житті.',
    ux_testing_52: "Глибинне інтерв'ю",
    ux_testing_53: "Дозволяє вам вивчити ставлення, переконання, бажання та досвід користувачів, які приходять на ваш сайт. Ми рекомендуємо провести 15-20 інтерв'ю",
    ux_testing_54: 'Етнографічні дослідження',
    ux_testing_55: 'Проводиться серед респондентів, де вони будуть використовувати продукт. Спостерігаючи, ви починаєте добре розуміти психологію користувача, емоційні проблеми, із якими він стикається.',
    ux_testing_56: 'UX-тестування користувача',
    ux_testing_57: 'Якісний метод',
    ux_testing_58: 'Цей метод допомагає зрозуміти мотиви та логіку поведінки користувачів.',
    ux_testing_59: 'Ми використовуємо:',
    ux_testing_60: 'Ми розробляємо карту шляху клієнта, щоб проілюструвати результати нашого дослідження.',
    ux_testing_61: '30 учасників (чоловіки/жінки)',
    ux_testing_62: 'Респонденти проходять попередню перевірку та отримують запрошення взяти участь в опитуванні.',
    ux_testing_63: 'Віддалена онлайн-співбесіда на платформі Wantent',
    ux_testing_64: 'ШІ + людина',
    ux_testing_65: 'AI+UX-тестування з людиною',
    ux_testing_66: 'Поєднання унікальної технології штучного інтелекту Wantent та залучення реальних користувачів',
    ux_testing_67: 'Великий шрифт у заголовку привернув увагу',
    ux_testing_68: 'Користувачі дивляться на «гучномовні» цифри, але не читають інформацію поруч із ними',
    ux_testing_69: 'UX-тестування допоможе вам зрозуміти, як люди взаємодіють з вашим продуктом, програмою або веб-сайтом.',
    ux_testing_70: 'Методологія дослідження - ШІ + тестування людиною',
    ux_testing_71: 'Як це працює',
    ux_testing_72: 'Wantent — це рішення, що ґрунтується на штучному інтелекті, яке відповідає ринку контенту.',
    ux_testing_73: 'Wantent оцінює ефективність веб-сайтів та додатків, застосовуючи технології машинного навчання для аналізу емоційних реакцій та залучення аудиторії.',
    ux_testing_74: 'Щоб інтерпретувати результати, ми використовуємо методи розповідання історій, різноманітність та співчуття, щоб стимулювати продуктивність та зростання.',
    ux_testing_75: 'Увага та емоції користувача при переходах по розділам',
    ux_testing_76: 'Статистика щодо груп учасників (увага та зосередженість протягом усього сеансу)',
    ux_testing_77: 'Аналіз конверсій сторінок',
    ux_testing_78: 'Увага та емоції при виконанні завдань',
    ux_testing_79: 'Висновки щодо сприйняття',
    ux_testing_80: 'сайту різними групами учасників',
    ux_testing_81: 'Аналіз легкості',
    ux_testing_82: 'навігації по сторінках та легкості пошуку важливих елементів',
    ux_testing_83: 'Аналіз сприйняття',
    ux_testing_84: 'інформації, розміщеної на сайті',
    ux_testing_85: 'Визначення фрагментів',
    ux_testing_86: 'для доопрацювання всередині окремих сторінок (навігація, інформаційні блоки тощо)',
    ux_testing_87: 'Ми детально розбираємо',
    ux_testing_88: 'Основні результати дослідження',
    ux_testing_89: 'Рекомендації щодо покращення структури сайту та окремих сторінок',
    ux_testing_90: 'Ми ретельно відбираємо цільову аудиторію для юзабіліті-тестування.',
    ux_testing_91: 'Проводимо дослідження в різних країнах, різними мовами та серед різних груп користувачів',
    ux_testing_92: 'Можемо протестувати у додатках',
    ux_testing_93: 'та на сайтах конкурентів та провести порівняльний аналіз',
    ux_testing_94: 'Ми комбінуємо різноманітні методи дослідження.',
    ux_testing_95: 'Наші кваліфіковані фасилітатори проводять учасника через процес тестування.',
    ux_testing_96: 'Ми проводимо тестування',
    ux_testing_97: 'на різних пристроях та версіях програмного забезпечення',
    ux_testing_98: 'Чому варто вибрати саме нас?',
    wantent_1: '4Сервіс х',
    wantent_2: 'Wantent',
    wantent_3: 'Унікальний партнерський проект від 4Service та Wantent © Поєднання штучного інтелекту та людського підходу до контент-аналізу',
    wantent_4: 'вимір успіху до виходу контенту в ефір',
    wantent_5: "зниження ризиків, пов'язаних із проблемами різноманітності",
    wantent_6: 'глибоке розуміння та рекомендації щодо покращення контенту',
    wantent_7: "об'єктивні рішення щодо контенту та маркетингової стратегії",
    wantent_8: 'Wantent — це рішення, що ґрунтується на штучному інтелекті, яке відповідає ринку контенту.',
    wantent_9: 'Wantent - це технологічне рішення на основі штучного інтелекту для порівняння контенту з ринком. Wantent оцінює ефективність відеоконтенту, застосовуючи технології машинного навчання для аналізу емоційного відгуку та залучення аудиторії.',
    wantent_10: 'Wantent допомагає визначити відповідність контенту ринку та максимізувати ефективність контенту за рахунок',
    wantent_11: 'налаштування сценарію та підбору учасників',
    wantent_12: 'індивідуальної розробки проекту та залучення аудиторії з усього світу',
    wantent_13: 'збору реакцій глядачів',
    wantent_14: "детального та точного зворотного зв'язку від аудиторії",
    wantent_15: 'аналізу сприйняття контенту',
    wantent_16: 'аналізу поведінки та емоційних реакцій за допомогою ШІ',
    wantent_17: 'надання знахідок, отриманих у результаті аналітичної обробки, для фінальних підсумків',
    wantent_18: 'рекомендації щодо покращення',
    wantent_19: 'Wantent максимізує ефективність вашого контенту',
    wantent_20: 'Попереднє тестування концепції реклами',
    wantent_21: 'Тестування креативної концепції',
    wantent_22: 'Перевірка відповідності майбутнього креативу розробленої стратегії та розуміння реакції цільової аудиторії для можливих коригувань креативних концепцій/меседжів',
    wantent_23: 'Тестування видимості елементів бренду (постпродакшн)',
    wantent_24: 'Підтвердження ефективності рекламної/спонсорської інвестиції',
    wantent_25: 'Тестування реклами',
    wantent_26: 'A/B-тестування',
    wantent_27: 'Порівняння ефективності креативів та підвищення залученості та уважності на етапі виробництва',
    wantent_28: 'Запуск тестування реклами',
    wantent_29: 'Визначення ефективності повідомлень та створення рекомендацій щодо покращення звукового супроводу та зображень',
    wantent_30: 'Рішення для ТБ',
    wantent_31: 'Пілотні випуски, промо, нові формати, тестування телеведучих',
    wantent_32: 'Проведення порівняльного аналізу для виявлення найбільш привабливого контенту та його посилення',
    wantent_33: 'Тестування телешоу та художніх фільмів',
    wantent_34: 'Аналіз залучення глядачів, рівня уваги, емоційного відгуку під час перегляду тривалого контенту в природних умовах. Ми автоматично знаходимо піки незацікавленості аудиторії та моменти втрати уваги для оцінки та покращення монтажу відео',
    wantent_35: 'UI/UX-тестування',
    wantent_36: 'Тестування прототипів сайтів та мобільних додатків',
    wantent_37: 'Аналіз реакції та емоцій користувачів за допомогою технології Wantent:',
    wantent_38: 'рівень уваги',
    wantent_39: 'емоційні реакції',
    wantent_40: 'теплові карти напряму погляду учасників',
    wantent_41: 'Аналіз юзабіліті веб-сайту та програми на основі відгуків учасників:',
    wantent_42: 'Шкала зручності використання системи (SUS)',
    wantent_43: 'Оцінка зусиль клієнта (простота взаємодії)',
    wantent_44: 'Чистий показник просування (NPS)',
    wantent_45: 'Відповідність GDPR та конфіденційність користувачів',
    wantent_46: 'Wantent повністю відповідає політикам GDPR та CCPA та відповідає принципам збору, зберігання, обробки та захисту персональних даних учасників.',
    wantent_47: 'Микита Лобинцев',
    wantent_48: 'СDO, Reface (Медіа та Розваги)',
    wantent_49: "Щодня ми експериментуємо з багатьма новими ідеями контенту за допомогою технологій машинного навчання. Wantent допомагає нам зрозуміти та оцінити ризики та переваги, дізнатися відгуки, поведінку та сприйняття користувачів, щоб встановити причинно-наслідковий зв'язок різних аспектів наших рішень.",
    wantent_50: 'Олександр Смирнов',
    wantent_51: 'співвласник TABASCO (Реклама)',
    wantent_52: 'Wantent надає дійсно унікальну послугу, яка допомагає нам дізнатися про нюанси того, що подобається і не подобається споживачам. Немає можливості обдурити або збрехати – Wantent завжди знає правду, і тим самим ми, як маркетологи, отримуємо безцінні (хахаха – ми знаємо точну ціну і вона доступна) рекомендації щодо покращення наших комунікацій.',
    wantent_53: 'Відгуки',
    terms_1: 'Умови',
    terms_2: 'Угода про умови використання',
    terms_3: 'Будь ласка, уважно прочитайте цю Угоду про умови використання («умови використання», «угоду») перед використанням веб-сайту.',
    terms_4: '(«сайт»), яким керує 4Service Holdings GmbH («4Service», «нас», «ми», «наш»).',
    terms_5: 'Умови використання: Використовуючи цей веб-сайт, ви підтверджуєте, що прочитали та ознайомилися з цією Угодою та погоджуєтеся дотримуватися її умов. Якщо ви не бажаєте дотримуватися умов цієї Угоди, вам рекомендується відпрвідно залишити веб-сайт. 4Service дозволяє використання та доступ до цього веб-сайту, його продуктів та послуг лише тим, хто прийняв його умови.',
    terms_6: 'Політика конфіденційності',
    terms_7: 'Перш ніж ви продовжите використовувати наш веб-сайт, ми рекомендуємо ознайомитися з нашою Політикою конфіденційності щодо збору даних про користувачів. Це допоможе вам краще зрозуміти наші практики.',
    terms_8: 'Вікове обмеження',
    terms_9: "Вам має бути не менше 16 (шістнадцяти) років, перш ніж ви зможете використати цей веб-сайт. Використовуючи цей веб-сайт, ви гарантуєте, що Вам виповнилося 16 (шістнадцять) років, і ви можете юридично дотримуватися цієї Угоди. 4Service не несе відповідальності за зобов'язання, пов'язані зі спотворенням інформації про вік.",
    terms_10: 'Інтелектуальна власність',
    terms_11: 'Ви погоджуєтесь з тим, що всі матеріали, продукти та послуги, представлені на цьому веб-сайті, є власністю 4Service, її афілійованих осіб, директорів, посадових осіб, співробітників, агентів, постачальників або ліцензіарів, включаючи всі авторські права, комерційні таємниці, товарні знаки, патенти та іншу інтелектуальну власність. Ви також погоджуєтесь з тим, що ви не відтворюватимете або поширюватимете інтелектуальну власність 4Service, включаючи електронну, цифрову реєстрацію або реєстрацію нових товарних знаків.',
    terms_12: 'Доступ до нашого веб-сайту',
    terms_13: "Доступ до нашого сайту дозволено на тимчасовій основі, і ми залишаємо за собою право відкликати або змінити послуги, які ми надаємо на нашому сайті, без попередження. Ми не несемо відповідальності, якщо з будь-якої причини наш сайт недоступний у будь-який час або протягом будь-якого періоду. Час від часу ми можемо обмежувати доступ до деяких частин нашого сайту або до всього нашого сайту користувачам, які зареєструвалися у нас. Ви несете відповідальність за вжиття всіх заходів, необхідних для доступу до нашого сайту, включаючи використання обладнання, сумісного з нашим сайтом. Ви також несете відповідальність за те, щоб усі особи, які отримують доступ до нашого сайту через ваше інтернет-з'єднання, знали про ці умови та дотримувалися їх.",
    terms_14: 'Застосоване право',
    terms_15: 'Відвідуючи цей веб-сайт, ви погоджуєтесь з тим, що закони Австрійської Республіки, без урахування принципів колізійного права, регулюватимуть ці умови та будь-які суперечки будь-якого роду, які можуть виникнути між 4Service та вами.',
    terms_16: 'Суперечки',
    terms_17: "Будь-яка суперечка, пов'язана з відвідуванням вами даного веб-сайту, підлягає розгляду в арбітражі Комерційним судом Відня.",
    terms_18: 'Компенсація',
    terms_19: 'Ви погоджуєтесь відшкодувати збитки 4Service та його афілійованим особам та захистити 4Service від юридичних претензій та вимог, які можуть виникнути внаслідок вашого використання або неправильного використання нашого веб-сайту. Ми залишаємо за собою право обирати нашого власного юрисконсульта.',
    terms_20: 'Терміни зберігання',
    terms_21: 'З огляду на цілі обробки термін зберігання персональних даних Користувачів (термін зберігання) становить 24 місяці з дати отримання від Вас в установленому порядку згоди на обробку даних.',
    terms_22: 'Обмеження відповідальності',
    terms_23: '4Service не несе відповідальності за будь-які збитки, які можуть бути завдані вам внаслідок неправильного використання вами нашого веб-сайту. 4Service залишає за собою право без попереднього повідомлення редагувати, модифікувати та змінювати цю Угоду в будь-який час шляхом оновлення цієї публікації. Ваше подальше використання веб-сайту зараз або після публікації будь-яких змін або модифікацій означатиме прийняття вами таких змін або модифікацій. Якщо якусь частину цієї угоди буде оголошено незаконною, недійсною або такою, що не має законної сили, ця частина буде вважатися відокремленою і не вплине на дійсність і застосовність будь-яких інших положень. Можливі докази використання сайту з незаконною метою будуть передані до правоохоронних органів. Ця Угода є домовленістю між 4Service та користувачем.',
    terms_24: "Будь ласка, надсилайте всі питання та проблеми, пов'язані з конфіденційністю/використанням, за наступною адресою:",
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Тегетхофштрассе 7',
    terms_27: '1010 Відень',
    terms_28: 'Австрія',
    terms_29: 'Огляд',
    terms_30: 'Якщо персональні дані, які ви надаєте нам, є неточними, ви маєте право попросити нас негайно виправити їх (стаття 16 GDPR).',
    thank_you_1: 'Дякуємо Вам!',
    thank_you_2: "Ми зв'яжемося з вами протягом 24 годин",
    thank_you_3: 'P.S. Наші менеджери вже розглядають ваш запит, щоб скласти найкращу пропозицію',
    thank_you_4: 'Меню',
    thank_you_5: 'Жінка друкує',
    privacy_policy_1: "Форма сповіщення про конфіденційність для суб'єктів даних (користувачів веб-сайту)",
    privacy_policy_2: "Ця Форма сповіщення про конфіденційність призначена для фізичних осіб (суб'єктів даних), чиї персональні дані збираються 4Service Holdings GmbH відповідно до вимог GDPR у рамках відвідування веб-сайту.",
    privacy_policy_3: '(тут після Веб-сайт та Користувачі Веб-сайту/Користувачі відповідно).',
    privacy_policy_4: "Форма сповіщення про конфіденційність для суб'єктів даних (користувачів веб-сайту) 4Service Holdings GmbH застосовується у всіх компаніях групи 4Service, які на законних підставах знаходяться під наглядом або контролем 4Service Holdings GmbH.",
    privacy_policy_5: 'Контакти',
    privacy_policy_6: '4Service Holdings GmbH',
    privacy_policy_7: '(Компанія/ми/нас),',
    privacy_policy_8: 'Адреса:',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Відень, Австрія.',
    privacy_policy_10: 'Електронна пошта:',
    privacy_policy_12: 'Ким ми є?',
    privacy_policy_13: 'Фізична особа (Ви), яка відвідує веб-сайт з будь-якою метою. Для цілей цього Документу Ви повинні також бути вказані як Користувач.',
    privacy_policy_14: 'Персональні дані, які ми отримуємо від вас',
    privacy_policy_15: 'Коли Ви заходите на наш сайт, ми надсилаємо Вам повідомлення про конфіденційність, в якому пропонуємо Вам надати згоду на обробку наступної інформації:',
    privacy_policy_16: "IP-адреса, ім'я користувача, прізвище, адреса, номер телефону (стаціонарна або мобільна), адреса електронної пошти, адреса, назва компанії, країна, адреса електронної пошти, ім'я, прізвище, номер телефону, провінція, штат і поштовий індекс , дані про використання, дані про взаємодію із зовнішніми соціальними мережами або платформами, інформація про реєстрацію та авторизацію на сайті",
    privacy_policy_17: 'географічне положення.',
    privacy_policy_18: 'Якщо ви висловлюєте свою волю і даєте нам таку згоду, ми починаємо обробляти таку інформацію від вас.',
    privacy_policy_19: 'Законність (згода)',
    privacy_policy_20: 'Коли Ви заходите на наш сайт, ми надсилаємо Вам повідомлення про конфіденційність, в якому пропонуємо Вам надати згоду на обробку наступної інформації:',
    privacy_policy_21: 'Правовим підґрунтям для обробки персональних даних є згода, яку ми отримаємо від Вас, заповнивши форму згоди за наступним посиланням:',
    privacy_policy_22: 'Якщо ви заповнюєте форму згоди, це означає, що ви розумієте та приймаєте всі умови, зазначені в цьому повідомленні про конфіденційність.',
    privacy_policy_23: 'Відгук згоди',
    privacy_policy_24: 'Ви маєте право відкликати згоду, яку Ви надали раніше, у будь-який час. Відкликання згоди не впливає на законність обробки, що ґрунтується на згоді до її відкликання. Ви можете відкликати свою згоду, надіславши нам відповідний запит на наступну електронну адресу remove@4service-group.com, при цьому форма запиту доступна за наступним посиланням:',
    privacy_policy_25: 'Форма виведення коштів для користувача',
    privacy_policy_26: 'Цілі обробки',
    privacy_policy_27: 'Ми обробляємо ваші персональні дані для наступних цілей:',
    privacy_policy_28: "– покращити обслуговування клієнтів (дозволяє ефективніше реагувати на запити клієнтів); - Персоналізація досвіду Користувачів. Дозволяє визначити, які послуги цікавіші); – покращити Сайт. (Дозволяє підвищувати якість продуктів та послуг, зручність їх використання, розробляти нові Сервіси, покращувати наші продукти та послуги); - зв'язуватися з Користувачем за допомогою інформаційних бюлетенів, маркетингових або рекламних матеріалів та іншої інформації, яка включає наші новини, оновлення, інформацію про послуги з позначкою про інструкцію, як відмовитися від отримання наступних електронних листів. – проводити статистичні та інші види досліджень та аналізів на основі знеособлених даних. — надавати персоналізовані послуги Користувачеві та виконувати угоди та договори; – участь Користувача у різних проектах, що реалізуються нами через Сайт, відповіді на запити, адресовані Користувачем через Сайт, дослідження, ведення облікових записів та просування послуг.",
    privacy_policy_29: "Суб'єкти, яким можуть бути передані персональні дані",
    privacy_policy_30: "При обробці ваших персональних даних ми передаємо ваші персональні дані суб'єктам, які діють як обробники даних Компанії. Обробники компанії діють виключно на підставі інструкцій компанії. З особливостями дій, які здійснюють обробники Компанії, та зі списком таких обробників можна ознайомитись у Політиці конфіденційності та захисту даних за наступним посиланням: Політика конфіденційності та захисту даних",
    privacy_policy_31: 'Країни, до яких можуть бути передані персональні дані',
    privacy_policy_32: 'Компанія передає ваші персональні дані на підставі рішення про адекватність, як це передбачено GDPR та Комісією ЄС. Додаткову інформацію про передачу персональних даних у США можна знайти в Політиці конфіденційності та захисту даних за наступним посиланням: Політика конфіденційності та захисту даних.',
    privacy_policy_33: 'Термін зберігання',
    privacy_policy_34: 'З огляду на цілі обробки термін зберігання персональних даних Користувачів (термін зберігання) становить 24 місяці з дати отримання від Вас в установленому порядку згоди на обробку даних.',
    privacy_policy_35: 'Право доступу',
    privacy_policy_36: 'Ви маєте право знати, чи обробляються персональні дані стосовно вас, і 2) якщо так, отримати доступ до таких даних з безліччю додаткових умов, зазначених у статті 15 GDPR.',
    privacy_policy_37: 'Право на виправлення',
    privacy_policy_38: 'Якщо персональні дані, які ви надаєте нам, є неточними, ви маєте право попросити нас виправити їх (стаття 16 GDPR).',
    privacy_policy_39: 'Право на стирання (право на забуття)',
    privacy_policy_40: "Ви маєте право отримати від нас видалення ваших особистих даних без невиправданої затримки, і ми будемо зобов'язані видалити ваші особисті дані без невиправданої затримки, якщо застосовні підстави, зазначені у статті 17 GDPR.",
    privacy_policy_41: 'Право на обмеження обробки',
    privacy_policy_42: 'Ви маєте право обмежити обробку ваших персональних даних за кількома винятками в рамках GDPR, зокрема, зазначеними у статті 18 GDPR.',
    privacy_policy_43: "Ми зобов'язані повідомити Вам, які дані збираються, як вони використовуються, як довго вони зберігатимуться і чи будуть передані третім особам. Ця інформація повинна повідомлятись коротко і в доступному формулюванні.",
    privacy_policy_44: 'Право на переносимість даних',
    privacy_policy_45: 'Вам дозволено отримувати та повторно використовувати ваші особисті дані у своїх цілях у різних службах.',
    privacy_policy_46: 'Право на заперечення',
    privacy_policy_47: 'Ви маєте право заперечувати обробку персональних даних, які обробляються Компанією. Ми повинні припинити обробку персональних даних, якщо ми не продемонструємо вагомих законних підстав для обробки, які переважають інтереси, права і свободи людини, або якщо обробка призначена для встановлення або захисту юридичних вимог.',
    privacy_policy_48: 'Право не піддаватися рішенню, заснованому виключно на автоматизованій обробці',
    privacy_policy_49: 'Ви маєте право заперечувати будь-яке автоматичне профілювання, яке відбувається без вашої згоди. При цьому Ви маєте право обробляти Ваші особисті дані за участю людини.',
    privacy_policy_50: 'Скарги',
    privacy_policy_51: 'Якщо ви хочете подати скаргу на те, як ваші персональні дані обробляються Компанією (або обробниками, описаними вище), або на те, як було опрацьовано вашу скаргу, ви маєте право подати скаргу безпосередньо до наглядового органу авторизованої Компанії.',
    privacy_policy_52: 'Деталі для кожного з цих контактів:',
    privacy_policy_53: 'Орган нагляду:',
    privacy_policy_54: 'Австрійський орган захисту даних',
    privacy_policy_55: 'Österreichische Datenschutzbehörde',
    privacy_policy_56: 'Вікенбурггасе 8',
    privacy_policy_57: '1080 Відень',
    privacy_policy_58: 'Австрія / Європа',
    privacy_policy_59: 'Компанія:',
    privacy_policy_60: '4Service Holdings GmbH (Компанія/ми/нас),',
    privacy_policy_61: 'Адреса: Tegetthoffstraße 7, 1010 Відень, Австрія.',
    privacy_policy_62: 'Електронна пошта:',
    privacy_policy_63: 'Політика конфіденційності та захисту даних',
    privacy_policy_64: 'Докладніше про те, як і чому ми використовуємо ваші дані, читайте тут: Політика конфіденційності та захисту даних',
    privacy_policy_65: 'Схвалення',
    privacy_policy_66: 'Компанія розробила всі внутрішні документи для визначення ролей серед персоналу щодо обробки персональних даних у Компанії, зокрема відповідальність за затвердження та перевірку законності цього документа несе Керуючий директор.',
    contacts_1: 'Для клієнтів',
    contacts_2: 'Для всіх видів бізнесу, агентств з дослідження ринку та великих компаній, ділових партнерів та ЗМІ.',
    contacts_3: 'Tegetthoffstrasse 7, Відень, Австрія',
    contacts_4: 'Для покупців',
    contacts_5: 'Місцеві жителі та мандрівники, водії та студенти, професійні таємні покупці та домосіди.',
    contacts_6: "Зв'яжіться з нами з вашої країни:",
    contacts_7: 'Італія',
    contacts_8: 'США',
    contacts_9: 'Великобританія',
    contacts_10: 'Швейцарія',
    contacts_11: 'Нідерланди',
    contacts_12: 'Словаччина',
    contacts_13: 'Словенія',
    contacts_14: 'Румунія',
    contacts_15: 'Арізона',
    contacts_16: 'Україна',
    contacts_17: 'Казахстан',
    contacts_18: 'Інші країни',
    menu_1: 'CJM',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'BHT',
    menu_5: 'UX-тестування',
    menu_6: 'HR-дослідження',
    menu_7: 'Якісні дослідження',
    menu_8: 'Кількісне дослідження',
    menu_9: 'Таємний покупець',
    menu_10: 'Voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Моніторинг цін',
    menu_14: 'Про нас',
    menu_15: 'Контакти',
    menu_16: 'Блог',
    menu_17: 'Методи',
    menu_18: 'Продукти',
    menu_19: 'Shoppers \n platform',
    menu_20: 'CSR',
    nps_passives: 'Пасивні',
    nps_detractors: 'Недоброзичливці',
    nps_promoters: 'Промоутери',
    nps_a_score: 'оцінка',
    area: 'Область',
    products_tabs_1: 'Для досягнення максимальної ефективності ми також проводимо у комплексі:',
    products_tabs_2: 'Продукти',
    products_tabs_3: 'Якісне дослідження - це збір та аналіз нечислових даних для розуміння концепцій, думок або досвіду.',
    products_tabs_4: "Кількісні методи дослідження - це об'єктивний вимір та числовий аналіз даних, зібраних за допомогою інтерв'ю, анкет та опитувань.",
    our_clients_1: 'Наші клієнти',
    preview_cases_section_1: 'Випадки',
    preview_cases_section_2: 'Переходьте до кейсів, щоб читати більше цікавих постів',
    blog_1: 'Блог із цікавим контентом',
    blog_2: 'Немає постів, що відповідають вашому запиту.',
    about: 'Про',
    why_us: 'Чому ми',
    about_us: 'Про нас',
    key_features: 'Ключова особливість',
    solutions: 'Рішення',
    monitoring: 'Моніторинг',
    how: 'Як',
    how_it_works: 'Як це працює',
    when_to_use: 'Коли використовувати',
    mystery_shopping: 'Таємний покупець',
    voicer: 'Voicer',
    wantent: 'Wantent',
    play4sales: 'Play4Sales',
    price_monitoring: 'Моніторинг цін',
    service_design: 'Сервіс-дизайн',
    qualitative_research: 'Якісні дослідження',
    quantitative_research: 'Кількісні дослідження',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Автомобілебудування',
    hashtag_retayl: '#Роздрібна торгівля',
    hashtag_all_posts: '#Всі_пости',
    hashtag_feedback: "#Зворотній зв'язок",
    hashtag_4Service_csr: '#4Service_CSR',
    hashtag_cases: '#Кейси',
    hashtag_market_research: '#дослідження ринку',
    more: 'Більше',
    speak_to_an_expert: 'Поговоріть із експертом',
    book_consultancy: 'Замовити консультацію',
    read_all: 'Читати все',
    find_out_more: 'Дізнатися більше',
    address: 'Адреса',
    mon_fri: 'пн-пт',
    phone_number: 'Номер телефону',
    flag: 'прапор',
    scroll_to_left: 'прокрутити вліво',
    online: 'В мережі',
    tel: 'Тел.',
    email: 'Електронна пошта',
    light_it_up: 'Підсвіти це',
    about_us_60: 'Аліна Андрєєва',
    about_us_61: 'Директор з розвитку бізнесу',
    about_us_62: 'Юлія Кравченко',
    about_us_63: 'Старший менеджер проекту',
    about_us_64: 'Ольга Аксонова',
    about_us_65: 'Менеджер з розвитку бізнесу',
    about_us_66: 'Желєвський Дмитро',
    about_us_67: 'Операційний директор',
    about_us_roles_1: 'Управління',
    about_us_roles_2: 'Глобальна команда',
    about_us_roles_3: 'Власники продукту',
    ad_testing_1: 'Тестування реклами',
    ad_testing_2: 'Рекламна кампанія – це величезні інвестиції. Переконайтеся що ваші оголошення ефективні та забезпечують максимальну рентабельність інвестицій',
    ad_testing_3: 'замок',
    ad_testing_4: 'Галузі які ми обслуговуємо',
    ad_testing_5: '4Service - глобальний холдинг з дослідження клієнтського досвіду',
    ad_testing_6: '4Service – глобальний холдинг маркетингових досліджень та CX. Ми пропонуємо експертні послуги зі збору даних для різних підприємств',
    ad_testing_7: 'Магазин самообслуговування',
    ad_testing_8: 'Роздрібна торгівля',
    ad_testing_9: 'Електроніка',
    ad_testing_10: 'Люкс/Преміум',
    ad_testing_11: 'Ресторани та  Гостинність',
    ad_testing_12: 'Автомобільний',
    ad_testing_13: 'Подорожі та відпочинок',
    ad_testing_14: 'Мода',
    ad_testing_15: 'Банки',
    ad_testing_16: 'Годинники та  Ювелірні вироби',
    ad_testing_17: 'Побутовий прилад',
    ad_testing_18: 'Член Езомар',
    ad_testing_19: 'Ми провели дослідження Ad Testing з реклами для різних підприємств у різних галузях',
    ad_testing_20: 'Вам потрібно тестування реклами якщо ви',
    ad_testing_21: 'Просуваєте новий товар чи послугу',
    ad_testing_22: 'Торкаєтесь делікатної теми',
    ad_testing_23: 'Намагаєтесь охопити нову цільову аудиторію чи ринок',
    ad_testing_24: 'Обговорюєте кілька різних варіантів дизайну',
    ad_testing_25: 'Потребуєте перевірки концепції для керуючих команд або інвесторів',
    ad_testing_26: 'Ви отримаєте відповіді на запитання',
    ad_testing_27: 'Які рекламні канали найкраще підходять для цих оголошень?',
    ad_testing_28: 'Ви використовуєте правильні візуальні ефекти щоб донести повідомлення?',
    ad_testing_29: 'Чи резонують Ваші рекламні тексти та повідомлення?',
    ad_testing_30: 'Які рекламні канали найкраще підходять для цих оголошень?',
    ad_testing_31: 'Що привертає їхню увагу в першу чергу?',
    ad_testing_32: 'Чи легко зрозуміти розміщення та макет оголошення?',
    ad_testing_33: 'Ми оцінюємо',
    ad_testing_34: 'Брендінг',
    ad_testing_35: "як ваш бренд пов'язаний з рекламою і наскільки він запам'ятається",
    ad_testing_36: 'Ключові метрики',
    ad_testing_37: 'Інтеграція',
    ad_testing_38: 'Визнання',
    ad_testing_39: 'Відповідність',
    ad_testing_40: 'Творчість',
    ad_testing_41: 'Як ваша реклама виділяється та привертає увагу людей',
    ad_testing_42: 'Ключові метрики',
    ad_testing_43: 'ВИЗНАННЯ',
    ad_testing_44: 'ПОДОБАТИСЯ',
    ad_testing_45: 'МТО',
    ad_testing_46: 'МОТИВАЦІЯ',
    ad_testing_47: 'Клієнтський досвід, емоційна залученість',
    ad_testing_48: 'За допомогою технологій штучного інтелекту та експертів-аналітиків ми аналізуємо реальні емоції людей та отримуємо уявлення про їх сприйняття реклами',
    ad_testing_49: 'Рейтинг',
    ad_testing_50: 'Чоловічий',
    ad_testing_51: 'Жіночий',
    ad_testing_52: 'Високий рівень залучення',
    ad_testing_53: 'Увага',
    ad_testing_54: "Що сподобалося і запам'яталося публіці?",
    ad_testing_55: 'Актриса',
    ad_testing_56: 'Рівність',
    ad_testing_57: 'Музичний фон',
    ad_testing_58: 'Позитивні емоції',
    ad_testing_59: 'Люди різних рас та зовнішності',
    ad_testing_60: 'зйомки на відкритій місцевості',
    ad_testing_61: "Що глядачам не сподобалося і не запам'яталося?",
    ad_testing_62: 'Голос диктора не відповідав тому що відбувалося на екрані',
    ad_testing_63: 'Низький рівень уваги до продукту',
    ad_testing_64: 'Занадто динамічно',
    ad_testing_65: 'Важко зрозуміти',
    ad_testing_66: 'TRP',
    ad_testing_67: 'Враження',
    ad_testing_68: 'Шоу',
    ad_testing_69: 'Перетворення',
    ad_testing_70: 'ЗМІ',
    ad_testing_71: "Ми вимірюємо ефективність рекламної кампанії у взаємозв'язку з активністю ЗМІ, розраховуючи вплив інвестицій у ЗМІ в різних каналах на результати, що відслідковуються.",
    ad_testing_72: 'Ми проводимо рекламні дослідження в будь-якому форматі, на будь-якій платформі чи каналі',
    ad_testing_73: 'відеоконтент',
    ad_testing_74: 'відкритий',
    ad_testing_75: 'телевізійна реклама',
    ad_testing_76: 'друкувати',
    ad_testing_77: 'цифровий',
    ad_testing_78: 'рекламні кампанії',
    ad_testing_79: 'POS дисплей та реклама',
    ad_testing_80: 'аніматика',
    ad_testing_81: 'З нашою допомогою ви можете протестувати свою рекламу на будь-якому етапі',
    ad_testing_82: 'Концепція',
    ad_testing_83: 'які ідеї мають сильнішу властивість?',
    ad_testing_84: 'Виробництво на ранній стадії',
    ad_testing_85: 'яка аніматика піде в продакшн?',
    ad_testing_86: 'Виробництво',
    ad_testing_87: 'як відредагувати відео щоб воно утримувало увагу?',
    ad_testing_88: 'пре-медіа',
    ad_testing_89: "які канали зв'язку працюватимуть і на який результат можна очікувати?",
    ad_testing_90: 'Пост-тестування реклами',
    ad_testing_91: 'Виробництво',
    ad_testing_92: 'моя реклама захоплює? Чи знижується згодом ефективність творчості?',
    ad_testing_93: 'пре-медіа',
    ad_testing_94: 'наскільки ефективна моя реклама у цифрах? як це співвідноситься з конкуренцією?',
    ad_testing_95: 'Попереднє тестування реклами',
    ad_testing_96: 'Щоб надати вам якісні дані, ми використовуємо',
    ad_testing_97: 'методологію кількісних досліджень',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'методологію якісних досліджень',
    ad_testing_100: 'Фокус групи',
    ad_testing_101: 'Штучний інтелект',
    ad_testing_102: 'Wantent - аналіз реакції глядача з метою оцінки ефективності переглянутого матеріалу',
    ad_testing_103: 'Аналітичні звіти, які ви отримуєте',
    ad_testing_104: 'Наша команда аналітиків, медіаекспертів, маркетологів підготує та надасть вам індивідуальний звіт з докладними рекомендаціями',
    ad_testing_age: 'вік',
    ad_testing_ad: 'Оголошення',
    automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
    automotive_industry_price_analysis_2: 'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
    automotive_industry_price_analysis_3: 'Request a quote',
    automotive_industry_price_analysis_4: 'Our methodology',
    automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
    automotive_industry_price_analysis_6: 'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
    automotive_industry_price_analysis_7: 'We offer precise figures and dependable multilateral data',
    automotive_industry_price_analysis_8: 'We have global coverage spanning every country in the world',
    automotive_industry_price_analysis_9: 'Our in-house resources include mystery shoppers and auditors',
    automotive_industry_price_analysis_10: 'We validate the quality of our data',
    automotive_industry_price_analysis_11: 'Our advantages:',
    automotive_industry_price_analysis_12: 'Price monitoring for specific competitive models (B2B and B2C)',
    automotive_industry_price_analysis_13: 'Monitoring the level of discount',
    automotive_industry_price_analysis_14: 'Monitoring retailer stock for your brand and competitors',
    automotive_industry_price_analysis_15: 'Monitoring e-commerce automotive prices',
    automotive_industry_price_analysis_16: 'Price communication analysis',
    automotive_industry_price_analysis_17: 'Keeping track of retailer shipping charges',
    automotive_industry_price_analysis_18: 'Monitoring promotions and sales at retailers',
    automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
    automotive_industry_price_analysis_20: 'Calculating lease payments',
    automotive_industry_price_analysis_21: 'Analyzing specific pricing considerations for electric vehicles (EVs)',
    automotive_industry_price_analysis_22: 'Conducting direct-to-consumer versus retailer price comparisons',
    automotive_industry_price_analysis_23: 'Developing subscription pricing models',
    automotive_industry_price_analysis_24: '4Service is a global customer experience (CX) holding company with over 20 years of experience',
    automotive_industry_price_analysis_25: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_26: "4Service's analysis of prices in the automotive industry includes:",
    automotive_industry_price_analysis_27: "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
    automotive_industry_price_analysis_28: 'of customers understand that prices are increasing across all brands',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'of dealers mentioned the possibility of price increases during the communication/selling process',
    automotive_industry_price_analysis_35: '47.6',
    automotive_industry_price_analysis_36: 'Key figures',
    automotive_industry_price_analysis_37: "You'll figure out how to price your product relative to other vehicles in the lineup.",
    automotive_industry_price_analysis_38: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_39: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
    automotive_industry_price_analysis_40: "You'll be able to determine the appropriate monthly payment and leasing rates.",
    automotive_industry_price_analysis_41: 'What benefits for your brand or dealer will you gain:',
    automotive_industry_price_analysis_42: 'You submit an application',
    automotive_industry_price_analysis_43: 'Our manager contacts you to determine the main objectives',
    automotive_industry_price_analysis_44: 'We conduct a marketing research',
    automotive_industry_price_analysis_45: 'We regularly provide you with reports and recommendations in an online format',
    automotive_industry_price_analysis_46: 'Car with sale sticker',
    automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
    automotive_industry_price_analysis_48: 'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
    retail_audit_1: 'АУДИТ ПОЛЬОВОГО МАРКЕТИНГУ/аудит роздрібної торгівлі',
    retail_audit_2: 'Збирайте детальні дані про ваш магазин або бренд, аналізуйте перешкоди зростання та створюйте ефективні стратегії для їх подолання',
    retail_audit_3: 'Створіть запит цінової пропозиції',
    retail_audit_4: '4Service проводить аудит магазинів на місці від імені брендів, щоб оцінити ефективність їхніх промоційних стратегій, команд виконавців та маркетингових тактик в досягненні цільового ринку.',
    retail_audit_5: 'Ми уважно спостерігаємо за виконанням промоційних кампаній і гарантуємо дотримання промоційних принципів.',
    retail_audit_6: 'Top 10',
    retail_audit_7: 'Агентства CX в Європі',
    retail_audit_8: 'років досвіду',
    retail_audit_9: 'покриття країн',
    retail_audit_10: '1,6 мільйона',
    retail_audit_11: 'оцінок щороку',
    retail_audit_12: 'Що ми відстежуємо?',
    retail_audit_13: 'Наявність матеріалів для точок продажу (POS)',
    retail_audit_14: 'Наявність та активація представників бренду, забезпечення дотримання запланованого графіка',
    retail_audit_15: 'Оцінка залучення представників бренду до кампаній підтримки',
    retail_audit_16: 'Оцінка взаємодії зі споживачами, забезпечення дотримання промоційних принципів та сутності бренду',
    retail_audit_17: 'Заходи запобігання шахрайству',
    retail_audit_18: 'Ваш бізнес може використовувати результати аудиту польового маркетингу для:',
    retail_audit_19: 'Оцінки відповідності бюджету та процесів',
    retail_audit_20: 'Порівняння фактичних результатів зі очікуваними цілями',
    retail_audit_21: 'Покращення ефективності маркетингових кампаній ',
    retail_audit_22: 'Стратегічне планування на майбутнє',
    retail_audit_23: 'Виявлення можливостей зниження витрат',
    retail_audit_24: 'Покращення продажів та рівня конверсії',
    retail_audit_25: 'Оптимізація повернення інвестицій (ROI)',
    retail_audit_26: 'ВОНИ ОБРАЛИ 4SERVICE ЯК СВОГО ТОРГІВЕЛЬНОГО ІНВЕСТИЦІЙНОГО ПАРТНЕРА',
    retail_audit_27: 'Відомий міжнародний виробник тютюнової продукції уклав партнерство з 4Service для підвищення ефективності своїх брендів та поліпшення роботи в магазинах. Наші команди здійснюють аудит маркетингу на місці та надають звіти про споживчий досвід у реальному часі',
    retail_audit_28: 'підвищення точності планування агентства з філд-маркетингу',
    retail_audit_29: 'посилення передачі змісту бренда',
    retail_audit_30: 'залучення споживачів та підвищення активності (BA engagement)',
    retail_audit_31: 'підвищення точності збору даних та відповідність вимогам Загального регламенту про захист даних (GDPR)',
    retail_audit_32: 'Які цінні уявлення ми зазвичай отримуємо:',
    retail_audit_33: 'Відсутність послідовності або навіть конфлікти між умовами SLA призводять до значних несанкціонованих витрат для клієнта',
    retail_audit_34: 'Неправильне розрахування вартості послуг через плутанину з видами наданого сервісу',
    retail_audit_35: 'Не виконання полевих робіт, включаючи непризначення персоналу на заплановану локацію або POS',
    retail_audit_36: 'Розрахунки на підставі оцінки, а не фактичних даних, які не узгоджуються',
    retail_audit_37: 'Неправильне застосування промо-посібника, з наданням шахрайських даних',
    retail_audit_38: 'Як ми працюємо?',
    retail_audit_39: 'Наш аудитор відвідує визначені точки продажу (POS) на основі плану агентства.',
    retail_audit_40: 'Вони оцінюють ключові показники ефективності (KPI), які зазначені в технічному завданні, такі як наявність POS, залучення представника бренду, дотримання промоційних вказівок та заходів запобігання шахрайству.',
    retail_audit_41: 'Валідація:',
    retail_audit_42: 'Ми збираємо та узагальнюємо отримані дані.',
    retail_audit_43: 'Складається комплексний звіт.',
    retail_audit_44: 'Наша команда перевіряє всі опитування на достовірність, включаючи відповідність технічному завданню проекта та будь-які додаткові коментарі.',
    retail_audit_45: 'Онлайн-система звітності:',
    retail_audit_46: 'Заповнені та перевірені анкети завантажуються на наш онлайн-портал протягом 36 годин.',
    retail_audit_47: 'Клієнти мають доступ до особистого облікового порталу, що дає змогу в будь-який час моніторити хід виконання проекту.',
    retail_audit_48: 'Надається заключний звіт з результатами та рекомендаціями.',
    retail_audit_49: 'Зверніться до експерта',
    retail_audit_50: 'Наші переваги:',
  },
  it: {
    form_title: 'Ci metteremo in contatto con te a breve',
    form_required: 'Icampi contrassegnati con * sono obbligatori',
    form_name_placeholder: 'Nome',
    form_email_placeholder: 'Email',
    form_phone_placeholder: 'Numero di telefono',
    form_company_placeholder: 'Società',
    form_submit: 'Invia',
    form_1: "Acconsento al trattamento dei dati personali secondo l'informativa sulla Privacy",
    form_2: 'Politica sulla privacy',
    validation_privacy_policy: "Si prega di accettare l'informativa sulla privacy",
    validation_phone: 'Numero di telefono non valido',
    validation_email: 'Formato email non valido',
    validation_required: 'I campi obbligatori non sono compilati',
    submit_error: 'Si è verificato un errore durante il salvataggio del modulo',
    footer_1: 'I nostri partner:',
    footer_2: 'Metodi',
    footer_3: 'Prodotti',
    footer_4: 'Contatti',
    footer_5: 'Impronta',
    footer_6: 'Politica sulla privacy',
    about_us_1: "4Service Responsabilità sociale d'impresa",
    about_us_2: "Facciamo del nostro meglio per mantenere una scintilla di gentilezza in ciascuno dei nostri dipendenti e rendere il mondo che ci circonda un po' migliore",
    about_us_3: "4Service Responsabilità sociale d'impresa",
    about_us_4: "Facciamo del nostro meglio per mantenere una scintilla di gentilezza in ciascuno dei nostri dipendenti e rendere il mondo che ci circonda un po' migliore",
    about_us_5: 'Gioco di affari di Service Mania',
    about_us_6: 'Le nostre iniziative di sensibilizzazione per bambini e studenti',
    about_us_7: 'Beneficenza / Volontariato',
    about_us_8: "Orfanotrofi, case di cura per anziani e volontariato per l'esercito Ucraino. La parte più commovente delle nostre attività",
    about_us_9: 'Benessere degli animali',
    about_us_10: 'Chiunque può entrare nel nostro ufficio con un animale domestico. Diamo una mano ai rifugi, raccogliamo fondi per il cibo e talvolta cerchiamo anche di trovare una casa per gli animali senza casa',
    about_us_11: '4Service Group.',
    about_us_12: "Società di ricerca internazionale che gestisce l'esperienza del cliente (customer experience)",
    about_us_13: 'Realizziamo progetti complessi per aumentare le vendite e sviluppare il business delle aziende orientate al cliente.',
    about_us_14: "4Service Group è una holding di ricerca internazionale con sede a Vienna, Austria. Il 2001 segna l'inizio delle attività dell'azienda volte a migliorare la qualità della vita dei propri clienti e delle persone che interagiscono con l'azienda, acquistano i suoi servizi e consumano i suoi prodotti. Del resto non è un caso che sia stato scelto il nome «Forservice» per un'azienda, che rappresenta il servizio al cliente e la buona qualità.",
    about_us_15: "Questo è stato l'inizio della storia di 4Service a Kiev, in Ucraina, come fornitore leader di servizi di mystery shopping. Ha continuato a operare con successo come società di ricerca in 52 paesi con nuove opportunità in un approccio olistico all'analisi e al miglioramento dell'intera catena dell'esperienza del cliente (Customer experience CX).",
    about_us_16: 'nato in nome del servizio',
    about_us_17: "2006 primo ufficio situato al di fuori dell'Ucraina 2006",
    about_us_18: 'trasferimento della sede centrale a Vienna',
    about_us_19: "Membro d'élite MSPA, Top-10 agenzia in Europa",
    about_us_20: 'stabilendo uffici in 9 paesi',
    about_us_21: 'primi progetti digitali, Voicer',
    about_us_22: 'partner di DesignThinkers Academy',
    about_us_23: 'primi progetti globali di CХ',
    about_us_24: 'Missione',
    about_us_25: 'La nostra missione è migliorare il servizio dei clienti in tutto il mondo. La nostra ispirazione sono i cambiamenti qualitativi che vediamo nelle persone e nelle aziende con cui lavoriamo.',
    about_us_26: '20+ anni di Customer Experience',
    about_us_27: '95 1 500 000+ valutazioni della qualità del servizio. 400+ progetti attivi.',
    about_us_28: 'Accumuliamo e condividiamo la migliore esperienza del settore da tutto il mondo',
    about_us_29: 'Sede centrale a Vienna',
    about_us_30: 'Raccogliamo e condividiamo la migliore esperienza del settore da tutto il mondo',
    about_us_31: 'valutazioni della qualità del servizio',
    about_us_32: 'Top-10',
    about_us_33: '7 uffici di rappresentanza / sede centrale in Austria',
    about_us_34: 'progetti attivi',
    about_us_35: 'Il tuo futuro posto di lavoro.',
    about_us_36: 'I nostri uffici.',
    about_us_37: '4Service ™ si impegna a creare uno spazio di lavoro confortevole per i propri dipendenti. I nostri dipendenti lavorano in ufficio, da remoto o possono unirsi per garantire la massima comodità e produttività di ciascun dipendente. Camere per il relax, supporto psicologico gratuito e un ambiente accogliente. Siamo amici degli umani e degli animali domestici.',
    about_us_38: 'Una squadra che si illumina.',
    about_us_39: "Il nostro team multinazionale si impegna ad ascoltare, ad appoggiarsi al feedback dei nostri clienti e la crescita reciproca. La mappa del percorso del cliente con i nostri clienti è importante per la nostra attività quanto il lancio di nuovi prodotti o il raggiungimento dei nostri obiettivi finanziari. Le nostre attività sono incentrate sull'avere un impatto positivo sulle comunità che ci circondano e sulla fornitura di innovazione e soluzioni incentrate sulle persone per i nostri clienti.",
    about_us_40: 'Tutto',
    about_us_41: 'Gestione',
    about_us_42: 'Tutti i proprietari del prodotto globale del team di gestione /All Management Team Global Product owners.',
    about_us_43: 'Proprietari del prodotto',
    about_us_44: 'I nostri partner',
    about_us_45: 'Realizziamo progetti complessi per aumentare le vendite e sviluppare il business delle aziende orientate al cliente.',
    about_us_46: 'Istituti di formazione professionale che sono rappresentati in 25 paesi e formano, sviluppano e facilitano team e comunità multidisciplinari creativi per guidare un cambiamento positivo. Il Design Thinking ti consente di sviluppare e fornire idee innovative, modifiche e soluzioni a problemi complicati.',
    about_us_47: "La loro missione è fornire spazio e tempo ai team e agli individui per essere ispirati e sfidati, per «imparare facendo» e rafforzare la loro capacità di risoluzione creativa dei problemi e aiutare le organizzazioni a costruire l'ambiente e la mentalità necessari per dare il via a un'innovazione audace.",
    about_us_48: "è l'Associazione di categoria rappresentativa per le aziende coinvolte nel settore del Mystery Shopping. L'Associazione opera su base regionale in tutto il mondo, con sedi situate in America, Europa/Africa e Asia/Pacifico. Le aziende associate si uniscono come un organismo comune allo scopo di rafforzare l'industria del Mystery Shopping attraverso sforzi e azioni combinati.",
    about_us_49: "è un'organizzazione associativa senza scopo di lucro. I membri formano una comunità convinta del potenziale dell'analisi dei dati, della ricerca e degli approfondimenti per aiutare a migliorare le società, le organizzazioni e la vita di tutti.",
    about_us_50: 'ESOMAR ha sede ad Amsterdam, nei Paesi Bassi. ESOMAR è presente in oltre 130 paesi attraverso i suoi membri dal 1947.',
    about_us_51: "(UCCA) è una comunità professionale di esperti privati e aziende unite con l'obiettivo di sviluppo e interazioni nell'area del contact center e del servizio al cliente. La filosofia dell'UCCA è quella di elevare la cultura del settore dei contact center, basata su tecnologie moderne e standard elevate.",
    about_us_52: "L'Associazione ucraina dei contact center è stata fondata nel 2010.",
    about_us_53: "studia la migliore esperienza mondiale nel campo dell'istruzione per adattarla e utilizzarla sul mercato Ucraino. Ecco perché Academy DTEK collabora con le principali business school internazionali: INSEAD, IE Business School e Stanford University.",
    about_us_54: 'Academy DTEK è focalizzata sullo sviluppo di una cultura orientata al cliente e innovativa in D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, DX School digital program, Effective Leader: Pre-MBA, Change Energy.',
    about_us_55: 'Sergey Pashkovsky',
    about_us_56: 'Viktoria Skorbota',
    about_us_57: 'Artem Fomin',
    about_us_58: 'Max Papka',
    about_us_59: 'Responsabile delle vendite internazionali',
    bht_1: 'Monitoraggio della salute del marchio',
    bht_2: 'Tracciamento',
    bht_3: 'I marchi sono incredibilmente potenti',
    bht_4: 'Scopri le prestazioni complessive del tuo marchio',
    bht_5: 'Circa',
    bht_6: "Che cos'è il monitoraggio della salute del marchio?",
    bht_7: 'Si tratta di un tipo di ricerca di mercato che viene condotta con una certa regolarità (ogni due settimane, ogni mese ecc.) al fine di ottenere informazioni ',
    bht_8: "sullo stato di un marchio sul mercato sulla base di indicatori quali la preminenza, la domanda, l'immagine, le caratteristiche dei suoi consumatori, salute del marchio, ecc.",
    bht_9: 'Acquista il marchio più spesso',
    bht_10: 'Leale',
    bht_11: 'Acquistato il marcio ultimi 3 mesi',
    bht_12: 'Clienti abituali/ripetuti',
    bht_13: 'Acquistato il marchio negli ultimi 6 mesi',
    bht_14: 'Clienti a breve termine',
    bht_15: "Acquistato il marchio nell'ultimo anno",
    bht_16: 'Clienti a lungo termine',
    bht_17: "Conosci il marchio? ma non l'ho mai usato/comprato",
    bht_18: 'Consapevoli, ma non consumatori',
    bht_19: 'Inconsapevole: non hai mai sentito parlare del marchio',
    bht_20: 'Inconsapevole',
    bht_21: 'Profilo degli acquirenti del marchio',
    bht_22: 'Valutare la coerenza degli sforzi compiuti a lungo termine per costruire un marchio',
    bht_23: 'Misurare il livello di conoscenza del marchio da parte del cliente',
    bht_24: "Mostrare l'efficacia delle campagne pubblicitarie e informative.",
    bht_25: 'Determinare quale impatto ha un periodo di alta pubblicità sulle attività di vendita/marketing dei concorrenti.',
    bht_26: "Fornendo un controllo costante all'imprenditore nei periodi di",
    bht_27: 'Quali compiti possono essere risolti da BHT:',
    bht_28: 'Richiedi una ricerca',
    bht_29: 'Compito da risolvere',
    bht_30: 'Ad esempio,',
    bht_31: "se un'azienda ha bisogno di una sola sessione di BHT a trimestre durante la misurazione della quota di mercato, un sondaggio tra gli intervistati dovrebbe essere condotto più spesso quando si misura la consapevolezza del marchio del cliente dalla pubblicità.",
    bht_32: 'CATI',
    bht_33: 'Personale (faccia a faccia)',
    bht_53: 'Interviste/Colloqui',
    bht_34: 'CAWIComputer Assisted Web Interviewing)',
    bht_35: 'Compito da risolvere',
    bht_36: 'By collecting information',
    bht_37: 'Raccogliendo informazioni attraverso vari metodi, il tracciamento del marchio è solitamente suddiviso in tre fasi a seconda del pubblico di destinazione e della difficoltà di raggiungerlo:',
    bht_38: 'Usiamo sia metodi tradizionali che contemporanei per collezionare dati:',
    bht_39: "Lavoriamo utilizzando due modelli, uno con un semplice insieme di indicatori, mentre l'altro si rivolge a settori più complessi come l'industria alimentare e dei servizi. Un modello più complesso prevede uno studio approfondito che include fino a 10 indici stimati.",
    bht_40: 'Offriamo il',
    bht_41: 'controllo sui dati iniziali',
    bht_42: 'Copriamo',
    bht_43: 'tutto il paese',
    bht_44: 'Forniamo una soluzione ',
    bht_45: 'per mercati non dinamici/mercati con un numero limitato di attori (Brand Point Analyzer)',
    bht_46: 'Perché noi?',
    bht_47: 'Perché scegliere noi?',
    bht_50: 'Risultato negativo',
    bht_51: 'Buon risultato',
    bht_52: 'Risultato medio',
    cases_1: "Sfida: come migliorare l'esperienza di acquisto di veicoli elettrici dei clienti negli showroom Hyundai?",
    cases_2: 'Mappatura del percorso del cliente',
    cases_3: 'Ideazione',
    cases_4: 'Ricerca qualitativa',
    cases_5: "Sfida: cosa dovrebbe essere cambiato nei negozi (sia nel processo di servizio, negli interni, nella percezione del marchio) per attirare l'interesse dei clienti per il marchio Oppo?",
    cases_6: 'Online CJM',
    cases_7: 'Test UI',
    cases_8: 'Interviste',
    cases_9: "Sfida: cosa dovrebbe essere cambiato nei negozi per attirare l'interesse dei clienti verso il marchio Oppo?",
    cases_10: 'Parte qualitativa',
    cases_11: 'Ideazione',
    cases_12: 'Parte quantitativa',
    cases_13: 'Mappa del percorso del cliente /Customer Journey Map',
    cases_14: 'Casi',
    cases_15: 'Tutto ',
    cases_16: 'Creiamo insieme il vostro caso eccezionale',
    csi_1: 'Cliente',
    csi_2: 'indice di soddisfazione',
    csi_3: 'Determinare i motivi ',
    csi_4: "della soddisfazione e dell'insoddisfazione dei consumatori",
    csi_5: 'La ricerca sulla soddisfazione del cliente ',
    csi_6: 'è la determinazione dei fattori, delle cause e dei motivi che influenzano la decisione di acquisto di un cliente, nonché il comportamento del pubblico target.',
    csi_7: 'Un sondaggio sulla soddisfazione del cliente ',
    csi_8: 'mostra quanto è soddisfatto o insoddisfatto un cliente rispetto a un particolare aspetto del servizio o del prodotto in generale.',
    csi_9: 'Il sondaggio sulla soddisfazione ',
    csi_10: "viene condotto tra i clienti esistenti di un'azienda e gli utenti di determinati prodotti/servizi.",
    csi_11: "Che cos'è la CSI?",
    csi_12: "La soddisfazione del cliente misura l’entità in cui le aspettative di un cliente sono state soddisfatte per quanto riguarda l'acquisto di un prodotto o servizio fornito dalla tua azienda.",
    csi_13: 'Happy man',
    csi_14: 'Circa',
    csi_15: 'Identificare eventuali fattori nascosti ',
    csi_16: 'che possono influenzare la soddisfazione del cliente',
    csi_17: 'Determinare le ragioni',
    csi_18: 'alla base di qualsiasi aumento/diminuzione della domanda di beni e servizi',
    csi_19: "Ottimizzare l'azienda",
    csi_20: 'processi di business',
    csi_21: 'Adattandoli',
    csi_22: ' alle esigenze del consumatore moderno',
    csi_23: 'Formare un messaggio pubblicitario efficace',
    csi_24: ' e posizionare un prodotto sotto la richiesta del cliente',
    csi_25: 'Compiti da risolvere',
    csi_26: 'Quali compiti possono risolvere i sondaggi sulla soddisfazione dei clienti',
    csi_27: 'Uomo con il telefono',
    csi_28: 'Costruiamo un modello matematico ',
    csi_29: 'dei fattori che possono influenzare la soddisfazione/fidelizzazione del cliente (analisi di regressione)',
    csi_30: 'Ottimizzare',
    csi_31: " i processi di business di un'azienda",
    csi_32: 'Costruiamo un indice di soddisfazione del cliente calcolato',
    csi_33: '(CSI) e confrontarlo con quello dichiarativo',
    csi_34: 'Conduciamo ricerche',
    csi_35: ' per entrambi i segmenti B2B e B2C.',
    csi_36: 'I nostri questionari sono sviluppati da un team ',
    csi_37: 'di analisti specialmente per i progetti individuali dei nostri clienti',
    csi_38: 'Perche noi',
    csi_39: 'Perché scegliere noi?',
    csi_40: 'Methods of collecting information:',
    main_1: 'migliorare la propria performance finanziaria imparando dalle esperienze dei propri clienti',
    main_2: 'Consulenza e servizi СX una visione a 360° del tuo business',
    main_3: 'Scopri la nostra gamma di soluzioni e strumenti che utilizziamo per risolvere le tue sfide aziendali',
    main_4: 'Prodotti',
    main_5: "La ricerca qualitativa è la raccolta e l'analisi di dati non numerici per comprendere concetti, opinioni o esperienze",
    main_6: "I metodi di ricerca quantitativi sono la misurazione oggettiva e l'analisi numerica dei dati raccolti attraverso sondaggi, questionari e indagini.",
    main_7: "Il mystery shopping è una tecnica di ricerca di mercato per misurare e monitorare le interazioni tra un cliente e un'azienda o un'organizzazione durante uno scenario predefinito",
    main_8: 'Sistema di gestione del feedback. La voce del tuo cliente in un unico sistema - Sistema di gestione del feedback dei clienti',
    main_9: "Una piattaforma di content intelligence basata sull'intelligenza artificiale che fornisce approfondimenti dal pubblico",
    main_10: 'Software di gestione delle prestazioni. Applicazione gamificata per aumentare le vendite',
    main_11: 'Il monitoraggio dei prezzi è il processo di raccolta, elaborazione e analisi dei prezzi dei concorrenti',
    main_12: 'Il service design è come comprendere i bisogni e i desideri delle persone che utilizzeranno un prodotto o un servizio trascorrendo del tempo con loro',
    main_13: 'Il nostro team analitico applica gli approcci più aggiornati e fondamentali che abbiamo dimostrato di essere efficaci con molti clienti',
    main_14: 'Metodologie',
    main_15: 'Mappa di percorso del cliente',
    main_16: "Customer Journey Mapping (CJM) riguarda la definizione di un pubblico target, la comprensione delle sue esigenze e dell'ambiente e quindi la creazione di un piano per offrire un'esperienza coinvolgente",
    main_17: 'CJM',
    main_18: 'Punteggio promotore internet',
    main_19: "Il Net Promoter Score (NPS) è il gold standard delle metriche relative all'esperienza del cliente, basato su una domanda: con quale probabilità consiglieresti organizzazione/prodotto/servizio?",
    main_20: 'NPS',
    main_21: 'Monitoraggio della salute del marchio',
    main_22: 'La ricerca Brand Health Tracking (BHT) /Monitoraggio della salute del marchio/ può misurare le prestazioni del tuo marchio in termini di consapevolezza e utilizzo, posizionamento del marchio e prestazioni del marchio',
    main_23: 'BHT',
    main_24: 'Ricerca sulle risorse umane',
    main_25: "La ricerca sulle risorse umane viene utilizzata per valutare le pratiche e le prestazioni delle risorse umane e offre un'analisi dettagliata sullo sviluppo e sulla gestione attuali",
    main_26: 'Ricerca sulle risorse umane',
    main_27: 'Indice di soddisfazione del cliente',
    main_28: "La ricerca sulle risorse umane viene utilizzata per valutare le pratiche e le prestazioni delle risorse umane e offre un'analisi dettagliata sullo sviluppo e sulla gestione attuali",
    main_29: 'CSI',
    main_30: 'Test UX',
    main_31: "Il test di usabilità (UX) è il modo in cui le persone reali interagiscono con un sito Web, un'app o un altro prodotto e ne osservano il comportamento e le reazioni",
    main_32: 'Perche noi',
    main_33: "La nostra missione è aiutare le aziende a fornire un'esperienza cliente altamente efficace",
    main_34: 'Puntare sulla qualità',
    main_35: 'Controlliamo la qualità ',
    main_36: 'quando si tratta di dati di ricerca. Mettiamo a disposizione dei clienti tutti i set di dati',
    main_37: 'Conveniente',
    main_38: 'La nostra esperienza, le risorse e i processi ',
    main_39: 'aziendali semplificati ci consentono di farti risparmiare tempo e denaro',
    main_40: 'Soluzioni smart',
    main_41: 'Utilizziamo una varietà di tecniche ',
    main_42: 'e toolkit per risolvere i tuoi problemi particolari',
    main_43: 'Puntare sulla qualità',
    main_44: 'We quality-controlled',
    main_45: 'when it comes to research data. We make all data sets available to clients',
    main_46: 'Conveniente',
    main_47: 'La nostra esperienza, le risorse e i processi ',
    main_48: 'aziendali semplificati ci consentono di farti risparmiare tempo e denaro',
    main_49: 'Soluzioni smart',
    main_50: 'Utilizziamo una varietà di tecniche ',
    main_51: 'e toolkit per risolvere i tuoi problemi particolari',
    main_52: 'Cash & Carry',
    main_53: 'Elettronica ',
    main_54: 'Lusso/& Premium',
    main_55: 'Ristorazione & Ospitalità',
    main_56: 'Vendita al dettaglio',
    main_57: 'Settore automobilistico',
    main_58: 'Moda',
    main_59: 'Banche',
    main_60: 'Viaggi & tempo libero',
    main_61: 'Orologi & Gioielli',
    main_62: 'Casalinghi & elettrodomestici',
    main_63: 'Blog',
    main_64: 'Vai al blog per leggere altri post interessanti',
    main_65: 'Retail audit',
    cjm_1: 'Cliente',
    cjm_2: 'Journey map/Journey map',
    cjm_3: 'Entra nel mondo dei clienti e condividi la loro esperienza',
    cjm_4: 'Parlate con un esperto di cosa è la Customer Journey Map (percorso del cliente)?',
    cjm_5: "CJM fornisce una cronologia visualizzata dell'interazione del consumatore con un prodotto, servizio, azienda o marchio attraverso vari canali e durante un certo periodo di tempo.",
    cjm_6: "Ciò consente alle aziende di analizzare in modo oggettivo l'esperienza di interazione con il cliente, di correggere ed eliminare eventuali ostacoli e di offrire raccomandazioni per il miglioramento del prodotto.",
    cjm_7: "Determinazione dell'AT, segmentazione",
    cjm_8: 'Individuazione delle fasi di interazione con il cliente',
    cjm_9: 'Identificazione dei principali canali di interazione con il cliente',
    cjm_10: "Ricerca sull'esperienza del cliente",
    cjm_11: 'Analisi delle barriere e ipotesi di ottimizzazione',
    cjm_12: 'Esecuzione e verifica di ipotesi',
    cjm_13: 'Progettazione di una mappa del percorso( Customer Journey Map) del cliente consiste nei seguenti passaggi:',
    cjm_14: 'Mappa del percorso/Jorney Map',
    cjm_15: "CJM fornisce una cronologia visualizzata dell'interazione del consumatore con un prodotto, servizio, azienda o marchio attraverso vari canali e durante un certo periodo di tempo.",
    cjm_16: 'Garantiamo il lavoro di squadra',
    cjm_17: ' tra analisti e designer',
    cjm_18: 'Offriamo un approccio individuale per ogni cliente',
    cjm_19: 'approach to each client',
    cjm_20: "Possiamo aumentare la soddisfazione complessiva del cliente con il servizio/prodotto e, in ultima analisi, aumentare la loro fedeltà all'azienda",
    cjm_21: 'customer satisfaction with the service/product and ultimately increase their loyalty to the company',
    cjm_22: "Garantiamo l'ottimizzazione del percorso del consumatore e prendiamo in considerazione i processi di business dell'azienda",
    cjm_23: 'optimization of the consumer’s path and take into account the company’s business processes',
    cjm_24: 'Offriamo ai nostri clienti la possibilità di utilizzare approcci combinati: CATI, CAWI, focus group, interviste in profondità, interviste agli esperti, etnografia, ecc.',
    cjm_25: 'the opportunity to use combined approaches: CATI, CAWI, focus groups, in-depth interviews, expert interviews, ethnography, etc.',
    cjm_26: 'Perché scegliere noi?',
    methods_hr_1: 'Ricerca Risorse Umane',
    methods_hr_2: 'Ottieni informazioni obiettive sul tuo team e scopri come migliorare le performance HR',
    methods_hr_3: 'Alcuni motivi per cui potresti aver bisogno di una ricerca sulle risorse umane',
    methods_hr_4: ' del personale intervistato',
    methods_hr_5: ' per i progetti delle risorse umane implementati',
    methods_hr_6: 'sono state organizzate sessioni di formazione del personale',
    methods_hr_7: 'Parla con un esperto di Holding Leader in Europa per la customer experience e la ricerca dei dipendenti.',
    methods_hr_8: '4service HR ricerche in numeri',
    methods_hr_9: 'Per identificare i problemi e le debolezze in ogni fase della vita dei dipendenti della tua azienda: ricerca/reclutamento/Onboarding/CarrieraSviluppo/Ricertificazioni',
    methods_hr_10: 'Per sapere quali occasioni meritano aumenti e bonus',
    methods_hr_11: "È necessario prevedere o fornire soluzioni per l'insoddisfazione del lavoro",
    methods_hr_12: 'Attraverso la ricerca sulle HR ( risorse umane) sono possibili nuovi e attuali metodi di valutazione delle politiche, dei programmi e delle pratiche delle HR(risorse umane)',
    methods_hr_13: "Per migliorare la salute mentale dei dipendenti dell'azienda",
    methods_hr_14: 'Se vuoi migliorare la produttività attraverso un approccio orientato agli obiettivi',
    methods_hr_15: 'Se hai un team internazionale e / o remoto e hai bisogno di gestirlo',
    methods_hr_16: 'Per capire quanto è attraente il tuo Employer Brand per i potenziali dipendenti',
    methods_hr_17: 'Soddisfazione degli impiegati',
    methods_hr_18: 'Ricerca sul marchio delle risorse umane',
    methods_hr_19: 'Diversità culturale',
    methods_hr_20: 'Piani retributivi e salariali',
    methods_hr_21: 'Coinvolgimento e lealtà del team',
    methods_hr_22: 'Classe protetta',
    methods_hr_23: 'Aree di ricerca',
    methods_hr_24: 'Soluzioni 1',
    methods_hr_25: 'eNPS, soddisfazione dei dipendenti, fedeltà dei dipendenti',
    methods_hr_26: 'Cosa misuriamo:',
    methods_hr_27: 'Lealtà:',
    methods_hr_28: 'lealtà dei dipendenti, previsione del comportamento futuro, determinazione del peso dei fattori che influenzano la lealtà',
    methods_hr_29: 'Coinvolgimento:',
    methods_hr_30: 'coinvolgimento dei dipendenti nel lavoro, interesse per i risultati e le realizzazioni',
    methods_hr_31: 'Fidanzamento:',
    methods_hr_32: 'identificazione dei valori dei dipendenti e dei valori aziendali',
    methods_hr_33: 'Fidati di 360:',
    methods_hr_34: 'fiducia nella direzione, nei colleghi, nei subordinati, fiducia nelle strategie e nelle politiche del datore di lavoro',
    methods_hr_35: 'Soddisfazione:',
    methods_hr_36: 'definizione dei fattori unici per ogni azienda, valutazione della soddisfazione su ogni fattore chiave che influenza la fidelizzazione',
    methods_hr_37: 'Soluzioni 2',
    methods_hr_38: 'Marchio del datore di lavoro',
    methods_hr_39: "Analisi interna dell'employer brand (personale, stakeholder, reclutatori interni e indagine sugli specialisti delle risorse umane)",
    methods_hr_40: 'brand analysis (staff, stakeholder, internal recruiters and HR specialists survey)',
    methods_hr_41: 'Analisi del datore di lavoro esterno (analisi EVP della concorrenza, sondaggio sui reclutatori esterni, sondaggio sulle persone in cerca di lavoro)',
    methods_hr_42: '(competitor EVP analysis, external recruiter survey, job seeker survey)',
    methods_hr_43: 'Analisi della brand awareness e della reputazione',
    methods_hr_44: 'analysis',
    methods_hr_45: 'Presenza del datore di lavoro',
    methods_hr_47: 'Raccolta delle referenze del datore di lavoro',
    methods_hr_48: 'collection',
    methods_hr_49: 'Benchmarking competitivo ',
    methods_hr_50: 'su fattori chiave come ',
    methods_hr_51: 'stipendio,',
    methods_hr_52: ' ambiente di lavoro, atmosfera, natura del lavoro',
    methods_hr_53: 'Analisi dei bisogni e delle aspettative',
    methods_hr_54: ' delle persone in cerca di lavoro',
    methods_hr_55: 'Interviste approfondite,',
    methods_hr_56: 'vengono utilizzate ricerche documentali e ricerche quantitative',
    methods_hr_57: 'Soluzione 3',
    methods_hr_58: 'Mappa del viaggio dei dipendenti/impiegato misterioso',
    methods_hr_59: "costruire l'identificazione del percorso di ricerca del lavoro",
    methods_hr_60: 'le principali fasi della ricerca, i canali di comunicazione, i criteri di selezione e valutazione delle persone in cerca di lavoro',
    methods_hr_61: 'monitoraggio di tutte le fasi',
    methods_hr_62: 'e canali di comunicazione con le persone in cerca di lavoro mystery/misterioso/ (basta fissare un appuntamento per un colloquio, sostenere un colloquio, uno stage in azienda)',
    methods_hr_63: 'Identificazione ',
    methods_hr_64: 'dei punti deboli nei processi di reclutamento e onboarding',
    methods_hr_65: 'Raccomandazioni per il miglioramento,',
    methods_hr_66: ' benchmarking con i leader del settore',
    mystery_shopping_1: 'Mystery',
    mystery_shopping_2: 'Shopping',
    mystery_shopping_3: 'Valuta il tuo servizio con clienti reali',
    mystery_shopping_4: 'Verifica gli standard valuta il tuo personale avvicinati al tuo cliente',
    mystery_shopping_5: "Siamo membri d'élite dell'associazione professionale leader di fornitori di servizi di Mystery Shopping MSPA Europe",
    mystery_shopping_6: 'di esperienza ',
    mystery_shopping_7: 'visite mensili',
    mystery_shopping_8: 'mystery shopper in tutto il mondo',
    mystery_shopping_9: 'Progetti attivi',
    mystery_shopping_10: 'uffici, quartier generale in Austria',
    mystery_shopping_11: 'Fornitori europei di Mystery Shopping',
    mystery_shopping_12: 'valutazioni della qualità del servizio',
    mystery_shopping_13: 'Questionario',
    mystery_shopping_14: 'Software',
    mystery_shopping_15: "Profilo dell'acquirente",
    mystery_shopping_16: 'Convalida e obiezione',
    mystery_shopping_17: 'Analitica',
    mystery_shopping_18: 'Con questi 5 passaggi la nostra azienda 4Service garantisce la qualità del servizio di mystery shopper',
    mystery_shopping_19: 'Programma',
    mystery_shopping_20: 'I 5 elementi del programma di Mystery Shopping di 4Service',
    mystery_shopping_21: 'Questionario',
    mystery_shopping_22: "l'essenza del vostra programma",
    mystery_shopping_23: 'Questa parte del programma è visualizzabile da tutti i soggetti interessati',
    mystery_shopping_24: 'Per i top manager.',
    mystery_shopping_25: ' Mostra tutte le fasi e i dettagli importanti del processo',
    mystery_shopping_26: 'per gli impiegati.',
    mystery_shopping_27: 'Rifletti su ciò che i datori di lavoro si aspettano da loro',
    mystery_shopping_28: 'Per mystery shoppers',
    mystery_shopping_29: 'Chiaro e strutturato per evitare confusione e pregiudizi',
    mystery_shopping_30: 'Software Shopmetrics:',
    mystery_shopping_31: 'facile da usare e innovativo',
    mystery_shopping_32: 'Benefici:',
    mystery_shopping_33: 'Integrazione ',
    mystery_shopping_34: 'con i processi aziendali e il software',
    mystery_shopping_35: 'Guidati dal GDPR',
    mystery_shopping_36: 'Toolkit per la gestione della privacy',
    mystery_shopping_37: 'Dettagliato',
    mystery_shopping_38: 'Pacchetto di conformità legale',
    mystery_shopping_39: 'relazione',
    mystery_shopping_40: 'costruttore',
    mystery_shopping_41: 'Gestione',
    mystery_shopping_42: ' dei livelli di accesso',
    mystery_shopping_43: 'La maggior parte dei questionari',
    mystery_shopping_44: "viene completata entro un'ora",
    mystery_shopping_45: 'profilo degli acquirenti:',
    mystery_shopping_46: 'Oltre 200.000 mystery shopper in tutto il mondo',
    mystery_shopping_47: 'Criteri principali: massima vicinanza al tuo target di riferimento, con precedenti esperienze con la tua azienda o concorrenti',
    mystery_shopping_48: 'Formazione degli acquirenti:',
    mystery_shopping_49: 'Prova di introduzione alla formazione',
    mystery_shopping_50: 'Skype/chiamata telefonica',
    mystery_shopping_51: 'Test di valutazione (facoltativo)',
    mystery_shopping_52: 'Convalida e obiezioni',
    mystery_shopping_53: 'Validazione ',
    mystery_shopping_54: 'Sistema',
    mystery_shopping_55: 'Il 100% dei report',
    mystery_shopping_56: ' viene esaminato dal team di convalida',
    mystery_shopping_57: 'Ulteriori richieste ',
    mystery_shopping_58: 'di chiarimenti',
    mystery_shopping_59: 'Registrazioni audio ',
    mystery_shopping_60: 'dopo la visita per annotare le questioni più importanti',
    mystery_shopping_61: 'Sistema di prevenzione delle frodi dei dipendenti',
    mystery_shopping_62: 'prevention system',
    mystery_shopping_63: 'Obiezione',
    mystery_shopping_64: 'Sistema',
    mystery_shopping_65: 'La valutazione di qualsiasi acquirente ',
    mystery_shopping_66: 'può essere contestata dal dipendente direttamente sul sito web',
    mystery_shopping_67: 'Ogni obiezione ',
    mystery_shopping_68: 'viene considerata e risolta dal team di convalida locale',
    mystery_shopping_69: 'Puoi vedere la statistica ',
    mystery_shopping_70: 'delle obiezioni',
    mystery_shopping_71: 'Analitica',
    mystery_shopping_72: 'La relazione viene presentata di persona e contiene:',
    mystery_shopping_73: "I punti deboli e le preoccupazioni dell'azienda",
    mystery_shopping_74: 'pain points and concerns',
    mystery_shopping_75: 'Dinamiche ',
    mystery_shopping_76: 'per divisioni e sezioni',
    mystery_shopping_77: 'Correlazione ',
    mystery_shopping_78: 'tra il rispetto degli standard e la soddisfazione del cliente',
    mystery_shopping_79: 'Benchmarking',
    mystery_shopping_80: ' contro concorrenti',
    mystery_shopping_81: 'Raccomandazioni pratiche per il miglioramento del servizio',
    mystery_shopping_82: 'recommendations on service improvements',
    mystery_shopping_83: 'Cosa ci rende diversi?',
    mystery_shopping_84: 'Realizziamo sessioni di ideazione',
    mystery_shopping_85: 'Non solo consigliamo cosa fare, ma insieme a voi cerchiamo modi pratici per risolvere i problemi di servizio.',
    mystery_shopping_86: 'Le sessioni di ideazione sono un potente strumento che ci aiuta:',
    mystery_shopping_87: 'Sviluppare indicazioni per il miglioramento di prodotti o servizi',
    mystery_shopping_88: 'Per esplorare nuove opportunità di flusso di entrate e strategie di business',
    mystery_shopping_89: 'Per trovare soluzioni a complesse sfide incentrate sul cliente',
    mystery_shopping_90: 'Per trasformare i punti deboli dei clienti in delizie',
    mystery_shopping_91: 'Realizziamo video interviste',
    mystery_shopping_92: 'Non raccogliamo solo questionari, ma riceviamo anche commenti in tempo reale dagli acquirenti',
    mystery_shopping_93: 'Lo facciamo per:',
    mystery_shopping_94: 'I nostri facilitatori condurranno interviste approfondite e forniranno un feedback efficace',
    mystery_shopping_95: "Guarda l'esperienza dalla prospettiva del cliente",
    mystery_shopping_96: 'Migliora la tua strategia di marketing dei contenuti',
    mystery_shopping_97: 'prevedere il comportamento del cliente',
    mystery_shopping_98: 'Progettiamo la Customer Journey Map',
    mystery_shopping_99: 'La mappatura del percorso del cliente ci aiuta a calarci nei panni dei clienti per comprenderne le esigenze e le sfide.',
    mystery_shopping_100: 'Creare un customer journey è il modo migliore per:',
    mystery_shopping_101: "Guarda l'esperienza dalla prospettiva del cliente",
    mystery_shopping_102: 'Migliora la tua strategia di marketing dei contenuti',
    mystery_shopping_103: 'Prevedere il comportamento del cliente',
    mystery_shopping_104: 'Identificare le lacune nel servizio o nelle comunicazioni',
    mystery_shopping_105: 'Forniamo diversi tipi di Mystery Shopping',
    mystery_shopping_106: 'Tipologie di Mystery Shopping',
    mystery_shopping_107: 'Mystery chiamante',
    mystery_shopping_108: 'Chiama i tuoi manager e/o il call center per determinare la qualità del servizio fornito al telefono',
    mystery_shopping_109: 'Lusso mystery shopping',
    mystery_shopping_110: 'Servizio di controllo in location di lusso da parte di una categoria speciale di mystery shopper',
    mystery_shopping_111: 'Visite competitive',
    mystery_shopping_112: 'Il modo più semplice per capire come opera il tuo concorrente',
    mystery_shopping_113: 'Impiegato misterioso/Dipendente Mystery ',
    mystery_shopping_114: "Ti aiuterà a comprendere l'intero percorso del tuo dipendente (assunzione, adattamento, processi d'ufficio, ecc.)",
    mystery_shopping_115: 'Visite motivazionali',
    mystery_shopping_116: "Uno strumento utile per stimolare l'offerta di servizi e/o prodotti aggiuntivi, la vendita di prodotti specifici",
    mystery_shopping_117: 'Mystery Shopper Online',
    mystery_shopping_118: "Simula e misura l'esperienza del cliente nel tuo negozio online, sito Web o app",
    mystery_shopping_119: 'Controllo delle vendite e delle promozioni',
    mystery_shopping_120: 'Aiuta a identificare come vengono visualizzate le promozioni in tutte le località e quanto efficacemente funzionano',
    mystery_shopping_121: 'Acquisto e visite di ritorno',
    mystery_shopping_122: "Aiuta a riflettere l'intero processo dall'acquisto al reso per migliorare l'esperienza del cliente",
    mystery_shopping_123: '> 20 anni',
    nps_1: 'Net Promoter',
    nps_2: 'Score',
    nps_3: 'Misura, monitora e migliora la fidelizzazione dei clienti',
    nps_4: 'Rispondono con un punteggio da 0 a 6. ',
    nps_5: "Potrebbero aver avuto un'esperienza negativa ed è improbabile che comprino di nuovo da te, o potrebbero persino scoraggiare gli altri dal comprando da te. ",
    nps_6: 'Questi sono gli intervistati che ottengono un punteggio tra 7 e 8. ',
    nps_7: 'Sono soddisfatti del tuo servizio ma non abbastanza felici da essere considerati promotori.',
    nps_8: 'Questi sono gli intervistati che valutano il tuo business  come un 9 o un 10. ',
    nps_9: 'Ciò significa che ti consiglierebbero a un amico o a un collega e quindi stanno difendendo per tuo conto.',
    nps_10: 'Che cos’è NPS',
    nps_11: 'It measures customer perception based on one simple question:',
    nps_12: 'How likely is it that you would recommend [Organization/Product /Service] to a friend or colleague?',
    nps_13: 'La mia azienda ne ha bisogno?',
    nps_14: 'NPS è la tua crescita sostenibile',
    nps_15: 'Understand the Dynamics of Customer Loyalty',
    nps_16: 'Motiva il tuo personale',
    nps_17: "Comprendere l'atteggiamento nei confronti dei diversi prodotti",
    nps_18: 'Confronta il modo in cui i clienti trattano te e i tuoi concorrenti',
    nps_19: 'Cosa posso fare con NPS? ',
    nps_20: 'Per quale tipo di attività è rilevante NPS?',
    nps_21: 'Information gathering methods:',
    nps_22: 'I nostri consigli quando si lavora con NPS',
    nps_23: 'Ciò significa che sviluppiamo linee guida per aiutare a trasformare i detrattori in promotori.',
    nps_24: 'Lavoriamo con un metodo “a circuito chiuso” (closed loop).',
    nps_25: 'NPS è una ricerca di monitoraggio in cui monitoriamo i cambiamenti nella fedeltà dei clienti',
    nps_26: 'Monitoriamo le dinamiche ',
    nps_27: 'Offriamo di valutare sempre i concorrenti nella tua nicchia per un analisi più approfondite.',
    nps_28: 'Valutazione dei concorrenti',
    price_monitoring_1: 'Monitoraggio dei prezzi',
    price_monitoring_2: 'Monitoraggio della vendita al dettaglio.',
    price_monitoring_3: 'Aumentare il potenziale di vendita e ottimizzare la  politica dei prezzi monitorando i prezzi della concorrenza.',
    price_monitoring_4: '4Service fornisce servizi di monitoraggio dei prezzi in tutto il mondo da oltre 20 anni.',
    price_monitoring_5: 'I prezzi sono monitorati in breve tempo dal nostro team di revisori interni qualificati, permettendoci di rispondere rapidamente alle variazioni dei prezzi dei concorrenti',
    price_monitoring_6: 'SFIDE che la nostra azienda può aiutarti a risolvere con il monitoraggio dei prezzi',
    price_monitoring_7: 'Valutazione dei prezzi dei concorrenti',
    price_monitoring_8: 'e politiche di prodotto',
    price_monitoring_9: 'Monitoraggio di promozioni',
    price_monitoring_10: 'e offerte speciale',
    price_monitoring_11: 'Monitoraggio delle condizioni di mercato',
    price_monitoring_12: 'e del contesto di mercato',
    price_monitoring_13: 'Verifica e controllo punti di vendita',
    price_monitoring_14: 'per attrezzature e merci speciali',
    price_monitoring_15: 'Prodotto di gestione',
    price_monitoring_16: "flusso all'interno del commercio",
    price_monitoring_17: 'Ottimizzare le condizioni',
    price_monitoring_18: 'per una migliore collaborazione con fornitori, rivenditori',
    price_monitoring_19: 'Misurazione dello spazio sugli scaffali',
    price_monitoring_20: 'valutazione del posizionamento delle merci, disponibilità di magazzino',
    price_monitoring_21: ' chiave indicatori e criteri di monitoraggio',
    price_monitoring_22: "Tutti i dati sono raccolti in un'unica interfaccia che include:",
    price_monitoring_23: 'Nome',
    price_monitoring_24: ' del prodotto e categoria del marchio',
    price_monitoring_25: 'disponibilità',
    price_monitoring_26: ' di materiale promozionale',
    price_monitoring_27: 'prezzo ',
    price_monitoring_28: 'e la disponibilità di eventuali offerte promozionali',
    price_monitoring_29: 'scaffale',
    price_monitoring_30: 'Misura',
    price_monitoring_31: 'Prodotto ',
    price_monitoring_32: 'Schermo',
    price_monitoring_33: 'SKU',
    price_monitoring_34: 'di fronte',
    price_monitoring_35: 'Tipo',
    price_monitoring_36: 'di confezionamento',
    price_monitoring_37: 'qualsiasi altra ',
    price_monitoring_38: ' informazione generale',
    price_monitoring_39: 'Analisi pre-progetto:',
    price_monitoring_40: 'Studiamo ',
    price_monitoring_41: ' la politica dei prezzi e dei prodotti della tua azienda, le pratiche commerciali e del settore',
    price_monitoring_42: 'Noi forniamo',
    price_monitoring_43: 'formazione, test e certificazione tempestivi per gli auditor',
    price_monitoring_44: 'Personalizziamo',
    price_monitoring_45: ' e installiamo il software',
    price_monitoring_46: 'Lavoro sul campo:',
    price_monitoring_47: 'Visite alla vendita al dettaglio',
    price_monitoring_48: 'punti vendita',
    price_monitoring_49: 'Segnalazione:',
    price_monitoring_50: 'Il nostro team analitico',
    price_monitoring_51: "predispone la reportistica online nella contabilità personale dell'azienda",
    price_monitoring_52: 'Forniamo',
    price_monitoring_53: ' un rapporto fotografico completo, un rapporto PPT, un rapporto Excel in termini di tipo di località, nome della catena, indirizzo del punto vendita',
    price_monitoring_54: 'Come ',
    price_monitoring_55: 'funziona',
    price_monitoring_56: 'la dimensione ',
    price_monitoring_57: 'del calcolo del costo ',
    price_monitoring_58: 'degli sconti individuali',
    price_monitoring_59: 'programmi fedeltà',
    price_monitoring_60: 'eccetera',
    price_monitoring_61: 'Utilizziamo software specializzati:',
    price_monitoring_62: 'PRADATA, Shopmetrics e offriamo un supporto tecnico completo',
    price_monitoring_63: 'Distribuzione rapida/',
    price_monitoring_64: 'Distribuzione e scalabilità rapide (dal giorno 1)',
    price_monitoring_65: 'Tutti i dati confermati',
    price_monitoring_66: 'da foto e tagging GPS',
    price_monitoring_67: 'Abbiamo il numero necessario ',
    price_monitoring_68: 'di agenti sul campo in qualsiasi paese',
    price_monitoring_69: 'Possiamo lavorare con grande',
    price_monitoring_70: 'quantità di dati',
    price_monitoring_71: 'Monitoraggio dei singoli prezzi',
    price_monitoring_72: 'Se hai prodotti complessi, possiamo aiutarti a definire la tua strategia di prezzo',
    price_monitoring_73: 'Siamo in grado di monitorare i prezzi dei singoli concorrenti, ad esempio:',
    price_monitoring_74: 'Basso',
    price_monitoring_75: 'Costo',
    price_monitoring_76: 'alta efficienza',
    price_monitoring_77: 'alta efficienza',
    price_monitoring_78: 'Rapporto Excel',
    price_monitoring_79: 'Rapporto PPT',
    price_monitoring_80: 'Rapporto BI',
    price_monitoring_81: 'I dati sui prezzi della concorrenza ti aiutano a prendere le migliori decisioni sui prezzi.',
    price_monitoring_82: 'Forniamo un rapporto fotografico completo, un rapporto PPT, un rapporto Excel in termini di tipo di località.',
    qualitative_research_1: 'qualitative',
    qualitative_research_2: 'Ricerche',
    qualitative_research_3: 'Raccogliere, analizzare e interpretare i dati osservando le azioni e le parole dei tuoi clienti',
    qualitative_research_4: 'valori',
    qualitative_research_5: 'il comportamento',
    qualitative_research_6: 'sfondo',
    qualitative_research_7: 'Le aspettative',
    qualitative_research_8: "L'ambiente",
    qualitative_research_9: 'Paure/Barriere',
    qualitative_research_10: 'Qual è lo scopo della scelta del cliente?',
    qualitative_research_11: 'uomo con il telefono',
    qualitative_research_12: 'marchio',
    qualitative_research_13: 'categoria',
    qualitative_research_14: 'pubblicità',
    qualitative_research_15: 'Prodotto',
    qualitative_research_16: "L'unicità dei metodi qualitativi è che ci consentono di comprendere le cause e le motivazioni del comportamento del consumatore",
    qualitative_research_17: "Analisi dell'atteggiamento del consumatore nei confronti di un fenomeno particolare ",
    qualitative_research_18: 'Prospettive ed esperienze individuali',
    qualitative_research_19: 'Argomenti che non possono essere approfonditi attraverso i sondaggi',
    qualitative_research_20: 'Argomenti delicati',
    qualitative_research_21: 'Pubblico difficile da raggiungere',
    qualitative_research_22: 'Geografia complessa',
    qualitative_research_23: 'Interviste in profondità utili per:',
    qualitative_research_24: 'Interviste approfondite',
    qualitative_research_25: 'Un’intervista approfondita è una tecnica di ricerca qualitativa che prevede lo svolgimento di interviste individuali per esplorare le loro prospettive su una particolare idea, programma o situazione. Un’intervista approfondita esplora in dettaglio le esperienze /percezioni/pratiche individuali.',
    qualitative_research_26: 'Obiettivi:',
    qualitative_research_27: 'Caratterizzazione delle norme sociali e culturali',
    qualitative_research_28: 'Pubblico difficile da raggiungere',
    qualitative_research_29: 'Condivisione e confronto (Morgan) indagato attraverso sondaggi',
    qualitative_research_30: 'Argomenti che non possono essere approfonditi attraverso i sondaggi',
    qualitative_research_31: 'Focus group di approfondimento utili per:',
    qualitative_research_32: 'Focus group',
    qualitative_research_33: "Esplorazione profonda di incontri individuali, discussioni emergenti, discussioni tra manifestazioni sotto l'influenza del moderatore del gruppo.",
    qualitative_research_34: 'Generare approfondimenti su esperienze condivise e norme sociali attraverso discussioni di gruppo, dati narrativi in una discussione mirata.',
    qualitative_research_36: 'Benefits:',
    qualitative_research_37: 'Studiare lo stile di vita e il comportamento del consumatore in diverse condizioni reali (a casa, nel punto vendita, al lavoro, ecc.)',
    qualitative_research_38: 'Ricerca di approfondimenti per lo sviluppo di nuovi prodotti, packaging, miglioramento del servizio, ecc.',
    qualitative_research_39: 'In-depth ethnography research useful for:',
    qualitative_research_40: 'Ricerca etnografica',
    qualitative_research_41: "L'etnografia è un tipo di ricerca qualitativa che comporta il coinvolgimento in una particolare comunità o organizzazione per osservare da vicino il loro comportamento e le loro interazioni. ",
    qualitative_research_42: "L'etnografia è un metodo di ricerca flessibile che consente di acquisire una profonda comprensione della cultura, delle convenzioni e delle dinamiche sociali condivise di un gruppo. Tuttavia, comporta anche alcune sfide pratiche ed etiche.",
    qualitative_research_43: 'Metodi:',
    qualitative_research_44: "L'etnografia è uno studio del consumatore in condizioni di vita reale e della sua interazione con un prodotto/servizio.",
    qualitative_research_45: 'Vantaggi di lavorare con noi',
    qualitative_research_46: 'Possiamo trovare intervistati da qualsiasi pubblico di destinazione. E controlliamo la qualità del reclutamento',
    qualitative_research_47: 'Reclutamento di qualsiasi tipo di complessità',
    qualitative_research_48: 'I nostri moderatori sono multilingue e hanno capacità di agevolazioni',
    qualitative_research_49: 'Moderatori esperti',
    qualitative_research_50: 'Lavorare online/offline',
    qualitative_research_51: 'Forniamo registrazioni audio/video',
    qualitative_research_52: 'recordings',
    qualitative_research_53: 'Siamo in grado di condurre ricerche qualitative in qualsiasi località',
    quantitative_research_1: 'Studio della soddisfazione del cliente',
    quantitative_research_2: 'con un particolare prodotto o servizio (SCI, NPS)',
    quantitative_research_3: 'Condurre la segmentazione',
    quantitative_research_4: 'sondaggi su diversi mercati',
    quantitative_research_5: 'Ricerca di mercato',
    quantitative_research_6: "Gestione dell'NPS",
    quantitative_research_7: 'Analisi dei concorrenti',
    quantitative_research_8: 'Quantitative',
    quantitative_research_9: 'ricerche',
    quantitative_research_10: 'Metodologia di ricerca che utilizza per testare le teorie sugli atteggiamenti e i comportamenti delle persone sulla base di dati oggettivi, numerici e statistici',
    quantitative_research_11: 'anni',
    quantitative_research_12: 'di esperienza CX in tutto il mondo',
    quantitative_research_13: 'pannello',
    quantitative_research_14: 'degli intervistati',
    quantitative_research_15: 'Paesi',
    quantitative_research_16: 'gestiamo progetti in tutto il mondo',
    quantitative_research_17: 'Chi siamo',
    quantitative_research_18: '4Service è una CX holding globale di ricerche di marketing. Offriamo servizi di raccolta dati esperti per una grande varietà di aziende',
    quantitative_research_19: '4service By Numbers',
    quantitative_research_20: 'Assistita da computer',
    quantitative_research_21: 'Intervista web',
    quantitative_research_22: 'Una tecnica di questionario basata su Internet. Il rispondente completa un questionario elettronico senza intervistatore. Il questionario può essere inviato elettronicamente o pubblicato sul sito web.',
    quantitative_research_23: 'Computer Assisted Web Interviewing',
    quantitative_research_24: 'Nella maggior parte dei casi questo metodo è il più conveniente in termini finanziari e costi di tempo.',
    quantitative_research_25: 'this method is the most cost-effective in terms of material and time costs',
    quantitative_research_26: 'Questo sondaggio offre',
    quantitative_research_27: " l'opportunità di intervistare un pubblico difficile da raggiungere",
    quantitative_research_28: ' Offre ampie possibilità',
    quantitative_research_29: ' di dimostrazione di materiale video e audio e immagini',
    quantitative_research_30: 'Gli intervistati possono ',
    quantitative_research_31: 'essere localizzati ovunque nel mondo.',
    quantitative_research_32: 'Permette di indagare il comportamento specifico del pubblico di Internet.',
    quantitative_research_33: "Le I principali risultati sono accessibili dal cliente in tempo reale tramite un'interfaccia web.",
    quantitative_research_34: 'Idoneo per qualsiasi argomento o problema confidenziale, ',
    quantitative_research_35: 'sensibile e personale a cui gli intervistati potrebbero essere stati riluttanti a rispondere quando parlavano con un intervistatore',
    quantitative_research_36: '150 000+ pannello',
    quantitative_research_37: 'Abbiamo il nostro pannello di Intervistati in tutto il mondo.',
    quantitative_research_38: 'Lavoriamo con richieste impegnative.',
    quantitative_research_39: 'Utilizzando strumenti complementari (social media, pubblicità), possiamo trovare un pubblico specifico e a profilo sofisticato del rispondente.',
    quantitative_research_40: 'We motivate our respondents',
    quantitative_research_41: 'Abbiamo progettato un framework per incoraggiare il completamento di lungo sondaggi fornendo materiale incentive.',
    quantitative_research_42: 'Le interviste faccia a faccia',
    quantitative_research_43: 'Condurre conversazioni personali utilizzando un tablet.',
    quantitative_research_44: "Questo è uno dei principali metodi di raccolta di dati quantitativi, durante che l'intervistatore comunica con il rispondente direttamente a seguito di un questionario rigorosamente strutturato.",
    quantitative_research_45: 'Le interviste faccia a faccia/face-to-face, possono essere condotte presso il luogo degli intervistati di lavoro o residenza, in strada o presso sedi di vendita designate utilizzando un tablet.',
    quantitative_research_46: 'Interviste faccia a faccia condotte utilizzando il tablet',
    quantitative_research_47: 'Determinazione del livello ',
    quantitative_research_48: 'di popolarità e riconoscimento del marchio',
    quantitative_research_49: 'Segmentazione dei consumatori',
    quantitative_research_50: ' in base al loro comportamento di acquisto',
    quantitative_research_51: "Analisi dell'efficacia di una campagna pubblicitaria",
    quantitative_research_52: 'of the advertising campaign',
    quantitative_research_53: 'Misurazione della soddisfazione del cliente',
    quantitative_research_54: 'measurement',
    quantitative_research_55: 'Determinazione del miglior prezzo per il prodotto o il servizio',
    quantitative_research_56: 'for the product or service',
    quantitative_research_57: 'Analisi delle preferenze dei consumatori',
    quantitative_research_58: 'of consumer preferences',
    quantitative_research_59: 'Studio ',
    quantitative_research_60: 'di come i consumatori acquistano',
    quantitative_research_61: 'Valutazione ',
    quantitative_research_62: 'della capacità di mercato',
    quantitative_research_63: 'Software DigSi',
    quantitative_research_64: 'Usiamo un software speciale con registrazione audio e GPS',
    quantitative_research_65: 'Intervistatori e supervisori',
    quantitative_research_66: 'Possiamo condurre interviste in qualsiasi lingua e tradurre nella lingua preferita del cliente.',
    quantitative_research_67: 'Campionamento',
    quantitative_research_68: 'Il dipartimento analitico sviluppa una sistema per il reclutamento degli intervistati per ottenere i migliori risultati possibili.',
    quantitative_research_69: 'Assistita da computer',
    quantitative_research_70: 'Intervista telefonica',
    quantitative_research_71: 'Metodologia utilizzata per la raccolta di informazioni quantitative attraverso interviste telefoniche utilizzando un questionario chiaramente strutturato.',
    quantitative_research_72: 'Intervista telefonica assistita da computer',
    quantitative_research_73: 'Abbiamo i nostri call center',
    quantitative_research_74: 'Utilizziamo un software speciale con registrazione audio e GPS',
    quantitative_research_75: 'Operatori qualificati',
    quantitative_research_76: 'I nostri operatori parlano lingue diverse, sono formati e ben collaudati, e migliorano continuamente le loro capacità(skills).',
    quantitative_research_77: 'Banche dati',
    quantitative_research_78: 'Lavoriamo sia con il nostro databases che con la tua base di clienti',
    quantitative_research_79: 'SLA',
    quantitative_research_80: 'Garantiamo la qualità e firmiamo uno SLA (Service Level Agreement)',
    quantitative_research_81: 'Definizione degli obiettivi',
    quantitative_research_82: 'Definizione della metodologia',
    quantitative_research_83: 'Segmentazione e campionamento',
    quantitative_research_84: 'Definizione dei canali di raccolta dei dati',
    quantitative_research_85: 'Progettazione un questionario logico per il rispondente',
    quantitative_research_86: 'Processo di raccolta dei dati',
    quantitative_research_87: 'Garanzia di qualità',
    quantitative_research_88: 'Tecniche di analisi',
    quantitative_research_89: 'Elaborazione dati',
    quantitative_research_90: 'Progettazione del sondaggio',
    quantitative_research_91: 'Localizzazione GPS di intervistatore',
    quantitative_research_92: 'Verifica dei dati',
    quantitative_research_93: 'Programmazione e test del questionario',
    quantitative_research_94: 'Convalida dei dati',
    quantitative_research_95: 'Registrazioni audio mentre segui Requisiti GDPR',
    quantitative_research_96: 'Formazione e competenza di personale di ricerca',
    quantitative_research_97: 'Garanzia di qualità',
    quantitative_research_98: 'Analisi fattoriale',
    quantitative_research_99: 'Segmentazione',
    quantitative_research_100: 'Mappatura percettiva',
    quantitative_research_101: 'Correlazione',
    quantitative_research_102: 'Modello di conversione',
    quantitative_research_103: 'Jaccard',
    quantitative_research_104: 'Analisi di gruppo',
    quantitative_research_105: 'etc.',
    quantitative_research_106: 'Relazioni analitiche',
    quantitative_research_107: 'Utilizziamo SPSS ® e altri strumenti per costruire report analitici che utilizzano diverse metodologie',
    quantitative_research_108: 'What business challenges сan be solved:',
    quantitative_research_109: 'What business challenges сan be solved:',
    social_responsibility_1: "4Service Responsabilità sociale d'impresa.",
    social_responsibility_2: "Con il cuore e l'anima aperti di ogni membro del nostro team. cCerchiamo di mantenere una scintilla di gentilezza in ciascuno dei nostri dipendenti e di rendere il mondo un po' migliore.",
    social_responsibility_3: 'Attività educative/Sostegno ai giovani',
    social_responsibility_4: 'Le nostre iniziative di sensibilizzazione per bambini e studenti',
    social_responsibility_5: "Gioco d'affari Service Mania",
    social_responsibility_6: 'Una parte importante delle nostre attività di formazione è il business game Service Mania.',
    social_responsibility_7: 'Service Mania ti sfida a pensare strategicamente a come affrontare situazioni insolite.',
    social_responsibility_8: 'Presentazione della ricerca / Diiya. Attività commerciale(Business)',
    social_responsibility_9: 'Una prospettiva aziendale e del cliente sulla situazione del servizio: una presentazione dei risultati della  4Servizio i risultati della ricerca da parte di  Victoria Skorbota, responsabile dello sviluppo  di 4Service in Ucraina.',
    social_responsibility_10: 'Sessioni di Service Design',
    social_responsibility_11: 'La metodologia del Service Design è una parte piu importante di 4Service. Un paio di anni fa siamo diventati partner della DesignThinkers Academy. Non solo applichiamo le migliori pratiche nel nostro lavoro, ma forniamo anche corsi di formazione con migliori aziende',
    social_responsibility_12: 'Beneficenza / Volontariato',
    social_responsibility_13: "Orfanotrofi, case di cura per anziani e volontariato per l'esercito ucraino. La parte più toccante delle nostre attività",
    social_responsibility_14: 'Gruppo #HelpEasyWith4Service',
    social_responsibility_15: 'Lavoriamo in collaborazione con il  Fondo di beneficenza Blagomai e cerchiamo di portare  festa e aiuto a coloro che hanno   bisogno',
    social_responsibility_16: 'Raccolta fondi per orfanotrofi e case di riposo.',
    social_responsibility_17: "Abbiamo avviato diverse raccolte fondi per case di riposo e orfanotrofi per l'acquisto di beni di prima necessità.",
    social_responsibility_18: '#SaveUkraine',
    social_responsibility_19: 'La guerra ha colpito molti membri del nostro staff e amici intimi. Ognuno di noi aiuta come può.',
    social_responsibility_20: 'Benessere degli animali.',
    social_responsibility_21: 'Chiunque può venire nel nostro ufficio con il proprio animale domestico. Aiutiamo rifugi, raccogliamo fondi per il cibo e talvolta cerchiamo anche case per animali senzatetto.',
    social_responsibility_22: 'Sostenere i rifugi per animali.',
    social_responsibility_23: 'Gli animali senza casa hanno bisogno di aiuto  come tutti i membri vulnerabili della nostra  società.',
    social_responsibility_24: 'La nostra azienda ha preso in cura il Sirius Stray Animal Shelter (regione di Kiev, Ucraina)',
    social_responsibility_25: 'Iniziative creative per aiutare gli animali randagi.',
    social_responsibility_26: "Come possiamo portare l'urgente questione dei rifugi per animali all'attenzione dei nostri clienti e partner?",
    social_responsibility_27: 'Ufficio adatto agli animali domestici/Pet -friendly office',
    social_responsibility_28: 'Molti membri del nostro team hanno animali domestici. Abbiamo creato un ambiente sano in cui tutti possono venire in ufficio con i propri animali domestici e sentirsi a proprio agio.',
    ux_testing_1: 'Test UX',
    ux_testing_2: "Approccio umano e basato sull'intelligenza artificiale per testare e migliorare i vostri siti web e le vostre applicazioni",
    ux_testing_3: "Cos'è il test UX/Cos'è il test dell'esperienza utente",
    ux_testing_4: 'Il test UX ti aiuta a capire come le persone interagiscono con il tuo prodotto, app o sito web',
    ux_testing_5: 'La navigazione non è molto chiara',
    ux_testing_6: 'Non ho idea di come fare un ordine',
    ux_testing_7: 'Non ci sono abbastanza foto nel catalogo',
    ux_testing_8: 'Lo farai:',
    ux_testing_9: 'Scopri le opportunità per migliorare',
    ux_testing_10: "Identificare i problemi nella progettazione e nell'interazione con il cliente",
    ux_testing_11: "Scopri quali difficoltà incontra l'utente durante l'interazione con il sito",
    ux_testing_12: 'Osserva il percorso del cliente',
    ux_testing_13: 'Riconosci quando e perché il tuo pubblico di destinazione lascia il sito',
    ux_testing_14: 'Definisci quali sezioni suscitano il minimo e il massimo interesse ed emozione',
    ux_testing_15: "Test UX basato sull'utente",
    ux_testing_16: '4Service ti fornisce le vere intuizioni umane di cui hai bisogno per creare siti Web e app incentrati sul cliente utilizzando metodi qualitativi e quantitativi',
    ux_testing_17: 'Non capisco come usare il sito. Dove è nascosto il menu?',
    ux_testing_18: 'Penso che ci sia troppo spazio tra le lettere, il testo è illeggibile',
    ux_testing_19: 'Le immagini si sovrappongono. È così che dovrebbe essere?',
    ux_testing_20: 'Il test UX ti aiuta a capire come le persone interagiscono con il tuo prodotto, app o sito web',
    ux_testing_21: "Progettazione della ricerca di test UX basata sull'utente:",
    ux_testing_22: 'Scopri le opportunità per migliorare',
    ux_testing_23: 'Determinazione del tipo e del metodo di controllo (qualitativo/quantitativo)',
    ux_testing_24: 'Elaborazione di ipotesi e scenari utente',
    ux_testing_25: 'Analisi e refertazione dei risultati dei test',
    ux_testing_26: 'Due soluzioni',
    ux_testing_27: 'Offriamo due approcci per testare UX con successo',
    ux_testing_28: "Test UX basato sull'utente",
    ux_testing_29: 'AI + test UX basati su persone',
    ux_testing_30: 'I nostri punti di forza:',
    ux_testing_31: 'Possiamo analizzare il panorama della concorrenza',
    ux_testing_32: 'Possiamo coinvolgere esperti ristretti per condurre e partecipare alle interviste',
    ux_testing_33: 'Possiamo condurre interviste in diverse lingue e tradurle nella lingua richiesta',
    ux_testing_34: "con l'usabilità esistente",
    ux_testing_35: 'metriche o standard',
    ux_testing_36: 'la tua performance',
    ux_testing_37: 'contro un concorrente',
    ux_testing_38: 'Versione',
    ux_testing_39: 'lo stesso prodotto',
    ux_testing_40: 'Grado di soddisfazione',
    ux_testing_41: 'NPS',
    ux_testing_42: 'Tassi di successo',
    ux_testing_43: 'Tasso di errore',
    ux_testing_44: "Tempo di completamento dell'attività",
    ux_testing_45: "Test UX basato sull'utente",
    ux_testing_46: 'metodo quantitativo',
    ux_testing_47: 'Utilizziamo almeno 100 persone per partecipare ai test UX per questa fase',
    ux_testing_48: 'Rilevante quando hai bisogno di un confronto del tuo sito web o della tua app:',
    ux_testing_49: 'Misura metodo quantitativo:',
    ux_testing_50: 'Osservazione',
    ux_testing_51: 'Trascorrere del tempo con un utente o un gruppo di utenti e osservare il loro comportamento con il prodotto mentre lo usano nella vita quotidiana',
    ux_testing_52: 'intervista approfondita',
    ux_testing_53: 'Consentono di apprendere gli atteggiamenti, le convinzioni, i desideri e le esperienze degli utenti che visitano il tuo sito. Si consiglia di condurre 15-20 interviste',
    ux_testing_54: 'Ricerca etnografica',
    ux_testing_55: "Condotto nell'ambiente degli intervistati in cui utilizzeranno il prodotto. Osservando, inizi ad avere una buona comprensione della psicologia dell'utente, delle sfide emotive che deve affrontare.",
    ux_testing_56: "Test UX basato sull'utente",
    ux_testing_57: 'Metodo qualitativo',
    ux_testing_58: 'Questo metodo aiuta a comprendere le motivazioni e la logica del comportamento degli utenti',
    ux_testing_59: 'Noi usiamo:',
    ux_testing_60: 'Stiamo sviluppando una mappa del percorso del cliente per illustrare i risultati della nostra ricerca',
    ux_testing_61: '30 partecipanti (maschi/femmine)',
    ux_testing_62: 'Gli intervistati effettuano un pre-screening e li invitano a partecipare al sondaggio',
    ux_testing_63: 'Colloqui online a distanza sulla piattaforma Wantant',
    ux_testing_64: "AI + basato sull'essere umano",
    ux_testing_65: 'AI + test UX basati su persone',
    ux_testing_66: 'Una combinazione di tecnologia unica di intelligenza artificiale Wantant e utenti reali',
    ux_testing_67: "Il carattere di grandi dimensioni nel titolo ha attirato l'attenzione",
    ux_testing_68: 'Gli utenti guardano i numeri «ad alta voce» ma non leggono le informazioni accanto «г»',
    ux_testing_69: 'Il test UX ti aiuta a capire come le persone interagiscono con il tuo prodotto, app o sito web',
    ux_testing_70: "AI + progettazione della ricerca sui test basati sull'uomo",
    ux_testing_71: 'Come funziona',
    ux_testing_72: "Wantent è una soluzione adatta al mercato dei contenuti basata sull'intelligenza artificiale.",
    ux_testing_73: "Wantant stima l'efficienza di siti Web e app applicando tecnologie ML per analizzare le reazioni emotive e il coinvolgimento del pubblico.",
    ux_testing_74: 'Per interpretare i risultati, utilizziamo tecniche di storytelling, diversità ed empatia, per guidare le prestazioni e la crescita.',
    ux_testing_75: "L'attenzione e le emozioni dell'utente durante le transizioni attraverso le sezioni",
    ux_testing_76: 'Statistiche per gruppo di partecipanti (attenzione e concentrazione durante la sessione)',
    ux_testing_77: 'Analisi delle conversioni di pagina',
    ux_testing_78: "Attenzione ed emozioni durante l'esecuzione dei compiti",
    ux_testing_79: 'Risultati sulla percezione del sito Web da parte di diversi gruppi di partecipanti',
    ux_testing_80: 'of the website by different groups of participants',
    ux_testing_81: 'Analisi della facilità di navigazione tra le pagine e della facilità di reperimento di elementi importanti',
    ux_testing_82: 'of navigating through pages and the ease of finding important elements',
    ux_testing_83: "Un'analisi della percezione delle informazioni fornite sul sito web",
    ux_testing_84: 'of the information provided on the website',
    ux_testing_85: "Definizione aree di miglioramento all'interno delle singole pagine (navigazione, blocchi informativi, ecc.)",
    ux_testing_86: 'of improvement within individual pages (navigation, information blocks, etc.)',
    ux_testing_87: 'Analizziamo nel dettaglio',
    ux_testing_88: 'Risultati chiave della ricerca',
    ux_testing_89: 'Raccomandazioni per migliorare la struttura del sito web e delle singole pagine',
    ux_testing_90: 'Selezioniamo attentamente il pubblico di destinazione per i test di usabilità.',
    ux_testing_91: 'Conduciamo ricerche in diversi paesi, diverse lingue e tra diversi gruppi di utenti',
    ux_testing_92: 'Possiamo eseguire test nelle applicazioni',
    ux_testing_93: ' e sui siti Web della concorrenza e fornire analisi comparative',
    ux_testing_94: 'Combiniamo diversi metodi di ricerca.',
    ux_testing_95: 'I nostri facilitatori qualificati guidano il partecipante attraverso il processo di test.',
    ux_testing_96: 'Conduciamo test su una varietà di dispositivi e versioni software',
    ux_testing_97: 'on a variety of devices and software versions',
    ux_testing_98: 'Perché scegliere noi?',
    wantent_1: '4Service x',
    wantent_2: 'Wantent',
    wantent_3: "Unico progetto dei partner 4Setice e Wantent © Un'unica combinazione di intelligenza    artificiale e applicazione umana per l'analisi dei contenuti.",
    wantent_4: 'Misurare il successo prima che il contenuto venga trasmesso',
    wantent_5: 'Mitigare i rischi dei problemi di diversità',
    wantent_6: 'Approfondimenti e raccomandazioni per migliorare il contenuto',
    wantent_7: 'Decisioni oggettive sui contenuti e sulla strategia di marketing',
    wantent_8: "Wantent è una soluzione di content-market fit basata sull'intelligenza artificiale.",
    wantent_9: "Wantent è una soluzione basata sull'intelligenza artificiale per abbinare i contenuti al mercato. Wantent valuta l'efficacia dei contenuti video applicando tecnologie di ML per analizzare la risposta emotiva e il coinvolgimento del pubblico.",
    wantent_10: "Wantent aiuta a definire l'adattamento al mercato dei contenuti e a massimizzare l'efficienza dei contenuti mediante l'impostazione dello scenario e il reclutamento dei partecipanti",
    wantent_11: 'Scenario setup & participant recruitment',
    wantent_12: 'design personalizzato del progetto e pubblico in tutto il mondo',
    wantent_13: 'Raccogliere le reazioni degli spettatori',
    wantent_14: 'feedback dettagliato e chiaro da parte del pubblico',
    wantent_15: 'Analisi della percezione del contenuto',
    wantent_16: "analisi del comportamento e della risposta emotiva da parte dell'intelligenza artificiale",
    wantent_17: 'Fornitura di approfondimenti per il riepilogo finale',
    wantent_18: 'raccomandazioni per miglioramenti',
    wantent_19: "Wantent massimizza l'efficienza dei contenuti",
    wantent_20: 'Pre-test del concetto di annuncio',
    wantent_21: 'Test del concetto creativo',
    wantent_22: 'Verifica della coerenza della creatività futura con la strategia sviluppata e comprensione delle reazioni del pubblico target per eventuali aggiustamenti dei concetti/messaggi creativi.',
    wantent_23: 'Test di viewability dei brandelements (post-produzione)',
    wantent_24: "Conferma dell'efficacia dell'investimento pubblicitario/sponsorizzante",
    wantent_25: 'Test degli annunci',
    wantent_26: 'Test A/B',
    wantent_27: "Confrontare l'efficacia dei creativi e aumentare l'impegno e l'attenzione durante la fase di produzione",
    wantent_28: 'Avvio del test degli annunci',
    wantent_29: "Definizione delle prestazioni dei messaggi e creazione di raccomandazioni per migliorare l'audio e le immagini ",
    wantent_30: 'Soluzioni per la TV',
    wantent_31: 'Piloti, promo, nuovi format, test di presentatori televisivi',
    wantent_32: "Conduzione di un'analisi comparativa per individuare quello più coinvolgente e rafforzarlo",
    wantent_33: 'Test su programmi televisivi (TV show) e lungometraggi',
    wantent_34: 'Analizzare il coinvolgimento, il livello di attenzione e la risposta emotiva degli spettatori durante la visione di contenuti lunghi in condizioni naturali. Individuiamo automaticamente i picchi di ascolto e i momenti di distrazione per valutare e migliorare il montaggio del video.',
    wantent_35: 'Test UI/UX',
    wantent_36: ' Prototipi di siti web e test di applicazioni mobili.',
    wantent_37: 'Analisi della reazione e delle emozioni degli utenti utilizzando la tecnologia Wantant:',
    wantent_38: 'Livello di attenzione',
    wantent_39: 'Reazioni emotive',
    wantent_40: 'Heatmap della direzione dello sguardo dei partecipanti',
    wantent_41: "Analisi dell'usabilità di siti web e applicazioni sulla base del feedback   dei partecipanti:",
    wantent_42: 'Scala di usabilità del sistema (SUS)',
    wantent_43: ' Punteggio dello sforzo del cliente (facilità di    interazione)',
    wantent_44: 'Net Promotion Score (NPS)',
    wantent_45: 'Conformità al GDPR e privacy degli utenti',
    wantent_46: 'Wantent è pienamente conforme alle politiche GDPR e CCPA e soddisfa i principi di raccolta, archiviazione, elaborazione e protezione dei dati personali dei partecipanti',
    wantent_47: 'Nikita Lobyntsev',
    wantent_48: 'СDO, Reface (Media e Intrattenimento)',
    wantent_49: 'Ogni giorno sperimentiamo tonnellate di nuove idee di contenuto con tecnologie di apprendimento automatico. Wantent ci aiuta a comprendere e valutare rischi e vantaggi, scoprire il feedback, il comportamento e la percezione degli utenti per ottenere la causalità dei diversi aspetti delle nostre soluzioni.',
    wantent_50: 'Alexander Smirnov',
    wantent_51: 'comproprietario TABASCO (Pubblicità)',
    wantent_52: "Wantent offre un servizio davvero unico che ci aiuta a conoscere dettagli molto piccoli su ciò che piace e non piace ai consumatori. Non c'è modo di imbrogliare, non c'è modo di mentire - Wantent conosce sempre la verità, e con la presente noi esperti di marketing otteniamo le linee guida inestimabili (hahaha - conosciamo il prezzo esatto ed è conveniente) su come migliorare le nostre comunicazioni.",
    wantent_53: 'Reviews',
    terms_1: 'Terms & conditions',
    terms_2: 'Accordo sui termini di utilizzo',
    terms_3: "Si prega di leggere attentamente le presenti condizioni d'uso («condizioni d'uso», «accordo») prima di utilizzare il sito web",
    terms_4: '(«sito web») gestito da 4Service Holdings GmbH («4Service», «noi»,«noi», «nostro»). ',
    terms_5: "Condizioni d'uso Utilizzando questo sito Web, l'utente certifica di aver letto e rivisto il presente Accordo e di accettare di rispettarne i termini. Se non si desidera essere vincolati dai termini del presente Accordo, si consiglia di lasciare il sito Web di conseguenza. 4Service concede l'uso e l'accesso a questo sito Web, ai suoi prodotti e ai suoi servizi solo a coloro che ne hanno accettato i termini.",
    terms_6: 'Politica sulla privacy',
    terms_7: "Prima di continuare a utilizzare il nostro sito Web, ti consigliamo di leggere la nostra Informativa sulla privacy relativa alla raccolta dei dati dell'utente. Ti aiuterà a comprendere meglio le nostre pratiche.",
    terms_8: 'Limite di età',
    terms_9: 'Devi avere almeno 16 (sedici) anni prima di poter utilizzare questo sito web. Utilizzando questo sito Web, garantisci di avere almeno 16 (sedici) anni e di poter aderire legalmente al presente Accordo. 4Service non si assume alcuna responsabilità per responsabilità relative alla falsa dichiarazione di età.',
    terms_10: 'La proprietà intellettuale',
    terms_11: "Accetti che tutti i materiali, i prodotti e i servizi forniti su questo sito Web siano di proprietà di 4Service, dei suoi affiliati, direttori, funzionari, dipendenti, agenti, fornitori o licenziatari, inclusi tutti i diritti d'autore, i segreti commerciali, i marchi, i brevetti e altre proprietà intellettuali.  Accetti inoltre di non riprodurre o ridistribuire la proprietà intellettuale di 4Service in alcun modo, incluse registrazioni elettroniche, digitali o di nuovi marchi.",
    terms_12: 'Accesso al nostro sito web',
    terms_13: "L'accesso al nostro sito è consentito su base temporanea e ci riserviamo il diritto di ritirare o modificare il servizio che forniamo sul nostro sito senza preavviso. Non saremo responsabili se per qualsiasi motivo il nostro sito non è disponibile in qualsiasi momento o per qualsiasi periodo. Di volta in volta, potremmo limitare l'accesso ad alcune parti del nostro sito, o al nostro intero sito, agli utenti che si sono registrati con noi. Sei responsabile di prendere tutte le disposizioni necessarie per avere accesso al nostro sito, incluso l'uso di apparecchiature compatibili con il nostro sito. Sei inoltre responsabile di garantire che tutte le persone che accedono al nostro sito tramite la tua connessione Internet siano a conoscenza di questi termini e che li rispettino.",
    terms_14: 'Legge applicabile',
    terms_15: "Visitando questo sito Web, accetti che le leggi della Repubblica d'Austria, senza riguardo ai principi delle leggi sui conflitti, regoleranno questi termini e condizioni, o qualsiasi controversia di qualsiasi tipo che potrebbe sorgere tra 4Service e te.",
    terms_16: 'Controversie',
    terms_17: 'Qualsiasi controversia relativa in qualsiasi modo alla tua visita a questo sito Web sarà arbitrata dal tribunale commerciale di Vienna.',
    terms_18: 'Indennizzo',
    terms_19: 'Accetti di indennizzare 4Service e le sue affiliate e di tenere 4Service indenne da rivendicazioni e richieste legali che potrebbero derivare dal tuo uso o uso improprio del nostro sito web. Ci riserviamo il diritto di selezionare il nostro consulente legale.',
    terms_20: 'Il periodo di conservazione',
    terms_21: "Tenuto conto delle finalità del trattamento, il periodo di conservazione dei dati personali degli Utenti (periodo di conservazione) è di 24 mesi dalla data in cui è stato regolarmente ottenuto il consenso al trattamento dei dati da parte dell'Utente.",
    terms_22: 'Limitazione di responsabilità',
    terms_23: "4Service non è responsabile per eventuali danni che potrebbero verificarsi a seguito dell'uso improprio del nostro sito Web. 4Service si riserva il diritto, senza preavviso, di modificare, modificare e cambiare il presente Accordo in qualsiasi momento aggiornando questa pubblicazione. L'uso continuato del sito Web ora, o dopo la pubblicazione di eventuali cambiamenti o modifiche, indicherà l'accettazione da parte dell'utente di tali cambiamenti o modifiche. Se qualsiasi parte di questo accordo è dichiarata illegale, nulla o inapplicabile, quella parte sarà considerata separabile e non pregiudicherà la validità e l'applicabilità delle restanti disposizioni. Eventuali prove dell'uso del sito Web per scopi illegali saranno fornite alle autorità preposte all'applicazione della legge. Il presente Accordo costituisce un'intesa tra 4Service e l'utente.",
    terms_24: 'Si prega di indirizzare tutte le domande e i dubbi relativi alla privacy/utilizzo al seguente indirizzo:',
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Tegetthoffstrasse 7',
    terms_27: '1010 Vienna',
    terms_28: 'Austria',
    terms_29: 'Recensione',
    terms_30: 'Quando i dati personali che ci fornisci non sono inesatti, hai il diritto di chiederci di correggerli (articolo 16 del GDPR).',
    thank_you_1: 'Grazie!',
    thank_you_2: 'Ti ricontatteremo entro 24 ore',
    thank_you_3: "I nostri responsabili stanno già esaminando la tua richiesta per mettere insieme un'offerta migliore",
    thank_you_4: 'Menu',
    thank_you_5: 'Woman typing',
    privacy_policy_1: 'Modulo di informativa privacy per gli interessati (utenti del sito)',
    privacy_policy_2: 'Il presente Modulo di informativa sulla privacy (Informativa sulla privacy) è destinato alle persone fisiche (Interessati) i cui dati personali sono raccolti da 4Service Holdings GmbH, in linea con i requisiti del GDPR in occasione della visita del sito web',
    privacy_policy_3: '(di seguito, rispettivamente, “Sito” e “Utenti/Utenti del Sito”).',
    privacy_policy_4: "Il modulo di informativa sulla privacy per gli interessati (utenti del sito Web) di 4Service Holdings GmbH è applicato in tutte le società del gruppo 4Service, che sono sotto l'autorità legale sotto la supervisione o il controllo di 4Service Holdings GmbH.",
    privacy_policy_5: 'Contatti',
    privacy_policy_6: '4Service Holding GmbH',
    privacy_policy_7: 'azienda/noi/noi),',
    privacy_policy_8: 'Indirizzo',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
    privacy_policy_10: 'E- mail :',
    privacy_policy_12: 'Chi siamo',
    privacy_policy_13: 'Un individuo (Lei) che visita il sito Web per qualsiasi scopo. Ai fini del presente Documento Lei sarà indicato anche come Utente.',
    privacy_policy_14: 'Dati personali che raccogliamo da te',
    privacy_policy_15: "Quando accedi al nostro sito Web, ti inviamo un'informativa sulla privacy, in cui ti offriamo di chiederti di fornirci un consenso per il trattamento delle seguenti informazioni:",
    privacy_policy_16: 'Indirizzo IP, nome utente, cognome, indirizzo, numero di telefono (fisso o mobile), indirizzo e-mail, indirizzo, ragione sociale, nazione, indirizzo e-mail, nome, cognome, numero di telefono, provincia, stato e CAP , dati di utilizzo, dati di interazione con social network o piattaforme esterne, informazioni sulla registrazione e autenticazione sul sito',
    privacy_policy_17: 'la posizione geografica',
    privacy_policy_18: "Nel caso in cui l'utente esprima la propria volontà e ci fornisca tale consenso, inizieremo a trattare tali informazioni da parte dell'utente.",
    privacy_policy_19: 'Liceità (consenso)',
    privacy_policy_20: "Quando accedi al nostro sito Web, ti inviamo un'informativa sulla privacy, in cui ti chiediamo di fornirci un consenso per il trattamento delle seguenti informazioni:",
    privacy_policy_21: 'La base giuridica per il trattamento dei dati personali è il consenso, che otteniamo da Lei attraverso la compilazione del modulo di consenso al seguente link:',
    privacy_policy_22: 'Se compili il modulo di consenso, significa che comprendi e accetti tutte le condizioni specificate nella presente Informativa sulla privacy.',
    privacy_policy_23: 'Revoca del consenso',
    privacy_policy_24: "L'utente ha il diritto di revocare in qualsiasi momento il consenso che ci ha fornito in precedenza. La revoca del consenso non pregiudica la liceità del trattamento basato sul consenso prima della sua revoca. Potete revocare il vostro consenso inviandoci l'apposita richiesta al seguente indirizzo e-mail withdraw@4service-group.com, il cui modulo è disponibile al seguente link:",
    privacy_policy_25: "Modulo di recesso per l'utente",
    privacy_policy_26: 'Finalità del trattamento',
    privacy_policy_27: 'Elaboriamo i tuoi dati personali per i seguenti scopi:',
    privacy_policy_28: "– migliorare i servizi ai clienti (Permette una risposta più efficace alle richieste dei clienti); – personalizzazione dell'esperienza degli Utenti. Permette di determinare chi è più interessante nei servizi); – migliorare il Sito. (Permette di migliorare la qualità dei prodotti e dei servizi, la comodità del loro utilizzo, sviluppare nuovi Servizi migliorare i nostri prodotti e servizi); – comunicare con l'Utente con newsletter, materiale di marketing o promozionale e altre informazioni che includono le nostre notizie, aggiornamenti, informazioni sui servizi con il commento sull'istruzione su come rifiutare di ricevere e-mail di follow-up. – effettuare ricerche e analisi statistiche e di altro genere sulla base di dati anonimizzati. – fornire servizi personalizzati all'Utente ed eseguire accordi e contratti; – partecipare dell'Utente a vari progetti da noi implementati attraverso il Sito, rispondendo alle domande rivolte dall'Utente attraverso il Sito, ricerca, mantenimento di conti e registri e promozione di servizi.",
    privacy_policy_29: 'Soggetti a cui possono essere comunicati i dati personali',
    privacy_policy_30: "Durante l'elaborazione dei tuoi dati personali, trasferiamo i tuoi dati personali alle entità che agiscono come responsabili del trattamento della Società. I responsabili del trattamento della Società agiscono esclusivamente sulla base delle istruzioni della Società. Le specifiche delle azioni condotte dai responsabili del trattamento della Società e l'elenco di tali responsabili sono disponibili nella Politica sulla privacy e sulla protezione dei dati al seguente collegamento: Politica sulla privacy e sulla protezione dei dati",
    privacy_policy_31: 'Paesi verso i quali i dati personali possono essere trasferiti',
    privacy_policy_32: 'La Società trasferisce i tuoi dati personali sulla base di una decisione di adeguatezza come previsto dal GDPR e dalla Commissione Europea. Ulteriori informazioni sul trasferimento dei dati personali negli Stati Uniti sono disponibili nella Privacy and Data Protection Policy al seguente link: Privacy and Data Protection Policy.',
    privacy_policy_33: 'Il periodo di conservazione',
    privacy_policy_34: "Tenuto conto delle finalità del trattamento, il periodo di conservazione dei dati personali degli Utenti (periodo di conservazione) è di 24 mesi dalla data in cui è stato regolarmente ottenuto il consenso al trattamento dei dati da parte dell'Utente.",
    privacy_policy_35: 'Il diritto di accesso',
    privacy_policy_36: "Hai il diritto di sapere se i dati personali che ti riguardano sono in fase di elaborazione e 2) in tal caso, accedere a tali dati con un sacco di disposizioni aggiuntive stabilite nell'articolo 15 del GDPR.",
    privacy_policy_37: 'Diritto alla rettifica',
    privacy_policy_38: "Se i dati personali forniti dall'utente non sono inesatti, l'utente ha il diritto di chiederne la correzione (articolo 16 del GDPR).",
    privacy_policy_39: "Diritto alla cancellazione (diritto all'oblio)",
    privacy_policy_40: "Avete il diritto di ottenere da noi la cancellazione dei vostri dati personali senza ritardi ingiustificati e noi avremo l'obbligo di cancellare i vostri dati personali senza ritardi ingiustificati se si applicano i motivi di cui all'articolo 17 del GDPR.",
    privacy_policy_41: 'Diritto alla limitazione del trattamento',
    privacy_policy_42: "Avete il diritto di limitare il trattamento dei vostri dati personali con diverse eccezioni nell'ambito del GDPR, in particolare ai sensi dell'articolo 18 del GDPR.",
    privacy_policy_43: 'Siamo obbligati a informarti su quali dati vengono raccolti, come vengono utilizzati, per quanto tempo saranno conservati e se saranno condivisi con terze parti. Tali informazioni devono essere comunicate in modo conciso e con un linguaggio semplice.',
    privacy_policy_44: 'Diritto alla portabilità dei dati',
    privacy_policy_45: "L'utente ha il diritto di ottenere e riutilizzare i propri dati personali per i propri scopi in diversi servizi.",
    privacy_policy_46: 'Diritto di opposizione',
    privacy_policy_47: "Avete il diritto di opporvi al trattamento dei dati personali che vengono elaborati dalla Società. Dobbiamo interrompere l'elaborazione dei dati personali a meno che non dimostriamo motivi legittimi impellenti per l'elaborazione che prevalgono sugli interessi, i diritti e le libertà dell'individuo o se l'elaborazione è per l'istituzione o l'esercizio della difesa di rivendicazioni legali.",
    privacy_policy_48: 'Diritto di non essere sottoposto a una decisione basata esclusivamente sul trattamento automatizzato',
    privacy_policy_49: "Avete il diritto di opporti a qualsiasi profilazione automatizzata che si verifica senza consenso. Con la presente, l'utente ha il diritto che i propri dati personali siano trattati con il coinvolgimento umano.",
    privacy_policy_50: 'Denunce, contestazioni',
    privacy_policy_51: "Nel caso in cui desideriate presentare un reclamo in merito al trattamento dei vostri dati personali da parte della Società (o dei responsabili del trattamento, descritti in precedenza), o al modo in cui il vostro reclamo è stato gestito, avete il diritto di presentare un reclamo direttamente all'autorità di controllo e alla Società.",
    privacy_policy_52: 'I dettagli di ognuno di questi contatti sono:',
    privacy_policy_53: 'Autorità di vigilanza:',
    privacy_policy_54: "L'Autorità austriaca per la protezione dei dati personali",
    privacy_policy_55: 'Österreichische Datenschutzbehörde',
    privacy_policy_56: 'Wickenburggasse 8',
    privacy_policy_57: '1080 Vienna',
    privacy_policy_58: 'Austria / Europe',
    privacy_policy_59: 'Azienda:',
    privacy_policy_60: '4Service Holdings GmbH (Società/noi/noi),',
    privacy_policy_61: 'Indirizzo: Tegetthoffstraße 7, 1010 Vienna, Austria.',
    privacy_policy_62: 'E-mail:',
    privacy_policy_63: 'Politica sulla privacy e protezione dei dati',
    privacy_policy_64: 'Maggiori informazioni su come e perché utilizziamo i tuoi dati qui: Informativa sulla privacy e sulla protezione dei dati',
    privacy_policy_65: 'Approvazione',
    privacy_policy_66: "La Società ha sviluppato tutti i documenti interni per definire i ruoli tra il personale in merito al trattamento dei dati personali all'interno della Società, in particolare responsabile dell'approvazione e del riesame della legittimità di questo documento è l'Amministratore Delegato.",
    contacts_1: 'Per i clienti',
    contacts_2: 'Per tutti i tipi di aziende, agenzie di ricerche di mercato e grandi aziende, partner commerciali e media.',
    contacts_3: 'Tegetthoffstrasse 7, Vienna, Austria',
    contacts_4: 'Per gli acquirenti',
    contacts_5: 'Gente del posto e viaggiatori, autisti e studenti, mystery shopper professionisti e homebodies.',
    contacts_6: 'Contattaci dal tuo paese:',
    contacts_7: 'Italia',
    contacts_8: "Stati Uniti d'America",
    contacts_9: 'UK',
    contacts_10: 'Svizzera',
    contacts_11: 'Paesi Bassi/Olanda',
    contacts_12: 'Slovacchia',
    contacts_13: 'Slovenia',
    contacts_14: 'Romania',
    contacts_15: 'AZ',
    contacts_16: 'Ucraina',
    contacts_17: 'Kazakistan',
    contacts_18: 'Resto del mondo',
    menu_1: 'CJM',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'BHT',
    menu_5: "Test dell'esperienza utente",
    menu_6: 'Ricerca sulle risorse umane',
    menu_7: 'Ricerca qualitativa',
    menu_8: 'Ricerca quantitativa',
    menu_9: 'Mystery shopping',
    menu_10: 'Voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Monitoraggio dei prezzi',
    menu_14: 'Chi siamo',
    menu_15: 'Contatti',
    menu_16: 'Blog',
    menu_17: 'Metodi',
    menu_18: 'Prodotti',
    menu_19: 'Shoppers \n platform',
    menu_20: 'CSR',
    nps_passives: 'Passivi',
    nps_detractors: 'Detrattori',
    nps_promoters: 'Promotori',
    nps_a_score: 'un punteggio',
    area: 'Area',
    products_tabs_1: 'Al fine di ottenere la massima efficienza, eseguiamo anche in complesso:',
    products_tabs_2: 'Prodotti',
    products_tabs_3: 'La ricerca qualitativa raccoglie e analizza dati non numerici per comprendere concetti, opinioni o esperienze',
    products_tabs_4: "I metodi di ricerca quantitativi sono una misurazione oggettiva e un'analisi numerica dei dati raccolti attraverso sondaggi, questionari e sondaggi",
    our_clients_1: 'I nostri clienti',
    preview_cases_section_1: 'Casi',
    preview_cases_section_2: 'Vai ai casi per leggere post più interessanti',
    blog_1: 'Blog con contenuti interessanti',
    blog_2: 'Nessun post disponibile per la tua richiesta.',
    about: 'Circa',
    why_us: 'Perche noi',
    about_us: 'Chi siamo',
    key_features: 'Funzionalità chiave',
    solutions: 'Soluzione ',
    monitoring: 'Monitoraggio ',
    how: 'Come ',
    how_it_works: 'Come funziona',
    when_to_use: 'Quando usare',
    mystery_shopping: 'Mystery shopping',
    voicer: 'Voicer',
    wantent: 'Wantent',
    play4sales: 'Play4Sales',
    price_monitoring: 'Monitoraggio dei prezzi',
    service_design: 'Progettazione del servizio',
    qualitative_research: 'Ricerca qualitativa',
    quantitative_research: 'Ricerca quantitativa',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Settore automobilistico',
    hashtag_retayl: 'Vendita al dettaglio,',
    hashtag_all_posts: '#Tutti_i post',
    hashtag_feedback: '#feedback',
    hashtag_4Service_csr: '#4Servizio_CSR',
    hashtag_cases: '#Casi',
    hashtag_market_research: '#ricerca di mercato',
    more: 'Di più',
    speak_to_an_expert: 'Parlare con un esperto',
    book_consultancy: 'Ordina una consulenza',
    read_all: 'Leggi tutto',
    find_out_more: 'Scopri di più',
    address: 'Indirizzo ',
    mon_fri: 'dal lunedì al venerdì/lun-ven',
    phone_number: 'Numero di telefono',
    flag: 'bandiera',
    scroll_to_left: 'scorrere verso sinistra',
    online: 'online',
    tel: 'Telefono',
    email: 'Email',
    light_it_up: 'Accendo',
    about_us_60: 'Alina Andreieva',
    about_us_61: 'Direttore dello sviluppo commerciale',
    about_us_62: 'Julia Kravchenko',
    about_us_63: 'Senior Project Manager',
    about_us_64: 'Olga Aksonova',
    about_us_65: 'Responsabile dello sviluppo aziendale',
    about_us_66: 'Zhelevskiy Dmitriy',
    about_us_67: 'Direttore delle operazioni',
    about_us_roles_1: 'Gestione',
    about_us_roles_2: 'Squadra Globale',
    about_us_roles_3: 'Proprietari del prodotto',
    ad_testing_1: 'Test degli annunci',
    ad_testing_2: 'Una campagna pubblicitaria è un enorme investimento.Assicurati che i tuoi annunci siano efficaci e forniscano il massimo ROI ',
    ad_testing_3: 'bloccò ',
    ad_testing_4: 'Industrie che serviamo',
    ad_testing_5: '4Service è una società globale di ricerca sulla customer experience',
    ad_testing_6: "4Service è ricerca di mercato globale e holding CX. Offriamo servizi di raccolta dati esperti a un'ampia varietà di aziende",
    ad_testing_7: 'Cash & Carry',
    ad_testing_8: 'Vendita al dettaglio',
    ad_testing_9: 'Elettronica ',
    ad_testing_10: 'Lusso/& Premium',
    ad_testing_11: 'Ristorazione e ospitalità',
    ad_testing_12: 'Settore automobilistico',
    ad_testing_13: 'Viaggi & Tempo libero',
    ad_testing_14: 'Moda',
    ad_testing_15: 'Banche',
    ad_testing_16: 'Orologi e gioielli',
    ad_testing_17: 'Casalinghi ed elettrodomestici',
    ad_testing_18: 'Membro di Esomar',
    ad_testing_19: 'Abbiamo condotto ricerche di Ad Testing sulla pubblicità per varie aziende in diverse aree',
    ad_testing_20: 'Hai bisogno di Ad Testing( test degli annunci) se sei',
    ad_testing_21: 'Promuovere un nuovo prodotto o servizio',
    ad_testing_22: 'Toccando un argomento delicato',
    ad_testing_23: 'Cercando di raggiungere un nuovo target demografico o di mercato',
    ad_testing_24: 'Discutere diverse opzioni di design',
    ad_testing_25: 'Hai bisogno di una prova di concetto per i team di gestione o gli investitori',
    ad_testing_26: 'Riceverai risposte alle domande',
    ad_testing_27: 'Quali canali pubblicitari sono più adatti a questi annunci?',
    ad_testing_28: 'Stiamo usando gli elementi visivi giusti per trasmettere il messaggio?',
    ad_testing_29: 'I tuoi testi e messaggi pubblicitari risuonano?',
    ad_testing_30: 'Quali canali pubblicitari sono più adatti a questi annunci?',
    ad_testing_31: 'Che cosa attira la loro attenzione in primo luogo?',
    ad_testing_32: 'Is the placement and layout of the ad easy to understand?',
    ad_testing_33: 'Valutiamo',
    ad_testing_34: 'Marchiò ',
    ad_testing_35: 'come il tuo marchio è legato alla pubblicità e quanto verrà ricordato',
    ad_testing_36: 'Chiave metrica',
    ad_testing_37: 'Integrazione',
    ad_testing_38: 'Riconoscimento',
    ad_testing_39: 'Adatto',
    ad_testing_40: 'Creativo',
    ad_testing_41: "In che modo la tua pubblicità si distingue e attira l'attenzione delle persone",
    ad_testing_42: 'Chiave metrica',
    ad_testing_43: 'Riconoscimento',
    ad_testing_44: 'MI piace',
    ad_testing_45: 'MTO',
    ad_testing_46: 'Motivazioni ',
    ad_testing_47: 'Esperienza del cliente, coinvolgimento emotivo',
    ad_testing_48: "Con l'uso della tecnologia dell'intelligenza artificiale e di analisti esperti, analizziamo le reali emozioni delle persone e otteniamo approfondimenti sulle loro percezioni della pubblicità",
    ad_testing_49: 'Valutazione',
    ad_testing_50: 'Maschili ',
    ad_testing_51: 'Femminile ',
    ad_testing_52: 'Coinvolgimento alto',
    ad_testing_53: 'Attenzioni ',
    ad_testing_54: 'Cosa è piaciuto e cosa ricorda il pubblico?',
    ad_testing_55: 'Attrice',
    ad_testing_56: 'Uguaglianza',
    ad_testing_57: 'sfondo musicale',
    ad_testing_58: 'Emozioni positive',
    ad_testing_59: 'Persone di diverse razze e aspetto',
    ad_testing_60: "Scatti all'aperto",
    ad_testing_61: 'What did the audienceне NOT like and recall?',
    ad_testing_62: 'La voce di chi parla non corrispondeva a ciò che stava accadendo sullo schermo',
    ad_testing_63: 'Scarsa attenzione al prodotto',
    ad_testing_64: 'troppo dinamico',
    ad_testing_65: 'Difficile scendere a compromessi',
    ad_testing_66: 'TRP',
    ad_testing_67: 'impressioni',
    ad_testing_68: 'Spettacoli',
    ad_testing_69: 'Conversione',
    ad_testing_70: 'Media',
    ad_testing_71: "Misuriamo l'efficacia di una campagna pubblicitaria in concomitanza con l'attività mediatica, calcolando l'impatto degli investimenti mediatici sui diversi canali sui risultati monitorati.",
    ad_testing_72: 'Conduciamo ricerche pubblicitarie in qualsiasi formato, piattaforma o canale',
    ad_testing_73: 'contenuti video',
    ad_testing_74: 'esterno',
    ad_testing_75: 'annunci televisivi(TV)',
    ad_testing_76: 'stampa',
    ad_testing_77: 'digitale',
    ad_testing_78: 'campagne pubblicitarie',
    ad_testing_79: 'Esposizione e pubblicità POS',
    ad_testing_80: 'animazioni',
    ad_testing_81: 'Con il nostro aiuto, puoi testare la tua pubblicità in qualsiasi momento',
    ad_testing_82: 'Concetto',
    ad_testing_83: 'quali idee hanno una capacità maggiore?',
    ad_testing_84: 'Produzione in fase iniziale',
    ad_testing_85: 'quali animazioni andranno in produzione?',
    ad_testing_86: 'Produzione ',
    ad_testing_87: "come modificare un video in modo che mantenga l'attenzione?",
    ad_testing_88: 'Pre-media',
    ad_testing_89: 'quali canali di comunicazione funzioneranno e quale risultato ci si può aspettare?',
    ad_testing_90: "Post-test dell'annuncio",
    ad_testing_91: 'Produzione ',
    ad_testing_92: "la mia pubblicità è accattivante? L'efficacia della creatività diminuisce nel tempo?",
    ad_testing_93: 'Pre-media',
    ad_testing_94: 'come si confronta con la concorrenza?',
    ad_testing_95: 'Pre-test degli annunci',
    ad_testing_96: 'Per fornirti i dati di alta qualità, utilizziamo',
    ad_testing_97: 'Metodologia di ricerca quantitativa',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'Metodologia della ricerca qualitativa',
    ad_testing_100: 'Focus Groups',
    ad_testing_101: 'intelligenza artificiale(AI)',
    ad_testing_102: 'Wantent',
    ad_testing_103: 'Rapporti analitici che ricevi',
    ad_testing_104: 'Il nostro team di analisti, esperti di media, esperti di marketing preparerà e presenterà un rapporto su misura con consigli dettagliati',
    ad_testing_age: 'Eta',
    ad_testing_ad: 'Pubblicità',
    automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
    automotive_industry_price_analysis_2: 'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
    automotive_industry_price_analysis_3: 'Request a quote',
    automotive_industry_price_analysis_4: 'Our methodology',
    automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
    automotive_industry_price_analysis_6: 'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
    automotive_industry_price_analysis_7: 'We offer precise figures and dependable multilateral data',
    automotive_industry_price_analysis_8: 'We have global coverage spanning every country in the world',
    automotive_industry_price_analysis_9: 'Our in-house resources include mystery shoppers and auditors',
    automotive_industry_price_analysis_10: 'We validate the quality of our data',
    automotive_industry_price_analysis_11: 'Our advantages:',
    automotive_industry_price_analysis_12: 'Price monitoring for specific competitive models (B2B and B2C)',
    automotive_industry_price_analysis_13: 'Monitoring the level of discount',
    automotive_industry_price_analysis_14: 'Monitoring retailer stock for your brand and competitors',
    automotive_industry_price_analysis_15: 'Monitoring e-commerce automotive prices',
    automotive_industry_price_analysis_16: 'Price communication analysis',
    automotive_industry_price_analysis_17: 'Keeping track of retailer shipping charges',
    automotive_industry_price_analysis_18: 'Monitoring promotions and sales at retailers',
    automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
    automotive_industry_price_analysis_20: 'Calculating lease payments',
    automotive_industry_price_analysis_21: 'Analyzing specific pricing considerations for electric vehicles (EVs)',
    automotive_industry_price_analysis_22: 'Conducting direct-to-consumer versus retailer price comparisons',
    automotive_industry_price_analysis_23: 'Developing subscription pricing models',
    automotive_industry_price_analysis_24: '4Service is a global customer experience (CX) holding company with over 20 years of experience',
    automotive_industry_price_analysis_25: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_26: "4Service's analysis of prices in the automotive industry includes:",
    automotive_industry_price_analysis_27: "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
    automotive_industry_price_analysis_28: 'of customers understand that prices are increasing across all brands',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'of dealers mentioned the possibility of price increases during the communication/selling process',
    automotive_industry_price_analysis_35: '47.6',
    automotive_industry_price_analysis_36: 'Key figures',
    automotive_industry_price_analysis_37: "You'll figure out how to price your product relative to other vehicles in the lineup.",
    automotive_industry_price_analysis_38: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_39: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
    automotive_industry_price_analysis_40: "You'll be able to determine the appropriate monthly payment and leasing rates.",
    automotive_industry_price_analysis_41: 'What benefits for your brand or dealer will you gain:',
    automotive_industry_price_analysis_42: 'You submit an application',
    automotive_industry_price_analysis_43: 'Our manager contacts you to determine the main objectives',
    automotive_industry_price_analysis_44: 'We conduct a marketing research',
    automotive_industry_price_analysis_45: 'We regularly provide you with reports and recommendations in an online format',
    automotive_industry_price_analysis_46: 'Car with sale sticker',
    automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
    automotive_industry_price_analysis_48: 'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
  },
  ro: {
    form_title: 'Vă vom contacta în scurt timp',
    form_required: 'Câmpurile marcate cu * sunt obligatorii',
    form_name_placeholder: 'Nume',
    form_email_placeholder: 'E-mail',
    form_phone_placeholder: 'Număr de telefon',
    form_company_placeholder: 'Companie',
    form_submit: 'Trimite',
    form_1: 'Îmi dau consimțământul pentru prelucrarea datelor cu caracter personal conform cu',
    form_2: 'Politica de confidențialitate',
    validation_privacy_policy: 'Vă rugăm să acceptați Politica de confidențialitate',
    validation_phone: 'Numărul de telefon nu este valid',
    validation_email: 'format de email invalid',
    validation_required: 'Câmpurile obligatorii nu sunt completate',
    submit_error: 'A apărut o eroare la salvarea formularului',
    footer_1: 'Partenerii noștri:',
    footer_2: 'Metode',
    footer_3: 'Produse',
    footer_4: 'Contacte:',
    footer_5: 'Imprima',
    footer_6: 'Politica de confidențialitate',
    about_us_1: 'Responsabilitatea socială corporativă 4Service',
    about_us_2: 'Din inima și sufletul fiecărui membru al echipei noastre. Ne străduim să păstrăm o scânteie de bunătate în fiecare dintre angajații noștri și să facem lumea din jurul nostru puțin mai bună.',
    about_us_3: 'Responsabilitatea socială corporativă 4Service',
    about_us_4: 'Din inima și sufletul fiecărui membru al echipei noastre. Ne străduim să păstrăm o scânteie de bunătate în fiecare dintre angajații noștri și să facem lumea din jurul nostru puțin mai bună.',
    about_us_5: 'Jocul de afaceri Service Mania',
    about_us_6: 'Inițiativele noastre de implicare pentru copii și elevi',
    about_us_7: 'Caritate / Voluntariat',
    about_us_8: 'Orfelinate, cămine pentru vârstnici și voluntariat în cadrul armatei ucrainene. Cea mai emoționantă parte a activităților noastre',
    about_us_9: 'Bunăstarea animalelor',
    about_us_10: 'Oricine poate intra în biroul nostru cu un animal de companie. Dăm o mână de ajutor adăposturilor, strângem fonduri pentru hrană și, uneori, încercăm să găsim o casă pentru animalele fără adăpost',
    about_us_11: '4Service Group',
    about_us_12: 'Este o companie internațională de cercetare care gestionează experiența clienților.',
    about_us_13: 'Implementăm proiecte complexe pentru creșterea vânzărilor și dezvoltarea afacerilor companiilor orientate către client.',
    about_us_14: 'Este o companie internațională de cercetare cu sediul central în Viena, Austria. Compania a fost înființată în 2001 cu scopul de a îmbunătăți calitatea vieții clienților săi și a persoanelor care interacționează cu afacerea, utilizează serviciile sale și consumă produsele sale. În fond, nu fără motiv a fost ales numele «Forservice» pentru o companie care reprezintă serviciul clienților și calitatea bună.',
    about_us_15: 'Aceasta a fost începutul istoriei 4Service în Kiev, Ucraina, ca principal furnizor de servicii de mystery shopping. A continuat să opereze cu succes ca o companie de cercetare în 52 de țări, cu noi oportunități în abordarea holistică de analizare și îmbunătățire a întregului lanț de experiență a clienților.',
    about_us_16: 'născut în numele serviciului',
    about_us_17: 'Prima filială situată în afara Ucrainei',
    about_us_18: 'Relocarea sediului central la Viena',
    about_us_19: 'Membra de elită a MSPA, agenție de top 10 în Europa',
    about_us_20: 'stabilirea de birouri in 9 tari',
    about_us_21: 'primele proiecte digitale, Voicer',
    about_us_22: 'parteneri ai DesignThinkers Academy',
    about_us_23: 'primele proiecte globale CХ',
    about_us_24: 'Misiune',
    about_us_25: 'Misiunea noastră este de a îmbunătăți serviciile pentru clienți în întreaga lume! Sursa noastră de inspirație o reprezintă schimbările calitative pe care le observăm la oamenii și companiile cu care lucrăm.',
    about_us_26: '20+ ani de experiență în domeniul experienței clienților',
    about_us_27: '95 de țări',
    about_us_28: 'Colectăm și împărtășim cea mai bună experiență din industrie din întreaga lume',
    about_us_29: 'Sediul central în Viena',
    about_us_30: 'ţări. Colectăm și împărtășim cea mai bună experiență din industrie din întreaga lume',
    about_us_31: 'Evaluarile calității serviciilor',
    about_us_32: 'suntem în top-10 agenții europene CX',
    about_us_33: 'reprezentanțe / Sediul central în Austria',
    about_us_34: 'proiecte active',
    about_us_35: 'Viitorul tău loc de muncă',
    about_us_36: 'Birourile noastre',
    about_us_37: '4Service ™ depune eforturi pentru a crea un spațiu de lucru confortabil pentru angajații săi. Angajații noștri lucrează la birou, de acasă sau în sistem hibrid, în funcție de cea mai mare comoditate și productivitate a fiecărui angajat. Dispunem de camere de relaxare, oferim suport psihologic gratuit și un mediu prietenos. Suntem prietenoși cu oamenii și animalele de companie',
    about_us_38: 'Echipa care lumineaza',
    about_us_39: 'Echipa noastră multiculturală este angajată să asculte, să acorde atenție feedback-ului oferit de clienții noștri și să crească împreună cu ei. Harta călătoriei clienților noștri alături de noi este la fel de  importantă pentru afacerea noastră precum lansarea de noi produse sau atingerea obiectivelor financiare. Activitățile noastre sunt orientate către obținerea unui impact pozitiv asupra comunităților din jurul  nostru și livrarea de soluții inovatoare și centrate pe oameni pentru clienții noștri.',
    about_us_40: 'Toate',
    about_us_41: 'management',
    about_us_42: 'Echipa globală',
    about_us_43: 'Proprietari de produse',
    about_us_44: 'Partenerii noștri',
    about_us_45: 'Implementam proiecte complexe pentru cresterea vanzarilor si dezvoltarea afacerilor companiilor orientate catre client',
    about_us_46: 'Istoricul nostru arată că suntem unul dintre principalii instituți de formare profesională cu o abordare centrată pe design, prezenți în 25 de țări, unde formăm, dezvoltăm și facilităm echipe multidisciplinare și comunități creativie pentru a genera schimbări pozitive. Gândirea în design vă permite să dezvoltați și să furnizați idei, schimbări și soluții inovatoare pentru probleme complicate.',
    about_us_47: 'Misiunea lor este de a oferi spațiu și timp pentru ca echipele și indivizii să fie inspirați și provocați, să învețe prin faptă și să își dezvolte abilitățile de rezolvare creativă a problemelor, ajutând organizațiile să construiască mediul și mentalitatea necesare pentru a iniția inovații îndrăznețe.',
    about_us_48: 'Este Asociația de Comerț Reprezentativă pentru companiile implicate în industria Mystery Shopping-ului. Asociația operează la nivel regional înîntreaga lume, cu capitole situate în America, Europa / Africa și Asia / Pacific. Companiile membre se unesc ca un corp comun în scopul consolidării industriei Mystery Shopping prin eforturi și acțiuni combinate.',
    about_us_49: 'Este o organizație de membri fără scop lucrativ. Membrii formează o comunitate convinsă de potențialul analizei datelor, cercetării și informațiilor de a ajuta la îmbunătățirea societății, organizațiilor și vieții fiecăruia dintre noi.',
    about_us_50: 'ESOMAR are sediul central în Amsterdam, Țările de Jos. ESOMAR este prezentă în peste 130 de țări prin membrii săi din 1947.',
    about_us_51: '(UCCA) este o comunitate profesională formată din experți privați și companii unite în scopul dezvoltării și interacțiunii în domeniul centrelor de contact și al serviciilor pentru clienți. Filosofia UCCA este de a ridica cultura industriei centrelor de contact, bazată pe tehnologii moderne și standarde ridicate.',
    about_us_52: 'Asociația Ucraineană a Centrelor de Contact a fost înființată în 2010.',
    about_us_53: 'Academy DTEK studiază cea mai bună experiență internațională în domeniul educației pentru a o adapta și utiliza pe piața ucraineană. De aceea, Academia DTEK colaborează cu cele mai importante școli de afaceri internaționale: INSEAD, IE Business School și Universitatea Stanford.',
    about_us_54: 'Academy DTEK este concentrată pe dezvoltarea unei culturi orientate spre clienți și inovatoare în cadrul D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, programul digital DX School, Pre-MBA: Effective Leader și Change Energy.',
    about_us_55: 'Sergey Pashkovsky',
    about_us_56: 'Viktoria Skorbota',
    about_us_57: 'Artem Fomin',
    about_us_58: 'Max Papka',
    about_us_59: 'Manager International de vanzari',
    bht_1: 'Monitorizarea Sănătății',
    bht_2: 'Brandului',
    bht_3: 'Mărcile sunt incredibil de puternice',
    bht_4: 'Descoperă cât de bine performează brandul tău în general',
    bht_5: 'Despre',
    bht_6: 'Ce este Monitorizarea Sănătății Brandului (BHT)?',
    bht_7: 'Acesta este un tip de cercetare de piață care se realizează cu o anumită regularitate (la două săptămâni, în fiecare lună etc.) pentru a obține informații despre',
    bht_8: 'statutul unui brand în piață pe baza unor indicatori precum notorietatea, cererea, loialitatea, imaginea, etc.',
    bht_9: 'Au cumpărat cel mai des marca',
    bht_10: 'Loial',
    bht_11: 'Au cumparat marca pentru 3 luni',
    bht_12: 'Repertoriu / clienți obișnuiți',
    bht_13: 'Au cumparat marca pentru 6 luni',
    bht_14: 'Clienți pe termen scurt',
    bht_15: 'Au cumpărat marca pentru un an',
    bht_16: 'Clienți pe termen lung',
    bht_17: 'Sunteți la curent cu marca? dar nu am folosit-o/cumpărat-o niciodată',
    bht_18: 'Sunt la curent, dar nu sunt clienți',
    bht_19: 'Nu sunt la curent: nu au auzit niciodată de marcă',
    bht_20: 'Nu sunt la curent',
    bht_21: 'Consumul de Brand',
    bht_22: 'Evaluarea coerenței eforturilor depuse pe termen lung pentru a construi un brand',
    bht_23: 'Măsurarea nivelului de cunoaștere a clienților despre marcă',
    bht_24: 'Arătarea eficienței campaniilor de publicitate și de informare',
    bht_25: 'Determinarea impactului pe care o are o perioadă de publicitate ridicată asupra activităților de vânzări/marketing ale concurenților',
    bht_26: 'Oferirea unui control constant proprietarului afacerii în perioadele de schimbări continue ale pieței',
    bht_27: 'Ce sarcini pot fi rezolvate de BHT:',
    bht_28: 'Solicitați o cercetare',
    bht_29: 'Sarcini de rezolvat',
    bht_30: 'De exemplu,',
    bht_31: 'dacă o companie are nevoie de o singură sesiune de BHT pe trimestru atunci cand se măsoară cota de piață a brandului, un sondaj ar trebui să fie efectuat mai des atunci când se măsoară notorietatea brandului ca și impact al publicității.',
    bht_32: 'CATI',
    bht_33: 'Față în față',
    bht_53: 'Interviuri',
    bht_34: 'СAWI',
    bht_35: 'Sarcini de rezolvat',
    bht_36: 'Prin colectarea de informații',
    bht_37: 'prin diverse metode, urmărirea mărcii este de obicei împărțită în trei etape, în funcție de publicul țintă și de cât de dificil este de atins:',
    bht_38: 'Folosim atât metode tradiționale, cât și contemporane pentru a colecta date:',
    bht_39: 'Lucrăm folosind două modele: primul cu un set simplu de indicatori, iar al doilea vizează industrii mai complexe, cum ar fi industria alimentară și a serviciilor. Un model mai complex presupune un studiu aprofundat care include până la 10 indici estimați.',
    bht_40: 'Noi oferim',
    bht_41: 'un control al datelor inițiale',
    bht_42: 'Acoperim',
    bht_43: 'intreaga tara',
    bht_44: 'Oferim o soluție',
    bht_45: 'pentru piețe nedinamice/piețe cu un număr limitat de jucători (Brand Point Analyse)',
    bht_46: 'De ce noi?',
    bht_47: 'De ce ne alegeți pe noi?',
    bht_50: 'Rezultat slab',
    bht_51: 'Rezultat bun',
    bht_52: 'Rezultat mediu',
    cases_1: 'Provocare: Cum să îmbunătățiți experiența clienților de cumpărare a vehiculelor electrice în showroom-urile Hyundai?',
    cases_2: 'Maparea călătoriei clientului',
    cases_3: 'Generare de idei',
    cases_4: 'Cercetare calitativa',
    cases_5: 'Provocare: Ce ar trebui schimbat în magazine (atât în procesul de servire, în interior, în percepția mărcii) pentru a atrage interesul clienților pentru marca Oppo?',
    cases_6: 'CJM online',
    cases_7: 'Testarea UI',
    cases_8: 'Interviuri',
    cases_9: 'Provocare: Ce ar trebui schimbat în magazine pentru a atrage interesul clienților pentru marca Oppo?',
    cases_10: 'Partea calitativă',
    cases_11: 'Generare de idei',
    cases_12: 'Partea cantitativă',
    cases_13: 'Harta călătoriei clientului',
    cases_14: 'Cazuri',
    cases_15: 'Toate',
    cases_16: 'Hai sa construim impreuna cazul tau de succes',
    csi_1: 'Indicele',
    csi_2: 'de Satisfacție a Clienților',
    csi_3: 'Determinați motivele pentru care sunt mulțumiți /',
    csi_4: 'nemulțumiți clienții dumneavoastră',
    csi_5: 'Cercetarea satisfacției clienților',
    csi_6: 'ne ajută să determinăm factorii, cauzele și motivele care influențează decizia clientului de cumpărare, precum și comportamentul publicului țintă.',
    csi_7: 'Un sondaj de satisfacție a clienților',
    csi_8: 'arată cât de mulțumit sau nemulțumit este clientul cu un anumit aspect al serviciului sau al produsului în general.',
    csi_9: 'Sondajul de satisfacție',
    csi_10: 'se aplică în rândul clienților existenți ai companiei și utilizatorilor anumitor produse/servicii.',
    csi_11: 'Ce este CSI?',
    csi_12: 'Indicele de satisfacție a clienților indică în ce măsură au fost satisfăcute așteptările clienților cu privire la achiziția unui produs sau serviciu furnizat de compania dumneavoastră.',
    csi_13: 'Om fericit',
    csi_14: 'Despre',
    csi_15: 'Identificați orice factori ascunși',
    csi_16: 'care pot afecta satisfacția clientului',
    csi_17: 'Determinați motivele',
    csi_18: 'în spatele oricărei creșteri/scăderi a cererii de bunuri și servicii',
    csi_19: 'Optimizați procesele',
    csi_20: 'de afaceri ale companiei',
    csi_21: 'Adaptați',
    csi_22: 'caracteristicile produselor / serviciilor la nevoile consumatorului modern',
    csi_23: 'Formaţi un mesaj publicitar eficient',
    csi_24: 'și poziționați un produs la cererea clientului',
    csi_25: 'Sarcini de rezolvat',
    csi_26: 'Ce sarcini pot rezolva sondajele de satisfacție a clienților',
    csi_27: 'Bărbat cu telefon',
    csi_28: 'Construim un model matematic',
    csi_29: 'a factorilor care pot influența satisfacția/loialitatea clienților (analiza de regresie)',
    csi_30: 'Optimizați',
    csi_31: 'procesele de afaceri ale companiei',
    csi_32: 'Construim un indice calculat de satisfacție a clienților',
    csi_33: '(CSI) și îl comparăm cu unul declarativ',
    csi_34: 'Facem o cercetare',
    csi_35: 'pentru ambele segmente B2B și B2C.',
    csi_36: 'Chestionarele noastre sunt elaborate de o echipă',
    csi_37: 'de analiști în special pentru proiectele individuale ale clienților noștri',
    csi_38: 'De ce noi',
    csi_39: 'De ce să ne alegeți pe noi?',
    csi_40: 'Metode de colectare a informațiilor:',
    main_1: 'au reușit să-și îmbunătățească performanța financiară învățând din experiențele clienților lor',
    main_2: 'Consultanță și servicii CX, o perspectivă la 360° asupra pieței și afacerii dumneavoastră',
    main_3: 'Descoperiți gama noastră de soluții și instrumente pe care le folosim pentru a vă rezolva obiectivele de marketing și de business',
    main_4: 'Produse',
    main_5: 'Cercetarea calitativă este o colectare și o analiza de date non-numerice pentru a înțelege concepte, opinii sau experiențe',
    main_6: 'Metodele de cercetare cantitativă reprezintă o măsurare obiectivă și o analiză numerică a datelor colectate prin sondaje, chestionare și anchete.',
    main_7: 'Mystery shopping este o tehnică de cercetare a pieței pentru măsurarea și monitorizarea interacțiunilor dintre un client și o companie sau organizație în timpul unui scenariu predefinit.',
    main_8: 'Sistem de management al feedback-ului. Vocea clientului dumneavoastră într-un singur Sistem de management al feedback-ului clienților',
    main_9: 'O platformă de informații despre conținut alimentată de inteligență artificială, care oferă informații de la public',
    main_10: 'Software de management al performanței. Aplicație gamificată pentru creșterea vânzărilor',
    main_11: 'Monitorizarea prețurilor este procesul de colectare, procesare și analiză a prețurilor concurenților',
    main_12: 'Designul de servicii este modul de a înțelege nevoile și dorințele persoanelor prin experiența folosirii unui produs sau serviciu',
    main_13: 'Echipa noastră de analiști aplică cele mai fundamentale și moderne metode de cercetare, și realizează modele de analiză care s-au dovedit a fi foarte eficiente pentru mulți dintre clienții noștri',
    main_14: 'Metodologii',
    main_15: 'Harta călătoriei clientului',
    main_16: 'Customer Journey Mapping (CJM) se referă la definirea unui public țintă, înțelegerea nevoilor și a mediului acestora și apoi crearea unui plan pentru a oferi o experiență captivantă',
    main_17: 'CJM',
    main_18: 'Scor Net de Promovare (NPS)',
    main_19: 'Net Promoter Score (NPS) este standardul de aur al valorilor experienței clienților, bazat pe o întrebare: Cât de probabil ați recomanda Organizația/Produsul/Serviciul?',
    main_20: 'NPS',
    main_21: 'Monitorizarea Sănătății Brandului (BHT)',
    main_22: 'Cercetarea de tip Brand Health Tracking (BHT) poate măsura, atât transversal cât și longitudinal, performanța mărcii dvs. în ceea ce privește gradul de conștientizare și utilizare, poziționarea și performanța mărcii.',
    main_23: 'BHT',
    main_24: 'Cercetare organizațională',
    main_25: 'Cercetarea organizațională este folosită pentru a evalua practicile și performanța HR, notorietatea și percepția brandului companiei pe piața muncii, satisfacția angajaților,etc. Acest tip de cercetare oferă direcții clare pentru dezvoltarea managementului actual din cadrul companiei dumneavoastră',
    main_26: 'OR',
    main_27: 'Indicele de satisfacție a clienților',
    main_28: 'Indicele de Satisfacție a Clienților (CSI) este un scor care indică cât de mulțumiți sunt clienții față de experiența avută cu produsele / serviciile oferite de compania dumneavoastră',
    main_29: 'CSI',
    main_30: 'Testare UX',
    main_31: 'Testarea de utilizare (UX) este modul în care oamenii reali interacționează cu un site web, o aplicație sau un alt produs și observă comportamentul și reacțiile lor la acesta',
    main_32: 'De ce noi',
    main_33: 'Misiunea noastră este de a ajuta companiile să ofere clienților o experiență extrem de eficientă',
    main_34: 'Concentrarea pe calitate',
    main_35: 'Suntem controlați din punct de vedere al calității',
    main_36: 'când vine vorba de date de cercetare. Punem la dispoziția clienților toate seturile de date',
    main_37: 'Eficient din punct de vedere al costurilor',
    main_38: 'Experiența noastră, resursele și eficiența',
    main_39: 'proceselor de afaceri ne permit să economisim bani și timp pentru tine',
    main_40: 'Soluții inteligente',
    main_41: 'Folosim o varietate de tehnici',
    main_42: 'și seturi de instrumente pentru a rezolva problemele tale specifice',
    main_43: 'Concentrarea pe calitate',
    main_44: 'Noi controlăm calitatea',
    main_45: 'când vine vorba de date de cercetare. Punem la dispoziția clienților toate seturile de date',
    main_46: 'Eficient din punct de vedere al costurilor',
    main_47: 'Experiența noastră, resursele și eficiența',
    main_48: 'proceselor de afaceri ne permit să economisim bani și timp pentru tine',
    main_49: 'Soluții inteligente',
    main_50: 'Folosim o varietate de tehnici',
    main_51: 'și seturi de instrumente pentru a rezolva problemele tale specifice',
    main_52: 'Cash&Carry',
    main_53: 'Electronice',
    main_54: 'Lux/Premium',
    main_55: 'Restaurante și ospitalitate',
    main_56: 'Cu amănuntul',
    main_57: 'Automobile',
    main_58: 'Modă',
    main_59: 'Bănci',
    main_60: 'Călătorii și agrement',
    main_61: 'Ceasuri și bijuterii',
    main_62: 'Uz casnic și electrocasnice',
    main_63: 'Blog',
    main_64: 'Accesați blogul pentru a citi mai multe postări interesante',
    main_65: 'Audit de retail',
    main_66: 'Colectați date detaliate despre magazinul sau marca dvs., analizați barierele de creștere și creați strategii eficiente pentru a le depăși',
    cjm_1: 'Client',
    cjm_2: 'Harta călătoriei',
    cjm_3: 'Intră în lumea clienților și împărtășește din experiența lor',
    cjm_4: 'Ce este Harta Customer Journey (harta de călătorie a clienților)?',
    cjm_5: 'CJM oferă un istoric vizualizat al interacțiunii consumatorului cu un produs, serviciu, companie sau marcă prin diverse canale și într-o anumită perioadă de timp.',
    cjm_6: 'Acest lucru permite companiilor să analizeze în mod obiectiv experiența de interacțiune cu clienții, să repare și să elimine orice bariere care apar și să ofere recomandări pentru îmbunătățirea produsului.',
    cjm_7: 'Determinarea TA, segmentare',
    cjm_8: 'Identificarea etapelor de interactiune cu clientul',
    cjm_9: 'Identificarea canalelor primare de interacțiune cu clienții',
    cjm_10: 'Cercetarea experienței clienților',
    cjm_11: 'Analiza barierelor și ipotezele privind optimizarea',
    cjm_12: 'Executarea si testarea ipotezelor',
    cjm_13: 'Proiectarea unei hărți de călătorie a clienților constă din următorii pași:',
    cjm_14: 'Harta Customer Journey va demonstra:',
    cjm_15: 'CJM oferă un istoric vizualizat al interacțiunii consumatorului cu un produs, serviciu, companie sau marcă prin diverse canale și într-o anumită perioadă de timp.',
    cjm_16: 'Asiguram munca in echipa',
    cjm_17: 'între analiști și designeri',
    cjm_18: 'Oferim individual',
    cjm_19: 'abordare a fiecărui client',
    cjm_20: 'Putem crește în ansamblu',
    cjm_21: 'Satisfacția clienților cu serviciul/produsul și creșterea loialității lor față de companie',
    cjm_22: 'Vă garantăm',
    cjm_23: 'optimizarea traseului consumatorului și luarea în considerare a proceselor de afaceri ale companiei',
    cjm_24: 'Oferim clienților noștri',
    cjm_25: 'posibilitatea de a utiliza abordări combinate: CATI, CAWI, focus grupuri, interviuri aprofundate, interviuri cu experți, etnografie etc.',
    cjm_26: 'De ce să ne alegeți pe noi?',
    methods_hr_1: 'Cercetarea resurselor umane',
    methods_hr_2: 'Obțineți informații obiective despre echipa dvs. și găsiți cum să îmbunătățiți performanța HR',
    methods_hr_3: 'Câteva motive pentru care este posibil să aveți nevoie de cercetare HR',
    methods_hr_4: 'a personalului intervievat',
    methods_hr_5: 'proiecte de resurse umane implementate',
    methods_hr_6: 'au avut loc sesiuni de instruire a personalului',
    methods_hr_7: 'Holding-ul de vârf european pentru experiența clienților și cercetarea angajaților',
    methods_hr_8: 'Cercetările 4service privind resursele umane în cifre',
    methods_hr_9: 'Pentru a identifica problemele și punctele slabe în fiecare etapă a vieții angajaților companiei dvs.: căutare/recrutare/integrare/dezvoltare cariera/recertificări',
    methods_hr_10: 'Sa stii ce ocazii merita mariri si bonusuri',
    methods_hr_11: 'Trebuie să preziceți sau să oferiți soluții pentru nemulțumirea angajaților',
    methods_hr_12: 'Prin cercetarea HR sunt posibile metode noi și actuale de evaluare a politicilor, programelor și practicilor HR',
    methods_hr_13: 'Pentru a îmbunătăți sănătatea mintală a angajaților companiei',
    methods_hr_14: 'Dacă doriți să îmbunătățiți productivitatea printr-o abordare orientată spre obiective',
    methods_hr_15: 'Dacă aveți o echipă internațională și/sau la distanță și trebuie să o gestionați',
    methods_hr_16: 'Pentru a înțelege cât de atractiv este brandul dvs. de angajator pentru potențialii angajați',
    methods_hr_17: 'Satisfactia angajatilor',
    methods_hr_18: 'Cercetare de branding HR',
    methods_hr_19: 'Diversitatea culturală',
    methods_hr_20: 'Planuri de compensare și salariu',
    methods_hr_21: 'Implicarea echipei și loialitatea',
    methods_hr_22: 'Angajații clasei protejate',
    methods_hr_23: 'Domenii de cercetare',
    methods_hr_24: 'Solutia 1',
    methods_hr_25: 'eNPS, Satisfacția angajaților, Loialitatea angajaților',
    methods_hr_26: 'Ce măsurăm:',
    methods_hr_27: 'Loialitate:',
    methods_hr_28: 'loialitatea angajaților, anticiparea comportamentului viitor, determinarea ponderii factorilor care afectează loialitatea',
    methods_hr_29: 'Implicare:',
    methods_hr_30: 'implicarea angajatilor in munca, interes pentru rezultate si realizari',
    methods_hr_31: 'Angajament:',
    methods_hr_32: 'identificarea valorilor angajatilor si a valorilor companiei',
    methods_hr_33: 'Trust 360:',
    methods_hr_34: 'încredere în management, colegi de muncă, subordonați, încredere în strategiile și politicile angajatorilor',
    methods_hr_35: 'Satisfacţie:',
    methods_hr_36: 'definirea factorilor unici pentru fiecare companie, evaluarea satisfacției pentru fiecare factor cheie care afectează loialitatea',
    methods_hr_37: 'Solutia 2',
    methods_hr_38: 'Branding de angajator',
    methods_hr_39: 'Angajator intern',
    methods_hr_40: 'analiza mărcii (angajați, părți interesate, recrutori interni și specialiști în resurse umane)',
    methods_hr_41: 'Analiza angajatorului extern',
    methods_hr_42: '(analiza EVP concurenților, sondaj de recrutare externă, sondaj de căutare de locuri de muncă)',
    methods_hr_43: 'Conștientizarea mărcii și reputația',
    methods_hr_44: 'analiză',
    methods_hr_45: 'Prezența angajatorului',
    methods_hr_47: 'Referința angajatorului',
    methods_hr_48: 'Colectie',
    methods_hr_49: 'Reper competitiv',
    methods_hr_50: 'asupra factorilor cheie precum',
    methods_hr_51: 'Salariu,',
    methods_hr_52: 'mediul de lucru, atmosfera, natura muncii',
    methods_hr_53: 'Analiza nevoilor si asteptarilor',
    methods_hr_54: 'a solicitantilor de locuri de munca',
    methods_hr_55: 'interviuri aprofundate,',
    methods_hr_56: 'se utilizează cercetarea de birou și cercetarea cantitativă',
    methods_hr_57: 'Solutia 3',
    methods_hr_58: 'Harta călătoriei angajaților/angajat misterios',
    methods_hr_59: 'construirea călătoriei în căutarea unui loc de muncă identificând',
    methods_hr_60: 'principalele etape ale căutării, canalele de comunicare, criteriile de selecție și evaluarea persoanelor aflate în căutarea unui loc de muncă',
    methods_hr_61: 'monitorizarea tuturor etapelor',
    methods_hr_62: 'și canale de comunicare cu cei care caută un loc de muncă misterios (doar a face o programare pentru un interviu, a avea un interviu, un stagiu în companie)',
    methods_hr_63: 'Identificare',
    methods_hr_64: 'a punctelor slabe în procesele de recrutare și integrare',
    methods_hr_65: 'Recomandări de îmbunătățire,',
    methods_hr_66: 'evaluare comparativă cu liderii din industrie',
    mystery_shopping_1: 'Cumpărături',
    mystery_shopping_2: 'Misterioase',
    mystery_shopping_3: 'Evaluează-ți serviciul cu clienți reali',
    mystery_shopping_4: 'Verificați evaluarea standardelor pentru ca personalul dvs. să fie mai apropiat de client',
    mystery_shopping_5: 'Suntem membru de elită al asociației profesionale de lideri Mystery Shopping Providers MSPA Europe',
    mystery_shopping_6: 'de experienţă',
    mystery_shopping_7: 'vizite lunare',
    mystery_shopping_8: 'cumpărători misterioși din întreaga lume',
    mystery_shopping_9: 'Proiecte active',
    mystery_shopping_10: 'birouri, sediu central în Austria',
    mystery_shopping_11: 'Furnizori europeni de servicii de Mystery Shopping',
    mystery_shopping_12: 'evaluări ale calității serviciilor',
    mystery_shopping_13: 'Chestionar',
    mystery_shopping_14: 'Software',
    mystery_shopping_15: 'Profilul cumpărătorului',
    mystery_shopping_16: 'Validare și obiecție',
    mystery_shopping_17: 'Analitice',
    mystery_shopping_18: 'Cu acești 5 pași, compania noastră 4Service garantează calitatea serviciului mystery shopper',
    mystery_shopping_19: 'Program',
    mystery_shopping_20: 'Cele 5 elemente ale programului Mystery Shopping de la 4Service',
    mystery_shopping_21: 'Chestionar:',
    mystery_shopping_22: 'esența programului tau',
    mystery_shopping_23: 'Această parte a programului este vizibilă de către toate părțile interesate',
    mystery_shopping_24: 'Pentru managerii de top.',
    mystery_shopping_25: 'Afișați toți pașii importanți și detaliile procesului',
    mystery_shopping_26: 'Pentru angajati.',
    mystery_shopping_27: 'Reflectați ceea ce așteaptă angajatorii de la ei',
    mystery_shopping_28: 'Pentru cumpărătorii misterioși.',
    mystery_shopping_29: 'Clar și structurat pentru a evita confuzia și părtinirea',
    mystery_shopping_30: 'Software Shopmetrics:',
    mystery_shopping_31: 'ușor de utilizat și inovator',
    mystery_shopping_32: 'Beneficii:',
    mystery_shopping_33: 'Se integrează',
    mystery_shopping_34: 'cu procesele și software-ul dvs. de afaceri',
    mystery_shopping_35: 'Orientare GDPR',
    mystery_shopping_36: 'Setul de instrumente de management al confidențialității',
    mystery_shopping_37: 'Detalii despre',
    mystery_shopping_38: 'Pachetul de conformitate legală',
    mystery_shopping_39: 'Construire',
    mystery_shopping_40: 'Raport',
    mystery_shopping_41: 'Management',
    mystery_shopping_42: 'al nivelurilor de acces',
    mystery_shopping_43: 'Majoritatea chestionarelor',
    mystery_shopping_44: 'sunt finalizate într-o oră',
    mystery_shopping_45: 'Profilul cumpărătorilor:',
    mystery_shopping_46: 'Peste 200 000 de cumpărători misterioși din întreaga lume',
    mystery_shopping_47: 'Criterii principale: proximitate maximă față de publicul țintă, cu experiență anterioară cu compania sau concurenții',
    mystery_shopping_48: 'Instruirea cumpărătorilor:',
    mystery_shopping_49: 'Test de formare introductiva',
    mystery_shopping_50: 'Skype/Apel telefonic',
    mystery_shopping_51: 'Test de evaluare (optional)',
    mystery_shopping_52: 'Validare și obiecții',
    mystery_shopping_53: 'Validare',
    mystery_shopping_54: 'Sistem',
    mystery_shopping_55: 'Rapoartele sunt 100% ',
    mystery_shopping_56: 'revizuite de către echipa de validare',
    mystery_shopping_57: 'Apeluri suplimentare',
    mystery_shopping_58: 'pentru clarificare',
    mystery_shopping_59: 'Înregistrări audio',
    mystery_shopping_60: 'după vizită pentru a nota cele mai importante aspecte',
    mystery_shopping_61: 'Frauda angajaților',
    mystery_shopping_62: 'sistem de prevenire',
    mystery_shopping_63: 'Obiecţie',
    mystery_shopping_64: 'Sistem',
    mystery_shopping_65: 'Evaluarea oricărui cumpărător',
    mystery_shopping_66: 'poate fi obiectată de către angajat direct pe site',
    mystery_shopping_67: 'Fiecare obiecție',
    mystery_shopping_68: 'este luată în considerare de către echipa locală de validare',
    mystery_shopping_69: 'Puteți vedea statistici',
    mystery_shopping_70: 'ale obiecțiilor',
    mystery_shopping_71: 'Analitice.',
    mystery_shopping_72: 'Raportul este prezentat personal și conține:',
    mystery_shopping_73: 'Problemele',
    mystery_shopping_74: 'și preocupările principale ale companiei',
    mystery_shopping_75: 'Dinamica',
    mystery_shopping_76: 'pe diviziuni și secțiuni',
    mystery_shopping_77: 'Corelație',
    mystery_shopping_78: 'între îndeplinirea standardelor și satisfacția clienților',
    mystery_shopping_79: 'Reper',
    mystery_shopping_80: 'împotriva concurenților',
    mystery_shopping_81: 'Practic',
    mystery_shopping_82: 'recomandări privind îmbunătățirea serviciilor',
    mystery_shopping_83: 'Ce ne face diferiți?',
    mystery_shopping_84: 'Desfășurăm sesiuni de generare de idei',
    mystery_shopping_85: 'Noi nu doar recomandăm ce să faci, ci împreună cu tine căutăm soluții practice pentru rezolvarea problemelor de servicii',
    mystery_shopping_86: 'Sesiunile de generare de idei sunt o unealtă puternică care ne ajută:',
    mystery_shopping_87: 'Sa dezvoltam direcții pentru îmbunătățirea produselor sau serviciilor',
    mystery_shopping_88: 'Sa exploram noi oportunități de flux de venituri și strategii de afaceri',
    mystery_shopping_89: 'Pentru a găsi soluții la provocări complexe orientate către client',
    mystery_shopping_90: 'transformarea problemelor clientilor în experiențe plăcute',
    mystery_shopping_91: 'Realizam interviuri video',
    mystery_shopping_92: 'Nu colectăm doar chestionare - primim și comentarii live de la cumpărători',
    mystery_shopping_93: 'Facem acest lucru pentru a:',
    mystery_shopping_94: 'Persoanele responsabile vor efectua interviuri detaliate și vor oferi feedback eficient',
    mystery_shopping_95: 'A vedea experiența din perspectiva clienților',
    mystery_shopping_96: 'Îmbunătățiți strategia de marketing de conținut',
    mystery_shopping_97: 'Prezice comportamentul clienților',
    mystery_shopping_98: 'Noi proiectăm harta călătoriei clienților',
    mystery_shopping_99: 'Crearea hărții călătoriei clienților ne ajută să ne punem în locul clienților și să înțelegem nevoile și provocările lor',
    mystery_shopping_100: 'Crearea unei harti de călătorie a clienților este cea mai bună modalitate de a:',
    mystery_shopping_101: 'A vedea experiența din perspectiva clienților',
    mystery_shopping_102: 'Îmbunătăți strategia de marketing de conținut',
    mystery_shopping_103: 'Prezice comportamentul clienților',
    mystery_shopping_104: 'Identificarea problemelor în servicii sau comunicare',
    mystery_shopping_105: 'Oferim diferite tipuri de Cumpărături misterioase',
    mystery_shopping_106: 'Tipuri de cumpărături misterioase',
    mystery_shopping_107: 'Apelant misterios',
    mystery_shopping_108: 'Sunăm managerii și/sau centrul de apeluri pentru a determina calitatea serviciului oferit prin telefon',
    mystery_shopping_109: 'Cumpărături misterioase de lux',
    mystery_shopping_110: 'Serviciu de verificare în locații de lux de către o categorie specială de cumpărători misterioși',
    mystery_shopping_111: 'Vizite competitive',
    mystery_shopping_112: 'Cel mai simplu mod de a înțelege cum funcționează concurentul tău',
    mystery_shopping_113: 'Angajat misterios',
    mystery_shopping_114: 'Vă va ajuta să înțelegeți întreaga călătorie a angajatului dvs. (angajare, adaptare, procese de birou etc.)',
    mystery_shopping_115: 'Vizite motivaționale',
    mystery_shopping_116: 'Un instrument util pentru stimularea ofertei de servicii și/sau produse suplimentare, vânzări de produse specifice',
    mystery_shopping_117: 'Cumpărător misterios online',
    mystery_shopping_118: 'Simulează și măsoară experiența clienților în magazinul dvs. online, site-ul sau aplicația dvs',
    mystery_shopping_119: 'Verificarea vânzărilor și promoțiilor',
    mystery_shopping_120: 'Ajută la identificarea modului în care sunt afișate promoțiile în toate locațiile și cât de eficient funcționează',
    mystery_shopping_121: 'Vizite de cumpărare și retur',
    mystery_shopping_122: 'Ajută la reflectarea întregului proces de la cumpărare până la returnare pentru a îmbunătăți experiența clientului',
    mystery_shopping_123: '> 20 ani',
    nps_1: 'Scorul Net',
    nps_2: 'de Promovare',
    nps_3: 'Măsurați, urmăriți și îmbunătățiți loialitatea clienților',
    nps_4: 'Ei răspund cu un scor de la 0 la 6.',
    nps_5: 'Aceștia ar putea să fi avut o experiență proastă și este puțin probabil să mai cumpere de la tine și ar putea chiar să îi descurajeze pe alții să cumpere de la tine',
    nps_6: 'Aceștia sunt respondenți care au un punctaj între 7 și 8.',
    nps_7: 'Sunt mulțumiți de serviciile dvs., dar nu sunt suficient de fericiți pentru a fi considerați promotori.',
    nps_8: 'Aceștia sunt respondenții care vă evaluează afacerea cu 9 sau 10.',
    nps_9: 'Acest lucru înseamnă că ar recomanda compania unui prieten sau coleg, și astfel promovează compania în numele tău',
    nps_10: 'Ce este NPS?',
    nps_11: 'Măsoară percepția clienților pe baza unei întrebări simple:',
    nps_12: 'Cât de probabil este să recomandați [Compania/Produsul/Serviciile] unui prieten sau coleg?',
    nps_13: 'Compania mea are nevoie de el?',
    nps_14: 'NPS este creșterea ta durabilă',
    nps_15: 'Înțelegeți dinamica loialității clienților',
    nps_16: 'Motivați-vă personalul',
    nps_17: 'Înțelegeți relația cu diferite produse',
    nps_18: 'Comparați modul în care clienții vă tratează pe dvs. și pe concurenții dvs',
    nps_19: 'Ce pot face cu NPS?',
    nps_20: 'Pentru ce fel de afacere este relevant NPS-ul?',
    nps_21: 'Metode de colectare a informațiilor:',
    nps_22: 'Recomandările noastre atunci când lucrați cu NPS',
    nps_23: 'Aceasta înseamnă că dezvoltăm linii directoare pentru a ajuta la transformarea Detractorilor în promotori',
    nps_24: 'Lucrăm cu o metodă „în buclă închisă”.',
    nps_25: 'NPS este o cercetare de urmărire în care monitorizăm schimbările în loialitatea clienților',
    nps_26: 'Monitorizăm dinamica',
    nps_27: 'Oferim mereu evaluarea concurenților din domeniul dvs. pentru o analiză mai detaliată',
    nps_28: 'Evaluarea concurenților',
    price_monitoring_1: 'Monitorizarea prețurilor',
    price_monitoring_2: 'Monitorizare retail',
    price_monitoring_3: 'Creșteți potențialul de vânzări și optimizați-vă politica de prețuri prin monitorizarea prețurilor concurenților',
    price_monitoring_4: '4Service oferă servicii de monitorizare a prețurilor la nivel mondial de mai bine de 20 de ani',
    price_monitoring_5: 'Prețurile sunt monitorizate într-un timp scurt de echipa noastră de auditori interni calificați, permițându-ne să răspundem rapid la modificările prețurilor concurenților',
    price_monitoring_6: 'Provocările pe care compania noastră vă poate ajuta să le rezolvați prin monitorizarea prețurilor',
    price_monitoring_7: 'Evaluarea prețurilor practicate de concurenți',
    price_monitoring_8: 'și politici de produs',
    price_monitoring_9: 'Monitorizarea promoțiilor',
    price_monitoring_10: 'și ofertelor speciale',
    price_monitoring_11: 'Monitorizarea condițiilor de piață',
    price_monitoring_12: 'și a mediului de piață',
    price_monitoring_13: 'Verificarea și controlul punctelor de vânzare',
    price_monitoring_14: 'pentru echipamente și mărfuri speciale',
    price_monitoring_15: 'Gestionarea fluxului',
    price_monitoring_16: 'de produse în cadrul comerțului',
    price_monitoring_17: 'Condiții de optimizare',
    price_monitoring_18: 'pentru o mai bună colaborare cu furnizorii, dealerii',
    price_monitoring_19: 'Măsurarea spațiului la raft,',
    price_monitoring_20: 'evaluarea poziționării categoriilor de produse, disponibilitatea stocului',
    price_monitoring_21: 'Indicatori cheie și criterii de monitorizare',
    price_monitoring_22: 'Toate datele sunt integrate într-o singură interfață, care include:',
    price_monitoring_23: 'Numele',
    price_monitoring_24: 'categoriei de produse și marca',
    price_monitoring_25: 'Disponibilitate',
    price_monitoring_26: 'materialelor promoționale',
    price_monitoring_27: 'Preț',
    price_monitoring_28: 'și disponibilitatea oricăror oferte promoționale',
    price_monitoring_29: 'Mărime',
    price_monitoring_30: 'raft',
    price_monitoring_31: 'Afișare',
    price_monitoring_32: 'produs',
    price_monitoring_33: 'Orientare',
    price_monitoring_34: 'SKU',
    price_monitoring_35: 'Tip',
    price_monitoring_36: 'de ambalare',
    price_monitoring_37: 'Oricare altă',
    price_monitoring_38: 'informație generală',
    price_monitoring_39: 'Analiza pre-proiect:',
    price_monitoring_40: 'Studiem',
    price_monitoring_41: 'politica de prețuri și produse ale companiei dvs., practicile de afaceri și din industrie',
    price_monitoring_42: 'Oferim',
    price_monitoring_43: 'instruire promptă, testare și certificare pentru auditori',
    price_monitoring_44: 'Personalizăm',
    price_monitoring_45: 'și configuram software-ul',
    price_monitoring_46: 'Munca de teren:',
    price_monitoring_47: 'Vizite la punctele',
    price_monitoring_48: 'de vânzare ale retailerilor',
    price_monitoring_49: 'Raportare:',
    price_monitoring_50: 'Echipa noastră analitică',
    price_monitoring_51: 'pregătește rapoarte online în conturile personale ale companiei',
    price_monitoring_52: 'Oferim',
    price_monitoring_53: 'un reportaj foto complet, raport PPT, raport Excel în ceea ce privește tipul de localitate, numele lanțului, adresa punctului de vânzare',
    price_monitoring_54: 'Cum',
    price_monitoring_55: 'funcționează',
    price_monitoring_56: 'mărimea reducerilor individuale',
    price_monitoring_57: 'calculul costurilor',
    price_monitoring_58: 'mărimea bonusurilor individuale',
    price_monitoring_59: 'programe de loialitate',
    price_monitoring_60: 'etc.',
    price_monitoring_61: 'Folosim software specializat:',
    price_monitoring_62: 'PRADATA, Shopmetrics, și oferim suport tehnic complet',
    price_monitoring_63: 'Desfăşurare rapidă',
    price_monitoring_64: 'și scalare (din ziua 1)',
    price_monitoring_65: 'Toate datele confirmate',
    price_monitoring_66: 'prin fotografii și etichetare GPS',
    price_monitoring_67: 'Avem numărul necesar',
    price_monitoring_68: 'de agenți de teren în orice țară',
    price_monitoring_69: 'Putem lucra cu mari',
    price_monitoring_70: 'cantități de date',
    price_monitoring_71: 'Monitorizarea prețurilor individuale',
    price_monitoring_72: 'Dacă aveți produse complexe - vă putem ajuta să vă definiți strategia de preț',
    price_monitoring_73: 'Putem monitoriza prețurile individuale ale concurenților, de exemplu:',
    price_monitoring_74: 'Cost',
    price_monitoring_75: 'scăzut',
    price_monitoring_76: 'Eficiență',
    price_monitoring_77: 'înaltă',
    price_monitoring_78: 'raport Excel',
    price_monitoring_79: 'raport PPT',
    price_monitoring_80: 'Raport BI',
    price_monitoring_81: 'Datele privind prețurile competitorilor vă ajută să luați cele mai bune decizii de preț',
    price_monitoring_82: 'Oferim un raport complet foto, un raport PPT, un raport Excel în funcție de tipul de localitate.',
    qualitative_research_1: 'Cercetări',
    qualitative_research_2: 'calitative',
    qualitative_research_3: 'Colectăm, analizăm și interpretăm date prin observarea acțiunilor și cuvintelor clienților tăi',
    qualitative_research_4: 'Valori',
    qualitative_research_5: 'Comportament',
    qualitative_research_6: 'Istoric',
    qualitative_research_7: 'Așteptări',
    qualitative_research_8: 'Mediu',
    qualitative_research_9: 'Temeri/Bariere',
    qualitative_research_10: 'Care este motivul alegerii?',
    qualitative_research_11: 'Bărbat cu telefon',
    qualitative_research_12: 'marca',
    qualitative_research_13: 'categorie',
    qualitative_research_14: 'publicitate',
    qualitative_research_15: 'produs',
    qualitative_research_16: 'Unicitatea metodelor calitative constă în faptul că ne permit să înțelegem cauzele și motivația comportamentului consumatorilor',
    qualitative_research_17: 'Analiza atitudinii consumatorului față de un anumit fenomen',
    qualitative_research_18: 'Perspective și experiențe individuale',
    qualitative_research_19: 'Subiecte care nu pot fi investigate prin sondaje',
    qualitative_research_20: 'Subiecte sensibile',
    qualitative_research_21: 'Public greu de atins',
    qualitative_research_22: 'Geografie complexă',
    qualitative_research_23: 'Interviuri aprofundate utile pentru:',
    qualitative_research_24: 'Interviuri în profunzime',
    qualitative_research_25: 'Un interviu în profunzime este o tehnică de cercetare calitativă care implică realizarea de interviuri individuale cu respondenții pentru a explora perspectivele acestora asupra unei anumite idei, program sau situație. Un interviu aprofundat explorează experiențele / percepțiile / practicile individuale în detalii bogate.',
    qualitative_research_26: 'Beneficii:',
    qualitative_research_27: 'Caracterizarea normelor sociale și culturale',
    qualitative_research_28: 'Public greu de atins',
    qualitative_research_29: 'Partajarea și compararea (Morgan) investigate prin sondaje',
    qualitative_research_30: 'Subiecte care nu pot fi investigate prin sondaje',
    qualitative_research_31: 'Focus grupuri aprofundate utile pentru:',
    qualitative_research_32: 'Focus grup',
    qualitative_research_33: 'Explorarea profundă a întâlnirilor individuale, discuții emergente, discuții între manifestări sub influența moderatorului de grup.',
    qualitative_research_34: 'Generarea de perspective asupra experiențelor comune și a normelor sociale prin discuții de grup, date narative într-o discuție concentrată.',
    qualitative_research_36: 'Beneficii:',
    qualitative_research_37: 'Studierea stilului de viață și a comportamentului consumatorului în diferite condiții reale (acasă, la punctul de vânzare, la locul de muncă etc.)',
    qualitative_research_38: 'Căutați perspective pentru dezvoltarea de noi produse, ambalaje, îmbunătățirea serviciilor etc.',
    qualitative_research_39: 'Cercetare etnografică aprofundată utilă pentru:',
    qualitative_research_40: 'Cercetare etnografică',
    qualitative_research_41: 'Etnografia este un tip de cercetare calitativă care implică patrunderea într-o anumită comunitate sau organizație pentru a le observa comportamentul și interacțiunile de aproape.',
    qualitative_research_42: 'Etnografia este o metodă de cercetare flexibilă care vă permite să obțineți o înțelegere profundă a culturii, convențiilor și dinamicii sociale comune a unui grup. Cu toate acestea, implică și unele provocări practice și etice.',
    qualitative_research_43: 'Metode:',
    qualitative_research_44: 'Etnografia este un studiu al consumatorului în condiții de viață reală și interacțiune cu un produs/serviciu',
    qualitative_research_45: 'Beneficiile lucrului cu noi',
    qualitative_research_46: 'Putem găsi respondenți din orice public țintă. Și controlăm calitatea recrutării',
    qualitative_research_47: 'Recrutare de orice fel de complexitate',
    qualitative_research_48: 'Moderatorii noștri sunt poligloti și au abilități de facilitare',
    qualitative_research_49: 'Moderatori experți',
    qualitative_research_50: 'Lucrează online/offline',
    qualitative_research_51: 'Oferim audio/video',
    qualitative_research_52: 'înregistrări',
    qualitative_research_53: 'Putem efectua cercetări calitative în orice localitate',
    quantitative_research_1: 'Studiul satisfacției clienților',
    quantitative_research_2: 'cu un anumit produs sau serviciu (SCI, NPS)',
    quantitative_research_3: 'Efectuarea segmentării',
    quantitative_research_4: 'sondaje ale diferitelor piețe',
    quantitative_research_5: 'Cercetare de piata',
    quantitative_research_6: 'managementul NPS',
    quantitative_research_7: 'Analiza concurenței',
    quantitative_research_8: 'Cercetări',
    quantitative_research_9: 'cantitative',
    quantitative_research_10: 'Metodologie de cercetare care folosește pentru a testa teorii despre atitudinile și comportamentele oamenilor bazate pe date obiective, numerice și statistice',
    quantitative_research_11: 'ani',
    quantitative_research_12: 'de experiență CX la nivel mondial',
    quantitative_research_13: 'grup de respondenti',
    quantitative_research_14: 'al respondenţilor',
    quantitative_research_15: 'ţări',
    quantitative_research_16: 'derulăm proiecte în toată lumea',
    quantitative_research_17: 'Cine suntem noi',
    quantitative_research_18: '4Service este o companie globală de cercetare de marketing și CX. Oferim servicii de colectare a datelor pentru o varietate largă de afaceri, cu ajutorul experților noștri.',
    quantitative_research_19: 'Cifrele 4Service',
    quantitative_research_20: 'Asistat de calculator',
    quantitative_research_21: 'Interviu web',
    quantitative_research_22: 'O tehnică de chestionare bazată pe internet. Respondentul completează un chestionar electronic fără ajutorul unui intervievator. Chestionarul poate fi trimis electronic sau postat pe site.',
    quantitative_research_23: 'Interviu web asistat de calculator',
    quantitative_research_24: 'În cele mai multe cazuri',
    quantitative_research_25: 'această metodă este cea mai rentabilă din punct de vedere al costurilor de material și timp',
    quantitative_research_26: 'Această metodă permite',
    quantitative_research_27: 'într-un timp scurt intervievarea unui segment de consumatori specific, respectandu-se rigoarea științifică de eșantionare',
    quantitative_research_28: 'Oferă posibilități extinse',
    quantitative_research_29: 'pentru demonstrații materiale video și audio, precum și imagini',
    quantitative_research_30: 'Respondenții pot fi',
    quantitative_research_31: 'situati oriunde în lume',
    quantitative_research_32: 'Permite investigarea',
    quantitative_research_33: 'comportamentului specific al audienței internetului. Principalele rezultate pot fi accesate de client în timp real printr-o interfață web',
    quantitative_research_34: 'Eligibil pentru orice confidențialitate,',
    quantitative_research_35: 'Subiect sau problemă sensibilă și personală la care respondenții ar putea fi reticenți să răspundă când discută cu un intervievator',
    quantitative_research_36: '150 000+ panel',
    quantitative_research_37: 'Avem propriul nostru panel de respondenți din întreaga lume',
    quantitative_research_38: 'Abordam intrebari dificile',
    quantitative_research_39: 'Prin utilizarea unor instrumente complementare (social media, publicitate), putem găsi un public specific și un profil sofisticat al respondentului',
    quantitative_research_40: 'Ne motivăm respondenții',
    quantitative_research_41: 'Am creat un cadru pentru a încuraja completarea chestionarelor lungi, oferind stimulente materiale',
    quantitative_research_42: 'Interviuri față în față',
    quantitative_research_43: 'realizat prin utilizarea tabletei',
    quantitative_research_44: 'Aceasta este una dintre principalele metode de colectare a datelor cantitative, timp în care intervievatorul comunică direct cu respondentul pe un chestionar strict structurat.',
    quantitative_research_45: 'Interviurile față în față pot fi desfășurate la locul de muncă sau de reședință al respondenților, pe stradă sau în locurile de vânzare desemnate utilizand o tabletă',
    quantitative_research_46: 'Interviuri față în față realizate prin utilizarea tabletei',
    quantitative_research_47: 'Determinarea nivelului',
    quantitative_research_48: 'de popularitate și recunoaștere a mărcii',
    quantitative_research_49: 'Segmentarea consumatorilor',
    quantitative_research_50: 'pe baza comportamentului lor de cumpărare',
    quantitative_research_51: 'Analiza impactului',
    quantitative_research_52: 'campaniei de publicitate',
    quantitative_research_53: 'Satisfacția clientului',
    quantitative_research_54: 'măsurare',
    quantitative_research_55: 'Stabilirea celui mai bun preț',
    quantitative_research_56: 'pentru produs sau serviciu',
    quantitative_research_57: 'Analiză',
    quantitative_research_58: 'a preferințelor consumatorilor',
    quantitative_research_59: 'Studiu',
    quantitative_research_60: 'despre cum cumpără consumatorii',
    quantitative_research_61: 'Evaluare',
    quantitative_research_62: 'a capacitatii pietei',
    quantitative_research_63: 'Software-ul DigSee',
    quantitative_research_64: 'Folosim software special cu inregistrare audio si GPS',
    quantitative_research_65: 'Intervievatori și supraveghetori',
    quantitative_research_66: 'putem efectua interviuri în orice limbă și putem traduce în limba preferată a clientului',
    quantitative_research_67: 'Esantionare',
    quantitative_research_68: 'Departamentul de analiză dezvoltă un sistem de recrutare a respondenților pentru a obține cel mai bun rezultat posibil',
    quantitative_research_69: 'Asistat de calculator',
    quantitative_research_70: 'Interviu telefonic',
    quantitative_research_71: 'Metodologie de colectare a informațiilor cantitative prin interviuri telefonice folosind un chestionar clar structurat.',
    quantitative_research_72: 'Interviuri telefonice asistate de calculator',
    quantitative_research_73: 'Avem propriu nostru call-center',
    quantitative_research_74: 'Folosim software special cu inregistrare audio si GPS',
    quantitative_research_75: 'Operatori calificați',
    quantitative_research_76: 'Operatorii noștri vorbesc diferite limbi, sunt instruiți, bine testați și își îmbunătățesc în mod continuu abilitățile',
    quantitative_research_77: 'Baze de date',
    quantitative_research_78: 'Lucrăm cu propriile noastre baze de date și cu bazele dvs. de clienți, de asemenea',
    quantitative_research_79: 'SLA',
    quantitative_research_80: 'Garantăm calitatea și semnăm un SLA (Service Level Agreement)',
    quantitative_research_81: 'Stabilirea obiectivelor',
    quantitative_research_82: 'Determinarea metodologiei',
    quantitative_research_83: 'Segmentarea și eșantionarea',
    quantitative_research_84: 'Definirea canalelor de colectare a datelor',
    quantitative_research_85: 'Crearea unui chestionar logic pentru respondent',
    quantitative_research_86: 'Procesul de colectare a datelor',
    quantitative_research_87: 'Asigurarea calității',
    quantitative_research_88: 'Tehnici de analiză',
    quantitative_research_89: 'Procesarea datelor',
    quantitative_research_90: 'Designul sondajului',
    quantitative_research_91: 'Urmărirea locației prin GPS a intervievatorilor',
    quantitative_research_92: 'Verificarea datelor',
    quantitative_research_93: 'Programarea și testarea chestionarului',
    quantitative_research_94: 'Data validarii',
    quantitative_research_95: 'Înregistrări audio respectând cerințele GDPR',
    quantitative_research_96: 'Pregătirea și competența personalului de cercetare',
    quantitative_research_97: 'Asigurarea calității',
    quantitative_research_98: 'Analiza factorilor',
    quantitative_research_99: 'Segmentarea',
    quantitative_research_100: 'Cartografierea perceptivă',
    quantitative_research_101: 'Corelație',
    quantitative_research_102: 'Model de conversie',
    quantitative_research_103: 'Jaccard',
    quantitative_research_104: 'Analiza grupului',
    quantitative_research_105: 'etc.',
    quantitative_research_106: 'Rapoarte analitice',
    quantitative_research_107: 'Folosim SPSS® și alte instrumente pentru a construi rapoarte analitice folosind diferite metodologii',
    quantitative_research_108: 'Ce provocări de business pot fi rezolvate:',
    quantitative_research_109: 'Ce obiective de marketing pot fi atinse:',
    social_responsibility_1: 'Responsabilitatea Socială Corporativă a 4Service',
    social_responsibility_2: 'Din inima și sufletul fiecărui membru al echipei noastre. Încercăm să păstrăm o scânteie de bunătate în fiecare dintre angajații noștri și să facem lumea din jurul nostru un pic mai bună',
    social_responsibility_3: 'Activități educaționale / Sprijin pentru tineret',
    social_responsibility_4: 'Initiativele noastre de implicare socială pentru copii și studenți',
    social_responsibility_5: 'Service Mania - jocul de afaceri',
    social_responsibility_6: 'O parte importantă a activităților noastre de formare este jocul de afaceri Service Mania.',
    social_responsibility_7: 'Service Mania te provoacă să gândești strategic despre cum să gestionezi situații neobișnuite',
    social_responsibility_8: 'Prezentare cercetare / Diiya Business',
    social_responsibility_9: 'O perspectivă a afacerilor și a clienților asupra situației serviciilor: o prezentare a rezultatelor cercetării noastre 4Service de Victoria Skorbota, șeful de dezvoltare la 4Service Ucraina',
    social_responsibility_10: 'Sesiuni de Service Design',
    social_responsibility_11: 'Metodologia Service Design este o parte importantă a 4Service. În urmă cu câțiva ani, am devenit partener al Academiei DesignThinkers și nu numai că folosim cele mai bune practici în munca noastră, ci și oferim în mod activ formare celor mai bune companii.',
    social_responsibility_12: 'Caritate / Voluntariat',
    social_responsibility_13: 'Orfelinate, case de îngrijire pentru persoanele în vârstă și voluntariat pentru armata ucraineană. Cea mai emoționantă parte a activităților noastre',
    social_responsibility_14: '#AjutorUsorCuGrupul4Service',
    social_responsibility_15: 'Lucrăm în parteneriat cu Fundația de Caritate Blagomai și încercăm să aducem bucurie și ajutor celor care au nevoie',
    social_responsibility_16: 'Strângere de fonduri pentru orfelinate și case de bătrâni',
    social_responsibility_17: 'Noi, cei de la 4Service, am inițiat mai multe strângeri de fonduri pentru casele de bătrâni și orfelinate pentru a cumpăra produse de primă necesitate',
    social_responsibility_18: '#SalvatiUcraina',
    social_responsibility_19: 'Războiul a afectat mulți dintre angajații noștri și prietenii apropiați. Fiecare dintre noi ajută cât putem',
    social_responsibility_20: 'Bunăstarea animalelor',
    social_responsibility_21: 'Oricine poate veni la biroul nostru cu animalul de companie. Ajutăm adăposturi, strângem fonduri pentru hrană și, uneori, căutăm și case pentru animalele fără adăpost',
    social_responsibility_22: 'Sprijinirea adaposturilor de animale',
    social_responsibility_23: 'Animalele fără adăpost au nevoie de ajutor la fel de mult ca toți membrii vulnerabili ai societății noastre.',
    social_responsibility_24: 'Compania noastră a luat în grija noastră adăpostul pentru animale fără stăpân Sirius (regiunea Kiev, Ucraina).',
    social_responsibility_25: 'Inițiative creative pentru a ajuta animalele fără stăpân',
    social_responsibility_26: 'Cum putem aduce problema urgentă a adăposturilor de animale în atenția clienților și partenerilor noștri?',
    social_responsibility_27: 'Birou care acceptă animale de companie',
    social_responsibility_28: 'Mulți dintre membrii echipei noastre au animale de companie. Am creat un mediu sănătos în care toată lumea poate veni la birou cu animalele lor de companie și se poate simți confortabil',
    ux_testing_1: 'Testare UX',
    ux_testing_2: 'O abordare unitară bazată pe interacțiune umană și inteligență artificială pentru îmbunătățirea conținutului și utilizării website-urilor sau aplicațiilor',
    ux_testing_3: 'Ce este testarea UX',
    ux_testing_4: 'Testarea UX vă ajută să înțelegeți cum interacționează oamenii cu produsul, aplicația sau site-ul dvs',
    ux_testing_5: 'Navigarea nu este foarte clara',
    ux_testing_6: 'Habar n-am cum să fac o comandă',
    ux_testing_7: 'Nu sunt suficiente fotografii în catalog',
    ux_testing_8: 'Veți:',
    ux_testing_9: 'Descoperi oportunități de îmbunătățire',
    ux_testing_10: 'Identifica probleme în design și interacțiunea cu clienții',
    ux_testing_11: 'Aflați ce dificultăți întâmpină utilizatorul când interacționează cu site-ul',
    ux_testing_12: 'Observați călătoria clientului',
    ux_testing_13: 'Recunoașteți când și de ce publicul țintă părăsește site-ul',
    ux_testing_14: 'Definiți care secțiuni trezesc cel mai puțin și cel mai mare interes și emoție',
    ux_testing_15: 'Testare UX bazată pe utilizator',
    ux_testing_16: '4Service vă oferă informațiile umane reale de care aveți nevoie pentru a crea site-uri web și aplicații centrate pe client folosind metode calitative și cantitative',
    ux_testing_17: 'Nu inteleg cum sa folosesc site-ul. Unde este ascuns meniul?',
    ux_testing_18: 'Cred că există prea mult spațiu între litere, textul este greu de citit',
    ux_testing_19: 'Imaginile se suprapun una peste alta. Așa ar trebui să fie?',
    ux_testing_20: 'Testarea UX vă ajută să înțelegeți cum interacționează oamenii cu produsul, aplicația sau site-ul dvs',
    ux_testing_21: 'Design de cercetare de testare UX bazat pe utilizatori:',
    ux_testing_22: 'Descoperiți oportunități de îmbunătățire',
    ux_testing_23: 'Determinarea tipului și metodei de testare (calitativă/cantitativă)',
    ux_testing_24: 'Elaborarea de ipoteze și scenarii de utilizator',
    ux_testing_25: 'Analiza și raportarea rezultatelor testelor',
    ux_testing_26: 'Două soluții',
    ux_testing_27: 'Oferim două abordări pentru a testa cu succes experiența utilizatorului',
    ux_testing_28: 'Testare UX bazată pe utilizator',
    ux_testing_29: 'AI + testare UX bazată pe utilizator',
    ux_testing_30: 'Punctele noastre forte:',
    ux_testing_31: 'Putem analiza peisajul competitiv',
    ux_testing_32: 'Putem aduce experți specializați pentru a efectua și participa la interviuri',
    ux_testing_33: 'Putem efectua interviuri în diferite limbi și le putem traduce în limba necesară',
    ux_testing_34: 'cu utilitatea existentă',
    ux_testing_35: 'metrici sau standarde',
    ux_testing_36: 'performanța ta',
    ux_testing_37: 'împotriva unui concurent',
    ux_testing_38: 'versiuni',
    ux_testing_39: 'ale aceluiasi produs',
    ux_testing_40: 'Evaluare de satisfacție',
    ux_testing_41: 'NPS',
    ux_testing_42: 'Rate de succes',
    ux_testing_43: 'Rate de eroare',
    ux_testing_44: 'Timpul de finalizare a sarcinii',
    ux_testing_45: 'Testare UX bazată pe utilizator',
    ux_testing_46: 'Metoda cantitativă',
    ux_testing_47: 'Folosim cel puțin 100 de persoane pentru a participa la testarea UX pentru această etapă',
    ux_testing_48: 'Relevant atunci când aveți nevoie de o comparație a site-ului sau a aplicației dvs.:',
    ux_testing_49: 'Măsurarea metodei cantitative:',
    ux_testing_50: 'Observare',
    ux_testing_51: 'Petrecerea timpului cu un utilizator sau grup de utilizatori și observarea comportamentului acestora cu produsul pe măsură ce îl folosesc în viața de zi cu zi',
    ux_testing_52: 'Interviu aprofundat',
    ux_testing_53: 'Vă permit să aflați atitudinile, credințele, dorințele și experiențele utilizatorilor care vin pe site-ul dvs. Vă recomandăm să efectuați 15-20 de interviuri',
    ux_testing_54: 'Cercetare etnografică',
    ux_testing_55: 'Realizat în mediul respondenților care vor folosi produsul. Prin observare, începi să ai o bună înțelegere a psihologiei utilizatorului, a provocărilor emoționale cu care se confruntă.',
    ux_testing_56: 'Testare UX bazată pe utilizator',
    ux_testing_57: 'Metoda calitativă',
    ux_testing_58: 'Această metodă ajută la înțelegerea motivațiilor și a logicii comportamentului utilizatorilor',
    ux_testing_59: 'Folosim:',
    ux_testing_60: 'Elaborăm o hartă a călătoriei clienților pentru a ilustra rezultatele cercetării noastre',
    ux_testing_61: '30 de participanți (bărbați/femei)',
    ux_testing_62: 'Respondenții examinează în prealabil și îi invită să participe la sondaj',
    ux_testing_63: 'Interviu online de la distanță pe platforma Wantent',
    ux_testing_64: 'bazat pe AI + om',
    ux_testing_65: 'AI + testare UX bazată pe experiența umană',
    ux_testing_66: 'O combinație de tehnologie unică de inteligență artificială Wantent și utilizatori reali',
    ux_testing_67: 'Fonturile mari din titlu au atras atenția',
    ux_testing_68: 'Utilizatorii se uită la numerele «cu voce tare», dar nu citesc informațiile de lângă ele «г»',
    ux_testing_69: 'Testarea UX vă ajută să înțelegeți cum interacționează oamenii cu produsul, aplicația sau site-ul dvs',
    ux_testing_70: 'Design de cercetare pentru testare AI + experiență umană',
    ux_testing_71: 'Cum funcționează',
    ux_testing_72: 'Wantent este o soluție AI bazată pe potrivirea conținutului pe piață',
    ux_testing_73: 'Wantent estimează eficiența site-urilor web și a aplicațiilor prin aplicarea tehnologiilor ML pentru a analiza reacțiile emoționale și implicarea publicului.',
    ux_testing_74: 'Pentru a interpreta rezultatele, folosim tehnici de povestire, diversitate și empatie, pentru a stimula performanța și creșterea.',
    ux_testing_75: 'Atenția și emoțiile utilizatorului în timpul tranzițiilor prin secțiuni',
    ux_testing_76: 'Statistici pe grupul de participanți (atenție și concentrare pe tot parcursul sesiunii)',
    ux_testing_77: 'Analiza conversiilor paginilor',
    ux_testing_78: 'Atenție și emoții în timpul îndeplinirii sarcinilor',
    ux_testing_79: 'Constatări asupra percepției',
    ux_testing_80: 'ale website-ului de către diferite grupuri de participanți',
    ux_testing_81: 'Analiza ușurinței',
    ux_testing_82: 'a navigării prin pagini și ușurința găsirii elementelor importante',
    ux_testing_83: 'O analiză a percepției',
    ux_testing_84: 'a informațiilor furnizate pe site',
    ux_testing_85: 'Domenii de definire',
    ux_testing_86: 'de îmbunătățire în cadrul paginilor individuale (navigație, blocuri de informații etc.)',
    ux_testing_87: 'Analizăm în detaliu',
    ux_testing_88: 'Rezultatele cheie ale cercetării',
    ux_testing_89: 'Recomandări pentru îmbunătățirea structurii site-ului web și a paginilor individuale',
    ux_testing_90: 'Selectăm cu atenție publicul țintă pentru testarea utilizabilității.',
    ux_testing_91: 'Efectuăm cercetări în diferite țări, limbi diferite și între diferite grupuri de utilizatori',
    ux_testing_92: 'Putem testa în aplicații',
    ux_testing_93: 'și pe site-urile web ale concurenților și oferim analize comparative',
    ux_testing_94: 'Combinăm diferite metode de cercetare.',
    ux_testing_95: 'Moderatorii noștri calificați îndrumă participantul prin procesul de testare.',
    ux_testing_96: 'Facem teste',
    ux_testing_97: 'pe o varietate de dispozitive și versiuni de software',
    ux_testing_98: 'De ce sa ne alegeți pe noi?',
    wantent_1: '4Service x',
    wantent_2: 'Wantent',
    wantent_3: 'Un proiect partener unic de la 4Service and Wantent © O abordare în care este combinată inteligența artificială și experiența umană pentru o analiză eficientă a conținutului media',
    wantent_4: 'măsurarea succesului înainte ca conținutul să fie difuzat',
    wantent_5: 'atenuarea riscurilor problemelor legate de diversitate',
    wantent_6: 'perspective profunde și recomandări pentru îmbunătățirea conținutului',
    wantent_7: 'decizii obiective privind conținutul și strategia de marketing',
    wantent_8: 'Wantent este o soluție bazată pe inteligență artificială (AI) pentru potrivirea conținutului pe piață',
    wantent_9: 'Wantent este o soluție bazată pe inteligența artificială (AI) pentru potrivirea conținutului pe piață. Wantent evaluează eficacitatea conținutului video prin aplicarea tehnologiilor de învățare automată (ML) pentru a analiza răspunsul emoțional și angajamentul publicului',
    wantent_10: 'Wantent ajută la definirea potrivirii conținutului pe piață și la maximizarea eficienței conținutului prin:',
    wantent_11: 'Configurarea scenariului și recrutarea participanților',
    wantent_12: 'proiecte personalizate și public țintă la nivel mondial',
    wantent_13: 'Colectarea reacțiilor spectatorilor',
    wantent_14: 'feedback detaliat și clar din partea publicului',
    wantent_15: 'Analiza percepției conținutului',
    wantent_16: 'analiza comportamentului și a reacțiilor emoționale prin AI',
    wantent_17: 'Livrarea de informații pentru încheierea finală',
    wantent_18: 'recomandări pentru îmbunătățiri',
    wantent_19: 'Wantent maximizează eficiența conținutului dvs',
    wantent_20: 'Testare preliminară a conceptului publicitar',
    wantent_21: 'Testarea conceptului creativ',
    wantent_22: 'Verificarea coerenței viitoarei reclame cu strategia dezvoltată și înțelegerea reacțiilor publicului țintă pentru posibile ajustări ale conceptelor/mesajelor creative',
    wantent_23: 'Testarea vizibilității elementelor mărcii (post-producție)',
    wantent_24: 'Confirmarea eficacitatii investitiei in publicitate/sponsorizare',
    wantent_25: 'Testarea reclamelor',
    wantent_26: 'Testare A/B',
    wantent_27: 'Compararea eficacității reclamelor și creșterea angajamentului și a atenției în timpul fazei de producție',
    wantent_28: 'S-a lansat testarea reclamelor',
    wantent_29: 'Definirea performantei mesajelor si crearea de recomandari pentru imbunatatirea audio si vizuala',
    wantent_30: 'Solutii pentru TV',
    wantent_31: 'Piloți, promoții, noi formate, testare prezentatori TV',
    wantent_32: 'Efectuarea unei analize comparative pentru a o identifica pe cea mai captivantă și pentru a o consolida',
    wantent_33: 'Testare de emisiuni TV și lungmetraje',
    wantent_34: 'Analizarea implicării telespectatorilor, a nivelului de atenție, a răspunsului emoțional în timpul vizionării conținutului lung în condiții naturale. Găsim automat varful culminant al audienței și momentele de distragere a atenției pentru a evalua și îmbunătăți montarea video',
    wantent_35: 'Testare UI/UX',
    wantent_36: 'Prototipuri de site-uri web și testare de aplicații mobile',
    wantent_37: 'Analiza reacției și emoțiilor utilizatorului folosind tehnologia Wantent:',
    wantent_38: 'nivelul de atentie',
    wantent_39: 'reacții emoționale',
    wantent_40: 'hărți termice ale direcției privirii participanților',
    wantent_41: 'Analiza de utilizare a site-ului și a aplicației pe baza feedback-ului participanților:',
    wantent_42: 'Scala de utilizare a sistemului (SUS)',
    wantent_43: 'Scorul de efort al clientului (ușurință de interacțiune)',
    wantent_44: 'Scor Net de Promovare (NPS)',
    wantent_45: 'Conformitatea GDPR și confidențialitatea utilizatorilor',
    wantent_46: 'Wantent respectă pe deplin politicile GDPR și CCPA și respectă principiile de colectare, stocare, prelucrare și protecție a datelor cu caracter personal ale participanților.',
    wantent_47: 'Nikita Lobyntsev',
    wantent_48: 'СDO, Reface (media și divertisment)',
    wantent_49: 'Noi experimentăm zilnic cu tone de idei noi de conținut, folosind tehnologii de învățare automată. Wantent ne ajută să înțelegem și să evaluăm riscurile și avantajele, să descoperim feedback-ul, comportamentul și percepția utilizatorilor pentru a obține o cauzalitate a diferitelor aspecte ale soluțiilor noastre.',
    wantent_50: 'Alexander Smirnov',
    wantent_51: 'coproprietar TABASCO (Publicitate)',
    wantent_52: 'Wantent oferă un serviciu cu adevărat unic care ne ajută să cunoaștem detalii minore despre ceea ce le place sau nu le place consumatorilor. Nu există nicio modalitate de a înșela, nu există nicio modalitate de a  minți - Wantent știe întotdeauna adevărul, iar noi, ca experți în marketing, primim ghiduri neprețuite (hahaha - știm prețul exact și este accesibil) despre cum să îmbunătățim comunicările noastre.',
    wantent_53: 'Recenzii',
    terms_1: 'Termeni si conditii',
    terms_2: 'Acordul privind termenii de utilizare',
    terms_3: 'Vă rugăm să citiți cu atenție acești Termeni de utilizare („termeni de utilizare”, „acord”) înainte de a utiliza site-ul web',
    terms_4: '(«site-ul web») operat de 4Service Holdings GmbH («4Service», «noi», «noi», «nostru»).  «c»',
    terms_5: 'Condițiile de utilizarePrin utilizarea acestui site web, certificați că ați citit și revizuit acest Acord și că sunteți de acord să respectați termenii săi. Dacă nu doriți să fiți obligat de termenii acestui Acord, vă sfătuim să părăsiți site-ul corespunzător. 4Service acordă doar utilizarea și accesul la acest site web, produsele sale și serviciile sale celor care au acceptat termenii săi.',
    terms_6: 'Politica de confidențialitate',
    terms_7: 'Înainte de a continua să utilizați site-ul nostru, vă sfătuim să citiți Politica noastră de confidențialitate cu privire la colectarea datelor noastre despre utilizatori. Vă va ajuta să înțelegeți mai bine practicile noastre.',
    terms_8: 'Restricție de vârstă',
    terms_9: 'Trebuie să aveți cel puțin 16 (șaisprezece) ani pentru a utiliza acest site web. Prin utilizarea acestui site web, garantați că aveți cel puțin 16 (șaisprezece) ani și că puteți respecta legal acest Acord. 4Service nu își asumă responsabilitatea pentru obligațiile legate de reprezentarea eronată a vârstei.',
    terms_10: 'Proprietate intelectuală',
    terms_11: 'Sunteți de acord că toate materialele, produsele și serviciile furnizate pe acest site web sunt proprietatea 4Service, a afiliaților, directorilor, ofițerilor, angajaților, agenților, furnizorii sau licențiatorii săi, inclusiv toate drepturile de autor, secretele comerciale, mărcile comerciale, brevetele și alte proprietăți intelectuale. . De asemenea, sunteți de acord că nu veți reproduce sau redistribui proprietatea intelectuală a 4Service în niciun fel, inclusiv înregistrări electronice, digitale sau noi mărci comerciale.',
    terms_12: 'Accesarea site-ului nostru',
    terms_13: 'Accesul la Site-ul nostru este permis temporar și ne rezervăm dreptul de a retrage sau de a modifica serviciul pe care îl oferim pe site-ul nostru fără notificare. Nu vom fi răspunzători dacă, din orice motiv, site-ul nostru este indisponibil în orice moment sau pentru orice perioadă. Din când în când, este posibil să restricționăm accesul la unele părți ale site-ului nostru, sau la întregul nostru site, utilizatorilor care s-au înregistrat la noi. Sunteți responsabil să faceți toate aranjamentele necesare pentru a avea acces la site-ul nostru, inclusiv utilizarea echipamentelor care sunt compatibile cu site-ul nostru. De asemenea, sunteți responsabil pentru a vă asigura că toate persoanele care accesează site-ul nostru prin conexiunea dumneavoastră la internet sunt la curent cu acești termeni și că îi respectă.',
    terms_14: 'Lege aplicabilă',
    terms_15: 'Vizitând acest site web, sunteți de acord că legile Republicii Austria, fără a ține cont de principiile legilor conflictuale, vor guverna acești termeni și condiții sau orice dispută de orice fel care ar putea apărea între 4Service și dumneavoastră.',
    terms_16: 'Conflicte',
    terms_17: 'Orice dispută legată în orice mod de vizita dumneavoastră pe acest site web va fi arbitrată de Curtea Comercială din Viena.',
    terms_18: 'Despăgubiri',
    terms_19: 'Sunteți de acord să despăgubiți 4Service și afiliații săi și să lăsați 4Service inofensiv față de pretențiile legale și cererile care pot apărea din utilizarea sau utilizarea necorespunzătoare a site-ului nostru. Ne rezervăm dreptul de a ne alege propriul consilier juridic.',
    terms_20: 'Perioada de depozitare',
    terms_21: 'Ținând cont de scopurile prelucrării, perioada de stocare a datelor cu caracter personal ale Utilizatorilor (perioada de stocare) este de 24 de luni de la data la care consimțământul pentru prelucrarea datelor este obținut în mod corespunzător de la Dvs.',
    terms_22: 'Limitarea răspunderii',
    terms_23: '4Service nu este responsabil pentru nicio dauna care vi se poate produce ca urmare a utilizării greșite a site-ului nostru. 4Service își rezervă dreptul, fără notificare, de a edita, modifica și schimba în orice moment acest Acord prin actualizarea acestei postări. Utilizarea în continuare a site-ului web acum sau în urma postării oricăror modificări sau schimbari, va indica acceptarea de către dumneavoastră a acestor modificări sau schimbari. Dacă orice parte a acestui acord este declarată ilegală, nulă sau inaplicabilă, acea parte va fi considerată separabilă și nu va afecta valabilitatea și aplicabilitatea oricăror prevederi rămase. Eventualele dovezi ale utilizării site-ului web în scopuri ilegale vor fi furnizate autorităților de aplicare a legii. Acest acord este o înțelegere între 4Service și utilizator.',
    terms_24: 'Vă rugăm să trimiteți toate întrebările și preocupările legate de confidențialitate/utilizare la următoarea adresă:',
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Tegetthoffstrasse 7',
    terms_27: '1010 Viena',
    terms_28: 'Austria',
    terms_29: 'Revizuire',
    terms_30: 'Atunci când datele personale pe care ni le furnizați nu sunt inexacte, aveți dreptul să ne cereți să le corectăm într-adevăr (articolul 16 GDPR).',
    thank_you_1: 'Mulțumesc!',
    thank_you_2: 'Vă vom contacta în termen de 24 de ore',
    thank_you_3: 'P.S. Managerii noștri examinează deja solicitarea dvs. pentru a crea o ofertă mai bună',
    thank_you_4: 'Meniu',
    thank_you_5: 'Femeie tastand',
    privacy_policy_1: 'Formular de notificare privind confidențialitatea datelor pentru persoanele vizate (utilizatorii site-ului web)',
    privacy_policy_2: 'Acest formular de notificare privind confidențialitatea (Notificare de confidențialitate) este destinat persoanelor fizice (persoanele vizate) ale căror date cu caracter personal sunt colectate de 4Service Holdings GmbH, în conformitate cu cerințele GDPR în cadrele de vizitare a site-ului web',
    privacy_policy_3: '(de aici după site-ul web și, respectiv, utilizatorii site-ului web/utilizatorii).',
    privacy_policy_4: 'Formularul de notificare privind confidențialitatea pentru persoanele vizate (utilizatorii site-ului web) ai 4Service Holdings GmbH este aplicat în toate companiile grupului 4Service, care se află sub autoritatea legală, sub supravegherea sau controlată de 4Service Holdings GmbH.',
    privacy_policy_5: 'Contacte',
    privacy_policy_6: '4Service Holdings GmbH',
    privacy_policy_7: '(Companie/noi/noi),',
    privacy_policy_8: 'Adresă:',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Vienna, Austria.',
    privacy_policy_10: 'E-mail:',
    privacy_policy_12: 'Cine suntem noi?',
    privacy_policy_13: 'O persoană fizică (Dvs.) care vizitează site-ul web cu orice scop. În scopul prezentului Document, veți fi indicat și ca Utilizator.',
    privacy_policy_14: 'Datele personale pe care le colectăm de la dumneavoastră',
    privacy_policy_15: 'Când intrați pe site-ul nostru web, vă trimitem o notificare de confidențialitate, în care ne oferim să vă cerem să ne furnizați consimțământul pentru prelucrarea următoarelor informații:',
    privacy_policy_16: 'Adresă IP, numele utilizatorului, prenume, adresă, număr de telefon (fix sau mobil), adresă de e-mail, adresă, denumire companie, țară, adresă de e-mail, nume, prenume, număr de telefon, provincie, stat și cod poștal/ Cod poștal, date de utilizare, date despre interacțiunea cu rețelele sau platformele sociale externe, informații despre înregistrare și autentificare pe site',
    privacy_policy_17: 'pozitia geografica.',
    privacy_policy_18: 'În cazul în care vă exprimați voința și ne dați un astfel de consimțământ, începem să procesăm astfel de informații de la dvs.',
    privacy_policy_19: 'Legalitate (consimțământ)',
    privacy_policy_20: 'Când accesați site-ul nostru web, vă trimitem o notificare de confidențialitate în care vă cerem să ne oferiți consimțământul pentru prelucrarea următoarelor informații:',
    privacy_policy_21: 'Temeiul legal pentru prelucrarea datelor cu caracter personal este consimțământul, pe care îl vom primi de la dvs. prin completarea formularului de consimțământ de la următorul link:',
    privacy_policy_22: 'Dacă completați formularul de consimțământ, aceasta înseamnă că înțelegeți și acceptați toate condițiile specificate în această Notificare de confidențialitate.',
    privacy_policy_23: 'Retragerea consimțământului',
    privacy_policy_24: 'Aveți dreptul de a retrage în orice moment consimțământul pe care ni l-ați furnizat anterior. Retragerea consimțământului nu va afecta legalitatea prelucrării bazate pe consimțământ înainte de retragerea acestuia. Vă puteți retrage consimțământul trimițându-ne cererea corespunzătoare la următoarea adresă de e-mail withdraw@4service-group.com, prin prezenta, formularul de solicitare este disponibil la următorul link:',
    privacy_policy_25: 'Formular de retragere pentru utilizator',
    privacy_policy_26: 'Scopurile prelucrarii',
    privacy_policy_27: 'Prelucrăm datele dumneavoastră cu caracter personal în următoarele scopuri:',
    privacy_policy_28: '– îmbunătățirea serviciilor clienților (permite un răspuns mai eficient la solicitările clienților); – personalizarea experienței Utilizatorilor (permite să se determine cine este mai interesant în servicii); – îmbunătățirea Site-ului (permite îmbunătățirea calității produselor și serviciilor, confortul utilizării acestora, dezvoltarea de noi Servicii, îmbunătățirea produselor și serviciilor noastre); – comunicați cu Utilizatorul cu buletine informative, materiale de marketing sau promoționale și alte informații care includ știri, actualizări, informații despre servicii, cu observația privind instrucțiunile despre cum să refuzați primirea de e-mailuri ulterioare; – efectuarea de cercetări și analize statistice și de altă natură pe baza datelor anonimizate; – furnizarea de servicii personalizate Utilizatorului și îndeplinirea acordurilor și contractelor; – participarea Utilizatorului la diverse proiecte implementate de noi prin intermediul Site-ului, răspunsul la întrebările adresate de Utilizator prin intermediul Site-ului, cercetarea, întreținerea conturilor și înregistrărilor și promovarea serviciilor.',
    privacy_policy_29: 'Subiecții cărora le pot fi transferate datele personale',
    privacy_policy_30: 'În timpul procesării datelor dumneavoastră cu caracter personal, transferăm datele dumneavoastră cu caracter personal către entitățile care acționează ca procesatori ai Companiei. Procesatorii Companiei acționează exclusiv pe baza instrucțiunilor Companiei. Specificul acțiunilor pe care procesatorii Companiei le desfășoară și lista acestor procesatori pot fi găsite în Politica de confidențialitate și protecția datelor la următorul link: Politica de confidențialitate și protecție a datelor',
    privacy_policy_31: 'Țările în care datele personale pot fi transferate',
    privacy_policy_32: 'Compania transferă datele dumneavoastră cu caracter personal pe baza unei decizii de adecvare, astfel cum este stipulată de GDPR și Comisia UE. Informații suplimentare privind transferul de date cu caracter personal către SUA pot fi găsite în Politica de confidențialitate și protecția datelor la următorul link: Politica de confidențialitate și protecție a datelor.',
    privacy_policy_33: 'Perioada de depozitare',
    privacy_policy_34: 'Ținând cont de scopurile prelucrării, perioada de stocare a datelor cu caracter personal ale Utilizatorilor (perioada de stocare) este de 24 de luni de la data la care consimțământul pentru prelucrarea datelor este obținut în mod corespunzător de la Dvs.',
    privacy_policy_35: 'Dreptul de acces',
    privacy_policy_36: 'Aveți dreptul de a ști dacă datele cu caracter personal care vă privesc sunt în curs de prelucrare și 2) dacă da, accesați aceste date cu o mulțime de prevederi suplimentare menționate în articolul 15 GDPR.',
    privacy_policy_37: 'Dreptul la rectificare',
    privacy_policy_38: 'Atunci când datele personale pe care ni le furnizați nu sunt inexacte, aveți dreptul să ne cereți să le corectăm într-adevăr (articolul 16 GDPR).',
    privacy_policy_39: 'Dreptul la ștergere (dreptul de a fi uitat)',
    privacy_policy_40: 'Aveți dreptul de a obține de la noi ștergerea datelor dumneavoastră cu caracter personal fără întârzieri nejustificate și vom avea obligația de a vă șterge datele cu caracter personal fără întârzieri nejustificate, în cazul în care se aplică motivele menționate în articolul 17 din GDPR.',
    privacy_policy_41: 'Dreptul la restricționarea prelucrării',
    privacy_policy_42: 'Aveți dreptul de a limita prelucrarea datelor dumneavoastră cu caracter personal, cu mai multe excepții în domeniul de aplicare al GDPR, în special menționat în articolul 18 din GDPR.',
    privacy_policy_43: 'Suntem obligați să vă informăm ce date sunt colectate, cum sunt utilizate, cât timp vor fi păstrate și dacă vor fi partajate cu terți. Aceste informații trebuie comunicate concis și într-un limbaj simplu.',
    privacy_policy_44: 'Dreptul la portabilitatea datelor',
    privacy_policy_45: 'Vi se permite să obțineți și să reutilizați datele dumneavoastră cu caracter personal în propriile scopuri prin diferite servicii.',
    privacy_policy_46: 'Dreptul de a obiecta',
    privacy_policy_47: 'Aveți dreptul de a vă opune prelucrării datelor cu caracter personal care sunt prelucrate de către Companie. Trebuie să încetăm prelucrarea datelor cu caracter personal cu excepția cazului în care demonstrăm temeiuri legitime convingătoare pentru prelucrare care prevalează asupra intereselor, drepturilor și libertăților persoanei sau dacă prelucrarea este pentru stabilirea sau exercitarea apărării unor pretenții legale.',
    privacy_policy_48: 'Dreptul de a nu fi supus unei decizii bazate exclusiv pe prelucrare automată',
    privacy_policy_49: 'Aveți dreptul de a vă opune oricărei profilări automate care are loc fără consimțământ. Prin prezenta, aveți dreptul ca datele dumneavoastră cu caracter personal să fie prelucrate cu implicarea umană.',
    privacy_policy_50: 'Reclamații',
    privacy_policy_51: 'În cazul în care doriți să depuneți o plângere cu privire la modul în care datele dumneavoastră cu caracter personal sunt prelucrate de către Companie (sau de către procesatori, descrise mai sus) sau despre modul în care reclamația dumneavoastră a fost tratată, aveți dreptul de a depune o plângere direct la autoritatea de supraveghere și Companie.',
    privacy_policy_52: 'Detaliile pentru fiecare dintre aceste contacte sunt:',
    privacy_policy_53: 'Autoritatea de supraveghere:',
    privacy_policy_54: 'Autoritatea austriacă pentru protecția datelor',
    privacy_policy_55: 'Österreichische Datenschutzbehörde',
    privacy_policy_56: 'Wickenburggasse 8',
    privacy_policy_57: '1080 Viena',
    privacy_policy_58: 'Austria / Europa',
    privacy_policy_59: 'Companie:',
    privacy_policy_60: '4Service Holdings GmbH (Companie/noi/noi),',
    privacy_policy_61: 'Address: Tegetthoffstraße 7, 1010 Vienna, Austria.',
    privacy_policy_62: 'E-mail:',
    privacy_policy_63: 'Politica de confidențialitate și protecție a datelor',
    privacy_policy_64: 'Citiți mai multe despre cum și de ce vă folosim datele aici: Politica de confidențialitate și protecție a datelor',
    privacy_policy_65: 'Aprobare',
    privacy_policy_66: 'Compania a elaborat toate documentele interne pentru a defini rolurile în rândul personalului cu privire la prelucrarea datelor cu caracter personal în cadrul Companiei, în special responsabil pentru aprobarea și revizuirea legitimității acestui document este Directorul General.',
    contacts_1: 'Pentru clienti',
    contacts_2: 'Pentru toate tipurile de afaceri, agenții de cercetare de piață și companii mari, parteneri de afaceri și mass-media.',
    contacts_3: 'Str. Lt.-col. Dumitru Papazoglu 96, București 031205',
    contacts_4: 'Pentru Cumpărători',
    contacts_5: 'Localnici și călători, șoferi și studenți, cumpărători misterioși profesioniști și oameni de casă.',
    contacts_6: 'Contacteaza-ne din tara ta:',
    contacts_7: 'Italia',
    contacts_8: 'STATELE UNITE ALE AMERICII',
    contacts_9: 'Regatul Unit',
    contacts_10: 'Elveţia',
    contacts_11: 'Olanda',
    contacts_12: 'Slovacia',
    contacts_13: 'Slovenia',
    contacts_14: 'România',
    contacts_15: 'AZ',
    contacts_16: 'Ucraina',
    contacts_17: 'Kazahstan',
    contacts_18: 'Restul lumii',
    menu_1: 'CJM',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'BHT',
    menu_5: 'Testare UX',
    menu_6: 'OR',
    menu_7: 'Cercetare calitativa',
    menu_8: 'Cercetare cantitativă',
    menu_9: 'Cumpărături misterioase',
    menu_10: 'voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Monitorizarea prețurilor',
    menu_14: 'Despre noi',
    menu_15: 'Contacte',
    menu_16: 'Blog',
    menu_17: 'Metode',
    menu_18: 'Produse',
    menu_19: 'Shoppers \n platform',
    menu_20: 'CSR',
    nps_passives: 'Pasivii',
    nps_detractors: 'Detractorii',
    nps_promoters: 'Promotorii',
    nps_a_score: 'un scor',
    area: 'Zonă',
    products_tabs_1: 'Pentru a obține o eficiență maximă, desfășurăm și în complex:',
    products_tabs_2: 'Produse',
    products_tabs_3: 'Cercetarea calitativă este colectarea și analizarea datelor nenumerice pentru a înțelege concepte, opinii sau experiențe',
    products_tabs_4: 'Metodele de cercetare cantitativă reprezintă o măsurare obiectivă și o analiză numerică a datelor colectate prin sondaje, chestionare și anchete.',
    our_clients_1: 'Clienții noștri',
    preview_cases_section_1: 'Cazuri',
    preview_cases_section_2: 'Accesați cazurile pentru a citi mai multe postări interesante',
    blog_1: 'Blog cu conținut emoționant',
    blog_2: 'Nu există postări disponibile pentru cererea dvs.',
    about: 'Despre',
    why_us: 'De ce noi',
    about_us: 'Despre noi',
    key_features: 'Caracteristici cheie',
    solutions: 'Soluții',
    monitoring: 'Monitorizare',
    how: 'Cum',
    how_it_works: 'Cum funcționează',
    when_to_use: 'Când să utilizați',
    mystery_shopping: 'Cumpărături misterioase',
    voicer: 'Voicer',
    wantent: 'Wantent',
    play4sales: 'Play4Sales',
    price_monitoring: 'Monitorizarea prețurilor',
    service_design: 'Design de servicii',
    qualitative_research: 'Cercetare calitativa',
    quantitative_research: 'Cercetare cantitativă',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Automobile',
    hashtag_retayl: '#Cu amănuntul',
    hashtag_all_posts: '#Toate_postările',
    hashtag_feedback: '#părere',
    hashtag_4Service_csr: '#4Service_CSR',
    hashtag_cases: '#cazuri',
    hashtag_market_research: '#piata_cercetare',
    more: 'Mai mult',
    speak_to_an_expert: 'Vorbește cu un expert',
    book_consultancy: 'Comandați o consultație',
    read_all: 'Citeste tot',
    find_out_more: 'Află mai multe',
    address: 'Adresă',
    mon_fri: 'Luni-Vineri',
    phone_number: 'Număr de telefon',
    flag: 'steag',
    scroll_to_left: 'derulați la stânga',
    online: 'online',
    tel: 'Tel',
    email: 'E-mail',
    light_it_up: '«Aprinde-l»',
    about_us_60: 'Alina Andreieva',
    about_us_61: 'Director de dezvoltare a afacerii',
    about_us_62: 'Julia Kravchenko',
    about_us_63: 'Manager senior de proiecte',
    about_us_64: 'Olga Aksonova',
    about_us_65: 'Manager Dezvoltare Afaceri',
    about_us_66: 'Zhelevskiy Dmitriy',
    about_us_67: 'Director de operatiuni',
    about_us_roles_1: 'management',
    about_us_roles_2: 'Echipa globală',
    about_us_roles_3: 'Proprietari de produse',
    ad_testing_1: 'Testare campanie publicitara',
    ad_testing_2: 'O campanie de publicitate este o investiție uriașă. Asigurați-vă că campaniile dvs. publicitare sunt puternice și oferă rentabilitate maximă a investiției',
    ad_testing_3: 'Lacăt',
    ad_testing_4: 'Industriile în care activăm',
    ad_testing_5: '4Service este un holding global de cercetare privind experiența clienților',
    ad_testing_6: '4Service este cercetare globală de marketing și holding CX. Oferim servicii de colectare de date expert pentru o mare varietate de afaceri',
    ad_testing_7: 'Cash & Carry',
    ad_testing_8: 'Cu amănuntul',
    ad_testing_9: 'Electronice',
    ad_testing_10: 'Lux/Premium',
    ad_testing_11: 'Restaurante și ospitalitate',
    ad_testing_12: 'Automobile',
    ad_testing_13: 'Călătorii și agrement',
    ad_testing_14: 'Modă',
    ad_testing_15: 'Bănci',
    ad_testing_16: 'Ceasuri și bijuterii',
    ad_testing_17: 'Uz casnic și electrocasnice',
    ad_testing_18: 'Membru Esomar',
    ad_testing_19: 'Am efectuat cercetări de testare a reclamelor cu privire la publicitate pentru diverse companii din diferite domenii',
    ad_testing_20: 'Aveți nevoie de testare campanie publicitară dacă sunteți',
    ad_testing_21: 'Promovarea unui nou produs sau serviciu',
    ad_testing_22: 'Atingerea unui subiect sensibil',
    ad_testing_23: 'Încercarea de a atinge o nouă țintă demografică sau piață',
    ad_testing_24: 'Discutarea mai multor opțiuni de design diferite',
    ad_testing_25: 'Aveți nevoie de o dovadă de concept pentru echipele de management sau investitori',
    ad_testing_26: 'Veți primi răspunsuri la întrebări',
    ad_testing_27: 'Ce canale de publicitate sunt cele mai potrivite pentru aceste reclame?',
    ad_testing_28: 'Folosim imaginile potrivite pentru a transmite mesajul?',
    ad_testing_29: 'Textele și mesajele dvs. publicitare rezonează?',
    ad_testing_30: 'Ce canale de publicitate sunt cele mai potrivite pentru aceste reclame?',
    ad_testing_31: 'Ce le atrage atenția în primul rând?',
    ad_testing_32: 'Este ușor de înțeles plasarea și aspectul anunțului?',
    ad_testing_33: 'Evaluăm',
    ad_testing_34: 'Branding',
    ad_testing_35: 'modul în care marca dvs. este legată de publicitate și cât de mult va fi retinuta',
    ad_testing_36: 'Valori cheie',
    ad_testing_37: 'Integrare',
    ad_testing_38: 'Recunoaştere',
    ad_testing_39: 'Potrivi',
    ad_testing_40: 'Creativ',
    ad_testing_41: 'Cum iese în evidență reclama ta și cum atrage atenția oamenilor',
    ad_testing_42: 'Valori cheie',
    ad_testing_43: 'RECUNOAŞTERE',
    ad_testing_44: 'AFINITATE',
    ad_testing_45: 'MTO',
    ad_testing_46: 'MOTIVAȚIE',
    ad_testing_47: 'Experiența clientului, implicare emoțională',
    ad_testing_48: 'Cu ajutorul tehnologiei de inteligență artificială și a analiștilor experți, analizăm emoțiile reale ale oamenilor și obținem informații despre percepțiile lor despre publicitate',
    ad_testing_49: 'Evaluare',
    ad_testing_50: 'Masculin',
    ad_testing_51: 'Feminin',
    ad_testing_52: 'Implicare mare',
    ad_testing_53: 'Atenţie',
    ad_testing_54: 'Ce i-a plăcut publicului și și-a amintit?',
    ad_testing_55: 'Actriţă',
    ad_testing_56: 'Egalitate',
    ad_testing_57: 'Fundal muzical',
    ad_testing_58: 'Emoții pozitive',
    ad_testing_59: 'Oameni de diferite rase și aspect',
    ad_testing_60: 'Fotografii în aer liber',
    ad_testing_61: 'Ce NU i-a plăcut publicului și și-a amintit?',
    ad_testing_62: 'Vocea vorbitorului nu se potrivea cu ceea ce se întâmpla pe ecran',
    ad_testing_63: 'Atenție redusă la produs',
    ad_testing_64: 'Prea dinamic',
    ad_testing_65: 'Greu de înțeles',
    ad_testing_66: 'TRP',
    ad_testing_67: 'Impresii',
    ad_testing_68: 'Spectacole',
    ad_testing_69: 'Conversie',
    ad_testing_70: 'Mass-media',
    ad_testing_71: 'Măsurăm eficiența unei campanii de publicitate în legătură cu activitatea media, calculând impactul investițiilor media pe diferite canale asupra rezultatelor monitorizate.',
    ad_testing_72: 'Efectuăm cercetări de publicitate în orice format, platformă sau canal',
    ad_testing_73: 'conținut video',
    ad_testing_74: 'în aer liber',
    ad_testing_75: 'reclame TV',
    ad_testing_76: 'imprimare',
    ad_testing_77: 'digital',
    ad_testing_78: 'campanii publicitare',
    ad_testing_79: 'Afișare POS și publicitate',
    ad_testing_80: 'animatie',
    ad_testing_81: 'Cu ajutorul nostru, vă puteți testa campania de publicitate în orice etapă',
    ad_testing_82: 'Concept',
    ad_testing_83: 'care idei au o capacitate mai puternică?',
    ad_testing_84: 'Producție în stadiu incipient',
    ad_testing_85: 'Cum pot fi produse animațiile previzualizate?',
    ad_testing_86: 'Productie',
    ad_testing_87: 'cum să editez un videoclip astfel încât să rețină atenția?',
    ad_testing_88: 'Pre-media',
    ad_testing_89: 'ce canale de comunicare vor funcționa și ce rezultat se poate aștepta?',
    ad_testing_90: 'Post-testarea campaniei publicitare',
    ad_testing_91: 'Productie',
    ad_testing_92: 'este reclama mea captivantă? Eficiența creativității scade în timp?',
    ad_testing_93: 'Pre-media',
    ad_testing_94: 'cât de eficientă este campania mea publicitara în cifre? cum se compara cu concurenta?',
    ad_testing_95: 'Testare prealabilă a campaniei publicitare',
    ad_testing_96: 'Pentru a vă furniza date de înaltă calitate, utilizăm',
    ad_testing_97: 'Metodologia cercetării cantitative',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'Metodologia cercetării calitative',
    ad_testing_100: 'Focus grup',
    ad_testing_101: 'AI',
    ad_testing_102: 'Wantent',
    ad_testing_103: 'Rapoartele analitice pe care le primiți',
    ad_testing_104: 'Echipa noastră de analiști, experți media, marketeri vă va pregăti și vă va prezenta un raport personalizat cu recomandări detaliate',
    ad_testing_age: 'Vârstă',
    ad_testing_ad: 'Campanie publicitara',
    automotive_industry_price_analysis_1: 'Automotive Industry Price Analysis',
    automotive_industry_price_analysis_2: 'Competitive price analysis can help prevent lost profits, increase market share, and monitor your market position. Allow us to assist you in selecting a successful competitive strategy!',
    automotive_industry_price_analysis_3: 'Request a quote',
    automotive_industry_price_analysis_4: 'Our methodology',
    automotive_industry_price_analysis_5: 'Stylish elegant people car salon',
    automotive_industry_price_analysis_6: 'The Mystery Shopping method is the only approach that accurately reflects a real customer experience and provides a credible overview.',
    automotive_industry_price_analysis_7: 'We offer precise figures and dependable multilateral data',
    automotive_industry_price_analysis_8: 'We have global coverage spanning every country in the world',
    automotive_industry_price_analysis_9: 'Our in-house resources include mystery shoppers and auditors',
    automotive_industry_price_analysis_10: 'We validate the quality of our data',
    automotive_industry_price_analysis_11: 'Our advantages:',
    automotive_industry_price_analysis_12: 'Price monitoring for specific competitive models (B2B and B2C)',
    automotive_industry_price_analysis_13: 'Monitoring the level of discount',
    automotive_industry_price_analysis_14: 'Monitoring retailer stock for your brand and competitors',
    automotive_industry_price_analysis_15: 'Monitoring e-commerce automotive prices',
    automotive_industry_price_analysis_16: 'Price communication analysis',
    automotive_industry_price_analysis_17: 'Keeping track of retailer shipping charges',
    automotive_industry_price_analysis_18: 'Monitoring promotions and sales at retailers',
    automotive_industry_price_analysis_19: 'Determining monthly payment amounts',
    automotive_industry_price_analysis_20: 'Calculating lease payments',
    automotive_industry_price_analysis_21: 'Analyzing specific pricing considerations for electric vehicles (EVs)',
    automotive_industry_price_analysis_22: 'Conducting direct-to-consumer versus retailer price comparisons',
    automotive_industry_price_analysis_23: 'Developing subscription pricing models',
    automotive_industry_price_analysis_24: '4Service is a global customer experience (CX) holding company with over 20 years of experience',
    automotive_industry_price_analysis_25: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_26: "4Service's analysis of prices in the automotive industry includes:",
    automotive_industry_price_analysis_27: "Lowest prices aren't always the key to competitiveness for car brands. Let us help you create a long-term pricing strategy",
    automotive_industry_price_analysis_28: 'of customers understand that prices are increasing across all brands',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'of people consider leasing and monthly payments to be the most preferred model for buying a car for them',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'of dealers mentioned the possibility of price increases during the communication/selling process',
    automotive_industry_price_analysis_35: '47.6',
    automotive_industry_price_analysis_36: 'Key figures',
    automotive_industry_price_analysis_37: "You'll figure out how to price your product relative to other vehicles in the lineup.",
    automotive_industry_price_analysis_38: "You'll use data on consumers' willingness to pay for different features to optimize your offer and identify the most important features for your target customers.",
    automotive_industry_price_analysis_39: 'of customers said they would still purchase a car they like, even if the brand informed them of an upcoming price increase',
    automotive_industry_price_analysis_40: "You'll be able to determine the appropriate monthly payment and leasing rates.",
    automotive_industry_price_analysis_41: 'What benefits for your brand or dealer will you gain:',
    automotive_industry_price_analysis_42: 'You submit an application',
    automotive_industry_price_analysis_43: 'Our manager contacts you to determine the main objectives',
    automotive_industry_price_analysis_44: 'We conduct a marketing research',
    automotive_industry_price_analysis_45: 'We regularly provide you with reports and recommendations in an online format',
    automotive_industry_price_analysis_46: 'Car with sale sticker',
    automotive_industry_price_analysis_47: 'We launch the project in 1 day!',
    automotive_industry_price_analysis_48: 'We offer a comprehensive set of reports including photo reports, PPT reports, and Excel reports, segmented by the type of locality',
    retail_audit_1: 'AUDIT DE MARKETING DE TEREN/Audit de retail',
    retail_audit_2: 'Colectați date detaliate despre magazinul sau brandul dvs., analizați obstacolele de creștere și creați strategii eficiente pentru a le depăși',
    retail_audit_3: 'Solicitați o ofertă',
    retail_audit_4: '4Service efectuează audituri de magazine la fața locului în numele brandurilor pentru a evalua eficacitatea strategiilor lor promoționale, a echipelor de executare de teren și a tacticii de marketing în atingerea pieței țintă prevăzute.',
    retail_audit_5: 'Observăm meticulos executarea campaniilor promoționale și ne asigurăm că se respectă ghidurile promoționale.',
    retail_audit_6: 'Top 10',
    retail_audit_7: 'Agenții CX din Europa',
    retail_audit_8: 'ani de experiență',
    retail_audit_9: 'acoperire în țări',
    retail_audit_10: '1,6 milioane',
    retail_audit_11: 'evaluări pe an',
    retail_audit_12: 'Ce urmărim?',
    retail_audit_13: 'Prezența materialelor punctului de vânzare (POS)',
    retail_audit_14: 'Prezența și activarea ambasadorilor de brand, asigurând respectarea programului planificat',
    retail_audit_15: 'Evaluarea implicării ambasadorilor de brand în campaniile de susținere',
    retail_audit_16: 'Evaluarea interacțiunii cu consumatorul, asigurând respectarea ghidurilor promoționale și a mesajelor de brand',
    retail_audit_17: 'Măsuri de prevenire a fraudelor',
    retail_audit_18: 'Afacerea dvs. poate folosi rezultatele unui audit de marketing de teren pentru:',
    retail_audit_19: 'Evaluarea conformității cu bugetul și procesele',
    retail_audit_20: 'Compare actual results with intended objectives',
    retail_audit_21: 'Îmbunătățirea performanței campaniilor de marketing ',
    retail_audit_22: 'Planificarea strategică pentru viitor',
    retail_audit_23: 'Identificarea oportunităților de reducere a costurilor',
    retail_audit_24: 'Îmbunătățirea vânzărilor și ratei de conversie',
    retail_audit_25: 'Optimizarea randamentului investițiilor (ROI)',
    retail_audit_26: 'EI AU ALES 4SERVICE CA ȘI PARTENERUL LOR DE INVESTIȚII',
    retail_audit_27: 'Un important producător internațional de produse din tutun a colaborat cu 4Service pentru a-și îmbunătăți mărcile și a-și îmbunătăți performanța în magazine. Echipele noastre au furnizat audit de marketing pe teren și raportare live a experienței consumatorilor.',
    retail_audit_28: 'creștere în acuratețea planificării a agenției de marketing de teren',
    retail_audit_29: 'creștere în livrarea mesajului de brand',
    retail_audit_30: 'creștere în angajamentul BA (activarea consumatorului)',
    retail_audit_31: 'creștere în acuratețea colectării datelor și a conformității GDPR',
    retail_audit_32: 'Ce insight-uri valoroase urmărim să găsim:',
    retail_audit_33: 'Lipsa de consecvență sau chiar conflictul cu privire la termenii SLA duce la plata unor taxe semnificative către client',
    retail_audit_34: 'Perceperea incorectă a taxelor din cauza amestecării tipurilor de servicii furnizate',
    retail_audit_35: 'Serviciul pe teren nu este efectuat, inclusiv personalul care nu este repartizat la locația planificată | POS',
    retail_audit_36: 'Cheltuieli efectuate pe baza unei estimări mai degrabă decât în ​​baza reală și nereconciliate',
    retail_audit_37: 'Aplicarea incorectă a ghidului promoțional care furnizează date frauduloase',
    retail_audit_38: 'Cum lucrăm noi?',
    retail_audit_39: 'Auditorul nostru vizitează locații desemnate ale punctelor de vânzare (POS) pe baza planului de activare al agenției.',
    retail_audit_40: 'Aceștia evaluează indicatorii cheie de performanță (KPI) așa cum sunt subliniați în brief, cum ar fi prezența POS, implicarea ambasadorului mărcii, conformitatea cu instrucțiunile promoționale și măsurile de prevenire a fraudei.',
    retail_audit_41: 'Validare:',
    retail_audit_42: 'Colectăm și consolidăm datele obținute.',
    retail_audit_43: 'Se redactează un raport comprehensiv',
    retail_audit_44: 'Echipa noastră de validare analizează cu atenție toate sondajele pentru acuratețe, inclusiv conformitatea cu descrierea proiectului și orice comentarii suplimentare',
    retail_audit_45: 'Sistem de raportare online:',
    retail_audit_46: 'Chestionarele completate și validate sunt încărcate pe portalul nostru online în 36 de ore.',
    retail_audit_47: 'Clienții au acces la portal printr-un cont personal, permițându-le să monitorizeze progresul în orice moment',
    retail_audit_48: 'Oferim un raport final care conține rezultate și recomandări.',
    retail_audit_49: 'Discută cu un expert',
    retail_audit_50: 'Punctele noastre forte:',
  },
  kz: {
    form_title: 'Мы свяжемся с вами в ближайшее время',
    form_required: 'Поля, отмеченные *, обязательны для заполнения',
    form_name_placeholder: 'Имя',
    form_email_placeholder: 'Электронная почта',
    form_phone_placeholder: 'Номер телефона',
    form_company_placeholder: 'Компания',
    form_submit: 'Отправить',
    form_1: 'Даю согласие на обработку персональных данных согласно',
    form_2: 'Политика конфиденциальности',
    validation_privacy_policy: 'Пожалуйста, примите Политику конфиденциальности',
    validation_phone: 'Номер телефона недействителен',
    validation_email: 'Неверный формат электронной почты',
    validation_required: 'Обязательные поля не заполнены',
    submit_error: 'Произошла ошибка при сохранении формы',
    footer_1: 'Наши партнеры:',
    footer_2: 'Методы',
    footer_3: 'Продукты',
    footer_4: 'Контакты:',
    footer_5: 'Выходные данные',
    footer_6: 'Политика конфиденциальности',
    about_us_1: 'Корпоративная социальная ответственность 4Service',
    about_us_2: 'Из глубины сердца и души каждого участника нашей команды. Мы прилагаем максимум усилий, чтобы сохранить искру доброты в каждом из наших сотрудников и сделать мир вокруг нас немного лучше.',
    about_us_3: 'Корпоративная социальная ответственность 4Service',
    about_us_4: 'Из глубины сердца и души каждого участника нашей команды. Мы прилагаем максимум усилий, чтобы сохранить искру доброты в каждом из наших сотрудников и сделать мир вокруг нас немного лучше.',
    about_us_5: 'Деловая игра Service Mania',
    about_us_6: 'Наши информационные инициативы для детей и студентов',
    about_us_7: 'Благотворительность/волонтерство',
    about_us_8: 'Детские дома, дома престарелых и волонтерство в украинской армии. Самая трогательная часть нашей деятельности',
    about_us_9: 'Благополучие животных',
    about_us_10: 'К нам в офис может прийти любой желающий с питомцем. Мы помогаем приютам, собираем средства на еду, а иногда и пытаемся найти дом для бездомных животных',
    about_us_11: 'Группа 4Service',
    about_us_12: 'Международная исследовательская компания, занимающаяся управлением клиентским опытом.',
    about_us_13: 'Реализуем комплексные проекты для увеличения продаж и развития бизнеса клиентоориентированных компаний.',
    about_us_14: 'Является международным исследовательским холдингом с главным офисом в Вене, Австрия. Компания была основана в 2001 году с целью улучшения качества жизни своих клиентов и людей, взаимодействующих с бизнесом, использующих его услуги и потребляющих его продукцию. В конце концов, это не случайно выбрано имя "Forservice" для компании, которая представляет обслуживание клиентов и хорошее качество.',
    about_us_15: 'Это был начало истории 4Service в Киеве, Украина, как ведущего поставщика услуг Mystery Shopping. Она продолжает успешно работать как исследовательская компания в 52 странах, открывая новые возможности в комплексном подходе к анализу и улучшению всей цепочки клиентского опыта.',
    about_us_16: 'рожденный во имя сервиса',
    about_us_17: 'первый офис за пределами Украины',
    about_us_18: 'перенос штаб-квартиры в Вену',
    about_us_19: 'элитный член MSPA, топ-10 агентств в Европе',
    about_us_20: 'открытие офисов в 9 странах',
    about_us_21: 'первые цифровые проекты, Voicer',
    about_us_22: 'партнеры Академии DesignThinkers',
    about_us_23: 'первые глобальные CХ-проекты',
    about_us_24: 'Миссия',
    about_us_25: 'Наша миссия — улучшать обслуживание клиентов по всему миру! Нас вдохновляют качественные изменения, которые мы наблюдаем в людях и компаниях, с которыми работаем.',
    about_us_26: 'более 20ти лет опыта работы с клиентами',
    about_us_27: '95 стран',
    about_us_28: 'Мы собираем и делимся лучшим отраслевым опытом со всего мира',
    about_us_29: 'Главный офис в Вене',
    about_us_30: 'страны. Мы собираем и делимся лучшим отраслевым опытом со всего мира',
    about_us_31: 'оценка качества обслуживания',
    about_us_32: 'мы входим в топ-10 европейских CX-агентств',
    about_us_33: 'представительства/штаб-квартира в Австрии',
    about_us_34: 'активные проекты',
    about_us_35: 'Ваше будущее место работы',
    about_us_36: 'Наши офисы',
    about_us_37: '4Service ™ прикладывает усилия для создания комфортного рабочего пространства для своих сотрудников. Наши сотрудники работают в офисе, удаленно или в комбинированном формате, в зависимости от наилучшего удобства и продуктивности каждого сотрудника. У нас есть комнаты для отдыха, бесплатная психологическая поддержка и дружественная атмосфера. Мы открыты для людей и домашних животных.',
    about_us_38: 'Блестящая команда',
    about_us_39: 'Наша международная команда стремится слушать и откликаться на обратную связь от наших клиентов, а также к взаимному развитию. Карта путешествия клиента с нами так же важна для нашего бизнеса, как запуск новых продуктов или достижение финансовых целей. Наши действия направлены на то, чтобы оказывать положительное влияние на сообщества вокруг нас и предлагать инновационные решения, ориентированные на потребности людей, для наших клиентов.',
    about_us_40: 'Все',
    about_us_41: 'Управление',
    about_us_42: 'Всемирная команда',
    about_us_43: 'Владельцы продукта',
    about_us_44: 'Наши партнеры',
    about_us_45: 'Реализуем комплексные проекты по увеличению продаж и развитию бизнеса клиентоориентированных компаний',
    about_us_46: 'является одним из ведущих институтов профессионального обучения, ориентированных на дизайн, представленных в 25 странах и обучающих, развивающих и поддерживающих творческие междисциплинарные команды и сообщества для достижения позитивных изменений. Дизайн-мышление позволяет разрабатывать и предлагать инновационные идеи, изменения и решения сложных проблем.',
    about_us_47: 'Их миссия - предоставлять пространство и время для вдохновения и вызовов командам и отдельным лицам, чтобы они могли "учиться, делая" и укреплять свою способность к креативному решению проблем, а также помогать организациям создавать необходимую среду и мышление для запуска смелых инноваций.',
    about_us_48: 'является представительской торговой ассоциацией для компаний, занимающихся индустрией "Mystery Shopping". Ассоциация работает на региональной основе по всему миру, с разделениями, расположенными в Америке, Европе/Африке и Азии/Тихом океане. Компании-члены объединяются как единое целое с целью укрепления индустрии "Mystery Shopping" через совместные усилия и действия.',
    about_us_49: 'это некоммерческая членская организация. Участники образуют сообщество, убежденное в потенциале анализа данных, исследований и идей, которые могут помочь улучшить общество, организации и жизнь каждого человека.',
    about_us_50: 'Штаб-квартира ESOMAR находится в Амстердаме, Нидерланды. ESOMAR присутствует в более чем 130 странах через своих членов с 1947 года.',
    about_us_51: '(UCCA) - профессиональное сообщество частных специалистов и компаний, объединенных целью развития и взаимодействия в сфере контакт-центра и обслуживания клиентов. Философия UCCA — повышение культуры индустрии контакт-центров на основе современных технологий и высоких стандартов.',
    about_us_52: 'Украинская ассоциация контакт-центров основана в 2010 году',
    about_us_53: 'изучает лучший мировой опыт в сфере образования, чтобы адаптировать и использовать его на украинском рынке. Именно поэтому Академия ДТЭК сотрудничает с ведущими международными бизнес-школами: INSEAD, IE Business School и Stanford University.',
    about_us_54: 'Академия ДТЭК ориентирована на развитие клиентоориентированной и инновационной культуры в D.Client School, ID School, Executive Education: Energy of Innovation, Power of Communications, цифровой программе DX School, Effective Leader: Pre-MBA, Change Energy.',
    about_us_55: 'Сергей Пашковский',
    about_us_56: 'Виктория Скорбота',
    about_us_57: 'Артем Фомин',
    about_us_58: 'Макс Папка',
    about_us_59: 'Менеджер по международным продажам',
    bht_1: 'Здоровье бренда',
    bht_2: 'Отслеживание',
    bht_3: 'Бренды обладают невероятной силой',
    bht_4: 'Узнайте, насколько хорошо работает ваш бренд в целом',
    bht_5: 'О программе',
    bht_6: 'Что такое отслеживание здоровья бренда?',
    bht_7: 'Это тип исследования рынка, которое проводится с определенной периодичностью (каждые две недели, каждый месяц и т. д.) с целью получения информации о',
    bht_8: 'статусе бренда на рынке на основе таких показателей, как известность, спрос, имидж, характеристики его потребителей, здоровье бренда и т. д.',
    bht_9: 'Покупают бренд чаще всего',
    bht_10: 'Верный',
    bht_11: 'Купил бренд на 3 месяца',
    bht_12: 'Репертуар / постоянные клиенты',
    bht_13: 'Купил бренд на 6 месяцев',
    bht_14: 'Клиенты в краткосрочной перспективе',
    bht_15: 'Купил бренд на год',
    bht_16: 'Клиенты в долгосрочной перспективе',
    bht_17: 'Знаете о бренде? Но никогда не использовали/не покупали его',
    bht_18: 'Знаю, но не являюсь клиентом',
    bht_19: 'Не знаю: никогда не слышал о бренде',
    bht_20: 'Не знаю',
    bht_21: 'Профиль покупателей бренда',
    bht_22: 'Оценка последовательности усилий, приложенных в долгосрочной перспективе для создания бренда',
    bht_23: 'Измерение уровня знаний клиентов о бренде',
    bht_24: 'Демонстрация эффективности рекламных и информационных кампаний',
    bht_25: 'Определение влияния периода высокой рекламы на продажи/маркетинговую деятельность конкурентов',
    bht_26: 'Обеспечение постоянного контроля владельца бизнеса в периоды непрерывных рыночных изменений',
    bht_27: 'Какие задачи может решить BHT:',
    bht_28: 'Запросить исследование',
    bht_29: 'Решаемая задача',
    bht_30: 'Например',
    bht_31: 'если компании требуется всего одна сессия BHT в квартал при измерении доли рынка, то опрос респондентов следует проводить чаще при измерении знания потребителя о бренде из рекламы.',
    bht_32: 'CATI',
    bht_33: 'Лицом к лицу',
    bht_53: 'Интервью',
    bht_34: 'CAWI',
    bht_35: 'Решаемая задача',
    bht_36: 'Собирая информацию',
    bht_37: 'с помощью различных методов, отслеживание бренда обычно делится на три этапа в зависимости от целевой аудитории и сложности ее охвата:',
    bht_38: 'Для сбора данных мы используем как традиционные, так и современные методы:',
    bht_39: 'Мы работаем с двумя моделями: первая с простым набором показателей, а вторая нацелена на более сложные отрасли, такие как пищевая промышленность и сфера услуг. Более сложная модель предполагает углубленное исследование, включающее до 10 оценочных показателей.',
    bht_40: 'Мы предлагаем',
    bht_41: 'контроль исходных данных',
    bht_42: 'Мы покрываем',
    bht_43: 'всю страну',
    bht_44: 'Мы предлагаем решение',
    bht_45: 'для нединамичных рынков/рынков с ограниченным количеством игроков (Brand Point Analyze)',
    bht_46: 'Почему мы?',
    bht_47: 'Почему выбирают нас?',
    bht_50: 'Плохой результат',
    bht_51: 'Хороший результат',
    bht_52: 'Средний результат',
    case_1: 'Задача: как повысить качество обслуживания клиентов при покупке электромобилей в автосалонах Hyundai?',
    case_2: 'Карта пути клиента',
    case_3: 'Идея',
    case_4: 'Качественное исследование',
    case_5: 'Вызов: Что нужно изменить в магазинах (как в процессе обслуживания, так и в интерьере, в восприятии бренда), чтобы вызвать интерес покупателей к бренду Oppo?',
    case_6: 'Онлайн CJM',
    case_7: 'Тестирование пользовательского интерфейса',
    case_8: 'Интервью',
    case_9: 'Вызов: Что нужно изменить в магазинах, чтобы вызвать интерес покупателей к бренду Oppo?',
    case_10: 'Качественная часть',
    case_11: 'Идея',
    case_12: 'Количественная часть',
    case_13: 'Карта пути клиента',
    case_14: 'Кейсы',
    case_15: 'Все',
    case_16: 'Давайте создадим ваш отличный кейс вместе',
    csi_1: 'Клиент',
    csi_2: 'индекс удовлетворенности',
    csi_3: 'Определить причины для потребителей',
    csi_4: 'удовлетворенность и неудовлетворенность',
    csi_5: 'Исследование удовлетворенности клиентов',
    csi_6: 'определение факторов, причин и мотивов, влияющих на решение покупателя о покупке, а также на поведение целевой аудитории.',
    csi_7: 'Опрос удовлетворенности клиентов',
    csi_8: 'показывает, насколько клиент удовлетворен или не удовлетворен определенным аспектом услуги или продуктом в целом.',
    csi_9: 'Опрос удовлетворенности',
    csi_10: 'проводится среди существующих клиентов компании и пользователей определенных продуктов/услуг.',
    csi_11: 'Что такое CSI?',
    csi_12: 'Удовлетворенность клиентов измеряет степень удовлетворения ожиданий клиентов в отношении покупки продукта или услуги, предоставляемых вашей компанией.',
    csi_13: 'Счастливый человек',
    csi_14: 'О программе',
    csi_15: 'Определить любые скрытые факторы',
    csi_16: 'это может повлиять на удовлетворенность клиентов',
    csi_17: 'Определить причины',
    csi_18: 'любого роста/падения спроса на товары и услуги',
    csi_19: 'Оптимизировать компанию',
    csi_20: 'бизнес-процессы',
    csi_21: 'Адаптировать их',
    csi_22: 'для нужд современного потребителя',
    csi_23: 'Сформировать эффективное рекламное сообщение',
    csi_24: 'и позиционировать товар под запрос клиента',
    csi_25: 'Задачи для решения',
    csi_26: 'Какие задачи могут решить опросы об удовлетворенности клиентов',
    csi_27: 'Человек с телефоном',
    csi_28: 'Мы строим математическую модель',
    csi_29: 'факторов, которые могут повлиять на удовлетворенность/лояльность клиентов (регрессионный анализ)',
    csi_30: 'Оптимизировать',
    csi_31: 'бизнес-процессы компании',
    csi_32: 'Мы строим расчетный индекс удовлетворенности клиентов',
    csi_33: '(CSI) и сравниваем его с декларативным',
    csi_34: 'Мы проводим исследование',
    csi_35: 'для сегментов B2B и B2C.',
    csi_36: 'Наши анкеты разрабатываются командой',
    csi_37: 'аналитиков специально для индивидуальных проектов наших клиентов',
    csi_38: 'Почему мы',
    csi_39: 'Почему выбирают нас?',
    csi_40: 'Методы сбора информации:',
    main_1: 'улучшить свои финансовые показатели, изучая опыт своих клиентов',
    main_2: 'СX консалтинг и услуга взгляда 360° на ваш бизнес',
    main_3: 'Ознакомьтесь с нашим ассортиментом решений и инструментов, которые мы используем для решения ваших бизнес-задач',
    main_4: 'Товары',
    main_5: 'Качественное исследование — это сбор и анализ нечисловых данных для понимания концепций, мнений или опыта',
    main_6: 'Количественные методы исследования — это объективное измерение и числовой анализ данных, собранных с помощью опросов и анкетирования',
    main_7: 'Тайный покупатель — это метод исследования рынка для измерения и мониторинга взаимодействия между покупателем и компанией или организацией в рамках заранее определенного сценария',
    main_8: 'Система управления обратной связью. Голос вашего клиента в одной системе Customer Feedback Management System',
    main_9: 'Платформа для анализа контента на основе ИИ, предоставляющая информацию от аудитории',
    main_10: 'Программное обеспечение для управления производительностью. Геймифицированное приложение для увеличения продаж',
    main_11: 'Ценовой мониторинг — это процесс сбора, обработки и анализа цен конкурентов',
    main_12: 'Сервис-дизайн — это понимание потребности и желания людей, которые будут пользоваться продуктом или услугой, проводить с ними время',
    main_13: 'Наша аналитическая команда применяет самые современные и фундаментальные подходы, которые доказали свою эффективность на многих клиентах',
    main_14: 'Методологии',
    main_15: 'Карта пути клиента',
    main_16: 'Customer Journey Mapping (CJM) — это определение целевой аудитории, понимание их потребностей и среды, а затем создание плана для обеспечения привлекательного опыта',
    main_17: 'CJM',
    main_18: 'Чистая оценка продвижения',
    main_19: 'Показатель Net Promoter Score (NPS) — это золотой стандарт показателей качества обслуживания клиентов, основанный на одном вопросе: насколько вероятно, что вы порекомендуете организацию/продукт/услугу?',
    main_20: 'NPS',
    main_21: 'Отслеживание здоровья бренда',
    main_22: 'Исследование Brand Health Tracking (BHT) может измерить, как ваш бренд работает с точки зрения узнаваемости и использования, позиционирования бренда и эффективности бренда',
    main_23: 'ВНТ',
    main_24: 'Исследования человеческих ресурсов',
    main_25: 'Исследования человеческих ресурсов используются для оценки практики и эффективности работы с персоналом и предлагают подробный анализ текущего развития и управления',
    main_26: 'Кадровые исследования',
    main_27: 'Индекс удовлетворенности клиентов',
    main_28: 'Индекс удовлетворенности клиентов (CSI) – это показатель, показывающий, насколько ваши клиенты удовлетворены продуктами/услугами компании.',
    main_29: 'CSI.',
    main_30: 'UX-тестирование',
    main_31: 'Юзабилити-тестирование (UX) — это наблюдение за тем как реальные люди взаимодействуют с веб-сайтом, приложением или другим продуктом, их поведением и реакцией на него',
    main_32: 'Почему мы',
    main_33: 'Наша миссия — помочь предприятиям обеспечить высокоэффективный клиентский опыт',
    main_34: 'Качество - прежде всего',
    main_35: 'Мы контролируем качество',
    main_36: 'когда дело доходит до данных исследований. Мы делаем все наборы данных доступными для клиентов',
    main_37: 'Экономично',
    main_38: 'Наш опыт, ресурсы и оптимизация',
    main_39: 'бизнес-процессы позволяют нам экономить ваши деньги и время',
    main_40: 'Умные решения',
    main_41: 'Мы используем разные техники',
    main_42: 'и инструменты для решения ваших конкретных проблем',
    main_43: 'Упор на качество',
    main_44: 'Мы контролируем качество',
    main_45: 'когда дело доходит до исследовательских данных. Мы делаем все наборы данных доступными для клиентов',
    main_46: 'Эффективно и экономно',
    main_47: 'Наш опыт, ресурсы и оптимизация',
    main_48: 'бизнес-процессы позволяют нам экономить ваши деньги и время',
    main_49: 'Умные решения',
    main_50: 'Мы используем разные техники',
    main_51: 'и инструменты для решения ваших конкретных проблем',
    main_52: 'Cash & Carry',
    main_53: 'Электроника',
    main_54: 'Люкс/Премиум',
    main_55: 'Рестораны и гостиничный бизнес',
    main_56: 'Розничная торговля',
    main_57: 'Автомобилестроение',
    main_58: 'Мода',
    main_59: 'Банки',
    main_60: 'Путешествия и отдых',
    main_61: 'Часы и украшения',
    main_62: 'Бытовая техника',
    main_63: 'Блог',
    main_64: 'Перейдите в блог, чтобы прочитать больше интересных сообщений',
    main_65: 'Розничный аудит',
    cjm_1: 'Клиент',
    cjm_2: 'Карта путешествия',
    cjm_3: 'Погрузитесь в мир клиентов и поделитесь их опытом',
    cjm_4: 'Что такое карта пути клиента?',
    cjm_5: 'CJM предоставляет визуализированную историю взаимодействия потребителя с продуктом, услугой, компанией или брендом по различным каналам и в течение определенного периода времени.',
    cjm_6: 'Это позволяет компаниям объективно анализировать опыт взаимодействия с клиентами, фиксировать и устранять возникающие барьеры и давать рекомендации по улучшению продукта.',
    cjm_7: 'Определение ТА, сегментация',
    cjm_8: 'Выявление этапов взаимодействия с клиентом',
    cjm_9: 'Выявление основных каналов взаимодействия с клиентами',
    cjm_10: 'Исследование клиентского опыта',
    cjm_11: 'Анализ барьеров и гипотеза по оптимизации',
    cjm_12: 'Выполнение и проверка гипотез',
    cjm_13: 'Создание карты пути клиента состоит из следующих шагов:',
    cjm_14: 'Карта пути клиента продемонстрирует:',
    cjm_15: 'CJM предоставляет визуализированную историю взаимодействия потребителя с продуктом, услугой, компанией или брендом по различным каналам и в течение определенного периода времени.',
    cjm_16: 'Мы обеспечиваем командную работу',
    cjm_17: 'между аналитиками и дизайнерами',
    cjm_18: 'Предлагаем индивидуальный',
    cjm_19: 'подход к каждому клиенту',
    cjm_20: 'В целом мы можем увеличить',
    cjm_21: 'удовлетворенность клиентов услугой/продуктом и, в конечном итоге, повышение их лояльности к компании',
    cjm_22: 'Мы гарантируем',
    cjm_23: 'оптимизацию пути потребителя и учет бизнес-процессов компании',
    cjm_24: 'Мы предлагаем нашим клиентам',
    cjm_25: 'возможность использования комбинированных подходов: CATI, CAWI, фокус-группы, глубинные интервью, экспертные интервью, этнография и др.',
    cjm_26: 'Почему выбирают нас?',
    method_hr_1: 'Исследования человеческих ресурсов',
    method_hr_2: 'Получите объективную информацию о своей команде и узнайте, как улучшить работу отдела кадров',
    method_hr_3: 'Некоторые причины, по которым вам может понадобиться HR-исследование',
    method_hr_4: 'из опрошенных сотрудников',
    method_hr_5: 'реализованы проекты hr',
    method_hr_6: 'было проведено обучение персонала',
    method_hr_7: 'Ведущий европейский холдинг в области клиентского опыта и исследований сотрудников',
    method_hr_8: 'Кадровые исследования 4service в цифрах',
    method_hr_9: 'Выявление проблем и слабых сторон на каждом этапе жизни сотрудников вашей компании: поиск/подбор/адаптация/карьерный рост/переаттестация',
    method_hr_10: 'Чтобы знать, в каких случаях заслуживают прибавок и бонусов',
    method_hr_11: 'Вам необходимо прогнозировать или предлагать решения неудовлетворенности сотрудников',
    method_hr_12: 'Благодаря HR-исследованиям возможны новые и современные методы оценки кадровой политики, программ и практик',
    method_hr_13: 'Для улучшения психического здоровья сотрудников компании',
    method_hr_14: 'Если вы хотите повысить производительность с помощью целеустремленного подхода',
    method_hr_15: 'Если у вас есть международная и/или удаленная команда, и вам нужно ею управлять',
    method_hr_16: 'Чтобы понять, насколько привлекателен ваш бренд работодателя для потенциальных сотрудников',
    method_hr_17: 'Удовлетворенность сотрудников',
    method_hr_18: 'Исследование HR-брендинга',
    method_hr_19: 'Культурное разнообразие',
    method_hr_20: 'Компенсации и зарплатные планы',
    method_hr_21: 'Вовлеченность и лояльность команды',
    method_hr_22: 'Сотрудники защищенного класса',
    method_hr_23: 'Области исследований',
    method_hr_24: 'Решение 1',
    method_hr_25: 'eNPS, удовлетворенность сотрудников, лояльность сотрудников',
    method_hr_26: 'Что мы измеряем:',
    method_hr_27: 'Лояльность:',
    method_hr_28: 'лояльность сотрудников, прогнозирование будущего поведения, определение веса факторов, влияющих на лояльность',
    method_hr_29: 'Участие:',
    method_hr_30: 'вовлеченность сотрудников в работу, заинтересованность в результатах и достижениях',
    method_hr_31: 'Вовлечение:',
    method_hr_32: 'идентификация ценностей сотрудников и ценностей компании',
    method_hr_33: 'Доверие 360:',
    method_hr_34: 'доверие к руководству, коллегам, подчиненным, доверие к стратегиям и политике работодателя',
    method_hr_35: 'Удовлетворение:',
    method_hr_36: 'определение уникальных факторов для каждой компании, оценка удовлетворенности по каждому ключевому фактору, влияющему на лояльность',
    методы_hr_37: 'Решение 2',
    method_hr_38: 'Бренд работодателя',
    method_hr_39: 'Внутренний работодатель',
    method_hr_40: 'анализ бренда (опрос сотрудников, стейкхолдеров, внутренних рекрутеров и HR-специалистов)',
    method_hr_41: 'Внешний анализ работодателя',
    method_hr_42: '(анализ EVP конкурентов, опрос внешних рекрутеров, опрос соискателей)',
    method_hr_43: 'Узнаваемость бренда и репутация',
    method_hr_44: 'анализ',
    method_hr_45: 'Присутствие работодателя',
    method_hr_47: 'Справочник работодателя',
    методы_hr_48: 'коллекция',
    method_hr_49: 'Конкурентный бенчмаркинг',
    method_hr_50: 'по ключевым факторам, таким как',
    method_hr_51: 'Зарплата',
    method_hr_52: 'рабочая среда, атмосфера, характер работы',
    method_hr_53: 'Анализ потребностей и ожиданий',
    method_hr_54: 'соискателей',
    method_hr_55: 'Подробные интервью',
    method_hr_56: 'используются кабинетные и количественные исследования',
    методы_hr_57: 'Решение 3',
    method_hr_58: 'Карта пути сотрудника/тайный сотрудник',
    method_hr_59: 'построение идентификации пути соискателя',
    методы_hr_60: 'основные этапы поиска, каналы связи, критерии отбора и оценка соискателей',
    method_hr_61: 'мониторинг всех этапов',
    method_hr_62: 'и каналы связи с тайными соискателями (просто запись на собеседование, прохождение собеседования, стажировка в компании)',
    method_hr_63: 'Идентификация',
    method_hr_64: 'слабых мест в процессах найма и адаптации',
    method_hr_65: 'Рекомендации по улучшению',
    method_hr_66: 'сравнительный анализ с лидерами отрасли',
    mystery_shopping_1: 'Тайна',
    mystery_shopping_2: 'Покупки',
    mystery_shopping_3: 'Оцените свой сервис с реальными клиентами',
    mystery_shopping_4: 'Проверьте стандарты, чтобы ваш персонал стал ближе к вашему клиенту',
    mystery_shopping_5: 'Мы являемся элитным членом ведущей профессиональной ассоциации поставщиков услуг Mystery Shopping Providers MSPA Europe',
    mystery_shopping_6: 'опыт',
    mystery_shopping_7: 'ежемесячные визиты',
    mystery_shopping_8: 'тайные покупатели со всего мира',
    mystery_shopping_9: 'Активные проекты',
    mystery_shopping_10: 'офисы, штаб-квартира в Австрии',
    mystery_shopping_11: 'Европейские Провайдеры услуг тайных покупателей',
    mystery_shopping_12: 'оценка качества обслуживания',
    mystery_shopping_13: 'Анкета',
    mystery_shopping_14: 'Программное обеспечение',
    mystery_shopping_15: 'Профиль покупателя',
    mystery_shopping_16: 'Подтверждение и возражение',
    mystery_shopping_17: 'Аналитика',
    mystery_shopping_18: 'Этими 5 шагами наша компания 4Service гарантирует качество услуги тайного покупателя',
    mystery_shopping_19: 'Программа',
    mystery_shopping_20: '5 элементов программы Mystery Shopping от 4Service',
    mystery_shopping_21: 'Анкета:',
    mystery_shopping_22: 'основа вашей программы',
    mystery_shopping_23: 'Эта часть программы доступна для просмотра всем заинтересованным лицам',
    mystery_shopping_24: 'Для топ-менеджеров.',
    mystery_shopping_25: 'Показать все важные шаги и детали процесса',
    mystery_shopping_26: 'Для сотрудников.',
    mystery_shopping_27: 'Отражение ожиданий работодателей',
    mystery_shopping_28: 'Для тайных покупателей.',
    mystery_shopping_29: 'Четко и структурировано, чтобы избежать путаницы и предвзятости',
    mystery_shopping_30: 'Программное обеспечение Shopmetrics:',
    mystery_shopping_31: 'удобный и инновационный',
    mystery_shopping_32: 'Преимущества:',
    mystery_shopping_33: 'Объединяет',
    mystery_shopping_34: 'с вашими бизнес-процессами и программным обеспечением',
    mystery_shopping_35: 'В соответствии с GDPR',
    mystery_shopping_36: 'Инструментарий управления конфиденциальностью',
    mystery_shopping_37: 'Подробно',
    mystery_shopping_38: 'Соответствие законодательным требованиям',
    mystery_shopping_39: 'Сообщить',
    mystery_shopping_40: 'строитель',
    mystery_shopping_41: 'Управление',
    mystery_shopping_42: 'уровни доступа',
    mystery_shopping_43: 'Большинство анкет',
    mystery_shopping_44: 'выполнены в течение часа',
    mystery_shopping_45: 'Профиль покупателя:',
    mystery_shopping_46: 'более 200 000 тайных покупателей по всему миру',
    mystery_shopping_47: 'Основные критерии: максимальная близость к вашей целевой аудитории, наличие предыдущего опыта работы с вашей компанией или конкурентами',
    mystery_shopping_48: 'Обучение покупателей:',
    mystery_shopping_49: 'Вводный обучающий тест',
    mystery_shopping_50: 'Скайп/телефонный звонок',
    mystery_shopping_51: 'Оценочный тест (необязательно)',
    mystery_shopping_52: 'Подтверждение и возражения',
    mystery_shopping_53: 'Проверка',
    mystery_shopping_54: 'Система',
    mystery_shopping_55: '100% анкет',
    mystery_shopping_56: 'проверяются командой валидаторов',
    mystery_shopping_57: 'Дополнительные звонки',
    mystery_shopping_58: 'для уточнения',
    mystery_shopping_59: 'Аудиозаписи',
    mystery_shopping_60: 'после визита отметить самые важные вопросы',
    mystery_shopping_61: 'Мошенничество со стороны сотрудников',
    mystery_shopping_62: 'система предотвращения',
    mystery_shopping_63: 'Возражение',
    mystery_shopping_64: 'Система',
    mystery_shopping_65: 'Любая оценка покупателя',
    mystery_shopping_66: 'сотрудник может возразить прямо на сайте',
    mystery_shopping_67: 'Каждое возражение',
    mystery_shopping_68: 'рассматривается и дается ответ местной командой валидаторов',
    mystery_shopping_69: 'Вы можете посмотреть статистику',
    mystery_shopping_70: 'возражений',
    mystery_shopping_71: 'Аналитика.',
    mystery_shopping_72: 'Отчет представлен лично и содержит:',
    mystery_shopping_73: 'Компания',
    mystery_shopping_74: 'болевые точки и проблемы',
    mystery_shopping_75: 'Динамика',
    mystery_shopping_76: 'по отделам и разделам',
    mystery_shopping_77: 'Корреляция',
    mystery_shopping_78: 'между соблюдением стандартов и удовлетворенностью клиентов',
    mystery_shopping_79: 'Сравнение',
    mystery_shopping_80: 'против конкурентов',
    mystery_shopping_81: 'Практично',
    mystery_shopping_82: 'рекомендации по улучшению сервиса',
    mystery_shopping_83: 'Что нас отличает?',
    mystery_shopping_84: 'Мы проводим сессии по разработке идей',
    mystery_shopping_85: 'Мы не только рекомендуем, что делать, но и вместе с вами ищем практические пути решения сервисных проблем.',
    mystery_shopping_86: 'Сессии идей — мощный инструмент, который помогает нам:',
    mystery_shopping_87: 'Разработать направления по улучшению продукта или услуги',
    mystery_shopping_88: 'Изучить новые возможности получения дохода и бизнес-стратегий',
    mystery_shopping_89: 'Чтобы найти решение сложных задач, ориентированных на клиента',
    mystery_shopping_90: 'Превратить болевые точки клиентов в удовольствие',
    mystery_shopping_91: 'Мы проводим видео-интервью',
    mystery_shopping_92: 'Мы не просто собираем анкеты - мы также получаем живые комментарии от покупателей',
    mystery_shopping_93: 'Мы делаем это, чтобы:',
    mystery_shopping_94: 'Наши фасилитаторы провели подробные интервью и дали эффективную обратную связь',
    mystery_shopping_95: 'Посмотреть на опыт с точки зрения клиента',
    mystery_shopping_96: 'Усовершенствовать свою стратегию контент-маркетинга',
    mystery_shopping_97: 'Предсказывать поведение клиентов',
    mystery_shopping_98: 'Мы разрабатываем карту пути клиента',
    mystery_shopping_99: 'Customer Journey Mapping помогает нам встать на место клиентов, чтобы понять их потребности и проблемы.',
    mystery_shopping_100: 'Создание пути клиента – лучший способ:',
    mystery_shopping_101: 'Посмотреть на опыт с точки зрения клиента',
    mystery_shopping_102: 'Усовершенствовать свою стратегию контент-маркетинга',
    mystery_shopping_103: 'Предсказывать поведение клиентов',
    mystery_shopping_104: 'Выявлять пробелы в обслуживании или коммуникации',
    mystery_shopping_105: 'Мы предлагаем различные виды тайных покупок',
    mystery_shopping_106: 'Типы тайных покупок',
    mystery_shopping_107: 'Таинственный звонящий',
    mystery_shopping_108: 'Звонит ваших менеджеров и/или колл-центр, чтобы определить качество предоставляемых услуг по телефону',
    mystery_shopping_109: 'Люксовый тайный шоппинг',
    mystery_shopping_110: 'Услуга проверки в элитных заведениях особой категорией тайных покупателей',
    mystery_shopping_111: 'Посещения конкурентов',
    мистерия_шоппинг_112: 'Самый простой способ понять, как работает ваш конкурент',
    mystery_shopping_113: 'Таинственный сотрудник',
    mystery_shopping_114: 'Поможет вам понять весь путь вашего сотрудника (процесс найма, адаптации, офисных процессов и т.д.)',
    mystery_shopping_115: 'Мотивационные визиты',
    mystery_shopping_116: 'Полезный инструмент для стимулирования предложения дополнительных услуг и/или товаров, продаж конкретных товаров',
    mystery_shopping_117: 'Тайный покупатель онлайн',
    mystery_shopping_118: 'Моделирует и измеряет качество обслуживания клиентов в вашем интернет-магазине, на веб-сайте или в приложении',
    mystery_shopping_119: 'Проверка продаж и рекламных акций',
    mystery_shopping_120: 'Помогает определить, как рекламные акции отображаются во всех местах и насколько эффективно они работают',
    mystery_shopping_121: 'Покупки и повторные посещения',
    mystery_shopping_122: 'Помогает отразить весь процесс от покупки до возврата, чтобы улучшить качество обслуживания клиентов',
    mystery_shopping_123: 'более 20 лет',
    nps_1: 'Чистое продвижение',
    nps_2: 'Оценка',
    nps_3: 'Измеряйте, отслеживайте и повышайте лояльность клиентов',
    nps_4: 'Они отвечают с оценкой от 0 до 6.',
    nps_5: 'Возможно, у них был плохой опыт, и они вряд ли будут покупать у вас снова, и могут даже посоветовать другим не покупать у вас.',
    nps_6: 'Это респонденты, набравшие от 7 до 8 баллов',
    nps_7: 'Они довольны вашим сервисом, но недостаточно счастливы, чтобы считаться промоутерами.',
    nps_8: 'Это респонденты, которые оценили ваш бизнес на 9 или 10 баллов',
    nps_9: 'Это означает, что они порекомендуют вас другу или коллеге и поэтому отстаивают интересы от вашего имени.',
    nps_10: 'Что такое NPS?',
    nps_11: 'Он измеряет восприятие клиентов на основе одного простого вопроса:',
    nps_12: 'Насколько вероятно, что вы порекомендуете [Организацию/Продукт/Услугу] другу или коллеге?',
    nps_13: 'Нужен ли он моей компании?',
    nps_14: 'NPS — ваш устойчивый рост',
    nps_15: 'Понять динамику лояльности клиентов',
    nps_16: 'Мотивировать своих сотрудников',
    nps_17: 'Понимать связь с разными продуктами',
    nps_18: 'Сравнивать, как клиенты относятся к вам и вашим конкурентам',
    nps_19: 'Что я могу сделать с NPS?',
    nps_20: 'Для какого бизнеса актуален NPS?',
    nps_21: 'Методы сбора информации:',
    nps_22: 'Наши рекомендации по работе с NPS',
    nps_23: 'Это означает, что мы разрабатываем рекомендации, которые помогут превратить недоброжелателей в промоутеров',
    nps_24: "Работаем по методу 'замкнутого цикла'",
    nps_25: 'NPS — это отслеживающее исследование, в котором мы отслеживаем изменения в лояльности клиентов',
    nps_26: 'Следим за динамикой',
    nps_27: 'Предлагаем всегда оценивать конкурентов в вашей нише для более глубокого анализа',
    nps_28: 'Оценка конкурентов',
    price_monitoring_1: 'Мониторинг цен',
    price_monitoring_2: 'Мониторинг розничной торговли',
    price_monitoring_3: 'Увеличьте потенциал продаж и оптимизируйте свою ценовую политику за счет мониторинга цен конкурентов',
    price_monitoring_4: '4Service уже более 20 лет предоставляет услуги по мониторингу цен по всему миру',
    price_monitoring_5: 'Цены отслеживаются в течение короткого времени нашей командой квалифицированных штатных аудиторов, что позволяет нам быстро реагировать на изменения цен конкурентов',
    price_monitoring_6: 'Задачи, которые наша компания может помочь вам решить с помощью мониторинга цен',
    price_monitoring_7: 'Оценка цен конкурентов',
    price_monitoring_8: 'и политика в отношении продуктов',
    price_monitoring_9: 'Мониторинг рекламных акций',
    price_monitoring_10: 'и специальные предложения',
    price_monitoring_11: 'Мониторинг рыночных условий',
    price_monitoring_12: 'и рыночная среда',
    price_monitoring_13: 'Проверка и контроль торговых точек',
    price_monitoring_14: 'для спецтехники и товаров',
    price_monitoring_15: 'Управление продуктом',
    price_monitoring_16: 'поток внутри сделки',
    price_monitoring_17: 'Оптимизация условий',
    price_monitoring_18: 'для лучшего сотрудничества с поставщиками, дилерами',
    price_monitoring_19: 'Измерение места на полке',
    price_monitoring_20: 'оценка позиционирования товаров, наличия на складе',
    price_monitoring_21: 'Ключевые индикаторы и критерии мониторинга',
    price_monitoring_22: 'Все данные собираются в одном интерфейсе, включая:',
    price_monitoring_23: 'имя',
    price_monitoring_24: 'категории продукта и бренда',
    price_monitoring_25: 'наличие',
    price_monitoring_26: 'рекламных материалов',
    price_monitoring_27: 'цена',
    price_monitoring_28: 'и наличие любых рекламных предложений',
    price_monitoring_29: 'полка',
    price_monitoring_30: 'размер',
    price_monitoring_31: 'продукт',
    price_monitoring_32: 'расположение',
    price_monitoring_33: 'Артикул',
    price_monitoring_34: 'внешний вид',
    price_monitoring_35: 'тип',
    price_monitoring_36: 'упаковки',
    price_monitoring_37: 'любое другое',
    price_monitoring_38: 'общая информация',
    price_monitoring_39: 'Предпроектный анализ:',
    price_monitoring_40: 'Изучаем',
    price_monitoring_41: 'ценовую и продуктовую политику вашей компании, деловую и отраслевую практика',
    price_monitoring_42: 'Мы предоставляем',
    price_monitoring_43: 'оперативное обучение, тестирование и сертифицирование аудиторов',
    price_monitoring_44: 'Мы настраиваем',
    price_monitoring_45: 'и нобновляем программное обеспечение',
    price_monitoring_46: 'Полевые работы:',
    price_monitoring_47: 'Посещения магазинов',
    price_monitoring_48: 'торговых точек',
    price_monitoring_49: 'Отчетность:',
    price_monitoring_50: 'Наша аналитическая группа',
    price_monitoring_51: 'разрабатывает онлайн-отчеты в личных кабинетах компании',
    price_monitoring_52: 'Мы предоставляем',
    price_monitoring_53: 'полный фотоотчет, отчет PPT, отчет Excel с указанием типа населенного пункта, названия сети, адреса торговой точки',
    price_monitoring_54: 'Как это',
    price_monitoring_55: 'работает',
    price_monitoring_56: 'размер индивидуальных скидок',
    price_monitoring_57: 'расчет стоимости',
    price_monitoring_58: 'размер индивидуальных бонусов',
    price_monitoring_59: 'программы лояльности',
    price_monitoring_60: 'и т. д.',
    price_monitoring_61: 'Мы используем специализированное программное обеспечение:',
    price_monitoring_62: 'PRADATA, Shopmetrics и предлагаем всестороннюю техническую поддержку',
    price_monitoring_63: 'Быстрый запуск',
    price_monitoring_64: 'и масштабирование (с первого дня)',
    price_monitoring_65: 'Все данные подтверждены',
    price_monitoring_66: 'по фотографиям и GPS-меткам',
    price_monitoring_67: 'У нас есть нужное количество',
    price_monitoring_68: 'полевых агентов в любой стране',
    price_monitoring_69: 'Можем работать с большим',
    price_monitoring_70: 'количеством данных',
    price_monitoring_71: 'Мониторинг отдельных цен',
    price_monitoring_72: 'Если у вас есть сложные продукты - мы можем помочь вам определить вашу стратегию ценообразования',
    price_monitoring_73: 'Мы можем отслеживать цены отдельных конкурентов, например:',
    price_monitoring_74: 'Низкая',
    price_monitoring_75: 'стоимость',
    price_monitoring_76: 'Высокая',
    price_monitoring_77: 'эффективность',
    price_monitoring_78: 'Отчет Excel',
    price_monitoring_79: 'Отчет PPT',
    price_monitoring_80: 'Отчет BI',
    price_monitoring_81: 'Данные о ценах конкурентов помогают вам принимать оптимальные ценовые решения',
    price_monitoring_82: 'Предоставляем полный фотоотчет, отчет PPT, отчет Excel с указанием локации',
    qualitative_research_1: 'Качественные',
    qualitative_research_2: 'исследования',
    qualitative_research_3: 'Собирайте, анализируйте и интерпретируйте данные, наблюдая за действиями и словами ваших клиентов',
    качественные_исследования_4: 'Ценности',
    qualitative_research_5: 'Поведение',
    qualitative_research_6: 'Фон',
    qualitative_research_7: 'Ожидания',
    qualitative_research_8: 'Окружающая среда',
    qualitative_research_9: 'Страхи/барьеры',
    qualitative_research_10: 'Какова цель выбора клиента?',
    qualitative_research_11: 'Человек с телефоном',
    qualitative_research_12: 'бренд',
    qualitative_research_13: 'категория',
    qualitative_research_14: 'реклама',
    qualitative_research_15: 'продукт',
    quality_research_16: 'Уникальность качественных методов в том, что они позволяют понять причины и мотивацию потребительского поведения',
    qualitative_research_17: 'Анализ отношения потребителя к тому или иному явлению',
    qualitative_research_18: 'Индивидуальные взгляды и опыт',
    qualitative_research_19: 'Темы, которые нельзя исследовать с помощью опросов',
    qualitative_research_20: 'Деликатные темы',
    qualitative_research_21: 'Труднодоступная аудитория',
    qualitative_research_22: 'Сложная география',
    qualitative_research_23: 'Глубинные интервью полезны для:',
    qualitative_research_24: 'Углубленные интервью',
    qualitative_research_25: 'Углубленное интервью — это метод качественного исследования, который включает в себя проведение индивидуальных интервью с респондентами для изучения их точки зрения на конкретную идею, программу или ситуацию. Подробное интервью подробно исследует индивидуальный опыт/восприятие/практику.',
    qualitative_research_26: 'Цели:',
    qualitative_research_27: 'Характеристика социальных и культурных норм',
    qualitative_research_28: 'Труднодоступная аудитория',
    qualitative_research_29: 'Обмен и сравнение (Морган), исследованных с помощью опросов',
    qualitative_research_30: 'Темы, которые нельзя исследовать с помощью опросов',
    qualitative_research_31: 'Углубленные фокус-группы полезны для:',
    qualitative_research_32: 'Фокус-группы',
    qualitative_research_33: 'Глубокое изучение отдельных встреч, возникающих дискуссий, дискуссий между проявлениями под влиянием модератора группы.',
    qualitative_research_34: 'Получение информации об общем опыте и социальных нормах посредством группового обсуждения, описательных данных в целенаправленном обсуждении.',
    qualitative_research_36: 'Преимущества:',
    qualitative_research_37: 'Изучение образа жизни и поведения потребителя в различных реальных условиях (дома, в торговой точке, на работе и т.д.)',
    qualitative_research_38: 'Поиск идей для разработки новых продуктов, упаковки, улучшения обслуживания и т. д.',
    qualitative_research_39: 'Углубленное этнографическое исследование полезно для:',
    qualitative_research_40: 'Этнографическое исследование',
    qualitative_research_41: 'Этнография – это тип качественного исследования, которое включает в себя погружение в жизнь определенного сообщества или организации и наблюдение за их поведением и взаимодействием с близкого расстояния.',
    qualitative_research_42: 'Этнография — это гибкий метод исследования, который позволяет вам получить глубокое представление об общей культуре, традициях и социальной динамике группы. Однако это также связано с некоторыми практическими и этическими проблемами.',
    qualitative_research_43: 'Методы:',
    qualitative_research_44: 'Этнография — это изучение потребителя в реальных условиях и взаимодействия с товаром/услугой',
    qualitative_research_45: 'Преимущества работы с нами',
    qualitative_research_46: 'Мы можем найти респондентов из любой целевой аудитории. И мы контролируем качество рекрутинга',
    qualitative_research_47: 'Рекрутинг любой сложности',
    qualitative_research_48: 'Наши модераторы говорят на нескольких языках и обладают навыками фасилитаторов',
    qualitative_research_49: 'Эксперты-модераторы',
    qualitative_research_50: 'Работа онлайн/офлайн',
    qualitative_research_51: 'Мы предоставляем аудио/видео',
    qualitative_research_52: 'записи',
    qualitative_research_53: 'Мы можем провести качественное исследование в любой локации',
    quantitative_research_1: 'Исследование удовлетворенности клиентов',
    quantitative_research_2: 'с определенным продуктом или услугой (SCI, NPS)',
    quantitative_research_3: 'Проведение сегментации',
    quantitative_research_4: 'опросы различных рынков',
    quantitative_research_5: 'Исследование рынка',
    quantitative_research_6: 'Управление NPS',
    quantitative_research_7: 'Анализ конкурентов',
    quantitative_research_8: 'Количественный',
    quantitative_research_9: 'исследования',
    quantitative_research_10: 'Методология исследования, которая используется для проверки теорий об отношении и поведении людей на основе объективных, числовых и статистических данных',
    quantitative_research_11: 'лет',
    quantitative_research_12: 'опыт CX по всему миру',
    quantitative_research_13: 'панель',
    quantitative_research_14: 'респондентов',
    quantitative_research_15: 'страны',
    quantitative_research_16: 'мы запускаем проекты по всему миру',
    quantitative_research_17: 'Кто мы',
    quantitative_research_18: '4Service — глобальный холдинг маркетинговых исследований и CX. Мы предлагаем экспертные услуги по сбору данных для самых разных предприятий',
    quantitative_research_19: '4Service в цифрах',
    quantitative_research_20: 'Компьютерная помощь',
    quantitative_research_21: 'Веб-интервью',
    quantitative_research_22: 'Метод анкетирования в Интернете. Респондент заполняет электронную анкету без помощи интервьюера. Анкету можно отправить в электронном виде или разместить на сайте.',
    quantitative_research_23: 'Компьютерное веб-собеседование',
    quantitative_research_24: 'В большинстве случаев',
    quantitative_research_25: 'данный метод является наиболее экономичным с точки зрения материальных и временных затрат',
    quantitative_research_26: 'Этот опрос предоставляет',
    quantitative_research_27: 'возможность взять интервью у труднодоступной аудитории',
    quantitative_research_28: 'Он предоставляет широкие возможности',
    quantitative_research_29: 'для демонстрации видео- и аудиоматериалов, а также изображений',
    quantitative_research_30: 'Респонденты могут быть',
    quantitative_research_31: 'в любой точке мира',
    quantitative_research_32: 'Это позволяет исследовать',
    quantitative_research_33: 'конкретное поведение интернет-аудитории. Клиент может получить доступ к основным результатам в режиме реального времени через веб-интерфейс',
    quantitative_research_34: 'Доступно для любой конфиденциальной информации',
    quantitative_research_35: 'деликатная, личная тема или вопрос, на который респонденты, возможно, не хотели отвечать во время разговора с интервьюером',
    quantitative_research_36: 'выборка ,более 150 000 человек',
    quantitative_research_37: 'У нас есть собственная выборка респондентов по всему миру',
    quantitative_research_38: 'Мы работаем со сложными запросами',
    quantitative_research_39: 'Используя дополнительные инструменты (социальные сети, реклама), мы можем найти конкретную аудиторию и сложный профиль респондента',
    quantitative_research_40: 'Мы мотивируем наших респондентов',
    quantitative_research_41: 'Мы разработали рамки для поощрения заполнения длинных опросов, предоставляя материальные стимулы',
    quantitative_research_42: 'Личные интервью',
    quantitative_research_43: 'проведено с помощью планшета',
    quantitative_research_44: 'Это один из основных методов сбора количественных данных, во время которого интервьюер общается непосредственно с респондентом по строго структурированному опроснику.',
    quantitative_research_45: 'Личные интервью могут проводиться по месту работы или жительства респондента, на улице или в специально отведенных местах продаж с использованием планшета',
    quantitative_research_46: 'Личные интервью, проведенные с использованием планшета',
    quantitative_research_47: 'Определение уровня',
    quantitative_research_48: 'популярности и узнаваемости бренда',
    quantitative_research_49: 'Сегментация потребителей',
    quantitative_research_50: 'на основе их покупательского поведения',
    quantitative_research_51: 'Анализ эффективности',
    quantitative_research_52: 'рекламной кампании',
    quantitative_research_53: 'Удовлетворенность клиентов',
    quantitative_research_54: 'измерение',
    quantitative_research_55: 'Определение лучшей цены',
    quantitative_research_56: 'для продукта или услуги',
    quantitative_research_57: 'Анализ',
    quantitative_research_58: 'предпочтений потребителей',
    quantitative_research_59: 'Исследование',
    quantitative_research_60: 'как покупают потребители',
    quantitative_research_61: 'Оценка',
    quantitative_research_62: 'емкости рынка',
    quantitative_research_63: 'Программное обеспечение DigSee',
    quantitative_research_64: 'Мы используем специальное программное обеспечение с аудиозаписью и GPS',
    quantitative_research_65: 'Интервьюеры и супервайзеры',
    quantitative_research_66: 'мы можем проводить интервью на любом языке и переводить на предпочитаемый клиентом язык',
    quantitative_research_67: 'Выборка',
    quantitative_research_68: 'Аналитический отдел разрабатывает систему набора респондентов для получения наилучшего результата',
    quantitative_research_69: 'Компьютерная помощь',
    quantitative_research_70: 'Телефонное интервью',
    quantitative_research_71: 'Методология сбора количественной информации посредством телефонных интервью с использованием четко структурированной анкеты.',
    quantitative_research_72: 'Компьютерное телефонное интервью',
    quantitative_research_73: 'У нас есть собственные колл-центры',
    quantitative_research_74: 'Мы используем специальное программное обеспечение с аудиозаписью и GPS',
    quantitative_research_75: 'Квалифицированные операторы',
    quantitative_research_76: 'Наши операторы говорят на разных языках, обучены, квалифицированы и постоянно совершенствуют свои навыки',
    quantitative_research_77: 'Базы данных',
    quantitative_research_78: 'Мы работаем как с собственными базами данных, так и с базами ваших клиентов',
    quantitative_research_79: 'SLA',
    quantitative_research_80: 'Мы гарантируем качество и подписываем SLA (Соглашение об уровне обслуживания)',
    quantitative_research_81: 'Постановка целей',
    quantitative_research_82: 'Определение методологии',
    quantitative_research_83: 'Сегментация и выборка',
    quantitative_research_84: 'Определение каналов сбора данных',
    quantitative_research_85: 'Создание логичной для респондента анкеты',
    quantitative_research_86: 'Процесс сбора данных',
    quantitative_research_87: 'Обеспечение качества',
    quantitative_research_88: 'Методы анализа',
    quantitative_research_89: 'Обработка данных',
    quantitative_research_90: 'Дизайн опроса',
    quantitative_research_91: 'Отслеживание местоположения интервьюеров с помощью GPS',
    quantitative_research_92: 'Проверка данных',
    quantitative_research_93: 'Разработка и тестирование анкеты',
    quantitative_research_94: 'Проверка данных',
    quantitative_research_95: 'Аудиозаписи с соблюдением требований GDPR',
    quantitative_research_96: 'Подготовка и обучение ректрутеров',
    quantitative_research_97: 'Обеспечение качества',
    quantitative_research_98: 'Факторный анализ',
    quantitative_research_99: 'Сегментация',
    quantitative_research_100: 'Карта восприятия',
    quantitative_research_101: 'Корреляция',
    quantitative_research_102: 'Модель конверсии',
    quantitative_research_103: 'Жаккард',
    quantitative_research_104: 'Кластерный анализ',
    quantitative_research_105: 'и т. д.',
    quantitative_research_106: 'Аналитические отчеты',
    quantitative_research_107: 'Мы используем SPSS® и другие инструменты для создания аналитических отчетов с использованием различных методологий',
    quantitative_research_108: 'Какие бизнес-задачи можно решить:',
    quantitative_research_109: 'Какие бизнес-задачи можно решить:',
    social_responsibility_1: 'Корпоративная социальная ответственность 4Service',
    social_responsibility_2: 'Из глубины сердца и души каждого участника нашей команды. Мы прилагаем максимум усилий, чтобы сохранить искру доброты в каждом из наших сотрудников и сделать мир вокруг нас немного лучше',
    social_responsibility_3: 'Образовательная деятельность / Поддержка молодежи',
    social_responsibility_4: 'Наши информационные инициативы для детей и студентов',
    social_responsibility_5: 'Деловая игра Service Mania',
    social_responsibility_6: 'Важной частью наших обучающих мероприятий является бизнес-игра Service Mania.',
    social_responsibility_7: 'Service Mania заставляет вас думать стратегически о том, как справляться с необычными ситуациями.',
    social_responsibility_8: 'Презентация исследования / Дийя. Бизнес',
    social_responsibility_9: 'Взгляд бизнеса и клиента на ситуацию с сервисом: презентация результатов нашего исследования 4Service Викторией Скорботой, директором по развитию 4Service Украина',
    social_responsibility_10: 'Сессии по дизайну услуг',
    social_responsibility_11: 'Методология проектирования услуг — важная часть 4Service. Пару лет назад мы стали партнером DesignThinkers Academy и не только используем лучшие практики в своей работе, но и активно обучаем лучшие компании',
    social_responsibility_12: 'Благотворительность/волонтерство',
    social_responsibility_13: 'Детские дома, дома престарелых и волонтерство в украинской армии. Самая трогательная часть нашей деятельности',
    social_responsibility_14: '#HelpEasyWith4ServiceGroup',
    social_responsibility_15: "Мы работаем в партнерстве с Благотворительным фондом 'Благомай' и стараемся сделать праздник и помочь тем, кто остро в этом нуждается",
    social_responsibility_16: 'Сбор средств для детских домов и домов престарелых',
    social_responsibility_17: 'Мы в 4Service инициировали несколько сборов средств для домов престарелых и детских домов на покупку предметов первой необходимости',
    social_responsibility_18: '#СпасемУкраину',
    social_responsibility_19: 'Война затронула многих наших сотрудников и близких друзей. Каждый из нас помогает, чем может',
    social_responsibility_20: 'Благополучие животных',
    social_responsibility_21: 'Каждый может прийти к нам в офис со своим питомцем. Мы помогаем приютам, собираем средства на еду, а также время от времени ищем приют для бездомных животных',
    social_responsibility_22: 'Поддержка приютов для животных',
    social_responsibility_23: 'Бездомные животные нуждаются в помощи так же, как и все уязвимые члены нашего общества.',
    social_responsibility_24: "Наша компания взяла под свою опеку приют для бездомных животных 'Сириус' (Киевская область, Украина).",
    social_responsibility_25: 'Креативные инициативы в помощь бездомным животным',
    social_responsibility_26: 'Как мы можем донести актуальную проблему приютов для животных до сведения наших клиентов и партнеров?',
    social_responsibility_27: 'Офис, в котором разрешено присутствие с домашними животными',
    social_responsibility_28: 'У многих членов нашей команды есть домашние животные. Мы создали здоровую среду, в которой каждый может прийти в офис со своим питомцем и чувствовать себя комфортно',
    ux_testing_1: 'UX-тестирование',
    ux_testing_2: 'Человеческий и искусственно-интеллектуальный подход к тестированию и улучшению ваших веб-сайтов и приложений',
    ux_testing_3: 'Что такое UX-тестирование',
    ux_testing_4: 'UX-тестирование помогает понять, как люди взаимодействуют с вашим продуктом, приложением или веб-сайтом',
    ux_testing_5: 'Навигация не очень понятна',
    ux_testing_6: 'Я понятия не имею, как сделать заказ',
    ux_testing_7: 'Недостаточно фото в каталоге',
    ux_testing_8: 'Вы:',
    ux_testing_9: 'Откроете для себя возможности для улучшения',
    ux_testing_10: 'Выявите проблемы в дизайне и взаимодействии с клиентом',
    ux_testing_11: 'Узнаете, с какими трудностями сталкивается пользователь при взаимодействии с сайтом',
    ux_testing_12: 'Наблюдайте за путешествием клиента',
    ux_testing_13: 'Распознавайте, когда и почему ваша целевая аудитория покидает сайт',
    ux_testing_14: 'Определите, какие разделы вызывают наименьший и наибольший интерес и эмоции',
    ux_testing_15: 'Пользовательское UX-тестирование',
    ux_testing_16: '4Service предоставляет вам реальное человеческое понимание, необходимое для создания клиентоориентированных веб-сайтов и приложений с использованием качественных и количественных методов',
    ux_testing_17: 'Я не понимаю, как пользоваться сайтом. Где спрятано меню?',
    ux_testing_18: 'Мне кажется, что между буквами слишком много места, текст не читается',
    ux_testing_19: 'Изображения накладываются друг на друга. Так и должно быть?',
    ux_testing_20: 'UX-тестирование помогает понять, как люди взаимодействуют с вашим продуктом, приложением или веб-сайтом',
    ux_testing_21: 'Дизайн исследования пользовательского UX-тестирования:',
    ux_testing_22: 'Откройте для себя возможности стать лучше',
    ux_testing_23: 'Определение типа и метода тестирования (качественное/количественное)',
    ux_testing_24: 'Составление гипотез и пользовательских сценариев',
    ux_testing_25: 'Анализ и отчет о результатах тестирования',
    ux_testing_26: 'Два решения',
    ux_testing_27: 'Мы предлагаем два подхода к успешному UX-тестированию',
    ux_testing_28: 'Пользовательское UX-тестирование',
    ux_testing_29: 'ИИ + UX-тестирование с участием человека',
    ux_testing_30: 'Наши сильные стороны:',
    ux_testing_31: 'Мы можем проанализировать конкурентную среду',
    ux_testing_32: 'Можем привлекать узких специалистов для проведения и участия в интервью',
    ux_testing_33: 'Мы можем проводить интервью на разных языках и переводить их на нужный язык',
    ux_testing_34: 'с существующим удобством использования',
    ux_testing_35: 'показатели или стандарты',
    ux_testing_36: 'ваша производительность',
    ux_testing_37: 'против конкурента',
    ux_testing_38: 'версии',
    ux_testing_39: 'того же продукта',
    ux_testing_40: 'Рейтинг удовлетворенности',
    ux_testing_41: 'NPS',
    ux_testing_42: 'Показатели успеха',
    ux_testing_43: 'Частота ошибок',
    ux_testing_44: 'Время выполнения задачи',
    ux_testing_45: 'Пользовательское UX-тестирование',
    ux_testing_46: 'Количественный метод',
    ux_testing_47: 'Мы привлекаем не менее 100 человек для участия в UX-тестировании на этом этапе',
    ux_testing_48: 'Актуально, когда вам нужно сравнить ваш сайт или приложение:',
    ux_testing_49: 'Количественный метод измерения:',
    ux_testing_50: 'Наблюдение',
    ux_testing_51: 'Проводить время с пользователем или группой пользователей и наблюдать за их поведением с продуктом, когда они используют его в повседневной жизни',
    ux_testing_52: 'Углубленное интервью',
    ux_testing_53: 'Позволяют вам узнать об отношении, убеждениях, желаниях и опыте пользователей, которые заходят на ваш сайт. Мы рекомендуем провести 15-20 интервью',
    ux_testing_54: 'Этнографическое исследование',
    ux_testing_55: 'Проводится в среде респондентов, где они будут использовать продукт. Наблюдая, вы начинаете хорошо понимать психологию пользователя, эмоциональные проблемы, с которыми он сталкивается.',
    ux_testing_56: 'Пользовательское UX-тестирование',
    ux_testing_57: 'Качественный метод',
    ux_testing_58: 'Этот метод помогает понять мотивы и логику поведения пользователей',
    ux_testing_59: 'Мы используем:',
    ux_testing_60: 'Мы разрабатываем карту пути клиента, чтобы проиллюстрировать результаты нашего исследования',
    ux_testing_61: '30 участников (мужчины/женщины)',
    ux_testing_62: 'Респонденты предварительно проверяются и приглашаются принять участие в опросе',
    ux_testing_63: 'Удаленное онлайн-собеседование на платформе Wantent',
    ux_testing_64: 'ИИ + человек',
    ux_testing_65: 'ИИ + UX-тестирование с участием человека',
    ux_testing_66: 'Сочетание уникальной технологии искусственного интеллекта Wantent и реальных пользователей',
    ux_testing_67: 'Большой шрифт в заголовке привлек внимание',
    ux_testing_68: "Пользователи смотрят на 'громко говорящие' цифры, но не читают информацию рядом с ними",
    ux_testing_69: 'UX-тестирование помогает понять, как люди взаимодействуют с вашим продуктом, приложением или веб-сайтом',
    ux_testing_70: 'Искусственный интеллект + дизайн исследования тестирования с участием человека',
    ux_testing_71: 'Как это работает',
    ux_testing_72: 'Wantent — это решение, основанное на искусственном интеллекте, которое соответствует требованиям рынка.',
    ux_testing_73: 'Wantent оценивает эффективность веб-сайтов и приложений, применяя технологии машинного обучения для анализа эмоциональных реакций и вовлеченности аудитории.',
    ux_testing_74: 'Чтобы интерпретировать результаты, мы используем методы повествования, разнообразие и сочувствие, чтобы стимулировать производительность и рост.',
    ux_testing_75: 'Внимание и эмоции пользователя во время переходов по разделам',
    ux_testing_76: 'Статистика по группам участников (внимание и сосредоточенность на протяжении всего сеанса)',
    ux_testing_77: 'Анализ конверсий страниц',
    ux_testing_78: 'Внимание и эмоции во время выполнения задач',
    ux_testing_79: 'Выводы по восприятию',
    ux_testing_80: 'сайта разными группами участников',
    ux_testing_81: 'Анализ легкости',
    ux_testing_82: 'навигации по страницам и простоте поиска важных элементов',
    ux_testing_83: 'Анализ восприятия',
    ux_testing_84: 'информации, представленной на сайте',
    ux_testing_85: 'Области определения',
    ux_testing_86: 'улучшений отдельных страниц (навигация, информационные блоки и т.д.)',
    ux_testing_87: 'Мы подробно анализируем',
    ux_testing_88: 'Ключевые результаты исследования',
    ux_testing_89: 'Рекомендации по улучшению структуры сайта и отдельных страниц',
    ux_testing_90: 'Мы тщательно выбираем целевую аудиторию для юзабилити-тестирования.',
    ux_testing_91: 'Мы проводим исследования в разных странах, на разных языках и среди разных групп пользователей',
    ux_testing_92: 'Мы можем тестировать в приложениях',
    ux_testing_93: 'и на сайтах конкурентов и провести сравнительный анализ',
    ux_testing_94: 'Мы комбинируем разные методы исследования.',
    ux_testing_95: 'Наши квалифицированные фасилитаторы сопровождают участника в процессе тестирования.',
    ux_testing_96: 'Мы проводим тестирование',
    ux_testing_97: 'на разных устройствах и версиях ПО',
    ux_testing_98: 'Почему выбирают нас?',
    wantent_1: '4Сервис х',
    wantent_2: 'Wantent',
    wantent_3: 'Уникальный партнерский проект от 4Service и Wantent © Сочетание искусственного интеллекта и человеческого подхода к анализу контента',
    wantent_4: 'измерение успеха до того, как контент выйдет в эфир',
    wantent_5: 'снижение рисков, связанных с проблемами разнообразия',
    wantent_6: 'глубокие идеи и рекомендации по улучшению контента',
    wantent_7: 'объективные решения по контенту и маркетинговой стратегии',
    wantent_8: 'Wantent — это решение, основанное на искусственном интеллекте, которое соответствует требованиям рынка.',
    wantent_9: 'Wantent — это решение на основе искусственного интеллекта для сопоставления контента с рынком. Wantent оценивает эффективность видеоконтента, применяя технологии машинного обучения для анализа эмоционального отклика и вовлеченности аудитории.',
    wantent_10: 'Wantent помогает определить соответствие контента рынку и максимизировать эффективность контента за счет',
    wantent_11: 'Настройки сценария и набора участников',
    wantent_12: 'персонализированного дизайна проекта и аудитории по всему миру',
    wantent_13: 'Сбора отзывов зрителей',
    wantent_14: 'подробного и четкого отзыва от аудитории',
    wantent_15: 'Анализа восприятия контента',
    wantent_16: 'анализа поведения и эмоциональных реакций с помощью ИИ',
    wantent_17: 'Предоставления информации для окончательного подведения итогов',
    wantent_18: 'рекомендаций по улучшению',
    wantent_19: 'Wantent максимизирует эффективность вашего контента',
    wantent_20: 'Предварительное тестирование концепции объявления',
    wantent_21: 'Тестирование креативной концепции',
    wantent_22: 'Проверка соответствия будущего креатива разработанной стратегии и понимание реакции целевой аудитории для возможных корректировок креативных концепций/месседжей',
    wantent_23: 'Тестирование элементов бренда на видимость (постобработка)',
    wantent_24: 'Подтверждение эффективности рекламных/спонсорских инвестиций',
    wantent_25: 'Тестирование рекламы',
    wantent_26: 'A/B-тестирование',
    wantent_27: 'Сравнение эффективности, креативности и повышения вовлеченности и внимания на этапе производства',
    wantent_28: 'Запуск тестирования рекламы',
    wantent_29: 'Определение производительности сообщений и создание рекомендаций по улучшению звука и изображения',
    wantent_30: 'Решения для ТВ',
    wantent_31: 'Пилоты, промо, новые форматы, тестирование телеведущих',
    wantent_32: 'Проведение сравнительного анализа, чтобы определить наиболее привлекательный и усилить его',
    wantent_33: 'Тестирование телешоу и полнометражных фильмов',
    wantent_34: 'Анализ вовлеченности зрителей, уровня внимания, эмоционального отклика при просмотре длительного контента в естественных условиях. Мы автоматически находим пики отвала аудитории и моменты отвлечения, чтобы оценить и улучшить монтаж видео',
    wantent_35: 'UI/UX-тестирование',
    wantent_36: 'Прототипы сайтов и тестирование мобильных приложений',
    wantent_37: 'Анализ реакции и эмоций пользователей с помощью технологии Wantent:',
    wantent_38: 'уровень внимания',
    wantent_39: 'эмоциональные реакции',
    wantent_40: 'тепловые карты направления взгляда участников',
    wantent_41: 'Анализ удобства использования веб-сайта и приложений на основе отзывов участников:',
    wantent_42: 'Шкала удобства использования системы (SUS)',
    wantent_43: 'Оценка усилий клиента (простота взаимодействия)',
    wantent_44: 'Чистый показатель продвижения (NPS)',
    wantent_45: 'Соответствие GDPR и конфиденциальности пользователей',
    wantent_46: 'Wantent полностью соответствует политикам GDPR и CCPA и соответствует принципам сбора, хранения, обработки и защиты персональных данных участников',
    wantent_47: 'Никита Лобынцев',
    wantent_48: 'СDO, Reface (медиа и развлечения)',
    wantent_49: 'Каждый день мы экспериментируем с множеством новых идей для контента с помощью технологий машинного обучения. Wantent помогает нам понять и оценить риски и преимущества, узнать отзывы, поведение и восприятие пользователей, чтобы установить причинно-следственную связь различных аспектов наших решений.',
    wantent_50: 'Александр Смирнов',
    wantent_51: 'совладелец TABASCO (Реклама)',
    wantent_52: 'Wantent предоставляет действительно уникальную услугу, которая помогает нам узнать очень мелкие детали того, что нравится и не нравится потребителям. Нет способа обмануть, нет способа лгать - Wantent всегда знает правду, и тем самым мы, как маркетологи, получаем бесценные (хахаха - мы знаем точную цену и она доступна) рекомендации по улучшению наших коммуникаций. ',
    wantent_53: 'Отзывы',
    terms_1: 'Положения и условия',
    terms_2: 'Соглашение об условиях использования',
    terms_3: "Пожалуйста, внимательно прочитайте настоящее Соглашение об условиях использования ('условия использования', 'соглашение') перед использованием веб-сайта",
    terms_4: '("веб-сайт"), которым управляет 4Service Holdings GmbH ("4Service", "нас", "мы", "наш").',
    terms_5: 'Условия использованияИспользуя этот веб-сайт, вы подтверждаете, что прочитали и ознакомились с настоящим Соглашением и согласны соблюдать его условия. Если вы не хотите соблюдать условия настоящего Соглашения, вам рекомендуется соответственно покинуть веб-сайт. 4Service разрешает использование и доступ к этому веб-сайту, его продуктам и услугам только тем, кто принял его условия.',
    terms_6: 'Политика конфиденциальности',
    terms_7: 'Прежде чем продолжить использование нашего веб-сайта, мы рекомендуем вам ознакомиться с нашей Политикой конфиденциальности в отношении сбора данных о пользователях. Это поможет вам лучше понять нашу практику.',
    terms_8: 'Возрастное ограничение',
    terms_9: 'Вам должно быть не менее 16 (шестнадцати) лет, прежде чем вы сможете использовать этот веб-сайт. Используя этот веб-сайт, вы гарантируете, что вам исполнилось 16 (шестнадцать) лет, и вы можете юридически соблюдать настоящее Соглашение. 4Service не несет ответственности за обязательства, связанные с искажением возраста.',
    terms_10: 'Интеллектуальная собственность',
    terms_11: 'Вы соглашаетесь с тем, что все материалы, продукты и услуги, представленные на этом веб-сайте, являются собственностью 4Service, ее аффилированных лиц, директоров, должностных лиц, сотрудников, агентов, поставщиков или лицензиаров, включая все авторские права, коммерческие секреты, товарные знаки, патенты и другая интеллектуальная собственность. Вы также соглашаетесь с тем, что вы не будете каким-либо образом воспроизводить или распространять интеллектуальную собственность 4Service, включая электронную, цифровую регистрацию или регистрацию новых товарных знаков.',
    terms_12: 'Доступ к нашему веб-сайту',
    terms_13: 'Доступ к нашему сайту разрешен на временной основе, и мы оставляем за собой право отозвать или изменить услуги, которые мы предоставляем на нашем сайте, без предварительного уведомления. Мы не несем ответственности, если по какой-либо причине наш сайт недоступен в любое время или в течение какого-либо периода. Время от времени мы можем ограничивать доступ к некоторым частям нашего сайта или всему нашему сайту пользователям, которые зарегистрировались у нас. Вы несете ответственность за принятие всех мер, необходимых для доступа к нашему сайту, включая использование оборудования, совместимого с нашим сайтом. Вы также несете ответственность за то, чтобы все лица, получающие доступ к нашему сайту через ваше интернет-соединение, знали об этих условиях и соблюдали их.',
    terms_14: 'Применимое законодательство',
    terms_15: 'Посещая этот веб-сайт, вы соглашаетесь с тем, что законы Австрийской Республики, без учета принципов коллизионного права, будут регулировать настоящие условия и любые споры любого рода, которые могут возникнуть между вами и 4Service.',
    terms_16: 'Споры',
    terms_17: 'Любой спор, каким-либо образом связанный с вашим посещением этого веб-сайта, подлежит рассмотрению в арбитраже Коммерческим судом Вены.',
    terms_18: 'Возмещение',
    terms_19: 'Вы соглашаетесь возместить ущерб 4Service и его аффилированным лицам и защитить 4Service от юридических претензий и требований, которые могут возникнуть в результате вашего использования или неправильного использования нашего веб-сайта. Мы оставляем за собой право выбирать нашего собственного адвоката.',
    terms_20: 'Срок хранения',
    terms_21: 'С учетом целей обработки срок сохранности персональных данных Пользователей (срок хранения) составляет 24 месяца с даты получения от Вас в установленном порядке согласия на обработку данных.',
    terms_22: 'Ограничение ответственности',
    terms_23: '4Service не несет ответственности за любые убытки, которые могут возникнуть у вас в результате неправильного использования вами нашего веб-сайта. 4Service оставляет за собой право без предварительного уведомления редактировать, изменять и изменять настоящее Соглашение в любое время, обновляя эту публикацию. Ваше дальнейшее использование веб-сайта сейчас или после публикации любых изменений или модификаций будет означать принятие вами таких изменений или модификаций. Если какая-либо часть настоящего соглашения будет объявлена незаконной, недействительной или не имеющей законной силы, эта часть будет считаться отделимой и не повлияет на действительность и применимость любых остальных положений. Возможные доказательства использования сайта в незаконных целях будут переданы в правоохранительные органы. Настоящее Соглашение является договоренностью между 4Service и пользователем.',
    terms_24: 'Пожалуйста, направляйте все вопросы и проблемы, связанные с конфиденциальностью/использованием, по следующему адресу:',
    terms_25: '4Service Holdings GmbH',
    terms_26: 'Тегетхоффштрассе 7',
    terms_27: '1010 Вена',
    terms_28: 'Австрия',
    terms_29: 'Обзор',
    terms_30: 'Когда личные данные, которые вы нам предоставляете, не являются неточными, вы имеете право попросить нас действительно исправить их (статья 16 GDPR).',
    thank_you_1: 'Спасибо!',
    thank_you_2: 'Мы свяжемся с вами в течение 24 часов',
    thank_you_3: 'PS Наши менеджеры уже рассматривают ваш запрос, чтобы составить лучшее предложение',
    thank_you_4: 'Меню',
    thank_you_5: 'Женщина печатает',
    privacy_policy_1: 'Форма уведомления о конфиденциальности для субъектов данных (пользователей веб-сайта)',
    privacy_policy_2: 'Эта форма уведомления о конфиденциальности (уведомление о конфиденциальности) предназначена для физических лиц (субъектов данных), чьи персональные данные собираются 4Service Holdings GmbH в соответствии с требованиями GDPR в рамках посещения веб-сайта',
    privacy_policy_3: '(здесь и далее Веб-сайт и Пользователи Веб-сайта/Пользователи соответственно).',
    privacy_policy_4: 'Форма уведомления о конфиденциальности для субъектов данных (пользователей веб-сайта) 4Service Holdings GmbH применяется во всех компаниях группы 4Service, которые находятся на законных основаниях под надзором или контролем 4Service Holdings GmbH.',
    privacy_policy_5: 'Контакты',
    privacy_policy_6: '4Service Holdings GmbH',
    privacy_policy_7: '(Компания/мы/нас)',
    privacy_policy_8: 'Адрес:',
    privacy_policy_9: 'Tegetthoffstraße 7, 1010 Вена, Австрия.',
    privacy_policy_10: 'Электронная почта:',
    privacy_policy_12: 'Кто мы?',
    privacy_policy_13: 'Физическое лицо (Вы), которое посещает Веб-сайт с любой целью. Для целей настоящего Документа Вы также должны быть указаны как Пользователь.',
    privacy_policy_14: 'Личные данные, которые мы получаем от вас',
    privacy_policy_15: 'Когда вы заходите на наш сайт, мы отправляем вам уведомление о конфиденциальности, в котором предлагаем вам дать согласие на обработку следующей информации:',
    privacy_policy_16: 'IP-адрес, имя пользователя, фамилия, адрес, номер телефона (стационарный или мобильный), адрес электронной почты, адрес, название компании, страна, адрес электронной почты, имя, фамилия, номер телефона, провинция, штат и почтовый индекс, данные об использовании, данные о взаимодействии с внешними социальными сетями или платформами, информация о регистрации и аунтификации на веб-сайте',
    privacy_policy_17: 'географическое положение.',
    privacy_policy_18: 'В случае, если вы выражаете свою волю и даете нам такое согласие, мы начинаем обрабатывать такую информацию от вас.',
    privacy_policy_19: 'Законность (согласие)',
    privacy_policy_20: 'Когда вы заходите на наш сайт, мы отправляем вам уведомление о конфиденциальности, в котором предлагаем вам дать согласие на обработку следующей информации:',
    privacy_policy_21: 'Правовым основанием для обработки персональных данных является согласие, которое мы получим от Вас, заполнив форму согласия по следующей ссылке:',
    privacy_policy_22: 'Если вы заполните форму согласия, это означает, что вы понимаете и принимаете все условия, указанные в этом Уведомлении о конфиденциальности.',
    privacy_policy_23: 'Отзыв согласия',
    privacy_policy_24: 'Вы имеете право в любое время отозвать согласие, которое Вы предоставили нам ранее. Отзыв согласия не влияет на законность обработки, основанной на согласии до его отзыва. Вы можете отозвать свое согласие, направив нам соответствующий запрос на следующий адрес электронной почты remove@4service-group.com, при этом форма запроса доступна по следующей ссылке:',
    privacy_policy_25: 'Форма вывода средств для пользователя',
    privacy_policy_26: 'Цели обработки',
    privacy_policy_27: 'Мы обрабатываем ваши персональные данные в следующих целях:',
    privacy_policy_28: '– улучшить клиентские сервисы (позволяет более эффективно реагировать на запросы клиентов); – персонализация опыта Пользователей (позволяет определить, кому больше интересны услуги); — улучшать Сайт (позволяет повышать качество товаров и услуг, удобство их использования, разрабатывать новые Сервисы, улучшать наши продукты и услуги); - связываться с Пользователем с помощью информационных бюллетеней, маркетинговых или рекламных материалов и другой информации, которая включает в себя наши новости, обновления, информацию об услугах с пометкой об инструкции, как отказаться от получения последующих электронных писем; – проводить статистические и иные виды исследований и анализов на основе обезличенных данных; — оказывать персонализированные услуги Пользователю и выполнять соглашения и договоры; – участие Пользователя в различных проектах, реализуемых нами через Сайт, ответы на запросы, адресованные Пользователем через Сайт, исследования, ведение учетных записей и записей и продвижение услуг.',
    privacy_policy_29: 'Субъекты, которым могут быть переданы персональные данные',
    privacy_policy_30: 'Во время обработки ваших персональных данных мы передаем ваши персональные данные субъектам, которые действуют в качестве обработчиков данных Компании. Обработчики Компании действуют исключительно на основании инструкций Компании. С особенностями действий, которые осуществляют обработчики Компании, и со списком таких обработчиков можно ознакомиться в Политике конфиденциальности и защиты данных по следующей ссылке: Политика конфиденциальности и защиты данных',
    privacy_policy_31: 'Страны, в которые могут быть переданы личные данные',
    privacy_policy_32: 'Компания передает ваши персональные данные на основании решения о достаточности, как это предусмотрено GDPR и Комиссией ЕС. Дополнительную информацию о передаче персональных данных в США можно найти в Политике конфиденциальности и защиты данных по следующей ссылке: Политика конфиденциальности и защиты данных.',
    privacy_policy_33: 'Срок сохранности',
    privacy_policy_34: 'С учетом целей обработки срок сохранности персональных данных Пользователей (срок хранения) составляет 24 месяца с даты получения от Вас в установленном порядке согласия на обработку данных.',
    privacy_policy_35: 'Право доступа',
    privacy_policy_36: 'Вы имеете право знать, обрабатываются ли личные данные, касающиеся Вас, и 2) если да, получить доступ к таким данным с множеством дополнительных условий, указанных в статье 15 GDPR',
    privacy_policy_37: 'Право на исправление',
    privacy_policy_38: 'Если личные данные, которые вы нам предоставляете, не являются неточными, вы имеете право попросить нас действительно исправить их (статья 16 GDPR).',
    privacy_policy_39: 'Право на удаление (право на забвение)',
    privacy_policy_40: 'Вы имеете право потребовать от нас удаления ваших личных данных без неоправданной задержки, и мы будем обязаны удалить ваши личные данные без неоправданной задержки, если применимы основания, указанные в статье 17 GDPR.',
    privacy_policy_41: 'Право на ограничение обработки',
    privacy_policy_42: 'Вы имеете право ограничить обработку своих персональных данных за несколькими исключениями в рамках GDPR, в частности, указанными в статье 18 GDPR.',
    privacy_policy_43: 'Мы обязаны сообщить Вам, какие данные собираются, как они используются, как долго они будут храниться и будут ли они переданы третьим лицам. Эта информация должна сообщаться кратко и доступно.',
    privacy_policy_44: 'Право на перенос данных',
    privacy_policy_45: 'Вам разрешено получать и повторно использовать ваши личные данные в своих целях в различных службах.',
    privacy_policy_46: 'Право на возражение',
    privacy_policy_47: 'Вы имеете право возражать против обработки персональных данных, которые обрабатываются Компанией. Мы должны прекратить обработку персональных данных, если мы не продемонстрируем веские законные основания для обработки, которая преобладает над интересами, правами и свободами человека, или если обработка предназначена для установления или защиты юридических требований.',
    privacy_policy_48: 'Право не подвергаться решению, основанному исключительно на автоматизированной обработке',
    privacy_policy_49: 'Вы имеете право возражать против любого автоматического профилирования, которое происходит без вашего согласия. При этом Вы имеете право обрабатывать Ваши персональные данные с участием человека.',
    privacy_policy_50: 'Жалобы',
    privacy_policy_51: 'В случае, если вы хотите подать жалобу на то, как ваши личные данные обрабатываются Компанией (или обработчиками, описанными выше), или на то, как была обработана ваша жалоба, вы имеете право подать жалобу напрямую с надзорным органом и Компанией.',
    privacy_policy_52: 'Подробности для каждого из этих контактов:',
    privacy_policy_53: 'Надзорный орган:',
    privacy_policy_54: 'Австрийский орган по защите данных',
    privacy_policy_55: 'Австрийская датская служба',
    privacy_policy_56: 'Викенбурггассе 8',
    privacy_policy_57: '1080 Вена',
    privacy_policy_58: 'Австрия/Европа',
    privacy_policy_59: 'Компания:',
    privacy_policy_60: '4Service Holdings GmbH (Компания/мы/нас)',
    privacy_policy_61: 'Адрес: Tegetthoffstraße 7, 1010 Вена, Австрия.',
    privacy_policy_62: 'Электронная почта:',
    privacy_policy_63: 'Политика конфиденциальности и защиты данных',
    privacy_policy_64: 'Подробнее о том, как и почему мы используем ваши данные, читайте здесь: Политика конфиденциальности и защиты данных',
    privacy_policy_65: 'Одобрение',
    privacy_policy_66: 'Компания разработала все внутренние документы для определения ролей сотрудников в отношении обработки персональных данных в Компании, в частности, ответственность за утверждение и проверку легитимности этого документа несет Управляющий директор.',
    contact_1: 'Для клиентов',
    contact_2: 'Для всех видов бизнеса, агентств по исследованию рынка и крупных компаний, деловых партнеров и СМИ.',
    contact_3: 'Tegetthoffstrasse 7, Вена, Австрия',
    contact_4: 'Для покупателей',
    contact_5: 'Местные жители и путешественники, водители и студенты, профессиональные тайные покупатели и домоседы.',
    contact_6: 'Свяжитесь с нами из вашей страны:',
    contact_7: 'Италия',
    contact_8: 'США',
    contact_9: 'Великобритания',
    contact_10: 'Швейцария',
    contact_11: 'Нидерланды',
    contact_12: 'Словакия',
    contact_13: 'Словения',
    contact_14: 'Румыния',
    contact_15: 'Азербайджан',
    contact_16: 'Украина',
    contact_17: 'Казахстан',
    contact_18: 'Другие страны',
    menu_1: 'CJM',
    menu_2: 'NPS',
    menu_3: 'CSI',
    menu_4: 'ВНТ',
    menu_5: 'UX-тестирование',
    menu_6: 'Кадровые исследования',
    menu_7: 'Качественное исследование',
    menu_8: 'Количественное исследование',
    menu_9: 'Тайный покупатель',
    menu_10: 'Voicer',
    menu_11: 'Play4Sales',
    menu_12: 'Wantent',
    menu_13: 'Ценовой мониторинг',
    menu_14: 'О нас',
    menu_15: 'Контакты',
    menu_16: 'Блог',
    menu_17: 'Методы',
    menu_18: 'Товары',
    menu_19: 'Покупатели \n латформа',
    menu_20: 'КСО',
    menu_21: 'Анализ цен на автомобили',
    nps_passives: 'Пассивные',
    nps_detractors: 'Недоброжелатели',
    nps_promoters: 'Промоутеры',
    nps_a_score: 'оценка',
    area: 'Площадь',
    products_tabs_1: 'Для достижения максимальной эффективности мы также проводим в комплексе:',
    products_tabs_2: 'Продукты',
    products_tabs_3: 'Качественное исследование — это сбор и анализ нечисловых данных для понимания концепций, мнений или опыта',
    products_tabs_4: 'Количественные методы исследования — это объективное измерение и числовой анализ данных, собранных с помощью опросов, анкетирований',
    our_clients_1: 'Наши клиенты',
    preview_cases_section_1: 'Кейсы',
    preview_cases_section_2: 'Перейдите к кейсам, чтобы прочитать больше интересных сообщений',
    blog_1: 'Блог с захватывающим контентом',
    blog_2: 'Нет доступных сообщений по вашему запросу',
    post_1_1: 'Чего не следует делать, отвечая на негативные отзывы',
    post_1_2: 'Вдох, выдох... И снова. Каждый бизнес получает негативные, а иногда и токсичные отзывы. Снижение звездного рейтинга, критика в социальных сетях или негативные отзывы могут напрямую повлиять на доход, и, к сожалению, это не всегда справедливо. Согласно исследованиям HBR, если вы правильно реагируете на негатив, это приведет к увеличению продаж. И лучшее, что может сделать любой бизнес, — это взять на себя ответственность.',
    post_1_3: '18 марта 2020 г.',
    post_2_1: 'Сессии по дизайну услуг',
    post_2_2: 'Сервис-дизайн — это методология, ориентированная на человека и клиента, которая фокусируется на клиентском опыте как ключевой ценности успеха. Сервис-дизайн рассматривает интегрированный подход к стратегическим, системным, процессным точкам и точкам взаимодействия дизайнерским решениям.',
    post_2_3: 'октябрь 2021 г.',
    post_3_1: 'Деловая игра Service Mania',
    post_3_2: "Важной частью наших обучающих мероприятий является бизнес-игра 'Сервисомания'. Service Mania заставляет вас думать стратегически о том, как справляться с необычными ситуациями на игровом поле и на рынке, чтобы завоевать клиентов.",
    post_3_3: '12 августа 2020 г.',
    post_4_1: 'Автомобильная промышленность',
    post_4_2: 'Задача: как повысить качество обслуживания клиентов при покупке электромобилей в салонах автомобильных дилеров?',
    post_4_3: 'сентябрь 2021',
    automotive_industry_post_1: 'Быстрый ответ',
    automotive_industry_post_2: 'быстрая и удобная организация времени тест-драйва',
    автомобильная_индустрия_пост_3: 'Предложить взять машину',
    automotive_industry_post_4: 'насколько пожелает клиент',
    automotive_industry_post_5: 'Внимание к деталям',
    automotive_industry_post_6: 'личные потребности и участие',
    automotive_industry_post_7: 'Предложили много информации',
    automotive_industry_post_8: 'материал об электромобилях и пакетных предложениях',
    automotive_industry_post_9: 'Подробное объяснение',
    automotive_industry_post_10: 'и очень понятным языком',
    automotive_industry_post_11: 'Активность',
    automotive_industry_post_12: 'Возможность сидеть в машине',
    automotive_industry_post_13: 'дотрагиваться',
    automotive_industry_post_14: 'Приглашение',
    automotive_industry_post_15: 'на повторный визит',
    automotive_industry_post_16: 'Бизнес-игра Service Mania',
    automotive_industry_post_17: 'Ана Крайинович',
    automotive_industry_post_18: 'Лингвист, кандидат наук и создатель комиксов, пишущий о психическом здоровье, языках, творчестве и жизни',
    automotive_industry_post_19: 'Мы используем',
    automotive_industry_post_20: 'методологию проектирования услуг',
    automotive_industry_post_21: 'поиск лучшего способа',
    automotive_industry_post_22: 'решения проблемы клиентов',
    automotive_industry_post_23: 'Клиент:',
    automotive_industry_post_24: 'Крупный европейский автомобильный дилер',
    automotive_industry_post_25: 'Вызов:',
    automotive_industry_post_26: 'Как повысить качество покупки электромобилей в салонах автомобильных дилеров?',
    automotive_industry_post_27: 'Мы использовали',
    automotive_industry_post_28: 'Как правило, мы используем сочетание количественных и качественных методов исследования и представляем отчет с инсайтами. В этот раз мы также провели офлайн-сессию идей для менеджеров разного уровня, где каждый мог найти подход к клиенту на основе нашего исследования.',
    automotive_industry_post_29: 'Наш покупатель',
    automotive_industry_post_30: 'Имя:',
    automotive_industry_post_31: 'Дэйв',
    automotive_industry_post_32: 'Возраст:',
    automotive_industry_post_33: 'Страна:',
    automotive_industry_post_34: 'Великобритания',
    automotive_industry_post_35: 'Текущий автомобиль:',
    automotive_industry_post_36: 'Фольксваген Гольф',
    automotive_industry_post_37: 'Цель:',
    automotive_industry_post_38: 'Новый автомобиль искали с сентября 2021 года',
    automotive_industry_post_39: 'Болевые точки:',
    automotive_industry_post_40: 'медленное обслуживание в автосалонах VW, Peugeot и KIA',
    automotive_industry_post_41: 'Купил Hyundai Ioniq Premium SE после визита MSH в автосалон',
    automotive_industry_post_42: 'Некоторые идеи',
    automotive_industry_post_43: 'Во время сессий могут проявляться совершенно неожиданные ожидания. Какие важные вещи теперь можно использовать для улучшения клиентского опыта:',
    service_mania_business_game_post_1: 'Деловая игра Service Mania',
    service_mania_business_game_post_2: 'Ана Краинович',
    service_mania_business_game_post_3: 'Лингвист, кандидат наук и создатель комиксов, пишущий о психическом здоровье, языках, творчестве и жизни',
    service_mania_business_game_post_4: 'Важной частью наших обучающих мероприятий является бизнес-игра Service Mania',
    service_mania_business_game_post_5: 'Service Mania побуждает вас мыслить стратегически о том, как справляться с необычными ситуациями на игровом поле и на рынке при привлечении клиентов.',
    service_mania_business_game_post_6: 'Игра позволяет искать индивидуальный подход ко всем типам клиентов, выявлять потребности и изучать ожидания.',
    service_mania_business_game_post_7: 'Использует игровые практики и механизмы для борьбы с конкурентами и адаптации бизнес-процессов компании к событиям в мире.',
    service_mania_business_game_post_8: 'Проведенные мероприятия:',
    service_mania_business_game_post_9: 'Дия.Бизнес (Service Mania для предпринимателей и менеджеров)',
    service_mania_business_game_post_10: 'Дата:',
    service_mania_business_game_post_11: '12 августа 2020, Харьков, Украина',
    service_mania_business_game_post_12: 'Аудитория:',
    service_mania_business_game_post_13: '50 человек',
    service_mania_business_game_post_14: 'Бизнес-школа MiniBoss',
    service_mania_business_game_post_15: '(Service Mania с будущими предпринимателями)',
    service_mania_business_game_post_16: 'Дата:',
    service_mania_business_game_post_17: '5 декабря 2020 г.',
    service_mania_business_game_post_18: 'Аудитория:',
    service_mania_business_game_post_19: '25 человек, Киев, Украина',
    service_mania_business_game_post_20: 'Набор студентов',
    service_mania_business_game_post_21: '(Service Mania с будущими тайными покупателями)',
    service_mania_business_game_post_22: 'Описание события:',
    service_mania_business_game_post_23: "Наш отдел кадров набрал студентов для работы в нашей компании. Одним из мероприятий стало проведение деловой игры 'Сервисомания'",
    service_mania_business_game_post_24: 'Дата:',
    service_mania_business_game_post_25: 'Аудитория:',
    service_mania_business_game_post_26: 'Студенты Киевского национального лингвистического университета',
    what_you_shouldnt_do_when_responding_to_negative_reviews_1: 'Если вы говорите одно и то же в каждом отзыве',
    what_you_shouldnt_do_when_responding_to_negative_reviews_2: 'будет выглядеть так, как будто у вас есть автоответчик и вы не очень внимательны к своим клиентам.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_3: 'Если вы говорите одно и то же в каждом отзыве',
    what_you_shouldnt_do_when_responding_to_negative_reviews_4: 'будет выглядеть так, как будто у вас есть',
    what_you_shouldnt_do_when_responding_to_negative_reviews_5: 'Несмотря на то, что некоторые отзывы могут быть почти идентичными,',
    what_you_shouldnt_do_when_responding_to_negative_reviews_6: 'также важно персонализировать ваши ответы.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_7: 'Хотя некоторые отзывы могут',
    what_you_shouldnt_do_when_responding_to_negative_reviews_8: 'также важно персонализировать ваши ответы.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_9: 'Если вы говорите одно и то же в каждом отзыве',
    what_you_shouldnt_do_when_responding_to_negative_reviews_10: 'это будет выглядеть так, как будто у вас есть',
    what_you_shouldnt_do_when_responding_to_negative_reviews_11: 'Если вы скажете',
    what_you_shouldnt_do_when_responding_to_negative_reviews_12: 'это будет выглядеть так, как будто у вас есть',
    what_you_shouldnt_do_when_responding_to_negative_reviews_13: 'Чего не следует делать, отвечая на негативные отзывы',
    what_you_shouldnt_do_when_responding_to_negative_reviews_14: 'Ана Краинович',
    what_you_shouldnt_do_when_responding_to_negative_reviews_15: 'Лингвист, кандидат наук и создатель комиксов, пишущий о психическом здоровье, языках, творчестве и жизни',
    what_you_shouldnt_do_when_responding_to_negative_reviews_16: 'Вдох, выдох... И снова.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_17: 'Каждая компания получает негативные, а иногда и токсичные отзывы.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_18: 'Понижение звездного рейтинга, критика в социальных сетях или негативные отзывы могут напрямую повлиять на доход, и, к сожалению, это не всегда справедливо.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_19: 'Согласно исследованиям HBR, если вы правильно реагируете на негатив, это приведет к увеличению продаж. И лучшее, что может сделать любой бизнес, — это взять на себя ответственность.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_20: 'Поэтому мы сочли важным напомнить вам, чего НЕЛЬЗЯ делать, отвечая на негативные отзывы.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_21: 'Игнорировать и создавать впечатление, что ничего не произошло',
    what_you_shouldnt_do_when_responding_to_negative_reviews_22: 'Конечно, вы можете отвечать только на положительные отзывы. Но игнорирование негативных отзывов может быть плохой стратегией. Ваши потенциальные клиенты должны видеть, что когда что-то идет не по плану, ваш бизнес проявляет инициативу и готов предпринять правильные шаги для исправления ситуации.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_23: 'Спорить с клиентом',
    what_you_shouldnt_do_when_responding_to_negative_reviews_24: 'Конечно, бывает, что клиенты бывают слишком несправедливы. Возможно, они не учли всех деталей или не учли некоторые особенности. Но отзывы — не место, чтобы доказать, что клиент неправ.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_25: 'Ваш ответ на отзыв увидят все, а не только рецензент. Вы должны оставаться объективными и нейтральными.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_26: 'Слишком поздний ответ',
    what_you_shouldnt_do_when_responding_to_negative_reviews_27: 'Существует стереотип, что клиенты не замечают, когда вы отвечаете на их отзывы. Но Google отправляет клиентам уведомление по электронной почте, чтобы предупредить их о новом ответе от компании.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_28: 'Согласно некоторым исследованиям, клиенты ожидают ответа от компании в течение 24 часов, но согласно исследованию Gatherup, 86% потребителей считают приемлемым ответ в течение 3 дней.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_29: 'Оставить клиентов без решения',
    what_you_shouldnt_do_when_responding_to_negative_reviews_30: 'Иногда вы не можете решить проблему клиента прямо сейчас и показать результат.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_31: 'Также помните о специфике отзывов Google: как только ваша компания оставила отзыв, клиенты не смогут ответить снова. Упростите задачу для клиентов, предложив несколько подходящих решений и предоставив контактную информацию, будь то номер телефона, адрес электронной почты или физический адрес.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_32: 'Будет ли клиент взаимодействовать и связываться с вашей компанией, полностью зависит от него. Важно, чтобы ваш бизнес предлагал решения и облегчал выбор клиентам.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_33: 'Шаблонный ответ',
    what_you_shouldnt_do_when_responding_to_negative_reviews_34: 'Если вы будете говорить одно и то же в каждом отзыве, это будет выглядеть так, как будто у вас автоответчик и вы не очень внимательны к своим клиентам.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_35: 'Несмотря на то, что некоторые отзывы могут быть почти идентичными, все же важно персонализировать ваши ответы.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_36: 'Шаблонный ответ',
    what_you_shouldnt_do_when_responding_to_negative_reviews_37: 'Каждая компания получает негативные, а иногда и токсичные отзывы.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_38: 'Понижение звездного рейтинга, критика в социальных сетях или негативные отзывы могут напрямую повлиять на доход, и, к сожалению, это не всегда справедливо.',
    what_you_shouldnt_do_when_responding_to_negative_reviews_39: 'Согласно исследованиям HBR, если вы правильно реагируете на негатив, это приведет к увеличению продаж. И лучшее, что может сделать любой бизнес, — это взять на себя ответственность.',
    service_design_sessions_post_1: 'Сессии проектирования услуг',
    service_design_sessions_post_2: 'Ана Краинович',
    service_design_sessions_post_3: 'Лингвист, кандидат наук и создатель комиксов, пишущий о психическом здоровье, языках, творчестве и жизни',
    service_design_sessions_post_4: 'Методология проектирования услуг является важной частью 4Service.',
    service_design_sessions_post_5: 'Сервисный дизайн – это методология, ориентированная на человека и клиента, которая фокусируется на клиентском опыте как ключевой ценности успеха. Сервис-дизайн рассматривает интегрированный подход к стратегическим, системным, процессным и точкам взаимодействия дизайнерским решениям.',
    service_design_sessions_post_6: 'Мы проводим корпоративное обучение, которое помогает решать важные бизнес-задачи. Мы также проводим семинары для заинтересованных сторон компании.',
    service_design_sessions_post_7: 'Пару лет назад мы стали партнером DesignThinkers Academy и не только используем лучшие практики в своей работе, но и активно обучаем лучшие компании.',
    service_design_sessions_post_8: 'Проведенные мероприятия:',
    service_design_sessions_post_9: 'Курс сервис-дизайна в ДТЭК.Академия',
    service_design_sessions_post_10: 'Даты:',
    service_design_sessions_post_11: 'октябрь/ноябрь 2021, Киев, Украина',
    service_design_sessions_post_12: 'Аудитория:',
    service_design_sessions_post_13: '20 человек',
    service_design_sessions_post_14: 'Дія.Бизнес',
    service_design_sessions_post_15: '(лекция + мастер-класс для всех, кто создает продукты и услуги, планирует инновационные разработки и отвечает за маркетинг и продажи)',
    service_design_sessions_post_16: 'Дата:',
    service_design_sessions_post_17: '27 мая 2021 г. Киев, Украина',
    service_design_sessions_post_18: 'Аудитория:',
    service_design_sessions_post_19: '25 человек',
    about: 'О',
    why_us: 'Почему мы',
    about_us: 'О нас',
    key_features: 'Ключевые особенности',
    solutions: 'Решения',
    monitoring: 'Мониторинг',
    how: 'Как',
    how_it_works: 'Как это работает',
    when_to_use: 'Когда использовать',
    mystery_shopping: 'Тайный покупатель',
    voicer: 'Voicer',
    wantent: 'Wantent',
    play4sales: 'Play4Sales',
    price_monitoring: 'Ценовой мониторинг',
    service_design: 'Сервис-дизайн',
    qualitative_research: 'Качественное исследование',
    quantitative_research: 'Количественное исследование',
    cawi: 'CAWI',
    f2f: 'F2F',
    cati: 'CATI',
    hashtag_automotive: '#Автомобили',
    hashtag_retayl: '#Розница',
    hashtag_all_posts: '#все_сообщения',
    hashtag_feedback: '#обратная связь',
    hashtag_4Service_csr: '#4Service_CSR',
    hashtag_cases: '#Кейсы',
    hashtag_market_research: '#исследование_рынка',
    more: 'Больше',
    speak_to_an_expert: 'Обратитесь к эксперту',
    book_consultancy: 'Заказ консультации',
    read_all: 'Читать все',
    find_out_more: 'Узнайте больше',
    address: 'Адрес',
    mon_fri: 'Пн-Пт',
    phone_number: 'Номер телефона',
    flag: 'флаг',
    scroll_to_left: 'прокрутить влево',
    online: 'онлайн',
    tel: 'Тел',
    email: 'E-mail',
    light_it_up: 'Зажигай',
    about_us_60: 'Алина Андреева',
    about_us_61: 'Директор по развитию бизнеса',
    about_us_62: 'Юлия Кравченко',
    about_us_63: 'Старший менеджер проекта',
    about_us_64: 'Ольга Аксонова',
    about_us_65: 'Менеджер по развитию бизнеса',
    about_us_66: 'Желевский Дмитрий',
    about_us_67: 'Операционный директор',
    about_us_roles_1: 'Управление',
    about_us_roles_2: 'Команда Глобал',
    about_us_roles_3: 'Владельцы продукта',
    ad_testing_1: 'Тестирование рекламы',
    ad_testing_2: 'Рекламная кампания — это огромные инвестиции. Убедитесь, что ваши объявления эффективны и обеспечивают максимальную рентабельность инвестиций',
    ad_testing_3: 'заблокировать',
    ad_testing_4: 'Отрасли, которые мы обслуживаем',
    ad_testing_5: '4Service – глобальный исследовательский холдинг, специализирующийся на изучении клиентского опыта',
    ad_testing_6: '4Service — глобальный маркетинговый холдинг и маркетинговые исследования. Мы предлагаем экспертные услуги по сбору данных для самых разных предприятий',
    ad_testing_7: 'Cash & Carry',
    ad_testing_8: 'Розничная торговля',
    ad_testing_9: 'Электроника',
    ad_testing_10: 'Люкс/Премиум',
    ad_testing_11: 'Рестораны и гостиничный бизнес',
    ad_testing_12: 'Автомобили',
    ad_testing_13: 'Путешествия и отдых',
    ad_testing_14: 'Мода',
    ad_testing_15: 'Банки',
    ad_testing_16: 'Часы и украшения',
    ad_testing_17: 'Бытовая техника',
    ad_testing_18: 'Член Esomar',
    ad_testing_19: 'Мы провели исследование Ad Testing по рекламе для различных предприятий в разных областях',
    ad_testing_20: 'Если Вам нужно тестирование рекламы',
    ad_testing_21: 'Продвижение нового продукта или услуги',
    ad_testing_22: 'Обсуждение деликатной темы',
    ad_testing_23: 'Попытка охватить новую целевую аудиторию или рынок',
    ad_testing_24: 'Обсуждение нескольких вариантов дизайна',
    ad_testing_25: 'Подтверждение концепции для управленческих команд или инвесторов',
    ad_testing_26: 'Вы получите ответы на вопросы',
    ad_testing_27: 'Какие рекламные каналы лучше всего подходят для этих объявлений?',
    ad_testing_28: 'Используем ли мы правильные визуальные эффекты, чтобы донести сообщение?',
    ad_testing_29: 'Находят ли отклик ваши рекламные тексты и сообщения?',
    ad_testing_30: 'Какие рекламные каналы лучше всего подходят для этих объявлений?',
    ad_testing_31: 'Что в первую очередь привлекает их внимание?',
    ad_testing_32: 'Легко ли понять размещение и макет объявления?',
    ad_testing_33: 'Мы оцениваем',
    ad_testing_34: 'Брендинг',
    ad_testing_35: 'как ваш бренд связан с рекламой и насколько он запомнится',
    ad_testing_36: 'Ключевые показатели',
    ad_testing_37: 'Интеграция',
    ad_testing_38: 'Признание',
    ad_testing_39: 'Подходит',
    ad_testing_40: 'Креатив',
    ad_testing_41: 'Выделяется ли Ваша реклама и привлекает ли она внимание людей',
    ad_testing_42: 'Ключевые показатели',
    ad_testing_43: 'РАСПОЗНАВАНИЕ',
    ad_testing_44: 'ЗАИНТЕРЕСОВАННОСТЬ',
    ad_testing_45: 'МТО',
    ad_testing_46: 'МОТИВАЦИЯ',
    ad_testing_47: 'Впечатления клиентов, эмоциональное взаимодействие',
    ad_testing_48: 'С помощью технологий искусственного интеллекта и экспертов-аналитиков мы анализируем реальные эмоции людей и получаем представление об их восприятии рекламы',
    ad_testing_49: 'Рейтинг',
    ad_testing_50: 'Мужской',
    ad_testing_51: 'Женский',
    ad_testing_52: 'Высокая вовлеченность',
    ad_testing_53: 'Внимание',
    ad_testing_54: 'Что понравилось и откликнулось аудитории?',
    ad_testing_55: 'Актриса',
    ad_testing_56: 'Равенство',
    ad_testing_57: 'Музыкальный фон',
    ad_testing_58: 'Положительные эмоции',
    ad_testing_59: 'Люди разных рас и внешности',
    ad_testing_60: 'Снимки на улице',
    ad_testing_61: 'Что НЕ понравилось аудитории и не нашло отклика?',
    ad_testing_62: 'Голос диктора не соответствовал происходящему на экране',
    ad_testing_63: 'Низкое внимание к продукту',
    ad_testing_64: 'Слишком динамично',
    ad_testing_65: 'Трудно понять',
    ad_testing_66: 'TRP',
    ad_testing_67: 'Впечатления',
    ad_testing_68: 'Показы',
    ad_testing_69: 'Конверсия',
    ad_testing_70: 'Медиа',
    ad_testing_71: 'Мы измеряем эффективность рекламной кампании в сочетании с медийной активностью, вычисляя влияние инвестиций в медиа в различных каналах на отслеживаемые результаты.',
    ad_testing_72: 'Мы проводим рекламные исследования в любом формате, платформе или канале',
    ad_testing_73: 'видеоконтент',
    ad_testing_74: 'на открытом воздухе',
    ad_testing_75: 'Телевизионная реклама',
    ad_testing_76: 'печать',
    ad_testing_77: 'цифровой',
    ad_testing_78: 'рекламные кампании',
    ad_testing_79: 'Расположение рекламных материалов в точках продаж',
    ad_testing_80: 'аниматика',
    ad_testing_81: 'С нашей помощью вы можете протестировать свою рекламу на любом этапе',
    ad_testing_82: 'Концепция',
    ad_testing_83: 'у каких идей больше возможностей?',
    ad_testing_84: 'Ранняя стадия производства',
    ad_testing_85: 'какая аниматика пойдет в производство?',
    ad_testing_86: 'Производство',
    ad_testing_87: 'как отредактировать видео, чтобы оно удерживало внимание?',
    ad_testing_88: 'Пре-медиа',
    ad_testing_89: 'какие каналы связи будут работать и какой результат можно ожидать?',
    ad_testing_90: 'Пост-тестирование рекламы',
    ad_testing_91: 'Производство',
    ad_testing_92: 'увлекает ли моя реклама? Снижается ли эффективность креативности со временем?',
    ad_testing_93: 'Предпечатная подготовка',
    ad_testing_94: 'насколько эффективна моя реклама в цифрах? Как она сравнивается с конкурентной?',
    ad_testing_95: 'Предварительное тестирование рекламы',
    ad_testing_96: 'Чтобы предоставить вам качественные данные, мы используем',
    ad_testing_97: 'Методология количественных исследований',
    ad_testing_98: 'CAWI',
    ad_testing_99: 'Методология качественных исследований',
    ad_testing_100: 'Фокус-группы',
    ad_testing_101: 'ИИ',
    ad_testing_102: 'Wantent',
    ad_testing_103: 'Аналитические отчеты, которые вы получаете',
    ad_testing_104: 'Наша команда аналитиков, медиаэкспертов, маркетологов подготовит и представит вам индивидуальный отчет с подробными рекомендациями',
    ad_testing_age: 'возраст',
    ad_testing_ad: 'Объявление',
    automotive_industry_price_analysis_1: 'Анализ цен в автомобильной промышленности',
    automotive_industry_price_analysis_2: 'Анализ конкурентных цен может помочь предотвратить упущенную выгоду, увеличить долю рынка и отслеживать вашу позицию на рынке. Позвольте нам помочь вам в выборе успешной конкурентной стратегии!',
    automotive_industry_price_analysis_3: 'Запросить коммерческое предложение',
    automotive_industry_price_analysis_4: 'Наша методология',
    automotive_industry_price_analysis_5: 'Салон автомобилей для стильных и элегантных людей.',
    automotive_industry_price_analysis_6: 'Метод Mystery Shopping является единственным подходом, который точно отражает реальный опыт клиента и предоставляет достоверный обзор.',
    automotive_industry_price_analysis_7: 'Мы предлагаем точные цифры и надежные многосторонние данные',
    automotive_industry_price_analysis_8: 'У нас глобальное покрытие, охватывающее каждую страну мира.',
    automotive_industry_price_analysis_9: 'Наши внутренние ресурсы включают в себя тайных покупателей и аудиторов',
    automotive_industry_price_analysis_10: 'Мы проверяем качество наших данных',
    automotive_industry_price_analysis_11: 'Наши преимущества:',
    automotive_industry_price_analysis_12: 'Мониторинг цен на конкретные модели конкурентов (B2B и B2C)',
    automotive_industry_price_analysis_13: 'Мониторинг уровня скидки',
    automotive_industry_price_analysis_14: 'Отслеживание торговых запасов вашего бренда и конкурентов в розничной торговле',
    automotive_industry_price_analysis_15: 'Мониторинг цен на автомобили в электронной коммерции',
    automotive_industry_price_analysis_16: 'Анализ ценовой коммуникации',
    automotive_industry_price_analysis_17: 'Отслеживание стоимости доставки розничных продавцов',
    automotive_industry_price_analysis_18: 'Отслеживание рекламных акций и распродаж в розничных магазинах',
    automotive_industry_price_analysis_19: 'Определение сумм ежемесячных платежей',
    automotive_industry_price_analysis_20: 'Расчет лизинговых платежей',
    automotive_industry_price_analysis_21: 'Анализ конкретных факторов ценообразования для электромобилей (ЭМ)',
    automotive_industry_price_analysis_22: 'Проведение сравнения цен между прямыми продажами потребителю и продажами через розничные магазины',
    automobile_industry_price_analysis_23: 'Разработка моделей ценообразования на основе подписки',
    automotive_industry_price_analysis_24: '4Service - это международная холдинговая компания, специализирующаяся на создании и улучшении клиентского опыта (CX) уже более 20 лет',
    automotive_industry_price_analysis_25: 'Вы будете использовать данные о готовности потребителей платить за различные функции, чтобы оптимизировать ваше предложение и определить наиболее важные характеристики для вашей целевой аудитории',
    automotive_industry_price_analysis_26: 'Анализ цен в автомобильной индустрии от 4Service включает в себя:',
    automotive_industry_price_analysis_27: 'Самые низкие цены не всегда являются ключом к конкурентоспособности автомобильных брендов. Позвольте нам помочь вам создать долгосрочную стратегию ценообразования',
    automotive_industry_price_analysis_28: 'клиенты понимают, что цены растут во всех брендах',
    automotive_industry_price_analysis_29: '29',
    automotive_industry_price_analysis_30: 'клиентов заявили, что все равно купят автомобиль, который им нравится, даже если бренд проинформирует их о предстоящем повышении цен,',
    automotive_industry_price_analysis_31: '31',
    automotive_industry_price_analysis_32: 'людей, считающих лизинг и ежемесячные платежи наиболее предпочтительной моделью при покупке автомобиля для них',
    automotive_industry_price_analysis_33: '48',
    automotive_industry_price_analysis_34: 'дилеров, упомянувших возможность увеличения цен в процессе общения/продажи',
    automotive_industry_price_analysis_35: '47,6',
    automotive_industry_price_analysis_36: 'Ключевые показатели',
    automotive_industry_price_analysis_37: 'Вы определите цену своего продукта относительно других автомобилей в линейке',
    automotive_industry_price_analysis_38: 'Вы будете использовать данные о готовности потребителей платить за различные функции, чтобы оптимизировать ваше предложение и выявить наиболее важные функции для ваших целевых клиентов',
    automotive_industry_price_analysis_39: 'покупателей говорят, что они все равно купили бы автомобиль, который им нравится, даже если бренд сообщил им о предстоящем повышении цен',
    automotive_industry_price_analysis_40: 'Вы сможете определить подходящую сумму ежемесячного платежа и ставки аренды.',
    automotive_industry_price_analysis_41: 'Какие преимущества для вашего бренда или дилера вы получите:',
    automotive_industry_price_analysis_42: 'Вы подаете заявку',
    automotive_industry_price_analysis_43: 'Наш менеджер связывается с вами для определения основных целей',
    automotive_industry_price_analysis_44: 'Мы проводим маркетинговое исследование',
    automotive_industry_price_analysis_45: 'Мы регулярно предоставляем вам отчеты и рекомендации в онлайн-формате',
    automotive_industry_price_analysis_46: 'Автомобиль с ярлыком распродажи',
    automotive_industry_price_analysis_47: 'Мы запускаем проект за 1 день!',
    automotive_industry_price_analysis_48: 'Мы предлагаем полный набор отчетов, включая фотоотчеты, отчеты в формате PPT и отчеты в формате Excel, сегментированные по типу местности',
    retail_audit_1: 'АУДИТ ТОРГОВОЙ СЕТИ/аудит розничной торговли',
    retail_audit_2: 'Соберите подробные данные о вашем магазине или бренде, проанализируйте преграды роста и разработайте эффективные стратегии их преодоления',
    retail_audit_3: 'Запросить коммерческое предложение',
    retail_audit_4: '4Service проводит аудит магазинов на месте от имени брендов, чтобы оценить эффективность их промоакций, команд, занимающихся выполнением в локации, и маркетинговых тактик в достижении целевого рынка',
    retail_audit_5: 'Мы тщательно наблюдаем за проведением рекламных кампаний и обеспечиваем соблюдение рекламных руководств',
    retail_audit_6: '10 лучших',
    retail_audit_7: 'Агентств по работе с клиентами в Европе',
    retail_audit_8: 'многолетний опыт',
    retail_audit_9: 'покрытие стран',
    retail_audit_10: '1,6 миллиона',
    retail_audit_11: 'оценки в год',
    retail_audit_12: 'Что мы отслеживаем?',
    retail_audit_13: 'Наличие материалов для точек продаж (POS)',
    retail_audit_14: 'Присутствие и активация представителей бренда, обеспечение соблюдения запланированного графика',
    retail_audit_15: 'Оценка участия представителей бренда в кампаниях по защите интересов',
    retail_audit_16: 'Оценка взаимодействия с потребителем, обеспечение соблюдения промоушн-программ и брендовых сообщений',
    retail_audit_17: 'Меры по предотвращению мошенничества',
    retail_audit_18: 'Ваш бизнес может использовать результаты аудита маркетинга в торговой точке для:',
    retail_audit_19: 'Оценки соответствия бюджету и процессам',
    retail_audit_20: 'Сравнения фактических результатов с заданными целями',
    retail_audit_21: 'Улучшения эффективности маркетинговых кампаний',
    retail_audit_22: 'Стратегического планирования на будущее',
    retail_audit_23: 'Выявления возможностей по снижению затрат',
    retail_audit_24: 'Повышения объемов продаж и конверсии',
    retail_audit_25: 'Оптимизации инвестиционной отдачи (ROI)',

    retail_audit_26: 'ОНИ ВЫБРАЛИ 4SERVICE В КАЧЕСТВЕ ПАРТНЕРА ПО ИНВЕСТИЦИЯМ В ТОРГОВЛЮ',
    retail_audit_27: 'Ведущий международный производитель табачных изделий стал партнером 4Service с целью усиления своих брендов и улучшения работы в магазинах. Наши команды провели аудит маркетинга на месте и реализовали систему реального отчета о впечатлениях потребителей',
    retail_audit_28: 'увеличение точности планирования агентства по маркетингу в торговых точках',
    retail_audit_29: 'увеличение доставки сообщений бренда',
    retail_audit_30: 'увеличение вовлеченности представителей бренда (активация потребителей)',
    retail_audit_31: 'увеличение точности сбора данных и соблюдения требований GDPR',
    retail_audit_32: 'Какие ценные идеи мы чаще всего находим:',
    retail_audit_33: 'Отсутствие согласованности или даже конфликты в условиях SLA приводят к значительным несанкционированным списаниям средств с клиента',
    retail_audit_34: 'Неправильное выставление счетов из-за смешения видов предоставляемых услуг',
    retail_audit_35: 'Отсутствие выполнения полевых работ, включая назначение сотрудников на запланированные места их выполнения | POS',
    retail_audit_36: 'Списания основаны на оценке, а не на реальных данных, и не проходят согласование',
    retail_audit_37: 'Неправильное применение рекламных руководств, ведущее к предоставлению мошеннических данных',

    retail_audit_38: 'Как мы работаем?',
    retail_audit_39: 'Наш аудитор посещает определенные точки продаж (POS) в соответствии с планом активации агентства.',
    retail_audit_40: 'Они оценивают ключевые показатели эффективности (KPI), как указано в брифе, такие как присутствие в точках продажи, участие представителей бренда, соблюдение рекомендаций по продвижению и меры по предотвращению мошенничества.',
    retail_audit_41: 'Валидация:',
    retail_audit_42: 'Мы собираем и систематизируем полученные данные.',
    retail_audit_43: 'Готовим подробный отчет.',
    retail_audit_44: 'Наша группа проверки тщательно проверяет все опросы на точность, включая соответствие брифу проекта и любые дополнительные комментарии.',
    retail_audit_45: 'Система онлайн-отчетности:',
    retail_audit_46: 'Заполненные и проверенные анкеты загружаются на наш онлайн-портал в течение 36 часов.',
    retail_audit_47: 'Клиенты имеют доступ к личному аккаунту на портале, что позволяет им отслеживать прогресс в любое время.',
    retail_audit_48: 'Предоставляется окончательный отчет, содержащий результаты и рекомендации.',
    retail_audit_49: 'Обратитесь к эксперту',
    retail_audit_50: 'Наши сильные стороны:',
  },
};
