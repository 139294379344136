import React from 'react';

const FormErrorMessage: React.FC = ({ children }: any) => (

  <div className="form-field-error">
    {`*${children}`}
  </div>
);

export default FormErrorMessage;
