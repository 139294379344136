import styled from 'styled-components';

export const LangMenuMobileStyles = styled.div`
  button {
    padding: 0;
    color: #EE2752;
    background: none;
    border: none;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    cursor: pointer;
  }

  img {
    width: 21px;
    height: 21px;
  }
  
  
  @media (min-width: 769px) {
    display: none;
  }
  
  .langMenu {
    transform: translateX(100%);
    
    transition: 0.3s ease-in all;
    
    &.langMenuOpen {
      transform: translateX(0);
    }
  }
  
  ul {
    z-index: 51;
    padding-top: 27px;
    
    gap: 12px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    position: absolute;
    right: 0;
    top: 0;
    width: 109px;
    height: 100vh;

    background: #0000FF;
    
    li {
      button {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 20px;
        width: 50px;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
      }
    }
    
    .selectedLanguage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      width: 50px;
      
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FFF;
    }
  }
`;
